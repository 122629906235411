import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import * as R from 'ramda'
import { Icons, Nil } from '@pbt/pbt-ui-components'
import { Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'

import { getPatient } from '~/store/reducers/patients'

import {
  getAllPatientPlansCancelled,
  getPatientHasNoMembership,
  getPatientHasPausedPlan,
  // @ts-ignore
} from '../../wellness-plans/wellnessPlanUtils'

export interface PatientMembershipIconProps {
  className: string
  hideIfNoMembership?: boolean
  isLoading?: boolean
  patientId: string | Nil
}

const PatientMembershipIcon = ({
  hideIfNoMembership,
  className,
  patientId,
  isLoading,
}: PatientMembershipIconProps) => {
  const patient = useSelector(getPatient(patientId))

  const hasNoMembership = getPatientHasNoMembership(patient)

  if (isLoading) {
    return (
      <Skeleton
        className={className}
        height={24}
        variant="circular"
        width={24}
      />
    )
  }

  if (hideIfNoMembership && hasNoMembership) {
    return null
  }

  const hasAllCancelledIconVariant = Icons.IconVariant.SUSPENSED

  const hasPausedIconVariant = Icons.IconVariant.SUSPENSED

  const hasNoMembershipIconVariant = Icons.IconVariant.INACTIVE

  const getIconVariant = R.cond([
    [getAllPatientPlansCancelled, R.always(hasAllCancelledIconVariant)],
    [getPatientHasPausedPlan, R.always(hasPausedIconVariant)],
    [getPatientHasNoMembership, R.always(hasNoMembershipIconVariant)],
    [R.T, R.always(Icons.IconVariant.PRIMARY)],
  ])

  const variant = getIconVariant(patient)

  return <WellnessIcon className={className} variant={variant} />
}

export default PatientMembershipIcon
