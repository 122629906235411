import React, { ForwardedRef, forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Nil } from '@pbt/pbt-ui-components'

import {
  clearMedicalHistoryDiagnoses,
  fetchDiagnoses,
  fetchDiagnosesFilters,
} from '~/store/actions/medicalHistory'
import {
  getMedicalHistoryDiagnoses,
  getMedicalHistoryDiagnosesFilters,
} from '~/store/reducers/medicalHistory'
import {
  DiagnoseHistoryItem,
  FetchOrdersOptions,
  SearchParameters,
  SoapDiagnoseSearchResult,
} from '~/types'

// @ts-ignore
import SelectableListWithFilters from '../../soap/list-with-filters/SelectableListWithFilters'
import DiagnosisHistoryExpandedDetails from './DiagnosisHistoryExpandedDetails'

const useStyles = makeStyles(
  () => ({
    selectableListContainer: {
      padding: 0,
    },
  }),
  { name: 'DiagnosisListWithFilters' },
)

export interface DiagnosisSelectableListHandle {
  clearItems: () => void
  getItems: () => DiagnoseHistoryItem[]
  onUpdateItem: (item: DiagnoseHistoryItem) => void
}

interface DiagnosisListWithFiltersProps {
  isLoading?: boolean
  isReceivingListItems?: boolean
  onProceed: (items: DiagnoseHistoryItem[]) => void
  patientId: string | Nil
  proceedButtonText?: string
  searchItems: (searchParameters: SearchParameters) => void
  searchPlaceholder: string
  searchResults: SoapDiagnoseSearchResult[]
}

const DiagnosisListWithFilters = forwardRef(function DiagnosisListWithFilters(
  { patientId, proceedButtonText, ...rest }: DiagnosisListWithFiltersProps,
  ref: ForwardedRef<DiagnosisSelectableListHandle>,
) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const diagnoses = useSelector(getMedicalHistoryDiagnoses)
  const filters = useSelector(getMedicalHistoryDiagnosesFilters)
  const { t } = useTranslation('Common')

  useEffect(() => {
    if (patientId) {
      dispatch(fetchDiagnosesFilters(patientId))
    }
  }, [])

  const getAllCategories = (entityType: FetchOrdersOptions['entityType']) => {
    const selectedFilter = filters.find(({ type }) => type === entityType)
    return selectedFilter?.children.map(({ categoryId }) => categoryId) || []
  }

  const loadNewItems = ({ categories, entityType }: FetchOrdersOptions) => {
    const categoriesToLoad = categories?.length
      ? categories
      : getAllCategories(entityType)

    if (categoriesToLoad && entityType && patientId) {
      dispatch(fetchDiagnoses(patientId, categoriesToLoad, entityType))
    }
  }

  return (
    <SelectableListWithFilters
      DetailsComponent={DiagnosisHistoryExpandedDetails}
      ListItemProps={{
        showListItemActions: true,
      }}
      classes={{
        container: classes.selectableListContainer,
      }}
      clearItems={() => dispatch(clearMedicalHistoryDiagnoses())}
      filters={filters}
      items={diagnoses}
      loadNewItems={loadNewItems}
      proceedButtonLabel={proceedButtonText || t('Common:ADD_TO_TIMELINE')}
      ref={ref}
      {...rest}
    />
  )
})

export default DiagnosisListWithFilters
