import { ApiError } from '@pbt/pbt-ui-components'

import { BoardingScheduleData, ScheduleData } from '~/types'

import {
  FETCH_ALTERNATE_DATE_SCHEDULES,
  FETCH_ALTERNATE_DATE_SCHEDULES_FAILURE,
  FETCH_ALTERNATE_DATE_SCHEDULES_SUCCESS,
  FETCH_BOARDING_SCHEDULES,
  FETCH_BOARDING_SCHEDULES_FAILURE,
  FETCH_BOARDING_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_FAILURE,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_UPCOMING_EVENTS,
  FETCH_UPCOMING_EVENTS_FAILURE,
  FETCH_UPCOMING_EVENTS_SUCCESS,
} from './types/scheduler'

export const fetchSchedules = (silent?: boolean, date?: string) => ({
  type: FETCH_SCHEDULES,
  silent,
  date,
})
export const fetchSchedulesSuccess = (scheduleData: ScheduleData) => ({
  type: FETCH_SCHEDULES_SUCCESS,
  ...scheduleData,
})
export const fetchSchedulesFailure = (error: ApiError) => ({
  type: FETCH_SCHEDULES_FAILURE,
  error,
})

export const fetchAlternateDateSchedules = (
  silent?: boolean,
  date?: string,
) => ({
  type: FETCH_ALTERNATE_DATE_SCHEDULES,
  silent,
  date,
})
export const fetchAlternateDateSchedulesSuccess = (
  scheduleData: ScheduleData,
) => ({
  type: FETCH_ALTERNATE_DATE_SCHEDULES_SUCCESS,
  ...scheduleData,
})
export const fetchAlternateDateSchedulesFailure = (error: ApiError) => ({
  type: FETCH_ALTERNATE_DATE_SCHEDULES_FAILURE,
  error,
})

export const fetchBoardingSchedules = (silent?: boolean, date?: string) => ({
  type: FETCH_BOARDING_SCHEDULES,
  silent,
  date,
})
export const fetchBoardingSchedulesSuccess = (
  scheduleData: BoardingScheduleData,
) => ({
  type: FETCH_BOARDING_SCHEDULES_SUCCESS,
  ...scheduleData,
})
export const fetchBoardingSchedulesFailure = (error: ApiError) => ({
  type: FETCH_BOARDING_SCHEDULES_FAILURE,
  error,
})

export const fetchUpcomingEvents = (
  clientId: string,
  patientId: string,
  includePast?: boolean,
  filterByClient?: boolean,
) => ({
  type: FETCH_UPCOMING_EVENTS,
  clientId,
  patientId,
  includePast,
  filterByClient,
})
export const fetchUpcomingEventsSuccess = (
  appointmentsList: string[],
  remindersList: string[],
) => ({
  type: FETCH_UPCOMING_EVENTS_SUCCESS,
  appointmentsList,
  remindersList,
})
export const fetchUpcomingEventsFailure = (error: ApiError) => ({
  type: FETCH_UPCOMING_EVENTS_FAILURE,
  error,
})
