import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil } from '@pbt/pbt-ui-components'

import {
  INACTIVE_ACTIVE_USERS,
  SCHEDULE_WORKING_TODAY,
} from '~/components/dashboard/timetable/rail/TimetableFilters'
import { TimetableEvent } from '~/types'
import { secondLevelMerge } from '~/utils'
import {
  getErrorMessage,
  getServerMultiValidationError,
  getServerValidationError,
} from '~/utils/errors'

import { DELETE_ESTIMATE_SUCCESS } from '../actions/types/finance'
import { FETCH_SCHEDULES_SUCCESS } from '../actions/types/scheduler'
import { CREATE_SOAP_SUCCESS } from '../actions/types/soap'
import {
  ADD_ESTIMATE_TO_EVENT,
  ADD_FORM_FAILURE,
  ADD_FORM_SUCCESS,
  ADD_TIMETABLE_EVENT_SOAP,
  CANCEL_FUTURE_APPOINTMENTS,
  CANCEL_FUTURE_APPOINTMENTS_FAILURE,
  CANCEL_FUTURE_APPOINTMENTS_SUCCESS,
  CLEAR_APPOINTMENT_VALIDATION_ERROR,
  CLEAR_PATCHED_APPOINTMENT_DATA,
  CLEAR_SCHEDULING_ASSIGNMENT,
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_BUSY_TIME,
  CREATE_BUSY_TIME_FAILURE,
  CREATE_BUSY_TIME_SUCCESS,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_BUSY_TIME,
  DELETE_BUSY_TIME_FAILURE,
  DELETE_BUSY_TIME_SUCCESS,
  DELETE_FORM_FAILURE,
  DELETE_FORM_SUCCESS,
  DISABLE_TIMETABLE_AUTO_REFRESH,
  EDIT_APPOINTMENT,
  EDIT_APPOINTMENT_FAILURE,
  EDIT_APPOINTMENT_SUCCESS,
  EDIT_BUSY_TIME,
  EDIT_BUSY_TIME_FAILURE,
  EDIT_BUSY_TIME_SUCCESS,
  ENABLE_TIMETABLE_AUTO_REFRESH,
  FETCH_APPOINTMENT,
  FETCH_APPOINTMENT_FAILURE,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_BUSY_TIME,
  FETCH_BUSY_TIME_FAILURE,
  FETCH_BUSY_TIME_SUCCESS,
  PATCH_APPOINTMENT,
  PATCH_APPOINTMENT_ABOLISH,
  PATCH_APPOINTMENT_FAILURE,
  PATCH_APPOINTMENT_SUCCESS,
  SET_DEFAULT_SCHEDULER_FILTERS,
  SET_DEFAULT_WHITEBOARD_FILTERS,
  SET_SCHEDULING_ASSIGNMENT,
  TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX,
  UPDATE_APPOINTMENT_NOTES,
  UPDATE_APPOINTMENT_NOTES_FAILURE,
  UPDATE_APPOINTMENT_NOTES_SUCCESS,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_FAILURE,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  UPDATE_EVENTS,
  UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS,
  UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES,
  UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS,
  UPDATE_TIMETABLE_FILTERED_PERSONS,
} from '../actions/types/timetable'
import { FETCH_WHITEBOARD_SUCCESS } from '../actions/types/whiteboard'
import { DELETE_ZOOM_LINK_SUCCESS } from '../duck/conferencing'
import type { RootState } from '../index'
import { getAppointmentId } from './soap'
import { getUsersMap } from './users'

export type TimetableState = {
  error: string | null
  eventFieldsToUpdate: Record<string, string>
  eventIdToUpdate: string | null
  filteredAppointmentTypeGroups: string[]
  filteredAppointmentTypes: string[]
  filteredPersonGroups: string[]
  filteredPersons: string[]
  isCreatingAppointment: boolean
  isDeleting: boolean
  isFetchingAppointment: boolean
  isLoading: boolean
  isPetParentIsAwareOfCancellationPolicyChecked: boolean
  isSaving: boolean
  isUpdatingNotes: boolean
  isZoomLinkCreating?: boolean
  lastAppointmentId: string | null
  map: Record<string, TimetableEvent>
  multiValidationError: string[] | null
  patchedAppointmentData: Partial<TimetableEvent> | null
  pendingAppointment: TimetableEvent | null
  persons: string[]
  schedulingAssignmentRedirectUrl: string | null
  schedulingClientId: string | null
  schedulingPatientId: string | null
  timetableAutoRefreshEnabled: boolean
  validationError: string | null
}

export const TIMETABLE_INITIAL_STATE: TimetableState = {
  eventFieldsToUpdate: {},
  eventIdToUpdate: null,
  filteredPersons: [],
  filteredAppointmentTypes: [],
  filteredPersonGroups: [SCHEDULE_WORKING_TODAY, INACTIVE_ACTIVE_USERS],
  filteredAppointmentTypeGroups: [],
  map: {},
  isLoading: false,
  isDeleting: false,
  isSaving: false,
  isCreatingAppointment: false,
  isPetParentIsAwareOfCancellationPolicyChecked: false,
  isUpdatingNotes: false,
  validationError: null,
  lastAppointmentId: null,
  error: null,
  persons: [],
  isFetchingAppointment: false,
  timetableAutoRefreshEnabled: true,
  patchedAppointmentData: null,
  pendingAppointment: null,
  schedulingClientId: null,
  schedulingPatientId: null,
  schedulingAssignmentRedirectUrl: null,
  multiValidationError: null,
}

const timetable = (
  state: TimetableState = TIMETABLE_INITIAL_STATE,
  action: AnyAction,
): TimetableState => {
  switch (action.type) {
    case UPDATE_TIMETABLE_FILTERED_PERSONS:
      return { ...state, filteredPersons: action.persons }
    case UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS:
      return { ...state, filteredPersonGroups: action.personGroups }
    case UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES:
      return { ...state, filteredAppointmentTypes: action.appointmentTypes }
    case UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS:
      return {
        ...state,
        filteredAppointmentTypeGroups: action.appointmentTypeGroups,
      }
    case SET_DEFAULT_SCHEDULER_FILTERS:
      return {
        ...state,
        filteredPersons: [],
        filteredPersonGroups: [SCHEDULE_WORKING_TODAY, INACTIVE_ACTIVE_USERS],
        filteredAppointmentTypeGroups: [],
        filteredAppointmentTypes: [],
      }
    case SET_DEFAULT_WHITEBOARD_FILTERS:
      return {
        ...state,
        filteredPersons: [],
        filteredPersonGroups: [INACTIVE_ACTIVE_USERS],
        filteredAppointmentTypeGroups: [],
        filteredAppointmentTypes: [],
      }
    case CLEAR_APPOINTMENT_VALIDATION_ERROR:
      return { ...state, validationError: null }
    case CREATE_APPOINTMENT:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        isCreatingAppointment: false,
        error: null,
        validationError: null,
        lastAppointmentId: null,
      }
    case CREATE_BUSY_TIME:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        error: null,
        validationError: null,
        lastAppointmentId: null,
      }
    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isCreatingAppointment: false,
        isLoading: false,
        isSaving: false,
        error: null,
        lastAppointmentId: action.appointmentId,
      }
    case CREATE_BUSY_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: null,
        lastAppointmentId: action.busyTimeId,
      }
    case CREATE_APPOINTMENT_FAILURE:
    case CREATE_BUSY_TIME_FAILURE:
      return {
        ...state,
        isCreatingAppointment: false,
        isLoading: false,
        isSaving: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
        multiValidationError: getServerMultiValidationError(action.error),
        lastAppointmentId: null,
      }
    case FETCH_APPOINTMENT:
    case FETCH_BUSY_TIME:
      return {
        ...state,
        isLoading: true,
        error: null,
        validationError: null,
        isFetchingAppointment: true,
      }
    case FETCH_APPOINTMENT_SUCCESS:
    case FETCH_BUSY_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isFetchingAppointment: false,
      }
    case FETCH_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetchingAppointment: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
        multiValidationError: getServerMultiValidationError(action.error),
      }
    case FETCH_BUSY_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
        multiValidationError: getServerMultiValidationError(action.error),
      }
    case PATCH_APPOINTMENT:
      const { id, ...data } = action.appointment
      return {
        ...state,
        eventIdToUpdate: id,
        eventFieldsToUpdate:
          id === state.eventIdToUpdate
            ? {
                ...state.eventFieldsToUpdate,
                ...data,
              }
            : data,
        isLoading: true,
        isSaving: true,
        error: null,
        validationError: null,
      }
    case EDIT_APPOINTMENT:
    case EDIT_BUSY_TIME:
      return {
        ...state,
        isLoading: true,
        isSaving: true,
        error: null,
        validationError: null,
      }
    case PATCH_APPOINTMENT_SUCCESS:
      return {
        ...state,
        eventIdToUpdate: null,
        eventFieldsToUpdate: {},
        isLoading: false,
        isSaving: false,
        error: null,
        patchedAppointmentData: {
          patientId: state.map[action.appointmentId]?.patient,
          businessAppointmentType:
            state.map[action.appointmentId]?.businessAppointmentType,
          scheduledEndDatetime:
            state.map[action.appointmentId]?.scheduledEndDatetime,
          scheduledStartDatetime:
            state.map[action.appointmentId]?.scheduledStartDatetime,
          client: state.map[action.appointmentId]?.client,
          stateId: state.map[action.appointmentId]?.stateId,
          type: state.map[action.appointmentId]?.type,
        },
      }
    case CLEAR_PATCHED_APPOINTMENT_DATA:
      return {
        ...state,
        patchedAppointmentData: null,
      }
    case EDIT_APPOINTMENT_SUCCESS:
    case EDIT_BUSY_TIME_SUCCESS:
      return { ...state, isLoading: false, isSaving: false, error: null }
    case PATCH_APPOINTMENT_FAILURE:
    case EDIT_APPOINTMENT_FAILURE:
    case EDIT_BUSY_TIME_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
        multiValidationError: getServerMultiValidationError(action.error),
      }
    case PATCH_APPOINTMENT_ABOLISH:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        error: null,
        validationError: null,
      }
    case DELETE_APPOINTMENT:
    case DELETE_BUSY_TIME:
    case CANCEL_FUTURE_APPOINTMENTS:
      return {
        ...state,
        isLoading: true,
        isDeleting: true,
      }
    case DELETE_APPOINTMENT_FAILURE:
    case DELETE_BUSY_TIME_FAILURE:
    case CANCEL_FUTURE_APPOINTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_EVENTS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.events),
      }
    case ADD_ESTIMATE_TO_EVENT:
      const { estimates } = state.map[action.eventId]
      return {
        ...state,
        map: {
          ...state.map,
          [action.eventId]: {
            ...state.map[action.eventId],
            estimates: R.uniq((estimates || []).concat(action.estimateId)),
          },
        },
      }
    case ADD_TIMETABLE_EVENT_SOAP:
      const soapsInAppointment = state.map[action.id]?.soaps
      return {
        ...state,
        map: {
          ...state.map,
          [action.id]: {
            ...state.map[action.id],
            soaps: R.uniqBy(
              R.prop('id'),
              soapsInAppointment
                ? [...soapsInAppointment, action.soap]
                : [action.soap],
            ),
          },
        },
      }
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
        map: R.dissoc(action.appointmentId, state.map),
      }
    case DELETE_BUSY_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
        map: R.dissoc(action.busyTimeId, state.map),
      }
    case CANCEL_FUTURE_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
      }
    case FETCH_SCHEDULES_SUCCESS:
    case FETCH_WHITEBOARD_SUCCESS:
      return { ...state, persons: action.persons }
    case UPDATE_APPOINTMENT_NOTES:
      return {
        ...state,
        isUpdatingNotes: true,
        error: null,
      }
    case UPDATE_APPOINTMENT_NOTES_SUCCESS:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.id]: {
            notes: action.notes,
          },
        }),
        isUpdatingNotes: false,
      }
    case UPDATE_APPOINTMENT_NOTES_FAILURE:
      return {
        ...state,
        isUpdatingNotes: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_APPOINTMENT_STATUS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.appointment.id]: {
            ...state.map[action.appointment.id],
            ...action.appointment,
          },
        },
        pendingAppointment: state.map[action.appointment.id],
        isSaving: true,
        validationError: null,
        error: null,
      }
    case UPDATE_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        pendingAppointment: null,
        isSaving: false,
      }
    case UPDATE_APPOINTMENT_STATUS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 409
            ? getServerValidationError(action.error)
            : null,
        map: {
          ...state.map,
          [action.appointment.id]: state.pendingAppointment,
        },
        pendingAppointment: null,
        isSaving: false,
      }
    case DELETE_ESTIMATE_SUCCESS:
      return {
        ...state,
        map: R.map(
          (event) => ({
            ...event,
            estimates: R.without([action.estimateId], event.estimates || []),
          }),
          state.map,
        ),
      }
    case ENABLE_TIMETABLE_AUTO_REFRESH:
      return { ...state, timetableAutoRefreshEnabled: true }
    case DISABLE_TIMETABLE_AUTO_REFRESH:
      return { ...state, timetableAutoRefreshEnabled: false }
    case CREATE_SOAP_SUCCESS: {
      const { appointmentId } = action.data.appointment
      const { id: soapId } = action.data.soapData

      if (!state.map[appointmentId]) {
        return state
      }

      return {
        ...state,
        map: {
          ...state.map,
          [appointmentId]: {
            ...state.map[appointmentId],
            soapId,
          },
        },
      }
    }
    case DELETE_ZOOM_LINK_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.appointmentId]: {
            ...state.map[action.appointmentId],
            meetingLink: null,
            dialIn: null,
            meetingNotes: '',
          },
        },
      }
    case SET_SCHEDULING_ASSIGNMENT:
      return {
        ...state,
        schedulingClientId: action.clientId,
        schedulingPatientId: action.patientId,
        schedulingAssignmentRedirectUrl: action.redirectUrl,
      }
    case CLEAR_SCHEDULING_ASSIGNMENT:
      return {
        ...state,
        schedulingClientId: null,
        schedulingPatientId: null,
        schedulingAssignmentRedirectUrl: null,
      }
    case TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX:
      return {
        ...state,
        isPetParentIsAwareOfCancellationPolicyChecked: action.payload,
      }
    case ADD_FORM_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.appointmentId]: {
            ...state.map[action.appointmentId],
            documentInstances: [
              ...(state.map[action.appointmentId].documentInstances || []),
              ...action.forms,
            ],
          },
        },
      }
    case ADD_FORM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
      }
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.appointmentId]: {
            ...state.map[action.appointmentId],
            documentInstances: [
              ...(
                state.map[action.appointmentId].documentInstances || []
              ).filter((form) => form.id !== action.formId),
            ],
          },
        },
      }
    case DELETE_FORM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default timetable
export const getTimetable = (state: RootState): TimetableState =>
  state.timetable
export const getTimetableEventIdToUpdate = (state: RootState) =>
  getTimetable(state).eventIdToUpdate
export const getTimetableEventFieldsToUpdate = (state: RootState) =>
  getTimetable(state).eventFieldsToUpdate
export const getTimetableFilteredAppointmentTypes = (state: RootState) =>
  getTimetable(state).filteredAppointmentTypes
export const getTimetableFilteredAppointmentTypeGroups = (state: RootState) =>
  getTimetable(state).filteredAppointmentTypeGroups
export const getTimetableFilteredPersons = (state: RootState) =>
  getTimetable(state).filteredPersons
export const getTimetableFilteredPersonGroups = (state: RootState) =>
  getTimetable(state).filteredPersonGroups
export const getTimetableEventsMap = (state: RootState) =>
  getTimetable(state).map
export const getTimetableEvent = (id: string | Nil) =>
  createSelector(getTimetableEventsMap, (map) => (id ? map[id] : undefined))
export const getTimetableEventForms = createSelector(
  getAppointmentId,
  getTimetableEventsMap,
  (id, map) => (id ? map[id]?.documentInstances : undefined),
)

export const getTimetableEventFormsByAppointmentId = (appointmentId?: string) =>
  createSelector(getTimetableEventsMap, (map) =>
    appointmentId ? map[appointmentId]?.documentInstances : undefined,
  )

export const getMultipleTimetableEvents = (ids: string[]) =>
  createSelector(getTimetableEventsMap, (map) =>
    R.props<string, TimetableEvent>(ids, map),
  )
export const getTimetableIsLoading = (state: RootState) =>
  getTimetable(state).isLoading
export const getTimeTableIsFetchingAppointment = (state: RootState) =>
  getTimetable(state).isFetchingAppointment
export const getTimetableIsDeleting = (state: RootState) =>
  getTimetable(state).isDeleting
export const getTimetableIsSaving = (state: RootState) =>
  getTimetable(state).isSaving
export const getTimetableIsUpdatingNotes = (state: RootState) =>
  getTimetable(state).isUpdatingNotes
export const getTimetableValidationError = (state: RootState) =>
  getTimetable(state).validationError
export const getTimetableMultiValidationError = (state: RootState) =>
  getTimetable(state).multiValidationError
export const getTimetableLastAppointmentId = (state: RootState) =>
  getTimetable(state).lastAppointmentId
export const getTimetableError = (state: RootState) => getTimetable(state).error
export const getTimetablePersons = (state: RootState) =>
  getTimetable(state).persons
export const getTimetablePersonsList = (state: RootState) =>
  getTimetablePersons(state)
    .map((id: string) => getUsersMap(state)[id])
    .filter(Boolean)
export const getTimetableAutoRefreshEnabled = (state: RootState) =>
  getTimetable(state).timetableAutoRefreshEnabled
export const getIsZoomLinkCreating = (state: RootState) =>
  getTimetable(state).isZoomLinkCreating
export const getSchedulingClientId = (state: RootState) =>
  getTimetable(state).schedulingClientId
export const getSchedulingPatientId = (state: RootState) =>
  getTimetable(state).schedulingPatientId
export const getSchedulingAssignmentRedirectUrl = (state: RootState) =>
  getTimetable(state).schedulingAssignmentRedirectUrl
export const getTimeTableIsCreatingAppointment = (state: RootState) =>
  getTimetable(state).isCreatingAppointment
export const getIsPetParentIsAwareOfCancellationPolicyChecked = (
  state: RootState,
) => getTimetable(state).isPetParentIsAwareOfCancellationPolicyChecked
export const getPatchedAppointmentData = (state: RootState) =>
  getTimetable(state).patchedAppointmentData
