import { gql } from '@apollo/client'

export const FETCH_BUSINESS_SOAP_WIDGETS = gql`
  query Business($id: UID!) {
    business(id: $id) {
      id
      soapWidgets {
        active
        soapWidgetId
        marketplaceId
        name
        nameTranslation
      }
    }
  }
`

export const FETCH_BUSINESS_SSO_IDP_SETTINGS = gql`
  query SsoIdpSettings {
    ssoIdpSettings {
      id
      ssoIdpDomain
      ssoIdpAppId
      ssoDefaultRoleId
    }
  }
`
