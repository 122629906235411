import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgThermometerIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      d="M18.563 5.25h-4.5m4.5 3h-4.5m4.5 3h-4.5m-7.126 2.068V6.375a1.875 1.875 0 0 1 3.75 0v6.943a3.375 3.375 0 1 1-3.75 0m2.625 2.807a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />,
    <circle cx={9} cy={16} fill="currentColor" r={1} />,
  )
export default SvgThermometerIcon
