import { gql } from '@apollo/client'

export const FETCH_AI_PROCESS_LOGS = gql`
  query AIAgentProcessLogs($input: AIAgentProcessLogInput!) {
    aiAgentProcessLogs(input: $input) {
      referenceEntity {
        entityId
        entityTypeId
        __typename
        ... on AIAgentAttachmentReferenceEntity {
          fileName
          fileExtension
          fileUrl
          uploadDate
        }
      }
      processStatuses {
        id
        processTypeId
        processStatusId
        creationDate
        modificationDate
      }
    }
  }
`
