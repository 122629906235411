import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import {
  useSaveWellnessPlanRenewalMappingsMutation,
  WellnessPlanVersionsDocument,
} from '~/api/graphql/generated/types'
import Stepper from '~/components/common/stepper/Stepper'
import i18n from '~/locales/i18n'
import { registerWarnAlert } from '~/store/duck/uiAlerts'
import { getApolloErrorMessage } from '~/utils/errors'

import {
  AutoRenewPlanLinking,
  AutoRenewPlanLinkingHandle,
} from './steps/plan-linking/AutoRenewPlanLinking'
import {
  PostActivateSteps,
  StepLabelsMap,
  Steps,
  StepsList,
} from './usePlanStepManager'
import { WellnessPlanVersionLayout } from './WellnessPlanVersionLayout'

export const LinkingWellnessPlansPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const [isPostActivateStepValid, setIsPostActivateStepValid] = useState(false)
  const stepComponentRef = useRef<AutoRenewPlanLinkingHandle>(null)

  const onStepValidStateChange = (isValid: boolean) => {
    setIsPostActivateStepValid(isValid)
  }

  const [saveWellnessPlanRenewalMappings, { loading }] =
    useSaveWellnessPlanRenewalMappingsMutation({
      refetchQueries: [
        {
          query: WellnessPlanVersionsDocument,
          variables: { limit: 100, offset: 0 },
        },
      ],
    })

  const handleFinish = () => {
    const input = stepComponentRef.current?.get()

    if (!input?.length) return
    saveWellnessPlanRenewalMappings({
      variables: {
        input,
      },
      onCompleted: () => {
        navigate(`/admin/catalog/wellness-plans/`)
      },
      onError: (error) => {
        dispatch(registerWarnAlert(getApolloErrorMessage(error)))
      },
    })
  }

  const getTooltipText = () => (
    <Box sx={{ maxWidth: 240 }}>
      {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.LINK_PLANS_AND_SAVE')}
    </Box>
  )

  const StepComponents = {
    [Steps.BRANDING]: {
      label: StepLabelsMap[Steps.BRANDING],
      disabled: true,
      tooltipText: i18n.t(
        'WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.LINK_PLANS_AND_SAVE',
      ),
    },
    [Steps.PLAN_BASICS]: {
      label: StepLabelsMap[Steps.PLAN_BASICS],
      disabled: true,
      tooltipText: getTooltipText(),
    },
    [Steps.PLAN_REVIEW]: {
      label: StepLabelsMap[Steps.PLAN_REVIEW],
      disabled: true,
      tooltipText: getTooltipText(),
    },
    [Steps.BASE]: {
      label: StepLabelsMap[Steps.BASE],
      disabled: true,
      tooltipText: getTooltipText(),
    },
    [Steps.CHARGE_SET_UP]: {
      label: StepLabelsMap[Steps.CHARGE_SET_UP],
      disabled: true,
      tooltipText: getTooltipText(),
    },
    [Steps.FINAL_REVIEW]: {
      label: StepLabelsMap[Steps.FINAL_REVIEW],
      disabled: true,
      tooltipText: getTooltipText(),
    },
    [PostActivateSteps.PLAN_LINKING]: {
      label: StepLabelsMap[PostActivateSteps.PLAN_LINKING],
    },
  }

  return (
    <WellnessPlanVersionLayout>
      <WellnessPlanVersionLayout.Header>
        <Stepper
          disableNavigationButtons
          activeStep={PostActivateSteps.PLAN_LINKING}
          components={StepComponents}
          id="wellness-plan-wizard-stepper"
          paddingMultiplier={0}
          steps={[...StepsList, PostActivateSteps.PLAN_LINKING]}
          onStepChange={() => {}}
        />
      </WellnessPlanVersionLayout.Header>
      <WellnessPlanVersionLayout.Body>
        <AutoRenewPlanLinking
          ref={stepComponentRef}
          onValidStateChange={onStepValidStateChange}
        />
      </WellnessPlanVersionLayout.Body>
      <WellnessPlanVersionLayout.Footer>
        <Tooltip
          title={
            isPostActivateStepValid ? undefined : (
              <Box sx={{ maxWidth: 230 }}>
                {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.FINISH_TOOLTIP')}
              </Box>
            )
          }
        >
          <Box>
            <ButtonWithLoader
              disabled={!isPostActivateStepValid}
              loading={loading}
              onClick={handleFinish}
            >
              {t('Common:FINISH_ACTION')}
            </ButtonWithLoader>
          </Box>
        </Tooltip>
      </WellnessPlanVersionLayout.Footer>
    </WellnessPlanVersionLayout>
  )
}
