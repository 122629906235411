import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import * as R from 'ramda'
import {
  CityTextField,
  CountryCode,
  CountrySelect,
  PermissionArea,
  PhoneUtils,
  PuiTextField,
  RegionUtils,
  StateSelect,
  Text,
  TextWithTooltip,
  TimezoneSelect,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import TimezoneWarningLabel from '~/components/dashboard/alerts/TimezoneWarningLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { BaseRoute } from '~/constants/routes'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getTimezoneGroups,
  getTimezones,
} from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeGeneralInformationFields,
} from '~/types'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import CountiesEditableList from '../CountiesEditableList'

const getCountiesValue = (value?: string[]) => {
  if (!value || value?.length === 0) {
    return ['']
  }

  return value
}

const PracticeGeneralInformation = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const { t } = useTranslation(['Common', 'Businesses'])

  const Timezones = useSelector(getTimezones)
  const TimezoneGroups = useSelector(getTimezoneGroups)
  const isBusinessPhoneReliantOnLocationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BUSINESS_PHONE_NUMBER_RELY_ON_LOCATION),
  )

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isSpain = business.countryCatalogCode === CountryCode.ES

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'phone',
        label: `${t('Common:PHONE_NUMBER')}*`,
        validators: ['phoneRequired', 'phone'],
        initialValue: business.phone || '',
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email'],
        initialValue: business.email || '',
      },
      {
        name: 'website',
        label: t('Common:WEBSITE'),
        validators: ['website'],
        initialValue: business.website || '',
      },
      {
        name: 'address',
        label: t('Common:PRACTICE_ADDRESS'),
        validators: ['required'],
        initialValue: business.address || '',
      },
      {
        name: 'suite',
        label: t('Common:SUITE_NUMBER'),
        validators: [],
        initialValue: business.suite || '',
      },
      {
        name: 'country',
        validators: ['required'],
        initialValue: RegionUtils.getAvailableCountry(business.country),
      },
      {
        name: 'city',
        validators: ['required'],
        initialValue: business.city || '',
      },
      {
        name: 'state',
        type: 'select',
        validators: ['required'],
        initialValue: business.state || '',
      },
      {
        name: 'zipcode',
        label: t('Common:ZIP_CODE'),
        validators: ['required', 'zip'],
        initialValue: business.zipcode || '',
      },
      {
        name: 'timeZone',
        label: t('Common:TIMEZONE'),
        type: 'select',
        validators: ['required'],
        initialValue: business.timeZone || '',
      },
      {
        name: 'clientsCommunicationEnabled',
        label: t('Businesses:GENERAL_INFORMATION.ENABLE_SMS_COMMUNICATIONS'),
        type: 'toggle',
        initialValue: Boolean(business.clientsCommunicationEnabled),
      },
      {
        name: 'counties',
        label: t('Common:COUNTY'),
        initialValue: getCountiesValue(business.counties),
      },
      {
        name: 'cif',
        label: t('Businesses:GENERAL_INFORMATION.CIF'),
        initialValue: business.taxIdentificationNumber,
      },
    ],
    false,
  )

  const {
    phone,
    email,
    website,
    address,
    suite,
    country,
    city,
    state,
    zipcode,
    timeZone,
    clientsCommunicationEnabled,
    counties,
    cif,
  } = fields

  usePracticeFieldsSection<PracticeGeneralInformationFields>({
    business,
    fields,
    parsedFields: {
      email: email.value,
      website: website.value,
      address: address.value,
      suite: suite.value,
      country: country.value,
      city: city.value,
      state: state.value,
      zipcode: zipcode.value,
      timeZone: timeZone.value,
      clientsCommunicationEnabled: clientsCommunicationEnabled.value,
      phone: PhoneUtils.parsePhoneNumber(phone.value),
      counties: counties.value.filter(Boolean),
      taxIdentificationNumber: cif.value,
    },
    reset,
    sectionName: PracticeDetailsPanels.GENERAL_INFORMATION,
    validate,
  })

  useEffect(() => {
    if (business.country !== country.value) {
      zipcode.setValue('')
      state.setValue('')
    }
  }, [country.value])

  const getChangeTimezoneLink = () =>
    `${BaseRoute.PRACTICES}/${business.id}?panels=${PracticeDetailsPanels.GENERAL_INFORMATION}`

  const hasBusinessLocation = country.value && state.value && zipcode.value
  const userCanEnableClientCommuncation =
    !isBusinessPhoneReliantOnLocationEnabled ||
    (isBusinessPhoneReliantOnLocationEnabled && hasBusinessLocation)

  const [openGeneralInformationLocationDialog] = useDialog(
    DialogNames.GENERAL_INFORMATION_LOCATION,
  )
  const onClientsCommunicationChanged = () => {
    if (clientsCommunicationEnabled.value || userCanEnableClientCommuncation) {
      return clientsCommunicationEnabled.setValue(
        !clientsCommunicationEnabled.value,
      )
    }

    return openGeneralInformationLocationDialog({
      permissions,
      originalFields: {
        address,
        clientsCommunicationEnabled,
        suite,
        city,
        country,
        state,
        zipcode,
      },
    })
  }

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
        >
          <Grid item md={5}>
            <PhoneInput country={business.countryCatalogCode} field={phone} />
          </Grid>
          <Grid item md={7}>
            <PuiTextField
              disabled={!permissions.update}
              field={email}
              inputProps={{ maxLength: 100 }}
              label={email.label}
            />
          </Grid>
        </Grid>
        <Grid item>
          <PuiTextField
            disabled={!permissions.update}
            field={website}
            inputProps={{ maxLength: 100 }}
            label={website.label}
          />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid
          container
          item
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
        >
          <Grid item md={8}>
            <PuiTextField
              disabled={!permissions.update}
              field={address}
              inputProps={{ maxLength: 100 }}
              label={`${address.label}*`}
            />
          </Grid>
          <Grid item md={4}>
            <PuiTextField
              disabled={!permissions.update}
              field={suite}
              inputProps={{ maxLength: 100 }}
              label={suite.label}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end" spacing={isMobile ? 0 : 4}>
          <Grid item md={4} xs={12}>
            <CityTextField
              required
              country={country.value}
              disabled={!permissions.update}
              field={city}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <CountrySelect
              required
              disabled={!permissions.update}
              field={country}
            />
          </Grid>
          <Grid item md={3} xs={4}>
            <StateSelect
              required
              country={country.value}
              disabled={!permissions.update}
              field={state}
            />
          </Grid>
          <Grid item md={2} xs={4}>
            <PuiTextField
              InputProps={{
                inputComponent: ZipInput,
                inputProps: { country: country.value },
              }}
              disabled={!permissions.update}
              field={zipcode}
              label={`${zipcode.label}*`}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end">
          <Grid item md={5} xs={11}>
            <FormControl fullWidth>
              <InputLabel htmlFor="label-select-timezone-group">
                {timeZone.label}*
              </InputLabel>
              <TimezoneSelect
                useTimezoneGroupVariant
                timezoneGroups={TimezoneGroups}
                timezones={Timezones}
                value={timeZone.value}
                onChange={(event) => {
                  timeZone.setValue(event.target.value)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item mb={0.5} ml={1.5}>
            <TimezoneWarningLabel
              getChangeTimezoneLink={getChangeTimezoneLink}
            />
          </Grid>
        </Grid>
        <CountiesEditableList
          disabled={!permissions.update}
          field={counties}
          fieldLabel={counties.label}
        />
        <Grid container item>
          <Grid item>
            <PuiSwitch
              checked={clientsCommunicationEnabled.value}
              disabled={!permissions.update}
              label={
                <TextWithTooltip
                  allowWrap
                  tooltipText={t(
                    'Businesses:GENERAL_INFORMATION.IT_TURNED_ON_TEXT_MESSAGING_FEATURE_WILL_BE_ENABLED',
                  )}
                  variant="body"
                >
                  {clientsCommunicationEnabled.label}
                </TextWithTooltip>
              }
              onChange={onClientsCommunicationChanged}
            />
          </Grid>
          {business.communicationsPhone && (
            <Grid container item alignItems="center">
              <Text strong ml={6} variant="body">
                {t('Businesses:GENERAL_INFORMATION.OUTBOUND_SMS_PHONE_NUMBER')}
              </Text>
              <Text ml={4} variant="body2">
                {PhoneUtils.formatPhoneNumber(business.communicationsPhone)}
              </Text>
            </Grid>
          )}
          {isSpain && (
            <Grid container item>
              <Grid item md={4} xs={12}>
                <PuiTextField
                  disabled={!permissions.update}
                  field={cif}
                  label={cif.label}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default memo(PracticeGeneralInformation, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
