import React from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableDataTable, {
  ScrollableDataTableColumnHeader,
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import { Variation } from '~/types'

import VariationsTableRow from './VariationsTableRow'

export interface VariationsTableProps
  extends ScrollableDataTableProps<Variation> {
  isDrug: boolean
  variations: Variation[]
}

const VariationsTable = ({
  variations,
  isDrug,
  ...rest
}: VariationsTableProps) => {
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const headerRow = [
    {
      label: t('Common:PER_PACKAGE'),
      subLabels: [{ label: t('Common:AMOUNT') }, { label: t('Common:UNITS') }],
    },
    { label: t('Common:PACKAGE') },
    {
      label: t('Common:CLIENT_PRICE'),
      subLabels: [
        { label: t('Common:NAME') },
        { label: t('Common:PRICE') },
        { label: t('Common:UNITS') },
      ],
    },
    { label: t('Common:MINIMUM_CHARGE') },
    { label: t('Common:DISPENSING_FEE') },
    { label: t('Common:ALLOW_DISCOUNT') },
    { label: t('Common:TAXABLE') },
    isDrug && { label: t('Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.NUMBER') },
    { label: t('Common:ACTIVE_ONE') },
    { label: t('Common:ON-HAND') },
    { label: t('Common:FORM_NOUN') },
    { label: t('Common:DRUG_DELIVERY_METHOD') },
    {
      label: t('Common:DRUG_STRENGTH'),
      subLabels: [{ label: t('Common:AMOUNT') }, { label: t('Common:UNITS') }],
    },
    { label: t('Common:DRUG_STRENGTH_COLOR') },
    { label: t('Common:INPUTS.WEIGHT_RANGE_INPUT.WEIGHT_RANGE') },
    { label: t('Common:FLAVOR') },
  ].filter(Boolean) as ScrollableDataTableColumnHeader[]

  return (
    <ScrollableDataTable
      data={variations}
      getNameColumnHeight={(index) => {
        const pricesCount = variations[index]?.prices?.length || 1
        return 52 + (pricesCount - 1) * 36 + (index === 0 ? 1 : 0)
      }}
      headerRow={headerRow}
      {...rest}
    >
      {({ item: variation, tableCellClassName }) => (
        <VariationsTableRow
          isDrug={isDrug}
          tableCellClassName={tableCellClassName}
          variation={variation}
        />
      )}
    </ScrollableDataTable>
  )
}

export default VariationsTable
