import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  ButtonWithLoader,
  Nil,
  PuiAlert,
  PuiDialogProps,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 450,
      maxWidth: 600,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'MultiValidationErrorDialog' },
)

export interface MultiValidationErrorDialogProps extends PuiDialogProps {
  errors: string[] | Nil
  onBack: () => void
  onProceed: () => void
  proceedButtonActionName?: string
}

const MultiValidationErrorDialog = ({
  open,
  onBack,
  onClose,
  onProceed,
  errors,
  proceedButtonActionName,
  title,
}: MultiValidationErrorDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const validationErrorProceedButtonActionName =
    proceedButtonActionName || t('Common:SCHEDULE_ACTION')

  const onBackOrClose = () => {
    onBack()
    if (onClose) {
      onClose()
    }
  }

  const onProceedButton = () => {
    onProceed()
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiAlert
      aria-labelledby="validation-error-dialog"
      classes={{
        paper: classes.paper,
      }}
      content={
        <Grid container direction="column" width="100%">
          <Grid item pt={1}>
            <Text align="center" fontSize="1.4rem" variant="h3">
              {t('Common:MULTIPLE_CONFLICTS')}:
            </Text>
          </Grid>
          <Grid item pt={2} width="100%">
            {errors?.map((e) => (
              <Text align="left" fontSize="1.4rem" key={e} variant="body">
                • {e}
              </Text>
            ))}
          </Grid>
          <Grid item pt={2} width="100%">
            <Grid container justifyContent="center" width="100%">
              <Grid item mr="16px" width="136px">
                <ButtonWithLoader
                  fullWidth
                  color="secondary"
                  onClick={onBackOrClose}
                >
                  {t('Common:GO_BACK')}
                </ButtonWithLoader>
              </Grid>
              <Grid item width="200px">
                <ButtonWithLoader fullWidth onClick={onProceedButton}>
                  {t('Common:PROCEED_ACTION_ANYWAY', {
                    actionName: validationErrorProceedButtonActionName,
                  })}
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      iconType={AlertIconType.WARN}
      open={open}
      title={title}
      onClose={onBackOrClose}
    />
  )
}

export default MultiValidationErrorDialog
