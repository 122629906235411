import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TableFilter } from '@pbt/pbt-ui-components'

import { ShipmentIcon } from '~/components/elements/Icons/generated'
import { ShipmentStatus } from '~/constants/shipments'
import {
  getInventoryOrdersFilters,
  setInventoryOrderFilters,
} from '~/store/duck/inventoryOrders'
import { getInventoryShipmentStatus } from '~/store/reducers/constants'

import {
  OrdersTableQuickFilter,
  OrdersTableQuickFilterProps,
} from './OrdersTableQuickFilter'

const openInventoryOrderStatuses: string[] = [
  ShipmentStatus.ORDERED,
  ShipmentStatus.PARTIAL,
]

export const OrdersTableQuickFilters = () => {
  const { t } = useTranslation('Admin')
  const dispatch = useDispatch()
  const InventoryOrderStatuses = useSelector(getInventoryShipmentStatus)
  const inventoryOrderFilters = useSelector(getInventoryOrdersFilters)

  const onApplyInventoryOrderFilter = (value: TableFilter) => {
    dispatch(
      setInventoryOrderFilters({ ...inventoryOrderFilters, status: value }),
    )
  }

  const quickFilters: OrdersTableQuickFilterProps[] = [
    {
      key: 'open-orders-filter',
      defaultChecked: true,
      IconComponent: ShipmentIcon,
      constants: InventoryOrderStatuses,
      filterValue: inventoryOrderFilters.status?.value,
      quickFilterValue: openInventoryOrderStatuses,
      text: t('Admin:CATALOG.ORDERS.OPEN_ORDERS'),
      onApplyFilter: onApplyInventoryOrderFilter,
    },
  ]

  return (
    <>
      {quickFilters.map(({ key, ...quickFilterProps }) => (
        <OrdersTableQuickFilter key={key} {...quickFilterProps} />
      ))}
    </>
  )
}
