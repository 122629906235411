import * as R from 'ramda'

import { BodySystemState } from '~/constants/SOAPStates'
import { FindingState } from '~/types'

export interface FindingsGroup {
  ncFindings: FindingState[]
  onlFindings: FindingState[]
  wnlFindings: FindingState[]
}

export const groupFindings = (
  findingsState: Record<string, FindingState> | FindingState[],
): FindingsGroup => {
  const findingsMap = R.groupBy(R.prop('state'), Object.values(findingsState))

  return {
    ncFindings: findingsMap[BodySystemState.NC] || [],
    onlFindings: findingsMap[BodySystemState.ONL] || [],
    wnlFindings: findingsMap[BodySystemState.WNL] || [],
  }
}

export const prepareFindings = ({
  ncFindings,
  onlFindings,
  wnlFindings,
}: FindingsGroup) => {
  const [wnlFindingsWithNotes = [], wnlFindingsWithoutNotes = []] = R.pipe(
    R.sortBy(R.prop('fullName')),
    R.partition<FindingState>(({ notes }) => Boolean(notes)),
  )(wnlFindings)

  const hasWnlFindings = Boolean(wnlFindings.length)

  const wnlFindingsWithoutNotesName = wnlFindingsWithoutNotes
    .map(({ fullName, name }) => fullName || name)
    .join(', ')

  const onlFindingsSorted = R.sortBy(R.prop('fullName'), onlFindings)
  const hasOnlFindings = Boolean(onlFindings.length)

  const hasNcFindings = Boolean(ncFindings.length)
  const ncFindingsSorted = R.pipe(
    R.filter((finding: FindingState) => Boolean(finding.notes)),
    R.sortBy<FindingState>(R.prop('fullName')),
  )(ncFindings)

  const hasFindings = hasWnlFindings || hasOnlFindings || hasNcFindings

  return {
    hasWnlFindings,
    wnlFindingsWithNotes,
    wnlFindingsWithoutNotesName,
    onlFindingsSorted,
    hasOnlFindings,
    hasNcFindings,
    ncFindingsSorted,
    hasFindings,
  }
}
