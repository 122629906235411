import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, PuiSelect, SelectEntity } from '@pbt/pbt-ui-components'

import { WellnessPlanAutoRenew, WellnessPlanVersionAutoRenew } from '~/types'

import { WellnessPlanSelectItem } from './WellnessPlanSelectItem'

interface WellnessPlanSelectProps {
  field: Field
  onSelect: () => void
  plansList: string[]
  plansMap: Record<string, WellnessPlanAutoRenew>
  versionsMap: Record<string, WellnessPlanVersionAutoRenew>
}

export const WellnessPlanSelect = ({
  field,
  plansList,
  onSelect,
  plansMap,
  versionsMap,
}: WellnessPlanSelectProps) => {
  const { t } = useTranslation('WellnessPlans')

  useEffect(() => {
    onSelect()
  }, [field.value])

  return (
    <PuiSelect
      fullWidth
      Item={WellnessPlanSelectItem}
      TooltipProps={{ open: false }}
      field={field}
      items={
        plansList.map((key) => {
          const plan = plansMap[key]
          const version = versionsMap[plan.versionId]
          return {
            id: key,
            name: `${t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.VERSION')} ${version.versionNumber} | ${plan.name}`,
            active: version.active,
          }
        }) as unknown as SelectEntity[]
      }
      renderEmpty={false}
      renderSelectedValue={(key: string) => {
        const plan = plansMap[key]
        const version = versionsMap[plan.versionId]
        return (
          <WellnessPlanSelectItem
            active={version.active || false}
            name={`${t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.VERSION')} ${version.versionNumber} | ${plan.name}`}
          />
        )
      }}
    />
  )
}
