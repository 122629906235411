/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Skeleton } from '@mui/material'

import { StatusProps, StatusWarning } from '~/components/elements/Status/Status'

import { useNoShowText } from './useNoShowText'

interface NoShowAlertTextProps {
  plainText?: boolean
}

const ConditionalStatusWarning = ({
  condition,
  children,
}: StatusProps & { condition: boolean }) =>
  condition ? <StatusWarning>{children}</StatusWarning> : <>{children}</>

export const NoShowAlertText = ({
  plainText = false,
}: NoShowAlertTextProps) => {
  const noShowAlertText = useNoShowText()

  if (noShowAlertText === null) {
    return <Skeleton sx={{ mb: 1 }} variant="rectangular" width="100%" />
  }

  return (
    <ConditionalStatusWarning condition={!plainText}>
      {noShowAlertText}
    </ConditionalStatusWarning>
  )
}
