import React, { CSSProperties, ReactNode } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { css, Divider, ListSubheader, SelectChangeEvent } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import Typography from '../Typography/Typography'

const rightWidth = 28
const paddingLeft = 8

const StyledInputLabel = styled(InputLabel)`
  padding-left: ${paddingLeft}px;
  max-width: calc(100% - ${rightWidth + 1}px);

  &:not(.MuiInputLabel-shrink) {
    top: -9px;
    margin-left: 0;
    font-size: 1.4rem;
  }
`

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop: string) => prop !== 'borderlessWhenDisabled',
})<{ borderlessWhenDisabled: boolean }>`
  & .MuiSelect-outlined {
    color: ${({ theme }) => theme.colors.secondaryText};
    padding-bottom: 3px;
    padding-top: 20px;
    font-size: 1.4rem;
    padding-left: ${paddingLeft}px;
    position: relative;

    &::after {
      content: '';
      display: block;
      border-right: 1px solid rgba(0, 0, 0, 0.23);
      position: absolute;
      top: 0;
      bottom: 0;
      right: ${rightWidth}px;
    }
  }

  & .MuiSelect-iconOutlined {
    width: ${rightWidth - 2}px;
    height: ${rightWidth - 2}px;
    right: 1px;
    color: ${({ theme }) => theme.colors.grayGray2};
  }

  ${({ borderlessWhenDisabled, theme }) =>
    borderlessWhenDisabled
      ? css`
          &.Mui-disabled {
            & .MuiSelect-outlined {
              -webkit-text-fill-color: ${theme.colors.secondaryText};

              &::after {
                display: none;
              }
            }

            & .MuiSelect-iconOutlined {
              display: none;
            }
          }
        `
      : ''}
`

const useStyles = makeStyles(
  (theme) => ({
    groupedMenuItemsLabel: {
      color: theme.colors.lowAccentText,
      fontSize: '1.2rem',
    },
  }),
  { name: 'OutlinedSelect' },
)

export type GroupedOption<T> = {
  label: string
  options: T[]
}

export type OutlinedSelectProps<T> = {
  borderlessWhenDisabled?: boolean
  className?: string
  disabled?: boolean
  emptyValue?: ReactNode
  /** When using `renderOption`, add `getOptionLabel` to generate uniq `keys` */
  getOptionLabel?: (value: T) => string
  groupedOptions?: GroupedOption<T>[]
  id: string
  label: ReactNode
  onChange: (value: T) => void
  options?: T[]
  /** To allow multiline, add `style={{ whiteSpace: 'normal' }}` to the root element */
  renderOption?: (value: T) => ReactNode
  style?: CSSProperties
  value: T
}

export const OutlinedSelect = <T = any,>({
  borderlessWhenDisabled = false,
  className,
  disabled,
  emptyValue = <em>None</em>,
  getOptionLabel = (option: any) => option.label ?? option,
  id,
  label,
  onChange,
  options = [],
  groupedOptions = [],
  renderOption,
  style,
  value,
}: OutlinedSelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    onChange(event.target.value as T)
  }

  const classes = useStyles()

  return (
    <FormControl fullWidth className={className} style={style}>
      <StyledInputLabel id={`${id}-label`}>{label}</StyledInputLabel>
      <StyledSelect
        IconComponent={ExpandMoreRoundedIcon}
        borderlessWhenDisabled={borderlessWhenDisabled}
        disabled={disabled}
        id={id}
        label={label}
        labelId={`${id}-label`}
        notched={false}
        value={value}
        variant="outlined"
        onChange={handleChange}
      >
        {options.map((option) => {
          const optionLabel = getOptionLabel(option)

          const isEmpty = optionLabel === ''

          return (
            <MenuItem
              key={isEmpty ? 'empty' : optionLabel}
              value={option as any}
            >
              {isEmpty
                ? emptyValue
                : renderOption
                  ? renderOption(option)
                  : optionLabel}
            </MenuItem>
          )
        })}
        {groupedOptions.flatMap((group, groupIndex) => [
          <ListSubheader key={group.label}>
            <Typography.Paragraph className={classes.groupedMenuItemsLabel}>
              {group.label}
            </Typography.Paragraph>
          </ListSubheader>,
          ...group.options.map((option, optionIndex) => {
            const optionLabel = getOptionLabel(option)
            const isEmpty = optionLabel === ''
            return (
              <MenuItem
                key={
                  isEmpty
                    ? `empty-${groupIndex}-${optionIndex}`
                    : `${optionLabel}-${groupIndex}-${optionIndex}`
                }
                value={option as any}
              >
                {isEmpty
                  ? emptyValue
                  : renderOption
                    ? renderOption(option)
                    : optionLabel}
              </MenuItem>
            )
          }),
          groupIndex < groupedOptions.length - 1 && (
            <Divider key={`divider-${group.label}-divider`} />
          ),
        ])}
      </StyledSelect>
    </FormControl>
  )
}
