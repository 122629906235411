import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as R from 'ramda'
import { Field, Nil } from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import { WellnessPlanAutoRenew, WellnessPlanVersionAutoRenew } from '~/types'

import { WellnessPlanSelect } from '../../WellnessPlanSelect'

const StyledTableBodyCell = styled(TableCell)`
  border-right: ${({ theme }) => theme.constants.tableBorder};
  padding-top: 5px;
  padding-bottom: 5px;
`

interface AutoRenewingVersionRowProps {
  activePatients: number
  activePlans: string[]
  autoRenewInto: string | Nil
  field: Field
  isFirstPlanInVersion: boolean
  isGrey: boolean
  onPlanSelect: () => void
  planKey: string
  planName: string
  plansMap: Record<string, WellnessPlanAutoRenew>
  versionName: string
  versionPlansList: string[]
  versionsMap: Record<string, WellnessPlanVersionAutoRenew>
}

export const AutoRenewingVersionRow = ({
  versionName,
  planKey,
  planName,
  isFirstPlanInVersion,
  versionPlansList,
  activePatients,
  isGrey,
  field,
  onPlanSelect,
  activePlans,
  autoRenewInto,
  plansMap,
  versionsMap,
}: AutoRenewingVersionRowProps) => (
  <TableRow
    data-testid={planKey}
    key={planKey}
    sx={{
      backgroundColor: (theme) => (isGrey ? theme.colors.grayGray4 : 'none'),
    }}
  >
    {isFirstPlanInVersion ? (
      <>
        <StyledTableBodyCell
          rowSpan={versionPlansList.length}
          sx={{ width: 250, verticalAlign: 'top' }}
        >
          <Typography.H3>{versionName}</Typography.H3>
        </StyledTableBodyCell>
        <StyledTableBodyCell
          rowSpan={versionPlansList.length}
          sx={{ width: 150, verticalAlign: 'top' }}
        >
          <Typography.Paragraph>{activePatients}</Typography.Paragraph>
        </StyledTableBodyCell>
        <StyledTableBodyCell>
          <Typography.Link>{planName}</Typography.Link>
        </StyledTableBodyCell>
      </>
    ) : (
      <StyledTableBodyCell>
        <Typography.Link>{planName}</Typography.Link>
      </StyledTableBodyCell>
    )}
    <StyledTableBodyCell>
      <WellnessPlanSelect
        field={field}
        plansList={R.uniq(
          R.reject(R.isNil, [autoRenewInto, planKey, ...(activePlans || [])]),
        )}
        plansMap={plansMap}
        versionsMap={versionsMap}
        onSelect={onPlanSelect}
      />
    </StyledTableBodyCell>
  </TableRow>
)
