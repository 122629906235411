import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldObject } from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import { WellnessPlanAutoRenew, WellnessPlanVersionAutoRenew } from '~/types'

import { AutoRenewingVersionRow } from './AutoRenewingVersionRow'

interface VerifyAutoRenewingPlansTableProps {
  fields: FieldObject
  onPlanSelect: () => void
  plansMap: Record<string, WellnessPlanAutoRenew>
  versionsList: string[]
  versionsMap: Record<string, WellnessPlanVersionAutoRenew>
}

const StyledTableHeadCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
`

const createRowBackgroundColor = () => {
  let lastVersionId: string | null = null
  let isGrey = false

  return (currentVersionId: string): boolean => {
    if (currentVersionId !== lastVersionId) {
      isGrey = !isGrey
      lastVersionId = currentVersionId
    }
    return isGrey
  }
}

export const VerifyAutoRenewingPlansTable = ({
  plansMap,
  versionsList,
  versionsMap,
  fields,
  onPlanSelect,
}: VerifyAutoRenewingPlansTableProps) => {
  const { t } = useTranslation('WellnessPlans')

  const getRowBackgroundColor = createRowBackgroundColor()

  const activeVersion = versionsList.find(
    (id) => versionsMap[id]?.active,
  ) as string

  const activePlans = versionsMap[activeVersion]?.plans

  const renderVersionRows = () =>
    versionsList.map((versionId: string) => {
      const version = versionsMap[versionId]
      const versionPlansList = version.plans
      const isGrey = getRowBackgroundColor(versionId)

      return versionPlansList.map((planKey: string, index: number) => {
        const plan = plansMap[planKey]
        const isFirstPlanInVersion = index === 0

        return (
          <AutoRenewingVersionRow
            activePatients={version.activePatients || 0}
            activePlans={activePlans}
            autoRenewInto={plan.autoRenewInto}
            field={fields[planKey]}
            isFirstPlanInVersion={isFirstPlanInVersion}
            isGrey={isGrey}
            key={planKey}
            planKey={planKey}
            planName={plan.name}
            plansMap={plansMap}
            versionName={`${t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.VERSION')} ${version.versionNumber}`}
            versionPlansList={versionPlansList}
            versionsMap={versionsMap}
            onPlanSelect={onPlanSelect}
          />
        )
      })
    })

  return (
    <Table
      sx={{ minWidth: 1000, border: (theme) => theme.constants.tableBorder }}
    >
      <TableHead>
        <TableRow>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.VERSION')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.ACTIVE_PATIENTS')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.PLAN')}
            </Typography.H3>
          </StyledTableHeadCell>
          <StyledTableHeadCell>
            <Typography.H3 color="grayGray2">
              {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.AUTO_RENEW_INFO')}
            </Typography.H3>
          </StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>{renderVersionRows()}</TableBody>
    </Table>
  )
}
