import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupItem,
  Nil,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { fetchGlobalItem } from '~/store/actions/globalInventoryCatalog'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getGlobalInventoryItem,
  getIsItemLoading,
} from '~/store/reducers/globalInventoryCatalog'
import { GlobalInventoryCatalogItem, Variation } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import {
  toggleSelectedCustomCompoundState,
  toggleSelectedVariationsState,
} from '../inventoryUtils'
import { GlobalItemSelectorVariationList } from './GlobalItemSelectorVariationList'
import ListItem from './ListItem'

const useStyles = makeStyles(
  (theme) => ({
    variationProgress: {
      margin: '0 auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    infoIcon: {
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    controlButton: {
      padding: theme.spacing(0.5),
    },
    listItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: 500,
    },
    listItemContainerSelected: {
      borderBottom: theme.constants.tabBorder,
    },
    variationsHeader: {
      paddingLeft: parseInt(theme.spacing(1), 10) + 2,
    },
    variationItem: {
      marginLeft: parseInt(theme.spacing(4), 10) - 2,
    },
  }),
  { name: 'GlobalItemSelectorItem' },
)

interface GlobalItemSelectorItemProps {
  buttons: ControlButtonGroupItem[]
  isSelected?: boolean
  itemId: string
  onItemChange: (itemId: GlobalInventoryCatalogItem | Nil) => void
  rawSearchTerm?: string
  selectedVariationsMap: Record<string, Variation[]>
  setSelectedVariationsMap: (variationsMap: Record<string, Variation[]>) => void
}

const GlobalItemSelectorItem = ({
  itemId,
  isSelected,
  buttons,
  rawSearchTerm,
  onItemChange,
  selectedVariationsMap,
  setSelectedVariationsMap,
}: GlobalItemSelectorItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const item = useSelector(getGlobalInventoryItem(itemId))
  const isItemLoading = useSelector(getIsItemLoading)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const variations = item?.variations || []

  const showToolTip =
    isSelected &&
    variations.some(
      ({ businessVariationIds }) =>
        businessVariationIds && businessVariationIds.length,
    )

  const updateSelectedItemOnFetched = useCloseAfterCreation(() => {
    onItemChange(item)
  }, getIsItemLoading)

  const onItemClick = () => {
    setSelectedVariationsMap({})
    if (isSelected) {
      onItemChange(undefined)
    } else {
      onItemChange(item)
      updateSelectedItemOnFetched()
      dispatch(fetchGlobalItem(itemId))
    }
  }

  const toggleCustomCompound = (newCustomCompoundId: string) => {
    const updatedVariationsMap = toggleSelectedCustomCompoundState(
      selectedVariationsMap,
      { id: newCustomCompoundId },
    )

    setSelectedVariationsMap(updatedVariationsMap)
  }

  const toggleSelectedVariations = (
    variation: Variation,
    variationName: string,
  ) => {
    const updatedVariationsMap = toggleSelectedVariationsState(
      selectedVariationsMap,
      variation,
      variationName,
      isFoodCatalogEnabled,
    )

    setSelectedVariationsMap(updatedVariationsMap)
  }

  return (
    <Grid
      container
      className={classNames({
        [classes.listItemContainerSelected]: isSelected,
      })}
      direction="column"
    >
      <ListItem
        className={classes.listItem}
        icons={
          <ControlButtonGroup
            buttons={showToolTip ? buttons : []}
            classes={{
              iconButton: classes.controlButton,
              infoIcon: classes.infoIcon,
            }}
          />
        }
        isLoading={!item}
        isSelected={isSelected}
        key={itemId}
        name={item?.name || ''}
        rawSearchTerm={rawSearchTerm}
        onClick={onItemClick}
      />
      {isSelected && isItemLoading && (
        <CircularProgress className={classes.variationProgress} size={20} />
      )}
      {!R.isEmpty(variations) && isSelected && !isItemLoading && item && (
        <GlobalItemSelectorVariationList
          item={item}
          selectedVariationsMap={selectedVariationsMap}
          toggleCustomCompound={toggleCustomCompound}
          variations={variations}
          onSelectVariation={toggleSelectedVariations}
        />
      )}
    </Grid>
  )
}

export default GlobalItemSelectorItem
