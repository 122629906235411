import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material'
import { Constant } from '@pbt/pbt-ui-components'
import {
  ClientAndPatient as ClientAndPatientIcon,
  Communications as CommunicationsIcon,
  Estimates as EstimatesIcon,
  Print as PrintIcon,
  Sms as SmsIcon,
  Whiteboard as WhiteboardIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { BaseRoute } from '~/constants/routes'
import i18n from '~/locales/i18n'
import { createSOAP } from '~/store/actions/soap'
import { patchAppointment } from '~/store/actions/timetable'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
  getWidgetDataTotalCount,
} from '~/store/duck/landing'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getAppointmentsWidgetAssignedMode,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getSOAPisLoading } from '~/store/reducers/soap'
import {
  getMultipleTimetableEvents,
  getTimetableIsLoading,
} from '~/store/reducers/timetable'
import { TimetableEvent, WidgetColumn } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

import AppointmentTypeCell from '../AppointmentTypeCell'
import ClientAndPatientCell from '../ClientAndPatientCell'
import DateGroupingCell from '../DateGroupingCell'
import DateRangeCell from '../DateRangeCell'
import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import NoRecords from '../NoRecords'
import StatusSelectActionPopper from '../StatusSelectActionPopper'
import TableWidget from '../TableWidget'
import useEnrichGroupedItemsByDate from '../useEnrichGroupedItemsByDate'
import { getVisibleWidgetColumns } from '../widgetUtils'

const columns: WidgetColumn<TimetableEvent>[] = [
  {
    id: 'dateGrouping',
    prop: (item) => <DateGroupingCell title={item?.dateTitle} />,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'dateRange',
    component: DateRangeCell,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 2,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 2,
      [WidgetWidthType.HALF_WIDTH]: 2,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:TIME'),
  },
  {
    id: 'clientAndPatient',
    component: ClientAndPatientCell,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 2,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 2,
      [WidgetWidthType.HALF_WIDTH]: 2,
      [WidgetWidthType.THIRD_WIDTH]: 2.5,
    },
    label: i18n.t('Common:CLIENT_AND_PATIENT'),
  },
  {
    id: 'notes',
    prop: 'notes',
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:NOTES'),
  },
  {
    id: 'appointmentType',
    component: AppointmentTypeCell,
    width: 1.5,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:APPOINTMENT_TYPE'),
  },
  {
    id: 'spaceName',
    prop: (item) => item.space?.name,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1,
      [WidgetWidthType.THIRD_WIDTH]: 1,
    },
    label: i18n.t('Common:SPACE_ONE'),
  },
  {
    id: 'appointmentState',
    prop: (item) => <AppointmentStateLabel item={item} variant="small" />,
    width: 1,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:STATE'),
  },
]

interface AdmittedWidgetProps extends LandingWidgetProps<TimetableEvent> {
  onExpand: () => void
  rowsCount?: number
  widthType?: WidgetWidthType
}

const AdmittedWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  onExpand,
  ...rest
}: AdmittedWidgetProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Landing'])

  const AppointmentsWidgetAssignedMode = useSelector(
    getAppointmentsWidgetAssignedMode,
  )
  const appointmentIds = useSelector(
    getWidgetData(LandingType.LANDING_DASHBOARD, LandingWidgetName.ADMITTED),
  )
  const totalCount = useSelector(
    getWidgetDataTotalCount(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.ADMITTED,
    ),
  )
  const widgetDataIsLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.ADMITTED,
    ),
  )
  const appointments = useSelector(getMultipleTimetableEvents(appointmentIds))
  const soapIsLoading = useSelector(getSOAPisLoading)
  const appointmentsIsLoading = useSelector(getTimetableIsLoading)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const isLoading =
    widgetDataIsLoading || soapIsLoading || appointmentsIsLoading

  const appointmentsList = useEnrichGroupedItemsByDate({
    dateProp: 'scheduledStartDatetime',
    titleProp: 'dateTitle',
    items: appointments,
  })

  const isDymoConnectEnabled = useIsDymoConnectEnabled()

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPrintInvoiceDialog] = useDialog(DialogNames.PRINT_INVOICE)
  const [openPrintCageLabelDialog] = useDialog(DialogNames.PRINT_CAGE_LABEL)
  const [openPrintFolderLabelDialog] = useDialog(DialogNames.PRINT_FOLDER_LABEL)
  const [openNewConversationDialog] = useDialog(
    DialogNames.NEW_CONVERSATION_WITH_STEPS,
  )
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const openInvoice = useOpenInvoice(null, openInvoiceDialog)

  const goToSoap = (item: TimetableEvent) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  const handleNavigateToItemDetails = goToSoap

  const handleGoToSoap = goToSoap

  const handleCreateSoap = (item?: TimetableEvent) => {
    if (item?.id) {
      dispatch(
        createSOAP(item.id, isPatientSharingEnabled ? item.businessId : null),
      )
    }
  }

  const handleOpenInvoice = (item: TimetableEvent) => {
    openInvoice({
      clientId: item.clientId,
      patientId: item.patientId,
      invoiceId: item.invoiceId,
      soapId: item.soapId,
    })
  }

  const handlePrintInvoice = (item: TimetableEvent) =>
    openPrintInvoiceDialog({
      invoiceIds: item.invoiceId ? [item.invoiceId] : [],
    })

  const handleGoToPatient = (item?: TimetableEvent) => {
    if (item) {
      navigate(`/client/${item.clientId}/patient/${item.patientId}`)
    }
  }

  const handlePrintCageCard = (item?: TimetableEvent) => {
    if (item) {
      openPrintCageLabelDialog({
        clientId: item.clientId,
        patientId: item.parentId,
      })
    }
  }
  const handlePrintFolderLabel = (item?: TimetableEvent) => {
    if (item) {
      openPrintFolderLabelDialog({
        clientId: item.clientId,
        patientId: item.parentId,
      })
    }
  }

  const navigateToConversation = (id?: string) => {
    if (id) {
      navigate(`${BaseRoute.COMMUNICATIONS}/${id}`)
    }
  }

  const handleCreateNewConversation = (item?: TimetableEvent) => {
    if (item) {
      openNewConversationDialog({
        clientId: item.clientId,
        patientId: item.parentId,
        appointmentId: item.id,
      })
    }
  }

  const handleOpenConversation = ({ conversationId }: TimetableEvent) =>
    navigateToConversation(conversationId)

  const refreshAppointmentWidgets = () => {
    dispatch(
      fetchWidgetsData(
        [LandingWidgetName.ADMITTED, LandingWidgetName.UPCOMING],
        {
          quiet: false,
          landingType: LandingType.LANDING_DASHBOARD,
        },
      ),
    )
  }

  const refreshAfterSave = useCloseAfterCreation(
    refreshAppointmentWidgets,
    getTimetableIsLoading,
  )

  const handleStatusChange = (value: Constant, item?: TimetableEvent) => {
    if (item?.id) {
      dispatch(patchAppointment({ id: item.id, state: value.id }))
      refreshAfterSave()
    }
  }

  const handleCreateAppointment = () => {
    openAppointmentDialog()
  }

  const handleNavigateToScheduler = () => navigate('/scheduler')

  const getActions = (item?: TimetableEvent) =>
    [
      {
        id: 'soapAndAppointment',
        isGroup: true,
        items: [
          item?.soapId
            ? {
                id: 'goToSoap',
                label: t('Common:GO_TO_SOAP'),
                Icon: ArrowForwardIcon,
                onClick: () => handleGoToSoap(item),
              }
            : {
                id: 'createSoap',
                label: t('Common:CREATE_SOAP'),
                Icon: AddIcon,
                onClick: () => handleCreateSoap(item),
              },
          {
            id: 'appointmentStatus',
            label: t('Common:APPOINTMENT_STATUS'),
            Icon: WhiteboardIcon,
            subItem: StatusSelectActionPopper,
            onClick: (value: any) => handleStatusChange(value, item),
          },
        ],
      },
      item?.invoiceId && {
        id: 'invoice',
        title: t('Common:INVOICE'),
        isGroup: true,
        items: [
          {
            id: 'openInvoice',
            label: t('Common:OPEN_INVOICE'),
            Icon: EstimatesIcon,
            onClick: () => handleOpenInvoice(item),
          },
          {
            id: 'printInvoice',
            label: t('Common:PRINT_INVOICE'),
            Icon: PrintIcon,
            onClick: () => handlePrintInvoice(item),
          },
        ],
      },
      {
        id: 'patient',
        title: t('Common:PATIENT'),
        isGroup: true,
        items: [
          {
            id: 'goToPatientDetailsInvoice',
            label: t('Common:GO_TO_PATIENT_DETAILS'),
            Icon: ClientAndPatientIcon,
            onClick: () => handleGoToPatient(item),
          },
          {
            id: 'printCageCardLabel',
            label: t('Common:PRINT_CAGE_CARD_LABEL'),
            Icon: PrintIcon,
            onClick: () => handlePrintCageCard(item),
          },
          isDymoConnectEnabled && {
            id: 'printFolderLabel',
            label: t('Common:PRINT_FOLDER_LABEL'),
            Icon: PrintIcon,
            onClick: () => handlePrintFolderLabel(item),
          },
        ],
      },
      {
        id: 'client',
        title: t('Common:CLIENT'),
        isGroup: true,
        items: [
          item?.conversation?.id
            ? {
                id: 'openConversation',
                label: t('Common:OPEN_CONVERSATION'),
                Icon: SmsIcon,
                onClick: () => handleOpenConversation(item),
              }
            : {
                id: 'newConversation',
                label: t('Common:NEW_CLIENT_CONVERSATION'),
                Icon: CommunicationsIcon,
                onClick: () => handleCreateNewConversation(item),
              },
        ],
      },
    ] as PopperAction[]

  const visibleColumns = getVisibleWidgetColumns(columns, widthType)
  return (
    <LandingWidget
      showHeaderTitles
      assignedModes={AppointmentsWidgetAssignedMode}
      canNavigateToItemDetails={(item: TimetableEvent) => Boolean(item.soapId)}
      columns={visibleColumns}
      component={TableWidget}
      data={appointmentsList}
      getActions={getActions}
      isLoading={isLoading}
      navigateToDetailsTooltip={t('Common:GO_TO_SCHEDULER')}
      navigateToItemDetailsTooltip={t('Common:GO_TO_SOAP')}
      noRecords={
        <NoRecords
          action={t('Common:ADD_APPOINTMENT')}
          text={t('Landing:ADMITTED_WIDGET.NO_ADMITTED_PATIENTS')}
          onClick={handleCreateAppointment}
        />
      }
      rowsCount={rowsCount}
      totalCount={totalCount}
      onExpand={onExpand}
      onNavigateToDetails={handleNavigateToScheduler}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default AdmittedWidget
