import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Backdrop,
  Button,
  Drawer,
  Grid,
  Hidden,
  List,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { moment, Nil, Text } from '@pbt/pbt-ui-components'

// @ts-ignore
import UserDetailsMobile from '../UserDetailsMobile'
import LeftMenuListItem from './LeftMenuListItem'
// @ts-ignore
import { getSelectedItem } from './leftMenuUtils'
import { MenuItem } from './menuItem'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      top: theme.mixins.toolbar.minHeight,
      overflowX: 'hidden',
      bottom: 0,
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        top: 0,
        borderRight: 'none',
      },
      borderRight: `1px solid ${theme.colors.divider}`,
      scrollbarWidth: 'none', // firefox
      '&::-webkit-scrollbar': {
        // webkit
        display: 'none',
      },
    },
    drawer: {
      width: theme.constants.leftMenuExpandedWidth,
      flexShrink: 0,
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: theme.constants.leftMenuExpandedWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up('md')]: {
        width: theme.constants.leftMenuWidth,
      },
    },
    expandButton: {
      backgroundColor: theme.colors.grayGray4,
      height: 39,
      minHeight: 39,
      justifyContent: 'flex-end',
      padding: 0,
      minWidth: 0,
      borderRadius: 0,
    },
    backdropOpen: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.headerTopBar, 'above'),
    },
    backdropClose: {
      zIndex: theme.zIndex.base,
    },
    listContainer: {
      flex: 1,
    },
    mainListContainer: {
      minWidth: '100%',
      flexGrow: 1,
      maxHeight: 1000,
      overflow: 'hidden',
      transition: theme.transitions.create(['max-width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    mainListContainerCollapsed: {
      minWidth: 0,
      maxWidth: 0,
      transition: theme.transitions.create(['max-width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    middleListContainer: {
      flex: 1,
    },
    changingIcon: {
      pointerEvents: 'none',
    },
    list: {
      width: '100%',
    },
    subMenuContainer: {
      minWidth: '100%',
    },
    collapsedSubMenuText: {
      userSelect: 'none',
      textTransform: 'capitalize',
      marginTop: theme.spacing(2),
      writingMode: 'vertical-rl',
      color: 'rgba(60,56,56,0.4)',
      fontSize: '1.8rem',
    },
    collapsedSubMenuContainerVisible: {
      width: '100%',
      minWidth: '100%',
      opacity: 1,
      transition: theme.transitions.create(['opacity', 'width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    collapsedSubMenuContainerHidden: {
      width: 0,
      minWidth: 0,
      opacity: 0,
      transition: theme.transitions.create(['opacity', 'width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  { name: 'LeftMenu' },
)

export interface LeftMenuProps {
  menuConfig: MenuItem[]
  open: boolean
  setOpen: (value: boolean) => void
}

const LeftMenu = ({
  open,
  setOpen: onOpenChange,
  menuConfig,
}: LeftMenuProps) => {
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const { t } = useTranslation('Menu')
  const enteringScreenAnimationDuration =
    theme.transitions.duration.enteringScreen

  const selectedItem = getSelectedItem(menuConfig, location)
  const isInnerItemSelected = Boolean(selectedItem?.children)

  const [activeSubMenu, setActiveSubMenu] = useState<MenuItem | Nil>(
    isInnerItemSelected ? selectedItem : undefined,
  )
  const [closeTimeout, setCloseTimeout] = useState<
    ReturnType<typeof setTimeout> | ''
  >('')
  const [openTimeout, setOpenTimeout] = useState<
    ReturnType<typeof setTimeout> | ''
  >('')
  const [pinned, setPinned] = useState(false)
  const [lastStateChangedTime, setLastStateChangedTime] = useState<Date>()

  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen !== open) {
      setLastStateChangedTime(new Date())
    }

    onOpenChange(newOpen)
  }

  const isMobile = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  )
  const clearCloseTimeout = () => {
    clearTimeout(closeTimeout)
  }

  const clearOpenTimeout = () => {
    clearTimeout(openTimeout)
  }

  const clearTimeouts = () => {
    clearCloseTimeout()
    clearOpenTimeout()
  }

  useEffect(
    () =>
      // unmount
      clearTimeouts,
  )

  useEffect(() => {
    setActiveSubMenu(isInnerItemSelected ? selectedItem : undefined)
  }, [selectedItem])

  const handleInnerElementsResize = () => {
    // NOTE: hack for resizing canvas elements
    const MENU_ANIMATION_DURATION = 200

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, MENU_ANIMATION_DURATION)
  }

  const changeOpen = (isOpen: boolean) => {
    clearOpenTimeout()
    clearCloseTimeout()
    handleOpenChange(isOpen)
    if (!isOpen && selectedItem !== activeSubMenu) {
      setActiveSubMenu(isInnerItemSelected ? selectedItem : undefined)
    }
    handleInnerElementsResize()
  }

  const startCloseTimeout = () => {
    clearCloseTimeout()
    setCloseTimeout(setTimeout(changeOpen, 5000, false))
  }

  const startOpenTimeout = () => {
    clearOpenTimeout()
    setOpenTimeout(setTimeout(changeOpen, 600, true))
  }

  const onItemSelected = (item: MenuItem) => {
    if (isMobile && !item.children) {
      onOpenChange(false)
    }
  }

  const toggleInnerMenu = (item?: MenuItem) => {
    setActiveSubMenu(activeSubMenu ? undefined : item)
  }

  const onMenuMouseEnter = () => {
    clearCloseTimeout()
    startOpenTimeout()
  }

  const onMenuMouseLeave = () => {
    clearOpenTimeout()
    if (!pinned) {
      startCloseTimeout()
    }
  }

  const MenuDrawer = (
    <Drawer
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
      variant="permanent"
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={onMenuMouseEnter}
      onMouseLeave={onMenuMouseLeave}
    >
      <Hidden mdDown>
        <Button
          aria-label={t('Menu:LEFT_MENU.TOGGLE_MENU')}
          className={classes.expandButton}
          title=""
          onClick={() => {
            const menuIsOpening =
              open &&
              moment(moment()).diff(lastStateChangedTime, 'millisecond') <=
                enteringScreenAnimationDuration

            // prevent closing menu when it is opening
            if (!menuIsOpening) {
              clearTimeouts()
              changeOpen(!open)
            }

            setPinned(!open || menuIsOpening)
          }}
        >
          {open ? (
            <ChevronLeftIcon
              className={classes.changingIcon}
              color="secondary"
            />
          ) : (
            <ChevronRightIcon
              className={classes.changingIcon}
              color="secondary"
            />
          )}
        </Button>
      </Hidden>
      <Hidden mdUp>
        <UserDetailsMobile open={open} />
      </Hidden>
      <Grid container item className={classes.listContainer} direction="column">
        <Grid
          container
          item
          className={classes.middleListContainer}
          wrap="nowrap"
        >
          <Grid
            container
            item
            className={classNames(classes.mainListContainer, {
              [classes.mainListContainerCollapsed]: Boolean(activeSubMenu),
            })}
          >
            <List className={classes.list}>
              {menuConfig.map((item) => (
                <LeftMenuListItem
                  item={item}
                  key={item.id}
                  open={open || isMobile}
                  onClick={() => {
                    if (item.children) {
                      if (open || !isInnerItemSelected) {
                        toggleInnerMenu(item)
                      }
                      changeOpen(true)
                    }
                  }}
                  onItemSelected={onItemSelected}
                />
              ))}
            </List>
          </Grid>
          <Grid container item wrap="nowrap">
            {activeSubMenu && (
              <Grid
                container
                item
                className={classNames({
                  [classes.collapsedSubMenuContainerVisible]:
                    !activeSubMenu || (!open && !isMobile),
                  [classes.collapsedSubMenuContainerHidden]:
                    activeSubMenu && (open || isMobile),
                })}
                justifyContent="center"
              >
                <Text className={classes.collapsedSubMenuText}>
                  {t('Menu:LEFT_MENU.ACTIVE_SUB_MENU', {
                    subMenu: activeSubMenu?.text,
                  })}
                </Text>
              </Grid>
            )}
            <List className={classes.subMenuContainer}>
              <LeftMenuListItem
                paging
                item={activeSubMenu as MenuItem}
                open={open || isMobile}
                onClick={() => toggleInnerMenu()}
              />
              {activeSubMenu &&
                activeSubMenu.children &&
                activeSubMenu.children.map((subMenuItem: MenuItem) => (
                  <LeftMenuListItem
                    item={subMenuItem}
                    key={subMenuItem.id}
                    open={open || isMobile}
                    onClick={() => {
                      if (subMenuItem.children) {
                        changeOpen(true)
                      }
                    }}
                    onItemSelected={onItemSelected}
                  />
                ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )

  return isMobile ? (
    <Backdrop
      classes={{
        root: classes.backdropOpen,
        invisible: classes.backdropClose,
      }}
      invisible={!open}
      open={open}
      onClick={() => changeOpen(false)}
    >
      {MenuDrawer}
    </Backdrop>
  ) : (
    MenuDrawer
  )
}

export default LeftMenu
