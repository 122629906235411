import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { Contact, ContactsMap, ReferredByContact } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_CONTACTS = 'contacts/FETCH_CONTACTS'
export const FETCH_CONTACTS_SUCCESS = 'contacts/FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'contacts/FETCH_CONTACTS_FAILURE'

export const FETCH_CONTACT = 'contacts/FETCH_CONTACT'
export const FETCH_CONTACT_SUCCESS = 'contacts/FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILURE = 'contacts/FETCH_CONTACT_FAILURE'

export const FETCH_MORE_ITEMS_FOR_CONTACTS =
  'contacts/FETCH_MORE_ITEMS_FOR_CONTACTS'
export const FETCH_MORE_ITEMS_FOR_CONTACTS_FAILURE =
  'contacts/FETCH_MORE_ITEMS_FOR_CONTACTS_FAILURE'
export const FETCH_MORE_ITEMS_FOR_CONTACTS_SUCCESS =
  'contacts/FETCH_MORE_ITEMS_FOR_CONTACTS_SUCCESS'

export const CREATE_CONTACT = 'contacts/CREATE_CONTACT'
export const CREATE_CONTACT_SUCCESS = 'contacts/CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'contacts/CREATE_CONTACT_FAILURE'

export const UPDATE_CONTACTS = 'contacts/UPDATE_CONTACTS'

export const EDIT_CONTACT = 'contacts/EDIT_CONTACT'
export const EDIT_CONTACT_SUCCESS = 'contacts/EDIT_CONTACT_SUCCESS'
export const EDIT_CONTACT_FAILURE = 'contacts/EDIT_CONTACT_FAILURE'

export const DELETE_CONTACT = 'contacts/DELETE_CONTACT'
export const DELETE_CONTACT_SUCCESS = 'contacts/DELETE_CONTACT_SUCCESS'
export const DELETE_CONTACT_FAILURE = 'contacts/DELETE_CONTACT_FAILURE'

export const FETCH_REFERRED_BY_CONTACTS = 'contacts/FETCH_REFERRED_BY_CONTACTS'
export const FETCH_REFERRED_BY_CONTACTS_SUCCESS =
  'contacts/FETCH_REFERRED_BY_CONTACTS_SUCCESS'
export const FETCH_REFERRED_BY_CONTACTS_FAILURE =
  'contacts/FETCH_REFERRED_BY_CONTACTS_FAILURE'

export const CLEAR_CONTACTS = 'contacts/CLEAR_CONTACTS'

export const fetchContacts = (from?: number, to?: number, query?: string) => ({
  type: FETCH_CONTACTS,
  from,
  to,
  query,
})
export const fetchContactsSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_CONTACTS_SUCCESS,
  list,
  totalCount,
})

export const fetchContactsFailure = (error: ApiError) => ({
  type: FETCH_CONTACTS_FAILURE,
  error,
})

export const fetchContact = (contactId: string) => ({
  type: FETCH_CONTACT,
  contactId,
})
export const fetchContactSuccess = (contactId: string) => ({
  type: FETCH_CONTACT_SUCCESS,
  contactId,
})
export const fetchContactFailure = (error: ApiError) => ({
  type: FETCH_CONTACT_FAILURE,
  error,
})

export const fetchMoreItemsForContacts = (
  from: number,
  to: number,
  query: string,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONTACTS,
  from,
  to,
  query,
})
export const fetchMoreItemsForContactsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONTACTS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForContactsFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_CONTACTS_FAILURE,
  error,
})

export const createContact = (contact: Contact) => ({
  type: CREATE_CONTACT,
  contact,
})
export const createContactSuccess = (contactId: string) => ({
  type: CREATE_CONTACT_SUCCESS,
  contactId,
})
export const createContactFailure = (error: ApiError) => ({
  type: CREATE_CONTACT_FAILURE,
  error,
})

export const updateContacts = (contacts: ContactsMap) => ({
  type: UPDATE_CONTACTS,
  contacts,
})

export const editContact = (contact: Contact) => ({
  type: EDIT_CONTACT,
  contact,
})
export const editContactSuccess = (contactId: string) => ({
  type: EDIT_CONTACT_SUCCESS,
  contactId,
})
export const editContactFailure = (error: ApiError) => ({
  type: EDIT_CONTACT_FAILURE,
  error,
})

export const deleteContact = (contactId: string) => ({
  type: DELETE_CONTACT,
  contactId,
})
export const deleteContactSuccess = (contactId: string) => ({
  type: DELETE_CONTACT_SUCCESS,
  contactId,
})
export const deleteContactFailure = (error: ApiError) => ({
  type: DELETE_CONTACT_FAILURE,
  error,
})

export const fetchReferredByContacts = (from?: number, to?: number) => ({
  type: FETCH_REFERRED_BY_CONTACTS,
  from,
  to,
})
export const fetchReferredByContactsSuccess = (
  contacts: ReferredByContact[],
) => ({
  type: FETCH_REFERRED_BY_CONTACTS_SUCCESS,
  contacts,
})
export const fetchReferredByContactsFailure = (error: ApiError) => ({
  type: FETCH_REFERRED_BY_CONTACTS_FAILURE,
  error,
})

export const clearContacts = () => ({ type: CLEAR_CONTACTS })

export type ContactsState = {
  error: string | null
  isCreating: boolean
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  map: ContactsMap
  referredBy: ReferredByContact[]
  referredByTotalCount: number
  totalCount: number
}

export const CONTACTS_INITIAL_STATE: ContactsState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  referredBy: [],
  referredByTotalCount: 0,
  isFetching: false,
  isFetchingList: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isLoading: false,
  error: null,
}

export const contactsReducer = (
  state: ContactsState = CONTACTS_INITIAL_STATE,
  action: AnyAction,
): ContactsState => {
  switch (action.type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
      }
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_CONTACTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_MORE_ITEMS_FOR_CONTACTS:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_CONTACTS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case FETCH_MORE_ITEMS_FOR_CONTACTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_CONTACT:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
      }
    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
      }
    case CREATE_CONTACT:
      return { ...state, isCreating: true }
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        isCreating: false,
      }
    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isCreating: false,
      }
    case UPDATE_CONTACTS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.contacts),
      }
    case EDIT_CONTACT:
      return { ...state, isUpdating: true }
    case EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case EDIT_CONTACT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isUpdating: false,
      }
    case DELETE_CONTACT:
      return { ...state, isDeleting: true }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        map: R.omit([action.contactId], state.map),
        list: R.without([action.contactId], state.list),
        isDeleting: false,
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case DELETE_CONTACT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isDeleting: false,
      }
    case FETCH_REFERRED_BY_CONTACTS:
      return {
        ...state,
        referredBy: [],
        isFetchingList: true,
      }
    case FETCH_REFERRED_BY_CONTACTS_SUCCESS:
      return {
        ...state,
        referredBy: action.contacts,
        isFetchingList: false,
      }
    case FETCH_REFERRED_BY_CONTACTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isFetchingList: false,
      }
    case CLEAR_CONTACTS:
      return {
        ...state,
        map: {},
        list: [],
        totalCount: 0,
      }
    default:
      return state
  }
}

export const getContacts = (state: RootState): ContactsState => state.contacts
export const getContactsList = (state: RootState) => getContacts(state).list
export const getContactsMap = (state: RootState) => getContacts(state).map
export const getReferredByContacts = (state: RootState) =>
  getContacts(state).referredBy
export const getContactsIsLoading = (state: RootState) =>
  getContacts(state).isLoading
export const getContactsIsFetching = (state: RootState) =>
  getContacts(state).isFetching
export const getContactsIsCreating = (state: RootState) =>
  getContacts(state).isCreating
export const getContactsIsUpdating = (state: RootState) =>
  getContacts(state).isUpdating
export const getContactsIsDeleting = (state: RootState) =>
  getContacts(state).isDeleting
export const getContactsIsFetchingList = (state: RootState) =>
  getContacts(state).isFetchingList
export const getContactsTotalCount = (state: RootState) =>
  getContacts(state).totalCount
export const getContact = (id: string | Nil) =>
  createSelector(getContactsMap, (map) => (id ? map[id] : undefined))
export const getMultipleContacts = (ids: string[]) =>
  createSelector(getContactsMap, (map) => R.props(ids, map))
