import * as R from 'ramda'
import { Business as BusinessType, Field } from '@pbt/pbt-ui-components'

import {
  isEmptyMember,
  PersonBusinessRole,
  PersonDto,
} from '~/store/dto/Person'

export type OptionalIdBusinessType = Omit<BusinessType, 'id'> & {
  id?: string
  isGroup?: boolean
}
export type OptionalFieldsPersonDto = Omit<
  PersonDto,
  'id' | 'businessRoles'
> & {
  businessRoles?: PersonBusinessRole[]
  businesses?: { businessIndex: number; jobId: string | Field }[]
  id?: string
}

export const serializeRegistrationData = ({
  manager,
  businesses,
  members,
}: {
  businesses: Record<string, OptionalIdBusinessType>
  manager: OptionalFieldsPersonDto
  members: Record<string, OptionalFieldsPersonDto>
}) => {
  const data: {
    businesses?: OptionalIdBusinessType[]
    members?: OptionalFieldsPersonDto[]
    person?: OptionalFieldsPersonDto
  } = {
    person: undefined,
    businesses: undefined,
    members: undefined,
  }
  data.person = { ...manager }
  data.person.businesses = []
  delete data.person.id
  data.person.mobilePhone = data?.person?.phone?.replace(/\s/g, '')
  delete data.person.phone
  data.businesses = Object.values(businesses).map((business) => {
    const result = { ...business }
    result.phone = result?.phone?.replace(/\s/g, '')
    delete result.id
    return result
  })
  const businessesKeys = Object.keys(businesses)
  if (businessesKeys.length === 1) {
    data.businesses[0].isGroup = true
  }
  data.members = Object.values(members)
    .filter((member) => !isEmptyMember(member as PersonDto))
    .map((member) => {
      const result = { ...member }
      delete result.id
      delete result.businessRoles
      result.businesses = member?.businessRoles?.map(
        ({ businessId, jobId }) => ({
          businessIndex: businessesKeys.indexOf(businessId),
          jobId,
        }),
      )
      return result
    })
  return data
}

export const serializeRegistrationByInviteData = (person: PersonDto) => {
  const result: {
    avatar: string
    country: string
    dea: string
    email: string
    firstName: string
    lastName: string
    mobilePhone?: string
    password: string
    phone?: string
    pims: string
  } = R.pick(
    [
      'avatar',
      'country',
      'dea',
      'email',
      'firstName',
      'lastName',
      'phone',
      'password',
      'pims',
    ],
    person,
  )
  result.mobilePhone = result.phone
  delete result.phone
  return result
}
