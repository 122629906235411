import { gql } from '@apollo/client/core'

const PROCESSING_REFUNDS_FRAGMENT = gql`
  fragment ProcessingRefundsFields on ClientBillingSummary {
    processingRefunds {
      amount
      count
    }
  }
`

export const FETCH_CLIENT_BILLING_ACTIVITY = (
  includeProcessingRefunds: boolean,
) => gql`
  ${includeProcessingRefunds ? PROCESSING_REFUNDS_FRAGMENT : ''}
  query ClientBillingActivity($clientId: UID!) {
    client(id: $clientId) {
      id
      billingActivitySummary {
        balance
        deposits {
          amount
          count
        }
        unappliedPayments {
          amount
          count
        }
        pendingRefunds {
          amount
          count
        }
        unpaidInvoices {
          amount
          count
        }
        ${includeProcessingRefunds ? `...ProcessingRefundsFields` : ''}
      }
    }
  }
`
