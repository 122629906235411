import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import {
  AdminCourseType,
  RxInput,
  RxPrescriptionOrigin,
  RxType,
} from '~/api/graphql/generated/types'
import { PrescriptionType } from '~/constants/prescription'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import {
  GetPrescriptionType,
  Order,
  Prescription,
  PrescriptionDrugInfo,
  PrescriptionV2,
} from '~/types'

import { nullOrValue, numberOrNull } from './index'

export const getPrescriptionName = (
  prescription: Order | Prescription | Nil,
  isFoodCatalogEnabled: boolean,
) =>
  prescription?.name ||
  prescription?.inventory?.name ||
  prescription?.[
    isFoodCatalogEnabled ? 'globalInventoryMapping' : 'globalInventory'
  ]?.name ||
  prescription?.drugMasterName

export const getIsCreatedPrescriptionChewyRx = (
  prescriptionType: PrescriptionType | Nil,
) => prescriptionType === PrescriptionType.CHEWY

export const getIsCreatedPrescriptionChewyReactiveRx = (
  prescriptionType: PrescriptionType | Nil,
  origin: RxPrescriptionOrigin | Nil,
) =>
  getIsCreatedPrescriptionChewyRx(prescriptionType) &&
  (R.isNil(origin) || origin === RxPrescriptionOrigin.Customer)

export const getIsCreatedPrescriptionChewyActiveRx = (
  prescriptionType: PrescriptionType | Nil,
  origin: RxPrescriptionOrigin | Nil,
) =>
  getIsCreatedPrescriptionChewyRx(prescriptionType) &&
  origin === RxPrescriptionOrigin.Vet

export const getWorkflowType = (workflow: PrescriptionWorkflowType | Nil) => ({
  isOrder: workflow === PrescriptionWorkflowType.ORDER,
  isInHouseRx: workflow === PrescriptionWorkflowType.PRESCRIBE,
  isOutsidePharmacy: workflow === PrescriptionWorkflowType.OUTSIDE_PHARMACY,
  isChewyReactiveRx: workflow === PrescriptionWorkflowType.CHEWY_REACTIVE_RX,
  isChewyActiveRx: workflow === PrescriptionWorkflowType.CHEWY_ACTIVE_RX,
})

export const getPrescriptionType = (
  type: PrescriptionType | Nil,
  origin?: RxPrescriptionOrigin | Nil,
) => ({
  isChewyActiveRx: getIsCreatedPrescriptionChewyActiveRx(type, origin),
  isChewyReactiveRx: getIsCreatedPrescriptionChewyReactiveRx(type, origin),
  isInHouse: type === PrescriptionType.IN_HOUSE,
  isScriptOut: type === PrescriptionType.SCRIPT_OUT,
})

const getPrescriptionsList = ({
  isChewyActiveRx,
}: {
  isChewyActiveRx: boolean
}) => [
  {
    prescriptionType: PrescriptionType.CHEWY,
    prescriptionWorkflowType: isChewyActiveRx
      ? PrescriptionWorkflowType.CHEWY_ACTIVE_RX
      : PrescriptionWorkflowType.CHEWY_REACTIVE_RX,
    prescriptionTypeLabel: isChewyActiveRx
      ? i18n.t('Common:CHEWY_BRAND_NAME')
      : i18n.t('Common:CHEWY_RX_REQUEST'),
  },
  {
    prescriptionType: PrescriptionType.IN_HOUSE,
    prescriptionWorkflowType: PrescriptionWorkflowType.PRESCRIBE,
    prescriptionTypeLabel: i18n.t('Common:PRESCRIPTION'),
  },
  {
    prescriptionType: PrescriptionType.SCRIPT_OUT,
    prescriptionWorkflowType: PrescriptionWorkflowType.OUTSIDE_PHARMACY,
    prescriptionTypeLabel: i18n.t('Common:OUTSIDE_PHARMACY'),
  },
]

const findPrescriptionByType = ({
  prescriptionType: prescriptionTypeParam,
  origin,
}: GetPrescriptionType) =>
  getPrescriptionsList({
    isChewyActiveRx: origin === RxPrescriptionOrigin.Vet,
  }).find(({ prescriptionType }) => prescriptionType === prescriptionTypeParam)

export const getPrescriptionTypeLabel = ({
  prescriptionType,
  origin,
}: GetPrescriptionType): string =>
  findPrescriptionByType({ prescriptionType, origin })?.prescriptionTypeLabel ||
  i18n.t('Common:PRESCRIPTION')

export const getPrescriptionWorkflow = ({
  orderType,
  prescriptionType,
  origin,
}: GetPrescriptionType & {
  orderType: OrderType
}): PrescriptionWorkflowType => {
  if (orderType === OrderType.INVENTORY) {
    return PrescriptionWorkflowType.ORDER
  }
  return (
    findPrescriptionByType({ prescriptionType, origin })
      ?.prescriptionWorkflowType || PrescriptionWorkflowType.PRESCRIBE
  )
}

export const getPrescriptionTypeByWorkflow = (
  workflow: PrescriptionWorkflowType | Nil,
  origin?: RxPrescriptionOrigin | Nil,
): PrescriptionType | undefined =>
  getPrescriptionsList({
    isChewyActiveRx: origin === RxPrescriptionOrigin.Vet,
  })?.find(
    ({ prescriptionWorkflowType }) => prescriptionWorkflowType === workflow,
  )?.prescriptionType

export const getPrescriptionWorkflowType = ({
  orderType,
  prescriptionType,
  origin,
}: GetPrescriptionType & { orderType: OrderType }) =>
  getWorkflowType(
    getPrescriptionWorkflow({ orderType, prescriptionType, origin }),
  )

export const convertOrderToRxInput = (
  prescription: Prescription,
  isFoodCatalogEnabled: boolean,
  autoshipUnitId?: string | Nil,
  autoshipFrequency?: number | Nil,
): RxInput => ({
  administrationCourseLength: numberOrNull(
    prescription.administrationCourseLength,
  ),
  administrationCourseType:
    prescription.administrationCourseType as unknown as AdminCourseType,
  administrationFrequencyId: nullOrValue(
    prescription.administrationFrequencyId,
  ),
  administrationFrequencyNotes: prescription.administrationFrequencyNotes,
  administrationInstructionsAutofill: nullOrValue(
    prescription.administrationInstructionsAutofill,
  ),
  administrationQuantity: numberOrNull(prescription.administrationQuantity),
  administrationQuantityCustomUnit: nullOrValue(
    prescription.administrationQuantityCustomUnit,
  ),
  administrationQuantityUnitId: nullOrValue(
    prescription.administrationQuantityUnitId,
  ),
  administrationRouteId: nullOrValue(prescription.administrationRouteId),
  autoshipFrequency: nullOrValue(autoshipFrequency),
  autoshipUnitId: nullOrValue(autoshipUnitId),
  businessId: nullOrValue(prescription.businessId),
  clientId: nullOrValue(prescription.clientId),
  // @ts-ignore
  clientTasks: nullOrValue(prescription.clientTasks),
  compoundingReason: prescription.compoundingReason,
  compoundingReasonId: nullOrValue(prescription.compoundingReasonId),
  deleted: nullOrValue(prescription.deleted),
  discussedWithClient: prescription.discussedWithClient,
  doctorId: nullOrValue(prescription.doctorId),
  eventId: nullOrValue(prescription.eventId),
  globalFoodCatalogId: nullOrValue(prescription.globalFoodCatalogId),
  globalFoodCatalogVariationId: nullOrValue(
    prescription.globalFoodCatalogVariationId,
  ),
  globalInventoryId: nullOrValue(prescription.globalInventoryId),
  globalVariationId: nullOrValue(prescription.globalVariationId),
  ...(isFoodCatalogEnabled
    ? {
        globalInventoryMappingId: nullOrValue(
          prescription.globalInventoryMappingId,
        ),
        globalVariationMappingId: nullOrValue(
          prescription.globalVariationMappingId,
        ),
      }
    : {}),
  id: nullOrValue(prescription.id),
  price: nullOrValue(prescription.price),
  inventoryId: nullOrValue(prescription.inventoryId),
  logId: numberOrNull(prescription.logId),
  notes: prescription.notes,
  numberRefills: numberOrNull(prescription.quantityNumbRefills),
  orderNumber: nullOrValue(prescription.orderNumber),
  parentId: nullOrValue(prescription.parentId),
  partNumber: nullOrValue(prescription.partNumber),
  patientId: nullOrValue(prescription.patientId),
  pdfUrl: nullOrValue(prescription.pdfUrl),
  quantity: numberOrNull(prescription.quantity),
  quantityDrugExpirationDate: nullOrValue(
    prescription.quantityDrugExpirationDate,
  ),
  quantityPerRefill: nullOrValue(prescription.quantityPerRefill),
  quantityPerRefillCustomUnit: nullOrValue(
    prescription.quantityPerRefillCustomUnit,
  ),
  quantityPerRefillUnitId: nullOrValue(prescription.quantityPerRefillUnitId),
  quantityRefillExpirationDate: nullOrValue(
    prescription.quantityRefillExpirationDate,
  ),
  quantityUnitId: nullOrValue(prescription.quantityUnitId),
  refillCancelDate: nullOrValue(prescription.refillCancelDate),
  refillExpirationDate: nullOrValue(prescription.quantityRefillExpirationDate),
  refillNotes: nullOrValue(prescription.refillNotes),
  refillRemains: nullOrValue(prescription.refillRemains),
  rejectionReason: prescription.rejectionReason,
  signedDate: nullOrValue(prescription.signedDate),
  soapId: nullOrValue(prescription.soapId),
  storeDefault: nullOrValue(prescription.storeDefault),
  totalQuantity: nullOrValue(prescription.totalQuantity),
  totalQuantityCustomUnit: nullOrValue(prescription.totalQuantityCustomUnit),
  totalQuantityUnitId: nullOrValue(prescription.totalQuantityUnitId),
  variationId: nullOrValue(prescription.variationId),
})

export const convertPrescriptionV2ToPrescription = (
  prescription: PrescriptionV2 | undefined,
): Prescription | undefined => {
  if (!prescription) {
    return prescription
  }

  const drugInfo: PrescriptionDrugInfo | undefined = !prescription.drugInfo
    ? undefined
    : {
        customCompound: prescription.drugInfo.customCompound,
        customForm: prescription.drugInfo.customForm,
        deliveryMethodId: prescription.drugInfo.deliveryMethod?.id,
        description: prescription.drugInfo.description,
        flavor: prescription.drugInfo.flavor,
        formId: prescription.drugInfo.form?.id,
        strength: prescription.drugInfo.strength,
        strengthColorId: prescription.drugInfo.strengthColor?.id,
        strengthUnitsId: prescription.drugInfo.strengthUnits?.id,
        perPackageAmount: prescription.drugInfo.perPackageAmount,
        perPackageUnitsId: prescription.drugInfo.perPackageUnits?.id,
        packageTypeId: prescription.drugInfo.packageType?.id,
        count: prescription.drugInfo.count,
        compounded: prescription.drugInfo.compounded,
      }

  return {
    ...prescription,
    administrationFrequencyId: prescription.administrationFrequency?.id,
    administrationQuantityUnitId: prescription.administrationQuantityUnit?.id,
    administrationRouteId: prescription.administrationRoute?.id,
    clientId: prescription.client?.id,
    compoundingReasonId: prescription.compoundingReasonState?.id,
    drugInfo,
    drugMasterName: prescription.drugInfo?.masterName,
    globalVariationMappingId: prescription.globalVariationMapping?.id,
    patientId: prescription.patient?.id,
    // @ts-ignore
    prescriptionType: prescription.type,
    quantityDrugExpirationDate: prescription.drugExpirationDate,
    quantityNumbRefills: prescription.numberRefills || 0,
    quantityPerRefillUnitId: prescription.quantityPerRefillUnit?.id,
    quantityRefillExpirationDate: prescription.refillExpirationDate,
    stateId: prescription.state?.id,
    totalQuantityUnitId: prescription.totalQuantityUnit?.id,
    type: OrderType.PRESCRIPTION,
  }
}

export const getRxType = (prescription: Prescription | Nil) => ({
  isVetDiet: prescription?.rxType === RxType.VetDiet,
  isRxPrescription: prescription?.rxType === RxType.MedicalPrescription,
})

export const getPrescriptionOrderId = (order: Order) =>
  (order.lineItem
    ? order.lineItem.logId
    : !R.isEmpty(order.logId)
      ? order.logId
      : undefined) ?? order.id

export const getPrescriptionGlobalInventoryId = (
  order: Prescription,
  isFoodCatalogEnabled: boolean,
) => {
  const globalInventoryId = order.globalInventory?.id || order.globalInventoryId
  const globalInventoryMappingId =
    order.globalInventoryMapping?.id || order.globalInventoryMappingId
  if (isFoodCatalogEnabled) {
    return globalInventoryMappingId || globalInventoryId
  }
  return globalInventoryId
}
