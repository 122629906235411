import { isEmpty } from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import { type RootState } from '~/store'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../../sagas/utils/requestAPI'
import { Duck } from '../interfaces/Duck'

const INITIAL_STATE = {
  isCatalogFilterLoading: false,
  isCatalogItemsLoading: false,
  catalogFilters: [],
  catalogItems: [],
  catalogTotalCount: undefined,
}

const mergeGroups = <T extends { id: any; items: any }>(
  list1: T[],
  list2: T[],
) => {
  const lastItemInFirstList = list1[list1.length - 1]
  const firstItemInSecondList = list2[0]

  if (isEmpty(list2)) {
    return list1
  }

  if (isEmpty(list1)) {
    return list2
  }

  if (!firstItemInSecondList) {
    return lastItemInFirstList
  }

  return lastItemInFirstList.id === firstItemInSecondList.id
    ? [
        ...list1.slice(0, list1.length - 1),
        {
          ...list1[list1.length - 1],
          items: [...list1[list1.length - 1].items, ...list2[0].items],
        },
        ...list2.slice(1),
      ]
    : [...list1, ...list2]
}

export function* fetchCatalogFiltersSaga(
  config: Record<string, any>,
  duck: Duck,
  { type }: { type: string },
): Generator {
  try {
    const filters = yield requestAPI(config.apiEndpoints[type])
    yield put(duck.actions.fetchCatalogFiltersSuccess(filters))
  } catch (error) {
    yield put(duck.actions.fetchCatalogFiltersFailure(error))
  }
}

export function* fetchCatalogItemsSaga(
  config: Record<string, any>,
  duck: Duck,
  {
    type,
    categories,
    listType,
    entityTypes,
    query,
    from,
    to,
  }: {
    categories: string[]
    entityTypes: string[]
    from: number
    listType: string
    query: string
    to: number
    type: string
  },
) {
  try {
    const { data: items, totalCount } = yield requestAPI(
      config.apiEndpoints[type],
      categories,
      listType,
      entityTypes,
      query,
      from,
      to,
    )

    yield put(duck.actions.fetchCatalogItemsSuccess(items, totalCount))
  } catch (error) {
    yield put(duck.actions.fetchCatalogItemsFailure(error))
  }
}

export function* fetchMoreCatalogItemsSaga(
  config: Record<string, any>,
  duck: Duck,
  {
    type,
    categories,
    listType,
    entityTypes,
    query,
    from,
    to,
  }: {
    categories: string[]
    entityTypes: string[]
    from: number
    listType: string
    query: string
    to: number
    type: string
  },
) {
  try {
    const { data: items, totalCount } = yield requestAPI(
      config.apiEndpoints[type],
      categories,
      listType,
      entityTypes,
      query,
      from,
      to,
    )
    yield put(duck.actions.fetchMoreCatalogItemsSuccess(items, totalCount))
  } catch (error) {
    yield put(duck.actions.fetchMoreCatalogItemsFailure(error))
  }
}

export default (config: Record<string, any>) => ({
  types: {
    FETCH_CATALOG_FILTERS: 'FETCH_CATALOG_FILTERS',
    FETCH_CATALOG_FILTERS_SUCCESS: 'FETCH_CATALOG_FILTERS_SUCCESS',
    FETCH_CATALOG_FILTERS_FAILURE: 'FETCH_CATALOG_FILTERS_FAILURE',
    FETCH_CATALOG_ITEMS: 'FETCH_CATALOG_ITEMS',
    FETCH_CATALOG_ITEMS_SUCCESS: 'FETCH_CATALOG_ITEMS_SUCCESS',
    FETCH_CATALOG_ITEMS_FAILURE: 'FETCH_CATALOG_ITEMS_FAILURE',
    FETCH_MORE_CATALOG_ITEMS: 'FETCH_MORE_CATALOG_ITEMS',
    FETCH_MORE_CATALOG_ITEMS_SUCCESS: 'FETCH_MORE_CATALOG_ITEMS_SUCCESS',
    FETCH_MORE_CATALOG_ITEMS_FAILURE: 'FETCH_MORE_CATALOG_ITEMS_FAILURE',
  },
  actions: (duck: Duck) => ({
    fetchCatalogFilters: () => ({ type: duck.types.FETCH_CATALOG_FILTERS }),
    fetchCatalogFiltersSuccess: (filters: any) => ({
      type: duck.types.FETCH_CATALOG_FILTERS_SUCCESS,
      filters,
    }),
    fetchCatalogFiltersFailure: (error: ApiError) => ({
      type: duck.types.FETCH_CATALOG_FILTERS_FAILURE,
      error,
    }),
    // eslint-disable-next-line max-params
    fetchCatalogItems: (
      categories: string[],
      listType: string,
      entityTypes: string[],
      query: string,
      from: number,
      to: number,
    ) => ({
      type: duck.types.FETCH_CATALOG_ITEMS,
      categories,
      listType,
      entityTypes,
      query,
      from,
      to,
    }),
    fetchCatalogItemsSuccess: <T = any>(items: T[], totalCount: number) => ({
      type: duck.types.FETCH_CATALOG_ITEMS_SUCCESS,
      items,
      totalCount,
    }),
    fetchCatalogItemsFailure: (error: ApiError) => ({
      type: duck.types.FETCH_CATALOG_ITEMS_FAILURE,
      error,
    }),
    // eslint-disable-next-line max-params
    fetchMoreCatalogItems: (
      categories: string[],
      listType: string,
      entityTypes: string[],
      query: string,
      from: number,
      to: number,
    ) => ({
      type: duck.types.FETCH_MORE_CATALOG_ITEMS,
      categories,
      listType,
      entityTypes,
      query,
      from,
      to,
    }),
    fetchMoreCatalogItemsSuccess: <T = any>(
      items: T[],
      totalCount: number,
    ) => ({
      type: duck.types.FETCH_MORE_CATALOG_ITEMS_SUCCESS,
      items,
      totalCount,
    }),
    fetchMoreCatalogItemsFailure: (error: ApiError) => ({
      type: duck.types.FETCH_MORE_CATALOG_ITEMS_FAILURE,
      error,
    }),
  }),
  reducer: (state = INITIAL_STATE, action: AnyAction, duck: Duck) => {
    switch (action.type) {
      case duck.types.FETCH_CATALOG_FILTERS:
        return {
          ...state,
          isCatalogFilterLoading: true,
          error: null,
        }
      case duck.types.FETCH_CATALOG_FILTERS_SUCCESS:
        return {
          ...state,
          isCatalogFilterLoading: false,
          catalogFilters: action.filters,
        }
      case duck.types.FETCH_CATALOG_FILTERS_FAILURE:
        return {
          ...state,
          isCatalogFilterLoading: false,
          error: getErrorMessage(action.error),
        }
      case duck.types.FETCH_CATALOG_ITEMS:
        return {
          ...state,
          isCatalogItemsLoading: true,
          error: null,
          catalogItems: [],
          catalogTotalCount: 0,
        }
      case duck.types.FETCH_CATALOG_ITEMS_SUCCESS:
        return {
          ...state,
          isCatalogItemsLoading: false,
          catalogItems: action.items,
          catalogTotalCount: action.totalCount,
        }
      case duck.types.FETCH_CATALOG_ITEMS_FAILURE:
        return {
          ...state,
          isCatalogItemsLoading: false,
          error: getErrorMessage(action.error),
        }
      case duck.types.FETCH_MORE_CATALOG_ITEMS:
        return {
          ...state,
          isCatalogItemsLoading: true,
          error: null,
        }
      case duck.types.FETCH_MORE_CATALOG_ITEMS_SUCCESS:
        return {
          ...state,
          isCatalogItemsLoading: false,
          catalogItems: mergeGroups(state.catalogItems, action.items),
          catalogTotalCount: action.totalCount,
        }
      case duck.types.FETCH_MORE_CATALOG_ITEMS_FAILURE:
        return {
          ...state,
          isCatalogItemsLoading: false,
          error: getErrorMessage(action.error),
        }
      default:
        return state
    }
  },
  selectors: {
    getCatalogFilters: (state: RootState) =>
      config.getReducer(state).catalogFilters,
    getCatalogItems: (state: RootState) =>
      config.getReducer(state).catalogItems,
    getCatalogItemsTotalCount: (state: RootState) =>
      config.getReducer(state).catalogTotalCount,
    getIsCatalogFilterLoading: (state: RootState) =>
      config.getReducer(state).isCatalogFilterLoading,
    getIsCatalogItemsLoading: (state: RootState) =>
      config.getReducer(state).isCatalogItemsLoading,
  },
  saga: (duck: Duck) =>
    function* saga(): Generator {
      yield all([
        yield takeLatest(
          duck.types.FETCH_CATALOG_FILTERS,
          fetchCatalogFiltersSaga,
          config,
          duck,
        ),
        yield takeLatest(
          duck.types.FETCH_CATALOG_ITEMS,
          fetchCatalogItemsSaga,
          config,
          duck,
        ),
        yield takeLatest(
          duck.types.FETCH_MORE_CATALOG_ITEMS,
          fetchMoreCatalogItemsSaga,
          config,
          duck,
        ),
      ])
    },
})
