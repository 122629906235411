import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  LanguageUtils,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { createPrice, editPrice } from '~/store/actions/procedures'
import { getCvcCumulusRhapsodyIntegrationEnabled } from '~/store/reducers/constants'
import {
  getAvailableSpeciesForProcedure,
  getProcedure,
  getProceduresIsLoading,
} from '~/store/reducers/procedures'
import { BasePrice } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import PriceComponent, { PriceHandle } from './Price'
import ReadOnlyPriceComponent, { ReadOnlyPriceHandle } from './ReadOnlyPrice'

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(3, 3, 4, 3),
    },
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      width: 144,
    },
  }),
  { name: 'PriceDialog' },
)

export interface PriceDialogProps extends BasePuiDialogProps {
  price?: BasePrice
  procedureId: string
}

const PriceDialog = ({
  price,
  procedureId,
  open,
  onClose,
}: PriceDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const procedure = useSelector(getProcedure(procedureId))
  const isLoading = useSelector(getProceduresIsLoading)
  const availableSpecies = useSelector(
    getAvailableSpeciesForProcedure(procedure?.id),
  )
  const isCvcCumulusRhapsodyIntegrationEnabled = useSelector(
    getCvcCumulusRhapsodyIntegrationEnabled,
  )

  const priceRef = useRef<PriceHandle | ReadOnlyPriceHandle>(null)
  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getProceduresIsLoading,
  )

  const save = () => {
    if (priceRef.current?.validate()) {
      const newPrice = priceRef.current?.get()
      setCloseAfterCreationOn()
      if (!price) {
        dispatch(createPrice(procedureId, newPrice))
      } else {
        dispatch(editPrice(procedureId, { ...newPrice, id: price.id }))
      }
    }
  }

  const hasUnsavedChanges = () => priceRef.current?.hasUnsavedChanges() ?? false

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={{
        onOk: save,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          onClick={save}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="price-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={hasUnsavedChanges}
      maxWidth="sm"
      open={open}
      title={t('Dialogs:PRICE_DIALOG.ADD_PRICE_FOR_PROCEDURE', {
        procedureName: LanguageUtils.getTranslatedFieldName(procedure),
      })}
      onClose={onClose}
    >
      {isCvcCumulusRhapsodyIntegrationEnabled && price ? (
        <ReadOnlyPriceComponent
          availableSpecies={availableSpecies}
          price={price}
          ref={priceRef}
        />
      ) : (
        <PriceComponent
          availableSpecies={availableSpecies}
          price={price}
          ref={priceRef}
        />
      )}
    </PuiDialog>
  )
}

export default PriceDialog
