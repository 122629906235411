export enum TeamFilter {
  ALL = 'ALL',
  MEMBER = 'MEMBER',
  TEAM = 'TEAM',
}

export type FetchMembersOptions = {
  businessId?: string
  from?: number
  includeInactive?: boolean
  includeInvitationStatus?: boolean
  noRolesInResult?: boolean
  roleIds?: string[]
  shorten?: boolean
  to?: number
}

export type TeamMemberLicense = {
  additionalInfo?: string
  expirationDate?: string
  id?: string
  issueDate?: string
  issuingAuthority?: string
  issuingState?: string
  number?: string
  rank?: string
  type?: string
  typeId?: string
}

export type BusinessRoleItem = {
  businessId: string
  roleId: string
}
