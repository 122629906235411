import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import { Membership } from '~/types'
import useDialog from '~/utils/useDialog'

interface NoPlanCellProps {
  membership: Partial<Membership>
}

const NoPlanCell = ({ membership }: NoPlanCellProps) => {
  const { t } = useTranslation('Common')

  const { create: wellnessPlanCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN),
  )

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)

  const signUp = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    openMembershipSignUpDialog({
      clientId: membership.clientId,
      patientId: membership.patient,
    })
  }

  if (!wellnessPlanCreatePermissions) {
    return null
  }

  return (
    <Grid container>
      <Link
        color="inherit"
        component="button"
        underline="always"
        variant="body1"
        onClick={signUp}
      >
        {t('Common:ADD_PLAN')}
      </Link>
    </Grid>
  )
}

export default NoPlanCell
