import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Nil, Utils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import ApiErrorTypes from '~/constants/apiErrorTypes'
import i18n from '~/locales/i18n'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import {
  LabOrder,
  LabOrderDevice,
  LabOrderLabTest,
  LabOrderOptions,
  LabVendorProvider,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  removeMultipleOrders as removeMultipleOrderLogs,
  updateMultipleOrders as updateMultipleOrderLogs,
} from '../actions/orders'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import {
  EMAIL_LAB_RESULT,
  EMAIL_LAB_RESULT_FAILURE,
  EMAIL_LAB_RESULT_SUCCESS,
} from '../actions/types/communications'
import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'
import { fetchLabTestDetails } from './labTestsDashboard'
import { registerTableAlert, registerWarnAlert } from './uiAlerts'

export const FETCH_LAB_ORDERS = 'labOrders/FETCH_LAB_ORDERS'
export const FETCH_LAB_ORDERS_SUCCESS = 'labOrders/FETCH_LAB_ORDERS_SUCCESS'
export const FETCH_LAB_ORDERS_FAILURE = 'labOrders/FETCH_LAB_ORDERS_FAILURE'

export const PLACE_LAB_ORDER = 'labOrders/PLACE_LAB_ORDER'
export const PLACE_LAB_ORDER_SUCCESS = 'labOrders/PLACE_LAB_ORDER_SUCCESS'
export const PLACE_LAB_ORDER_FAILURE = 'labOrders/PLACE_LAB_ORDER_FAILURE'

export const PLACE_ALL_LAB_ORDERS = 'labOrders/PLACE_ALL_LAB_ORDERS'
export const PLACE_ALL_LAB_ORDERS_SUCCESS =
  'labOrders/PLACE_ALL_LAB_ORDERS_SUCCESS'
export const PLACE_ALL_LAB_ORDERS_FAILURE =
  'labOrders/PLACE_ALL_LAB_ORDERS_FAILURE'

export const CANCEL_LAB_ORDER = 'labOrders/CANCEL_LAB_ORDER'
export const CANCEL_LAB_ORDER_SUCCESS = 'labOrders/CANCEL_LAB_ORDER_SUCCESS'
export const CANCEL_LAB_ORDER_FAILURE = 'labOrders/CANCEL_LAB_ORDER_FAILURE'
export const CANCEL_LAB_ORDER_ABOLISH = 'labOrders/CANCEL_LAB_ORDER_ABOLISH'

export const FINALIZE_LAB_ORDER = 'labOrders/FINALIZE_LAB_ORDER'
export const FINALIZE_LAB_ORDER_SUCCESS = 'labOrders/FINALIZE_LAB_ORDER_SUCCESS'
export const FINALIZE_LAB_ORDER_FAILURE = 'labOrders/FINALIZE_LAB_ORDER_FAILURE'

export const COMPLETE_LAB_ORDER = 'labOrders/COMPLETE_LAB_ORDER'
export const COMPLETE_LAB_ORDER_WITH_CALLBACK =
  'labOrders/COMPLETE_LAB_ORDER_WITH_CALLBACK'
export const COMPLETE_LAB_ORDER_SUCCESS = 'labOrders/COMPLETE_LAB_ORDER_SUCCESS'
export const COMPLETE_LAB_ORDER_FAILURE = 'labOrders/COMPLETE_LAB_ORDER_FAILURE'

export const FETCH_DEVICES = 'labOrders/FETCH_DEVICES'
export const FETCH_DEVICES_SUCCESS = 'labOrders/FETCH_DEVICES_SUCCESS'
export const FETCH_DEVICES_FAILURE = 'labOrders/FETCH_DEVICES_FAILURE'

export const CLEAR_LAB_ORDERS = 'labOrders/CLEAR_LAB_ORDERS'

export const CLEAR_DEVICES = 'labOrders/CLEAR_DEVICES'

export const GET_RESULT_FRAME_URL = 'labOrders/GET_RESULT_FRAME_URL'
export const GET_RESULT_FRAME_URL_SUCCESS =
  'labOrders/GET_RESULT_FRAME_URL_SUCCESS'
export const GET_RESULT_FRAME_URL_FAILURE =
  'labOrders/GET_RESULT_FRAME_URL_FAILURE'

export const CLEAR_RESULT_FRAME_URL = 'labOrders/CLEAR_RESULT_FRAME_URL'

export const FETCH_LAB_VENDOR_PROVIDERS = 'labOrders/FETCH_LAB_VENDOR_PROVIDERS'
export const FETCH_LAB_VENDOR_PROVIDERS_SUCCESS =
  'labOrders/FETCH_LAB_VENDOR_PROVIDERS_SUCCESS'
export const FETCH_LAB_VENDOR_PROVIDERS_FAILURE =
  'labOrders/FETCH_LAB_VENDOR_PROVIDERS_FAILURE'

export const SET_SOAPS_WITH_NO_DOCTORS = 'labOrders/SET_SOAPS_WITH_NO_DOCTORS'

export const SET_LOG_IDS_FOR_INVOICE_WITH_NO_PRODUCER =
  'labOrders/SET_LOG_IDS_FOR_INVOICE_WITH_NO_PRODUCER'

export const setSoapsWithNoDoctor = (soapIds: string[]) => ({
  type: SET_SOAPS_WITH_NO_DOCTORS,
  soapIds,
})

export const setLogIdsForInvoiceWithNoProducer = (logIds: string[]) => ({
  type: SET_LOG_IDS_FOR_INVOICE_WITH_NO_PRODUCER,
  logIds,
})

export const fetchLabOrders = (soapId: string | Nil, invoiceId?: string) => ({
  type: FETCH_LAB_ORDERS,
  soapId,
  invoiceId,
})
export const fetchLabOrdersSuccess = (labOrders: LabOrder[]) => ({
  type: FETCH_LAB_ORDERS_SUCCESS,
  labOrders,
})
export const fetchLabOrdersFailure = (error: ApiError) => ({
  type: FETCH_LAB_ORDERS_FAILURE,
  error,
})

export const placeLabOrder = (
  soapId: string | Nil,
  vendorId: string,
  labTests: LabOrderLabTest[],
  options: LabOrderOptions,
) => ({ type: PLACE_LAB_ORDER, soapId, vendorId, labTests, options })
export const placeLabOrderSuccess = (
  vendorId: string,
  labOrders: LabOrder[],
) => ({
  type: PLACE_LAB_ORDER_SUCCESS,
  vendorId,
  labOrders,
})
export const placeLabOrderFailure = (error: ApiError) => ({
  type: PLACE_LAB_ORDER_FAILURE,
  error,
})

export const placeAllLabOrders = (
  vendorId: string,
  labTests: LabOrderLabTest[],
  options: LabOrderOptions,
) => ({
  type: PLACE_ALL_LAB_ORDERS,
  vendorId,
  labTests,
  options,
})
export const placeAllLabOrdersSuccess = (
  vendorId: string,
  labOrders: LabOrder[],
) => ({
  type: PLACE_ALL_LAB_ORDERS_SUCCESS,
  vendorId,
  labOrders,
})
export const placeAllLabOrdersFailure = (error: ApiError) => ({
  type: PLACE_ALL_LAB_ORDERS_FAILURE,
  error,
})

export const cancelLabOrder = (vendorId: string, orderId: string) => ({
  type: CANCEL_LAB_ORDER,
  vendorId,
  orderId,
})
export const cancelLabOrderSuccess = (vendorId: string, orderId: string) => ({
  type: CANCEL_LAB_ORDER_SUCCESS,
  vendorId,
  orderId,
})
export const cancelLabOrderFailure = (error: ApiError | null) => ({
  type: CANCEL_LAB_ORDER_FAILURE,
  error,
})
export const cancelLabOrderAbolish = () => ({ type: CANCEL_LAB_ORDER_ABOLISH })

export const finalizeLabOrder = (
  vendorId: string | Nil,
  orderId: string,
  invoiceId?: string,
) => ({
  type: FINALIZE_LAB_ORDER,
  vendorId,
  orderId,
  invoiceId,
})
export const finalizeLabOrderSuccess = (
  vendorId: string | Nil,
  labOrder: LabOrder,
) => ({
  type: FINALIZE_LAB_ORDER_SUCCESS,
  vendorId,
  labOrder,
})
export const finalizeLabOrderFailure = (error: ApiError) => ({
  type: FINALIZE_LAB_ORDER_FAILURE,
  error,
})

export const completeLabOrder = (vendorId: string | Nil, orderId: string) => ({
  type: COMPLETE_LAB_ORDER,
  vendorId,
  orderId,
})
export const completeLabOrderWithCallback = (
  vendorId: string | Nil,
  orderId: string,
  completeOrderCallback: () => void,
) => ({
  type: COMPLETE_LAB_ORDER_WITH_CALLBACK,
  vendorId,
  orderId,
  completeOrderCallback,
})
export const completeLabOrderSuccess = (
  vendorId: string | Nil,
  labOrder: LabOrder,
) => ({
  type: COMPLETE_LAB_ORDER_SUCCESS,
  vendorId,
  labOrder,
})
export const completeLabOrderFailure = (error: ApiError) => ({
  type: COMPLETE_LAB_ORDER_FAILURE,
  error,
})

export const fetchDevices = (
  targetBusinessId?: string,
  idexxRefresh?: boolean,
) => ({
  type: FETCH_DEVICES,
  targetBusinessId,
  idexxRefresh,
})
export const fetchDevicesSuccess = (
  devices: Record<string, LabOrderDevice[]>,
) => ({
  type: FETCH_DEVICES_SUCCESS,
  devices,
})
export const fetchDevicesFailure = (error: ApiError) => ({
  type: FETCH_DEVICES_FAILURE,
  error,
})

export const clearLabOrders = () => ({ type: CLEAR_LAB_ORDERS })

export const clearDevices = () => ({ type: CLEAR_DEVICES })

export const fetchResultFrameUrl = (resultIdentifier: string) => ({
  type: GET_RESULT_FRAME_URL,
  resultIdentifier,
})
export const fetchResultFrameUrlSuccess = (frameUrl: string) => ({
  type: GET_RESULT_FRAME_URL_SUCCESS,
  frameUrl,
})
export const fetchResultFrameUrlFailure = (error: ApiError) => ({
  type: GET_RESULT_FRAME_URL_FAILURE,
  error,
})

export const clearResultFrameUrl = () => ({ type: CLEAR_RESULT_FRAME_URL })

export const fetchLabVendorProviders = () => ({
  type: FETCH_LAB_VENDOR_PROVIDERS,
})
export const fetchLabVendorProvidersSuccess = (
  providers: LabVendorProvider[],
) => ({
  type: FETCH_LAB_VENDOR_PROVIDERS_SUCCESS,
  providers,
})
export const fetchLabVendorProvidersFailure = (error: ApiError) => ({
  type: FETCH_LAB_VENDOR_PROVIDERS_FAILURE,
  error,
})

export type LabOrdersState = {
  devices: Record<string, LabOrderDevice[]>
  error: string | null
  isCancelingOrder: boolean
  isCompletingOrder: boolean
  isDevicesLoading: boolean
  isFinalizingOrder: boolean
  isOrdersLoading: boolean
  isPlacingOrder: boolean
  isProvidersLoading: boolean
  isSendingLabResult: boolean
  labOrders: LabOrder[]
  logsIdsForInvoiceWithNoProducer: string[]
  providers: LabVendorProvider[]
  resultsFrame: {
    frameUrl: string | null
    isLoading: boolean
  }
  soapsWishNoDoctor: string[]
}

export const INITIAL_STATE: LabOrdersState = {
  labOrders: [],
  devices: {},
  providers: [],
  isOrdersLoading: false,
  isPlacingOrder: false,
  isCancelingOrder: false,
  isFinalizingOrder: false,
  isCompletingOrder: false,
  isDevicesLoading: false,
  isProvidersLoading: false,
  isSendingLabResult: false,
  resultsFrame: {
    isLoading: false,
    frameUrl: null,
  },
  soapsWishNoDoctor: [],
  logsIdsForInvoiceWithNoProducer: [],
  error: null,
}

export const labOrdersReducer = (
  state: LabOrdersState = INITIAL_STATE,
  action: AnyAction,
): LabOrdersState => {
  switch (action.type) {
    case SET_SOAPS_WITH_NO_DOCTORS:
      return {
        ...state,
        soapsWishNoDoctor: action.soapIds,
      }
    case SET_LOG_IDS_FOR_INVOICE_WITH_NO_PRODUCER:
      return {
        ...state,
        logsIdsForInvoiceWithNoProducer: action.logIds,
      }
    case FETCH_LAB_ORDERS:
      return {
        ...state,
        error: null,
        isOrdersLoading: true,
      }
    case FETCH_LAB_ORDERS_SUCCESS:
      return {
        ...state,
        labOrders: (action.labOrders || []).map(
          ({ orders, ...rest }: LabOrder) => ({
            orders: orders || [],
            ...rest,
          }),
        ),
        isOrdersLoading: false,
      }
    case FETCH_LAB_ORDERS_FAILURE:
      return {
        ...state,
        isOrdersLoading: false,
        error: getErrorMessage(action.error),
      }
    case PLACE_ALL_LAB_ORDERS:
      return {
        ...state,
        error: null,
        isPlacingOrder: true,
      }
    case PLACE_ALL_LAB_ORDERS_SUCCESS:
      return {
        ...state,
        isPlacingOrder: false,
        labOrders: state.labOrders.map((labOrder) =>
          action.vendorId === labOrder.vendorId
            ? {
                ...labOrder,
                orders: [...labOrder.orders, ...(action.labOrders || [])],
              }
            : labOrder,
        ),
      }
    case PLACE_ALL_LAB_ORDERS_FAILURE:
      return {
        ...state,
        isPlacingOrder: false,
        error:
          action.error.status === 409 ? null : getErrorMessage(action.error),
      }
    case PLACE_LAB_ORDER:
      return {
        ...state,
        error: null,
        isPlacingOrder: true,
      }
    case PLACE_LAB_ORDER_SUCCESS:
      return {
        ...state,
        isPlacingOrder: false,
        labOrders: state.labOrders.map((vendor) =>
          action.vendorId === vendor.vendorId
            ? {
                ...vendor,
                orders: [...vendor.orders, ...(action.labOrders || [])],
              }
            : vendor,
        ),
      }
    case PLACE_LAB_ORDER_FAILURE:
      return {
        ...state,
        isPlacingOrder: false,
        error:
          action.error.status === 409 ? null : getErrorMessage(action.error),
      }
    case CANCEL_LAB_ORDER:
      return {
        ...state,
        error: null,
        isCancelingOrder: true,
      }
    case CANCEL_LAB_ORDER_SUCCESS:
      return {
        ...state,
        isCancelingOrder: false,
        labOrders: state.labOrders.map((vendor) =>
          action.vendorId === vendor.vendorId
            ? {
                ...vendor,
                orders: Utils.removeById(action.orderId, vendor.orders),
              }
            : vendor,
        ),
      }
    case CANCEL_LAB_ORDER_FAILURE:
      return {
        ...state,
        isCancelingOrder: false,
        error: action.error ? getErrorMessage(action.error) : null,
      }
    case CANCEL_LAB_ORDER_ABOLISH:
      return {
        ...state,
        error: null,
        isCancelingOrder: false,
      }
    case FINALIZE_LAB_ORDER:
      return {
        ...state,
        error: null,
        isFinalizingOrder: true,
      }
    case FINALIZE_LAB_ORDER_SUCCESS:
      return {
        ...state,
        isFinalizingOrder: false,
        labOrders: state.labOrders.map((vendor) =>
          action.vendorId === vendor.vendorId
            ? {
                ...vendor,
                orders: Utils.updateById(action.labOrder, vendor.orders),
              }
            : vendor,
        ),
      }
    case FINALIZE_LAB_ORDER_FAILURE:
      return {
        ...state,
        isFinalizingOrder: false,
        error: getErrorMessage(action.error),
      }
    case COMPLETE_LAB_ORDER:
    case COMPLETE_LAB_ORDER_WITH_CALLBACK:
      return {
        ...state,
        error: null,
        isCompletingOrder: true,
      }
    case COMPLETE_LAB_ORDER_SUCCESS:
      return {
        ...state,
        isCompletingOrder: false,
        labOrders: state.labOrders.map((vendor) =>
          action.vendorId === vendor.vendorId
            ? {
                ...vendor,
                orders: Utils.updateById(action.labOrder, vendor.orders),
              }
            : vendor,
        ),
      }
    case COMPLETE_LAB_ORDER_FAILURE:
      return {
        ...state,
        isCompletingOrder: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_DEVICES:
      return {
        ...state,
        error: null,
        isDevicesLoading: true,
      }
    case FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.devices,
        isDevicesLoading: false,
      }
    case FETCH_DEVICES_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isDevicesLoading: false,
      }
    case CLEAR_LAB_ORDERS:
      return {
        ...state,
        labOrders: [],
      }
    case CLEAR_DEVICES:
      return {
        ...state,
        devices: {},
      }
    case GET_RESULT_FRAME_URL:
      return {
        ...state,
        error: null,
        resultsFrame: {
          isLoading: true,
          frameUrl: null,
        },
      }
    case GET_RESULT_FRAME_URL_SUCCESS:
      return {
        ...state,
        resultsFrame: {
          isLoading: false,
          frameUrl: action.frameUrl,
        },
      }
    case GET_RESULT_FRAME_URL_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        resultsFrame: {
          isLoading: false,
          frameUrl: null,
        },
      }
    case CLEAR_RESULT_FRAME_URL:
      return {
        ...state,
        resultsFrame: {
          isLoading: false,
          frameUrl: null,
        },
      }
    case FETCH_LAB_VENDOR_PROVIDERS:
      return {
        ...state,
        error: null,
        isProvidersLoading: true,
      }
    case FETCH_LAB_VENDOR_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.providers,
        isProvidersLoading: false,
      }
    case FETCH_LAB_VENDOR_PROVIDERS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isProvidersLoading: false,
      }
    case EMAIL_LAB_RESULT:
      return {
        ...state,
        error: null,
        isSendingLabResult: true,
      }
    case EMAIL_LAB_RESULT_SUCCESS:
      return {
        ...state,
        isSendingLabResult: false,
      }
    case EMAIL_LAB_RESULT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isSendingLabResult: false,
      }
    default:
      return state
  }
}

export const getLabOrdersState = (state: RootState): LabOrdersState =>
  state.labOrders
export const getLabOrders = (state: RootState) =>
  getLabOrdersState(state).labOrders
export const getLabOrdersIsLoading = (state: RootState) =>
  getLabOrdersState(state).isOrdersLoading
export const getLabOrderIsPlacing = (state: RootState) =>
  getLabOrdersState(state).isPlacingOrder
export const getLabOrderIsCanceling = (state: RootState) =>
  getLabOrdersState(state).isCancelingOrder
export const getLabOrderIsFinalizing = (state: RootState) =>
  getLabOrdersState(state).isFinalizingOrder
export const getLabOrderIsCompleting = (state: RootState) =>
  getLabOrdersState(state).isCompletingOrder
export const getDevices = (state: RootState) => getLabOrdersState(state).devices
export const getDevicesByVendorId = (vendorId: string) =>
  createSelector(getDevices, (devices) => R.prop(vendorId, devices) || [])
export const getLabOrdersByVendorId = (vendorId: string | Nil) =>
  createSelector(getLabOrders, (labOrders) =>
    labOrders.filter(R.propEq('vendorId', vendorId)),
  )
export const getDevicesIsLoading = (state: RootState) =>
  getLabOrdersState(state).isDevicesLoading
export const getResultFrameUrl = (state: RootState) =>
  getLabOrdersState(state).resultsFrame.frameUrl
export const getResultFrameUrlIsLoading = (state: RootState) =>
  getLabOrdersState(state).resultsFrame.isLoading
export const getLabVendorProviders = (state: RootState) =>
  getLabOrdersState(state).providers
export const getLabVendorProvidersIsLoading = (state: RootState) =>
  getLabOrdersState(state).isProvidersLoading
export const getIsSendingResultEmail = (state: RootState) =>
  getLabOrdersState(state).isSendingLabResult
export const getSoapsWithNoDoctor = (state: RootState) =>
  getLabOrdersState(state).soapsWishNoDoctor
export const getLabOrdersError = (state: RootState) =>
  getLabOrdersState(state).error
export const getLogIdsForInvoiceWithNoProducer = (state: RootState) =>
  getLabOrdersState(state).logsIdsForInvoiceWithNoProducer || []

export function* fetchLabOrdersSaga({
  soapId,
  invoiceId,
}: ReturnType<typeof fetchLabOrders>) {
  try {
    const labOrders = yield* requestAPI(API.fetchLabOrders, soapId, invoiceId)
    yield put(fetchLabOrdersSuccess(labOrders))
  } catch (error) {
    yield put(fetchLabOrdersFailure(error as ApiError))
  }
}

function* handleLabPlacementError(
  error: ApiError,
  failureAction: (error: ApiError) => AnyAction,
) {
  const statusCode = error?.response?.status
  if (statusCode === 409) {
    const soapIds = error?.responseBody?.soapIds || []
    const logIds = error?.responseBody.logIds || []
    if (soapIds.length) {
      yield put(setSoapsWithNoDoctor(soapIds))
    }
    if (logIds.length) {
      yield put(setLogIdsForInvoiceWithNoProducer(logIds))
    }
    const type = error?.responseBody?.error?.type
    if (type === ApiErrorTypes.ORDERS_ARE_LOCKED) {
      const message = error.responseBody.error.message!
      yield put(registerWarnAlert(message))
    }
    const idexxErrors = error?.responseBody?.idexxErrors || []
    if (idexxErrors.length) {
      yield put(
        registerTableAlert({
          columns: [
            {
              id: 'errorCode',
              label: 'Error code',
              field: 'errorCode',
            },
            {
              id: 'message',
              label: 'Message',
              field: 'message',
            },
          ],
          rows: idexxErrors,
          message:
            'We are unable to finalize this order due to an error from Idexx',
        }),
      )
    }
  }

  yield put(failureAction(error))
}

function* handleLabCancellationError(
  error: ApiError,
  failureAction: (error: ApiError) => AnyAction,
) {
  const statusCode = error?.response?.status
  const idexxErrors = error?.responseBody?.idexxErrors
  const firstIdexxErrorCode = idexxErrors?.[0]?.errorCode

  if (firstIdexxErrorCode === ApiErrorTypes.ORDER_CANNOT_BE_MODIFIED) {
    const message = i18n.t('Errors:API_ERROR.ORDER_CANNOT_BE_DELETED')
    yield put(cancelLabOrderAbolish())
    yield put(registerWarnAlert(message))
  } else if (statusCode === 409) {
    const type = error?.responseBody?.error?.type
    if (
      type === ApiErrorTypes.ORDERS_ARE_LOCKED ||
      type === ApiErrorTypes.INVOICE_IS_POSTED
    ) {
      const message = error.responseBody.error.message!
      yield put(registerWarnAlert(message))
    }
  }

  yield put(failureAction(error))
}

export function* placeLabOrderSaga({
  soapId,
  vendorId,
  labTests,
  options,
}: ReturnType<typeof placeLabOrder>) {
  try {
    const { orders, logs } = yield* requestAPI(
      API.placeLabOrder,
      soapId,
      vendorId,
      labTests,
      options,
    )
    yield put(updateMultipleOrderLogs(logs))
    yield put(placeLabOrderSuccess(vendorId, orders))
    yield put(fetchTimelineAction())
  } catch (error) {
    yield handleLabPlacementError(error as ApiError, placeLabOrderFailure)
  }
}

const serializeLabTestGroups = (
  groupedMap: Record<string, LabOrderLabTest[]>,
  options: LabOrderOptions,
) => {
  const keys = Object.keys(groupedMap)

  return keys.map((soapId) => ({
    soapId: soapId === 'null' ? null : soapId,
    orderedLabTests: groupedMap[soapId],
    ...options,
  }))
}

export function* placeAllLabOrdersSaga({
  vendorId,
  labTests,
  options,
}: ReturnType<typeof placeAllLabOrders>) {
  try {
    const groupedLabTests = serializeLabTestGroups(
      R.groupBy(R.propOr(null, 'soapId'), labTests),
      options || {},
    )
    const { orders, logs } = yield* requestAPI(
      API.placeAllLabOrders,
      vendorId,
      groupedLabTests,
    )
    yield put(updateMultipleOrderLogs(logs))
    yield put(placeAllLabOrdersSuccess(vendorId, orders))
    yield put(fetchTimelineAction())
  } catch (error) {
    yield handleLabPlacementError(error as ApiError, placeAllLabOrdersFailure)
  }
}

export function* cancelLabOrderSaga({
  vendorId,
  orderId,
}: ReturnType<typeof cancelLabOrder>) {
  try {
    const { logs } = yield* requestAPI(API.cancelLabOrder, vendorId, orderId)
    yield put(removeMultipleOrderLogs(logs))
    yield put(cancelLabOrderSuccess(vendorId, orderId))
    yield put(fetchTimelineAction())
  } catch (error) {
    yield handleLabCancellationError(error as ApiError, cancelLabOrderFailure)
  }
}

export function* finalizeLabOrderSaga({
  vendorId,
  orderId,
  invoiceId,
}: ReturnType<typeof finalizeLabOrder>) {
  try {
    const { order, logs } = yield* requestAPI(
      API.finalizeLabOrder,
      vendorId,
      orderId,
    )
    yield put(updateMultipleOrderLogs(logs))
    yield put(finalizeLabOrderSuccess(vendorId, order))
    if (invoiceId) {
      yield put(fetchInvoiceV3({ id: invoiceId }))
    }
  } catch (error) {
    yield put(finalizeLabOrderFailure(error as ApiError))
  }
}

export function* completeLabOrderSaga({
  vendorId,
  orderId,
}: ReturnType<typeof completeLabOrder>) {
  try {
    const { order, logs } = yield* requestAPI(
      API.completeLabOrder,
      vendorId,
      orderId,
    )
    yield put(completeLabOrderSuccess(vendorId, order))
    yield put(updateMultipleOrderLogs(logs))
    yield put(fetchTimelineAction())
  } catch (error) {
    yield put(completeLabOrderFailure(error as ApiError))
  }
}

export function* completeLabOrderWithCallbackSaga({
  vendorId,
  orderId,
  completeOrderCallback,
}: ReturnType<typeof completeLabOrderWithCallback>) {
  // @ts-ignore
  yield call(completeLabOrderSaga, { vendorId, orderId })
  yield call(completeOrderCallback)
  yield put(fetchLabTestDetails(vendorId, orderId))
}

export function* fetchDevicesSaga({
  targetBusinessId,
  idexxRefresh,
}: ReturnType<typeof fetchDevices>) {
  try {
    const devices = yield* requestAPI(
      API.fetchDevices,
      targetBusinessId,
      idexxRefresh,
    )
    yield put(fetchDevicesSuccess(devices))
  } catch (error) {
    yield put(fetchDevicesFailure(error as ApiError))
  }
}

export function* fetchResultFrameUrlSaga({
  resultIdentifier,
}: ReturnType<typeof fetchResultFrameUrl>) {
  try {
    const resultsFrameUrl = yield* requestAPI(
      API.getLabTestResultFrame,
      resultIdentifier,
    )
    yield put(fetchResultFrameUrlSuccess(resultsFrameUrl))
  } catch (error) {
    yield put(fetchResultFrameUrlFailure(error as ApiError))
  }
}

export function* fetchLabVendorProvidersSaga() {
  try {
    const providers = yield* requestAPI(API.fetchLabVendorProviders)
    yield put(fetchLabVendorProvidersSuccess(providers))
  } catch (error) {
    yield put(fetchLabVendorProvidersFailure(error as ApiError))
  }
}

function* watchFetchLabOrders() {
  yield takeLeading(FETCH_LAB_ORDERS, fetchLabOrdersSaga)
}

function* watchPlaceLabOrder() {
  yield takeLeading(PLACE_LAB_ORDER, placeLabOrderSaga)
}

function* watchPlaceAllLabOrders() {
  yield takeLeading(PLACE_ALL_LAB_ORDERS, placeAllLabOrdersSaga)
}

function* watchCancelLabOrder() {
  yield takeLeading(CANCEL_LAB_ORDER, cancelLabOrderSaga)
}

function* watchFinalizeLabOrder() {
  yield takeLeading(FINALIZE_LAB_ORDER, finalizeLabOrderSaga)
}

function* watchCompleteLabOrder() {
  yield takeLeading(COMPLETE_LAB_ORDER, completeLabOrderSaga)
}

function* watchCompleteLabOrderWithCallback() {
  yield takeLeading(
    COMPLETE_LAB_ORDER_WITH_CALLBACK,
    completeLabOrderWithCallbackSaga,
  )
}

function* watchFetchDevices() {
  yield takeLeading(FETCH_DEVICES, fetchDevicesSaga)
}

function* watchFetchResultFrameUrl() {
  yield takeLatest(GET_RESULT_FRAME_URL, fetchResultFrameUrlSaga)
}

function* watchFetchLabVendorProviders() {
  yield takeLatest(FETCH_LAB_VENDOR_PROVIDERS, fetchLabVendorProvidersSaga)
}

export function* labOrdersSaga() {
  yield all([
    watchFetchLabOrders(),
    watchPlaceLabOrder(),
    watchPlaceAllLabOrders(),
    watchCancelLabOrder(),
    watchFinalizeLabOrder(),
    watchCompleteLabOrder(),
    watchCompleteLabOrderWithCallback(),
    watchFetchDevices(),
    watchFetchResultFrameUrl(),
    watchFetchLabVendorProviders(),
  ])
}
