import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api/practiceCloning'
import { DocumentToSend } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../sagas/utils/requestAPI'
// @ts-ignore
import createDuck from './duck-generators/createDuck'
import practiceCloningBuilder, {
  INITIAL_STATE,
  // @ts-ignore
} from './duck-generators/practiceCloning'
import { Duck } from './interfaces/Duck'

export function* createSessionFromFileSaga(
  duck: Duck,
  { file, businessId }: { businessId: string; file: any },
) {
  try {
    const documentToSend = { file: file?.raw } as DocumentToSend
    const {
      entities: { practiceCloningSession },
      result: createdSessionId,
    } = yield requestAPI(
      API.createImportSessionFromFile,
      businessId,
      documentToSend,
    )
    yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    yield put(duck.actions.createSessionFromFileSuccess(createdSessionId))
  } catch (error) {
    yield put(duck.actions.createSessionFromFileFailure(error))
  }
}

const practiceImportDuck = createDuck({
  name: 'practiceImport',
  duck: practiceCloningBuilder,
  types: {
    CREATE_SESSION_FROM_FILE: 'CREATE_SESSION_FROM_FILE',
    CREATE_SESSION_FROM_FILE_SUCCESS: 'CREATE_SESSION_FROM_FILE_SUCCESS',
    CREATE_SESSION_FROM_FILE_FAILURE: 'CREATE_SESSION_FROM_FILE_FAILURE',
  },
  reducer: (state = INITIAL_STATE, action: any, duck: Duck) => {
    switch (action.type) {
      case duck.types.CREATE_SESSION_FROM_FILE:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.CREATE_SESSION_FROM_FILE_SUCCESS:
        return {
          ...state,
          activeSessionId: action.sessionId,
          isLoading: false,
        }
      case duck.types.CREATE_SESSION_FROM_FILE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      default:
        return state
    }
  },
  actions: (duck: Duck) => ({
    createSessionFromFile: (businessId: string, file: any) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE,
      businessId,
      file,
    }),
    createSessionFromFileSuccess: (sessionId: string) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE_SUCCESS,
      sessionId,
    }),
    createSessionFromFileFailure: (error: ApiError) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE_FAILURE,
      error,
    }),
  }),
  saga: (duck: Duck) =>
    function* saga(): any {
      yield all([
        yield takeLatest(
          duck.types.CREATE_SESSION_FROM_FILE as any,
          createSessionFromFileSaga,
          duck,
        ),
      ])
    },
  apiEndpoints: (types: Record<string, string>) => ({
    [types.CREATE_SESSION]: API.createImportSession,
    [types.FETCH_SESSION]: API.fetchImportSession,
    [types.FETCH_ACTIVE_SESSION]: API.fetchActiveImportSession,
  }),
})

export const practiceImportReducer = practiceImportDuck.reducer
export const practiceImportSaga = practiceImportDuck.saga
export const practiceImportSelectors = practiceImportDuck.selectors
export const practiceImportActions = practiceImportDuck.actions
