import { curry, props, uniq } from 'ramda'
import { AnyAction } from 'redux'
import { all, call, put, takeLeading } from 'redux-saga/effects'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import { type RootState } from '~/store'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../../sagas/utils/requestAPI'
import updateEntities from '../../sagas/utils/updateEntities'
import { Duck } from '../interfaces/Duck'
import { finishLoading, startLoading } from '../progress'

export const INITIAL_STATE = {
  list: [],
  map: {},
  isLoading: false,
  isFetching: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
}

const listReducer = (state = INITIAL_STATE, action: AnyAction, duck: Duck) => {
  switch (action.type) {
    case duck.types.FETCH_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case duck.types.FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
      }
    case duck.types.FETCH_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case duck.types.FETCH_MORE_ITEMS_FOR_LIST:
      return { ...state, isLoading: true }
    case duck.types.FETCH_MORE_ITEMS_FOR_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case duck.types.FETCH_MORE_ITEMS_FOR_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case duck.types.REFRESH_LIST_SUCCESS:
      return { ...state, list: action.list, totalCount: action.totalCount }
    case duck.types.REFRESH_LIST_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case duck.types.UPDATE_ITEMS:
      return { ...state, map: secondLevelMerge(state.map, action.items) }
    case duck.types.FETCH_ITEM:
      return { ...state, isLoading: true, isFetching: true }
    case duck.types.FETCH_ITEM_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case duck.types.FETCH_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export function* fetchListSaga(
  config: Record<string, any>,
  duck: Duck,
  { type, ...args }: { type: string },
) {
  try {
    yield put(startLoading(config.name))
    const response = yield* requestAPI(
      config.apiEndpoints[type],
      ...Object.values(args),
    )
    const {
      result: { data: list, totalCount },
      entities: { [config.name]: listEntity, ...otherEntities },
    } = response
    yield call(updateEntities, otherEntities)
    yield put(duck.actions.updateItems(listEntity))
    yield put(duck.actions.fetchListSuccess(list, totalCount))
  } catch (error) {
    yield put(duck.actions.fetchListFailure(error))
  } finally {
    yield put(finishLoading(config.name))
  }
}

export function* fetchMoreItemsForListSaga(
  config: Record<string, any>,
  duck: Duck,
  { type, ...args }: { type: string },
) {
  try {
    const {
      result: { data: list, totalCount },
      entities: { [config.name]: listEntity, ...otherEntities },
    } = yield* requestAPI(config.apiEndpoints[type], ...Object.values(args))
    yield call(updateEntities, otherEntities)
    yield put(duck.actions.updateItems(listEntity))
    yield put(
      duck.actions.fetchMoreItemsForListSuccess(
        list,
        totalCount,
        (args as any).from,
      ),
    )
  } catch (error) {
    yield put(duck.actions.fetchMoreItemsForListFailure(error))
  }
}

export function* refreshListSaga(
  config: Record<string, any>,
  duck: Duck,
  { type, ...args }: { type: string },
) {
  try {
    const response = yield* requestAPI(
      config.apiEndpoints[type],
      ...Object.values(args),
    )
    const {
      result: { data: list, totalCount },
      entities: { [config.name]: listEntity, ...otherEntities },
    } = response
    yield call(updateEntities, otherEntities)
    yield put(duck.actions.updateItems(listEntity))
    yield put(duck.actions.refreshListSuccess(list, totalCount))
  } catch (error) {
    yield put(duck.actions.refreshListFailure(error))
  }
}

export function* fetchItemSaga(
  config: Record<string, any>,
  duck: Duck,
  { type, ...args }: { type: string },
) {
  try {
    const response = yield* requestAPI(
      config.apiEndpoints[type],
      ...Object.values(args),
    )
    const {
      entities: { [config.name]: listEntity, ...otherEntities },
    } = response
    yield call(updateEntities, otherEntities)
    yield put(duck.actions.updateItems(listEntity))
    yield put(duck.actions.fetchItemSuccess())
  } catch (error) {
    yield put(duck.actions.fetchItemFailure(error))
  }
}

export default (config: Record<string, any>) => ({
  types: {
    FETCH_LIST: 'FETCH_LIST',
    FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',
    FETCH_LIST_FAILURE: 'FETCH_LIST_FAILURE',
    FETCH_MORE_ITEMS_FOR_LIST: 'FETCH_MORE_ITEMS_FOR_LIST',
    FETCH_MORE_ITEMS_FOR_LIST_SUCCESS: 'FETCH_MORE_ITEMS_FOR_LIST_SUCCESS',
    FETCH_MORE_ITEMS_FOR_LIST_FAILURE: 'FETCH_MORE_ITEMS_FOR_LIST_FAILURE',
    REFRESH_LIST: 'REFRESH_LIST',
    REFRESH_LIST_SUCCESS: 'REFRESH_LIST_SUCCESS',
    REFRESH_LIST_FAILURE: 'REFRESH_LIST_FAILURE',
    FETCH_ITEM: 'FETCH_ITEM',
    FETCH_ITEM_SUCCESS: 'FETCH_ITEM_SUCCESS',
    FETCH_ITEM_FAILURE: 'FETCH_ITEM_FAILURE',
    UPDATE_ITEMS: 'UPDATE_ITEMS',
  },
  actions: (duck: Duck) => ({
    fetchList: <T = any>(...args: T[]) => ({
      type: duck.types.FETCH_LIST,
      ...args,
    }),
    fetchListSuccess: <T = any>(list: T[], totalCount: number) => ({
      type: duck.types.FETCH_LIST_SUCCESS,
      list,
      totalCount,
    }),
    fetchListFailure: (error: ApiError) => ({
      type: duck.types.FETCH_LIST_FAILURE,
      error,
    }),
    fetchMoreItemsForList: <T = any>(...args: T[]) => ({
      type: duck.types.FETCH_MORE_ITEMS_FOR_LIST,
      ...args,
    }),
    fetchMoreItemsForListSuccess: <T = any>(
      list: T[],
      totalCount: number,
      from: number,
    ) => ({
      type: duck.types.FETCH_MORE_ITEMS_FOR_LIST_SUCCESS,
      list,
      totalCount,
      from,
    }),
    fetchMoreItemsForListFailure: (error: ApiError) => ({
      type: duck.types.FETCH_MORE_ITEMS_FOR_LIST_FAILURE,
      error,
    }),
    refreshList: <T = any>(...args: T[]) => ({
      type: duck.types.REFRESH_LIST,
      ...args,
    }),
    refreshListSuccess: <T = any>(list: T[], totalCount: number) => ({
      type: duck.types.REFRESH_LIST_SUCCESS,
      list,
      totalCount,
    }),
    refreshListFailure: (error: ApiError) => ({
      type: duck.types.REFRESH_LIST_FAILURE,
      error,
    }),
    fetchItem: <T = any>(...args: T[]) => ({
      type: duck.types.FETCH_ITEM,
      ...args,
    }),
    fetchItemSuccess: () => ({ type: duck.types.FETCH_ITEM_SUCCESS }),
    fetchItemFailure: (error: ApiError) => ({
      type: duck.types.FETCH_ITEM_FAILURE,
      error,
    }),
    updateItems: <T = any>(items: T[]) => ({
      type: duck.types.UPDATE_ITEMS,
      items,
    }),
  }),
  reducer: listReducer,
  selectors: {
    getList: (state: RootState) => config.getReducer(state).list,
    getMap: (state: RootState) => config.getReducer(state).map,
    getItem: curry((id, state) => config.getReducer(state).map[id]),
    getMultipleItems: curry((ids, state) =>
      props(ids, config.getReducer(state).map),
    ),
    getIsLoading: (state: RootState) => config.getReducer(state).isLoading,
    getIsFetching: (state: RootState) => config.getReducer(state).isFetching,
    getError: (state: RootState) => config.getReducer(state).error,
    getTotalCount: (state: RootState) => config.getReducer(state).totalCount,
  },
  saga: (duck: Duck) =>
    function* saga(): Generator {
      yield all([
        // @ts-ignore
        yield takeLeading(duck.types.FETCH_LIST, fetchListSaga, config, duck),
        yield takeLeading(
          // @ts-ignore
          duck.types.FETCH_MORE_ITEMS_FOR_LIST,
          fetchMoreItemsForListSaga,
          config,
          duck,
        ),
        yield takeLeading(
          // @ts-ignore
          duck.types.REFRESH_LIST,
          refreshListSaga,
          config,
          duck,
        ),
        // @ts-ignore
        yield takeLeading(duck.types.FETCH_ITEM, fetchItemSaga, config, duck),
      ])
    },
})
