import React, { memo } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LinkButton, PermissionArea, Text } from '@pbt/pbt-ui-components'

import MembershipPaymentMethodCell from '~/components/common/lists/primitive-table/cells/MembershipPaymentMethodCell'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import FeatureToggle from '~/constants/featureToggle'
import { fetchWpCancellationInfo } from '~/store/actions/wellnessPlans'
import {
  getCRUDByArea,
  getCurrentBusinessHasActiveWellnessPlans,
} from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import {
  getWellnessPlansIsCancelling,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'
import { Membership, MembershipPlan } from '~/types'
import { getPatientStatus } from '~/utils'
import { getBusinessName } from '~/utils/businessUtils'
import { useEditPaymentInfo } from '~/utils/membershipPaymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import MembershipPatientCell from './MembershipPatientCell'
import MemberSinceCell from './MemberSinceCell'
import NoPlanCell from './NoPlanCell'
import PaymentDueCell from './PaymentDueCell'
import PlanPeriodCell from './PlanPeriodCell'
import PlanTypeCell from './PlanTypeCell'
import TierCell from './TierCell'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: theme.constants.tableBorder,
      borderTop: 0,
      backgroundColor: theme.colors.tableBackground,
    },
    cell: {
      padding: theme.spacing(0.5, 1),
    },
    patientContainer: {
      borderRight: theme.constants.tableBorder,
      minWidth: 150,
    },
    planDetails: {
      fontSize: '1.4rem',
      minHeight: 48,
      '&:not(:last-child)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    oddRowContainer: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    linkButton: {
      minWidth: 'auto',
      padding: theme.spacing(0.5),
      fontSize: '1.4rem',
      color: theme.colors.link,
    },
    editButton: {
      color: theme.colors.editIconColor,
      padding: theme.spacing(0.75),
    },
  }),
  { name: 'MembershipTablePatientRow' },
)

interface MembershipTablePatientRowProps {
  index: number
  isItemLoaded: (index: number) => boolean
  item?: Partial<Membership>
}

const MembershipTablePatientRow = memo(
  ({
    item: patientMembership = {},
    isItemLoaded,
    index,
  }: MembershipTablePatientRowProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Abbreviations', 'Common'])

    const hasActiveWellnessPlans = useSelector(
      getCurrentBusinessHasActiveWellnessPlans,
    )
    const patient = useSelector(getPatient(patientMembership.patient))
    const business = useSelector(getBusiness(patientMembership.businessId))
    const isCancelling = useSelector(getWellnessPlansIsCancelling)
    const { read: wellnessPlanReadPermissions } = useSelector(
      getCRUDByArea(PermissionArea.WELLNESS_PLAN),
    )

    const isWellnessplanSharingEnabled = useSelector(
      getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING),
    )

    const isMedium = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('lg'),
    )

    const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
    const [openCancelMembershipDialog] = useDialog(
      DialogNames.CANCEL_MEMBERSHIP,
    )

    const { clientId } = patientMembership
    const patientId = patientMembership.patient

    const onCancelInfoFetched = (cancelId: string) => {
      openCancelMembershipDialog({ clientId, patientId, cancelId })
    }

    const {
      canUpdate,
      isLinkToUpdateWplansPaymentMethodEnabled,
      onEditPaymentInfo,
      Icon,
    } = useEditPaymentInfo({
      clientId,
      patientId,
      withBillingAddress: true,
    })

    const setCallbackAfterFetchCancellationInfoOn = useCloseAfterCreation(
      onCancelInfoFetched,
      getWellnessPlansIsLoading,
    )

    const patientStatus = patient ? getPatientStatus(patient) : undefined
    const itemLoaded = isItemLoaded(index)

    const viewTermsAndConditions = () => {
      openDocumentDialog({
        previewOnly: true,
        document: patientMembership.termsAndConditions,
        PreviewProps: {
          view: true,
          showChangeFile: false,
          clientId,
          patientId,
        },
        step: DocumentDialogStates.PREVIEW,
      })
    }

    const handleCancelPlan = (cancelId: MembershipPlan['cancelId']) => {
      setCallbackAfterFetchCancellationInfoOn(cancelId)
      dispatch(fetchWpCancellationInfo(clientId, patientId, cancelId))
    }
    return (
      <Grid
        container
        className={classNames(classes.container, {
          [classes.oddRowContainer]: index % 2 === 1,
        })}
      >
        {!patientMembership?.plans?.length && (
          <Grid
            container
            item
            alignItems="center"
            className={classes.planDetails}
            justifyContent="space-between"
            px={2}
            wrap="nowrap"
          >
            <Grid item py={0.75} xs={4}>
              <MembershipPatientCell {...patientMembership} />
            </Grid>
            {!patientStatus?.disabled &&
              hasActiveWellnessPlans &&
              itemLoaded && (
                <Grid item alignItems="center" className={classes.cell}>
                  <NoPlanCell membership={patientMembership} />
                </Grid>
              )}
          </Grid>
        )}
        {patientMembership?.plans?.map(
          (plan: MembershipPlan, planIndex: number) => (
            <Grid
              container
              item
              alignItems="center"
              className={classes.planDetails}
              key={plan.planLogId}
              px={2}
              wrap="nowrap"
            >
              <Grid
                item
                alignSelf="normal"
                className={classes.patientContainer}
                py={0.75}
                xs={4}
              >
                {planIndex === 0 && (
                  <MembershipPatientCell {...patientMembership} />
                )}
              </Grid>
              <Grid item className={classes.cell} minWidth={125} xs={3}>
                <Dotdotdot clamp={2}>
                  <TierCell plan={plan} />
                </Dotdotdot>
              </Grid>
              {isWellnessplanSharingEnabled && (
                <Grid item className={classes.cell} minWidth={125} xs={3}>
                  <Dotdotdot clamp={2}>
                    <Text variant="body2">{getBusinessName(business)}</Text>
                  </Dotdotdot>
                </Grid>
              )}
              <Grid item className={classes.cell} minWidth={80} xs={2}>
                <Dotdotdot clamp={2}>
                  <PlanTypeCell plan={plan} />
                </Dotdotdot>
              </Grid>
              <Grid item className={classes.cell} minWidth={120} xs={3}>
                <Dotdotdot clamp={2}>
                  <PlanPeriodCell plan={plan} />
                </Dotdotdot>
              </Grid>
              <Grid item className={classes.cell} minWidth={120} xs={3}>
                <PaymentDueCell membership={patientMembership} plan={plan} />
              </Grid>
              <Grid item className={classes.cell} minWidth={135} xs={3}>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <MembershipPaymentMethodCell
                    paymentMethod={plan.paymentMethod}
                  />
                  {(isLinkToUpdateWplansPaymentMethodEnabled || !isMedium) && (
                    <IconButton
                      className={classes.editButton}
                      disabled={!canUpdate}
                      size="large"
                      onClick={() => onEditPaymentInfo(plan)}
                    >
                      {Icon}
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid item className={classes.cell} minWidth={85} xs={2}>
                <MemberSinceCell plan={plan} />
              </Grid>
              <Grid item className={classes.cell} xs={1}>
                {patientMembership.termsAndConditions ? (
                  <LinkButton
                    className={classes.linkButton}
                    disabled={!wellnessPlanReadPermissions}
                    onClick={
                      wellnessPlanReadPermissions
                        ? viewTermsAndConditions
                        : undefined
                    }
                  >
                    {t('Common:TERMS')}
                  </LinkButton>
                ) : (
                  t('Abbreviations:COMMON.NOT_AVAILABLE')
                )}
              </Grid>
              <Grid item className={classes.cell} xs={1}>
                <LinkButton
                  className={classes.linkButton}
                  disabled={!canUpdate}
                  loading={isCancelling}
                  sx={{
                    visibility:
                      plan.planLogId && plan.cancelId === plan.planLogId
                        ? 'visible'
                        : 'hidden',
                  }}
                  onClick={() => handleCancelPlan(plan.cancelId)}
                >
                  {t('Common:CANCEL_ACTION')}
                </LinkButton>
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    )
  },
)

export default MembershipTablePatientRow
