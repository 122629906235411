import * as R from 'ramda'
import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import AssetDestination from '~/constants/AssetDestination'
import AttachmentHistoryGroupType from '~/constants/AttachmentHistoryGroupType'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import {
  AttachmentHistory,
  CreateAttachmentHistoryGroupInput,
  FilesWithDetails,
} from '~/types'
import { mapFilesToObjects } from '~/utils/file'

import {
  batchDeleteDiagnosisHistory,
  batchDeleteDiagnosisHistoryFailure,
  batchDeleteDiagnosisHistorySuccess,
  deleteAttachmentHistory,
  deleteAttachmentHistoryFailure,
  deleteAttachmentHistorySuccess,
  deleteDiagnosisHistory,
  deleteDiagnosisHistoryFailure,
  deleteDiagnosisHistorySuccess,
  deleteNoteHistory,
  deleteNoteHistoryFailure,
  deleteNoteHistorySuccess,
  deleteVaccineHistory,
  deleteVaccineHistoryFailure,
  deleteVaccineHistorySuccess,
  editDiagnosisHistory,
  editDiagnosisHistoryFailure,
  editDiagnosisHistorySuccess,
  editNoteHistory,
  editNoteHistoryFailure,
  editNoteHistorySuccess,
  editVaccineHistory,
  editVaccineHistoryFailure,
  editVaccineHistorySuccess,
  fetchAttachmentHistory,
  fetchAttachmentHistoryFailure,
  fetchAttachmentHistorySuccess,
  fetchDiagnoses,
  fetchDiagnosesFailure,
  fetchDiagnosesFilters,
  fetchDiagnosesFiltersFailure,
  fetchDiagnosesFiltersSuccess,
  fetchDiagnosesSuccess,
  fetchUploadedDocuments,
  fetchUploadedDocumentsFailure,
  fetchUploadedDocumentsSuccess,
  fetchVaccines,
  fetchVaccinesFailure,
  fetchVaccinesSuccess,
  saveAttachmentHistoryDocuments,
  saveAttachmentHistoryDocumentsFailure,
  saveAttachmentHistoryDocumentsSuccess,
  saveAttachmentHistoryFailure,
  saveAttachmentHistorySuccess,
  saveAttachmentHistoryV2,
  saveDiagnosisHistory,
  saveDiagnosisHistoryFailure,
  saveDiagnosisHistorySuccess,
  saveMultiAttachmentHistory,
  saveNoteHistory,
  saveNoteHistoryFailure,
  saveNoteHistorySuccess,
  savePastMedicalRecord,
  savePastMedicalRecordFailure,
  savePastMedicalRecordSuccess,
  saveVaccineHistory,
  saveVaccineHistoryFailure,
  saveVaccineHistorySuccess,
} from '../actions/medicalHistory'
import {
  fetchTimeline as fetchTimelineAction,
  optimisticAttachmentDeleteFromTimeline,
} from '../actions/timeline'
import {
  BATCH_DELETE_DIAGNOSIS_HISTORY,
  DELETE_ATTACHMENT_HISTORY,
  DELETE_DIAGNOSIS_HISTORY,
  DELETE_NOTE_HISTORY,
  DELETE_VACCINE_HISTORY,
  EDIT_DIAGNOSIS_HISTORY,
  EDIT_NOTE_HISTORY,
  EDIT_VACCINE_HISTORY,
  FETCH_ATTACHMENT_HISTORY,
  FETCH_DIAGNOSES,
  FETCH_DIAGNOSES_FILTERS,
  FETCH_UPLOADED_DOCUMENTS,
  FETCH_VACCINES,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS,
  SAVE_ATTACHMENT_HISTORY_V2,
  SAVE_DIAGNOSIS_HISTORY,
  SAVE_MULTI_ATTACHMENT_HISTORY,
  SAVE_NOTE_HISTORY,
  SAVE_PAST_MEDICAL_RECORD,
  SAVE_VACCINE_HISTORY,
} from '../actions/types/medicalHistory'
import { uploadAsset, uploadAssetList } from '../duck/files'
import { fetchWidgetsData } from '../duck/landing'
import { getCurrentBusinessId, getCurrentUserId } from '../reducers/auth'
import requestAPI from './utils/requestAPI'

export function* fetchUploadedDocumentsSaga({
  patientId,
}: ReturnType<typeof fetchUploadedDocuments>) {
  try {
    const uploadedDocuments = yield* requestAPI(API.fetchUploadedDocuments, {
      patientId,
    })
    yield put(fetchUploadedDocumentsSuccess(uploadedDocuments))
  } catch (error) {
    yield put(fetchUploadedDocumentsFailure(error as ApiError))
  }
}

export function* fetchVaccinesSaga({
  patientId,
}: ReturnType<typeof fetchVaccines>) {
  try {
    const vaccines = yield* requestAPI(API.fetchVaccines, patientId)
    yield put(fetchVaccinesSuccess(vaccines))
  } catch (error) {
    yield put(fetchVaccinesFailure(error as ApiError))
  }
}

export function* saveVaccineHistorySaga({
  items,
  patientId,
  clientId,
}: ReturnType<typeof saveVaccineHistory>) {
  try {
    yield* requestAPI(API.saveVaccineHistory, items, patientId, clientId)
    yield put(fetchTimelineAction())
    yield put(saveVaccineHistorySuccess())
  } catch (error) {
    yield put(saveVaccineHistoryFailure(error as ApiError))
  }
}

export function* editVaccineHistorySaga({
  items,
  patientId,
}: ReturnType<typeof editVaccineHistory>) {
  try {
    yield* requestAPI(API.saveVaccineHistory, items, patientId)
    yield put(fetchTimelineAction())
    yield put(editVaccineHistorySuccess())
  } catch (error) {
    yield put(editVaccineHistoryFailure(error as ApiError))
  }
}

export function* deleteVaccineHistorySaga({
  itemId,
  patientId,
}: ReturnType<typeof deleteVaccineHistory>) {
  try {
    yield* requestAPI(API.deleteVaccineHistory, itemId, patientId)
    yield put(fetchTimelineAction())
    yield put(deleteVaccineHistorySuccess())
  } catch (error) {
    yield put(deleteVaccineHistoryFailure(error as ApiError))
  }
}

export function* fetchDiagnosesFiltersSaga({
  patientId,
}: ReturnType<typeof fetchDiagnosesFilters>) {
  try {
    const filters = yield* requestAPI(API.fetchDiagnosesFilters, patientId)
    yield put(fetchDiagnosesFiltersSuccess(filters))
  } catch (error) {
    yield put(fetchDiagnosesFiltersFailure(error as ApiError))
  }
}

export function* fetchDiagnosesSaga({
  patientId,
  categories,
  entityType,
}: ReturnType<typeof fetchDiagnoses>) {
  try {
    const diagnoses = yield* requestAPI(
      API.fetchDiagnoses,
      patientId,
      categories.join(','),
      entityType,
    )
    yield put(fetchDiagnosesSuccess(diagnoses))
  } catch (error) {
    yield put(fetchDiagnosesFailure(error as ApiError))
  }
}

const refetchDiagnosesSnapshotData = (patientId: string) =>
  fetchWidgetsData([SnapshotsAliasTypes.Diagnoses], {
    quiet: false,
    landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
    patientId,
  })

export function* saveDiagnosisHistorySaga({
  items: unsavedItems,
  patientId,
  clientId,
}: ReturnType<typeof saveDiagnosisHistory>) {
  try {
    // Ensures that no item without diagnosisId is sent to BE due to rare bug https://petabyte.atlassian.net/browse/PUI-10727
    const normalizedItems = R.filter(R.has('diagnosisId'), unsavedItems)
    yield* requestAPI(
      API.saveDiagnosisHistory,
      normalizedItems,
      patientId,
      clientId,
    )
    yield put(fetchTimelineAction())
    yield put(refetchDiagnosesSnapshotData(patientId))
    yield put(saveDiagnosisHistorySuccess())
  } catch (error) {
    yield put(saveDiagnosisHistoryFailure(error as ApiError))
  }
}

export function* editDiagnosisHistorySaga({
  items,
  patientId,
}: ReturnType<typeof editDiagnosisHistory>) {
  try {
    yield* requestAPI(API.saveDiagnosisHistory, items, patientId)
    yield put(fetchTimelineAction())
    yield put(editDiagnosisHistorySuccess())
  } catch (error) {
    yield put(editDiagnosisHistoryFailure(error as ApiError))
  }
}

export function* deleteDiagnosisHistorySaga({
  itemId,
  patientId,
}: ReturnType<typeof deleteDiagnosisHistory>) {
  try {
    yield* requestAPI(API.deleteDiagnosisHistory, itemId, patientId)
    yield put(fetchTimelineAction())
    yield put(deleteDiagnosisHistorySuccess())
  } catch (error) {
    yield put(deleteDiagnosisHistoryFailure(error as ApiError))
  }
}

export function* batchDeleteDiagnosisHistorySaga({
  items,
  patientId,
}: ReturnType<typeof batchDeleteDiagnosisHistory>) {
  try {
    yield* requestAPI(API.batchDeleteDiagnosisHistory, items, patientId)
    yield put(batchDeleteDiagnosisHistorySuccess())
  } catch (error) {
    yield put(batchDeleteDiagnosisHistoryFailure(error as ApiError))
  }
}

export function* saveNoteHistorySaga({
  items,
  patientId,
  clientId,
}: ReturnType<typeof saveNoteHistory>) {
  try {
    yield* requestAPI(API.saveNoteHistory, items, patientId, clientId)
    yield put(fetchTimelineAction())
    yield put(saveNoteHistorySuccess())
  } catch (error) {
    yield put(saveNoteHistoryFailure(error as ApiError))
  }
}

export function* editNoteHistorySaga({
  items,
  patientId,
}: ReturnType<typeof editNoteHistory>) {
  try {
    yield* requestAPI(API.saveNoteHistory, items, patientId)
    yield put(fetchTimelineAction())
    yield put(editNoteHistorySuccess())
  } catch (error) {
    yield put(editNoteHistoryFailure(error as ApiError))
  }
}

export function* deleteNoteHistorySaga({
  itemId,
  patientId,
}: ReturnType<typeof deleteNoteHistory>) {
  try {
    yield* requestAPI(API.deleteNoteHistory, itemId, patientId)
    yield put(fetchTimelineAction())
    yield put(deleteNoteHistorySuccess())
  } catch (error) {
    yield put(deleteNoteHistoryFailure(error as ApiError))
  }
}

export function* saveAttachmentHistoryV2Saga({
  blob,
  item,
  clientId,
  patientId,
}: ReturnType<typeof saveAttachmentHistoryV2>) {
  try {
    const fileUrl: string = yield uploadAsset({
      blob,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })
    const { description, ...notesWithoutDescription } = item
    const itemWithNotes = { ...notesWithoutDescription, notes: description }
    yield* requestAPI(
      API.saveAttachmentHistoryV2,
      itemWithNotes,
      fileUrl,
      patientId,
      clientId,
    )
    yield put(fetchTimelineAction())
    yield put(saveAttachmentHistorySuccess())
  } catch (error) {
    yield put(saveAttachmentHistoryFailure(error as ApiError))
  }
}

function* fetchAttachmentHistorySaga({
  patientId,
}: ReturnType<typeof fetchAttachmentHistory>) {
  try {
    const attachments = yield* requestAPI(API.fetchAttachmentHistory, patientId)
    yield put(fetchAttachmentHistorySuccess(attachments))
  } catch (error) {
    yield put(fetchAttachmentHistoryFailure(error as ApiError))
  }
}

function* getAttachmentHistoryGroupSaga(
  attachmentGroup: FilesWithDetails,
  fileUrls: string[],
  groupType: AttachmentHistoryGroupType,
) {
  const attachmentHistories: AttachmentHistory[] = yield call(
    mapFilesToObjects,
    attachmentGroup.files,
    fileUrls,
  )
  const authorId: string = yield select(getCurrentUserId)
  const businessId: string = yield select(getCurrentBusinessId)

  return {
    attachmentHistories,
    authorId,
    businessId,
    date: attachmentGroup.date,
    diagnosedIn: attachmentGroup.diagnosedIn,
    notes: attachmentGroup.description,
    title: attachmentGroup.title,
    type: groupType,
  }
}

export function* savePastMedicalRecordSaga({
  files,
  clientId,
  patientId,
}: ReturnType<typeof savePastMedicalRecord>) {
  try {
    const fileUrls: string[] = yield uploadAssetList({
      files: files.files,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const attachmentHistoryGroup: CreateAttachmentHistoryGroupInput =
      yield getAttachmentHistoryGroupSaga(files, fileUrls, AttachmentHistoryGroupType.PreviousMedicalRecord)

    yield* requestAPI(
      API.saveMultiAttachmentHistory,
      patientId,
      attachmentHistoryGroup,
    )
    yield put(fetchTimelineAction())
    yield put(savePastMedicalRecordSuccess())
  } catch (error) {
    yield put(savePastMedicalRecordFailure(error as ApiError))
  }
}

export function* saveMultiAttachmentHistorySaga({
  attachmentGroup,
  clientId,
  patientId,
}: ReturnType<typeof saveMultiAttachmentHistory>) {
  try {
    const fileUrls: string[] = yield uploadAssetList({
      files: attachmentGroup.files,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const attachmentHistoryGroup: CreateAttachmentHistoryGroupInput =
      yield getAttachmentHistoryGroupSaga(attachmentGroup, fileUrls, AttachmentHistoryGroupType.Other)

    yield* requestAPI(
      API.saveMultiAttachmentHistory,
      patientId,
      attachmentHistoryGroup,
    )
    yield put(fetchTimelineAction())
    yield put(saveAttachmentHistorySuccess())
  } catch (error) {
    yield put(saveAttachmentHistoryFailure(error as ApiError))
  }
}

export function* saveAttachmentHistoryDocumentsSaga({
  items,
  clientId,
  patientId,
}: ReturnType<typeof saveAttachmentHistoryDocuments>) {
  try {
    yield* requestAPI(
      API.saveAttachmentHistoryDocuments,
      items,
      patientId,
      clientId,
    )
    yield put(fetchTimelineAction())
    yield put(saveAttachmentHistoryDocumentsSuccess())
  } catch (error) {
    yield put(saveAttachmentHistoryDocumentsFailure(error as ApiError))
  }
}

export function* deleteAttachmentHistorySaga({
  attachmentId,
  patientId,
}: ReturnType<typeof deleteAttachmentHistory>) {
  try {
    yield put(optimisticAttachmentDeleteFromTimeline(attachmentId))
    yield* requestAPI(API.deleteAttachmentHistory, attachmentId, patientId)
    yield put(fetchTimelineAction())
    yield put(deleteAttachmentHistorySuccess())
  } catch (error) {
    yield put(deleteAttachmentHistoryFailure(error as ApiError))
  }
}

function* watchFetchVaccines() {
  yield takeLeading(FETCH_VACCINES, fetchVaccinesSaga)
}

function* watchSaveVaccineHistory() {
  yield takeLeading(SAVE_VACCINE_HISTORY, saveVaccineHistorySaga)
}

function* watchEditVaccineHistory() {
  yield takeLeading(EDIT_VACCINE_HISTORY, editVaccineHistorySaga)
}

function* watchDeleteVaccineHistory() {
  yield takeLeading(DELETE_VACCINE_HISTORY, deleteVaccineHistorySaga)
}

function* watchFetchDiagnosesFilters() {
  yield takeLeading(FETCH_DIAGNOSES_FILTERS, fetchDiagnosesFiltersSaga)
}

function* watchFetchDiagnoses() {
  yield takeLeading(FETCH_DIAGNOSES, fetchDiagnosesSaga)
}

function* watchSaveDiagnosisHistory() {
  yield takeLeading(SAVE_DIAGNOSIS_HISTORY, saveDiagnosisHistorySaga)
}

function* watchEditDiagnosisHistory() {
  yield takeLeading(EDIT_DIAGNOSIS_HISTORY, editDiagnosisHistorySaga)
}

function* watchDeleteDiagnosisHistory() {
  yield takeLeading(DELETE_DIAGNOSIS_HISTORY, deleteDiagnosisHistorySaga)
}

function* watchBatchDeleteDiagnosisHistory() {
  yield takeLeading(
    BATCH_DELETE_DIAGNOSIS_HISTORY,
    batchDeleteDiagnosisHistorySaga,
  )
}

function* watchSaveNoteHistory() {
  yield takeLeading(SAVE_NOTE_HISTORY, saveNoteHistorySaga)
}

function* watchEditNoteHistory() {
  yield takeLeading(EDIT_NOTE_HISTORY, editNoteHistorySaga)
}

function* watchDeleteNoteHistory() {
  yield takeLeading(DELETE_NOTE_HISTORY, deleteNoteHistorySaga)
}

function* watchFetchAttachmentHistory() {
  yield takeLeading(FETCH_ATTACHMENT_HISTORY, fetchAttachmentHistorySaga)
}

function* watchSaveAttachmentHistoryV2() {
  yield takeLeading(SAVE_ATTACHMENT_HISTORY_V2, saveAttachmentHistoryV2Saga)
}

function* watchPastMedicalRecord() {
  yield takeLeading(SAVE_PAST_MEDICAL_RECORD, savePastMedicalRecordSaga)
}

function* watchSaveMultiAttachmentHistory() {
  yield takeLeading(
    SAVE_MULTI_ATTACHMENT_HISTORY,
    saveMultiAttachmentHistorySaga,
  )
}

function* watchSaveAttachmentHistoryDocuments() {
  yield takeLeading(
    SAVE_ATTACHMENT_HISTORY_DOCUMENTS,
    saveAttachmentHistoryDocumentsSaga,
  )
}

function* watchDeleteAttachmentHistory() {
  yield takeLeading(DELETE_ATTACHMENT_HISTORY, deleteAttachmentHistorySaga)
}

function* watchFetchUploadedDocuments() {
  yield takeLeading(FETCH_UPLOADED_DOCUMENTS, fetchUploadedDocumentsSaga)
}

export default function* medicalHistorySaga() {
  yield all([
    watchFetchVaccines(),
    watchSaveVaccineHistory(),
    watchEditVaccineHistory(),
    watchDeleteVaccineHistory(),
    watchFetchDiagnosesFilters(),
    watchFetchDiagnoses(),
    watchSaveDiagnosisHistory(),
    watchEditDiagnosisHistory(),
    watchDeleteDiagnosisHistory(),
    watchBatchDeleteDiagnosisHistory(),
    watchSaveNoteHistory(),
    watchEditNoteHistory(),
    watchDeleteNoteHistory(),
    watchSaveAttachmentHistoryV2(),
    watchSaveMultiAttachmentHistory(),
    watchSaveAttachmentHistoryDocuments(),
    watchDeleteAttachmentHistory(),
    watchPastMedicalRecord(),
    watchFetchAttachmentHistory(),
    watchFetchUploadedDocuments(),
  ])
}
