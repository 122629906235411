import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Location, useLocation } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InfoIcon from '@mui/icons-material/Info'
import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { isEmpty } from 'ramda'
import { Business, CRUD, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getMultipleAreaCRUDIntersectionForRoles,
  getMultipleAreaCRUDUnionForRoles,
} from '~/store/reducers/auth'
import { getChatUnreadCount } from '~/store/reducers/chat'
import {
  getFeatureToggle,
  getNotificationArea,
} from '~/store/reducers/constants'
import { getNotificationsMenuTotalCountsForArea } from '~/store/reducers/notifications'
import useReloadRedirect from '~/utils/useReloadRedirect'

import NotificationCountBadge from '../notifications/NotificationCountBadge'
// @ts-ignore
import { getSelectedItem } from './leftMenuUtils'
import { MenuItem } from './menuItem'

type UseStyleProps = {
  notActive?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
    },
    item: {
      width: 'auto',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      '&:focus, &:hover, &:visited, &:link, &:active': {
        backgroundColor: theme.colors.tableBackground,
        textDecoration: 'none',
      },
      textDecoration: 'none',
    },
    selectedItem: {
      color: '#FAF8F5',
      backgroundColor: `${theme.colors.selectedItem} !important`,
      '&:focus': {
        backgroundColor: `${theme.colors.selectedItem} !important`,
      },
    },
    disabledItem: {
      opacity: ({ notActive }: UseStyleProps) =>
        notActive ? 'initial' : '1 !important',
      color: theme.colors.filterBorderColor,
    },
    itemRoot: {
      '&:hover': {
        backgroundColor: theme.colors.listItemHover,
      },
      height: 40,
      borderRadius: 2,
    },
    icon: {
      minWidth: 0,
      color: 'inherit',
      margin: theme.spacing(1),
      marginRight: 0,
      textAlign: 'center',
      width: 24,
      position: 'relative',
    },
    listItemText: {
      minWidth: 'auto',
      paddingLeft: 0,
      overflow: 'hidden',
    },
    primaryItemText: {
      paddingLeft: theme.spacing(1),
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    expandItem: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandItemHidden: {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    childrenList: {
      background:
        'linear-gradient(180deg, #FEFFFF 13.47%, #FBFBFB 98.31%, #EFEDED 100%)',
    },
    textOpen: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    textClose: {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    noVisibility: {
      visibility: 'hidden',
    },
    backArrow: {
      marginLeft: theme.spacing(1),
    },
    disabledTooltip: {
      position: 'absolute',
      top: '10px',
      right: 0,
      justifyContent: 'center',
    },
    disabledTooltipIcon: {
      width: 20,
      height: 20,
    },
  }),
  { name: 'LeftMenuListItem' },
)

const PADDING_LEFT = 18

const getIsDisabledByCondition = (
  item: MenuItem,
  business: Business | undefined,
) =>
  typeof item.disabled === 'function' ? item.disabled(business) : item.disabled

interface LeftMenuListItemProps {
  additionalPaddingLevel?: number
  disabled?: boolean
  item: MenuItem
  // eslint-disable-next-line react/no-unused-prop-types
  location?: Location
  onClick?: (event: any) => void
  onItemSelected?: (item: MenuItem) => void
  open: boolean
  paging?: boolean
}

const LeftMenuListItem = ({
  item: itemProp,
  open,
  onItemSelected,
  onClick,
  paging,
  disabled: disabledProp,
  additionalPaddingLevel = 0,
}: LeftMenuListItemProps) => {
  const item = itemProp || {}
  const classes = useStyles({ notActive: item.notActive } as UseStyleProps)
  const theme = useTheme()
  const location = useLocation()
  const permissions = useSelector(getCRUDByArea(item.permissionArea))
  const multiplePermissions = useSelector(
    getMultipleAreaCRUDIntersectionForRoles(
      item.permissionAreas ? item.permissionAreas : [],
    ),
  )

  const getChildPermissions = (items: MenuItem[] | undefined) => {
    const permissionAreas: any[] = []
    if (!items) {
      return []
    }
    items.forEach((menuItem) =>
      permissionAreas.push(
        menuItem.children
          ? getChildPermissions(menuItem.children)
          : menuItem.permissionArea,
      ),
    )
    return R.flatten(permissionAreas)
  }
  const itemChildrenPermissionList = getChildPermissions(item.children)
  const itemChildrenTotalCrud = useSelector(
    getMultipleAreaCRUDUnionForRoles(itemChildrenPermissionList),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const NotificationAreas = useSelector(getNotificationArea)
  const notificationAreaId = Utils.findConstantIdByName(
    item.notificationArea as string,
    NotificationAreas,
  )
  const notificationCount = useSelector(
    getNotificationsMenuTotalCountsForArea(notificationAreaId),
  ) as number

  const business = useSelector(getCurrentBusiness)
  const chatUnreadCount = useSelector(getChatUnreadCount)

  const reloadRedirect = useReloadRedirect()

  const isOpenPage = Boolean(getSelectedItem(item, location))

  const [expanded, setExpanded] = useState(isOpenPage || item.expanded)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const disabledByPermission =
    item.permissionArea && (isEmpty(permissions) || !permissions.read)
  const disabledByPermissionAction =
    item.permissionArea &&
    item.permissionAction &&
    (isEmpty(permissions) ||
      !permissions[item.permissionAction.toLowerCase() as Lowercase<CRUD>])
  const disabledByCondition = getIsDisabledByCondition(item, business)
  const disabledByChildrenPermissions =
    item.children &&
    !itemChildrenPermissionList.includes(undefined) &&
    (isEmpty(itemChildrenTotalCrud) || !itemChildrenTotalCrud.read) &&
    isCvcRolesEnabled
  const disabledByMultiplePermissions =
    item.permissionAreas &&
    item.permissionAreas.length > 0 &&
    (isEmpty(multiplePermissions) || !multiplePermissions.read)
  const disabled =
    disabledProp ||
    disabledByPermission ||
    disabledByPermissionAction ||
    disabledByCondition ||
    disabledByChildrenPermissions ||
    disabledByMultiplePermissions

  useEffect(() => {
    if (open && tooltipOpen) {
      setTooltipOpen(false)
    }
  }, [open, tooltipOpen])

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  if (item.hideIfNoPermissions && disabled) {
    return null
  }

  const childItems = (item.children || []).filter(
    (child) => !getIsDisabledByCondition(child, business) || child.showDisabled,
  )
  const hasChildren = childItems.length > 0
  const selected = paging || (expanded && hasChildren) ? false : isOpenPage
  const isSelectedOrDisabled = selected || disabled
  const iconColor = isSelectedOrDisabled ? 'inherit' : 'secondary'

  return (
    <>
      <Tooltip
        disableFocusListener
        disableTouchListener
        disableHoverListener={open}
        enterDelay={500}
        open={tooltipOpen}
        placement="right"
        title={item.tooltipText || item.text || ''}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
      >
        <Grid className={classes.container}>
          <ListItem
            button
            disableGutters
            className={classes.item}
            classes={{
              disabled: classes.disabledItem,
              root: classes.itemRoot,
              selected: classes.selectedItem,
            }}
            component={item.Component ?? 'div'}
            disabled={disabled || item.notActive}
            selected={selected}
            style={{
              paddingLeft: additionalPaddingLevel * PADDING_LEFT,
            }}
            onClick={(event: any) => {
              if (onClick) {
                onClick(event)
              }
              if (item.url) {
                reloadRedirect(item.url)
              }
              if (hasChildren && !item.doNotRenderChildren) {
                setExpanded(open ? !expanded : true)
              } else if (onItemSelected) {
                onItemSelected(item)
              }
            }}
          >
            {paging && <ArrowBackIcon className={classes.backArrow} />}
            <ListItemIcon className={classes.icon}>
              {item.icon ? (
                <item.icon color={iconColor} />
              ) : (
                <ExpandMore className={classes.noVisibility} />
              )}
              {item.notificationArea && (
                <NotificationCountBadge notificationCount={notificationCount} />
              )}
              {item.isChatItem && Boolean(chatUnreadCount) && (
                <NotificationCountBadge notificationCount={chatUnreadCount!} />
              )}
            </ListItemIcon>
            <ListItemText
              className={classNames(classes.listItemText, {
                [classes.textOpen]: open,
                [classes.textClose]: !open && item.icon,
              })}
              primary={item.text}
              primaryTypographyProps={{
                className: classes.primaryItemText,
                color: iconColor,
              }}
            />
            {hasChildren &&
              !item.doNotRenderChildren &&
              (!item.icon || open) &&
              (expanded ? (
                <ExpandLess
                  className={classNames(classes.expandItem, {
                    [classes.expandItemHidden]: !open && item.icon,
                  })}
                  color={iconColor}
                />
              ) : (
                <ExpandMore
                  className={classNames(classes.expandItem, {
                    [classes.expandItemHidden]: !open && item.icon,
                  })}
                  color={iconColor}
                />
              ))}
          </ListItem>
          {item.tooltipText && (
            <Tooltip
              className={classes.disabledTooltip}
              placement="right"
              title={item.tooltipText}
            >
              <ListItemIcon>
                <InfoIcon
                  className={classes.disabledTooltipIcon}
                  style={{ fill: theme.colors.selectedItem }}
                />
              </ListItemIcon>
            </Tooltip>
          )}
        </Grid>
      </Tooltip>
      {hasChildren && !item.doNotRenderChildren && (
        <Collapse unmountOnExit in={expanded}>
          <List disablePadding className={classes.childrenList} component="div">
            {childItems.map((child) => (
              <LeftMenuListItem
                additionalPaddingLevel={additionalPaddingLevel + 1}
                disabled={disabled}
                item={child}
                key={child.id}
                location={location}
                open={open}
                onItemSelected={onItemSelected}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default LeftMenuListItem
