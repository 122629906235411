import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Utils } from '@pbt/pbt-ui-components'

import PuiButtonGroup from '~/components/common/buttons/PuiButtonGroup'
import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import i18n from '~/locales/i18n'
import { getWidgetData } from '~/store/duck/landing'
import {
  getAIAgentProcessStatuses,
  getAIAgentProcessTypes,
  getDiagnosisStatus,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getUploadedDocuments } from '~/store/reducers/medicalHistory'
import { ProblemStates } from '~/types'

import RecordsComponent from './records/RecordsComponent'
import SnapshotsComponent from './snapshots/SnapshotsComponent'

const useStyles = makeStyles(
  (theme) => ({
    switchComponentContainer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'SnapshotsAndRecordsComponent' },
)

const ActiveMode = {
  SNAPSHOTS: 'snapshots',
  RECORDS: 'records',
}

const SnapshotsAndRecordsStates = [
  {
    label: i18n.t('Clients:SNAPSHOTS_AND_RECORDS.PATIENT_SNAPSHOTS_LABEL'),
    component: SnapshotsComponent,
  },
  {
    label: i18n.t('Clients:SNAPSHOTS_AND_RECORDS.PATIENT_RECORDS_LABEL'),
    component: RecordsComponent,
  },
]

const ModeRegex =
  /^\/client\/([A-Za-z0-9]+)\/patient\/([A-Za-z0-9]+)\/?([A-Za-z0-9]+)?/
const getModeFromUrl = (pathname: string) => pathname.match(ModeRegex)?.[3]

interface SnapshotsAndRecordsComponentProps {
  clientId: string
  patientId: string
}

const SnapshotsAndRecordsComponent = ({
  clientId,
  patientId,
}: SnapshotsAndRecordsComponentProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['MedicalHistory', 'Plurals'])

  const location = useLocation()
  const navigate = useNavigate()
  const activeTimelineType = getModeFromUrl(location.pathname)
  const isPmrAiSummarizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PMR_AI_SUMMARIZATION),
  )
  const isMasterProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MASTER_PROBLEMS_WIDGET),
  )

  const diagnoses = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      isMasterProblemsWidgetEnabled
        ? SnapshotsAliasTypes.Problems
        : SnapshotsAliasTypes.Diagnoses,
    ),
  )
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const AddedByAiDiagnosisStatusId = Utils.findConstantIdByName(
    ProblemStates.ADDED_BY_AI,
    DiagnosisStatus,
  )

  const pmrItemsForReview = diagnoses.filter(
    (diagnosis) => diagnosis.statusId === AddedByAiDiagnosisStatusId,
  )

  const uploadedDocuments = useSelector(getUploadedDocuments)
  const processStatusIds = useSelector(getAIAgentProcessStatuses)
  const processTypeIds = useSelector(getAIAgentProcessTypes)
  const ProblemProcessingTypeId = Utils.findConstantIdByName(
    'PMR Diagnosis Extraction',
    processTypeIds,
  )
  const ProcessingStatusTypeId = Utils.findConstantIdByName(
    'Processing',
    processStatusIds,
  )
  const CompletedStatusTypeId = Utils.findConstantIdByName(
    'Completed',
    processStatusIds,
  )

  const processingDocuments = uploadedDocuments.filter(
    (doc) =>
      doc.processStatuses.find(
        (ps) => ps.processTypeId === ProblemProcessingTypeId,
      )?.processStatusId === ProcessingStatusTypeId,
  )
  const readyForReviewDocuments = uploadedDocuments.filter(
    (doc) =>
      doc.processStatuses.find(
        (ps) => ps.processTypeId === ProblemProcessingTypeId,
      )?.processStatusId === CompletedStatusTypeId,
  )

  const setActiveMode = (mode: string) => {
    navigate(`/client/${clientId}/patient/${patientId}/${mode}`, {
      replace: true,
    })
  }

  useEffect(() => {
    if (
      !activeTimelineType ||
      !Object.values(ActiveMode).includes(activeTimelineType)
    ) {
      setActiveMode(ActiveMode.SNAPSHOTS)
    }
  }, [location.pathname, location.search])

  const activeState =
    activeTimelineType === ActiveMode.RECORDS
      ? SnapshotsAndRecordsStates[1]
      : SnapshotsAndRecordsStates[0]

  const Component = activeState.component

  return (
    <Grid container direction="column">
      <Grid
        container
        className={classes.switchComponentContainer}
        px={2}
        py={1}
      >
        <PuiButtonGroup
          items={SnapshotsAndRecordsStates}
          selectedItem={activeState}
          onItemSelected={() =>
            setActiveMode(
              activeTimelineType === ActiveMode.SNAPSHOTS
                ? ActiveMode.RECORDS
                : ActiveMode.SNAPSHOTS,
            )
          }
        />
      </Grid>
      {isPmrAiSummarizationEnabled &&
        readyForReviewDocuments.map((document) => {
          const itemsCount = pmrItemsForReview.filter(
            (item) =>
              item.previousMedicalRecordId ===
              document.referenceEntity.entityId,
          ).length

          if (itemsCount === 0) {
            return null
          }

          return (
            <Grid key={document.referenceEntity.entityId} mt={1} px={2}>
              <AlertBanner
                ContainerProps={{ width: '100%' }}
                actions={{
                  linkText: t('Plurals:Z_ICU_WORKAROUND.REVIEW_ITEMS', {
                    numRecords: itemsCount,
                  }),
                  onClick: () =>
                    navigate(
                      `/client/${clientId}/patient/${patientId}/previous-medical-records/${document.referenceEntity.entityId}`,
                    ),
                }}
                contentMargin={0.5}
                title={t(
                  'MedicalHistory:PMR_AI_SUMMARIZATION.READY_FOR_REVIEW',
                  {
                    documentName: document.referenceEntity.fileName,
                  },
                )}
                variant="good"
              />
            </Grid>
          )
        })}
      {isPmrAiSummarizationEnabled &&
        processingDocuments.map((document) => (
          <Grid key={document.referenceEntity.entityId} mt={1} px={2}>
            <AlertBanner
              ContainerProps={{ width: '100%' }}
              title={t(
                'MedicalHistory:PMR_AI_SUMMARIZATION.PROCESSING_DOCUMENT',
                {
                  documentName: document.referenceEntity.fileName,
                },
              )}
              variant="loading"
            />
          </Grid>
        ))}
      {activeTimelineType && (
        <Component clientId={clientId} patientId={patientId} />
      )}
    </Grid>
  )
}

export default SnapshotsAndRecordsComponent
