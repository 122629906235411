import React from 'react'
import { PhoneUtils } from '@pbt/pbt-ui-components'

import { ShippingAddress } from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'
import { formatCityStatePostcode } from '~/utils/cvcClient'

export const ChewyFullShippingAddress = ({
  fullName,
  address,
  phone1,
}: ShippingAddress) => (
  <div style={{ whiteSpace: 'normal' }}>
    <Typography.H3>{fullName}</Typography.H3>
    <Typography.Paragraph>{address.street1}</Typography.Paragraph>
    {address.street2 && (
      <Typography.Paragraph>{address.street2}</Typography.Paragraph>
    )}
    <Typography.Paragraph>
      {formatCityStatePostcode(address)}
    </Typography.Paragraph>
    <Typography.Paragraph>
      {PhoneUtils.formatPhoneNumber(PhoneUtils.parsePhoneNumber(`${phone1}`))}
    </Typography.Paragraph>
  </div>
)
