import { useSelector } from 'react-redux'
import { Business, WellnessPlanBenefit } from '@pbt/pbt-ui-components'

import { getBenefitIsAccessToBoop } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'

const useBoopEnabled = (business?: Business) => {
  const currentBusinessOpenBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const openBoop = business ? business?.openBoop : currentBusinessOpenBoop

  return openBoop
}

const removeBoopBenefitFromList = (benefits: WellnessPlanBenefit[]) =>
  benefits.filter((benefit) => !getBenefitIsAccessToBoop(benefit))

const useBoopFilteredBenefits = (benefits: WellnessPlanBenefit[]) =>
  useBoopEnabled() ? benefits : removeBoopBenefitFromList(benefits)

export { useBoopEnabled, useBoopFilteredBenefits }
