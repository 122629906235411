import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  LanguageUtils,
  NumberUtils,
  PuiDialog,
  useFields,
} from '@pbt/pbt-ui-components'

import { editLabTestPrice } from '~/store/actions/labTests'
import { getSpecies } from '~/store/reducers/constants'
import { getLabTestsIsEditing } from '~/store/reducers/labTests'
import { LabTest, Price } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import InfoList from '../../InfoList'
import PuiSwitch from '../../PuiSwitch'

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'ReadOnlyLabTestPriceDialog' },
)

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

interface ReadOnlyLabTestPriceDialogProps extends BasePuiDialogProps {
  labTest: LabTest
  price: Price
}

const ReadOnlyLabTestPriceDialog = ({
  open,
  onClose,
  labTest,
  price,
}: ReadOnlyLabTestPriceDialogProps) => {
  const useStylesProps: UseStylesProps = {}
  const classes = useStyles(useStylesProps)
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const isEditing: boolean = useSelector(getLabTestsIsEditing)
  const Species = useSelector(getSpecies)
  const availableSpecies = labTest.speciesIds?.length
    ? Species.filter((item) => labTest.speciesIds?.includes(item.id))
    : Species

  const dialogTitle: string = [
    t('Common:EDIT_PRICE'),
    LanguageUtils.getTranslatedFieldName(labTest || {}),
  ]
    .filter(Boolean)
    .join(' | ')

  const { fields, reset } = useFields(
    [
      {
        name: 'allowDiscount',
        label: t('Common:ALLOW_DISCOUNT'),
        initialValue: price.discountAllowed ?? true,
        type: 'toggle',
      },
      {
        name: 'taxable',
        label: t('Common:TAXABLE'),
        initialValue: price.taxable,
        type: 'toggle',
      },
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        initialValue: price.active ?? true,
        type: 'toggle',
      },
    ],
    false,
  )

  const { allowDiscount, taxable, active } = fields

  useEffect(() => {
    reset()
  }, [price])

  const setCloseOnEdited: () => void = useCloseAfterCreation(
    onClose,
    getLabTestsIsEditing,
  )

  const handleSave = () => {
    setCloseOnEdited()
    dispatch(
      editLabTestPrice(labTest.id, {
        ...price,
        discountAllowed: allowDiscount.value,
        taxable: taxable.value,
        active: active.value,
      }),
    )
  }

  const labTestPriceInfo = [
    { name: t('Common:PRICE_NAME'), value: price.name },
    {
      name: t('Common:PRICE'),
      value: NumberUtils.formatMoney(price.price ?? 0),
    },
    {
      name: t('Common:SPECIES'),
      value: LanguageUtils.getConstantTranslatedName(
        price.speciesId,
        availableSpecies,
      ),
    },
    {
      name: t('Common:UNIT'),
      value: useGetFormattedPriceUnit(price) as string,
    },
    { name: t('Common:CODE'), value: price.originalCode || '' },
  ]

  return (
    <PuiDialog
      confirmSaveOnClose
      fullWidth
      ConfirmCloseDialogProps={{
        onOk: handleSave,
      }}
      actions={
        <ButtonWithLoader loading={isEditing} onClick={handleSave}>
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => isFieldValuesChanged(fields)}
      maxWidth="sm"
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <InfoList items={labTestPriceInfo} />
      <Grid container item direction="column" mt={2} rowSpacing={1}>
        <Grid item>
          <PuiSwitch field={allowDiscount} label={allowDiscount.label} />
        </Grid>
        <Grid item>
          <PuiSwitch field={taxable} label={taxable.label} />
        </Grid>
        <Grid item>
          <PuiSwitch field={active} label={active.label} />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default ReadOnlyLabTestPriceDialog
