import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { RetailOrderStates } from '~/constants/invoice'

import { getRetailOrderStates } from '../reducers/constants'

export const useGetChewyOrderState = () => {
  const RetailOrderStatesConstant = useSelector(getRetailOrderStates)

  return (stateId: string | undefined) => {
    const currentState = Utils.findById(stateId, RetailOrderStatesConstant)
    return {
      currentState,
      isDraft: currentState?.name === RetailOrderStates.DRAFT,
      isMovedToChewyCart:
        currentState?.name === RetailOrderStates.MOVED_TO_CHEWY_CART,
      isOrderPlaced: currentState?.name === RetailOrderStates.ORDER_PLACED,
    }
  }
}
