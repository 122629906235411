import React, { ReactNode } from 'react'
import isPropValid from '@emotion/is-prop-valid'
import { Box, BoxProps, css, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PaletteColor } from '@pbt/pbt-ui-components'
import { getThemeColor } from '@pbt/pbt-ui-components/src/muiTheme'

import Typography from '~/components/elements/Typography/Typography'

import AlertIcon from '../Icons/generated/AlertIcon'
import DropdownToggleIcon from '../Icons/generated/DropdownToggleIcon'

export const statuses = [
  'normal',
  'warning',
  'danger',
  'good',
  'complete',
  'canceled',
  'inactive',
  'deceased',
  'chronic',
  'error',
  'chewy',
] as const

type StatusVariant = (typeof statuses)[number]

const statusColors: Record<
  StatusVariant,
  { bg: PaletteColor; color: PaletteColor; toggle: PaletteColor }
> = {
  chewy: {
    bg: 'ui-bg/10',
    color: 'message/info/primary',
    toggle: 'grayGray2',
  },
  normal: {
    bg: 'shadesNormal',
    color: 'brandPrimary',
    toggle: 'grayGray2',
  },
  warning: {
    bg: 'shadesNeedsAttention',
    color: 'uiNeedsAttention',
    toggle: 'grayGray2',
  },
  danger: {
    bg: 'shadesError',
    color: 'uiError',
    toggle: 'grayGray2',
  },
  good: {
    bg: 'shadesSuccess',
    color: 'uiSuccess',
    toggle: 'grayGray2',
  },
  complete: {
    bg: 'uiSuccess',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  canceled: {
    bg: 'grayGray4',
    color: 'grayGray2',
    toggle: 'grayGray2',
  },
  inactive: {
    bg: 'grayGray2',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  deceased: {
    bg: 'grayGray2',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  chronic: {
    bg: 'brandPrimary',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  error: {
    bg: 'uiError',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
}

export const getColor = ({
  prop,
  variant,
}: {
  prop: 'bg' | 'color' | 'toggle'
  variant: StatusVariant
}) => getThemeColor(statusColors[variant][prop])

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'withToggle',
})<{ variant: StatusVariant; withToggle?: boolean }>`
  ${({ withToggle }) =>
    withToggle
      ? css`
          cursor: pointer;
        `
      : ''}
  background-color: ${({ variant }) => getColor({ prop: 'bg', variant })};
  color: ${({ variant }) => getColor({ prop: 'color', variant })};
  display: flex;
  width: fit-content;
  max-width: 100%;
  border-radius: 2px;
  gap: 2px;
`

const StyledTypographyLabel = styled(Typography.Label, {
  shouldForwardProp: (props: string) =>
    isPropValid(props) && props !== 'hasStartIcon' && props !== 'hasEndIcon',
})<{
  hasEndIcon?: boolean
  hasStartIcon?: boolean
}>`
  flex-grow: 1;
  padding: ${({ theme, hasStartIcon, hasEndIcon }) =>
    theme.spacing(0.125, hasEndIcon ? 0 : 1, 0.125, hasStartIcon ? 0 : 1)};
`

export type StatusProps = BoxProps & {
  ellipsis?: boolean
  endIcon?: ReactNode
  labelSx?: SxProps
  startIcon?: ReactNode
  variant?: StatusVariant
  withAlertIcon?: boolean
  withToggle?: boolean
}

export const Status = ({
  variant = 'normal',
  startIcon,
  endIcon,
  children,
  withAlertIcon = false,
  withToggle = false,
  ellipsis = false,
  labelSx,
  ...props
}: StatusProps) => (
  <StyledBox variant={variant} withToggle={withToggle} {...props}>
    <>
      {withAlertIcon ? <AlertIcon /> : startIcon}
      <StyledTypographyLabel
        color={statusColors[variant].color}
        ellipsis={ellipsis}
        hasEndIcon={Boolean(withToggle || endIcon)}
        hasStartIcon={Boolean(withAlertIcon || startIcon)}
        sx={labelSx}
      >
        {children}
      </StyledTypographyLabel>
      {withToggle ? (
        <DropdownToggleIcon
          sx={{
            color: getColor({ prop: 'toggle', variant }),
          }}
        />
      ) : (
        endIcon
      )}
    </>
  </StyledBox>
)

// eslint-disable-next-line react/no-multi-comp
export const StatusError = ({
  variant = 'error',
  withAlertIcon = true,
  ellipsis = true,
  ...props
}: StatusProps) => (
  <Status
    ellipsis={ellipsis}
    variant={variant}
    withAlertIcon={withAlertIcon}
    {...props}
  />
)

// eslint-disable-next-line react/no-multi-comp
export const StatusWarning = ({
  variant = 'warning',
  ...props
}: StatusProps) => <StatusError variant={variant} {...props} />
