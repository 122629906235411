export const UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES =
  'timetable/UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPES'
export const UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS =
  'timetable/UPDATE_TIMETABLE_FILTERED_PERSON_GROUPS'
export const UPDATE_TIMETABLE_FILTERED_PERSONS =
  'timetable/UPDATE_TIMETABLE_FILTERED_PERSONS'
export const UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS =
  'timetable/UPDATE_TIMETABLE_FILTERED_APPOINTMENT_TYPE_GROUPS'

export const CREATE_APPOINTMENT = 'timetable/CREATE_APPOINTMENT'
export const CREATE_APPOINTMENT_FAILURE = 'timetable/CREATE_APPOINTMENT_FAILURE'
export const CREATE_APPOINTMENT_SUCCESS = 'timetable/CREATE_APPOINTMENT_SUCCESS'

export const FETCH_APPOINTMENT = 'timetable/FETCH_APPOINTMENT'
export const FETCH_APPOINTMENT_FAILURE = 'timetable/FETCH_APPOINTMENT_FAILURE'
export const FETCH_APPOINTMENT_SUCCESS = 'timetable/FETCH_APPOINTMENT_SUCCESS'

export const EDIT_APPOINTMENT = 'timetable/EDIT_APPOINTMENT'
export const EDIT_APPOINTMENT_FAILURE = 'timetable/EDIT_APPOINTMENT_FAILURE'
export const EDIT_APPOINTMENT_SUCCESS = 'timetable/EDIT_APPOINTMENT_SUCCESS'

export const PATCH_APPOINTMENT = 'timetable/PATCH_APPOINTMENT'
export const PATCH_APPOINTMENT_FAILURE = 'timetable/PATCH_APPOINTMENT_FAILURE'
export const PATCH_APPOINTMENT_SUCCESS = 'timetable/PATCH_APPOINTMENT_SUCCESS'
export const PATCH_APPOINTMENT_ABOLISH = 'timetable/PATCH_APPOINTMENT_ABOLISH'
export const CLEAR_PATCHED_APPOINTMENT_DATA =
  'timetable/CLEAR_PATCHED_APPOINTMENT_DATA'

export const DELETE_APPOINTMENT = 'timetable/DELETE_APPOINTMENT'
export const DELETE_APPOINTMENT_FAILURE = 'timetable/DELETE_APPOINTMENT_FAILURE'
export const DELETE_APPOINTMENT_SUCCESS = 'timetable/DELETE_APPOINTMENT_SUCCESS'

export const CANCEL_FUTURE_APPOINTMENTS = 'timetable/CANCEL_FUTURE_APPOINTMENTS'
export const CANCEL_FUTURE_APPOINTMENTS_FAILURE =
  'timetable/CANCEL_FUTURE_APPOINTMENTS_FAILURE'
export const CANCEL_FUTURE_APPOINTMENTS_SUCCESS =
  'timetable/CANCEL_FUTURE_APPOINTMENTS_SUCCESS'

export const CREATE_BUSY_TIME = 'timetable/CREATE_BUSY_TIME'
export const CREATE_BUSY_TIME_FAILURE = 'timetable/CREATE_BUSY_TIME_FAILURE'
export const CREATE_BUSY_TIME_SUCCESS = 'timetable/CREATE_BUSY_TIME_SUCCESS'

export const FETCH_BUSY_TIME = 'timetable/FETCH_BUSY_TIME'
export const FETCH_BUSY_TIME_FAILURE = 'timetable/FETCH_BUSY_TIME_FAILURE'
export const FETCH_BUSY_TIME_SUCCESS = 'timetable/FETCH_BUSY_TIME_SUCCESS'

export const EDIT_BUSY_TIME = 'timetable/EDIT_BUSY_TIME'
export const EDIT_BUSY_TIME_FAILURE = 'timetable/EDIT_BUSY_TIME_FAILURE'
export const EDIT_BUSY_TIME_SUCCESS = 'timetable/EDIT_BUSY_TIME_SUCCESS'

export const DELETE_BUSY_TIME = 'timetable/DELETE_BUSY_TIME'
export const DELETE_BUSY_TIME_FAILURE = 'timetable/DELETE_BUSY_TIME_FAILURE'
export const DELETE_BUSY_TIME_SUCCESS = 'timetable/DELETE_BUSY_TIME_SUCCESS'

export const UPDATE_EVENTS = 'timetable/UPDATE_EVENTS'
export const ADD_ESTIMATE_TO_EVENT = 'timetable/ADD_ESTIMATE_TO_EVENT'

export const ADD_FORM = 'timetable/ADD_FORM'
export const ADD_FORM_FAILURE = 'timetable/ADD_FORM_FAILURE'
export const ADD_FORM_SUCCESS = 'timetable/ADD_FORM_SUCCESS'

export const DELETE_FORM = 'timetable/DELETE_FORM'
export const DELETE_FORM_FAILURE = 'timetable/DELETE_FORM_FAILURE'
export const DELETE_FORM_SUCCESS = 'timetable/DELETE_FORM_SUCCESS'

export const CLEAR_APPOINTMENT_VALIDATION_ERROR =
  'timetable/CLEAR_APPOINTMENT_VALIDATION_ERROR'

export const ADD_TIMETABLE_EVENT_SOAP = 'timetable/ADD_TIMETABLE_EVENT_SOAP'

export const UPDATE_APPOINTMENT_STATUS = 'timetable/UPDATE_APPOINTMENT_STATUS'
export const UPDATE_APPOINTMENT_STATUS_FAILURE =
  'timetable/UPDATE_APPOINTMENT_STATUS_FAILURE'
export const UPDATE_APPOINTMENT_STATUS_SUCCESS =
  'timetable/UPDATE_APPOINTMENT_STATUS_SUCCESS'
export const ENABLE_TIMETABLE_AUTO_REFRESH =
  'timetable/ENABLE_TIMETABLE_AUTO_REFRESH'
export const DISABLE_TIMETABLE_AUTO_REFRESH =
  'timetable/DISABLE_TIMETABLE_AUTO_REFRESH'

export const SET_TIMETABLE_FILTERS = 'timetable/SET_TIMETABLE_FILTERS'

export const SET_DEFAULT_SCHEDULER_FILTERS =
  'timetable/SET_DEFAULT_SCHEDULER_FILTERS'
export const SET_DEFAULT_WHITEBOARD_FILTERS =
  'timetable/SET_DEFAULT_WHITEBOARD_FILTERS'

export const SET_SCHEDULING_ASSIGNMENT = 'timetable/SET_SCHEDULING_ASSIGNMENT'
export const CLEAR_SCHEDULING_ASSIGNMENT =
  'timetable/CLEAR_SCHEDULING_ASSIGNMENT'

export const UPDATE_APPOINTMENT_NOTES = 'timetable/UPDATE_APPOINTMENT_NOTES'
export const UPDATE_APPOINTMENT_NOTES_SUCCESS =
  'timetable/UPDATE_APPOINTMENT_NOTES_SUCCESS'
export const UPDATE_APPOINTMENT_NOTES_FAILURE =
  'timetable/UPDATE_APPOINTMENT_NOTES_FAILURE'

export const TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX =
  'TOGGLE_PET_PARENT_IS_AWARE_OF_CANCELLATION_POLICY_CHECKBOX'
