import { NamedEntity, Nil, Patient, User } from '@pbt/pbt-ui-components'

import {
  RetailOrderLineItem,
  RxPrescriptionOrigin,
} from '~/api/graphql/generated/types'
import { ListFilterType } from '~/components/dashboard/soap/list-with-filters/ListFilter'
import { PrescriptionType } from '~/constants/prescription'
import { OrderType } from '~/constants/SOAPStates'

import { InvoiceLineItem } from './finance'
import { InventoryItem, Price, Variation } from './inventories'
import { LabTest } from './labTests'
import { PatientUpdateReason } from './patients'

export type OrderNestedItem = 'inventory' | 'procedure' | 'labTest'

export type OrderVaccinationDetails = {
  administeredBy?: string
  amount?: number
  amountId?: string
  county?: string
  dateGiven?: string
  deliveryMethodId?: string
  doseTypeId?: string
  dueDate?: string
  id?: string
  location?: string
  locationId?: string
  lotExpiration?: string
  lotNumber?: string
  manufacturedBy?: string
  rabiesTag?: string
  rabiesTagExpiration?: string
  rabiesTagExpirationOptionId?: string
  rabiesTagExpirationUnitId?: string
  rabiesTagExpires?: string
  serialNumber?: string
  signature?: string
  signerId?: string
  typeId?: string
  vaccineDefaultAmount?: number
  vaccineDefaultAmountId?: string
  vaccineDefaultDeliveryLocation?: string
  vaccineDefaultDeliveryLocationId?: string
  vaccineDefaultDoseTypeId?: string
  vaccineDeliveryMethodId?: string
  vaccineDurationOfImmunity?: string
  vaccineDurationOfImmunityOptionId?: string
  vaccineDurationOfImmunityUnitId?: string
  vaccineTypeId?: string
}

export type OrderProcedure = {
  category?: string
  description?: string
  descriptionTranslation?: string
  id: string
  modalityId?: string
  procedureCategoryId?: string
  procedureCode: string
}

export type Order = NamedEntity & {
  administrationFrequencyId?: string
  administrationFrequencyNotes?: string
  administrationRouteId?: string
  autoshipFrequency?: number | Nil
  autoshipUnitId?: string | Nil
  bundleId?: string | Nil
  businessId?: string | Nil
  categoryName?: string
  charge?: boolean
  clientId?: string
  cover?: boolean
  creationDate?: string
  doctorId?: string
  drugMasterName?: string | Nil
  dueDatetime?: string
  globalInventory?: InventoryItem
  globalInventoryMapping?: InventoryItem
  globalVariation?: Variation
  globalVariationMapping?: Variation
  group?: string | Nil
  imagingOrderId?: string
  inventory?: InventoryItem
  inventoryLogStatusId?: string
  invoiceId?: string | Nil
  isFirstItemInCategory?: boolean
  isLastItemInCategory?: boolean
  items?: Order[]
  labTest?: LabTest
  limit?: number
  limitReached?: boolean
  lineItem?: InvoiceLineItem | RetailOrderLineItem
  logId?: string | Nil
  logState?: {
    id: string
  } | null
  logType?: string | Nil
  modificationDate?: string
  notes?: string | Nil
  orderStateId?: string
  origin?: RxPrescriptionOrigin | Nil
  parent?: Order
  parentId?: string
  patientId?: string
  patientUpdateReason?: PatientUpdateReason
  patientUpdateReverted?: boolean
  planName?: string
  prepaid?: boolean
  prepaidBox?: {
    remaining: number
  } | null
  prescriptionType?: PrescriptionType
  previousEntityType?: string
  price: Price
  procedure?: OrderProcedure
  quantity?: number
  refillRemains?: number
  refills?: unknown[]
  rejectionReason?: string | Nil
  retailOrderLineItem?: {
    // Cant use GQL RetailOrderLineItem here because BE gives us different structure
    autoshipFrequency?: number | Nil
    autoshipUnitId?: string | Nil
    id: string
    modificationDate?: string
  }
  signatureUrl?: string
  signedDate?: string
  signer?: User
  signerId?: string
  soapId?: string | Nil
  stateId?: string
  taskStateId?: string
  taskTemplate?: {
    id: string
  }
  totalQuantity?: number
  type: OrderType
  updatedPatient?: Patient
  usageCount?: number
  usedQuantity?: number | Nil
  vaccinationDetails?: OrderVaccinationDetails
  variation?: Variation
}

export type OrderForCreate = Partial<Order> & {
  clientId: string
}

export type DispensedFromData = {
  businessReferenceNumber?: string
  expirationDate?: string
  lineItemQuantity?: string
  lotNumber?: string
  manufacturerId?: string
  manufacturerName?: string
  serialNumber?: string
}

export type ChargeData = {
  price?: Price
  priceId?: string
  quantity?: number
}

export type FetchOrdersOptions = {
  applyPatientRestriction?: boolean
  categories?: string[] | Nil
  clientId: string
  entityType?: string | Nil
  eventId?: string
  filters?: string[]
  forShipments?: boolean
  from?: number | Nil
  labVendorId?: string
  listType: OrderListType
  patientId: string
  searchTerm?: string
  subcategories?: string[] | Nil
  to?: number | Nil
  withTasks?: boolean
}

export type SignatureInfo = {
  signature: string | null
  signerId: string | null
}

export type CRUDOrderOptions = {
  afterSaveCallback?: () => void
  allowEditPostedItem?: boolean
  declineReason?: string
  doctorId?: string | Nil
  email?: boolean
  fromInvoice?: boolean
  fromTimeline?: boolean
  invoiceId?: string | Nil
  isActiveRxApprove?: boolean
  isActiveRxApproveAndSubmit?: boolean
  isActiveRxDecline?: boolean
  isActiveRxSaveDraft?: boolean
  isActiveRxUndecline?: boolean
  isApprove?: boolean
  isSaveDraft?: boolean
  outsideSoap?: boolean
  previousEntityType?: string
  print?: boolean
  signatureInfo?: SignatureInfo
  soapId?: string | null
  submitterId?: string
  teamMemberId?: string
  type?: string
}

export type OrderFilter = ListFilterType

export type OrderDetails = {
  globalVariation?: Variation
  globalVariationMapping?: Variation
  prices: Price[]
  variation: Variation
}

export type OrderSearchFilter = {
  name: string
  nameTranslation: string
  type: string
}

export type OrderSearchResult = {
  filter: OrderSearchFilter[]
  item: Order
}

export type OrderCallbackWithTypeCheck = (
  order: Order,
  checkOptions?: {
    isDrug?: boolean
    isFood?: boolean
    isPrescribable?: boolean
    isVetDiet?: boolean
  },
) => void

export type PendingPrescriptionParams = {
  onAfterSave: () => void
  options: CRUDOrderOptions
  prescription: Order | Nil
}

export type SaveOrderHandler = (
  order: Order,
  options?: CRUDOrderOptions,
  handleCloseAfterSaving?: () => void,
) => void

export enum OrderListType {
  FULL = 'FULL',
  OUR = 'OUR',
}

export type OrderCountFilters = {
  applyPatientRestriction: boolean
  clientId: string
  eventId: string
  patientId: string
  types: OrderType[]
}
