import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Chip, Fab, Grid, GridProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  LanguageUtils,
  PrimitiveTableRowProps,
  Text,
} from '@pbt/pbt-ui-components'
import { getColumnStyles } from '@pbt/pbt-ui-components/src/utils/primitiveTableUtils'

import {
  FlagIcon,
  ThermometerIcon,
} from '~/components/elements/Icons/generated'
import { getPackageType } from '~/store/reducers/constants'
import { InventoryOrderItem } from '~/types'

import ShipmentItemStatusLabel from '../../shipments/ShipmentItemStatusLabel'

interface StyledRowContainerProps extends GridProps {
  index: number
}

const StyledRowContainer = styled(Grid)<StyledRowContainerProps>`
  border-top: none;
  border-bottom: none;
  background-color: ${({ index, theme }) =>
    index % 2 === 0 ? theme.colors.grayGray4 : 'unset'};
`

const StyledItemLabelChip = styled(Chip)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.colors.important,
  borderRadius: 1,
  fontWeight: theme.typography.fontWeightBold,
  minWidth: 0,
  '& .MuiChip-icon': {
    color: 'white',
  },
}))

interface OrderItemTableRowProps extends PrimitiveTableRowProps {
  index: number
  item: InventoryOrderItem
}

export const OrderItemTableRow = ({
  item: {
    name,
    statusId,
    orderedQuantity,
    receivedQuantity,
    damagedQuantity,
    packageTypeId,
    sku,
    inventoryItemFlags: { controlled, storeCold },
  },
  index,
  data: { mainColumnWidth, otherColumns },
}: OrderItemTableRowProps) => {
  const { t } = useTranslation(['Admin', 'Common'])
  const PackageType = useSelector(getPackageType)
  const packageType = LanguageUtils.getConstantTranslatedName(
    packageTypeId,
    PackageType,
  )

  return (
    <StyledRowContainer container alignItems="center" index={index} p={1}>
      <Grid
        item
        alignItems="center"
        display="flex"
        gap={1}
        justifyContent="space-between"
        px={0.5}
        style={getColumnStyles(mainColumnWidth)}
      >
        <Box display="flex" flexDirection="column" gap={1} minWidth={0}>
          <Text variant="body2">{name}</Text>
          <Box display="flex" gap={1}>
            {controlled && (
              <StyledItemLabelChip
                icon={<FlagIcon />}
                label={t('Common:CONTROLLED_SUBSTANCE')}
                size="small"
                title={t('Common:CONTROLLED_SUBSTANCE').toString()}
              />
            )}
            {storeCold && (
              <StyledItemLabelChip
                icon={<ThermometerIcon />}
                label={t('Common:STORE_COLD')}
                size="small"
                title={t('Common:STORE_COLD').toString()}
              />
            )}
          </Box>
        </Box>
        <Fab
          color="primary"
          size="small"
          sx={{ px: 4 }}
          variant="extended"
          onClick={() => {}}
        >
          {t('Admin:CATALOG.ORDERS.RECEIVE_ACTION')}
        </Fab>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        style={getColumnStyles(12 - mainColumnWidth)}
      >
        <Grid
          item
          display="flex"
          px={0.5}
          style={getColumnStyles(otherColumns[0].width)}
        >
          <ShipmentItemStatusLabel statusId={statusId} />
        </Grid>
        <Grid item px={0.5} style={getColumnStyles(otherColumns[1].width)}>
          <Text variant="body2">{orderedQuantity}</Text>
        </Grid>
        <Grid item px={0.5} style={getColumnStyles(otherColumns[2].width)}>
          <Text variant="body2">{receivedQuantity ?? '-'}</Text>
        </Grid>
        <Grid item px={0.5} style={getColumnStyles(otherColumns[3].width)}>
          <Text variant="body2">{damagedQuantity ?? '-'}</Text>
        </Grid>
        <Grid item px={0.5} style={getColumnStyles(otherColumns[4].width)}>
          <Text variant="body2">{packageType}</Text>
        </Grid>
        <Grid item px={0.5} style={getColumnStyles(otherColumns[5].width)}>
          <Text variant="body2">{sku ?? '-'}</Text>
        </Grid>
      </Grid>
    </StyledRowContainer>
  )
}
