/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'

import {
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import Link from '~/components/common/link/Link'
import { getPatient } from '~/store/reducers/patients'
import { Invoice } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'

import { MultipleLinesCell } from './MultipleLinesCell'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textDecorationColor: theme.colors.secondaryText,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'InvoiceTablePatientNameCell' },
)

interface InvoiceTablePatientNameCellProps {
  hideRetailOrder?: boolean
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const InvoiceLineItem = (section: ChargesSection) => {
  const classes = useStyles()

  const patientId = 'patient' in section ? section.patient?.id : ''
  const patientName = 'patient' in section ? section.patient?.name : ''

  return (
    <Link
      className={classes.link}
      id="credit-adjustment-patient-link"
      target="_self"
      to={`/patient/${patientId}`}
    >
      {patientName}
    </Link>
  )
}

const RetailOrderLineItem = (section: UIRoliSection) => {
  const classes = useStyles()
  const patients = section.patients ?? []

  return (
    <Box display="block" overflow="hidden" textOverflow="ellipsis">
      {patients.map((patient, index: number) => (
        <Link
          className={classes.link}
          id="credit-adjustment-patient-link"
          key={patient.id}
          style={{ textDecoration: 'none' }}
          target="_self"
          to={`/patient/${patient.id}`}
        >
          <>
            <span style={{ textDecoration: 'underline' }}>{patient.name}</span>
            <span style={{ textDecoration: 'none' }}>
              {index < patients.length - 1 && ',\u00A0'}
            </span>
          </>
        </Link>
      ))}
    </Box>
  )
}

export const InvoiceTablePatientNameCell = ({
  hideRetailOrder = false,
  invoice,
  multipleRowsLimit,
}: InvoiceTablePatientNameCellProps) => {
  const classes = useStyles()

  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const invoicePatientId = sections ? null : (invoice as Invoice).patientId
  const patientFromStore = useSelector(getPatient(invoicePatientId))

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItem}
        RetailOrderLineItem={RetailOrderLineItem}
        hideRetailOrder={hideRetailOrder}
        sections={sections}
      />
    )
  }

  if (!patientFromStore) {
    return null
  }

  return (
    <Link
      className={classes.link}
      id="credit-adjustment-patient-link"
      target="_blank"
      to={`/patient/${patientFromStore.id}`}
    >
      {patientFromStore.name}
    </Link>
  )
}
