import { findByProp } from '@pbt/pbt-ui-components/src/utils'

import { Price } from '~/api/graphql/generated/types'

export enum AppointmentPenaltyProcedureCodes {
  LATE_CANCELLATION_FEE = '57297',
  MISSED_APPOINTMENT_FEE = '57299',
}

export const getPenaltyFeeByOriginalCode = (
  prices: Partial<Price>[] | undefined,
  originalCode: AppointmentPenaltyProcedureCodes,
): number | null =>
  findByProp(originalCode, prices, 'originalCode')?.price || null
