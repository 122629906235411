import { useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getDocumentsV2ByIds } from '~/store/duck/documentsV2'
import {
  getDisplayLocations,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getTimetableEventFormsByAppointmentId } from '~/store/reducers/timetable'
import { TimetableEvent } from '~/types'

export enum DisplayLocationName {
  'CLIENT' = 'Client preferences',
  'PATIENT' = 'Patient preferences',
}

export const useFormPreferencesDisplay = (
  displayLocation: DisplayLocationName,
  appointment?: TimetableEvent | Nil,
  clientId?: string | Nil,
) => {
  const DisplayLocations = useSelector(getDisplayLocations)
  const displayLocationId = Utils.findConstantByName(
    displayLocation,
    DisplayLocations,
  )

  const eventForms = useSelector(
    getTimetableEventFormsByAppointmentId(appointment?.id),
  )

  const forms = useSelector(
    getDocumentsV2ByIds(clientId, appointment?.patientId),
  )

  const isSOAPConsentFormsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CONSENT_FORMS),
  )
  const showFormInfo = Boolean(appointment) && isSOAPConsentFormsEnabled

  return showFormInfo
    ? (eventForms || forms).filter((form) =>
        form.displayLocationIds?.includes(displayLocationId.id),
      )
    : undefined
}
