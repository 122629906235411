import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, MenuList } from '@mui/material'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

interface EstimatesTakeDepositActionSubMenuProps {
  anchorEl: PuiPopperProps['anchorEl']
  onClick: (sendPaymentLink: boolean) => void
  onClose: PuiPopperProps['onClose']
}

export const EstimatesTakeDepositActionSubMenu = ({
  anchorEl,
  onClick,
  onClose,
}: EstimatesTakeDepositActionSubMenuProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      open={Boolean(anchorEl)}
      placement="right-start"
      onClose={onClose}
    >
      <MenuList>
        <MenuItem onClick={() => onClick(false)}>
          {t('Common:RECORD_DEPOSIT_ACTION')}
        </MenuItem>
        <MenuItem onClick={() => onClick(true)}>
          {t('Payments:SEND_PAYMENT_LINK')}
        </MenuItem>
      </MenuList>
    </PuiPopper>
  )
}
