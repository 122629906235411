import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  moment,
  PermissionArea,
  PuiTooltip,
  useFields,
  WellnessPlan,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import PuiSwitch from '~/components/common/PuiSwitch'
import { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { MembershipAutoRenewalAllowed } from '~/constants/wellnessPlansConstants'
import { toggleAutoRenewalPlan } from '~/store/actions/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getWellnessPlansError } from '~/store/reducers/wellnessPlans'

const useStyles = makeStyles(
  (theme) => ({
    toggle: {
      marginRight: theme.spacing(1),
    },
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
  }),
  { name: 'WellnessPlanAutoRenewalToggle' },
)

export interface WellnessPlanAutoRenewalToggleProps {
  className: string
  plan: WellnessPlan
}

const WellnessPlanAutoRenewalToggle = ({
  className,
  plan,
}: WellnessPlanAutoRenewalToggleProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const isWpAutoRenewalLinkingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_LINKING_AUTO_RENEWAL),
  )
  const wellnessPlanError = useSelector(getWellnessPlansError)

  // User can switch atutorenewal flag once per session due to the stripe processing limits
  const [isLocked, setIsLocked] = useState(false)

  const [openAutoRenewalAlert] = useConfirmAlert({
    type: ConfirmAlertType.WP_AUTO_RENEWAL_WARNING,
  })

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN_CONFIG),
  )

  const isEdit = Boolean(plan.id)
  const isAutoRenewalDayLimited =
    moment().diff(plan.autoRenewalModificationDatetime, 'days') < 1
  const isAutoRenewalTypeAllowed =
    isEdit && MembershipAutoRenewalAllowed[plan.planType]

  const { fields, reset } = useFields(
    [
      {
        name: 'autoRenewal',
        label: t('WellnessPlans:AUTO_RENEWAL.AUTO_RENEW_PLAN_UPON_EXPIRY'),
        initialValue: plan.autoRenewal ?? true,
        type: 'toggle',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [plan, wellnessPlanError])

  const { autoRenewal } = fields

  if (!isAutoRenewalTypeAllowed) {
    return null
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={className}
      wrap="nowrap"
    >
      <PuiSwitch
        classes={{
          root: classes.toggle,
        }}
        disabled={isAutoRenewalDayLimited || isLocked || !permissions.update}
        field={{
          ...autoRenewal,
          set: (event) => {
            openAutoRenewalAlert({
              applyCustomMessage: true,
              message: t(
                'WellnessPlans:AUTO_RENEWAL.CONFIRMATION_WARNING_MESSAGE',
              ),
              okButtonText: t('Common:YES'),
              preventShowAgainCheckBox: false,
              onConfirm: (proceed) => {
                if (proceed) {
                  dispatch(
                    toggleAutoRenewalPlan(plan.id, !event.target.checked),
                  )
                  setIsLocked(true)
                  autoRenewal.setValue(!event.target.checked)
                }
              },
            })
          },
        }}
        label={autoRenewal.label}
      />
      <PuiTooltip
        tooltipPlacement="top-start"
        tooltipText={
          <>
            {t('WellnessPlans:AUTO_RENEWAL.LIMIT_WARNING_MESSAGE')}
            {isWpAutoRenewalLinkingEnabled && (
              <Box pt={2}>
                {t('WellnessPlans:AUTO_RENEWAL.DEFAULT_AUTO_RENEWAL_TOOLTIP')}
              </Box>
            )}
          </>
        }
      >
        <InfoIcon className={classes.infoIcon} />
      </PuiTooltip>
    </Grid>
  )
}

export default WellnessPlanAutoRenewalToggle
