import React from 'react'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTooltip } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import {
  getChewyFreeShippingEnabled,
  getFeatureToggle,
} from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    label: {
      fontSize: '1.6rem',
    },
    rootLabel: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(-0.5),
    },
    radio: {
      padding: '2px',
    },
    tooltip: {
      padding: theme.spacing(0.5, 1),
      backgroundColor: theme.colors.grayGray1,
      opacity: '0.8 !important',
      maxWidth: 313,
    },
    tooltipIcon: {
      backgroundColor: theme.colors.secondaryText,
      padding: theme.spacing(0, 0.5),
      fontSize: '1.4rem',
      maxWidth: 520,
    },
  }),
  { name: 'WorkflowSectionItem' },
)

export interface WorkflowSectionItemProps {
  disabled: boolean
  disabledTooltip?: React.ReactNode
  label: React.ReactNode
  workflow: PrescriptionWorkflowType
}

const WorkflowSectionItem = ({
  disabled,
  disabledTooltip,
  label,
  workflow,
}: WorkflowSectionItemProps) => {
  const classes = useStyles()

  const isActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )
  const isChewyFreeShippingEnabled = useSelector(getChewyFreeShippingEnabled)

  return typeof disabledTooltip === 'string' ? (
    <Tooltip
      PopperProps={
        isActiveRxEnabled
          ? {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -16],
                  },
                },
              ],
            }
          : {}
      }
      classes={
        isActiveRxEnabled
          ? {
              tooltip: isChewyFreeShippingEnabled
                ? classes.tooltip
                : classes.tooltipIcon,
            }
          : {}
      }
      placement={isActiveRxEnabled ? 'top' : 'top-start'}
      title={disabled ? disabledTooltip : ''}
    >
      <FormControlLabel
        classes={{ root: classes.rootLabel, label: classes.label }}
        control={<Radio classes={{ root: classes.radio }} />}
        disabled={Boolean(disabled)}
        label={label}
        value={workflow}
      />
    </Tooltip>
  ) : (
    <PuiTooltip tooltipText={disabled ? disabledTooltip : ''}>
      <FormControlLabel
        classes={{ root: classes.rootLabel, label: classes.label }}
        control={<Radio classes={{ root: classes.radio }} />}
        disabled={Boolean(disabled)}
        label={label}
        value={workflow}
      />
    </PuiTooltip>
  )
}

export default WorkflowSectionItem
