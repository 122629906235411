import { path } from 'ramda'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  Document,
  DocumentContext,
  DocumentCreate,
  DocumentInstanceCreateMessage,
  UnsavedDocument,
} from '~/types'

import {
  CLEAR_RESOLVED_DOCUMENT,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_WITH_TEMPLATE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_FAILURE,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_WITH_TEMPLATE,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_FAILURE,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_BY_TYPE,
  FETCH_DOCUMENTS_BY_TYPE_FAILURE,
  FETCH_DOCUMENTS_BY_TYPE_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_LIST,
  FETCH_DOCUMENTS_LIST_FAILURE,
  FETCH_DOCUMENTS_LIST_SUCCESS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS,
  FETCH_RESOLVED_DOCUMENT,
  FETCH_RESOLVED_DOCUMENT_BODY,
  FETCH_RESOLVED_DOCUMENT_BODY_FAILURE,
  FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS,
  FETCH_RESOLVED_DOCUMENT_FAILURE,
  FETCH_RESOLVED_DOCUMENT_SUCCESS,
  FETCH_RESOLVED_DOCUMENTS,
  FETCH_RESOLVED_DOCUMENTS_FAILURE,
  FETCH_RESOLVED_DOCUMENTS_SUCCESS,
  GENERATE_DOCUMENT_INSTANCES,
  GENERATE_DOCUMENT_INSTANCES_FAILURE,
  GENERATE_DOCUMENT_INSTANCES_SUCCESS,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE,
  GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS,
  GENERATE_PDF_DOWNLOAD,
  GENERATE_PDF_DOWNLOAD_FAILURE,
  GENERATE_PDF_DOWNLOAD_SUCCESS,
  GENERATE_PDF_PREVIEW,
  GENERATE_PDF_PREVIEW_FAILURE,
  GENERATE_PDF_PREVIEW_SUCCESS,
  RESET_DOCUMENTS,
  UPDATE_DOCUMENTS,
  WS_DOCUMENT_INSTANCE_CREATE,
} from './types/documents'

export const fetchDocumentsList = (
  from?: number,
  to?: number,
  query?: string | Nil,
) => ({
  type: FETCH_DOCUMENTS_LIST,
  from,
  to,
  query,
})
export const fetchDocumentsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_DOCUMENTS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchDocumentsListFailure = (error: ApiError) => ({
  type: FETCH_DOCUMENTS_LIST_FAILURE,
  error,
})

export const fetchDocument = (id: string) => ({ type: FETCH_DOCUMENT, id })
export const fetchDocumentFailure = (error: ApiError) => ({
  type: FETCH_DOCUMENT_FAILURE,
  error,
})
export const fetchDocumentSuccess = () => ({ type: FETCH_DOCUMENT_SUCCESS })

export const fetchDocuments = (ids: string[]) => ({
  type: FETCH_DOCUMENTS,
  ids,
})
export const fetchDocumentsFailure = (error: ApiError) => ({
  type: FETCH_DOCUMENTS_FAILURE,
  error,
})
export const fetchDocumentsSuccess = () => ({ type: FETCH_DOCUMENTS_SUCCESS })

export const fetchMoreItemsForDocumentsList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForDocumentsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForDocumentsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE,
  error,
})

export const fetchResolvedDocumentBody = (body: string) => ({
  type: FETCH_RESOLVED_DOCUMENT_BODY,
  body,
})
export const fetchResolvedDocumentBodyFailure = (error: ApiError) => ({
  type: FETCH_RESOLVED_DOCUMENT_BODY_FAILURE,
  error,
})
export const fetchResolvedDocumentBodySuccess = (body: string) => ({
  type: FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS,
  body,
})

export const fetchResolvedDocument = (document: Document) => ({
  type: FETCH_RESOLVED_DOCUMENT,
  document,
})
export const fetchResolvedDocumentFailure = (error: ApiError) => ({
  type: FETCH_RESOLVED_DOCUMENT_FAILURE,
  error,
})
export const fetchResolvedDocumentSuccess = (document: Document) => ({
  type: FETCH_RESOLVED_DOCUMENT_SUCCESS,
  document,
})
export const clearResolvedDocument = () => ({
  type: CLEAR_RESOLVED_DOCUMENT,
})

export const fetchResolvedDocuments = (
  documentIds: string[],
  context: DocumentContext,
  convertToTextDocument = false,
) => ({
  type: FETCH_RESOLVED_DOCUMENTS,
  documentIds,
  convertToTextDocument,
  context,
})
export const fetchResolvedDocumentsFailure = (error: ApiError) => ({
  type: FETCH_RESOLVED_DOCUMENTS_FAILURE,
  error,
})
export const fetchResolvedDocumentsSuccess = () => ({
  type: FETCH_RESOLVED_DOCUMENTS_SUCCESS,
})

export const createDocument = (document: Document) => ({
  type: path(['template', 'file'], document)
    ? CREATE_DOCUMENT_WITH_TEMPLATE
    : CREATE_DOCUMENT,
  document,
})

export const createDocumentForm = (document: DocumentCreate) => ({
  type: CREATE_DOCUMENT,
  document,
})

export const createDocumentSuccess = (documentId: string) => ({
  type: CREATE_DOCUMENT_SUCCESS,
  documentId,
})
export const createDocumentFailure = (error: ApiError) => ({
  type: CREATE_DOCUMENT_FAILURE,
  error,
})

export const editDocument = (document: Document) => ({
  type: path(['template', 'file'], document)
    ? EDIT_DOCUMENT_WITH_TEMPLATE
    : EDIT_DOCUMENT,
  document,
})
export const editDocumentSuccess = () => ({ type: EDIT_DOCUMENT_SUCCESS })
export const editDocumentFailure = (error: ApiError) => ({
  type: EDIT_DOCUMENT_FAILURE,
  error,
})

export const deleteDocument = (documentId: string) => ({
  type: DELETE_DOCUMENT,
  documentId,
})
export const deleteDocumentSuccess = (documentId: string) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  documentId,
})
export const deleteDocumentFailure = (error: ApiError) => ({
  type: DELETE_DOCUMENT_FAILURE,
  error,
})

export const updateDocuments = (documents: Record<string, Document>) => ({
  type: UPDATE_DOCUMENTS,
  documents,
})

export const resetDocuments = () => ({ type: RESET_DOCUMENTS })

export const fetchDocumentsByType = (
  documentType?: string | Nil,
  patientId?: string | Nil,
  eventId?: string | Nil,
  skipFileTemplates?: boolean,
) => ({
  type: FETCH_DOCUMENTS_BY_TYPE,
  documentType,
  patientId,
  eventId,
  skipFileTemplates,
})
export const fetchDocumentsByTypeFailure = (error: ApiError) => ({
  type: FETCH_DOCUMENTS_BY_TYPE_FAILURE,
  error,
})
export const fetchDocumentsByTypeSuccess = (list: string[]) => ({
  type: FETCH_DOCUMENTS_BY_TYPE_SUCCESS,
  list,
})

export const generateFileForDocumentTemplate = (documentId: string) => ({
  type: GENERATE_FILE_FOR_DOCUMENT_TEMPLATE,
  documentId,
})
export const generateFileForDocumentTemplateFailure = (error: ApiError) => ({
  type: GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE,
  error,
})
export const generateFileForDocumentTemplateSuccess = (document: Document) => ({
  type: GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS,
  document,
})

export const generatePdfPreview = (document: UnsavedDocument) => ({
  type: GENERATE_PDF_PREVIEW,
  document,
})
export const generatePdfPreviewFailure = (error: ApiError) => ({
  type: GENERATE_PDF_PREVIEW_FAILURE,
  error,
})
export const generatePdfPreviewSuccess = () => ({
  type: GENERATE_PDF_PREVIEW_SUCCESS,
})

export const generatePdfDownload = (document: UnsavedDocument) => ({
  type: GENERATE_PDF_DOWNLOAD,
  document,
})
export const generatePdfDownloadFailure = (error: ApiError) => ({
  type: GENERATE_PDF_DOWNLOAD_FAILURE,
  error,
})
export const generatePdfDownloadSuccess = () => ({
  type: GENERATE_PDF_DOWNLOAD_SUCCESS,
})

export const wsDocumentInstanceCreate = (
  body: DocumentInstanceCreateMessage,
) => ({
  type: WS_DOCUMENT_INSTANCE_CREATE,
  body,
})

export const generateDocumentInstances = (
  documentIds: string[],
  context: DocumentContext,
) => ({
  type: GENERATE_DOCUMENT_INSTANCES,
  documentIds,
  context,
})
export const generateDocumentInstancesSuccess = (instances: Document[]) => ({
  type: GENERATE_DOCUMENT_INSTANCES_SUCCESS,
  instances,
})
export const generateDocumentInstancesFailure = (error: ApiError) => ({
  type: GENERATE_DOCUMENT_INSTANCES_FAILURE,
  error,
})
