import React, { useEffect, useMemo } from 'react'
import {
  Constant,
  LanguageUtils,
  TableFilter,
  Utils,
} from '@pbt/pbt-ui-components'

import QuickFilterButton, {
  QuickFilterButtonProps,
} from '~/components/common/buttons/QuickFilterButton'

const getConstantIds = (quickFilter: string[], constants: Constant[]) =>
  quickFilter.map(
    (statusName) => Utils.findConstantByName(statusName, constants)?.id,
  )

const getHumanReadableName = (ids: string[], constants: Constant[]) =>
  ids
    .map((id: string) =>
      LanguageUtils.getConstantTranslatedName(id, constants, ''),
    )
    .join(', ')

export interface OrdersTableQuickFilterProps extends QuickFilterButtonProps {
  constants: Constant[]
  filterValue: TableFilter['value']
  onApplyFilter: (value: TableFilter) => void
  quickFilterValue: string[]
}

export const OrdersTableQuickFilter = ({
  constants,
  filterValue,
  quickFilterValue,
  onApplyFilter,
  ...quickFilterButtonProps
}: OrdersTableQuickFilterProps) => {
  const isFilterApplied = useMemo(() => {
    // Order table filter values are going to all be strings for now
    const filterIds =
      typeof filterValue === 'string' ? filterValue.split(',') : []
    const constantIds = getConstantIds(quickFilterValue, constants)

    return (
      filterIds.length === constantIds.length &&
      filterIds.every((id) => constantIds.includes(id))
    )
  }, [quickFilterValue, filterValue, constants])

  const handleToggleQuickFilter = () => {
    if (isFilterApplied) {
      onApplyFilter({})
      return
    }

    const ids = getConstantIds(quickFilterValue, constants)
    onApplyFilter({
      value: ids.join(','),
      humanReadable: getHumanReadableName(ids, constants),
    })
  }

  useEffect(() => {
    if (quickFilterButtonProps.defaultChecked && !isFilterApplied) {
      // Apply open order status filter on mount
      handleToggleQuickFilter()
    }
  }, [])

  return (
    <QuickFilterButton
      {...quickFilterButtonProps}
      active={isFilterApplied}
      onClick={handleToggleQuickFilter}
    />
  )
}
