import { Location } from 'react-router-dom'
import { Nil } from '@pbt/pbt-ui-components'

import { MenuItem } from './menuItem'

export const getSelectedItem = (
  item: MenuItem[] | MenuItem,
  location: Location,
): MenuItem | Nil => {
  const children = Array.isArray(item) ? item : item.children
  const menuItem = item as MenuItem

  return children
    ? children.find((child) => getSelectedItem(child, location))
    : menuItem?.url && location.pathname.indexOf(menuItem?.url) === 0
      ? menuItem
      : null
}
