import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { LabVendorConfig, LabVendorConfigIntegrationTesting } from '~/types'
import { updateByPath } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_LAB_VENDOR_CONFIGS =
  'labVendorConfig/FETCH_LAB_VENDOR_CONFIGS'
export const FETCH_LAB_VENDOR_CONFIGS_SUCCESS =
  'labVendorConfig/FETCH_LAB_VENDOR_CONFIGS_SUCCESS'
export const FETCH_LAB_VENDOR_CONFIGS_FAILURE =
  'labVendorConfig/FETCH_LAB_VENDOR_CONFIGS_FAILURE'

export const EDIT_LAB_VENDOR_CONFIG = 'labVendorConfig/EDIT_LAB_VENDOR_CONFIG'
export const EDIT_LAB_VENDOR_CONFIG_SUCCESS =
  'labVendorConfig/EDIT_LAB_VENDOR_CONFIG_SUCCESS'
export const EDIT_LAB_VENDOR_CONFIG_FAILURE =
  'labVendorConfig/EDIT_LAB_VENDOR_CONFIG_FAILURE'

export const fetchLabVendorConfigs = (businessId: string) => ({
  type: FETCH_LAB_VENDOR_CONFIGS,
  businessId,
})

export const fetchLabVendorConfigsSuccess = (
  businessId: string,
  configs: Record<string, LabVendorConfig>,
  list: string[],
) => ({
  type: FETCH_LAB_VENDOR_CONFIGS_SUCCESS,
  businessId,
  configs,
  list,
})

export const fetchLabVendorConfigsFailure = (error: ApiError) => ({
  type: FETCH_LAB_VENDOR_CONFIGS_FAILURE,
  error,
})

export const editLabVendorConfig = (
  businessId: string,
  vendorId: string,
  config: Partial<LabVendorConfig>,
) => ({
  businessId,
  vendorId,
  config,
  type: EDIT_LAB_VENDOR_CONFIG,
})

export const editLabVendorConfigSuccess = (
  businessId: string,
  config: Partial<LabVendorConfig>,
  success: boolean,
  status: string,
) => ({
  businessId,
  config,
  success,
  status,
  type: EDIT_LAB_VENDOR_CONFIG_SUCCESS,
})

export const editLabVendorConfigFailure = (
  error: ApiError,
  businessId: string,
  vendorId: string,
  config: Partial<LabVendorConfig>,
) => ({
  error,
  businessId,
  vendorId,
  config,
  type: EDIT_LAB_VENDOR_CONFIG_FAILURE,
})

export const LAB_VENDOR_CONFIG_SENSITIVE_ACTIONS = [
  {
    type: FETCH_LAB_VENDOR_CONFIGS_SUCCESS,
    sensitiveData: ['configs'],
  },
  {
    type: EDIT_LAB_VENDOR_CONFIG,
    sensitiveData: ['config'],
  },
  {
    type: EDIT_LAB_VENDOR_CONFIG_SUCCESS,
    sensitiveData: ['config'],
  },
  {
    type: EDIT_LAB_VENDOR_CONFIG_FAILURE,
    sensitiveData: ['config'],
  },
]

export type LabVendorConfigState = {
  error: string | null
  isReceiving: boolean
  isSending: boolean
  labsTesting: Record<string, Record<string, LabVendorConfigIntegrationTesting>>
  listByBusiness: Record<string, string[]>
  map: Record<string, Record<string, LabVendorConfig>>
}

export const INITIAL_STATE: LabVendorConfigState = {
  map: {},
  listByBusiness: {},
  labsTesting: {},
  isReceiving: false,
  isSending: false,
  error: null,
}

export const labVendorConfigReducer = (
  state: LabVendorConfigState = INITIAL_STATE,
  action: AnyAction,
): LabVendorConfigState => {
  switch (action.type) {
    case FETCH_LAB_VENDOR_CONFIGS:
      return {
        ...state,
        isReceiving: true,
      }
    case FETCH_LAB_VENDOR_CONFIGS_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.businessId]: action.configs,
        },
        listByBusiness: {
          ...state.listByBusiness,
          [action.businessId]: action.list,
        },
        isReceiving: false,
      }
    case FETCH_LAB_VENDOR_CONFIGS_FAILURE:
      return {
        ...state,
        isReceiving: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_LAB_VENDOR_CONFIG:
      return {
        ...state,
        labsTesting: updateByPath(
          [action.businessId, action.config.vendorId],
          { testing: true, success: undefined, status: undefined },
          state.labsTesting,
        ),
        isSending: true,
      }
    case EDIT_LAB_VENDOR_CONFIG_SUCCESS:
      return {
        ...state,
        labsTesting: updateByPath(
          [action.businessId, action.config.vendorId],
          { testing: false, success: action.success, status: action.status },
          state.labsTesting,
        ),
        map: {
          ...state.map,
          [action.businessId]: {
            ...state.map[action.businessId],
            [action.config.vendorId]: action.config,
          },
        },
        isSending: false,
      }
    case EDIT_LAB_VENDOR_CONFIG_FAILURE:
      return {
        ...state,
        labsTesting: {
          ...state.labsTesting,
          [action.businessId]: R.omit(
            [action.config.vendorId],
            state.labsTesting[action.businessId],
          ),
        },
        isSending: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getLabVendorConfig = (state: RootState): LabVendorConfigState =>
  state.labVendorConfig
export const getLabVendorConfigIsReceiving = (state: RootState) =>
  getLabVendorConfig(state).isReceiving

export const getTestingLabs = (businessId: string) =>
  createSelector(
    getLabVendorConfig,
    (labVendorConfigState) =>
      labVendorConfigState.labsTesting[businessId] || {},
  )

export const getLabConfigsMap = (state: RootState) =>
  getLabVendorConfig(state).map
export const getLabConfigsMapForBusiness = (businessId: string) =>
  createSelector(
    getLabConfigsMap,
    (mapByBusiness) => mapByBusiness[businessId] || {},
  )
export const getLabConfigsList = (state: RootState) =>
  getLabVendorConfig(state).listByBusiness
export const getLabConfigsListForBusiness = (businessId: string) =>
  createSelector(
    getLabConfigsList,
    (listByBusiness) => listByBusiness[businessId] || [],
  )
export const getMultipleLabConfigs = (ids: string[], businessId: string) =>
  createSelector(getLabConfigsMapForBusiness(businessId), (labConfigsMap) =>
    R.props(ids, labConfigsMap),
  )
export const getLabConfigs = (businessId: string) =>
  createSelector(
    [
      getLabConfigsMapForBusiness(businessId),
      getLabConfigsListForBusiness(businessId),
    ],
    (labConfigsMap, list) => list.map((id) => labConfigsMap[id]),
  )
export const getLabVendorConfigSanitized = (state: RootState) => ({
  ...state.labVendorConfig,
  // @ts-ignore
  map: R.map(
    (map) =>
      // @ts-ignore
      R.map(
        (key: any) => (key.password ? { ...key, password: '*' } : key),
        map,
      ),
    state.labVendorConfig.map ?? {},
  ),
})

export function* fetchLabVendorConfigsSaga({
  businessId,
}: ReturnType<typeof fetchLabVendorConfigs>) {
  try {
    const {
      result: list,
      entities: { labVendorConfigs = {} },
    } = yield* requestAPI(API.fetchLabVendorConfigs, businessId)
    yield put(fetchLabVendorConfigsSuccess(businessId, labVendorConfigs, list))
  } catch (error) {
    yield put(fetchLabVendorConfigsFailure(error as ApiError))
  }
}

export function* editLabVendorConfigSaga({
  businessId,
  vendorId,
  config,
}: ReturnType<typeof editLabVendorConfig>) {
  try {
    const { status } = yield* requestAPI(
      API.editLabVendorConfig,
      businessId,
      vendorId,
      config,
    )
    yield put(
      editLabVendorConfigSuccess(businessId, config, status === 'OK', status),
    )
  } catch (error) {
    yield put(
      editLabVendorConfigFailure(
        error as ApiError,
        businessId,
        vendorId,
        config,
      ),
    )
  }
}

function* watchFetchLabVendorConfigs() {
  yield takeLeading(FETCH_LAB_VENDOR_CONFIGS, fetchLabVendorConfigsSaga)
}

function* watchEditLabVendorConfig() {
  yield takeLeading(EDIT_LAB_VENDOR_CONFIG, editLabVendorConfigSaga)
}

export function* labVendorConfigSaga() {
  yield all([watchFetchLabVendorConfigs(), watchEditLabVendorConfig()])
}
