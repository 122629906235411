import { useSelector } from 'react-redux'
import moment from 'moment'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import { getInternalCancellationReasons } from '~/store/reducers/constants'

import { isWithinTimeInterval } from './time'

const twentyFourHoursInMinutes = 24 * 60

export const getIsOutsideCancellationWindow = (
  appointmentStartTime: string | Nil,
  appointmentEndTime?: string | Nil,
) =>
  isWithinTimeInterval(
    appointmentStartTime,
    moment(),
    twentyFourHoursInMinutes,
  ) || moment().isBetween(appointmentStartTime, appointmentEndTime)

export const useIsWaivableCancellationReason = () => {
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const cvcInitiatedReasonId = Utils.findConstantIdByName(
    'CVC initiated cancellation',
    internalCancellationReasons,
  )
  const patientDeceasedReasonId = Utils.findConstantIdByName(
    'Patient deceased',
    internalCancellationReasons,
  )
  const patientWentToErReasonId = Utils.findConstantIdByName(
    'Patient went to ER',
    internalCancellationReasons,
  )
  const clientWorkOrFamilyEmergencyReasonId = Utils.findConstantIdByName(
    'Client work or family emergency',
    internalCancellationReasons,
  )

  const hideWaiveFeeAlertReasons = [
    cvcInitiatedReasonId,
    patientDeceasedReasonId,
    patientWentToErReasonId,
    clientWorkOrFamilyEmergencyReasonId,
  ]

  const isWaivableCancellationReason = (reasonId: string | Nil) =>
    !hideWaiveFeeAlertReasons.includes(reasonId)

  return { isWaivableCancellationReason }
}
