import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import {
  Field,
  LanguageUtils,
  PuiSelect,
  PuiTextField,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import ManualInputSelect from '~/components/common/ManualInputSelect'
import {
  getControlledSubstanceSchedules,
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getProductForm,
} from '~/store/reducers/constants'
import { getManualInputSelectValue, handleNumberInput } from '~/utils'

import ReadOnlyGlobalItemInfoList, {
  InfoListItem,
} from './ReadOnlyGlobalItemInfoList'

export interface OldGlobalItemCompoundInfoProps {
  fields: {
    controlledSubstanceScheduleId: Field
    customForm: Field
    deliveryMethodId?: Field
    description: Field
    flavor: Field
    formId: Field
    strength?: Field
    strengthColorId?: Field
    strengthCombo?: Field
    strengthUnitsId?: Field
  }
  readOnly?: boolean
}

const OldGlobalItemCompoundInfo = ({
  fields,
  readOnly = false,
}: OldGlobalItemCompoundInfoProps) => {
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const ControlledSubstanceSchedules = useSelector(
    getControlledSubstanceSchedules,
  )
  const { t } = useTranslation(['Admin', 'Common'])

  const {
    formId,
    customForm,
    deliveryMethodId,
    strength,
    strengthCombo,
    strengthUnitsId,
    strengthColorId,
    description,
    controlledSubstanceScheduleId,
    flavor,
  } = fields

  const showStrength = (strength && strengthUnitsId) || strengthCombo
  const strengthValue = strength?.value || strengthCombo?.value

  const variationInfoItems = [
    formId && {
      name: t('Common:FORM_NOUN'),
      value: getManualInputSelectValue(
        ProductForm,
        formId.value,
        customForm.value,
      ),
    },
    flavor && {
      name: t('Common:FLAVOR'),
      value: flavor.value,
    },
    deliveryMethodId && {
      name: t('Common:DRUG_DELIVERY_METHOD'),
      value: LanguageUtils.getConstantTranslatedName(
        deliveryMethodId.value,
        DrugDeliveryMethod,
      ),
    },
    showStrength && {
      name: t('Common:DRUG_STRENGTH'),
      value: strengthValue
        ? `${strengthValue} ${LanguageUtils.getConstantTranslatedName(
            strengthUnitsId?.value,
            DrugStrengthUnit,
          )}`
        : '',
    },
    strengthColorId && {
      name: t('Common:DRUG_STRENGTH_COLOR'),
      value: LanguageUtils.getConstantTranslatedName(
        strengthColorId.value,
        DrugStrengthColor,
      ),
    },
    controlledSubstanceScheduleId && {
      name: t('Common:CONTROLLED_SUBSTANCE'),
      value: t('Admin:DRUG_CONTROLLER_SUBSTANCE_SCHEDULE', {
        controlledSubstanceSchedule: LanguageUtils.getConstantTranslatedName(
          controlledSubstanceScheduleId.value,
          ControlledSubstanceSchedules,
        ),
      }),
    },
  ].filter(Boolean) as InfoListItem[]

  return readOnly ? (
    <ReadOnlyGlobalItemInfoList items={variationInfoItems} />
  ) : (
    <Grid container direction="column">
      <Grid container item spacing={2}>
        <ManualInputSelect
          fields={{
            unitId: formId,
            customUnit: customForm,
          }}
          label={`${formId.label || t('Common:FORM_NOUN')}*`}
          options={ProductForm}
        />
      </Grid>
      <Grid container item alignItems="center" spacing={2}>
        <Grid item xs>
          <PuiTextField disabled field={flavor} label={t('Common:FLAVOR')} />
        </Grid>
        {deliveryMethodId && (
          <Grid item xs>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="delivery-method-select">
                {deliveryMethodId.label || t('Common:DRUG_DELIVERY_METHOD')}*
              </InputLabel>
              <PuiSelect
                field={deliveryMethodId}
                input={<Input id="delivery-method-select" />}
                items={DrugDeliveryMethod}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs>
          <PuiTextField
            field={description}
            label={description.label || t('Common:DESCRIPTION')}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" spacing={2}>
        {strength && (
          <Grid item xs>
            <NumericInput
              allowDecimal
              field={{
                ...strength,
                set: handleNumberInput(strength.setValue, 10, 4),
              }}
              label={strength.label || t('Common:DRUG_STRENGTH')}
              min={0.01}
            />
          </Grid>
        )}
        {strengthUnitsId && (
          <Grid item xs>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="strength-unit-select">
                {strengthUnitsId.label || t('Common:DRUG_STRENGTH_UNIT')}
              </InputLabel>
              <PuiSelect
                field={strengthUnitsId}
                input={<Input id="strength-unit-select" />}
                items={DrugStrengthUnit}
              />
            </FormControl>
          </Grid>
        )}
        {strengthColorId && (
          <Grid item xs>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="strength-color-select">
                {strengthColorId.label || t('Common:DRUG_STRENGTH_COLOR')}
              </InputLabel>
              <PuiSelect
                field={strengthColorId}
                input={<Input id="strength-color-select" />}
                items={DrugStrengthColor}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default OldGlobalItemCompoundInfo
