import React, { useEffect, useState } from 'react'
// @ts-ignore
import GoogleMapReact, { fitBounds } from 'google-map-react'
import { Nil } from '@pbt/pbt-ui-components'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'
import { getEnvironment } from '~/utils'
import GoogleMapHelper from '~/utils/GoogleMapHelper'

import aulaStyles from './aula.json'
import Marker from './Marker'

// Default to Washington DC
const defaultCenter: google.maps.LatLngLiteral = {
  lat: 38.88,
  lng: -77.01,
}

const createMapOptions = (map: any): google.maps.MapOptions => ({
  styles: aulaStyles,
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControlOptions: {
    position: map.ControlPosition.RIGHT_BOTTOM,
  },
})

const isCenterDefined = (center: Record<string, any> | Nil): boolean =>
  Boolean(
    center &&
      typeof center.lat !== 'undefined' &&
      typeof center.lng !== 'undefined',
  )

const reformatPoint = (point: google.maps.LatLng) => ({
  lat: point.lat(),
  lng: point.lng(),
})

interface MapProps {
  autoMove?: boolean
  center?: Record<string, any>
  highlightedId?: string | null
  onBoundsChanged?: (bounds: any) => void
  onCenterChanged?: (center: google.maps.LatLngLiteral) => void
  onMarkerClick?: (point: google.maps.places.PlaceResult) => void
  onSearchServiceReady?: (helper: GoogleMapHelper | null) => void
  onZoomChanged?: (zoom: number) => void
  points?: google.maps.places.PlaceResult[]
  zoom?: number
}

const Map: React.FC<MapProps> = ({
  autoMove = false,
  center,
  highlightedId = null,
  onBoundsChanged = null,
  onMarkerClick = null,
  points = [],
  zoom = 12,
  onCenterChanged = null,
  onSearchServiceReady = null,
  onZoomChanged = null,
}) => {
  const [api, setApi] = useState<any>(null)
  const [mapSize, setMapSize] = useState<google.maps.Size | null>(null)
  const [googleMapHelper, setGoogleMapHelper] =
    useState<GoogleMapHelper | null>(null)

  const isDefined = isCenterDefined(center)

  const setDefaultPosition = () => {
    if (onCenterChanged) {
      onCenterChanged(defaultCenter)
    }
  }

  useEffect(() => {
    if (isDefined) {
      return
    }
    if (navigator.geolocation) {
      let timer: ReturnType<typeof setTimeout> | null = setTimeout(
        setDefaultPosition,
        800,
      )
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (timer && onCenterChanged) {
            onCenterChanged({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          }
          clearTimeout(timer || undefined)
          timer = null
        },
        () => {
          clearTimeout(timer || undefined)
          timer = null
          setDefaultPosition()
        },
      )
    } else {
      setDefaultPosition()
    }
  }, [center])

  useEffect(() => {
    if (!autoMove || !points || !points.length || !api || !mapSize) {
      return
    }
    if (points.length === 1) {
      const { location } = points[0].geometry || {}
      if (onCenterChanged && location) {
        onCenterChanged({
          lat: location.lat(),
          lng: location.lng(),
        })
      }
      if (onZoomChanged) {
        onZoomChanged(10)
      }
      return
    }
    const bounds = new api.LatLngBounds()
    points.forEach((point) => {
      bounds.extend(point?.geometry?.location)
    })
    const { center, zoom } = fitBounds(
      {
        ne: reformatPoint(bounds.getNorthEast()),
        sw: reformatPoint(bounds.getSouthWest()),
      },
      mapSize,
    )
    if (onCenterChanged) {
      onCenterChanged(center)
    }
    if (onZoomChanged) {
      onZoomChanged(zoom)
    }
  }, [points, api, autoMove, mapSize])

  useEffect(() => {
    if (onSearchServiceReady) {
      onSearchServiceReady(googleMapHelper)
    }
    return () => {
      if (googleMapHelper) {
        googleMapHelper.destroy()
      }
    }
  }, [googleMapHelper])

  const onGoogleApiLoaded = ({ map, maps }: any) => {
    setApi(maps)
    if (googleMapHelper) {
      googleMapHelper.destroy()
    }
    setGoogleMapHelper(new GoogleMapHelper(maps, map))
    setMapSize(new google.maps.Size(map.offsetWidth, map.offsetHeight)) // TODO: need to call from onChange
  }

  const onChange = ({ bounds, size }: any) => {
    if (
      bounds &&
      bounds.ne &&
      typeof bounds.ne.lat !== 'undefined' &&
      onBoundsChanged
    ) {
      onBoundsChanged(bounds)
      setMapSize(size)
    }
  }

  if (!isDefined) {
    return null
  }

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: ENVIRONMENT_VARIABLES[getEnvironment()].GOOGLE_API_KEY,
        libraries: 'places',
      }}
      center={center || defaultCenter}
      options={createMapOptions}
      zoom={zoom}
      onChange={onChange}
      onGoogleApiLoaded={onGoogleApiLoaded}
    >
      {points.map((point) => (
        <Marker
          highlighted={highlightedId === point.place_id}
          key={`${
            point.name
          }${point?.geometry?.location?.lat()}${point?.geometry?.location?.lng()}`}
          lat={point?.geometry?.location?.lat()}
          lng={point?.geometry?.location?.lng()}
          name={point.name}
          onClick={() => onMarkerClick && onMarkerClick(point)}
        />
      ))}
    </GoogleMapReact>
  )
}

export default Map
