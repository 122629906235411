import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { TextWithTooltip, UnitUtils, Utils } from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import InfoList from '~/components/common/InfoList'
import { getGenderRestrictionString } from '~/components/common/inputs/gender/genderUtils'
import { getUnitsState } from '~/store/duck/settings'
import {
  getAgeUnits,
  getGender,
  getSpayedNeuteredStatus,
  getSpecies,
} from '~/store/reducers/constants'
import { InventoryItem, SpayedNeuteredGenderType } from '~/types'
import { getConstantsStringWithAll } from '~/utils'
import { getOneTimeUnitPluralized } from '~/utils/time'

export interface ReadOnlyInventoryRestrictionProps {
  inventory?: InventoryItem
}

const ReadOnlyInventoryRestriction = ({
  inventory,
}: ReadOnlyInventoryRestrictionProps) => {
  const Species = useSelector(getSpecies)
  const unitsState = useSelector(getUnitsState)
  const ageUnits = useSelector(getAgeUnits)
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])
  const Gender = useSelector(getGender)
  const SpayedNeuteredStatus = useSelector(getSpayedNeuteredStatus)

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const restrictionInfo = [
    {
      name: t('Common:SPECIES'),
      value: getConstantsStringWithAll(inventory?.speciesIds || [], Species),
    },
    {
      name: t('Common:GENDER'),
      value: getGenderRestrictionString(
        inventory?.genderRestrictions as SpayedNeuteredGenderType[],
        Gender,
        SpayedNeuteredStatus,
      ),
    },
    {
      name: t('Admin:CATALOG.INVENTORY_RESTRICTION.AGE_MINIMUM'),
      value: inventory?.ageMinimum
        ? `${inventory?.ageMinimum}\u00A0${getOneTimeUnitPluralized(
            Utils.getConstantName(inventory.ageUnitId, ageUnits, ''),
            inventory?.ageMinimum,
          )}`
        : '',
    },
    {
      name: t('Admin:CATALOG.INVENTORY_RESTRICTION.WEIGHT_MINIMUM'),
      value: inventory?.weightMinimum
        ? `${UnitUtils.convertUnitsForDisplay(
            UnitTypes.WEIGHT,
            inventory?.weightMinimum,
            unitsState,
          )}\u00A0${unitsState.weight}`
        : '',
    },
  ]

  return (
    <Grid container item direction="column" mb={2} spacing={1} wrap="nowrap">
      <Grid item>
        <TextWithTooltip
          strong
          tooltipText={t(
            'Tooltips:IF_PATIENT_OR_APPOINTMENT_DOES_NOT_MEET_CRITERIA_INVENTORY',
          )}
          variant={isMobile ? 'body' : 'body2'}
        >
          {t('Common:RESTRICTION_OTHER')}
        </TextWithTooltip>
      </Grid>
      <Grid item>
        <InfoList items={restrictionInfo} />
      </Grid>
    </Grid>
  )
}

export default ReadOnlyInventoryRestriction
