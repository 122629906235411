import React from 'react'
import { useSelector } from 'react-redux'
import { getSentence } from '@pbt/pbt-ui-components/src/utils/languageUtils'

import { getCommonPackagingLabel } from '~/components/dashboard/admin/catalog/inventory/inventoryUtils'
import {
  getInventoryProductSizeUnit,
  getPackageType,
  getProductForm,
} from '~/store/reducers/constants'
import { PrescriptionDrugInfo } from '~/types'
import { getManualInputSelectValue } from '~/utils'

interface VetDietInfoProps {
  drugInfo?: PrescriptionDrugInfo
}

const VetDietInfo = ({ drugInfo }: VetDietInfoProps) => {
  const ProductForm = useSelector(getProductForm)
  const PackageType = useSelector(getPackageType)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

  const formStr = getManualInputSelectValue(
    ProductForm,
    drugInfo?.formId,
    drugInfo?.customForm,
  )

  const packageUnitFields = {
    perPackageUnitsId: drugInfo?.perPackageUnitsId,
    perPackageAmount: drugInfo?.perPackageAmount,
    saleUnitOfMeasure: drugInfo?.saleUnitOfMeasure,
  }

  const packaging = getCommonPackagingLabel(
    InventoryProductSizeUnit,
    PackageType,
  )(packageUnitFields)

  return <>{getSentence([formStr, drugInfo?.flavor, packaging])}</>
}

export default VetDietInfo
