import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

export const useFetchOnInitialSyncQueryParams = ({
  key,
  onFetch,
}: {
  key: 'Invoices' | 'Payments' | 'Estimates'
  onFetch: () => void
}) => {
  const [searchParams] = useSearchParams()
  const isExpanded = searchParams.get('expanded') === key

  const isContextualBackButtonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CONTEXTUAL_BACK_BUTTON),
  )

  // Fetch initial data when the component is mounted and has search params
  useEffect(() => {
    if (isExpanded && isContextualBackButtonEnabled) {
      onFetch()
    }
  }, [])
}
