import React, { useCallback } from 'react'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import {
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { getInvoiceDueToPayNoFee } from '~/components/dashboard/invoices/invoiceUtils'
import { Invoice } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'
import { isRefundInvoice } from '~/utils/refundUtils'

import { TableAmountCell } from '../common/TableAmountCell'
import { MultipleLinesCell } from './MultipleLinesCell'

const useStyles = makeStyles(
  () => ({
    container: {},
  }),
  { name: 'InvoiceTableTotalCell' },
)

interface InvoiceTableTotalCellProps {
  classes?: ClassesType<typeof useStyles>
  dueToPayPrimary?: boolean
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

export const InvoiceTableTotalCell = ({
  classes: classesProp,
  invoice,
  dueToPayPrimary,
  multipleRowsLimit,
}: InvoiceTableTotalCellProps) => {
  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const isRefund = isRefundInvoice(invoice.invoiceNo) ?? false
  const dueToPayNoFee = getInvoiceDueToPayNoFee(
    invoice as Invoice | GraphqlInvoice,
  )

  const getTotalValue = () => {
    if (isRefund) {
      return (invoice as RefundInvoice).amount
    }

    return dueToPayPrimary ? dueToPayNoFee : invoice.amountNoFee
  }

  const amount = getTotalValue()

  const InvoiceLineItem = useCallback(
    (props: ChargesSection & { index: number }) => {
      if (props.index === 0) {
        return <TableAmountCell amount={amount} classes={classesProp} />
      }

      return <></>
    },
    [amount, classesProp],
  )

  const RetailOrderLineItem = useCallback(
    (props: UIRoliSection) => (
      <TableAmountCell amount={props.total} classes={classesProp} />
    ),
    [classesProp],
  )

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItem}
        RetailOrderLineItem={RetailOrderLineItem}
        sections={sections}
      />
    )
  }

  return <TableAmountCell amount={getTotalValue()} classes={classesProp} />
}
