/* eslint-disable max-lines */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  CustomFieldValidatorState,
  ErrorTooltip,
  Field,
  FieldProp,
  LanguageUtils,
  Nil,
  PermissionArea,
  PuiSelect,
  PuiTextField,
  SentenceFormatter,
  Text,
  UnitsState,
  UnitUtils,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import NdcNumberForm, {
  NdcNumberFormHandle,
} from '~/components/common/form-inputs/ndc/NdcNumberForm'
import InfoList from '~/components/common/InfoList'
import WeightRangeInput from '~/components/common/inputs/WeightRangeInput'
import PuiSwitch from '~/components/common/PuiSwitch'
import Spacer from '~/components/common/Spacer'
import FeatureToggle from '~/constants/featureToggle'
import { NameType } from '~/constants/variation'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import {
  clearVariationCalculatedCost,
  clearVariationPartNumberDetails,
  createVariation,
  fetchVariationCalculatedCost,
  fetchVariationPartNumberDetails,
  updateVariation,
} from '~/store/actions/variations'
import { getUnitsState } from '~/store/duck/settings'
import {
  useIsDrug,
  useIsGlobalInventoryItem,
  useIsVaccine,
  useIsVetDiet,
} from '~/store/hooks/orders'
import {
  getCRUDByArea,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import {
  getDrugStrengthUnit,
  getFeatureToggle,
  getInventoryProductSizeUnit,
  getInventorySubCategory,
  getPackageType,
  getProductForm,
} from '~/store/reducers/constants'
import { getInventoryIsSending } from '~/store/reducers/inventories'
import { getVariationIsSending } from '~/store/reducers/variations'
import {
  EasterEggEvents,
  GlobalInventoryCatalogItem,
  InventoryItem,
  Variation as VariationType,
} from '~/types'
import { getManualInputSelectValue, handleNumberInput } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { getCommonPackagingLabel, getOnHandAmount } from '../inventoryUtils'
import OldGlobalItemCompoundInfo from '../variation/OldGlobalItemCompoundInfo'
import VaccinationCompoundInfo from '../variation/VaccinationCompoundInfo'
import VariationAdjustmentsSection from '../variation/VariationAdjustmentsSection'
import VariationReOrderSection from '../variation/VariationReOrderSection'
import VariationShipmentsSection from '../variation/VariationShipmentsSection'
import VariationPriceSection, {
  VariationPriceSectionHandle,
} from './VariationPriceSection'
import VariationSection from './VariationSection'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%',
      margin: 0,
      height: 730,
    },
    content: {
      overflowY: 'scroll',
    },
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    ndcNumberContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    ndcNumberTitle: {
      fontSize: '1.4rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
    },
    sectionHeader: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.6rem',
      },
    },
    firstHeaderOffsets: {
      marginTop: theme.spacing(2),
    },
    activeContainer: {
      height: 40,
      border: theme.constants.tabBorder,
      borderWidth: '1px 0',
    },
    divider: {
      alignSelf: 'center',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    radioLabel: {
      whiteSpace: 'nowrap',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    button: {
      height: 40,
      minWidth: 150,
      maxWidth: 400,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
      margin: theme.spacing(0.5),
    },
    ndc11Label: {
      marginBottom: theme.spacing(1.25),
    },
    onHandAmount: {
      width: 300,
    },
    variationName: {
      flexShrink: 0,
    },
  }),
  { name: 'Variation' },
)

const isAllFieldsValid = (fields: Field[]) =>
  fields.map((field) => field.valid).every(Boolean)

const validateVariationName = ({
  state: { nameType, variationName },
}: CustomFieldValidatorState) =>
  nameType !== NameType.CUSTOM || Validators.notEmptyFormField(variationName)

const validateReOrderPointUnitId = ({
  state: { reOrderPoint, reOrderPointUnitId },
}: CustomFieldValidatorState) =>
  !Validators.notEmptyFormField(reOrderPoint) ||
  Validators.notEmptyFormField(reOrderPointUnitId)

const validateReOrderQuantityUnitId = ({
  state: { reOrderQuantity, reOrderQuantityUnitId },
}: CustomFieldValidatorState) =>
  !Validators.notEmptyFormField(reOrderQuantity) ||
  Validators.notEmptyFormField(reOrderQuantityUnitId)

const validateReOrderMaxQuantityUnitId = ({
  state: { reOrderMaxQuantity, reOrderMaxQuantityUnitId },
}: CustomFieldValidatorState) =>
  !Validators.notEmptyFormField(reOrderMaxQuantity) ||
  Validators.notEmptyFormField(reOrderMaxQuantityUnitId)

interface VariationHandle {
  getIsTouched: () => boolean
  save: () => void
}

interface VariationProps {
  inventory: InventoryItem | GlobalInventoryCatalogItem | Nil
  nextVariation?: VariationType
  onBack?: () => void
  onOk?: () => void
  onVariationFilled?: (variation: VariationType, clone?: boolean) => void
  singleVariationOnly?: boolean
  variation: VariationType | Nil
  variationIndex?: number
  variationsCount?: number
}

const Variation = forwardRef<VariationHandle, VariationProps>(
  (
    {
      inventory,
      nextVariation,
      variation: variationProp,
      variationIndex,
      variationsCount,
      onVariationFilled,
      onOk,
      onBack,
      singleVariationOnly = false,
    },
    ref,
  ) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('md'),
    )
    const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

    const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
    const PackageType = useSelector(getPackageType)
    const ProductForm = useSelector(getProductForm)
    const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
    const unitsStateConstant = useSelector(getUnitsState)
    const InventorySubCategory = useSelector(getInventorySubCategory)
    const isFoodCatalogEnabled = useSelector(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const isCurrentBusinessOmniChannel = useSelector(
      getCurrentBusinessIsOmniChannel,
    )
    const isSyncWithExternalPriceEnabled = useSelector(
      getFeatureToggle(FeatureToggle.PRICE_MATCH),
    )

    const variationId = variationProp?.id
    const globalVariationId =
      variationProp?.[
        isFoodCatalogEnabled
          ? 'globalInventoryVariationMappingId'
          : 'globalVariationId'
      ]
    const inventoryId = inventory?.id
    const isEdit = Boolean(variationId)

    const getFields = (
      variation: VariationType | Nil,
      isVaccine: boolean | Nil,
      unitsState: UnitsState,
    ): FieldProp[] => {
      const fields = [
        {
          name: 'perPackageAmount',
          label: t('Common:AMOUNT_PER_PACKAGE'),
          validators: ['required'],
          initialValue: variation?.perPackageAmount,
        },
        {
          name: 'perPackageUnitsId',
          label: t('Common:UNITS'),
          validators: ['required'],
          type: 'select',
          initialValue: variation?.perPackageUnitsId,
        },
        {
          name: 'packageTypeId',
          label: t('Common:PACKAGE_TYPE'),
          validators: ['required'],
          type: 'select',
          initialValue: variation?.packageTypeId,
        },
        {
          name: 'variationName',
          label: t('Common:VARIATION_NAME'),
          initialValue: isEdit ? variation?.name : '',
          validators: [
            { validator: validateVariationName, validatorName: 'required' },
          ],
        },
        {
          name: 'nameType',
          label: t('Common:NAME'),
          initialValue: NameType.CUSTOM,
        },
        {
          name: 'formId',
          label: t('Common:FORM_NOUN'),
          initialValue: variation?.formId,
          validators: isVaccine ? ['required'] : [],
        },
        {
          name: 'customForm',
          label: t('Common:FORM_NOUN'),
          initialValue: variation?.customForm,
        },
        {
          name: 'flavor',
          label: t('Common:FLAVOR'),
          initialValue: variation?.flavor,
        },
        {
          name: 'deliveryMethodId',
          label: t('Common:DRUG_DELIVERY_METHOD'),
          initialValue: variation?.deliveryMethodId,
        },
        {
          name: 'strength',
          label: t('Common:DRUG_STRENGTH'),
          initialValue: variation?.strength || '',
        },
        {
          name: 'strengthCombo',
          label: t('Common:DRUG_STRENGTH'),
          initialValue: variation?.strengthCombo || '',
        },
        {
          name: 'strengthUnitsId',
          label: t('Common:DRUG_STRENGTH_UNIT'),
          initialValue: variation?.strengthUnitsId,
        },
        {
          name: 'strengthColorId',
          label: t('Common:DRUG_STRENGTH_COLOR'),
          initialValue: variation?.strengthColorId,
        },
        {
          name: 'weightMinimum',
          initialValue: variation?.weightMinimum
            ? UnitUtils.convertUnits(
                UnitTypes.WEIGHT,
                variation?.weightMinimum,
                unitsState,
              )
            : null,
        },
        {
          name: 'weightMaximum',
          initialValue: variation?.weightMaximum
            ? UnitUtils.convertUnits(
                UnitTypes.WEIGHT,
                variation?.weightMaximum,
                unitsState,
              )
            : null,
        },
        { name: 'inStockAmount', initialValue: variation?.inStockAmount || '' },
        { name: 'inStockUnitsId', initialValue: variation?.inStockUnitsId },
        { name: 'location', initialValue: variation?.location },
        {
          name: 'active',
          label: t('Common:ACTIVE_ONE'),
          type: 'toggle',
          initialValue:
            typeof variation?.active === 'undefined' || variation?.active,
        },
        {
          name: 'description',
          label: t('Common:DESCRIPTION'),
          initialValue: variation?.description,
        },
        {
          name: 'reOrderPoint',
          label: t('Common:INVENTORY_RE-ORDER_POINT'),
          initialValue: variation?.reorderPoint,
        },
        {
          name: 'reOrderQuantity',
          label: t('Common:INVENTORY_RE-ORDER_QUANTITY'),
          initialValue: variation?.reorderQuantity,
        },
        {
          name: 'reOrderMaxQuantity',
          label: t('Common:MAXIMUM_QUANTITY'),
          initialValue: variation?.maxQuantity,
        },
        {
          name: 'reOrderPointUnitId',
          label: t('Common:UNIT'),
          initialValue: variation?.reorderPointUnitId,
          validators: [
            {
              validator: validateReOrderPointUnitId,
              validatorName: 'required',
            },
          ],
        },
        {
          name: 'reOrderQuantityUnitId',
          label: t('Common:UNIT'),
          initialValue: variation?.reorderPointUnitId,
          validators: [
            {
              validator: validateReOrderQuantityUnitId,
              validatorName: 'required',
            },
          ],
        },
        {
          name: 'reOrderMaxQuantityUnitId',
          label: t('Common:UNIT'),
          initialValue: variation?.reorderPointUnitId,
          validators: [
            {
              validator: validateReOrderMaxQuantityUnitId,
              validatorName: 'required',
            },
          ],
        },
        {
          name: 'vaccineDefaultDeliveryLocationId',
          label: t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DELIVERY_LOCATION'),
          initialValue: variation?.vaccineDefaultDeliveryLocationId || '',
          type: 'select',
        },
        {
          name: 'vaccineDefaultDeliveryLocation',
          label: t('Common:OTHER_LOCATION'),
          initialValue: variation?.vaccineDefaultDeliveryLocation || '',
          type: 'text',
        },
        {
          name: 'vaccineTypeId',
          label: t('Common:VACCINE_TYPE'),
          initialValue: variation?.vaccineTypeId || '',
          validators: isVaccine ? ['required'] : [],
          type: 'select',
        },
        {
          name: 'vaccineDurationOfImmunityOptionId',
          label: t('Admin:CATALOG.VARIATION.DURATION_OF_IMMUNITY'),
          initialValue: variation?.vaccineDurationOfImmunityOptionId || '',
          type: 'select',
        },
        {
          name: 'vaccineDurationOfImmunityUnitId',
          initialValue: variation?.vaccineDurationOfImmunityUnitId || '',
          type: 'select',
        },
        {
          name: 'vaccineDurationOfImmunity',
          label: t('Common:OTHER_DURATION'),
          initialValue: variation?.vaccineDurationOfImmunity || '',
          type: 'text',
        },
        {
          name: 'vaccineDefaultDoseTypeId',
          label: t('Admin:CATALOG.VARIATION.VACCINE_DEFAULT_DOSE_TYPE'),
          initialValue: variation?.vaccineDefaultDoseTypeId || '',
          type: 'select',
        },
        {
          name: 'vaccineDeliveryMethodId',
          label: t('Common:DRUG_DELIVERY_METHOD'),
          initialValue: variation?.vaccineDeliveryMethodId || '',
          validators: isVaccine ? ['required'] : [],
          type: 'select',
        },
        {
          name: 'vaccineDefaultAmountId',
          label: t('Common:DEFAULT_AMOUNT'),
          initialValue: variation?.vaccineDefaultAmountId || '',
          type: 'select',
        },
        {
          name: 'vaccineDefaultAmount',
          label: t('Common:OTHER_AMOUNT'),
          initialValue: variation?.vaccineDefaultAmount || '',
          type: 'text',
        },
        {
          name: 'rabiesTagExpirationOptionId',
          label: t('Admin:CATALOG.VACCINATION_COMPOUND_INFO.RABIES_TAG'),
          initialValue: variation?.rabiesTagExpirationOptionId || '',
          type: 'select',
        },
        {
          name: 'rabiesTagExpirationUnitId',
          initialValue: variation?.rabiesTagExpirationUnitId || '',
          type: 'select',
        },
        {
          name: 'rabiesTagExpiration',
          label: t(
            'Admin:CATALOG.VACCINATION_COMPOUND_INFO.OTHER_EXPIRATION_DATE',
          ),
          initialValue: variation?.rabiesTagExpiration || '',
          type: 'text',
        },
        {
          name: 'vetcoveSku',
          label: t('Admin:CATALOG.VARIATION.VET_COVE_COMPANY_SKU'),
          initialValue: variation?.vetcoveSku || '',
          type: 'text',
        },
        {
          name: 'vetcoveName',
          label: t('Admin:CATALOG.VARIATION.VET_COVE_COMPANY_NAME'),
          initialValue: variation?.vetcoveName || '',
          type: 'text',
        },
      ] as Array<FieldProp>

      if (inventory?.controlled) {
        fields.push({
          name: 'controlledSubstanceScheduleId',
          label: t('Common:CONTROLLED_SUBSTANCE'),
          initialValue: inventory?.controlledSubstanceScheduleId,
        })
      }

      return fields
    }

    const isDrug = useIsDrug(inventory)
    const isVetDiet = useIsVetDiet(inventory)
    const isGlobalItem = useIsGlobalInventoryItem(inventory)
    const isVaccine = useIsVaccine(inventory)
    const RabiesId =
      inventory?.categoryId &&
      Utils.findConstantIdByName(
        'Rabies',
        InventorySubCategory[inventory.categoryId],
      )
    const isRabies = RabiesId === inventory?.subcategoryId

    const priceSectionRef = useRef<VariationPriceSectionHandle>(null)
    const ndcFormRef = useRef<NdcNumberFormHandle>(null)

    const { fields, validate, reset } = useFields(
      getFields(variationProp, isVaccine, unitsStateConstant),
      false,
    )

    const {
      perPackageAmount,
      perPackageUnitsId,
      packageTypeId,
      variationName,
      nameType,
      formId,
      customForm,
      flavor,
      deliveryMethodId,
      strength,
      strengthCombo,
      strengthUnitsId,
      strengthColorId,
      weightMinimum,
      weightMaximum,
      inStockAmount,
      inStockUnitsId,
      location,
      active,
      description,
      reOrderPoint,
      reOrderQuantity,
      reOrderMaxQuantity,
      reOrderPointUnitId,
      reOrderQuantityUnitId,
      reOrderMaxQuantityUnitId,
      vaccineDefaultDeliveryLocationId,
      vaccineDefaultDeliveryLocation,
      vaccineTypeId,
      vaccineDurationOfImmunityOptionId,
      vaccineDurationOfImmunityUnitId,
      vaccineDurationOfImmunity,
      vaccineDefaultDoseTypeId,
      vaccineDeliveryMethodId,
      vaccineDefaultAmountId,
      vaccineDefaultAmount,
      rabiesTagExpirationOptionId,
      rabiesTagExpirationUnitId,
      rabiesTagExpiration,
      vetcoveSku,
      vetcoveName,
      controlledSubstanceScheduleId,
    } = fields

    const shipmentPermissions = useSelector(
      getCRUDByArea(PermissionArea.SHIPMENTS),
    )
    const adjustmentPermissions = useSelector(
      getCRUDByArea(PermissionArea.ADJUSTMENTS),
    )

    const [formStrengthNameVisible, setFormStrengthNameVisible] =
      useState(false)
    const [amountUnitNameVisible, setAmountUnitNameVisible] = useState(false)
    const [isPriceSectionValid, setIsPriceSectionValid] = useState(true)
    const [isTouched, setIsTouched] = useState(false)

    useFieldsChanged(() => {
      setIsTouched(true)
    }, fields)

    const inventoryIsSending = useSelector(getInventoryIsSending)
    const variationIsSending = useSelector(getVariationIsSending)
    const isLoading = inventoryIsSending || variationIsSending

    const isPriceMatchCapable =
      isCurrentBusinessOmniChannel && isSyncWithExternalPriceEnabled

    useEffect(() => {
      if (isPriceMatchCapable && globalVariationId) {
        dispatch(fetchVariationPartNumberDetails(globalVariationId))
      }
      if (variationId && inventoryId) {
        dispatch(fetchVariationCalculatedCost(inventoryId, variationId))
      }

      return () => {
        dispatch(clearVariationCalculatedCost())
        if (isPriceMatchCapable) {
          dispatch(clearVariationPartNumberDetails())
        }
      }
    }, [])

    useEffect(() => {
      const isVisible = Boolean(
        formId.value && strength.value && strengthUnitsId.value,
      )
      setFormStrengthNameVisible(isVisible)
    }, [formId.value, strength.value, strengthUnitsId.value])

    useEffect(() => {
      const isVisible = Boolean(
        perPackageAmount.value &&
          perPackageUnitsId.value &&
          packageTypeId.value,
      )
      setAmountUnitNameVisible(isVisible)
    }, [perPackageAmount.value, perPackageUnitsId.value, packageTypeId.value])

    useEffect(() => {
      reOrderQuantityUnitId.setValue(reOrderPointUnitId.value)
      reOrderMaxQuantityUnitId.setValue(reOrderPointUnitId.value)
    }, [reOrderPointUnitId.value])

    useEffect(() => {
      reOrderPointUnitId.setValue(reOrderQuantityUnitId.value)
      reOrderMaxQuantityUnitId.setValue(reOrderQuantityUnitId.value)
    }, [reOrderQuantityUnitId.value])

    useEffect(() => {
      reOrderQuantityUnitId.setValue(reOrderMaxQuantityUnitId.value)
      reOrderPointUnitId.setValue(reOrderMaxQuantityUnitId.value)
    }, [reOrderMaxQuantityUnitId.value])

    const getFormStrengthName = () => {
      const form = getManualInputSelectValue(
        ProductForm,
        formId.value,
        customForm.value,
      )
      const strengthUnits = LanguageUtils.getConstantTranslatedName(
        strengthUnitsId.value,
        DrugStrengthUnit,
      )
      const strengthValue = strength.value || strengthCombo.value
      const strengthText = strengthValue
        ? `${strengthValue} ${strengthUnits}`
        : ''
      return `${form}: ${strengthText}`
    }

    const getAmountUnitName = getCommonPackagingLabel(
      InventoryProductSizeUnit,
      PackageType,
    )
    const amountUnitFields = {
      perPackageUnitsId: perPackageUnitsId.value,
      packageTypeId: packageTypeId.value,
      perPackageAmount: perPackageAmount.value,
      count: variationProp?.count,
      saleUnitOfMeasure: variationProp?.saleUnitOfMeasure,
    }

    const [touched, setTouched] = useState(false)
    const [weightRangeIsValid, setWeightRangeIsValid] = useState(false)
    const [closeAfterUpdate, setCloseAfterUpdate] = useState(false)

    const validateWeightRange = (
      weightMinimumToValidate = '',
      weightMaximumToValidate = '',
    ) =>
      weightMinimumToValidate === '' ||
      R.isNil(weightMinimumToValidate) ||
      weightMaximumToValidate === '' ||
      R.isNil(weightMaximumToValidate) ||
      Number(weightMinimumToValidate) <= Number(weightMaximumToValidate)

    useEffect(() => {
      setWeightRangeIsValid(
        validateWeightRange(weightMinimum.value, weightMaximum.value),
      )
    }, [weightMinimum.value, weightMaximum.value])

    useEffect(() => {
      if (closeAfterUpdate && !isLoading) {
        setCloseAfterUpdate(false)
        dispatch(
          queueEasterEggEvent({ actionType: EasterEggEvents.SAVE_INVENTORY }),
        )
        if (onOk) {
          onOk()
        }
      }
    }, [closeAfterUpdate, isLoading])

    useEffect(() => {
      reset(getFields(variationProp, null, unitsStateConstant))
    }, [variationProp, priceSectionRef])

    const resetForm = () => {
      reset()
      priceSectionRef.current?.reset()
    }

    const getOnHandAmountLabel = getOnHandAmount(
      InventoryProductSizeUnit,
      PackageType,
    )

    const addOrUpdate = (clone = false) => {
      setTouched(true)
      const ndcNumberValidate = isDrug ? ndcFormRef.current?.validate : R.T
      const isActualPriceSectionValid = Boolean(
        priceSectionRef.current?.validate(),
      )
      setIsPriceSectionValid(isActualPriceSectionValid)

      if (
        ndcNumberValidate?.() &&
        validate() &&
        isActualPriceSectionValid &&
        weightRangeIsValid
      ) {
        const namesMap = {
          [NameType.CUSTOM]: () => variationName.value,
          [NameType.FORM_STRENGTH]: getFormStrengthName,
          [NameType.AMOUNT_UNIT_PACKAGE]: () =>
            getAmountUnitName(amountUnitFields),
        }
        const newName = namesMap[nameType.value]()

        const hasReOrderPoint = reOrderPoint.value || reOrderPoint.value === 0
        const hasReorderQuantity =
          reOrderQuantity.value || reOrderQuantity.value === 0
        const hasReOrderMaxQuantity =
          reOrderMaxQuantity.value || reOrderMaxQuantity.value === 0

        const newVariation = {
          ...(variationProp || ({} as VariationType)),
          name: newName,
          perPackageAmount: perPackageAmount.value,
          perPackageUnitsId: perPackageUnitsId.value,
          packageTypeId: packageTypeId.value,
          formId: formId.value,
          customForm: customForm.value,
          flavor: flavor.value,
          deliveryMethodId: deliveryMethodId.value,
          strength: strength.value,
          strengthUnitsId: strengthUnitsId.value,
          strengthColorId: strengthColorId.value,
          weightMinimum: UnitUtils.serializeWeightUnit({
            field: weightMinimum,
            unitsState: unitsStateConstant,
            initialValue: variationProp?.weightMinimum,
          }),
          weightMaximum: UnitUtils.serializeWeightUnit({
            field: weightMaximum,
            unitsState: unitsStateConstant,
            initialValue: variationProp?.weightMaximum,
          }),
          inStockAmount: inStockAmount.value,
          inStockUnitsId: inStockUnitsId.value,
          location: location.value,
          active: active.value,
          prices: priceSectionRef.current?.get(),
          description: description.value,
          reorderPoint: hasReOrderPoint ? reOrderPoint.value : undefined,
          reorderPointUnitId: hasReOrderPoint
            ? reOrderPointUnitId.value
            : undefined,
          reorderQuantity: hasReorderQuantity
            ? reOrderQuantity.value
            : undefined,
          reorderQuantityUnitId: hasReorderQuantity
            ? reOrderQuantityUnitId.value
            : undefined,
          maxQuantity: hasReOrderMaxQuantity
            ? reOrderMaxQuantity.value
            : undefined,
          maxQuantityUnitId: hasReOrderMaxQuantity
            ? reOrderMaxQuantityUnitId.value
            : undefined,
          vaccineDefaultDeliveryLocationId:
            vaccineDefaultDeliveryLocationId.value,
          vaccineDefaultDeliveryLocation: vaccineDefaultDeliveryLocation.value,
          vaccineTypeId: vaccineTypeId.value,
          vaccineDurationOfImmunityOptionId:
            vaccineDurationOfImmunityOptionId.value,
          vaccineDurationOfImmunityUnitId:
            vaccineDurationOfImmunityUnitId.value,
          vaccineDurationOfImmunity: vaccineDurationOfImmunity.value,
          vaccineDefaultDoseTypeId: vaccineDefaultDoseTypeId.value,
          vaccineDeliveryMethodId: vaccineDeliveryMethodId.value,
          vaccineDefaultAmountId: vaccineDefaultAmountId.value,
          vaccineDefaultAmount: vaccineDefaultAmount.value,
          vetcoveSku: vetcoveSku.value,
          vetcoveName: vetcoveName.value,
          ...(isRabies
            ? {
                rabiesTagExpirationOptionId: rabiesTagExpirationOptionId.value,
                rabiesTagExpirationUnitId: rabiesTagExpirationUnitId.value,
                rabiesTagExpiration: rabiesTagExpiration.value,
              }
            : {}),
          ...(isDrug
            ? { nationalDrugCode: ndcFormRef.current?.getNationalDrugCode() }
            : {}),
        }

        setCloseAfterUpdate(true)
        if (isEdit) {
          if (inventoryId) {
            dispatch(updateVariation(inventoryId, newVariation))
          }
        } else if (onVariationFilled) {
          onVariationFilled(newVariation, clone)
          if (nextVariation) {
            resetForm()
          }
        } else if (inventoryId) {
          dispatch(createVariation(inventoryId, newVariation))
        }
      }
    }

    useImperativeHandle(ref, () => ({
      getIsTouched: R.always(isTouched),
      save: addOrUpdate,
    }))

    const infoTitle = isVetDiet
      ? t('Admin:CATALOG.VARIATION.VET_DIET_INFO')
      : isDrug
        ? t('Admin:CATALOG.VARIATION.DRUG_INFO')
        : t('Admin:CATALOG.VARIATION.VACCINE_INFO')
    const notLastVariation =
      variationIndex && variationsCount && variationIndex < variationsCount - 1
    const isCustomCompound =
      !variationProp?.[
        isFoodCatalogEnabled
          ? 'globalInventoryVariationMappingId'
          : 'globalVariationId'
      ]
    const isReadOnlyCompound = isGlobalItem && !isCustomCompound
    const variationNumberStr =
      variationIndex && variationsCount
        ? t('Common:X_OF_Y', { x: variationIndex + 1, y: variationsCount })
        : ''

    const globalItemTitle = isEdit
      ? t('Admin:CATALOG.VARIATION.EDIT_INVENTORY_NAME_VARIATION', {
          inventoryName: inventory?.name,
        })
      : t('Admin:CATALOG.VARIATION.ADD_INVENTORY_NAME_VARIATION', {
          inventoryName: inventory?.name,
          variationNumberStr,
        })

    const flavorString = variationProp?.flavor ? `${variationProp?.flavor}` : ''

    const titleString = LanguageUtils.getSentence(
      [
        globalItemTitle,
        flavorString ? `(${flavorString})` : undefined,
        variationProp?.name,
      ],
      SentenceFormatter.REGULAR,
      ' | ',
    )

    return (
      <Grid
        container
        className={classes.container}
        direction="column"
        wrap="nowrap"
      >
        <Grid container item direction="column" wrap="nowrap">
          <Grid item xs={12}>
            <Text pb={1} pt={2} px={3} variant="hero2">
              {isGlobalItem
                ? titleString
                : isEdit
                  ? t('Common:EDIT_VARIATION')
                  : t('Common:ADD_VARIATION')}
            </Text>
          </Grid>
          <Grid className={classes.activeContainer} px={3}>
            <PuiSwitch field={active} label={active.label} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          className={classes.content}
          direction="column"
          px={3}
          py={2}
          wrap="nowrap"
        >
          {isEdit && (
            <>
              <PuiTextField
                className={classes.variationName}
                field={variationName}
                inputProps={{ maxLength: 100 }}
                label={`${variationName.label}*`}
                margin="none"
              />
              <Text
                strong
                className={classes.onHandAmount}
                pt={3}
                variant="body2"
              >
                {t('Common:ON-HAND')}:
                {variationProp ? getOnHandAmountLabel(variationProp) : ''}
              </Text>
            </>
          )}
          {isDrug && (
            <NdcNumberForm
              classes={{
                title: classes.ndcNumberTitle,
                titleContainer: classNames(
                  classes.ndcNumberContainer,
                  !isEdit && classes.firstHeaderOffsets,
                ),
                ndc11Label: classes.ndc11Label,
              }}
              nationalDrugCode={variationProp?.nationalDrugCode}
              ref={ndcFormRef}
            />
          )}

          {(isEdit || isDrug) && <Spacer spacing={2} />}

          <VariationSection
            defaultExpanded
            aria-label="variation-packaging-section"
            isValid={isAllFieldsValid([
              perPackageAmount,
              perPackageUnitsId,
              packageTypeId,
            ])}
            title={t('Common:PACKAGING')}
          >
            {isVetDiet ? (
              <InfoList
                container={false}
                items={[
                  {
                    name: t('Common:PACKAGE'),
                    value: getAmountUnitName(amountUnitFields),
                  },
                ]}
              />
            ) : (
              <Grid
                container
                alignItems="flex-end"
                wrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Grid item md={3} xs={6}>
                  <PuiTextField
                    field={{
                      ...perPackageAmount,
                      set: handleNumberInput(perPackageAmount.setValue, 10, 4),
                    }}
                    inputProps={{ maxLength: 15 }}
                    label={`${perPackageAmount.label}*`}
                    margin="none"
                  />
                </Grid>
                <div className={classes.divider}>/</div>
                <Grid item md={3} xs={6}>
                  <FormControl fullWidth margin="none">
                    <InputLabel htmlFor="per-package-unit-select">
                      {perPackageUnitsId.label}*
                    </InputLabel>
                    <PuiSelect
                      field={perPackageUnitsId}
                      input={<Input id="per-package-unit-select" />}
                      items={InventoryProductSizeUnit}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="flex-end"
                  md={3}
                  ml={3}
                  xs={12}
                >
                  <FormControl fullWidth margin="none">
                    <InputLabel htmlFor="package-type-select">
                      {packageTypeId.label}*
                    </InputLabel>
                    <PuiSelect
                      field={packageTypeId}
                      input={<Input id="package-type-select" />}
                      items={PackageType}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </VariationSection>

          <VariationSection
            defaultExpanded
            aria-label="variation-pricing-section"
            isValid={isPriceSectionValid}
            title={t('Common:PRICING')}
          >
            <VariationPriceSection
              key={variationIndex}
              packageTypeId={packageTypeId.value}
              perPackageUnitsId={perPackageUnitsId.value}
              ref={priceSectionRef}
              variation={variationProp}
            />
          </VariationSection>

          <VariationSection
            aria-label="variation-reorder-points-section"
            defaultExpanded={!isEdit}
            isValid={isAllFieldsValid([
              reOrderPointUnitId,
              reOrderQuantityUnitId,
              reOrderMaxQuantityUnitId,
            ])}
            title={t('Common:INVENTORY_RE-ORDER_POINTS')}
          >
            <VariationReOrderSection
              fields={{
                point: reOrderPoint,
                quantity: reOrderQuantity,
                maxQuantity: reOrderMaxQuantity,
                pointUnitId: reOrderPointUnitId,
                quantityUnitId: reOrderQuantityUnitId,
                maxQuantityUnitId: reOrderMaxQuantityUnitId,
              }}
              packageTypeId={packageTypeId.value}
              perPackageUnitsId={perPackageUnitsId.value}
            />
          </VariationSection>

          {isEdit && shipmentPermissions.read && (
            <VariationSection
              aria-label="variation-shipments-section"
              title={t('Common:SHIPMENTS')}
            >
              <VariationShipmentsSection variationId={variationId} />
            </VariationSection>
          )}

          {isEdit && adjustmentPermissions.read && (
            <VariationSection
              aria-label="variation-on-hand-adjustments-section"
              title={t('Admin:CATALOG.VARIATION.ON-HAND_ADJUSTMENTS')}
            >
              <VariationAdjustmentsSection
                packageTypeId={packageTypeId.value}
                perPackageAmount={variationProp?.perPackageAmount}
                perPackageUnitsId={perPackageUnitsId.value}
                variationId={variationId}
                variationName={variationName.value}
                onHandAmount={variationProp?.onHandAmount}
              />
            </VariationSection>
          )}

          {(isGlobalItem || isVaccine) && (
            <VariationSection
              aria-label="variation-info-section"
              defaultExpanded={!isEdit}
              isValid={
                isReadOnlyCompound ||
                isAllFieldsValid(
                  isVetDiet
                    ? [formId]
                    : isDrug
                      ? [formId, deliveryMethodId]
                      : [formId, vaccineDeliveryMethodId, vaccineTypeId],
                )
              }
              title={infoTitle}
              tooltipText={
                isVaccine
                  ? t('Tooltips:INFO_WILL_BE_USED_TO_AUTO-POPULATE')
                  : undefined
              }
            >
              {isGlobalItem && (
                <OldGlobalItemCompoundInfo
                  fields={
                    isVetDiet
                      ? {
                          formId,
                          customForm,
                          flavor,
                          description,
                          controlledSubstanceScheduleId,
                        }
                      : {
                          formId,
                          customForm,
                          flavor,
                          deliveryMethodId,
                          strength,
                          strengthCombo,
                          strengthUnitsId,
                          strengthColorId,
                          description,
                          controlledSubstanceScheduleId,
                        }
                  }
                  readOnly={isReadOnlyCompound}
                />
              )}
              {isVaccine && (
                <VaccinationCompoundInfo
                  fields={{
                    formId,
                    customForm,
                    vaccineDefaultDeliveryLocationId,
                    vaccineDefaultDeliveryLocation,
                    vaccineTypeId,
                    vaccineDurationOfImmunityOptionId,
                    vaccineDurationOfImmunityUnitId,
                    vaccineDurationOfImmunity,
                    vaccineDefaultDoseTypeId,
                    vaccineDeliveryMethodId,
                    vaccineDefaultAmountId,
                    vaccineDefaultAmount,
                    rabiesTagExpirationOptionId,
                    rabiesTagExpirationUnitId,
                    rabiesTagExpiration,
                  }}
                  isRabies={isRabies}
                />
              )}
            </VariationSection>
          )}

          {isDrug && (
            <VariationSection
              aria-label="variation-restriction-section"
              defaultExpanded={!isEdit}
              isValid={isAllFieldsValid([weightMaximum, weightMinimum])}
              title={t('Common:RESTRICTION_ONE')}
            >
              <Grid item md={12} xs={12}>
                <ErrorTooltip
                  message={t('Tooltips:WEIGHT_MINIMUM_LESS_THAN_MAXIMUM')}
                  open={touched && !weightRangeIsValid}
                >
                  <Grid container alignItems="center" spacing={3}>
                    <WeightRangeInput
                      maxWeightField={weightMaximum}
                      minWeightField={weightMinimum}
                    />
                  </Grid>
                </ErrorTooltip>
              </Grid>
            </VariationSection>
          )}

          <VariationSection
            aria-label="variation-distributor-section"
            title={t('Admin:CATALOG.VARIATION.DISTRIBUTOR_INFORMATION')}
          >
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <PuiTextField
                  field={vetcoveSku}
                  inputProps={{ maxLength: 50 }}
                  label={vetcoveSku.label}
                  margin="none"
                />
              </Grid>
              <Grid item xs>
                <PuiTextField
                  field={vetcoveName}
                  inputProps={{ maxLength: 50 }}
                  label={vetcoveName.label}
                  margin="none"
                />
              </Grid>
            </Grid>
          </VariationSection>

          {!isEdit && (
            <>
              <Spacer spacing={2} />
              <Grid item>
                <Text
                  strong
                  className={classes.sectionHeader}
                  mb={1}
                  variant="body2"
                >
                  {nameType.label}*
                </Text>
              </Grid>
              <Grid item>
                <RadioGroup
                  name="variationName"
                  value={nameType.value}
                  onChange={(event, value) => nameType.setValue(value)}
                >
                  {formStrengthNameVisible && (
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      control={<Radio value={NameType.FORM_STRENGTH} />}
                      label={t('Admin:CATALOG.VARIATION.USE_SUGGESTED_NAME', {
                        name: getFormStrengthName(),
                      })}
                    />
                  )}
                  {amountUnitNameVisible && (
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      control={<Radio value={NameType.AMOUNT_UNIT_PACKAGE} />}
                      label={t('Admin:CATALOG.VARIATION.USE_SUGGESTED_NAME', {
                        name: getAmountUnitName(amountUnitFields),
                      })}
                    />
                  )}
                  <Grid container alignItems="flex-end" wrap="nowrap">
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      control={<Radio value={NameType.CUSTOM} />}
                      label={t('Common:CUSTOM_NAME')}
                    />
                    <PuiTextField
                      disabled={nameType.value !== NameType.CUSTOM}
                      field={variationName}
                      id="variation-custom-name"
                      inputProps={{ maxLength: 50 }}
                      margin="none"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          container
          item
          alignItems="flex-end"
          className={classes.footer}
          justifyContent="space-between"
          px={3}
          py={2}
          wrap="nowrap"
        >
          {Boolean(variationIndex && variationIndex > 0) && (
            <Grid item mb={0.5}>
              <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
            </Grid>
          )}
          <Grid container item justifyContent="flex-end">
            <Grid item>
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading}
                loading={isLoading}
                onClick={() => addOrUpdate()}
              >
                {isEdit
                  ? t('Common:SAVE_ACTION')
                  : notLastVariation
                    ? t('Admin:CATALOG.VARIATION.CONTINUE_TO_NEXT_VARIATION', {
                        nextVariationName: nextVariation?.name,
                      })
                    : isGlobalItem
                      ? t('Common:SAVE_ACTION')
                      : t('Common:ADD_ACTION')}
              </ButtonWithLoader>
            </Grid>
            {!singleVariationOnly &&
              !isEdit &&
              isGlobalItem &&
              onVariationFilled && (
                <Fab
                  className={classes.button}
                  color="primary"
                  type="submit"
                  variant="extended"
                  onClick={() => addOrUpdate(true)}
                >
                  {t(
                    'Admin:CATALOG.VARIATION.ADD_ANOTHER_PACKAGE_FOR_VARIATION',
                    { variationName: variationProp?.name },
                  )}
                </Fab>
              )}
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default Variation
