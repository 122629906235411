import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils, StateLabel, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  getDiagnosisProblemStates,
  getDiagnosisStatus,
  getFeatureToggle,
  getFindingProblemStates,
} from '~/store/reducers/constants'
import {
  DiagnosisSnapshotItem,
  DiagnosisSnapshotItemType,
  ProblemStates,
} from '~/types'

interface DiagnosisStatusCellProps {
  inline?: boolean
  item: DiagnosisSnapshotItem
  shorten?: boolean
}

const DiagnosisStatusCell = ({
  item,
  shorten,
  inline,
}: DiagnosisStatusCellProps) => {
  const { t } = useTranslation(['Common', 'Clients'])
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const ProblemDiagnosisStates = useSelector(getDiagnosisProblemStates)
  const ProblemFindingStates = useSelector(getFindingProblemStates)
  const isPmrAiSummarizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PMR_AI_SUMMARIZATION),
  )
  const typeToStatesMap = {
    [DiagnosisSnapshotItemType.DIAGNOSIS_HISTORY]: DiagnosisStatus,
    [DiagnosisSnapshotItemType.DIAGNOSIS_LOG]: DiagnosisStatus,
    [DiagnosisSnapshotItemType.PROBLEM_DIAGNOSIS_LOG]: ProblemDiagnosisStates,
    [DiagnosisSnapshotItemType.PROBLEM_FINDING_LOG]: ProblemFindingStates,
  }
  const diagnosisStates =
    typeToStatesMap[item.type || DiagnosisSnapshotItemType.DIAGNOSIS_LOG]

  const statusDisplayName = LanguageUtils.getConstantTranslatedName(
    item.statusId,
    diagnosisStates,
  )
  const activeStatus =
    item.statusId && Utils.findById(item.statusId, diagnosisStates)

  const AddedByAiDiagnosisStatusId = Utils.findConstantIdByName(
    ProblemStates.ADDED_BY_AI,
    DiagnosisStatus,
  )

  const isHistoryRecord = !item.soapId
  const isAddedByAi =
    isPmrAiSummarizationEnabled && item.statusId === AddedByAiDiagnosisStatusId

  let stateName = statusDisplayName
  if (isAddedByAi) {
    stateName = ProblemStates.ADDED_BY_AI
  } else if (isHistoryRecord) {
    stateName = shorten
      ? t('Common:PREVIOUS_CLINIC')
      : t('Clients:TIMELINE.DIAGNOSIS_CARD.RECORD_FROM_PLACE', {
          diagnosedIn: item.diagnosedIn || t('Common:PREVIOUS_CLINIC'),
        })
  }

  return (
    <Dotdotdot clamp={1}>
      <StateLabel
        {...(isAddedByAi
          ? { warning: true }
          : activeStatus?.colorVariant
            ? { [activeStatus.colorVariant]: true }
            : {})}
        inline={inline}
      >
        {stateName}
      </StateLabel>
    </Dotdotdot>
  )
}

export default DiagnosisStatusCell
