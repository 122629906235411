import { getAPIPath, request } from './core'

export const fetchDocumentsListV2 = (
  businessId: string,
  appointmentId: string,
) =>
  request(getAPIPath(`/admin/services/documents/v2/instances`), {
    method: 'GET',
    params: { businessId, sourceId: appointmentId },
  })
