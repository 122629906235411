import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  clearWellnessPlanVersionTemplate,
  fetchWellnessPlanVersion,
  fetchWellnessPlanVersionTemplate,
} from '~/store/actions/wellnessPlans'
import {
  getWellnessPlanVersion,
  getWellnessPlanVersionTemplate,
} from '~/store/reducers/wellnessPlans'

export const useWellnessPlanVersionData = (
  wellnessPlanVersionId: string | undefined,
) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isClone = /\/clone\//.test(location.pathname)
  const isEdit = Boolean(wellnessPlanVersionId) && !isClone

  const versionTemplate = useSelector(getWellnessPlanVersionTemplate)
  const planToEdit = useSelector(getWellnessPlanVersion(wellnessPlanVersionId))

  useEffect(() => {
    if (wellnessPlanVersionId && isClone && !versionTemplate) {
      dispatch(fetchWellnessPlanVersionTemplate(wellnessPlanVersionId))
    } else if (wellnessPlanVersionId && !planToEdit) {
      dispatch(fetchWellnessPlanVersion(wellnessPlanVersionId, true))
    } else {
      dispatch(fetchWellnessPlanVersionTemplate())
    }
  }, [wellnessPlanVersionId])

  useEffect(
    () => () => {
      dispatch(clearWellnessPlanVersionTemplate())
    },
    [],
  )

  return { isClone, isEdit, versionTemplate, planToEdit }
}
