import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

export const ProcedurePriceCreateRedirect = () => {
  const { procedureId } = useParams()
  const baseProcedureRoute = `/admin/catalog/procedures`
  const procedureRoute = procedureId
    ? `${baseProcedureRoute}/${procedureId}`
    : baseProcedureRoute

  return <Navigate replace to={procedureRoute} />
}
