import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import { getCvcCumulusRhapsodyIntegrationEnabled } from '~/store/reducers/constants'
import { GlobalInventoryCatalogItem, Variation } from '~/types'

import GlobalItemSelectorVariationListItem from './GlobalItemSelectorVariationListItem'
import ListItem from './ListItem'

const useStyles = makeStyles(
  (theme) => ({
    listItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: 500,
    },
    variationsHeader: {
      paddingLeft: parseInt(theme.spacing(1), 10) + 2,
    },
    variationItem: {
      marginLeft: parseInt(theme.spacing(4), 10) - 2,
    },
  }),
  { name: 'GlobalItemSelectorItem' },
)

export interface GlobalItemSelectorVariationListProps {
  item: GlobalInventoryCatalogItem
  onSelectVariation: (variation: Variation, variationName: string) => void
  selectedVariationsMap: Record<string, Variation[]>
  toggleCustomCompound: (customCompoundId: string) => void
  variations: Variation[]
}

export const GlobalItemSelectorVariationList = ({
  item,
  variations,
  selectedVariationsMap,
  onSelectVariation,
  toggleCustomCompound,
}: GlobalItemSelectorVariationListProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const isCvcCumulusRhapsodyIntegrationEnabled = useSelector(
    getCvcCumulusRhapsodyIntegrationEnabled,
  )

  const { addedToCatalog = [], notAddedToCatalog = [] } = R.groupBy(
    (itemVariation) =>
      isCvcCumulusRhapsodyIntegrationEnabled &&
      itemVariation?.businessVariationIds?.length > 0
        ? 'addedToCatalog'
        : 'notAddedToCatalog',
    variations,
  )

  const customCompoundId = `${item.id}-custom-compound`
  const showCustomCompoundListItem =
    item?.compoundable && !isCvcCumulusRhapsodyIntegrationEnabled

  const renderVariationList = ({
    title,
    variationList,
    onClick = () => {},
    disabled,
    isSelected,
  }: {
    disabled?: boolean
    isSelected: boolean | ((variation: Variation) => boolean)
    onClick?: (variation: Variation, variationName: string) => void
    title: string
    variationList: Variation[]
  }) => (
    <Grid item>
      <Box className={classes.variationItem}>
        <Text strong className={classes.variationsHeader} variant="body2">
          {title}
        </Text>
      </Box>
      {variationList.map((variation) => (
        <GlobalItemSelectorVariationListItem
          className={classNames(classes.listItem, classes.variationItem)}
          disabled={disabled}
          isSelected={
            typeof isSelected === 'boolean' ? isSelected : isSelected(variation)
          }
          key={`${item.id}${variation.id}`}
          variation={variation}
          onClick={(variationName) => onClick(variation, variationName)}
        />
      ))}
    </Grid>
  )

  return (
    <Grid container item spacing={1}>
      {Boolean(addedToCatalog.length) &&
        renderVariationList({
          title: t('Common:VARIATIONS_ALREADY_IN_CATALOG'),
          variationList: addedToCatalog,
          disabled: true,
          isSelected: true,
        })}
      {Boolean(notAddedToCatalog.length) &&
        renderVariationList({
          title: t('Common:SELECT_VARIATIONS'),
          variationList: notAddedToCatalog,
          onClick: onSelectVariation,
          isSelected: (variation: Variation) =>
            Boolean(selectedVariationsMap[variation.id]),
        })}
      {showCustomCompoundListItem && (
        <ListItem
          className={classes.variationItem}
          isSelected={Boolean(selectedVariationsMap[customCompoundId])}
          key={customCompoundId}
          name={t('Common:CUSTOM_DRUG_COMPOUND')}
          onClick={() => toggleCustomCompound(customCompoundId)}
        />
      )}
    </Grid>
  )
}
