import { useTranslation } from 'react-i18next'
import { formatNumberWithDecimal } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { useGetNoShowCancellationPenaltyFee } from './useGetNoShowCancellationPenaltyFee'

export const useNoShowText = () => {
  const { t } = useTranslation(['Dialogs'])
  const { penaltyFee, isPenaltyFeeQueryLoading, penaltyFeeQueryError } =
    useGetNoShowCancellationPenaltyFee()

  if (isPenaltyFeeQueryLoading) {
    return null
  }

  if (penaltyFeeQueryError || !penaltyFee)
    return t('APPOINTMENT_DETAILS_DIALOG.NO_SHOW_ALERT_TEXT.DEFAULT')

  const amountToDisplay = formatNumberWithDecimal(penaltyFee)

  return t('APPOINTMENT_DETAILS_DIALOG.NO_SHOW_ALERT_TEXT.WITH_AMOUNT', {
    amount: amountToDisplay,
  })
}
