import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil, User, Utils } from '@pbt/pbt-ui-components'

import {
  Charges,
  ConstantEntity,
  GroupedItem,
  Invoice as GraphqlInvoice,
  InvoiceLineItem as InvoiceLineItemGenerated,
  Maybe,
  Payment as GraphQlPayment,
  PrescriptionType as PrescriptionTypeGenerated,
  RefundInvoice,
  RefundInvoiceLineItem,
  WellnessCoverage as GeneratedWellnessCoverage,
} from '~/api/graphql/generated/types'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import { PrescriptionType } from '~/constants/prescription'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import { OrderType } from '~/constants/SOAPStates'
import {
  fetchChargesByLog,
  getCharges,
  getChargesLoading,
} from '~/store/duck/charges'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  Invoice,
  InvoiceLineItem,
  Payment,
  RefundLineItem,
  WellnessCoverage,
} from '~/types'
import { CreateItemInput } from '~/types/entities/chargesSheet'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

import { getPrescriptionType } from './prescription'

export const isChargesInvoiceType = (
  charges: Charges | Nil,
): charges is Charges => charges?.type === InvoiceType.INVOICE

export const useGetAreChargesPostedAndEditable = (
  logType?: string | Nil,
  logId?: string | Nil,
) => {
  const dispatch = useDispatch()
  const charges = useSelector(getCharges)
  const chargesLoading = useSelector(getChargesLoading)
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  useEffect(() => {
    if (logType && logId && !chargesLoading && isEditPostedChargesEnabled) {
      dispatch(fetchChargesByLog({ logId, logType }))
    }
  }, [logType, logId, isEditPostedChargesEnabled])

  return charges && isEditPostedChargesEnabled && isChargesInvoiceType(charges)
}

const convertPrescriptionType = (
  apiPrescription?: Maybe<PrescriptionTypeGenerated>,
): PrescriptionType | undefined => {
  const prescriptionTypeMap: Record<
    PrescriptionTypeGenerated,
    PrescriptionType
  > = {
    [PrescriptionTypeGenerated.Chewy]: PrescriptionType.CHEWY,
    [PrescriptionTypeGenerated.InHouse]: PrescriptionType.IN_HOUSE,
    [PrescriptionTypeGenerated.ScriptOut]: PrescriptionType.SCRIPT_OUT,
  }

  return apiPrescription ? prescriptionTypeMap[apiPrescription] : undefined
}

export const refundInvoiceLineItemsToRefundLineItems = (
  items: RefundInvoiceLineItem[],
): RefundLineItem[] =>
  items.map((item) => ({
    id: item.id,
    price: item.price,
    refundQty: item.refundQty,
    refundedInvoice: {
      creationDate: item.refundedInvoice.creationDate,
      id: item.refundedInvoice.id,
      state: {
        id: item.refundedInvoice.state.id,
        name: item.refundedInvoice.state.name,
      },
    },
    restockQty: item.restockQty,
    subTotal: item.subTotal,
  }))

export const convertChargeSheetLineItemToInvoiceLineItem = (
  item: InvoiceLineItemGenerated,
): InvoiceLineItem => ({
  bundleId: item.groupSnapshotId || '',
  categoryId: item.inventoryCategoryId || '',
  clientId: item.clientId,
  cost: 0,
  costUnitsSize: null,
  creationDate: item.creationDate,
  declined: item.declined,
  // TODO when model will be finished add here discountAllowed
  discountAllowed: item.discountAllowed,
  discountAmount: item.discount,
  discountPerc: item.discountPerc || null,
  // TODO when model will be finished add here discountSourceId
  discountSourceId: undefined,
  // TODO when model will be finished add here dispensingFee
  dispensingFee: 0,
  eventId: item.eventId || '',
  extendedPrice: item.extendedPrice || 0,
  // TODO when model will be finished add here group
  group: '',
  // TODO when model will be finished add here groupName
  groupName: '',
  id: item.id,
  // TODO when model will be finished add here imagingVendorId
  imagingVendorId: undefined,
  invoiceId: item.invoiceId,
  inventoryControlled: item.inventoryControlled || false,
  inventory: {
    controlled: item.inventoryControlled || false,
  },
  items: undefined,
  refundLineItems: refundInvoiceLineItemsToRefundLineItems(
    item.refundLineItems,
  ),
  labTest: {
    orderId: item.labTestOrderId || '',
    type: item.labTestType || '',
    vendorId: item.labTestVendorId || '',
    logId: item.logId || '',
    logType: item.logType as OrderType,
    vendorCode: item.labTestVendorCode || '',
    vendorName: item.labTestVendorName || '',
    labTestSpecimen: item.labTestSpecimen || '',
    testFor: item.labTestTestFor || '',
  },
  labTestOrderId: item.labTestOrderId || '',
  labTestType: item.labTestType || '',
  labTestVendorId: item.labTestVendorId || '',
  logId: item.logId || '',
  logType: item.logType as OrderType,
  labTestVendorCode: item.labTestVendorCode || '',
  labTestVendorName: item.labTestVendorName || '',
  labTestSpecimen: item.labTestSpecimen || '',
  labTestTestFor: item.labTestTestFor || '',
  // TODO  when model will be finished add here minCharge
  minCharge: null,
  modificationDate: item.modificationDate,
  name: item.name,
  notes: item.notes || '',
  origin: item.origin,
  patientId: item.patientId,
  prepaid: item.prepaid,
  prepaidRemaining: item.prepaidRemaining || 0,
  price: {
    price: item.price,
    unitId: item.unitId || '',
    id: item.priceId,
    timeUnitId: item.timeUnitId || '',
    timeUnitSize: item.timeUnitSize || null,
    taxable: item.priceTaxable,
    taxes: [],
    discountAllowed: item.discountAllowed,
    minCharge: item.minCharge || null,
    name: item.name,
    originalCode: null,
    dispensingFee: item.dispensingFee || null,
    maxTimeUnitSize: item.priceMaxTimeUnitSize || null,
    minTimeUnitSize: item.priceMinTimeUnitSize || null,
  },
  priceId: item.priceId,
  priceItemId: item.priceItemId,
  priceTaxable: item.priceTaxable,
  priceUnits: item.priceUnits,
  priceUnitsSize: item.priceUnitsSize || null,
  procedure: {
    id: item.procedureId || '',
    procedureCategoryId: item.procedureCategoryId || '',
    code: item.procedureCode || '',
    modalityId: item.procedureModalityId || '',
    orderId: item.procedureOrderId || '',
    description: item.procedureDescription || '',
  },
  procedureCategoryId: item.procedureCategoryId || '',
  procedureCode: item.procedureCode || '',
  procedureModalityId: item.procedureModalityId || '',
  procedureOrderId: item.procedureOrderId || '',
  procedureDescription: item.procedureDescription || '',
  producerId: item.producerId,
  // TODO  when model will be finished add here procedureIdChanged
  producerIdChanged: undefined,
  quantity: item.quantity,
  soapId: item.soapId || '',
  soapLogModificationDate: item.soapLogModificationDate,
  // both state fields aliased from stateEntity which has id and name
  // @ts-ignore
  state: item.stateEntity?.name || '',
  // @ts-ignore
  stateId: item.stateEntity?.id || '',
  stateEntity: {
    id: item.stateEntity?.id || '',
    name: item.stateEntity?.name || '',
  },
  subTotal: item.subTotal,
  taxAmount: item.taxAmount,
  taxRate: item.taxRate,
  taxed: item.taxed,
  // @ts-ignore
  type: item.logType,
  unitId: item.unitId || '',
  updatedAt: item.updatedAt,
  usedQuantity: item.usedQuantity !== 0 ? item.usedQuantity || null : 0,
  // TODO  when model will be finished add here viewOrderNumber
  viewOrderNumber: undefined,
  wplanCharged: item.wplanCharged,
  wplanLogId: item.wplanLogId || '',
  orderNotes: item.orderNotes || '',
  prescriptionType: convertPrescriptionType(item.prescriptionType),
  priceMaxTimeUnitSize: item.priceMaxTimeUnitSize || null,
  priceMinTimeUnitSize: item.priceMinTimeUnitSize || null,
  priceTimeUnitId: item.timeUnitId || '',
  priceTimeUnitSize: item.timeUnitSize,
  variation: {
    customForm: item.variationCustomForm || '',
    deliveryMethodId: item.variationDeliveryMethodId || '',
    formId: item.variationFormId || '',
    packageTypeId: item.variationPackageTypeId || '',
    perPackageAmount: item.variationPerPackageAmount || '',
    perPackageUnitsId: item.variationPerPackageUnitsId || '',
    strength: item.variationStrength || '',
    strengthUnitsId: item.variationStrengthUnitsId || '',
  },
  variationCustomForm: item.variationCustomForm || '',
  variationDeliveryMethodId: item.variationDeliveryMethodId || '',
  variationFormId: item.variationFormId || '',
  variationPackageTypeId: item.variationPackageTypeId || '',
  variationPerPackageAmount: item.variationPerPackageAmount || '',
  variationPerPackageUnitsId: item.variationPerPackageUnitsId || '',
  variationStrength: item.variationStrength || '',
  variationStrengthUnitsId: item.variationStrengthUnitsId || '',
})

export const convertChargeSheetGroupedLineItemToInvoiceLineItem = (
  item: GroupedItem,
): InvoiceLineItem => {
  const convertedItem = {
    groupName: item.groupName,
    items:
      item.items && item.items.length > 0
        ? item.items.map((generatedItem) =>
            convertChargeSheetLineItemToInvoiceLineItem(generatedItem),
          )
        : [],
    id: item.groupSnapshotId || item.id,
    subTotal: item.subTotal,
    declined: item.declined,
  }
  return convertedItem as InvoiceLineItem
}

export const convertInvoiceLineItemToCreateItemInput = (
  items: InvoiceLineItem[],
  patientId: string,
  eventId?: string,
): CreateItemInput[] =>
  items.map((item) => ({
    bundleId: item.bundleId || undefined,
    eventId: item.eventId || eventId,
    group: item.group,
    logType: item.type || item.logType,
    name: item.name,
    patientId: item.patientId || patientId,
    prepaid: item.prepaid,
    priceId: item.priceId || R.path(['price', 'id'], item) || '',
    producerId: item.producerId || undefined,
    quantity: item.quantity,
    soapId: item.soapId,
    usedQuantity: item.usedQuantity || undefined,
  }))

export const isInvoiceLineItemInHouse = (item: InvoiceLineItem) =>
  getPrescriptionType(item.prescriptionType)?.isInHouse

// TODO need to reconsider this in the future either to make a better mapping or create a new InvoiceTotal components that suits open-api model
export const convertPaymentV3ToPayment = (
  payment: Payment,
  usersMap?: Record<string, User>,
): Payment => ({
  ...payment,
  paidByPerson:
    usersMap && payment.paidByPersonId
      ? usersMap[payment.paidByPersonId]
      : undefined,
  paidByPersonName: payment.paidByPersonName || undefined,
  type: payment.type || undefined,
  paymentMethod: R.defaultTo(
    '',
    payment.method?.name,
  ) as RhapsodyGoPaymentMethod,
  // TODO this might need to be changed on backend side
  description: payment.cardType
    ? `${payment.method?.name} | ${payment.cardType?.name}`
    : payment.method?.name || '',
})

// TODO need to reconsider this in the future either to make a better mapping or create a new InvoiceTotal components that suits open-api model
export const convertWellnessCoverageToInvoiceWellnessCoverage = (
  wellnessCoverage: GeneratedWellnessCoverage,
): WellnessCoverage => ({
  potential: {
    savedAmount: wellnessCoverage.potential?.savedAmount,
    earnedPoints: 0,
  },
  real: { savedAmount: wellnessCoverage.real?.savedAmount, earnedPoints: 0 },
})

// TODO need to reconsider this in the future either to make a better mapping or create a new InvoiceTotal components that suits open-api model
export const convertV3InvoiceToInvoiceOmitEventsAndPatients = (
  invoice: InvoiceV3,
  groupedItems: InvoiceLineItem[],
  usersMap?: Record<string, User>,
): Invoice => ({
  ...invoice,
  invoices: [],
  description: '',
  eventId: '',
  patient: '',
  additionalDiscount: invoice.additionalDiscount || 0,
  business: undefined,
  client: invoice.client,
  events: [],
  groups: [{ groupedItems }],
  name: invoice.name || undefined,
  payments: invoice.payments?.map((payment) =>
    convertPaymentV3ToPayment(payment, usersMap),
  ),
  requiredDeposit: invoice.requiredDeposit || 0,
  serviceFee: invoice.serviceFeeAmount || 0,
  type: InvoiceType.INVOICE,
  wellnessCoverage: invoice.wellnessCoverage
    ? convertWellnessCoverageToInvoiceWellnessCoverage(invoice.wellnessCoverage)
    : undefined,
})

export const getPaymentPaidPersonName = (payment: Payment | GraphQlPayment) =>
  payment.paidByPerson
    ? Utils.getPersonString({
        firstName: payment.paidByPerson.firstName || '',
        lastName: payment.paidByPerson.lastName || '',
      })
    : payment.paidByPersonName

export type UIRoliSection = {
  doctors: string[]
  id: 'retailOrder'
  orderId: string | Nil
  patients: { id: string; name: string }[]
  state: ConstantEntity | undefined
  total: number
}

export const getInvoiceSections = (
  invoice: Invoice | GraphqlInvoice | RefundInvoice,
  isChewyCheckoutEnabled: boolean,
) => {
  const roliSectionsLength =
    'retailOrder' in invoice ? (invoice.retailOrder?.sections?.length ?? 0) : 0
  const iliSectionsLength =
    'sections' in invoice ? (invoice.sections?.length ?? 0) : 0

  const hasRoliSections = roliSectionsLength >= 1
  const hasIliSections = iliSectionsLength >= 1

  const { retailOrder, sections } = invoice as GraphqlInvoice
  const retailOrderSections = retailOrder?.sections
  const retailOrderSection: UIRoliSection = {
    id: 'retailOrder',
    patients: R.uniqBy(
      R.prop('id'),
      retailOrderSections?.map((s) => ({
        id: s.patient?.id,
        name: s.patient?.name,
      })) ?? [],
    ),
    doctors: R.uniq(
      retailOrderSections?.map((s) => {
        const { assignedVetId, resources } = s.event ?? {}
        const veterinarian = resources?.find(
          (resource) => resource.employee.id === assignedVetId,
        )?.employee
        const doctor = Utils.getPersonString(veterinarian as User)
        return doctor
      }) ?? [],
    ),
    total: retailOrder?.totalPrice,
    state: retailOrder?.state,
    orderId: retailOrder?.orderConfirmationId,
  }

  const invoiceSections = isChewyCheckoutEnabled
    ? R.uniqBy(R.prop('id'), [
        ...(hasIliSections ? sections : []),
        ...(hasRoliSections ? [retailOrderSection] : []),
      ])
    : (invoice as GraphqlInvoice | RefundInvoice)?.sections

  const totalSections = invoiceSections?.length ?? 0

  return {
    hasMultipleLines: totalSections > 1,
    totalSections,
    hasIliSections,
    hasRoliSections,
    hasSections: isChewyCheckoutEnabled
      ? hasRoliSections || hasIliSections
      : hasIliSections,
    invoiceSections,
  }
}

export const useGetInvoiceSectionsWithLimit = (
  invoice: Invoice | GraphqlInvoice | RefundInvoice,
  multipleRowsLimit: number | Nil,
) => {
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const { hasSections, invoiceSections } = getInvoiceSections(
    invoice,
    isChewyCheckoutEnabled,
  )

  if (hasSections) {
    const sections = R.isNil(multipleRowsLimit)
      ? invoiceSections
      : invoiceSections.slice(0, multipleRowsLimit)

    return sections
  }

  return null
}
