import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { Defaults, PrimitiveTableColumn } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchInventoriesList,
  fetchMoreItemsForInventoriesList,
  setInventoriesListFilters,
} from '~/store/actions/inventories'
import { getCvcCumulusRhapsodyIntegrationEnabled } from '~/store/reducers/constants'
import {
  getInventoriesList,
  getInventoriesListFilters,
  getInventoryIsReceiving,
  getInventoryIsSending,
  getMultipleInventories,
  getTotalInventoryCount,
} from '~/store/reducers/inventories'
import { TableFilter } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'

import InventoryCategoryCell from './InventoryCategoryCell'
import InventoryDetails from './InventoryDetails'
import InventorySubCategoryCell from './InventorySubCategoryCell'
import InventoryTableCategoryFilter from './InventoryTableCategoryFilter'
import InventoryTableFlagIcon from './InventoryTableFlagIcon'
import InventoryTableStoreColdIcon from './InventoryTableStoreColdIcon'
import NoInventoriesScreen from './NoInventoriesScreen'

const allColumns = {
  name: {
    label: i18n.t('Common:NAME'),
    prop: 'name',
    highlight: true,
  },
  internalPartNumber: {
    label: i18n.t('Common:INTERNAL_PART_NUMBER'),
    prop: 'cumulusId',
  },
  category: {
    label: i18n.t('Common:CATEGORY'),
    prop: InventoryCategoryCell,
    filter: 'categoryIds',
    FilterComponent: InventoryTableCategoryFilter,
  },
  subcategory: {
    label: i18n.t('Common:SUB-CATEGORY'),
    prop: InventorySubCategoryCell,
  },
  species: {
    label: i18n.t('Common:SPECIES'),
    prop: SpeciesCell,
  },
  storeCold: {
    label: i18n.t('Common:STORE_COLD'),
    prop: InventoryTableStoreColdIcon,
  },
  controlled: {
    label: i18n.t('Common:CONTROLLED'),
    prop: InventoryTableFlagIcon,
  },
}

export interface InventoryTableComponentProps {
  headerButtons?: React.ReactNode
  headerTabs?: React.ReactNode
  itemId?: string
  onDetailsClose: () => void
}

const InventoryTableComponent = ({
  itemId,
  headerButtons,
  headerTabs,
  onDetailsClose,
}: InventoryTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const inventoriesList = useSelector(getInventoriesList)
  const inventories = useSelector(getMultipleInventories(inventoriesList))
  const totalCount = useSelector(getTotalInventoryCount)
  const inventoryIsReceiving = useSelector(getInventoryIsReceiving)
  const inventoryIsSending = useSelector(getInventoryIsSending)
  const filters = useSelector(getInventoriesListFilters)
  const isLoading = inventoryIsSending || inventoryIsReceiving
  const { t } = useTranslation('Common')
  const isCvcCumulusRhapsodyIntegrationEnabled = useSelector(
    getCvcCumulusRhapsodyIntegrationEnabled,
  )
  const columns: PrimitiveTableColumn[] = isCvcCumulusRhapsodyIntegrationEnabled
    ? [
        { ...allColumns.name, width: 3 },
        { ...allColumns.internalPartNumber, width: 2 },
        { ...allColumns.category, width: 2 },
        { ...allColumns.subcategory, width: 3 },
        { ...allColumns.species, width: 3 },
        { ...allColumns.storeCold, width: 1.5 },
        { ...allColumns.controlled, width: 1.5 },
      ]
    : [
        { ...allColumns.name, width: 4 },
        { ...allColumns.category, width: 5 },
        { ...allColumns.subcategory, width: 4 },
        { ...allColumns.species, width: 2 },
        { ...allColumns.controlled, width: 1 },
      ]

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setInventoriesListFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setInventoriesListFilters({}))
  }

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchInventoriesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search, filters])

  const navigateToInventory = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/inventories/catalog/${id}`))
  }

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForInventoriesList(startIndex, endIndex, search))
  }

  const isItemLoaded = (index: number) => Boolean(inventories[index])

  return (
    <ExpandableTable
      Expander={InventoryDetails}
      NoItemsScreen={NoInventoriesScreen}
      hasSelectedFilters={!isEmpty(filters)}
      headerButtons={headerButtons}
      headerTabs={headerTabs}
      isLoading={isLoading}
      itemId={itemId}
      list={inventories}
      searchTerm={search}
      title={t('Common:INVENTORY')}
      onClose={onDetailsClose}
      onSelected={navigateToInventory}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default InventoryTableComponent
