/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, SelectChangeEvent } from '@mui/material'
import {
  CircularProgressOverlay,
  PuiSelect,
  Text,
  TimezoneSelect,
  useFields,
} from '@pbt/pbt-ui-components'
import {
  Download as DownloadIcon,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import PuiIconButton from '~/components/common/PuiIconButton'
import { MigrationSteps } from '~/constants/migrationV2'
import i18n from '~/locales/i18n'
import {
  getActivationToken,
  getCurrentSession,
  getImportSettings,
  getIsLoadingExporterUrl,
  getIsLoadingImportSessionDetails,
  updateMigrationSessionSettings,
  updateMigrationSessionStage,
} from '~/store/duck/migrationV2'
import { useRhapsodyExporter } from '~/store/hooks/migrationV2'
import {
  getPimsFull,
  getTimezoneGroups,
  getTimezones,
} from '~/store/reducers/constants'
import {
  BaseStepComponentProps,
  MigrationSessionStage,
  SupportedPims,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const MigrationSessionStageToLabel = {
  [MigrationSessionStage.NEW]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.NEW',
  ),
  [MigrationSessionStage.EXPORT]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.EXPORT',
  ),
  [MigrationSessionStage.CONVERSION]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.CONVERSION',
  ),
  [MigrationSessionStage.REVIEW]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.REVIEW',
  ),
  [MigrationSessionStage.ANALYSIS]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.ANALYSIS',
  ),
  [MigrationSessionStage.IMPORT]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.IMPORT',
  ),
  [MigrationSessionStage.DELTA]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.DELTA',
  ),
  [MigrationSessionStage.DONE]: i18n.t(
    'Businesses:MIGRATION.SESSION_STAGES.DONE',
  ),
}

interface SessionOverviewItemProps {
  label: string
}

const SessionOverviewItem = ({
  label,
  children,
}: React.PropsWithChildren<SessionOverviewItemProps>) => (
  <Grid container alignItems="center" height={48}>
    <Grid item flex={1}>
      <Text variant="h4">{label}</Text>
    </Grid>
    <Grid item alignItems="center" display="flex" flex={1}>
      {children}
    </Grid>
  </Grid>
)

const SessionOverview = ({ stepController }: BaseStepComponentProps) => {
  const { t } = useTranslation(['Common', 'Businesses', 'Abbreviations'])
  const dispatch = useDispatch()

  const currentSession = useSelector(getCurrentSession)
  const currentSessionSettings = useSelector(getImportSettings)
  const isLoading = useSelector(getIsLoadingImportSessionDetails)
  const isLoadingExporterUrl = useSelector(getIsLoadingExporterUrl)
  const activationToken = useSelector(getActivationToken)
  const PimsFull = useSelector(getPimsFull)
  const timeZones = useSelector(getTimezones)
  const timeZoneGroups = useSelector(getTimezoneGroups)

  const exporterPimsName = PimsFull.find(
    ({ id: pimsId }) => pimsId === currentSession?.exporterPimsId,
  )?.name
  const isExporterPimsCovetrus = exporterPimsName === SupportedPims.Covetrus
  const { downloadExporter, rhapsodyExporterType } =
    useRhapsodyExporter(exporterPimsName)

  const handleProceed = () => {
    stepController.next()
  }

  const {
    fields: { sessionStage },
  } = useFields(
    [
      {
        name: 'timeZone',
        initialValue: currentSessionSettings?.timeZone,
        type: 'select',
      },
      {
        name: 'sessionStage',
        initialValue: currentSession?.stage,
        type: 'select',
      },
    ],
    false,
  )

  // Only update session stage selector if network request is successful
  useEffect(() => {
    if (currentSession?.stage && currentSession.stage !== sessionStage.value) {
      sessionStage.setValue(currentSession.stage)
    }
  }, [currentSession?.stage, sessionStage.value])

  const migrationSessionStages = Object.values(MigrationSessionStage).map(
    (key) => ({
      id: key,
      name: key,
      nameTranslation: MigrationSessionStageToLabel[key],
    }),
  )

  const handleUpdateMigrationSessionStage = (e: SelectChangeEvent<unknown>) => {
    if (!currentSession) {
      return
    }
    dispatch(
      updateMigrationSessionStage({
        sessionId: currentSession.id,
        stageTo: e.target.value as MigrationSessionStage,
      }),
    )
  }

  const handleUpdateMigrationSessionTimezone = (
    e: SelectChangeEvent<string>,
  ) => {
    if (!currentSession || !currentSessionSettings) {
      return
    }

    dispatch(
      updateMigrationSessionSettings({
        sessionId: currentSession?.id,
        settings: {
          ...currentSessionSettings,
          timeZone: e.target.value,
        },
      }),
    )
  }

  const handleEditCovetrusConfig = () => {
    stepController.setCurrentStepOverride({
      migrationStep: MigrationSteps.COVETRUS_CONFIG,
      props: {
        onNext: () => {
          // Removes step override and returns to SessionOverview step
          stepController.setCurrentStepOverride(undefined)
        },
      },
    })
  }

  const handleViewAndEditSessionFiles = () => {
    stepController.setCurrentStepOverride({
      migrationStep: MigrationSteps.SESSION_FILE_VIEW,
      props: {
        onNext: () => {
          // Removes step override and returns to SessionOverview step
          stepController.setCurrentStepOverride(undefined)
        },
      },
    })
  }

  return (
    <MigrationStep>
      <MigrationStepContent height={320}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:MIGRATION.LOADING_SESSION_DETAILS')}
          />
        ) : (
          <Grid container direction="column" p={1}>
            <SessionOverviewItem
              label={t(
                'Abbreviations:ACRONYMS.PRODUCTION_INFORMATION_MANAGEMENT_SYSTEM.LABEL_ABBREVIATION',
              )}
            >
              <Text mr={1}>{exporterPimsName}</Text>
              {rhapsodyExporterType && (
                <PuiIconButton
                  Icon={isLoadingExporterUrl ? CircularProgress : DownloadIcon}
                  IconProps={isLoadingExporterUrl ? { size: 24 } : null}
                  disabled={isLoadingExporterUrl}
                  title={t('Businesses:MIGRATION.DOWNLOAD_EXPORTER')}
                  onClick={downloadExporter}
                />
              )}
            </SessionOverviewItem>
            {isExporterPimsCovetrus && (
              <SessionOverviewItem
                label={t('Businesses:MIGRATION.COVETRUS_CONFIG')}
              >
                <Text mr={1}>
                  {t('Businesses:MIGRATION.SETUP_COVETRUS_INTEGRATION')}
                </Text>
                <PuiIconButton
                  Icon={EditIcon}
                  size="small"
                  onClick={handleEditCovetrusConfig}
                />
              </SessionOverviewItem>
            )}
            <SessionOverviewItem
              label={t('Businesses:MIGRATION.ACTIVATION_TOKEN')}
            >
              <Text>{activationToken}</Text>
            </SessionOverviewItem>
            <SessionOverviewItem
              label={t('Businesses:MIGRATION.SESSION_STAGE')}
            >
              <PuiSelect
                field={sessionStage}
                items={migrationSessionStages}
                onChange={handleUpdateMigrationSessionStage}
              />
            </SessionOverviewItem>
            <SessionOverviewItem
              label={t('Businesses:MIGRATION.SOURCE_DATA_TIME_ZONE')}
            >
              <TimezoneSelect
                useTimezoneGroupVariant
                timezoneGroups={timeZoneGroups}
                timezones={timeZones}
                value={currentSessionSettings?.timeZone}
                onChange={handleUpdateMigrationSessionTimezone}
              />
            </SessionOverviewItem>
            <SessionOverviewItem
              label={t('Businesses:MIGRATION.SESSION_FILES')}
            >
              <Text mr={1}>
                {t('Businesses:MIGRATION.SESSION_FILES_VIEW_AND_EDIT')}
              </Text>
              <PuiIconButton
                Icon={EditIcon}
                size="small"
                onClick={handleViewAndEditSessionFiles}
              />
            </SessionOverviewItem>
          </Grid>
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:PROCEED')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionOverview
