import React from 'react'

import { useVariationName } from '~/store/hooks/prescriptions'
import { Variation } from '~/types'

import ListItem, { ListItemProps } from './ListItem'

export interface GlobalItemSelectorVariationListItemProps
  extends Omit<ListItemProps, 'onClick' | 'name' | 'obsolete'> {
  onClick: (name: string) => void
  variation: Variation
}

const GlobalItemSelectorVariationListItem = ({
  variation,
  onClick,
  ...rest
}: GlobalItemSelectorVariationListItemProps) => {
  const variationName = useVariationName(variation)

  const variationFlavor = variation.flavor ? ` | (${variation.flavor})` : ''

  const variationsCount = variation.businessVariationIds
    ? variation.businessVariationIds.length
    : 0

  const variationsCountStr = variationsCount > 0 ? ` (${variationsCount})` : ''

  return (
    <ListItem
      wrapDescription
      name={`${variationName}${variationFlavor}${variationsCountStr}`}
      onClick={() => onClick(variationName)}
      {...rest}
      obsolete={variation.obsolete}
    />
  )
}

export default GlobalItemSelectorVariationListItem
