import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'
import { getDomainContext } from '@pbt/pbt-ui-components/src/utils'

import { tokenHolder } from '~/api/utils/token'
import FeatureToggle from '~/constants/featureToggle'
import { oauthLoginRocketChat, setChatUnreadCount } from '~/store/actions/chat'
import {
  getCurrentBusinessChatEnabled,
  getCurrentBusinessId,
  getCurrentUserId,
} from '~/store/reducers/auth'
import { getChatAuthToken } from '~/store/reducers/chat'
import { getFeatureToggle } from '~/store/reducers/constants'

const ChatUrlMap: Partial<Record<Environment, string>> = {
  [Environment.PROD]: 'https://chat.rhapsody.vet',
  [Environment.MASTER]: 'https://chat.master.rhapsody.vet',
  [Environment.STAGE]: 'https://chat.stage.rhapsody.vet',
}

const RhapsodyChatUrlMap: Partial<Record<Environment, string>> = {
  [Environment.PROD]: 'https://rc.rhapsody.vet',
  [Environment.MASTER]: 'https://rc.master.rhapsody.vet',
  [Environment.STAGE]: 'https://rc.stage.rhapsody.vet',
}

const domainContext = getDomainContext()

const chatUrl = ChatUrlMap[domainContext.env] || ChatUrlMap[Environment.STAGE]
const rhapsodyChatUrl =
  RhapsodyChatUrlMap[domainContext.env] || RhapsodyChatUrlMap[Environment.STAGE]

enum ObservableRCIframeEvent {
  STARTUP = 'startup',
  LOGGED_OUT = 'Custom_Script_Logged_Out',
  LOGGED_IN = 'Custom_Script_Logged_In',
  UNREAD_CHANGED = 'unread-changed',
  CUSTOM_UNREAD_CHANGED = 'custom-unread-changed',
}

export type RCContextValue = {
  setIframeContainerAnchor: (anchor: HTMLDivElement | null) => void
}

export const RCContext = React.createContext<RCContextValue>({
  setIframeContainerAnchor: () => {},
})

type RCIFrameProviderProps = {
  children?: React.ReactNode
}

const RCIFrameProvider = ({ children }: RCIFrameProviderProps): JSX.Element => {
  const dispatch = useDispatch()

  const isRCIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_INTEGRATION),
  )
  const isRCPhase2IntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_PHASE_2_INTEGRATION),
  )
  const currentBusinessChatEnabled = useSelector(getCurrentBusinessChatEnabled)
  const currentUserId = useSelector(getCurrentUserId)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const authToken = useSelector(getChatAuthToken)
  const iframeBaseUrl = isRCPhase2IntegrationEnabled ? rhapsodyChatUrl : chatUrl

  const [iframeContainerAnchor, setIframeContainerAnchor] =
    useState<HTMLDivElement | null>(null)
  const [isChatStarted, setIsChatStarted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isReloadingUser, setIsReloadingUser] = useState(false)
  const [loginTimeoutId, setLoginTimeoutId] = useState<number | null>(null)
  const [chatAccessToken, setChatAccessToken] = useState<string | null>(null)
  const [iframeUrl, setIframeUrl] = useState<string>(iframeBaseUrl || '')

  useEffect(() => {
    const asyncSetChatAccessToken = async () => {
      setChatAccessToken(await tokenHolder.getToken())
    }
    asyncSetChatAccessToken()
  }, [currentUserId])

  const chatDisabled = !isRCIntegrationEnabled || !authToken
  const rhapsodyChatDisabled =
    !isRCPhase2IntegrationEnabled || !currentBusinessChatEnabled

  const refreshAuthToken = () => {
    if (!isRCPhase2IntegrationEnabled && isRCIntegrationEnabled) {
      dispatch(oauthLoginRocketChat())
    }
  }

  useEffect(() => {
    refreshAuthToken()
  }, [currentUserId, currentBusinessId])

  const contextValue = useMemo(
    () => ({
      setIframeContainerAnchor,
    }),
    [setIframeContainerAnchor],
  )

  const ref = useRef<HTMLIFrameElement>(null)

  const sendIframeMessage = (message?: any) => {
    const iframeWindow = ref.current?.contentWindow
    if (iframeWindow) {
      iframeWindow.postMessage(message, iframeBaseUrl || '/')
    }
  }

  const handleStartUp = () => {
    refreshAuthToken()
    setIsChatStarted(true)
  }

  const loginUserAndSetAsCurrent = () => {
    sendIframeMessage({
      externalCommand: 'login-with-token',
      token: authToken,
    })
  }

  const handleSetUnreadCount = (data?: any) => {
    dispatch(setChatUnreadCount(data))
  }

  const handleLoggedIn = () => {
    if (isLoading) {
      // This timeout is more aesthetic than functionally required. There is a delay between the login
      // event and when the iFrame fully refreshes, so this just keeps the loading screen up a little
      // while longer.
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }
  }

  const handleLoggedOut = () => {
    if (loginTimeoutId) {
      clearTimeout(loginTimeoutId)
    }

    if (isReloadingUser) {
      loginUserAndSetAsCurrent()
      setIsReloadingUser(false)
    }
  }

  useEffect(() => {
    if (isChatStarted && authToken) {
      const iframeWindow = ref.current?.contentWindow
      if (iframeWindow) {
        setIsLoading(true)
        setIsReloadingUser(true)
        sendIframeMessage({ externalCommand: 'logout' })
        setLoginTimeoutId(window.setTimeout(handleLoggedOut, 3000))
      }
    }
  }, [authToken, isChatStarted])

  const EventHandlerMap: Record<ObservableRCIframeEvent, (data: any) => void> =
    {
      [ObservableRCIframeEvent.STARTUP]: handleStartUp,
      [ObservableRCIframeEvent.UNREAD_CHANGED]: handleSetUnreadCount,
      [ObservableRCIframeEvent.LOGGED_IN]: handleLoggedIn,
      [ObservableRCIframeEvent.LOGGED_OUT]: handleLoggedOut,
      [ObservableRCIframeEvent.CUSTOM_UNREAD_CHANGED]: handleSetUnreadCount,
    }

  useEffect(() => {
    const messageHandler = (
      e: MessageEvent<{ data: any; eventName: ObservableRCIframeEvent }>,
    ) => {
      const eventHandler = EventHandlerMap[e.data.eventName]
      if (eventHandler) {
        eventHandler(e.data.data)
      }
    }

    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [isLoading, isReloadingUser, loginTimeoutId])

  useEffect(() => {
    if (iframeBaseUrl && currentBusinessId && chatAccessToken) {
      setIframeUrl(
        `${iframeBaseUrl}?businessId=${currentBusinessId}&token=${chatAccessToken}`,
      )
    }
  }, [iframeBaseUrl, currentBusinessId, chatAccessToken])

  if (!chatAccessToken || (chatDisabled && rhapsodyChatDisabled)) {
    return <>{children}</>
  }

  const iframe = (
    <>
      {isLoading && iframeContainerAnchor && (
        <Grid
          container
          alignItems="center"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <CircularProgress color="primary" size={32} />
        </Grid>
      )}
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe
        frameBorder="0"
        height="100%"
        hidden={isLoading || !iframeContainerAnchor}
        ref={ref}
        src={iframeUrl || ''}
        title="internal-chat-iframe"
        width="100%"
      />
    </>
  )

  return (
    <RCContext.Provider value={contextValue}>
      {iframeContainerAnchor
        ? createPortal(iframe, iframeContainerAnchor)
        : iframe}
      {children}
    </RCContext.Provider>
  )
}

export default RCIFrameProvider
