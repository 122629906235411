import { useEffect } from 'react'

import { useProcedureByInternalCodeLazyQuery } from '~/api/graphql/generated/types'
import {
  AppointmentPenaltyProcedureCodes,
  getPenaltyFeeByOriginalCode,
} from '~/constants/procedures'

export const useGetNoShowCancellationPenaltyFee = () => {
  const [
    fetchLateCancellationFeeQuery,
    {
      data: lateCancellationFeeData,
      loading: isLateCancellationFeeQueryLoading,
      error: lateCancellationFeeQueryError,
    },
  ] = useProcedureByInternalCodeLazyQuery({
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })
  const [
    fetchMissedAppointmentFeeQuery,
    {
      data: missedAppointmentFeeData,
      loading: isMissedAppointmentFeeQueryLoading,
      error: missedAppointmentFeeQueryError,
    },
  ] = useProcedureByInternalCodeLazyQuery({
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })

  useEffect(() => {
    if (!lateCancellationFeeData && !missedAppointmentFeeData) {
      fetchLateCancellationFeeQuery({
        variables: {
          internalCode: AppointmentPenaltyProcedureCodes.LATE_CANCELLATION_FEE,
        },
      })
      fetchMissedAppointmentFeeQuery({
        variables: {
          internalCode: AppointmentPenaltyProcedureCodes.MISSED_APPOINTMENT_FEE,
        },
      })
    }
  }, [lateCancellationFeeData, missedAppointmentFeeData])

  let calculatedPenaltyFee

  if (!lateCancellationFeeQueryError && !missedAppointmentFeeQueryError) {
    const lateCancellationFeePrices =
      lateCancellationFeeData?.procedureByInternalCode?.prices?.data
    const missedAppointmentFeePrices =
      missedAppointmentFeeData?.procedureByInternalCode?.prices?.data

    const lateCancellationFee = getPenaltyFeeByOriginalCode(
      lateCancellationFeePrices,
      AppointmentPenaltyProcedureCodes.LATE_CANCELLATION_FEE,
    )
    const missedAppointmentFee = getPenaltyFeeByOriginalCode(
      missedAppointmentFeePrices,
      AppointmentPenaltyProcedureCodes.MISSED_APPOINTMENT_FEE,
    )

    calculatedPenaltyFee =
      lateCancellationFee && missedAppointmentFee
        ? Math.max(lateCancellationFee, missedAppointmentFee)
        : null
  }

  return {
    penaltyFee: calculatedPenaltyFee,
    isPenaltyFeeQueryLoading:
      isLateCancellationFeeQueryLoading || isMissedAppointmentFeeQueryLoading,
    penaltyFeeQueryError:
      lateCancellationFeeQueryError || missedAppointmentFeeQueryError,
  }
}
