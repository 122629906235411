import { gql } from '@apollo/client/core'

import { PRESCRIPTION_FIELDS } from '../fragments'

export const FETCH_PRESCRIPTION = gql`
  ${PRESCRIPTION_FIELDS}
  query Prescription($businessId: ID, $prescriptionId: ID!) {
    prescription(businessId: $businessId, id: $prescriptionId) {
      ...PrescriptionFields
    }
  }
`

export const REACTIVE_RX_UNLINKED_CLIENT_DETAILS = gql`
  query ReactiveRxUnlinkedClientDetails($prescriptionId: ID!) {
    reactiveRxUnlinkedClientDetails(prescriptionId: $prescriptionId) {
      clientFirstName
      clientLastName
      patientName
    }
  }
`
export const FETCH_ACTIVE_RX_WORKFLOW_ALLOWANCE = gql`
  query ActiveRxWorkflowAllowance(
    $businessId: ID!
    $clientId: ID!
    $patientId: ID!
  ) {
    activeRxWorkflowAllowance(
      businessId: $businessId
      clientId: $clientId
      patientId: $patientId
    ) {
      business
      client
      patient
    }
  }
`

export const FETCH_GLOBAL_ITEM_VARIATION = gql`
  query GlobalItemVariation($id: ID!) {
    globalItemVariations(ids: [$id]) {
      id
      name
      strength
      form {
        id
        name
      }
      strengthUnit {
        id
        name
      }
      strengthColor {
        id
        name
      }
      deliveryMethod {
        id
        name
      }
      chewyItems {
        count
        name
        inStock
        strength
        partNumber
        price
      }
      globalInventoryMapping {
        id
        ... on DrugCatalogMappingItem {
          requiresPrescription
        }
      }
    }
  }
`

export const FETCH_GLOBAL_ITEM_VARIATIONS = gql`
  query GlobalItemVariations($ids: [ID!]!) {
    globalItemVariations(ids: $ids) {
      id
      name
      strength
      form {
        id
        name
      }
      strengthUnit {
        id
        name
      }
      strengthColor {
        id
        name
      }
      deliveryMethod {
        id
        name
      }
      chewyItems {
        count
        name
        inStock
        strength
        partNumber
        price
      }
      globalInventoryMapping {
        id
        ... on DrugCatalogMappingItem {
          requiresPrescription
        }
      }
    }
  }
`

export const GET_ALL_ACTIVE_RX_FOR_SOAP = gql`
  ${PRESCRIPTION_FIELDS}
  query AllActiveRxForSoap($soapId: ID!) {
    getAllActiveRxForSoap(soapId: $soapId) {
      ...PrescriptionFields
      retailOrderLineItem {
        id
        modificationDate
      }
    }
  }
`

export const GET_ALL_PENDING_ACTIVE_RX_FOR_CHARGE_SHEET = gql`
  ${PRESCRIPTION_FIELDS}
  query AllPendingActiveRxForChargeSheet($businessId: ID!, $clientId: ID!) {
    getAllPendingActiveRxForChargeSheet(
      businessId: $businessId
      clientId: $clientId
    ) {
      ...PrescriptionFields
      retailOrderLineItem {
        id
        modificationDate
      }
    }
  }
`

export const FETCH_ALL_PENDING_ACTIVE_RX_FOR_SOAP = gql`
  ${PRESCRIPTION_FIELDS}
  query AllPendingActiveRxForSoap($soapId: ID!) {
    allPendingActiveRxForSoap(soapId: $soapId) {
      ...PrescriptionFields
      retailOrderLineItem {
        id
        modificationDate
      }
    }
  }
`
