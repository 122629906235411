/* eslint-disable consistent-return */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import * as R from 'ramda'
import {
  isIPad,
  isWindows,
} from '@pbt/pbt-ui-components/src/utils/browserUtils'

import { DymoLabelType } from '~/constants/dymo'
import {
  HtmlItemTemplate,
  HtmlLabelProps,
  HtmlLabelTemplate,
  PrinterTemplate,
} from '~/types'
import { PrintFormattedData } from '~/types/entities/print'

import PrintIFrame from './PrintIFrame'

const LAYOUT_TYPES = {
  SPREAD: 'SPREAD',
  CENTER: 'CENTER',
  TABLE: 'TABLE',
}

const DEFAULT_FONT_FAMILY = 'sans-serif'
const BORDER_SIZE = 0.01
const WINDOWS_FONT_ADJUST = 0.995
const FONT_ADJUST_STEP = 0.2
const MIN_FONT_SIZE = 1
const MAX_FONT_SIZE = 10

const setHtmlElementStyle = (
  htmlElement: HTMLElement,
  styleProps: Partial<CSSStyleDeclaration>,
) => {
  Object.entries(styleProps).forEach(([prop, value]) => {
    if (typeof value === 'string') {
      htmlElement.style[prop as any] = value
    }
  })
}

const getLabelTemplate = (
  labelTemplate: HtmlLabelTemplate[],
  containerId: string,
) => {
  const elements = labelTemplate
    .map(({ name }) => `<div id="${name}"></div>`)
    .join('')

  return `<div id="${containerId}">${elements}</div>`
}

const INITIAL_FONT_SIZE_SCALE = 0.8
const getFontSize = (lineHeight: number) => lineHeight * INITIAL_FONT_SIZE_SCALE

const getSectionStyles = ({
  sectionTemplate,
  printerTemplate,
  lineHeight,
}: {
  lineHeight: number
  printerTemplate: PrinterTemplate
  sectionTemplate: { [key: string]: any }
}): { [key: string]: any } => {
  const sectionStyles = {} as { [key: string]: any }

  const sectionHeight = lineHeight * sectionTemplate.lines
  const fontSize = getFontSize(lineHeight)

  sectionStyles.height = `${sectionHeight}mm`
  sectionStyles.fontSize = `${fontSize}mm`
  sectionStyles.overflow = 'hidden'
  sectionStyles.padding = `${printerTemplate.linePaddingVertical}mm ${printerTemplate.linePaddingHorizontal}mm`

  if (sectionTemplate.border) {
    sectionStyles.borderBottom = `${BORDER_SIZE}px solid ${printerTemplate.lineColor}`
  }

  if (sectionTemplate.backgroundShade) {
    sectionStyles.backgroundColor = printerTemplate.shadeColor
  }

  if (sectionTemplate.upper) {
    sectionStyles.textTransform = 'uppercase'
  }

  if (sectionTemplate.fontWeight) {
    sectionStyles.fontWeight = sectionTemplate.fontWeight
  }

  sectionStyles.fontFamily = sectionTemplate.fontFamily || DEFAULT_FONT_FAMILY

  return sectionStyles
}

const getContainerStyles = (
  printerTemplate: PrinterTemplate,
): Partial<CSSStyleDeclaration> => {
  const props = {
    paddingLeft:
      printerTemplate.outerPaddingLeft ||
      printerTemplate.outerPaddingHorizontal,
    paddingRight:
      printerTemplate.outerPaddingRight ||
      printerTemplate.outerPaddingHorizontal,
    paddingTop:
      printerTemplate.outerPaddingTop || printerTemplate.outerPaddingVertical,
    paddingBottom:
      printerTemplate.outerPaddingBottom ||
      printerTemplate.outerPaddingVertical,
    marginLeft: printerTemplate.pageMarginLeft,
    marginTop: printerTemplate.pageMarginTop,
  }

  const style: CSSStyleDeclaration = {} as CSSStyleDeclaration

  Object.entries(props).forEach(([key, value]) => {
    if (value) {
      style[key as any] = `${value}mm`
    }
  })

  return style
}

const getSectionsStyles = ({
  printerTemplate,
  labelTemplate,
  clientHeight,
}: {
  clientHeight: number
  labelTemplate: HtmlLabelTemplate[]
  printerTemplate: PrinterTemplate
}) => {
  const paddingTop =
    printerTemplate.outerPaddingTop || printerTemplate.outerPaddingVertical
  const paddingBottom =
    printerTemplate.outerPaddingBottom || printerTemplate.outerPaddingVertical

  // Find the total height in virtual line units
  const linesCount = labelTemplate.reduce(
    (count, { props }) => count + props.lines,
    0,
  )
  const sectionsCount = labelTemplate.length

  // Account for border padding
  const paddingsArea = (paddingTop || 0) + (paddingBottom || 0)

  const linesArea = 2 * printerTemplate.linePaddingVertical * sectionsCount

  const bordersCount = labelTemplate.reduce(
    (count, { props: { border } = {} as HtmlLabelTemplate['props'] }) =>
      count + (border ? 1 : 0),
    0,
  )
  const pixelPerMM = clientHeight / printerTemplate.bodyHeight
  const mmPerPixel = 1 / pixelPerMM

  const bordersArea = bordersCount * BORDER_SIZE * pixelPerMM

  const emptySpaceArea = paddingsArea + linesArea + bordersArea
  const adjustLineHeight = mmPerPixel * 0.5
  const lineHeight =
    (printerTemplate.bodyHeight - emptySpaceArea) / linesCount -
    adjustLineHeight

  return labelTemplate.map(({ name, props }) => {
    const sectionTemplate = props
    const sectionStyles = getSectionStyles({
      sectionTemplate,
      printerTemplate,
      lineHeight,
    })
    const fontSize = getFontSize(lineHeight)
    return { sectionName: name, fontSize, sectionStyles }
  })
}

const LayoutStylesMap = {
  [LAYOUT_TYPES.SPREAD]: 'justify-content: space-between; display: flex;',
  [LAYOUT_TYPES.CENTER]: 'display: block; text-align: center;',
  [LAYOUT_TYPES.TABLE]: 'border: 0; margin: 0; padding: 0; font-size: inherit;',
}

const decorateData = (decorator: string, data: string | number) => {
  if (decorator === 'parentheses') {
    return ` (${data}), `
  }

  return data
}

const getLabelContent = ({
  label,
  labelWeight,
  isTable,
  cellAttrs,
}: {
  cellAttrs?: string
  isTable: boolean
  label: string
  labelWeight?: string
}) => {
  if (!label) {
    return ''
  }

  const labelContent = labelWeight
    ? `<span style="font-weight: ${labelWeight};">${label}: </span>`
    : `${label}: `

  if (!isTable) {
    return labelContent
  }

  return cellAttrs
    ? `<td${cellAttrs}>${labelContent}</td>`
    : `<td>${labelContent}</td>`
}

const generateSectionItem = (
  sectionProps: { [key: string]: any },
  labelData: PrintFormattedData,
  sectionTemplate: { [key: string]: any },
  removeEmptySections?: boolean,
) => {
  const { layout, cellPadding } = sectionTemplate
  const {
    data: propName,
    alternativeData: alternativePropName,
    label,
    labelWeight,
    decorate,
    separator = '',
    rowStart,
    rowEnd,
  } = sectionProps

  const isTable = layout === LAYOUT_TYPES.TABLE

  const boundedData =
    labelData[propName as keyof PrintFormattedData] ||
    labelData[alternativePropName as keyof PrintFormattedData] ||
    ''

  if (!boundedData && removeEmptySections) {
    return
  }

  const cellAttrs =
    isTable && !rowEnd && cellPadding ? ` style="padding: ${cellPadding};"` : ''

  const labelContent = boundedData
    ? getLabelContent({ label, labelWeight, isTable, cellAttrs })
    : ''
  const contentSeparator = boundedData ? separator : ''
  const decoratedData = decorate
    ? decorateData(decorate, boundedData)
    : boundedData

  const prefix = rowStart ? '<tr>' : ''
  const suffix = rowEnd ? '</tr>' : ''

  return isTable
    ? `${prefix}${labelContent}<td${cellAttrs}>${decoratedData}${contentSeparator}</td>${suffix}`
    : `<span>${labelContent}${decoratedData}${contentSeparator}</span>`
}

const addRowStartEnd = (
  item: HtmlItemTemplate,
  index: number,
  arr: HtmlItemTemplate[],
) => {
  const isLast = index === arr.length - 1
  const { row, ...rest } = item

  const rowStart = row
  const rowEnd = isLast || arr[index + 1].row

  return { ...rest, rowStart, rowEnd }
}

const generateSection = (
  sectionTemplate: { [key: string]: any } = {},
  labelData: PrintFormattedData,
  removeEmptySections: boolean,
) => {
  const { bind, layout } = sectionTemplate

  if (!bind?.length) {
    return
  }

  const isTable = layout === LAYOUT_TYPES.TABLE
  const tag = isTable ? 'table' : 'span'

  const children = bind
    .map(isTable ? addRowStartEnd : R.identity)
    .map((sectionProps: { [key: string]: any }) =>
      generateSectionItem(
        sectionProps,
        labelData,
        sectionTemplate,
        removeEmptySections,
      ),
    )
    .filter(Boolean)
    .join('')

  const containerStyles = LayoutStylesMap[layout]
  const containerAttrs = containerStyles ? ` style="${containerStyles}"` : ''

  return `<${tag}${containerAttrs}>${children}</${tag}>`
}

// set up overall transformations
const getTransform = (printerTemplate: PrinterTemplate) => {
  const {
    scaleX = 1,
    scaleY = 1,
    rotate = 0,
    bodyWidth,
    pageOffsetLeft = 0,
    pageOffsetTop = 0,
  } = printerTemplate

  return [
    scaleX !== 1 ? `scaleX(${scaleX})` : '',
    scaleY !== 1 ? `scaleY(${scaleY})` : '',
    rotate !== 0 ? `rotate(${rotate}deg)` : '',
    rotate !== 0
      ? `translate(${-(bodyWidth + pageOffsetLeft)}mm, ${pageOffsetTop}mm)`
      : pageOffsetLeft || pageOffsetTop
        ? `translate(${pageOffsetLeft}mm, ${pageOffsetTop}mm)`
        : '',
  ]
    .filter(Boolean)
    .join(' ')
}

const isContentOverflow = (element: HTMLElement) =>
  element.scrollWidth > element.clientWidth ||
  element.scrollHeight > element.clientHeight

const adjustSectionFont = ({
  fontSize,
  htmlElement,
  fontAdjustFactor,
}: {
  fontAdjustFactor?: number
  fontSize: number
  htmlElement: HTMLElement
}) => {
  let fontSizeIterator = fontSize
  let adjusted = false
  if (isContentOverflow(htmlElement)) {
    // Shrink
    while (
      fontSizeIterator - FONT_ADJUST_STEP >= MIN_FONT_SIZE &&
      isContentOverflow(htmlElement)
    ) {
      fontSizeIterator -= FONT_ADJUST_STEP
      htmlElement.style.fontSize = `${fontSizeIterator}mm`
    }
  } else {
    // Expand
    while (
      fontSizeIterator + FONT_ADJUST_STEP <= MAX_FONT_SIZE &&
      !isContentOverflow(htmlElement)
    ) {
      fontSizeIterator += FONT_ADJUST_STEP
      htmlElement.style.fontSize = `${fontSizeIterator}mm`
      adjusted = true
    }

    if (adjusted && isContentOverflow(htmlElement)) {
      // Back it off
      fontSizeIterator -= FONT_ADJUST_STEP
      htmlElement.style.fontSize = `${fontSizeIterator}mm`
    }
  }

  // We do a further font adjust based on the font adjust parameter if present
  if (fontAdjustFactor) {
    fontSizeIterator *= fontAdjustFactor
    htmlElement.style.fontSize = `${fontSizeIterator}mm`
  }

  // On Windows, the printer renderer is slightly different sometimes
  if (isWindows()) {
    fontSizeIterator *= WINDOWS_FONT_ADJUST
    htmlElement.style.fontSize = `${fontSizeIterator}mm`
  }
}

const generateLabel = (
  labelData: PrintFormattedData,
  htmlLabel: HtmlLabelProps,
  iFrameDocument: Document,
  removeEmptySections: boolean = false,
) => {
  const containerId = 'container'

  const { template: labelTemplate, printerTemplate } = htmlLabel || {}

  let filteredTemplate = labelTemplate
  if (removeEmptySections) {
    filteredTemplate = labelTemplate.filter((section) =>
      section.props.bind.some(
        (dataPoint: { data: keyof PrintFormattedData }) =>
          labelData[dataPoint.data] !== undefined &&
          labelData[dataPoint.data] !== '',
      ),
    )
  }

  // set label sizes
  iFrameDocument.body.style.width = `${printerTemplate.bodyWidth}mm`
  iFrameDocument.body.style.height = `${printerTemplate.bodyHeight}mm`

  // build up the initial HTML
  iFrameDocument.body.innerHTML = getLabelTemplate(
    filteredTemplate,
    containerId,
  )

  // setup the container styles
  const containerStyles = getContainerStyles(printerTemplate)
  setHtmlElementStyle(
    iFrameDocument.getElementById(containerId) || ({} as HTMLElement),
    containerStyles,
  )

  // setup the section styles
  const sectionsStyles = getSectionsStyles({
    printerTemplate,
    labelTemplate: filteredTemplate,
    clientHeight: iFrameDocument.body.clientHeight,
  })
  sectionsStyles.forEach(
    ({
      sectionName,
      sectionStyles,
    }: {
      sectionName: string
      sectionStyles: any
    }) => {
      const htmlElement =
        iFrameDocument.getElementById(sectionName) || ({} as HTMLElement)
      setHtmlElementStyle(htmlElement, sectionStyles)
    },
  )

  // insert the bound values
  filteredTemplate.forEach(({ name, props }) => {
    const sectionHtml = generateSection(props, labelData, removeEmptySections)

    if (sectionHtml) {
      const htmlElement =
        iFrameDocument.getElementById(name) || ({} as HTMLElement)
      htmlElement.innerHTML = sectionHtml
    }
  })

  // adjust all the fonts
  sectionsStyles.forEach(
    (
      { sectionName, fontSize }: { fontSize: number; sectionName: string },
      index: number,
    ) => {
      const htmlElement =
        iFrameDocument.getElementById(sectionName) || ({} as HTMLElement)
      const { fontAdjustFactor } = labelTemplate[index].props || {}
      adjustSectionFont({ fontSize, htmlElement, fontAdjustFactor })
    },
  )

  // Perform additional transforms such as rotation
  iFrameDocument.body.dataset.transform = getTransform(printerTemplate)
}

interface PrintHtmlLabelProps {
  [key: string]: any
  data: PrintFormattedData
  htmlLabel: HtmlLabelProps
  labelType?: DymoLabelType
  removeEmptySections?: boolean
}

const PrintHtmlLabel = forwardRef<unknown, PrintHtmlLabelProps>(
  function PrintHtmlLabel(
    { data, htmlLabel, labelType, removeEmptySections, ...rest },
    ref,
  ) {
    const [iFrame, setIFrame] = useState<HTMLIFrameElement>()

    const renderLabel = () => {
      const contentDocument = iFrame?.contentDocument || ({} as Document)

      const style = contentDocument.createElement('style')
      style.setAttribute('type', 'text/css')

      style.innerText =
        '@media print{@page{margin:0;padding:0;border:0;size:auto}}body,html{margin:0;padding:0;border:0;background:0 0;overflow:hidden}body{margin: 0;transform-origin:0 0;color:#000;font-family:sans-serif}pre{white-space:pre-wrap}#container{overflow:hidden}'

      const head = contentDocument.getElementsByTagName('head')[0]
      head.appendChild(style)

      generateLabel(data, htmlLabel, contentDocument, removeEmptySections)
    }

    useImperativeHandle(ref, () => ({
      print: () => {
        const contentWindow = iFrame?.contentWindow || ({} as Window)
        const contentDocument = iFrame?.contentDocument || ({} as Document)

        if (isIPad()) {
          const newWindow = window.open()
          const width = Number.parseFloat(contentDocument.body.style.width)
          const height = Number.parseFloat(contentDocument.body.style.height)

          newWindow?.document.write(contentDocument.body.innerHTML)
          const script = [
            `document.body.style.width = "${width}mm"`,
            `document.body.style.height = "${height}mm"`,
            `document.body.style.transform = "${contentDocument.body.dataset.transform}"`,
            'const head = document.getElementsByTagName("head")[0]',
            'const style = document.createElement("style")',
            'style.setAttribute("type", "text/css")',
            `style.innerText = "${
              contentDocument.getElementsByTagName('head')[0].children[0]
                .textContent
            }"`,
            'head.appendChild(style)',
          ].join(';')
          newWindow?.document.write(`<script>${script}</script>`)
          newWindow?.document.close()

          newWindow?.focus()
          if (newWindow) {
            newWindow.onafterprint = () => newWindow.close()
          }
          newWindow?.print()
        } else {
          contentWindow.focus()
          contentDocument.body.style.transform =
            contentDocument.body.dataset.transform || ''
          contentWindow.print()

          contentDocument.body.style.transform = ''
        }
      },
      clear: () => {
        if (iFrame) {
          const contentDocument = iFrame?.contentDocument || ({} as Document)
          contentDocument.body.innerHTML = ''
          contentDocument.head.innerHTML = ''
        }
      },
    }))

    const handleFrameRef = useCallback((node: HTMLIFrameElement) => {
      setIFrame(node)
    }, [])

    useEffect(() => {
      if (iFrame && htmlLabel?.printerTemplate) {
        renderLabel()
      }
    }, [iFrame, data, htmlLabel?.printerTemplate])

    const { bodyWidth = 100, bodyHeight = 60 } =
      htmlLabel?.printerTemplate || {}

    return (
      <PrintIFrame
        visible
        ref={handleFrameRef}
        style={{
          width: `${bodyWidth}mm`,
          height: `${bodyHeight}mm`,
          border: '1px solid grey',
        }}
        {...rest}
      />
    )
  },
)

export default PrintHtmlLabel
