import i18n from '~/locales/i18n'
import { PageMeta } from '~/types'

export enum PageMetaRoute {
  ACTIVATION = '/auth/activation',
  LOGIN = '/auth/login',
  LOCK = '/auth/lock',
  PASSWORD_RESET = '/auth/password-reset',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  ROOT = '/',
}

export const PageMetaMap: Partial<Record<PageMetaRoute, PageMeta>> = {
  [PageMetaRoute.LOGIN]: {
    title: i18n.t('PageTitles:LOGIN_TITLE'),
    description: i18n.t('PageTitles:LOGIN_META_DESCRIPTION'),
  },
  [PageMetaRoute.LOCK]: {
    title: i18n.t('PageTitles:LOGIN_TITLE'),
    description: i18n.t('PageTitles:LOGIN_META_DESCRIPTION'),
  },
  [PageMetaRoute.PASSWORD_RESET]: {
    title: i18n.t('PageTitles:PASSWORD_RESET_TITLE'),
    description: i18n.t('PageTitles:PASSWORD_RESET_META_DESCRIPTION'),
  },
  [PageMetaRoute.TERMS_AND_CONDITIONS]: {
    title: i18n.t('PageTitles:TERMS_AND_CONDITIONS_TITLE'),
    description: i18n.t('PageTitles:TERMS_AND_CONDITIONS_META_DESCRIPTION'),
  },
  [PageMetaRoute.ROOT]: {
    title: i18n.t('PageTitles:DEFAULT_TITLE'),
    description: i18n.t('PageTitles:DEFAULT_META_DESCRIPTION'),
  },
}

export const AahaPageMetaMap: Partial<Record<PageMetaRoute, PageMeta>> = {
  [PageMetaRoute.ACTIVATION]: {
    title: i18n.t('PageTitles:AAHA_ACTIVATION_TITLE'),
    description: i18n.t('PageTitles:DEFAULT_META_DESCRIPTION'),
  },
}

export const BenchmarkingPageMetaMap: Partial<Record<PageMetaRoute, PageMeta>> =
  {
    [PageMetaRoute.ACTIVATION]: {
      title: i18n.t('PageTitles:BENCHMARKING_ACTIVATION_TITLE'),
      description: '', // Specifically requested from product team to leave empty
    },
  }
