import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'ramda'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  Constant,
  CustomFieldValidatorState,
  Field,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { AutomaticCommunicationType } from '~/components/common/automaticCommunication/AutomaticCommunicationPreviewDialog'
import PreviewButton from '~/components/common/buttons/PreviewButton'
import PuiSwitch from '~/components/common/PuiSwitch'
import TimeUnitsSelect from '~/components/common/TimeUnitsSelect'
import DialogNames from '~/constants/DialogNames'
import {
  CustomDatePrepositions,
  CustomDatePrepositionsList,
} from '~/constants/taskConstants'
import { getBusiness } from '~/store/reducers/businesses'
import {
  getCommunicationTransportBackupOption,
  getReminderTemplateTimeUnits,
} from '~/store/reducers/constants'
import {
  CommunicationTransportBackupOptionName,
  ReminderTemplate,
} from '~/types'
import { useBoopEnabled } from '~/utils/boop'
import { getCommunicationsMessageMaxLength } from '~/utils/communicationsUtils'
import useDialog from '~/utils/useDialog'

import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 780,
      maxWidth: 780,
    },
    expiresInput: {
      width: 48,
    },
    select: {
      '&&': {
        paddingRight: theme.spacing(1),
      },
    },
    ageUnitsSelect: {
      width: 72,
    },
    beforeAfterSelect: {
      width: 84,
    },
    addButton: {
      width: 150,
      marginRight: theme.spacing(1.5),
    },
    subjectWrapper: {
      height: 115,
    },
  }),
  { name: 'ReminderCommunicationDialog' },
)

interface ReminderCommunicationDialogProps {
  businessId: string
  onClose?: () => void
  onSave: (newTemplate: ReminderTemplate) => void
  open: boolean
  template?: Partial<ReminderTemplate>
}

const ReminderCommunicationDialog = ({
  open,
  businessId,
  template: templateProp,
  onClose,
  onSave,
}: ReminderCommunicationDialogProps) => {
  const template = templateProp || {}
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses', 'Time', 'Validations'])

  const isEdit = !isEmpty(template)

  const business = useSelector(getBusiness(businessId))
  const ReminderTemplateTimeUnits = useSelector(getReminderTemplateTimeUnits)
  const CommunicationTransportBackupOption: Constant[] = useSelector(
    getCommunicationTransportBackupOption,
  )
  const PreferredContactMethodId: string = Utils.findConstantIdByName(
    CommunicationTransportBackupOptionName.PREFERRED_CONTACT_METHOD,
    CommunicationTransportBackupOption,
  )
  const BoopMessagedId: string = Utils.findConstantIdByName(
    CommunicationTransportBackupOptionName.BOOP_MESSAGE,
    CommunicationTransportBackupOption,
  )

  const yearId = Utils.findConstantIdByName('Year', ReminderTemplateTimeUnits)

  const [openPreviewDialog] = useDialog(
    DialogNames.AUTOMATIC_COMMUNICATION_PREVIEW,
  )

  const MESSAGE_MAX_LENGTH = getCommunicationsMessageMaxLength(
    ConversationTransport.Sms,
    true,
  )
  const textMessageValidator = ({
    state: { textMessage },
  }: CustomFieldValidatorState) => textMessage.length <= MESSAGE_MAX_LENGTH

  const boopFlowEnabled = useBoopEnabled(business)

  const {
    fields: {
      active,
      amount,
      unit,
      beforeOrAfter,
      communicationMethodId,
      communicationMethodPrimaryBackupId,
      communicationMethodSecondaryBackupId,
      communicationMethodTertiaryBackupId,
      subject,
      emailMessage,
      textMessage,
    },
    validate,
  } = useFields(
    [
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: template?.active !== false,
      },
      {
        name: 'amount',
        initialValue: Math.abs(template?.dueDateOffset?.amount ?? 1),
      },
      { name: 'unit', initialValue: template?.dueDateOffset?.unit || yearId },
      {
        name: 'beforeOrAfter',
        initialValue:
          Number(template?.dueDateOffset?.amount) >= 0
            ? CustomDatePrepositions.AFTER
            : CustomDatePrepositions.BEFORE,
      },
      {
        name: 'communicationMethodId',
        label: t('Common:DEFAULT'),
        initialValue:
          template?.communicationMethodIds?.[0] || PreferredContactMethodId,
      },
      {
        name: 'communicationMethodPrimaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.PRIMARY_BACK_UP'),
        initialValue: template?.communicationMethodIds?.[1] || '',
      },
      {
        name: 'communicationMethodSecondaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.SECONDARY_BACK_UP'),
        initialValue: template?.communicationMethodIds?.[2] || '',
      },
      {
        name: 'communicationMethodTertiaryBackupId',
        label: t('Businesses:APPOINTMENT_COMMUNICATIONS.TERTIARY_BACK_UP'),
        initialValue: template?.communicationMethodIds?.[3] || '',
      },
      {
        name: 'subject',
        label: t('Common:SUBJECT_WILL_NOT_DISPLAY'),
        validators: ['required'],
        initialValue: template?.subject || '',
      },
      {
        name: 'emailMessage',
        label: t('Common:MESSAGE'),
        validators: ['required'],
        initialValue: template?.message || template?.template || '',
      },
      {
        name: 'textMessage',
        label: t('Common:TEXT_MESSAGE'),
        validators: [
          'required',
          {
            validator: textMessageValidator,
            validatorName: 'textMessageValidator',
          },
        ],
        messages: {
          textMessageValidator: t(
            'Validations:TEXT_MESSAGE_LENGTH_SHOULD_BE_LESS',
            { maxLength: MESSAGE_MAX_LENGTH },
          ),
        },
        initialValue: template?.textMessage || '',
      },
    ],
    false,
  )

  const handleAdd = () => {
    const amountToDispatch =
      beforeOrAfter.value === CustomDatePrepositions.AFTER
        ? amount.value
        : -amount.value
    const newCommunicationV2 = {
      active: active.value,
      communicationMethodIds: [
        communicationMethodId.value,
        communicationMethodPrimaryBackupId.value,
        communicationMethodSecondaryBackupId.value,
        communicationMethodTertiaryBackupId.value,
      ].filter(Boolean),
      dueDateOffset: { amount: amountToDispatch, unit: unit.value || yearId },
      id: template?.id,
      message: emailMessage.value,
      subject: subject.value,
      textMessage: textMessage.value,
    } as ReminderTemplate
    if (validate()) {
      onSave(newCommunicationV2)
      if (onClose) {
        onClose()
      }
    }
  }

  const backupFields = [
    communicationMethodId,
    communicationMethodPrimaryBackupId,
    communicationMethodSecondaryBackupId,
    communicationMethodTertiaryBackupId,
  ]

  const createBackupContactMethodList = (backupField: Field) => {
    const valuesToExclude = R.pipe(
      R.without([backupField]),
      R.pluck('value'),
    )(backupFields)

    return CommunicationTransportBackupOption.filter(
      (item) => boopFlowEnabled || item.id !== BoopMessagedId,
    ).map((item) => ({
      ...item,
      disabled: R.includes(item.id, valuesToExclude),
    }))
  }

  const handlePreviewSample = () => {
    if (business) {
      openPreviewDialog({
        business,
        automaticCommunication: {
          subject: subject.value,
          message: emailMessage.value,
          textMessage: textMessage.value,
        },
        type: AutomaticCommunicationType.REMINDER,
      })
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <>
          <ButtonWithLoader className={classes.addButton} onClick={handleAdd}>
            {isEdit ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
          </ButtonWithLoader>
          <PreviewButton onClick={handlePreviewSample} />
        </>
      }
      aria-labelledby="reminder-communication-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      scroll="paper"
      title={
        isEdit
          ? t('Businesses:REMINDER_SET_UP.EDIT_REMINDER_COMMUNICATION')
          : t('Businesses:REMINDER_SET_UP.ADD_REMINDER_COMMUNICATION')
      }
      onClose={onClose}
    >
      <Grid container direction="column" pb={3} pt={2} px={3}>
        <Grid item mb={2}>
          <PuiSwitch field={active} label={active.label} />
        </Grid>
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item>
            <Text variant="body">{t('Common:COMMUNICATE')}</Text>
          </Grid>
          <Grid item>
            <PuiTextField className={classes.expiresInput} field={amount} />
          </Grid>
          <Grid item>
            <TimeUnitsSelect
              amount={amount.value}
              className={classes.ageUnitsSelect}
              classes={{ select: classes.select }}
              field={unit}
              items={ReminderTemplateTimeUnits}
              renderEmpty={false}
            />
          </Grid>
          <Grid item>
            <PuiSelect
              field={beforeOrAfter}
              input={<Input id="communication-before-or-after" />}
              items={CustomDatePrepositionsList}
              renderEmpty={false}
            />
          </Grid>
          <Grid item>
            <Text variant="body">{t('Time:LABEL.DUE_DATE').toLowerCase()}</Text>
          </Grid>
        </Grid>
        <>
          <Grid container item columnSpacing={3} wrap="nowrap">
            <Grid item xs>
              <FormControl fullWidth margin="none">
                <InputLabel htmlFor="default-select">
                  {communicationMethodId.label}
                </InputLabel>
                <PuiSelect
                  field={communicationMethodId}
                  input={<Input id="default-select" />}
                  items={createBackupContactMethodList(communicationMethodId)}
                  renderEmpty={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth margin="none">
                <InputLabel shrink htmlFor="primary-backup-select">
                  {communicationMethodPrimaryBackupId.label}
                </InputLabel>
                <PuiSelect
                  field={communicationMethodPrimaryBackupId}
                  input={<Input id="primary-backup-select" />}
                  items={createBackupContactMethodList(
                    communicationMethodPrimaryBackupId,
                  )}
                  placeholder={t('Common:NONE')}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth margin="none">
                <InputLabel shrink htmlFor="secondary-backup-select">
                  {communicationMethodSecondaryBackupId.label}
                </InputLabel>
                <PuiSelect
                  disabled={!communicationMethodPrimaryBackupId.value}
                  field={communicationMethodSecondaryBackupId}
                  input={<Input id="secondary-backup-select" />}
                  items={createBackupContactMethodList(
                    communicationMethodSecondaryBackupId,
                  )}
                  placeholder={t('Common:NONE')}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth margin="none">
                <InputLabel shrink htmlFor="tertiary-backup-select">
                  {communicationMethodTertiaryBackupId.label}
                </InputLabel>
                <PuiSelect
                  disabled={
                    !communicationMethodPrimaryBackupId.value ||
                    !communicationMethodSecondaryBackupId.value
                  }
                  field={communicationMethodTertiaryBackupId}
                  input={<Input id="tertiary-backup-select" />}
                  items={createBackupContactMethodList(
                    communicationMethodTertiaryBackupId,
                  )}
                  placeholder={t('Common:NONE')}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item mt={1}>
            <MessageWithPreviewConfiguration
              className={classes.subjectWrapper}
              field={subject}
              showButtons={false}
              showPreview={false}
              tooltipTitle={subject.label}
            />
          </Grid>
          <Grid item mt={1}>
            <MessageWithPreviewConfiguration
              field={emailMessage}
              hidePlusButtonBlock={false}
              showPreview={false}
              tooltipTitle={emailMessage.label}
            />
          </Grid>
          <Grid item mt={1}>
            <MessageWithPreviewConfiguration
              field={textMessage}
              hidePlusButtonBlock={false}
              maxLength={MESSAGE_MAX_LENGTH}
              showButtons={false}
              showPreview={false}
              tooltipTitle={textMessage.label}
            />
          </Grid>
        </>
      </Grid>
    </PuiDialog>
  )
}

export default ReminderCommunicationDialog
