import {
  MutationUpdateChargeSheetItemBatchArgs,
  MutationUpdateChargeSheetLineItemProducerBatchArgs,
  MutationUpdateChargeSheetRetailOrderLineItemProducerBatchArgs,
  MutationUpdateSectionsAdditionalDiscountArgs,
  QueryPendingActiveRxForOutstandingFinalizationArgs,
  QueryPendingIdexxImagingForOutstandingFinalizationArgs,
  QueryPendingLabTestsForOutstandingFinalizationArgs,
  QueryPendingsForOutstandingFinalizationWhenPostingInvoiceArgs,
} from '~/api/graphql/generated/types'

import { getAPIPath, request } from './core'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  ADD_CHARGE_SHEET_ITEMS,
  DELETE_CHARGE_SHEET_ITEM,
  EDIT_CHARGE_SHEET_ITEM_BATCH,
  EDIT_CHARGE_SHEET_ITEM_PRODUCER_BATCH,
  EDIT_CHARGE_SHEET_RETAIL_ORDER_LINE_ITEM_PRODUCER_BATCH,
  GENERATE_HTML_CHARGE_SHEET,
  UPDATE_SECTION_ADDITIONAL_DISCOUNT,
} from './graphql/mutations/chargeSheet'
import {
  FETCH_CHARGE_SHEET_LINE_ITEM,
  FETCH_CHARGE_SHEET_RETAIL_ORDER_LINE_ITEM,
  FETCH_CLIENT_CHARGE_SHEET_BALANCE,
  FETCH_CLIENT_CHARGE_SHEET_ITEMS_COUNT,
  FETCH_LINE_ITEM_BY_LOG_ID,
  FETCH_LINE_ITEM_BY_LOG_ID_EXTENDED,
  FETCH_OPEN_CHARGE_SHEET,
  FETCH_ORDERS_FILTERS,
  getClientChargesQuery,
  getClientSoapChargesQuery,
  PENDING_ACTIVE_RX_FOR_OUTSTANDING_FINALIZATION,
  PENDING_IDEXX_IMAGING_FOR_OUTSTANDING_FINALIZATION,
  PENDING_LAB_TESTS_FOR_OUTSTANDING_FINALIZATION,
  PENDINGS_FOR_OUTSTANDING_FINALIZATION_WHEN_POSTING_INVOICE,
} from './graphql/queries/chargeSheet'
import * as Schema from './schemas'

export const fetchClientFinanceData = (isIpoM1CheckoutEnabled: boolean) =>
  requestQuery({
    query: getClientChargesQuery(isIpoM1CheckoutEnabled),
    variablesHandler: (businessId, clientId, includeDeleted) => ({
      businessId,
      id: clientId,
      includeDeleted,
    }),
    schema: Schema.clientWithChargeSheet,
    expectedErrors: ['LINE_ITEM_WITH_MISSED_LOG_TYPE'],
  })

export const fetchClientSoapFinanceData = (isIpoM1CheckoutEnabled: boolean) =>
  requestQuery({
    query: getClientSoapChargesQuery(isIpoM1CheckoutEnabled),
    variablesHandler: (businessId, clientId, soapId, includeDeleted) => ({
      businessId,
      id: clientId,
      includeDeleted,
      soapId,
    }),
    schema: Schema.clientWithChargeSheet,
    expectedErrors: ['LINE_ITEM_WITH_MISSED_LOG_TYPE'],
  })

export const fetchClientChargeSheetItemsCount = requestQuery({
  query: FETCH_CLIENT_CHARGE_SHEET_ITEMS_COUNT,
  variablesHandler: (businessId, clientId, includeDeleted) => ({
    businessId,
    id: clientId,
    includeDeleted,
  }),
})

export const printChargeSheet = requestMutation({
  mutation: GENERATE_HTML_CHARGE_SHEET,
  variablesHandler: (businessId, clientId, expandedGroups) => ({
    businessId,
    clientId,
    expandedGroups,
  }),
})

export const addChargeSheetItems = requestMutation({
  mutation: ADD_CHARGE_SHEET_ITEMS,
  variablesHandler: (businessId, clientId, createItemInputs) => ({
    businessId,
    clientId,
    createItemInputs,
  }),
})

export const fetchChargeSheetBalance = requestQuery({
  query: FETCH_CLIENT_CHARGE_SHEET_BALANCE,
  variablesHandler: (businessId, clientId, includeDeleted) => ({
    businessId,
    id: clientId,
    includeDeleted,
  }),
})

export const fetchOpenChargeSheet = requestQuery({
  query: FETCH_OPEN_CHARGE_SHEET,
  variablesHandler: (businessId, clientId) => ({
    businessId,
    clientId,
  }),
})

export const editChargeSheetLineItemProducerBatch = requestMutation({
  mutation: EDIT_CHARGE_SHEET_ITEM_PRODUCER_BATCH,
  variablesHandler: (
    businessId: string,
    { input }: MutationUpdateChargeSheetLineItemProducerBatchArgs,
  ) => ({
    businessId,
    input,
  }),
})

export const editChargeSheetRetailOrderLineItemProducerBatch = requestMutation({
  mutation: EDIT_CHARGE_SHEET_RETAIL_ORDER_LINE_ITEM_PRODUCER_BATCH,
  variablesHandler: (
    businessId: string,
    { input }: MutationUpdateChargeSheetRetailOrderLineItemProducerBatchArgs,
  ) => ({
    businessId,
    input,
  }),
})

export const deleteChargeSheetItems = requestMutation({
  mutation: DELETE_CHARGE_SHEET_ITEM,
  variablesHandler: (businessId, modifierId, deleteItemInputs) => ({
    businessId,
    deleteItemInputs,
    modifierId,
  }),
})

export const fetchChargeSheetOrdersFilters = requestQuery({
  query: FETCH_ORDERS_FILTERS,
  variablesHandler: (businessId, clientId, patientId) => ({
    businessId,
    clientId,
    patientId,
  }),
})

export const fetchChargeSheetLineItemById = requestQuery({
  query: FETCH_CHARGE_SHEET_LINE_ITEM,
  variablesHandler: (businessId, id, includeDeleted) => ({
    businessId,
    id,
    includeDeleted,
  }),
  expectedErrors: ['LINE_ITEM_WITH_MISSED_LOG_TYPE'],
})

export const fetchChargeSheetRetailOrderLineItemById = requestQuery({
  query: FETCH_CHARGE_SHEET_RETAIL_ORDER_LINE_ITEM,
  variablesHandler: (_, id) => ({
    id,
  }),
})

export const editChargeSheetOrder = (
  businessId: string,
  id: string,
  type: string,
  order: any,
  soapLogModificationDate: string,
  allowEditPostedItem: boolean,
) =>
  request(
    getAPIPath(`orders/item/${type}/${id}`),
    {
      method: 'PATCH',
      params: {
        businessId,
        expectedOrderItemModification: soapLogModificationDate,
        allowEditPostedItem,
      },
      data: order,
    },
    true,
  )

export const updateSectionsAdditionalDiscount = requestMutation({
  mutation: UPDATE_SECTION_ADDITIONAL_DISCOUNT,
  variablesHandler: (
    businessId,
    {
      modifierId,
      updateSectionsDiscountInputs,
    }: MutationUpdateSectionsAdditionalDiscountArgs,
  ) => ({
    businessId,
    modifierId,
    updateSectionsDiscountInputs,
  }),
})

export const editChargeSheetItemBatch = requestMutation({
  mutation: EDIT_CHARGE_SHEET_ITEM_BATCH,
  variablesHandler: (
    businessId,
    { updateItemInput }: MutationUpdateChargeSheetItemBatchArgs,
  ) => ({
    businessId,
    updateItemInput,
  }),
})

export const checkPendingOutstandingOrders = requestQuery({
  query: PENDINGS_FOR_OUTSTANDING_FINALIZATION_WHEN_POSTING_INVOICE,
  variablesHandler: (
    _,
    {
      clientId,
      sectionIds,
    }: QueryPendingsForOutstandingFinalizationWhenPostingInvoiceArgs,
  ) => ({
    clientId,
    sectionIds,
  }),
})

export const checkPendingLabTests = requestQuery({
  query: PENDING_LAB_TESTS_FOR_OUTSTANDING_FINALIZATION,
  variablesHandler: (
    _,
    { clientId, invoiceId }: QueryPendingLabTestsForOutstandingFinalizationArgs,
  ) => ({
    clientId,
    invoiceId,
  }),
})

export const checkPendingImaging = requestQuery({
  query: PENDING_IDEXX_IMAGING_FOR_OUTSTANDING_FINALIZATION,
  variablesHandler: (
    _,
    {
      clientId,
      invoiceId,
    }: QueryPendingIdexxImagingForOutstandingFinalizationArgs,
  ) => ({
    clientId,
    invoiceId,
  }),
})

export const checkPendingActiveRx = requestQuery({
  query: PENDING_ACTIVE_RX_FOR_OUTSTANDING_FINALIZATION,
  variablesHandler: (
    _,
    { clientId }: QueryPendingActiveRxForOutstandingFinalizationArgs,
  ) => ({
    clientId,
  }),
})

export const fetchLineItemByLogId = requestQuery({
  query: FETCH_LINE_ITEM_BY_LOG_ID,
  variablesHandler: (businessId, logId, logType) => ({
    businessId,
    logId,
    logType,
  }),
})

export const fetchLineItemByLogIdExtended = requestQuery({
  query: FETCH_LINE_ITEM_BY_LOG_ID_EXTENDED,
  variablesHandler: (businessId, logId, logType) => ({
    businessId,
    logId,
    logType,
  }),
})
