import { gql } from '@apollo/client/core'

export const FETCH_CLIENT_PREFERENCES = gql`
  query FetchClientPreferences($clientId: UID!) {
    client(id: $clientId) {
      id
      preference {
        genderIdentity {
          id
        }
        attitudeTowardsStaff {
          id
        }
        topicsToAvoid
        paymentMethod {
          id
        }
        financialComfort {
          id
        }
        concernForNonpayment
        extentOfCare {
          id
        }
        servicePreference {
          id
        }
        locationOfPerformedTreatment {
          id
        }
        timeOfDay {
          id
        }
        clientChronicallyLate
        clientChronicallyNoShow
        needsExtendedAppointmentTime {
          id
        }
        cadenceOfVisit {
          id
        }
        dayOfWeek {
          id
        }
        doctors {
          id
          firstName
          lastName
        }
        spaces {
          id
          name
        }
        financialComment
        careComment
        schedulingComment
      }
    }
  }
`
