import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { isNil } from 'ramda'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import {
  ShippingAddress,
  useClientChewyCheckoutInfoLazyQuery,
  useUpdateRetailOrderMutation,
} from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { AddPaymentDialogProps } from '~/components/dashboard/invoices/payment/AddPaymentDialog'
import { useMoveItemsToChewyCart } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/useMoveItemsToChewyCart'
import { useMoveToCartDialog } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/useMoveToCartDialog'
import { useSubmitChewyOrder } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/useSubmitChewyOrder'
import { PaymentRequestDialogProps } from '~/components/dashboard/invoices/payment/PaymentRequestDialog'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getChewyFreeShippingEnabled } from '~/store/reducers/constants'
import { fetchInvoiceV3, getInvoiceV3Loading } from '~/store/reducers/invoiceV3'
import { getUser } from '~/store/reducers/users'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import useDialog from '~/utils/useDialog'

import { ChewyPaymentDialogProps } from './elements/ChewyPaymentDialog/ChewyPaymentDialog'

type UseChewyPaymentProps = {
  invoice: InvoiceV3 | Nil
}

const useStyles = makeStyles(
  () => ({
    message: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'UsePaymentController' },
)

export const usePaymentController = ({ invoice }: UseChewyPaymentProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const { clientId } = invoice || {}

  const isInvoiceLoading = useSelector(getInvoiceV3Loading)
  const client = useSelector(getUser(clientId))
  const isChewyFreeShippingEnabled = useSelector(getChewyFreeShippingEnabled)

  const [openPaymentRequestDialog] = useDialog(DialogNames.PAYMENT_REQUEST)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.CHEWY_FREE_SHIPPING,
    preventShowAgainCheckBox: false,
  })

  const { openMoveToCartDialog } = useMoveToCartDialog(invoice)

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const total = invoice?.retailOrder?.totalPrice || 0
  const hasFirstStep =
    isChewyCheckoutEnabled &&
    total > 0 &&
    !isNil(clientId) &&
    !invoice?.retailOrder?.orderConfirmationId

  const [
    fetchClientChewyCheckoutInfo,
    {
      data,
      loading,
      error: loadingError,
      refetch: refetchClientChewyCheckoutInfo,
    },
  ] = useClientChewyCheckoutInfoLazyQuery({
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })

  const {
    placeOrderLoading,
    placeOrderError,
    submitChewyOrder,
    resetPlaceOrder,
  } = useSubmitChewyOrder(invoice, refetchClientChewyCheckoutInfo)

  const [
    updateRetailOrder,
    {
      loading: updateRetailOrderLoading,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: updateRetailOrderError,
      reset: updateRetailOrderReset,
    },
  ] = useUpdateRetailOrderMutation()

  const { moveToCartLoading, moveToCartError, moveToCart, resetMoveToCart } =
    useMoveItemsToChewyCart(invoice)

  const clientName = Utils.getPersonString(client)
  const paymentMethods = data?.client.paymentMethods || []
  const shippingAddresses = data?.client.shippingAddresses || []

  const handleAddressChange = useCallback(
    (shippingAddress: ShippingAddress) => {
      if (invoice?.retailOrder) {
        updateRetailOrder({
          variables: {
            retailOrderId: invoice.retailOrder.id,
            shippingAddressId: shippingAddress.id,
            expectedModificationDate: invoice.retailOrder.modificationDate,
          },
          onCompleted: () => {
            if (invoice?.id) {
              dispatch(fetchInvoiceV3({ id: invoice.id }))
            }
          },
        })
      }
    },
    [
      invoice?.retailOrder?.id,
      invoice?.retailOrder?.modificationDate,
      invoice?.id,
    ],
  )

  const baseChewyPaymentDialogProps: Omit<
    ChewyPaymentDialogProps,
    'open' | 'isPaymentRequest' | 'onMoveToCart' | 'onPlaceOrder'
  > = {
    clientName,
    placeOrderError,
    moveToCartError,
    placeOrderLoading,
    moveToCartLoading,
    loadingError,
    paymentMethods,
    shippingAddresses,
    total,
    loading,
    onAddressChange: handleAddressChange,
    recalculatingPrice: updateRetailOrderLoading || isInvoiceLoading,
    defaultAddressId: invoice?.retailOrder?.shippingAddress?.id,
  }

  const resetMutations = () => {
    resetMoveToCart()
    resetPlaceOrder()
    updateRetailOrderReset()
  }

  const [openChewyPaymentDialog, closeChewyPaymentDialog] = useDialog(
    DialogNames.CHEWY_PAYMENT_DIALOG,
    () => {
      resetMutations()
    },
    baseChewyPaymentDialogProps,
    true,
  )

  const onClose = () => {
    closeChewyPaymentDialog()
    resetMutations()
  }

  const openFreeShippingAlert = (onProceed: (proceed: boolean) => void) => {
    openConfirmAlert({
      onConfirm: onProceed,
      applyCustomMessage: true,
      okButtonText: t('Dialogs:CHEWY_FREE_SHIPPING.PROCEED'),
      message: t('Dialogs:CHEWY_FREE_SHIPPING.MESSAGE'),
      cancelButtonText: t('Dialogs:CHEWY_FREE_SHIPPING.CANCEL'),
      classes: {
        message: classes.message,
      },
    })
  }

  return {
    paymentRequest: ({
      paymentRequestDialogProps,
    }: {
      paymentRequestDialogProps: Omit<PaymentRequestDialogProps, 'open'>
    }) => {
      const secondStep = () => {
        onClose()
        openPaymentRequestDialog({
          ...paymentRequestDialogProps,
          showStepper: hasFirstStep,
        })
      }
      if (!hasFirstStep) {
        secondStep()
        return
      }
      fetchClientChewyCheckoutInfo({ variables: { id: clientId } })

      const chewyPaymentDialogProps: Partial<
        Omit<ChewyPaymentDialogProps, 'open'>
      > = {
        isPaymentRequest: true,
        onPlaceOrder: (variables) => {
          submitChewyOrder({ variables, onCompleted: secondStep })
        },
        onMoveToCart: () => {
          moveToCart(secondStep)
        },
      }

      openMoveToCartDialog({
        onMoved: secondStep,
        onCancel: () => {
          openChewyPaymentDialog(chewyPaymentDialogProps)
        },
        onClose: () => {
          openChewyPaymentDialog(chewyPaymentDialogProps)
        },
      })
    },
    addPayment: ({
      addPaymentDialogProps,
    }: {
      addPaymentDialogProps: Omit<AddPaymentDialogProps, 'open'>
    }) => {
      const secondStep = () => {
        onClose()
        openAddPaymentDialog({
          ...addPaymentDialogProps,
          showStepper: hasFirstStep,
        })
      }
      if (!hasFirstStep) {
        secondStep()
        return
      }
      fetchClientChewyCheckoutInfo({ variables: { id: clientId } })

      const chewyPaymentDialogProps: Partial<
        Omit<ChewyPaymentDialogProps, 'open'>
      > = {
        isPaymentRequest: false,
        onPlaceOrder: (variables) => {
          submitChewyOrder({ variables, onCompleted: secondStep })
        },
        onMoveToCart: () => {
          if (isChewyFreeShippingEnabled) {
            openFreeShippingAlert((proceed) => {
              if (proceed) {
                moveToCart(secondStep)
              }
            })
          } else {
            moveToCart(secondStep)
          }
        },
      }

      openChewyPaymentDialog(chewyPaymentDialogProps)
    },
    chewyPaymentDialogOnly: () => {
      if (!clientId) {
        return
      }

      function fetchCheckoutInfoAndOpenChewyPaymentDialog() {
        fetchClientChewyCheckoutInfo({ variables: { id: clientId } })
        openChewyPaymentDialog({
          hideStepper: true,
          isPaymentRequest: false,
          onPlaceOrder: (variables) => {
            submitChewyOrder({ variables, onCompleted: onClose })
          },
          onMoveToCart: () => {
            if (isChewyFreeShippingEnabled) {
              openFreeShippingAlert((proceed) => {
                if (proceed) {
                  moveToCart(onClose)
                }
              })
            } else {
              moveToCart(onClose)
            }
          },
        })
      }

      fetchCheckoutInfoAndOpenChewyPaymentDialog()
    },
  }
}
