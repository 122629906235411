import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { DateUtils, LanguageUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { getInventoryDistributors } from '~/store/reducers/constants'
import { InventoryOrder } from '~/types'

interface OrderItemsPageHeaderLabelsProps {
  inventoryOrder: InventoryOrder
}

export const OrderItemsPageHeaderLabels = ({
  inventoryOrder: {
    orderedDate,
    distributorId,
    distributorName,
    orderedByName,
  },
}: OrderItemsPageHeaderLabelsProps) => {
  const InventoryDistributors = useSelector(getInventoryDistributors)
  const distributor = Utils.getConstantName(
    distributorId,
    InventoryDistributors,
  )
  const distributorDisplayName = LanguageUtils.getConstantTranslatedName(
    distributorId,
    InventoryDistributors,
  )

  const orderItemHeaderLabels = [
    {
      i18nKey: 'Admin:CATALOG.ORDERS.ORDERED_DATE_LABEL',
      templateKey: 'date',
      value: DateUtils.formatDate(orderedDate),
    },
    {
      i18nKey: 'Admin:CATALOG.ORDERS.SUPPLIER_LABEL',
      templateKey: 'supplier',
      value: distributor === 'Other' ? distributorName : distributorDisplayName,
    },
    {
      i18nKey: 'Admin:CATALOG.ORDERS.ORDERED_BY_LABEL',
      templateKey: 'name',
      value: orderedByName,
    },
  ]

  return (
    <Box alignItems="center" display="flex" gap={2}>
      {orderItemHeaderLabels.map(({ i18nKey, templateKey, value }) => (
        <Text key={templateKey} variant="body2">
          <Trans
            components={{
              bold: <Text strong component="span" variant="body2" />,
            }}
            /**
             * Casting to 'as string' suppresses warnings from the extract-translations script,
             * which expects a string literal for i18nKey. This isn't a definitive fix but rather
             * it causes the parser to ignore the issue.
             */
            i18nKey={i18nKey as string}
            values={{ [templateKey]: value }}
          />
        </Text>
      ))}
    </Box>
  )
}
