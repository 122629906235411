import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { RhapsodyPayConfig } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const UPDATE_RHAPSODY_PAY_CONFIGS =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIGS'

export const GET_RHAPSODY_PAA_APP_URL = 'rhapsodyPay/GET_RHAPSODY_PAA_APP_URL'
export const GET_RHAPSODY_PAA_APP_URL_SUCCESS =
  'rhapsodyPay/GET_RHAPSODY_PAA_APP_URL_SUCCESS'
export const GET_RHAPSODY_PAA_APP_URL_FAILURE =
  'rhapsodyPay/GET_RHAPSODY_PAA_APP_URL_FAILURE'

export const UPDATE_RHAPSODY_PAY_CONFIG =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG'
export const UPDATE_RHAPSODY_PAY_CONFIG_SUCCESS =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG_SUCCESS'
export const UPDATE_RHAPSODY_PAY_CONFIG_FAILURE =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG_FAILURE'

export const UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY'
export const UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_SUCCESS =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_SUCCESS'
export const UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_FAILURE =
  'rhapsodyPay/UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_FAILURE'

export const FETCH_RHAPSODY_PAY_CONFIG = 'rhapsodyPay/FETCH_RHAPSODY_PAY_CONFIG'
export const FETCH_RHAPSODY_PAY_CONFIG_SUCCESS =
  'rhapsodyPay/FETCH_RHAPSODY_PAY_CONFIG_SUCCESS'
export const FETCH_RHAPSODY_PAY_CONFIG_FAILURE =
  'rhapsodyPay/FETCH_RHAPSODY_PAY_CONFIG_FAILURE'

export const updateRhapsodyPayConfigs = (
  rhapsodyPayConfigs: Record<string, RhapsodyPayConfig>,
) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIGS,
  rhapsodyPayConfigs,
})

export const getPayPassAppUrl = (goOptionId: string, passOptionId: string) => ({
  type: GET_RHAPSODY_PAA_APP_URL,
  goOptionId,
  passOptionId,
})

export const sendRhapsodyPayConfigSuccess = (url: string) => ({
  type: GET_RHAPSODY_PAA_APP_URL_SUCCESS,
  url,
})

export const sendRhapsodyPayConfigFailure = (error: ApiError) => ({
  type: GET_RHAPSODY_PAA_APP_URL_FAILURE,
  error,
})

export const updateRhapsodyPayConfigManually = (config: RhapsodyPayConfig) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY,
  config,
})

export const updateRhapsodyPayConfigManuallySuccess = (businessId: string) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_SUCCESS,
  businessId,
})

export const updateRhapsodyPayConfigManuallyFailure = (error: ApiError) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_FAILURE,
  error,
})

export const updateRhapsodyPayConfig = (config: RhapsodyPayConfig) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG,
  config,
})

export const updateRhapsodyPayConfigSuccess = (businessId: string) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG_SUCCESS,
  businessId,
})

export const updateRhapsodyPayConfigFailure = (error: ApiError) => ({
  type: UPDATE_RHAPSODY_PAY_CONFIG_FAILURE,
  error,
})

export const fetchRhapsodyPayConfig = (
  businessId: string,
  extended?: boolean,
) => ({
  type: FETCH_RHAPSODY_PAY_CONFIG,
  businessId,
  extended,
})

export const fetchRhapsodyPayConfigSuccess = (businessId: string) => ({
  type: FETCH_RHAPSODY_PAY_CONFIG_SUCCESS,
  businessId,
})

export const fetchRhapsodyPayConfigFailure = (error: ApiError) => ({
  type: FETCH_RHAPSODY_PAY_CONFIG_FAILURE,
  error,
})

export type RhapsodyPayState = {
  error: string | null
  isFetching: boolean
  isLoading: boolean
  isSaving: boolean
  rhapsodyPayApplicationUrl: string
  rhapsodyPayConfigMap: Record<string, RhapsodyPayConfig>
}

export const RHAPSODY_PAY_INITIAL_STATE: RhapsodyPayState = {
  rhapsodyPayConfigMap: {},
  rhapsodyPayApplicationUrl: '',
  isLoading: false,
  isSaving: false,
  isFetching: false,
  error: null,
}

export const rhapsodyPayReducer = (
  state: RhapsodyPayState = RHAPSODY_PAY_INITIAL_STATE,
  action: AnyAction,
): RhapsodyPayState => {
  switch (action.type) {
    case UPDATE_RHAPSODY_PAY_CONFIGS:
      return {
        ...state,
        rhapsodyPayConfigMap: secondLevelMerge(
          state.rhapsodyPayConfigMap,
          action.rhapsodyPayConfigs,
        ),
      }
    case GET_RHAPSODY_PAA_APP_URL:
      return { ...state, isLoading: true }
    case GET_RHAPSODY_PAA_APP_URL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rhapsodyPayApplicationUrl: action.url,
      }
    case GET_RHAPSODY_PAA_APP_URL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_RHAPSODY_PAY_CONFIG:
    case UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY:
      return { ...state, isLoading: true, isSaving: true }
    case UPDATE_RHAPSODY_PAY_CONFIG_SUCCESS:
    case UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_SUCCESS:
      return { ...state, isLoading: false, isSaving: false }
    case UPDATE_RHAPSODY_PAY_CONFIG_FAILURE:
    case UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        isSaving: false,
      }
    case FETCH_RHAPSODY_PAY_CONFIG:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_RHAPSODY_PAY_CONFIG_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_RHAPSODY_PAY_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        isFetching: false,
      }
    default:
      return state
  }
}

export const getRhapsodyPay = (state: RootState): RhapsodyPayState =>
  state.rhapsodyPay
export const getRhapsodyPayIsLoading = (state: RootState) =>
  getRhapsodyPay(state).isLoading
export const getRhapsodyPayIsFetching = (state: RootState) =>
  getRhapsodyPay(state).isFetching
export const getRhapsodyPayIsSaving = (state: RootState) =>
  getRhapsodyPay(state).isSaving
export const getRhapsodyPayConfigMap = (state: RootState) =>
  getRhapsodyPay(state).rhapsodyPayConfigMap
export const getRhapsodyPayConfig = (businessId: string | Nil) =>
  createSelector(getRhapsodyPayConfigMap, (map) =>
    businessId ? map[businessId] : undefined,
  )
export const getRhapsodyPayApplicationUrl = (state: RootState) =>
  getRhapsodyPay(state).rhapsodyPayApplicationUrl
