/* eslint-disable react/no-multi-comp */
import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import * as R from 'ramda'
import { BaseUser, Nil, Text, Utils } from '@pbt/pbt-ui-components'

import {
  Appointment,
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { Invoice, InvoiceEvent } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'

import { MultipleLinesCell } from './MultipleLinesCell'

interface InvoiceTableDoctorCellProps {
  hideRetailOrder?: boolean
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const getAssignedVet = (event: InvoiceEvent | Appointment | Nil) => {
  if (R.isNil(event)) {
    return ''
  }

  if ('assignedVet' in event) {
    return Utils.getPersonString(event.assignedVet as BaseUser)
  }

  const assignedVetId = event?.assignedVetId
  const veterinarian = event?.resources?.find(
    (resource) => resource.employee.id === assignedVetId,
  )?.employee
  const assignedVet = Utils.getPersonString(veterinarian as BaseUser)
  return assignedVet
}

const InvoiceLineItem = (section: ChargesSection) => {
  const assignedVet =
    'event' in section && section.event ? getAssignedVet(section.event) : null

  if (!assignedVet) {
    return <></>
  }
  return <Text variant="body2">{assignedVet}</Text>
}

const RetailOrderLineItem = (section: UIRoliSection) => {
  const doctors = section.doctors?.filter(Boolean).join(', ')

  return (
    <Dotdotdot clamp={1}>
      <Text variant="body2">{doctors}</Text>
    </Dotdotdot>
  )
}

export const InvoiceTableDoctorCell = ({
  invoice,
  multipleRowsLimit,
  hideRetailOrder = false,
}: InvoiceTableDoctorCellProps) => {
  const sections =
    useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit) || []

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItem}
        RetailOrderLineItem={RetailOrderLineItem}
        hideRetailOrder={hideRetailOrder}
        sections={sections}
      />
    )
  }

  if ('event' in invoice && invoice.event) {
    return <Text variant="body2">{getAssignedVet(invoice.event)}</Text>
  }

  return null
}
