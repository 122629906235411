import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Skeleton } from '@mui/material'
import * as R from 'ramda'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import ConnectChewyAccount from '~/components/dashboard/link-chewy-account/tooltips/ConnectChewyAccount'
import { Status } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'
import FeatureToggle from '~/constants/featureToggle'
import {
  PrescriptionWorkflowName,
  PrescriptionWorkflowType,
} from '~/constants/PrescriptionWorkflow'
import {
  getGlobalInventoryMapping,
  getIsActiveRxWorkflowAllowanceLoading,
  getIsFetchingChewyItems,
  getLinkedToChewy,
  getPrescriptionChewyItems,
} from '~/store/duck/prescriptions'
import {
  getChewyFreeShippingEnabled,
  getFeatureToggle,
} from '~/store/reducers/constants'

import WorkflowSectionItem from './WorkflowSectionItem'

type DisabledState = {
  disabled: boolean
  id: string
  priority: number
  tooltip: string | React.ReactNode
}

export interface ChewyActiveRxWorkflowProps {
  activeWorkflow: PrescriptionWorkflowType | undefined
  clientId: string
  disabled: boolean
  handleSetActiveWorkflow: (
    workflow: PrescriptionWorkflowType | undefined,
  ) => void
  isChewyReactiveRx: boolean | undefined
  isEdit: boolean
  isLoading: boolean
  petParentHasCardOnChewyWallet: boolean
}

const chewyActiveWorkflow = PrescriptionWorkflowType.CHEWY_ACTIVE_RX
const label = PrescriptionWorkflowName[chewyActiveWorkflow]
const getDisabled = (state: DisabledState) => state.disabled

const ChewyActiveRxWorkflow = ({
  activeWorkflow,
  clientId,
  disabled: disabledProp,
  isChewyReactiveRx,
  isEdit,
  handleSetActiveWorkflow,
  isLoading,
  petParentHasCardOnChewyWallet,
}: ChewyActiveRxWorkflowProps) => {
  const { t } = useTranslation('Tooltips')

  const isActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )
  const chewyAccountLinker = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_ACCOUNT_LINKER),
  )
  const isChewyFreeShippingEnabled = useSelector(getChewyFreeShippingEnabled)

  const { business, client, patient } = useSelector(getLinkedToChewy) || {}
  const chewyItems = useSelector(getPrescriptionChewyItems)
  const globalInventoryMapping = useSelector(getGlobalInventoryMapping)
  const isFetchingChewyItems = useSelector(getIsFetchingChewyItems)
  const isActiveRxWorkflowAllowanceLoading = useSelector(
    getIsActiveRxWorkflowAllowanceLoading,
  )

  const hasStock = chewyItems?.some((item) => item.inStock) ?? false

  const DisabledStates: DisabledState[] = [
    {
      id: 'MISSING_KYRIOS_ID',
      disabled: !business,
      priority: 1,
      tooltip: t('Tooltips:CHEWY_ACTIVE_RX'),
    },
    {
      id: 'CHEWY_ACCOUNT_NOT_LINKED',
      disabled: !client || !patient,
      priority: 2,
      tooltip: chewyAccountLinker ? (
        <ConnectChewyAccount
          clientId={clientId}
          tooltipText={t('Tooltips:CONNECT_CLIENT_CHEWY_ACCOUNT')}
        />
      ) : (
        t('Tooltips:CHEWY_ACCOUNT_NOT_LINKED')
      ),
    },
    {
      id: 'NOT_AVAILABLE_ON_CHEWY_CATALOG',
      disabled:
        !isFetchingChewyItems &&
        (R.isNil(chewyItems) || Boolean(chewyItems && R.isEmpty(chewyItems))),
      priority: 3,
      tooltip: isChewyFreeShippingEnabled
        ? t('Tooltips:CHEWY_ITEM_UNAVAILABLE')
        : t('Tooltips:ITEM_NOT_AVAILABLE_ON_CHEWY_CATALOG'),
    },
    {
      id: 'ITEM_CANNOT_BE_PRESCRIBED_THROUGH_CHEWY',
      disabled:
        !isFetchingChewyItems &&
        globalInventoryMapping?.requiresPrescription === false,
      priority: 4,
      tooltip: isChewyFreeShippingEnabled
        ? t('Tooltips:CHEWY_ITEM_UNAVAILABLE')
        : t('Tooltips:ITEM_CANNOT_BE_PRESCRIBED_THROUGH_CHEWY'),
    },
    {
      id: 'OUT_OF_STOCK_AT_CHEWY',
      disabled: !hasStock,
      priority: 5,
      tooltip: t('Tooltips:OUT_OF_STOCK_AT_CHEWY'),
    },
    // RHAP-1710 - Until this ticket is done, we will disable switch workflow temporarily
    {
      id: 'DISABLE_EDITION_WORKFLOW_TRANSFORMATION',
      disabled: isEdit && activeWorkflow !== chewyActiveWorkflow,
      priority: 6,
      tooltip: '',
    },
  ]

  const isDisabled = DisabledStates.some(getDisabled)
  const disabled =
    disabledProp ||
    isDisabled ||
    isActiveRxWorkflowAllowanceLoading ||
    isFetchingChewyItems ||
    (isChewyFreeShippingEnabled && isLoading)

  useEffect(() => {
    if (
      disabled &&
      !isEdit &&
      activeWorkflow === chewyActiveWorkflow &&
      !isFetchingChewyItems
    ) {
      handleSetActiveWorkflow(PrescriptionWorkflowType.OUTSIDE_PHARMACY)
    }
  }, [disabled, isFetchingChewyItems])

  const disabledTooltip = DisabledStates.find(getDisabled)?.tooltip ?? ''
  const displayFreeShipping =
    hasStock && petParentHasCardOnChewyWallet && !disabledTooltip

  if (!isActiveRxEnabled || isChewyReactiveRx) {
    return null
  }

  return (
    <>
      <WorkflowSectionItem
        disabled={disabled}
        disabledTooltip={disabled ? disabledTooltip : ''}
        label={
          isChewyFreeShippingEnabled ? (
            <Box
              alignItems="center"
              display="flex"
              gap={0.5}
              justifyContent="flex-start"
              width="100%"
            >
              <Box display="flex">
                <ChewySubmark disabled={disabled} fontSize="small" />
                <Typography.Label
                  color={disabled ? 'grayGray2' : 'chewyPrimary'}
                >
                  {t('Common:CHEWY_COM')}
                </Typography.Label>
              </Box>
              {isLoading && <Skeleton height={20} width={30} />}
              {displayFreeShipping && (
                <Status variant="chewy">{t('Common:FREE_SHIPPING')}</Status>
              )}
            </Box>
          ) : (
            label
          )
        }
        workflow={chewyActiveWorkflow}
      />
    </>
  )
}

export default ChewyActiveRxWorkflow
