import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Nil, Text, TextWithTooltip } from '@pbt/pbt-ui-components'
import { Eye as EyeIcon, WarnAlert } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
    },
    label: {},
    iconButton: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: 'auto',
    },
    eyeIcon: {
      color: theme.colors.tabLabel,
    },
    divider: {
      borderTop: theme.constants.tableBorder,
      width: '100%',
    },
    infoBalanceIcon: {
      color: theme.colors.alertWarning,
      fontSize: '1.5rem',
    },
    infoBalanceTooltip: {
      borderColor: theme.colors.alertWarning,
      '&::before': {
        borderTopColor: theme.colors.alertWarning,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
    subLabel: {},
  }),
  { name: 'ClientInfoLinkItem' },
)

interface ClientInfoLinkItemProps {
  alertText?: string | Nil
  classes?: ClassesType<typeof useStyles>
  highlighted?: boolean
  label: string
  onClick: () => void
  showDivider?: boolean
  subLabel?: string
}

const ClientInfoLinkItem = ({
  classes: classesProp,
  label,
  subLabel,
  showDivider,
  highlighted,
  onClick,
  alertText,
}: ClientInfoLinkItemProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <>
      {showDivider && <Grid className={classes.divider} />}
      <Grid
        container
        alignItems="center"
        className={classes.root}
        my={0.5}
        onClick={onClick}
      >
        <TextWithTooltip
          Icon={WarnAlert}
          TooltipProps={{
            classes: {
              tooltip: classes.infoBalanceTooltip,
            },
          }}
          className={classes.label}
          classes={{
            infoIcon: classes.infoBalanceIcon,
          }}
          iconPlacement="right"
          strong={highlighted}
          tooltipText={alertText}
          variant={highlighted ? 'h5' : 'body2'}
        >
          {label}
        </TextWithTooltip>
        <IconButton className={classes.iconButton} size="large" type="button">
          <EyeIcon className={classes.eyeIcon} />
        </IconButton>
        {subLabel && (
          <Grid item py={0.5}>
            <Text
              className={classes.subLabel}
              fontWeight={500}
              px="0.8rem"
              variant="h5"
            >
              {subLabel}
            </Text>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ClientInfoLinkItem
