import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgFlagIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      clipRule="evenodd"
      d="M17.757 5.892c-.13-.107-.307-.123-.469-.107l-1.164.277a6.56 6.56 0 0 1-3.608-.185H12.5a7.54 7.54 0 0 0-3.915-.292l-.453.093v-.14A.55.55 0 0 0 7.566 5C7.243 5 7 5.23 7 5.538v12.785a.55.55 0 0 0 .566.539c.324 0 .566-.23.566-.539v-5.615l.647-.123a6.23 6.23 0 0 1 3.317.261l.048.016c.81.277 1.65.43 2.508.43.582 0 1.18-.06 1.763-.214l1.164-.277c.243-.062.42-.277.42-.508v-5.97a.66.66 0 0 0-.242-.43"
      fill="currentColor"
      fillRule="evenodd"
    />,
  )
export default SvgFlagIcon
