// eslint-disable-next-line simple-import-sort/imports
import React, { Suspense } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import {
  CircularProgressOverlay,
  muiTheme,
  SentryUtils,
} from '@pbt/pbt-ui-components'

import './locales/i18n'

import App from './components/App'
import { store } from './store'
import { initLogRocket } from './utils/LogRocket'

import './index.css'

SentryUtils.initSentry()
initLogRocket()

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Suspense fallback={<CircularProgressOverlay open />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)
