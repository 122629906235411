import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { getDoctor } from '~/components/dashboard/soap/utils/userUtils'
import { useIsSoapLocked } from '~/store/hooks/soap'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'

import IdentifiedProblemList from './IdentifiedProblemList'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.grey[50],
    },
  }),
  { name: 'IdentifiedProblems' },
)

const IdentifiedProblems = forwardRef<HTMLDivElement>(
  function IdentifiedProblems(_, ref) {
    const { t } = useTranslation('Soap')
    const classes = useStyles()
    const appointmentId = useSelector(getAppointmentId)
    const appointment = useSelector(getTimetableEvent(appointmentId))
    const doctor = getDoctor(appointment)

    const isReadOnly = useIsSoapLocked()

    return (
      <Grid className={classes.root} ref={ref}>
        {!isReadOnly && (
          <Text m={2} mb={0} variant="h4">
            {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.TITLE')}
          </Text>
        )}

        <IdentifiedProblemList doctor={doctor} />
      </Grid>
    )
  },
)

export default IdentifiedProblems
