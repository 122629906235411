import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  AppointmentCancellationReasonConstant,
  Field,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import { getInternalCancellationReasons } from '~/store/reducers/constants'
import { useIsWaivableCancellationReason } from '~/utils/appointmentCancellationUtils'

export interface UseUpdateInternalNotesForAppointmentCancellation {
  internalNotes: Field
  isAppointmentWithin24Hours?: boolean
  isNoShowConsentAppointmentType: boolean
  otherReasonField: Field
  setInternalNote: (value: string) => void
  showWaiveFeeCheckbox: boolean
  waiveLateCancellationFee: Field
}

export const useUpdateInternalNotesForAppointmentCancellation = ({
  isAppointmentWithin24Hours,
  isNoShowConsentAppointmentType,
  internalNotes,
  setInternalNote,
  waiveLateCancellationFee,
  showWaiveFeeCheckbox,
  otherReasonField,
}: UseUpdateInternalNotesForAppointmentCancellation) => {
  const { t } = useTranslation(['Dialogs', 'Common'])
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )
  const otherReasonId = Utils.findConstantIdByName(
    'Other',
    internalCancellationReasons,
  )

  const cancellationReasonRegExp = new RegExp(
    `<p>${t('Common:CANCELLATION_REASON')}:\\s*([^<]+)<\\/p>`,
  )
  const cancellationReasonRegExpExact = new RegExp(
    `(<p>${t('Common:CANCELLATION_REASON')}: [^<]+</p>)`,
  )
  const waiveLateCancellationRegExp = new RegExp(
    `<p>${t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')}<\\/p>`,
  )
  const waivedOrNotApplicableLateCancellationRegExp = new RegExp(
    `(<p>${t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')}<\\/p>|<p>${t(
      'Common:LATE_CANCELLATION_FEE_NOTE.NOT_APPLICABLE',
    )}<\\/p>)`,
  )
  const otherReasonRegExp = new RegExp(
    `<p>${t(
      'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
    )}:<\\/p>`,
  )
  const otherReasonRegExpWithContent = new RegExp(
    `<p>${t(
      'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
    )}:\\s*([^<]+)<\\/p>`,
  )
  const otherReasonRegExpWithContentInclusive = new RegExp(
    `(<p>${t('Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION')}:\\s*[^<]+<\\/p>)`,
  )

  const { isWaivableCancellationReason } = useIsWaivableCancellationReason()

  const onWaiveLateCancellationFeeChange = (enabled: boolean) => {
    const prevValue = internalNotes.value
    let newValue = ''

    if (enabled) {
      if (prevValue === '') {
        newValue = `<p>${t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')}</p>`
      } else if (otherReasonRegExpWithContentInclusive.test(prevValue)) {
        newValue = prevValue.replace(
          otherReasonRegExpWithContentInclusive,
          `$1 <p>${t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')}</p>`,
        )
      } else {
        newValue = prevValue.replace(
          cancellationReasonRegExpExact,
          `$1 <p>${t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')}</p>`,
        )
      }
    } else {
      newValue = prevValue.replace(waiveLateCancellationRegExp, '')
    }

    setInternalNote(newValue)
    waiveLateCancellationFee.setValue(showWaiveFeeCheckbox ? enabled : null)
  }

  const onOtherReasonTextChange = () => {
    const prevValue = internalNotes.value
    let newValue

    if (otherReasonRegExpWithContent.test(prevValue)) {
      newValue = prevValue.replace(
        otherReasonRegExpWithContent,
        `<p>${t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
        )}: ${otherReasonField.value}</p>`,
      )
    } else if (otherReasonRegExp.test(prevValue)) {
      newValue = prevValue.replace(
        otherReasonRegExp,
        `<p>${t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
        )}: ${otherReasonField.value}</p>`,
      )
    }

    if (newValue) {
      setInternalNote(newValue)
    }
  }

  const removeCancellationFeeNote = (value: string) =>
    value.replace(waivedOrNotApplicableLateCancellationRegExp, '')

  const addCancellationFeeNoteIfNeeded = (value: string) => {
    if (
      isNoShowConsentAppointmentType &&
      !waivedOrNotApplicableLateCancellationRegExp.test(value)
    ) {
      const lateCancellationFeeNote = isAppointmentWithin24Hours
        ? t('Common:LATE_CANCELLATION_FEE_NOTE.WAIVED')
        : t('Common:LATE_CANCELLATION_FEE_NOTE.NOT_APPLICABLE')

      return value.replace(
        cancellationReasonRegExpExact,
        `$1 <p>${lateCancellationFeeNote}</p>`,
      )
    }
    return value
  }

  const addOtherReasonNote = (value: string) => {
    if (cancellationReasonRegExp.test(value)) {
      return value.replace(
        cancellationReasonRegExpExact,
        `$1 <p>${t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
        )}:</p>`,
      )
    }
    return value
  }

  const removeOtherReasonNote = (value: string) => {
    let newValue = value
    if (otherReasonRegExp.test(newValue)) {
      newValue = newValue.replace(otherReasonRegExp, '')
    } else if (otherReasonRegExpWithContent.test(newValue)) {
      newValue = newValue.replace(otherReasonRegExpWithContent, '')
    }
    return newValue
  }

  const onCancellationReasonChange = (
    reason: AppointmentCancellationReasonConstant | Nil,
  ) => {
    const prevValue = internalNotes.value
    if (!R.isNil(reason)) {
      let newValue = cancellationReasonRegExp.test(prevValue)
        ? prevValue.replace(
            cancellationReasonRegExp,
            `<p>${t('Common:CANCELLATION_REASON')}: ${reason.name}</p>`,
          )
        : `<p>${t('Common:CANCELLATION_REASON')}: ${reason.name}</p>${prevValue}`

      const shouldRemoveOtherReasonAndWaiveFeeNotes =
        !isWaivableCancellationReason(reason.id)

      if (shouldRemoveOtherReasonAndWaiveFeeNotes) {
        newValue = removeOtherReasonNote(newValue)
        newValue = removeCancellationFeeNote(newValue)
        waiveLateCancellationFee.setValue(null)
        otherReasonField.setValue(null)
      } else {
        newValue = addCancellationFeeNoteIfNeeded(newValue)

        if (reason.id === otherReasonId) {
          newValue = addOtherReasonNote(newValue)
        } else {
          newValue = removeOtherReasonNote(newValue)
          otherReasonField.setValue(null)
        }
        waiveLateCancellationFee.setValue(true)
      }
      setInternalNote(newValue)
    }
  }

  const onCancellationReasonRadioSelectEvent = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value
    const reasons = internalCancellationReasons.filter((r) => r.id === value)
    const reason = reasons.length > 0 ? reasons[0] : null
    onCancellationReasonChange(reason)
  }

  return {
    onCancellationReasonChange,
    onCancellationReasonRadioSelectEvent,
    onWaiveLateCancellationFeeChange,
    onOtherReasonTextChange,
  }
}
