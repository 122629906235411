import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getInventoryCategory } from '~/store/reducers/constants'

type InventoryOrderCategoryCellItem = {
  categories: string[]
}

export const InventoryOrderCategoryCell = ({
  categories,
}: InventoryOrderCategoryCellItem) => {
  const InventoryCategories = useSelector(getInventoryCategory)
  return categories
    ? categories
        .map((categoryId) =>
          LanguageUtils.getConstantTranslatedName(
            categoryId,
            InventoryCategories,
          ),
        )
        .join(', ')
    : ''
}
