import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import NumericInput from '~/components/common/inputs/NumericInput'
import FeatureToggle from '~/constants/featureToggle'
import { useInStockUnits } from '~/store/hooks/inventories'
import {
  getFeatureToggle,
  getInventoryAdjustmentReasons,
  getInventoryInStockUnit,
  getSelectableInventoryAdjustmentReasons,
} from '~/store/reducers/constants'
import {
  Adjustment,
  DataHandleWithReset,
  Space,
  UnsavedAdjustment,
} from '~/types'
import { getPluckedFieldsByFieldKey } from '~/utils'

import OnHandAmountCell from '../../on-hand/OnHandAmountCell'

const useStyles = makeStyles(
  (theme) => ({
    cell: {
      padding: theme.spacing(1, 3, 1, 1),
    },
    mainCell: {
      borderRight: theme.constants.tableBorder,
    },
    select: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      fontWeight: 400,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    quantity: {
      fontSize: '1.4rem',
      width: 60,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'BulkAdjustmentRow' },
)

export type BulkAdjustmentRowHandle = DataHandleWithReset

interface BulkAdjustmentRowProps {
  adjustment: Adjustment | UnsavedAdjustment
  spaces?: Space[]
}

const BulkAdjustmentRow = forwardRef<
  BulkAdjustmentRowHandle,
  BulkAdjustmentRowProps
>(function BulkAdjustmentRow({ adjustment, spaces = [] }, ref) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )
  const InventoryAdjustmentReasons = useSelector(getInventoryAdjustmentReasons)
  const SelectableInventoryAdjustmentReasons = useSelector(
    getSelectableInventoryAdjustmentReasons,
  )
  const InventoryInStockUnit = useSelector(getInventoryInStockUnit)

  const perPackageUnitsId = adjustment.variation?.perPackageUnitsId
  const packageTypeId = adjustment.variation?.packageTypeId

  const inStockUnitOptions = useInStockUnits(perPackageUnitsId, packageTypeId)

  const physicalCountReason = Utils.findConstantIdByName(
    'Physical count',
    isIpoM0InvoiceRefundsEnabled
      ? SelectableInventoryAdjustmentReasons
      : InventoryAdjustmentReasons,
  )
  const unitPackageType = Utils.findConstantIdByName(
    'Package',
    InventoryInStockUnit,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'desiredOnHandAmount',
        label: t('Common:QUANTITY'),
        validators: ['required'],
      },
      {
        name: 'quantityUnitId',
        label: t('Common:QUANTITY_UNIT_ID'),
        type: 'select',
        initialValue: adjustment.quantityUnitId || unitPackageType,
        validators: ['required'],
      },
      {
        name: 'reasonId',
        label: t('Common:ADJUSTMENT_REASON'),
        type: 'select',
        initialValue: adjustment.reasonId || physicalCountReason,
        validators: ['required'],
      },
      {
        name: 'storageLocationId',
        label: t('Common:STORAGE_LOCATION'),
        type: 'select',
        initialValue: adjustment.storageLocationId,
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
      },
    ],
    false,
  )

  useEffect(() => reset(), [adjustment])

  useImperativeHandle(ref, () => ({
    get: () => getPluckedFieldsByFieldKey(fields, 'value'),
    validate,
    reset,
  }))

  return (
    <TableRow>
      <TableCell className={classNames(classes.cell, classes.mainCell)}>
        <Text variant="body2">{adjustment.variation?.name}</Text>
      </TableCell>
      <TableCell className={classes.cell}>
        <Text variant="body2">
          {adjustment.variation && (
            // @ts-ignore
            <OnHandAmountCell {...adjustment.variation} />
          )}
        </Text>
      </TableCell>
      <TableCell className={classes.cell}>
        <Grid container wrap="nowrap">
          <NumericInput
            allowDecimal
            className={classes.quantity}
            field={fields.desiredOnHandAmount}
            margin="none"
            max={999999}
            min={0}
          />
          <PuiSelect
            disableUnderline
            classes={{
              select: classes.select,
            }}
            field={fields.quantityUnitId}
            items={inStockUnitOptions}
            renderEmpty={false}
          />
        </Grid>
      </TableCell>
      <TableCell className={classes.cell}>
        <EnumSelect
          Constant={
            isIpoM0InvoiceRefundsEnabled
              ? SelectableInventoryAdjustmentReasons
              : InventoryAdjustmentReasons
          }
          classes={{
            statusSelect: classes.select,
          }}
          field={fields.reasonId}
          placeholder={fields.reasonId.label}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <PuiSelect
          disableUnderline
          classes={{
            select: classes.select,
          }}
          field={fields.storageLocationId}
          items={spaces}
          placeholder={fields.storageLocationId.label}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <PuiTextField field={fields.notes} margin="none" />
      </TableCell>
    </TableRow>
  )
})

export default BulkAdjustmentRow
