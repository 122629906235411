import { gql } from '@apollo/client'

import {
  GROUPED_ITEM_FIELDS,
  INVOICE_LINE_ITEM_FIELDS,
  INVOICE_LINE_ITEM_TOOLTIP_FIELDS,
  INVOICE_SECTION_EVENT,
  RETAIL_ORDER_LINE_ITEM_FIELDS,
} from './finance'

export const CHARGE_SHEET_GROUPPED_ITEMS = (
  includeRetailOrderLineItems: boolean,
) => gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  ${includeRetailOrderLineItems ? RETAIL_ORDER_LINE_ITEM_FIELDS : ''}
  ${GROUPED_ITEM_FIELDS}

  fragment ChargeSheetGroupedItems on GroupedItems {
    ... on GroupedItem {
      ...GroupedItemFields
    }
    ... on InvoiceLineItem {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
    ${includeRetailOrderLineItems ? `...RetailOrderLineItemFields` : ``}
  }
`

export const CHARGE_SHEET_EVENTS = gql`
  fragment ChargeSheetEvents on Appointment {
    id
    clientId
    type {
      id
      name
    }
    scheduledStartDatetime
    resources {
      employee {
        id
        firstName
        lastName
      }
      responsibility {
        id
        name
      }
    }
    businessAppointmentType {
      id
      name
    }
  }
`

export const CHARGE_SHEET_SOAP = gql`
  fragment ChargeSheetSoap on Soap {
    id
    medicalNotes
    assignedVetId
    assignedVetTechId
    finalized
  }
`

export const CHARGE_SHEET_SECTIONS_FIELDS = (
  includeRetailOrderLineItems: boolean,
) => gql`
  ${CHARGE_SHEET_SOAP}
  ${CHARGE_SHEET_GROUPPED_ITEMS(includeRetailOrderLineItems)}
  ${INVOICE_SECTION_EVENT}
  fragment ChargeSheetSectionFields on ChargesSection {
    id
    soapId
    subtotal: subTotal
    totalTax: taxAmount
    discount
    additionalDiscount
    chewyItemDiscount
    itemDiscount
    bundleAdditionalDiscount
    estimateAdditionalDiscount
    modificationDate
    amount: totalAmount
    soap {
      ...ChargeSheetSoap
    }
    event {
      ...InvoiceSectionEvent
    }
    eventId
    patientId
    clientId
    businessId
    groupedItems {
      ...ChargeSheetGroupedItems
    }
  }
`

export const CHARGE_SHEET_FIELDS = (
  includeRetailOrderLineItems: boolean,
) => gql`
  ${CHARGE_SHEET_EVENTS}
  ${CHARGE_SHEET_SOAP}
  ${CHARGE_SHEET_GROUPPED_ITEMS(includeRetailOrderLineItems)}
  ${CHARGE_SHEET_SECTIONS_FIELDS(includeRetailOrderLineItems)}
  fragment ChargeSheetFields on ChargeSheet {
    id
    businessId
    amount
    amountNoFee
    subtotal: subTotal
    totalTax
    discount: totalDiscount
    type
    persons {
      id
      firstName
      lastName
    }
    prevBalance
    events {
      ...ChargeSheetEvents
    }
    sections {
      ...ChargeSheetSectionFields
    }
    modificationDate
    totalItems
  }
`
export const ORDER_FILTER = gql`
  fragment OrderFilter on OrderFilter {
    type
    name
    categoryId
    subcategoryId
    refineGroup
  }
`
export const SHIPPING_ADDRESS_FIELDS = gql`
  fragment ShippingAddressFields on Client {
    id
    shippingAddresses {
      id
      primary
      address {
        id
        street1
        city
        postcode
        state
      }
    }
  }
`

export const CLIENT_FIELDS = (queryShippingAddressForM1: boolean) => gql`
  ${queryShippingAddressForM1 ? SHIPPING_ADDRESS_FIELDS : ''}
  fragment ClientFields on Client {
    id
    firstName
    lastName
    homePhone
    mobilePhone
    email
    isBoopUser
    alertText
    alertColorId
    ${queryShippingAddressForM1 ? `...ShippingAddressFields` : ``}
  }
`
