import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'
import {
  Attachment as AttachmentIcon,
  Calendar as CalendarIcon,
  Communications,
  Diagnosis as DiagnosisIcon,
  Estimates as EstimatesIcon,
  Imaging as ImagingIcon,
  ImportedHistory as ImportedHistoryIcon,
  Lab as LabIcon,
  Note as NoteIcon,
  Prescription as PrescriptionsIcon,
  Question as QuestionIcon,
  Reminder as ReminderIcon,
  Surgery as SurgeryIcon,
  TasksDashboard,
  Vaccine as VaccineIcon,
  Vital as VitalIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { TimelineEntryType } from '~/constants/timelineConstants'
import i18n from '~/locales/i18n'
import { getCRUDByArea } from '~/store/reducers/auth'
import { TimelineConfigItem } from '~/types'

import AppointmentCard from './appointment/AppointmentCard'
import AttachmentCard from './attachment/AttachmentCard'
import ConversationCard from './communications/ConversationCard'
import DiagnosisCard from './diagnosis/DiagnosisCard'
import OrderedDrugCard from './drugs/OrderedDrugCard'
import EstimateCard from './estimate/EstimateCard'
import ImagingCard from './imaging/ImagingCard'
import ImportedHistoryCard from './importedHistory/ImportedHistoryCard'
import LabTestCard from './labTest/LabTestCard'
import NoteCard from './note/NoteCard'
import OTCSaleCard from './otcSale/OTCSaleCard'
import PrescriptionsCard from './prescriptions/PrescriptionsCard'
import QuestionCard from './question/QuestionCard'
import ReminderCard from './reminder/ReminderCard'
import SurgeryCard from './surgery/SurgeryCard'
import TaskCard from './task/TaskCard'
import VaccinationCard from './vaccination/VaccinationCard'
import VitalCard from './vital/VitalCard'

const useTimelineItems = () => {
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const diagnosesPermissions = useSelector(
    getCRUDByArea(PermissionArea.DIAGNOSE),
  )
  const imagesPermissions = useSelector(
    getCRUDByArea(PermissionArea.IMAGE_FILE),
  )
  const remindersPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )
  const taskPermissions = useSelector(getCRUDByArea(PermissionArea.TASK))
  const vitalPermissions = useSelector(getCRUDByArea(PermissionArea.VITAL))
  const prescriptionPermissions = useSelector(
    getCRUDByArea(PermissionArea.PRESCRIPTION),
  )

  const attachmentType = TimelineEntryType.ATTACHMENT_GROUP
  const attachmentKey = attachmentType.toLowerCase()

  const timelineItems: Record<string, TimelineConfigItem> = {
    appointment: {
      type: TimelineEntryType.APPOINTMENT,
      icon: CalendarIcon,
      label: i18n.t('Common:APPOINTMENT_OTHER'),
      component: AppointmentCard,
      permission: true,
    },
    [attachmentKey]: {
      type: attachmentType,
      icon: AttachmentIcon,
      label: i18n.t('Common:ATTACHMENTS'),
      component: AttachmentCard,
      permission: true,
    },
    conversation: {
      type: TimelineEntryType.CONVERSATION,
      icon: Communications,
      label: i18n.t('Common:CONVERSATIONS'),
      component: ConversationCard,
      permission: true,
    },
    diagnosis: {
      type: TimelineEntryType.DIAGNOSIS,
      icon: DiagnosisIcon,
      label: i18n.t('Common:DIAGNOSES'),
      component: DiagnosisCard,
      permission: diagnosesPermissions.read,
    },
    problem_diagnosis: {
      type: TimelineEntryType.DIAGNOSIS,
      icon: DiagnosisIcon,
      label: i18n.t('Common:DIAGNOSES'),
      component: DiagnosisCard,
      skipInFilters: true,
      permission: diagnosesPermissions.read,
    },
    estimate: {
      type: TimelineEntryType.ESTIMATE,
      icon: EstimatesIcon,
      label: i18n.t('Common:ESTIMATES_BUDGET'),
      component: EstimateCard,
      permission: invoicePermissions.read,
    },
    imaging: {
      type: TimelineEntryType.IMAGING,
      icon: ImagingIcon,
      label: i18n.t('Common:IMAGING'),
      component: ImagingCard,
      permission: imagesPermissions.read,
    },
    imported_history: {
      type: TimelineEntryType.IMPORTED_HISTORY,
      icon: ImportedHistoryIcon,
      label: i18n.t('Clients:TIMELINE.FILTER.IMPORTED_HISTORY'),
      component: ImportedHistoryCard,
      permission: true,
    },
    lab_test: {
      type: TimelineEntryType.LAB_TEST,
      icon: LabIcon,
      label: i18n.t('Abbreviations:COMMON.LABORATORIES'),
      component: LabTestCard,
      permission: true,
    },
    note: {
      type: TimelineEntryType.NOTE,
      icon: NoteIcon,
      label: i18n.t('Common:NOTES'),
      component: NoteCard,
      permission: true,
    },
    otc_sale: {
      type: TimelineEntryType.OTC_SALE,
      icon: EstimatesIcon,
      label: i18n.t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.OTC_SALE'),
      component: OTCSaleCard,
      permission: true,
    },
    'prescription.prescription': {
      type: TimelineEntryType.PRESCRIPTION,
      icon: PrescriptionsIcon,
      label: i18n.t('Clients:TIMELINE.FILTER.PRESCRIPTIONS_DRUGS'),
      component: PrescriptionsCard,
      permission: prescriptionPermissions.read,
    },
    'prescription.drug': {
      type: TimelineEntryType.PRESCRIPTION,
      icon: PrescriptionsIcon,
      component: OrderedDrugCard,
      label: i18n.t('Clients:TIMELINE.FILTER.PRESCRIPTIONS_DRUGS'),
      skipInFilters: true,
      permission: prescriptionPermissions.read,
    },
    question: {
      type: TimelineEntryType.QUESTION,
      icon: QuestionIcon,
      label: i18n.t('Common:QUESTION_OTHER'),
      component: QuestionCard,
      permission: true,
    },
    reminder: {
      type: TimelineEntryType.REMINDER,
      icon: ReminderIcon,
      label: i18n.t('Common:REMINDERS'),
      component: ReminderCard,
      permission: remindersPermissions.read,
    },
    surgery: {
      type: TimelineEntryType.SURGERY,
      icon: SurgeryIcon,
      label: i18n.t('Common:SURGERY'),
      component: SurgeryCard,
      permission: true,
    },
    task: {
      type: TimelineEntryType.TASK,
      icon: TasksDashboard,
      label: i18n.t('Common:TASKS'),
      component: TaskCard,
      permission: taskPermissions.read,
    },
    vaccination: {
      type: TimelineEntryType.VACCINATION,
      icon: VaccineIcon,
      label: i18n.t('Common:VACCINATIONS'),
      component: VaccinationCard,
      permission: true,
    },
    vital: {
      type: TimelineEntryType.VITAL,
      icon: VitalIcon,
      label: i18n.t('Common:VITALS'),
      component: VitalCard,
      permission: vitalPermissions.read,
    },
  }

  return timelineItems
}

export default useTimelineItems
