import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, LanguageUtils, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      borderBottom: theme.constants.filterBorder,
    },
    title: {
      width: 180,
      minWidth: 180,
    },
  }),
  { name: 'InfoList' },
)

export type InfoListItem = {
  component?: React.ReactNode
  hidden?: boolean
  name: string
  value?: string | number
}

export interface InfoListProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  container?: boolean
  items: InfoListItem[]
}

const InfoList = ({
  items,
  classes: classesProp,
  className,
  container = true,
}: InfoListProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid className={className} container={container}>
      {items.map((item) => {
        const { name, value, component, hidden } = item
        return (
          !hidden && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.row}
              key={name}
              py={1}
              wrap="nowrap"
            >
              <Text strong className={classes.title} variant="body2">
                {LanguageUtils.getTranslatedFieldName(item)}
              </Text>
              {value && <Text variant="body2">{value}</Text>}
              {component}
            </Grid>
          )
        )
      })}
    </Grid>
  )
}

export default InfoList
