import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Fab, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Defaults,
  PermissionArea,
  PrimitiveTableColumn,
  TableFilter,
  TaskEventType,
  Utils,
} from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import QuickFilterButton from '~/components/common/buttons/QuickFilterButton'
import ClientsFilter from '~/components/common/filters/ClientsFilter'
import TeamMemberFilter from '~/components/common/filters/TeamMemberFilter'
import ExpandableTable from '~/components/common/lists/ExpandableTable'
import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import AssignedCell from '~/components/common/lists/primitive-table/cells/AssignedCell'
import CreatedByCell from '~/components/common/lists/primitive-table/cells/CreatedByCell'
import DueDateCell from '~/components/common/lists/primitive-table/cells/DueDateCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import DialogNames from '~/constants/DialogNames'
import { NotificationAreaNames } from '~/constants/notifications'
import i18n from '~/locales/i18n'
import {
  fetchMoreItemsForTasksList,
  fetchTasksList,
  setTasksListFilters,
} from '~/store/actions/tasks'
import { useGetTaskIsUnlinkedRxRequest } from '~/store/hooks/tasks'
import {
  getCRUDByArea,
  getCurrentBusinessWellnessPlansEnabled,
  getCurrentUser,
} from '~/store/reducers/auth'
import { getEventType } from '~/store/reducers/constants'
import {
  getTasksIsLoadingList,
  getTasksList,
  getTasksListFilters,
  getTasksTotalCount,
  getTasksValidationError,
} from '~/store/reducers/tasks'
import { Task } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useErrorAlert from '~/utils/useErrorAlert'

import LinkedItemNotificationsBadge from '../notifications/LinkedItemNotificationsBadge'
import NoTasksScreen from './NoTasksScreen'
import TaskDetails from './TaskDetails'
import TaskPatientCell from './TaskPatientCell'
import TasksDateRangeFilter from './TasksDateRangeFilter'
import TasksStatusFilter from './TasksStatusFilter'
import TasksTableRow from './TasksTableRow'
import TaskStatusSelect from './TaskStatusSelect'
import TasksTypeFilter from './TasksTypeFilter'
import TaskTypeCell from './TaskTypeCell'

type TaskFilterState = {
  assignedTo?: string
  forClient?: boolean
  statuses?: string[]
} & PuiButtonGroupItem

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: 132,
      height: 40,
    },
    quickFilterButton: {
      minWidth: 156,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    quickFilterButtonSearch: {
      marginLeft: 0,
    },
  }),
  { name: 'TasksTableComponent' },
)

const Filters = {
  DUE_DATE: 'dueDate',
  ASSIGNED_TO: 'assignedTo',
  CREATED_BY: 'createdBy',
  TYPE: 'type',
  STATUSES: 'statuses',
}

const columns1: PrimitiveTableColumn[] = [
  {
    label: i18n.t('Time:LABEL.DUE_DATE'),
    prop: DueDateCell,
    filter: Filters.DUE_DATE,
    FilterComponent: TasksDateRangeFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:TASK'),
    prop: 'name',
    highlight: true,
    width: 3,
  },
]

const assignedColumn: PrimitiveTableColumn = {
  label: i18n.t('Common:ASSIGNED'),
  hasWarning: (item) => item.assigned === null,
  prop: AssignedCell,
  filter: Filters.ASSIGNED_TO,
  FilterComponent: ({ ...props }) => (
    <TeamMemberFilter displayEmpty {...props} />
  ),
  width: 3,
}

const assignedColumnForClient: PrimitiveTableColumn = {
  label: `${i18n.t('Common:ASSIGNED')}/${i18n
    .t('Common:PET_PARENT')
    .toLowerCase()}`,
  hasWarning: (item) => item.assigned === null,
  prop: AssignedCell,
  filter: Filters.ASSIGNED_TO,
  // eslint-disable-next-line react/no-multi-comp
  FilterComponent: ({ ...props }) => (
    <ClientsFilter
      displayEmpty
      label={i18n.t('Search:PET_PARENTS')}
      {...props}
    />
  ),
  width: 3,
}

const columns2: PrimitiveTableColumn[] = [
  {
    label: i18n.t('Common:CREATED_BY'),
    prop: CreatedByCell,
    filter: Filters.CREATED_BY,
    FilterComponent: TeamMemberFilter,
    width: 3,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    prop: TaskTypeCell,
    filter: Filters.TYPE,
    FilterComponent: TasksTypeFilter,
    width: 2,
  },
  {
    noTypography: true,
    prop: (task) => (
      <LinkedItemNotificationsBadge
        areaName={NotificationAreaNames.TASKS}
        itemId={task.id}
      />
    ),
    width: 0.25,
  },
]

const statusColumn = {
  label: i18n.t('Common:STATUS'),
  noTypography: true,
  filter: undefined,
  prop: (task: Task) => (
    <TaskStatusSelect
      task={task}
      onClick={(event) => event.stopPropagation()}
    />
  ),
  FilterComponent: undefined,
  width: 2,
}

const statusColumnForClient = {
  label: i18n.t('Common:STATUS'),
  noTypography: true,
  filter: Filters.STATUSES,
  prop: (task: Task) => (
    <TaskStatusSelect
      task={task}
      onClick={(event) => event.stopPropagation()}
    />
  ),
  FilterComponent: TasksStatusFilter,
  width: 2,
}
const useTaskTableColumns = (forClient: boolean) => {
  const getTaskIsUnlinkedRxRequest = useGetTaskIsUnlinkedRxRequest()

  const patientColumn: PrimitiveTableColumn = {
    hasWarning: getTaskIsUnlinkedRxRequest,
    label: i18n.t('Common:PATIENT'),
    noTypography: true,
    prop: (task: Task) => <TaskPatientCell task={task} />,
    width: 2,
    columnWrap: 'nowrap',
  }

  return forClient
    ? [
        ...columns1,
        patientColumn,
        assignedColumnForClient,
        ...columns2,
        statusColumnForClient,
      ]
    : [...columns1, patientColumn, assignedColumn, ...columns2, statusColumn]
}

interface TasksTableComponentProps {
  isExpandable?: boolean
  onDetailsClose: () => void
  taskId?: string
}

// eslint-disable-next-line react/no-multi-comp
const TasksTableComponent = ({
  isExpandable,
  taskId,
  onDetailsClose,
}: TasksTableComponentProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getTasksList)
  const isLoading = useSelector(getTasksIsLoadingList)
  const totalCount = useSelector(getTasksTotalCount)
  const filters = useSelector(getTasksListFilters)
  const currentUser = useSelector(getCurrentUser)
  const permissions = useSelector(getCRUDByArea(PermissionArea.TASK))
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const EventType = useSelector(getEventType)

  const { t } = useTranslation(['Common', 'Search', 'Tasks', 'Time'])

  const TaskType: TaskEventType =
    Utils.findConstantByName('Task', EventType) || {}
  const TaskStates = TaskType.states || []
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)

  const DoneStatuses = [DoneState]
  const ToDoStatuses = TaskStates.filter(({ id }) => id !== DoneState).map(
    (item) => item?.id,
  )

  const TaskFilterStates: TaskFilterState[] = [
    { label: t('Tasks:LABEL.TO_DO'), statuses: ToDoStatuses, forClient: false },
    { label: t('Common:DONE'), statuses: DoneStatuses, forClient: false },
  ]

  if (wellnessPlansEnabled) {
    TaskFilterStates.push({
      label: t('Tasks:TASKS_TABLE_COMPONENT.CLIENT_TASKS'),
      icon: undefined,
      forClient: true,
    })
  }

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const [openTaskDialog] = useDialog(DialogNames.TASK)

  const search = getUrlSearchParam('query', location.search)

  useErrorAlert({
    errorSelector: getTasksValidationError,
    onOpenHandler: () => {
      navigate(addSearch(location, '/tasks-dashboard/'))
    },
    onCloseHandler: () => {
      dispatch(
        fetchTasksList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
      )
    },
  })

  const setFilters = (newFilters: Record<string, TableFilter>) => {
    dispatch(setTasksListFilters(newFilters))
  }

  const setInitialFilters = () => {
    setFilters({
      statuses: {
        value: ToDoStatuses,
      },
      forClient: {
        value: false,
      },
    })
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    setFilters({ ...filters, [filter]: value })
  }

  const onClearFilters = () => {
    setInitialFilters()
  }

  useEffect(() => {
    setInitialFilters()
  }, [])

  useEffectExceptOnMount(() => {
    dispatch(fetchTasksList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search))
  }, [search, filters])

  const navigateToTask = (id: string) => {
    navigate(addSearch(location, `/tasks-dashboard/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(list[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForTasksList(startIndex, endIndex, search))
  }

  const selectedItem =
    filters.forClient?.value === true && wellnessPlansEnabled
      ? TaskFilterStates.find((state) => state.forClient)
      : TaskFilterStates.find((state) =>
          state.statuses?.every((status) =>
            ((filters.statuses?.value as string[]) || []).includes(status),
          ),
        ) || R.head(TaskFilterStates)

  const forClient = Boolean(selectedItem?.forClient)

  const specialFilters = [
    // for the `Boop tasks` tab `statues` filter becomes column filter
    forClient ? undefined : 'statuses',
    'forClient',
  ]

  const hasAppliedFilters = (appliedFilters: Record<string, TableFilter>) =>
    Object.keys(appliedFilters).filter(
      (filterName) =>
        Boolean(appliedFilters[filterName]) &&
        !specialFilters.includes(filterName),
    ).length > 0

  const hasSelectedFilters = hasAppliedFilters(filters)

  const columns = useTaskTableColumns(forClient)

  const handleSelectedTabFilterChange = (selectedFilter: TaskFilterState) => {
    setFilters({
      ...filters,
      statuses: {
        value: selectedFilter.statuses,
      },
      forClient: {
        ...filters.forClient,
        value: selectedFilter.forClient,
      },
      assignedTo: selectedFilter.forClient
        ? { value: null }
        : filters.assignedTo,
    })
  }

  const headerButtons = (
    <Grid
      container
      item
      xs
      alignItems={isMobile ? 'stretch' : 'center'}
      direction={isMobile ? 'column' : 'row'}
    >
      {!search && (
        <PuiButtonGroup
          items={TaskFilterStates}
          selectedItem={selectedItem}
          onItemSelected={handleSelectedTabFilterChange}
        />
      )}
      {!filters.forClient?.value && (
        <Grid item>
          <QuickFilterButton
            IconComponent={Avatar}
            IconProps={{
              person: currentUser,
            }}
            active={filters.assignedTo?.value === currentUser.id}
            className={classNames(
              classes.quickFilterButton,
              search && classes.quickFilterButtonSearch,
            )}
            text={t('Common:ASSIGNED_TO_ME')}
            onClick={() => {
              const wasChecked = filters.assignedTo?.value === currentUser.id
              setFilters({
                ...filters,
                assignedTo: wasChecked
                  ? { value: null }
                  : {
                      ...filters.assignedTo,
                      value: currentUser.id,
                      humanReadable: Utils.getPersonString(currentUser),
                    },
              })
            }}
          />
        </Grid>
      )}
      <Grid ml={2}>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
    </Grid>
  )

  const wrappedHeaderButtons = (
    <Grid container item px={3} py={2}>
      {headerButtons}
      {permissions.create && (
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={() =>
            openTaskDialog({
              redirectOnCreated: true,
              forClientActive: forClient,
            })
          }
        >
          {t('Tasks:LABEL.NEW_TASK')}
        </Fab>
      )}
    </Grid>
  )

  return (
    <ExpandableTable
      Expander={isExpandable ? TaskDetails : undefined}
      NoItemsScreen={NoTasksScreen}
      NoItemsScreenProps={{
        headerButtons,
      }}
      hasSelectedFilters={hasSelectedFilters}
      headerButtons={wrappedHeaderButtons}
      isLoading={isLoading}
      itemId={taskId}
      list={list}
      searchTerm={search}
      title={t('Common:TASKS')}
      onClose={onDetailsClose}
      onSelected={navigateToTask}
    >
      <PrimitiveTableWithSearchHighlights
        HeaderProps={{ useNilAsEmpty: true }}
        RowComponent={TasksTableRow}
        columns={columns}
        filters={filters}
        isItemLoaded={isItemLoaded}
        list={list}
        loadMoreItems={loadMoreItems}
        mainColumnCount={2}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default TasksTableComponent
