import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { CustomFieldValidatorState, useFields } from '@pbt/pbt-ui-components'

import { WellnessPlanAutoRenew, WellnessPlanVersionAutoRenew } from '~/types'
import { getPluckedFieldsByFieldKey } from '~/utils'

import { VerifyAutoRenewingPlansTable } from './VerifyAutoRenewingPlansTable'

interface VerifyAutoRenewingPlansContainergProps {
  activeVersion: string
  handleValidityChange: (isValid: boolean) => void
  plansList: string[]
  plansMap: Record<string, WellnessPlanAutoRenew>
  versionsList: string[]
  versionsMap: Record<string, WellnessPlanVersionAutoRenew>
}

export interface VerifyAutoRenewingPlansContainerHandle {
  get: () => void
}

export const VerifyAutoRenewingPlansContainer = forwardRef(
  (
    {
      handleValidityChange,
      plansMap,
      versionsList,
      versionsMap,
      plansList,
      activeVersion,
    }: VerifyAutoRenewingPlansContainergProps,
    ref: ForwardedRef<VerifyAutoRenewingPlansContainerHandle>,
  ) => {
    const activePlans = versionsMap[activeVersion]?.plans

    const planValidator = ({ value, name }: CustomFieldValidatorState) =>
      value === name || activePlans.includes(value)

    const { fields, validate } = useFields(
      plansList.map((planKey: string) => ({
        name: planKey,
        initialValue: plansMap[planKey]?.autoRenewInto || planKey,
        type: 'select',
        validators: [
          {
            validator: planValidator,
            validatorName: 'planValidator',
          },
        ],
      })),
    )

    const validateTable = () => {
      handleValidityChange(validate())
    }

    useImperativeHandle(ref, () => ({
      get: () => {
        const valuesObj: Record<string, string> = getPluckedFieldsByFieldKey(
          fields,
          'value',
        )
        const mappedValues = Object.keys(valuesObj).map((planKey) => ({
          sourcePlanId: plansMap[planKey].id,
          targetPlanId: plansMap[valuesObj[planKey]].id,
        }))
        return mappedValues
      },
    }))

    useEffect(() => {
      validateTable()
    }, [])

    return (
      <VerifyAutoRenewingPlansTable
        fields={fields}
        plansMap={plansMap}
        versionsList={versionsList}
        versionsMap={versionsMap}
        onPlanSelect={validateTable}
      />
    )
  },
)
