import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { useVariationName } from '~/store/hooks/prescriptions'
import { Variation } from '~/types'

import ListItem, { ListItemProps } from './ListItem'

const useStyles = makeStyles(
  (theme) => ({
    variationItem: {
      padding: theme.spacing(0.5, 0),
    },
  }),
  { name: 'VariationListItem' },
)

export interface VariationListItemProps
  extends Omit<ListItemProps, 'onClick' | 'name' | 'obsolete'> {
  onClick: (name: string) => void
  variation: Variation
}

const VariationListItem = ({
  variation,
  onClick,
  ...rest
}: VariationListItemProps) => {
  const classes = useStyles()

  const variationName = useVariationName(variation)

  const variationFlavor = variation.flavor ? ` | (${variation.flavor})` : ''

  const variationsCount = variation.businessVariationIds
    ? variation.businessVariationIds.length
    : 0

  const variationsCountStr = variationsCount > 0 ? ` (${variationsCount})` : ''

  const nameString = `${variationName}${variationFlavor}${variationsCountStr}`
  return (
    <ListItem
      wrapDescription
      className={classes.variationItem}
      key={variation.id}
      name={nameString}
      onClick={() => onClick(variationName)}
      {...rest}
      obsolete={variation.obsolete}
    />
  )
}

export default VariationListItem
