import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton, TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  LanguageUtils,
  PermissionArea,
  PuiTextArea,
  Text,
  Utils,
  WellnessPlan,
  WellnessPlanBenefit as WellnessPlanBenefitType,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useValidatePlanBenefits } from '~/store/hooks/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

import WellnessPlanBenefitLimitConfiguration from '../../WellnessPlanBenefitLimitConfiguration'
import {
  DEFAULT_DISCOUNT_PERCENT,
  getBenefitCoversAppointmentTypes,
  getBenefitCoversCatalog,
  getBenefitHasCoveredItems,
  getBenefitIsAccessToBoop,
} from '../../wellnessPlanUtils'
import { WellnessPlanBenefitAppointmentType } from './WellnessPlanBenefitAppointmentType'
import { WellnessPlanBenefitCover } from './WellnessPlanBenefitCover'

const useStyles = makeStyles(
  (theme) => ({
    addButtonBorderRow: {
      borderTop: theme.constants.tableBorder,
    },
    addButtonRow: {
      padding: theme.spacing(1),
    },
    addTextError: {
      fontWeight: 500,
      color: theme.colors.errorColor,
    },
    benefitCell: {
      width: 185,
    },
    benefitLimitConfiguration: {
      marginTop: theme.spacing(1),
    },
    benefitNameText: {
      fontSize: '1.4rem',
    },
    deleteIconButton: {
      padding: 0,
    },
    descriptionCell: {
      padding: theme.spacing(1),
      width: 180,
    },
    plusButtonError: {
      backgroundColor: theme.colors.errorColor,
    },
    tableRow: {
      borderBottom: theme.constants.tableBorder,
    },
    tableBodyCell: {
      padding: 0,
    },
    tableCell: {
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tableBorder,
      },
      verticalAlign: 'top',
      textAlign: 'start',
    },
    textAreaInputMultiline: {
      margin: 0,
    },
  }),
  { name: 'WellnessPlanBenefits' },
)

export interface WellnessPlanBenefitProps {
  benefit: WellnessPlanBenefitType
  disableEdit: boolean
  hasActivePatients: boolean
  hasCoverItemsValidationError: boolean
  hasEnoughBenefits: boolean
  isBaseLevel: boolean
  onRemoveBenefit: (benefit: WellnessPlanBenefitType) => void
  onUpdateBenefit: (benefit: WellnessPlanBenefitType) => WellnessPlan
  setHasCoverItemsValidationError: React.Dispatch<React.SetStateAction<boolean>>
  showAppointmentTypesColumn: boolean
  showCoveragesColumn: boolean
}

export const WellnessPlanBenefit = ({
  benefit: benefitProp,
  disableEdit,
  hasActivePatients,
  hasCoverItemsValidationError,
  hasEnoughBenefits,
  isBaseLevel,
  setHasCoverItemsValidationError,
  showAppointmentTypesColumn,
  showCoveragesColumn,
  onRemoveBenefit: removeBenefit,
  onUpdateBenefit: updateBenefit,
}: WellnessPlanBenefitProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  const permissions = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN_CONFIG),
  )

  const isWplanAutomateDiscountEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WPLAN_AUTOMATE_DISCOUNT),
  )

  const [openSelectBenefitAppointmentTypeDialog] = useDialog(
    DialogNames.SELECT_BENEFIT_APPOINTMENT_TYPE,
  )
  const [openAddWellnessPlanCoverageItemsDialog] = useDialog(
    DialogNames.ADD_WELLNESS_PLAN_COVERAGE_ITEMS,
  )
  const validatePlanBenefits = useValidatePlanBenefits()

  const hasAppointmentCover = getBenefitCoversAppointmentTypes(benefitProp)
  const hasCatalogCover = getBenefitCoversCatalog(benefitProp)
  const hasItems = getBenefitHasCoveredItems(benefitProp)
  const isAccessToBoop = getBenefitIsAccessToBoop(benefitProp)
  const canDeleteBenefit = (isBaseLevel || hasEnoughBenefits) && !isAccessToBoop
  const hasError = hasCoverItemsValidationError && !hasItems

  const handleAddAppointmentTypes = (benefit: WellnessPlanBenefitType) => {
    openSelectBenefitAppointmentTypeDialog({
      benefit,
      onSelected: (appointmentTypeIds) => {
        const updatedPlan = updateBenefit({
          ...benefit,
          appointmentTypeIds,
        })

        if (hasCoverItemsValidationError) {
          const benefitsValid = validatePlanBenefits(updatedPlan)

          setHasCoverItemsValidationError(!benefitsValid)
        }
      },
    })
  }

  const handleAddCatalogItem = (benefit: WellnessPlanBenefitType) => {
    openAddWellnessPlanCoverageItemsDialog({
      title: t('WellnessPlans:ADD_WHAT_IS_COVERED_FOR_BENEFIT', {
        benefitName: benefit.name,
      }),
      onSave: (cover) => {
        const updatedCover = cover.map((item) => ({
          ...item,
          ...(isWplanAutomateDiscountEnabled && benefit.discountApplied
            ? { coverPercent: Number(DEFAULT_DISCOUNT_PERCENT) / 100 }
            : {}),
        }))
        const updatedPlan = updateBenefit({
          ...benefit,
          cover: R.uniq(benefit.cover.concat(updatedCover)),
        })

        if (hasCoverItemsValidationError) {
          const benefitsValid = validatePlanBenefits(updatedPlan)

          setHasCoverItemsValidationError(!benefitsValid)
        }
      },
    })
  }

  const handleChangeDescription = Utils.handleFormTextInput((value) => {
    updateBenefit({
      ...benefitProp,
      description: value,
    })
  })

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classNames(classes.tableCell, classes.benefitCell)}>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Text className={classes.benefitNameText} variant="h2">
            {LanguageUtils.getTranslatedFieldName(benefitProp)}
          </Text>
          {canDeleteBenefit && !disableEdit && (
            <IconButton
              aria-label="delete"
              className={classes.deleteIconButton}
              size="large"
              onClick={() => removeBenefit(benefitProp)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        <WellnessPlanBenefitLimitConfiguration
          benefit={benefitProp}
          className={classes.benefitLimitConfiguration}
          disableEdit={disableEdit}
          onUpdateBenefit={updateBenefit}
        />
      </TableCell>
      <TableCell
        className={classNames(
          classes.tableCell,
          classes.tableBodyCell,
          classes.descriptionCell,
        )}
      >
        <PuiTextArea
          InputProps={{
            classes: {
              multiline: classes.textAreaInputMultiline,
            },
          }}
          disabled={!permissions.update}
          margin="none"
          value={benefitProp.description || ''}
          onChange={handleChangeDescription}
        />
      </TableCell>
      {showAppointmentTypesColumn && (
        <TableCell
          className={classNames(classes.tableCell, classes.tableBodyCell)}
        >
          <Grid
            container
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Grid item>
              {benefitProp.appointmentTypeIds?.map((appointmentTypeId) => (
                <WellnessPlanBenefitAppointmentType
                  appointmentTypeId={appointmentTypeId}
                  benefit={benefitProp}
                  disableEdit={disableEdit}
                  key={appointmentTypeId}
                  updateBenefit={updateBenefit}
                />
              ))}
            </Grid>
            {hasAppointmentCover && permissions.update && (
              <Grid
                container
                item
                className={classNames(classes.addButtonRow, {
                  [classes.addButtonBorderRow]: hasItems,
                })}
              >
                <AddButton
                  addText={`${t('Common:ADD_APPOINTMENT_TYPES')}${
                    hasError ? '*' : ''
                  }`}
                  classes={{
                    addText: classNames({
                      [classes.addTextError]: hasError,
                    }),
                    plusButton: classNames({
                      [classes.plusButtonError]: hasError,
                    }),
                  }}
                  onAdd={() => handleAddAppointmentTypes(benefitProp)}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      )}
      {showCoveragesColumn && (
        <TableCell
          className={classNames(classes.tableCell, classes.tableBodyCell)}
        >
          <Grid
            container
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Grid item>
              {benefitProp.cover?.map((coverage) => (
                <WellnessPlanBenefitCover
                  benefit={benefitProp}
                  coverage={coverage}
                  hasActivePatients={hasActivePatients}
                  key={`${coverage.id}_${coverage.itemId}`}
                  updateBenefit={updateBenefit}
                />
              ))}
            </Grid>
            {hasCatalogCover && permissions.update && (
              <Grid
                container
                item
                className={classNames(classes.addButtonRow, {
                  [classes.addButtonBorderRow]: hasItems,
                })}
              >
                <AddButton
                  addText={`${t('Common:ADD_CATALOG_ITEM')}${
                    hasError ? '*' : ''
                  }`}
                  classes={{
                    addText: classNames({
                      [classes.addTextError]: hasError,
                    }),
                    plusButton: classNames({
                      [classes.plusButtonError]: hasError,
                    }),
                  }}
                  onAdd={() => handleAddCatalogItem(benefitProp)}
                />
              </Grid>
            )}
          </Grid>
        </TableCell>
      )}
    </TableRow>
  )
}
