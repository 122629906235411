import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  GridWrap,
  Hidden,
  IconButton,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CurrencyTextField,
  CustomFieldValidatorState,
  Field,
  Nil,
  PuiCheckbox,
  PuiSelect,
  PuiTextField,
  PuiTooltip,
  RoleName,
  SelectEntity,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Info as InfoIcon,
} from '@pbt/pbt-ui-components/src/icons'

import PercentTextField from '~/components/common/inputs/PercentTextField'
import TaxRateSelect from '~/components/common/inputs/TaxRateSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { useGetAutoCalculatedPrice } from '~/store/hooks/variations'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getVariationIsLoadingPartNumberDetails,
  getVariationPartNumberDetails,
} from '~/store/reducers/variations'
import { DataHandleWithReset, Price } from '~/types'
import { handleNumberInput } from '~/utils'
import useHasRole from '~/utils/useHasRole'

import {
  getPriceUnitsIdFromValue,
  getPriceUnitsValuedFromId,
  isPluralUnit,
} from '../inventoryUtils'
import VariationCost from '../variation/VariationCost'
import VariationPriceUnit from '../variation/VariationPriceUnit'

const useStyles = makeStyles(
  (theme) => ({
    divider: {
      alignSelf: 'center',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    priceTopLeftItem: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(1),
    },
    deleteContainer: {
      borderLeft: theme.constants.tabBorder,
    },
    deleteIconButton: {
      padding: theme.spacing(1),
    },
    priceContainer: {
      border: theme.constants.tabBorder,
    },
    syncWithChewyPriceInfoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginLeft: theme.spacing(-1),
    },
    codeInfoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginTop: theme.spacing(2.5),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'VariationPriceBlock' },
)

const getPrice = (priceFields: Record<string, Field>) => {
  const getUnitSize = (unitValue: string, fieldName: string) =>
    isPluralUnit(unitValue) ? priceFields[fieldName].value : 1

  const markupPercent =
    priceFields.markupPercent.value === null
      ? null
      : Utils.round(priceFields.markupPercent.value / 100, 5)
  const id = priceFields.id.value
  const cost = priceFields.cost.value
  const costTypeId = priceFields.costTypeId.value

  const price = priceFields.price.value
  const priceUnitsValue = priceFields.unitId.value
  const priceUnitsSize = getUnitSize(priceUnitsValue, 'priceUnitsSize')
  const syncWithExternalPrice = priceFields.syncWithExternalPrice.value

  const dispensingFee = priceFields.dispensingFee.value
  const minCharge = priceFields.priceMinCharge.value
  const discountAllowed = priceFields.discountAllowed.value
  const taxable = priceFields.taxable.value
  const taxes = priceFields.taxes.value
  const name = priceFields.name.value
  const business = priceFields.business.value
  const originalCode = priceFields.originalCode.value
  const unitId = getPriceUnitsIdFromValue(priceUnitsValue)
  const newPrice: Price = {
    price,
    unitId,
    dispensingFee,
    priceUnitsSize,
    discountAllowed,
    minCharge,
    taxable,
    taxes,
    name,
    cost,
    costUnitsId: unitId,
    costUnitsSize: priceUnitsSize,
    markupPercent,
    business,
    costTypeId,
    id,
    syncWithExternalPrice,
    originalCode,
  }

  return newPrice
}

export interface VariationPriceBlockHandle extends DataHandleWithReset<Price> {}

export interface VariationPriceBlockProps {
  canBeRemoved?: boolean
  count?: number | Nil
  onPriceRemoved: () => void
  packageTypeId: string
  perPackageUnitsId: string
  price: Omit<Price, 'id'> & { id?: string }
  saleUnitOfMeasure?: string | Nil
  wrap?: GridWrap
}

const VariationPriceBlock = forwardRef<
  VariationPriceBlockHandle,
  VariationPriceBlockProps
>(function VariationPriceBlock(
  {
    canBeRemoved = false,
    price: priceProp,
    wrap = 'nowrap',
    perPackageUnitsId,
    packageTypeId,
    onPriceRemoved,
    saleUnitOfMeasure,
    count,
  },
  ref,
) {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation(['Admin', 'Common'])

  const [isCostInitialized, setIsCostInitialized] = useState(false)

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSyncWithExternalPriceEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PRICE_MATCH),
  )
  const variationPartNumberDetails = useSelector(getVariationPartNumberDetails)
  const isLoadingVariationPartNumberDetails = useSelector(
    getVariationIsLoadingPartNumberDetails,
  )

  const isPriceMatchCapable =
    isCurrentBusinessOmniChannel && isSyncWithExternalPriceEnabled

  const isUserPracticeAdmin = useHasRole(RoleName.PracticeAdministrator)

  const taxesValidator = ({ state, value }: CustomFieldValidatorState) =>
    !state.taxable || value.length > 0

  const codeSelectOptions: SelectEntity[] =
    variationPartNumberDetails?.map(({ code, name, nameTranslation }) => ({
      id: code,
      name: `${code} - ${name}`,
      nameTranslation: `${code} - ${nameTranslation ?? name}`,
    })) ?? []

  const originalCodeFromOptions = codeSelectOptions.find(
    (partNumber) => partNumber.id === priceProp.originalCode,
  )?.id

  const isMarkupPricingActive = Boolean(priceProp.markupPercent)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'id',
        initialValue: priceProp.id,
      },
      {
        name: 'price',
        label: t('Common:CLIENT_PRICE'),
        initialValue: priceProp.price || 0,
      },
      {
        name: 'unitId',
        label: t('Common:UNITS'),
        validators: ['required'],
        type: 'select',
        initialValue: priceProp.unitId
          ? getPriceUnitsValuedFromId(
              priceProp.unitId,
              Boolean(priceProp.priceUnitsSize && priceProp.priceUnitsSize > 1),
            )
          : '',
      },
      {
        name: 'syncWithExternalPrice',
        label: t('Admin:CATALOG.VARIATION_COST.SYNC_WITH_CHEWY_PRICE'),
        type: 'toggle',
        initialValue: priceProp.syncWithExternalPrice,
      },
      {
        name: 'priceUnitsSize',
        initialValue: priceProp.priceUnitsSize || '',
      },
      {
        name: 'cost',
        label: t('Common:COST'),
        initialValue: priceProp.cost || 0,
      },
      {
        name: 'costTypeId',
        label: t('Admin:CATALOG.VARIATION_COST.COST_CALCULATION'),
        type: 'select',
        initialValue: priceProp.costTypeId,
      },
      {
        name: 'useMarkupPricing',
        label: t('Admin:CATALOG.VARIATION_PRICE_BLOCK.USE_MARKUP_PRICING'),
        initialValue: isMarkupPricingActive,
      },
      {
        name: 'markupPercent',
        initialValue: R.isNil(priceProp.markupPercent)
          ? null
          : Utils.round(priceProp.markupPercent * 100, 2),
      },
      {
        name: 'name',
        label: t('Common:PRICE_NAME'),
        initialValue: priceProp.name,
      },
      {
        name: 'dispensingFee',
        label: t('Common:DISPENSING_FEE'),
        initialValue: priceProp.dispensingFee || 0,
      },
      {
        name: 'priceMinCharge',
        label: t('Common:MINIMUM_CHARGE'),
        initialValue: priceProp.minCharge || 0,
      },
      {
        name: 'discountAllowed',
        label: t('Common:ALLOW_DISCOUNT'),
        type: 'toggle',
        initialValue: priceProp.discountAllowed || false,
      },
      {
        name: 'taxable',
        label: t('Common:TAXABLE'),
        type: 'toggle',
        initialValue: priceProp.taxable || false,
      },
      {
        name: 'taxes',
        label: t('Common:SELECT_TAX_RATE'),
        type: 'select',
        validators: [
          { validator: taxesValidator, validatorName: 'taxesValidator' },
        ],
        messages: {
          taxesValidator: t('Validations:PLEASE_SELECT_TAX_RATE'),
        },
        initialValue: priceProp.taxes || [],
      },
      { name: 'business', initialValue: priceProp.business },
      {
        name: 'originalCode',
        label: t('Common:CODE'),
        type: isPriceMatchCapable ? 'select' : 'text',
        initialValue: isPriceMatchCapable
          ? originalCodeFromOptions
          : priceProp.originalCode,
      },
    ],
    false,
  )

  useImperativeHandle(ref, () => ({
    get: () => getPrice(fields),
    validate,
    reset,
  }))

  const {
    cost,
    costTypeId,
    discountAllowed,
    dispensingFee,
    markupPercent,
    name,
    price,
    priceMinCharge,
    priceUnitsSize,
    taxable,
    taxes,
    unitId,
    useMarkupPricing,
    originalCode,
    syncWithExternalPrice,
  } = fields

  const isClientPriceDisabled = useMarkupPricing.value
  const isMarkupPriceDisabled = isUserPracticeAdmin
    ? false
    : isPriceMatchCapable && syncWithExternalPrice.value

  const autoCalculatedCost = useGetAutoCalculatedPrice({
    costTypeId: costTypeId.value,
    unitId: unitId.value,
    priceUnitsSize: priceUnitsSize.value,
  })

  useEffect(() => {
    if (!isCostInitialized && autoCalculatedCost) {
      setIsCostInitialized(true)
      cost.setValue(autoCalculatedCost)
    }
  }, [autoCalculatedCost])

  useEffect(() => {
    if (isPriceMatchCapable && originalCodeFromOptions) {
      originalCode.setValue(originalCodeFromOptions)
    }
  }, [originalCodeFromOptions])

  useEffect(() => {
    const updatedMarkupPercentValue = useMarkupPricing.value
      ? markupPercent.value || 0
      : null

    markupPercent.setValue(updatedMarkupPercentValue)
  }, [useMarkupPricing.value])

  useEffect(() => {
    if (useMarkupPricing.value) {
      const markupPercentValue = markupPercent.value || 0
      const costValue = cost.value || 0
      const newPrice = (markupPercentValue / 100 + 1) * costValue
      price.setValue(Utils.round(newPrice, 2))
    }
  }, [useMarkupPricing.value, markupPercent.value, cost.value])

  const priceControlsArea = canBeRemoved && (
    <Grid item className={classes.deleteContainer}>
      <IconButton
        aria-label={t('Common:DELETE_ACTION')}
        className={classes.deleteIconButton}
        size="large"
        onClick={onPriceRemoved}
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  )

  return (
    <Grid container item className={classes.priceContainer} p={1} wrap="nowrap">
      <Grid container item direction="column">
        <Grid container item alignItems="center" wrap={wrap}>
          <Grid item className={classes.priceTopLeftItem} md={3} xs={12}>
            <CurrencyTextField
              disabled={isClientPriceDisabled}
              field={{
                ...price,
                set: handleNumberInput(
                  (value: string) => {
                    const formattedValue = parseFloat(value) || 0
                    price.setValue(formattedValue)
                  },
                  7,
                  2,
                  true,
                ),
              }}
              label={price.label}
            />
          </Grid>
          <Hidden mdDown>
            <div className={classes.divider}>/</div>
          </Hidden>
          <VariationPriceUnit
            count={count}
            fields={{
              unitId,
              priceUnitsSize,
            }}
            packageTypeId={packageTypeId}
            perPackageUnitsId={perPackageUnitsId}
            saleUnitOfMeasure={saleUnitOfMeasure}
          />
          {isPriceMatchCapable && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.priceTopLeftItem}
            >
              <PuiTooltip
                disabled={!useMarkupPricing.value}
                tooltipText={t(
                  'Tooltips:PRICE_MATCH_MARKUP_PRICING_ONLY_ONE_CAN_BE_ENABLED',
                )}
              >
                <PuiSwitch
                  disabled={!isUserPracticeAdmin}
                  field={{
                    ...syncWithExternalPrice,
                    set: () => {
                      if (!syncWithExternalPrice.value) {
                        useMarkupPricing.setValue(false)
                      }
                      syncWithExternalPrice.setValue(
                        !syncWithExternalPrice.value,
                      )
                    },
                  }}
                  label={syncWithExternalPrice.label}
                />
              </PuiTooltip>
              <PuiTooltip
                tooltipText={t('Tooltips:SYNC_WITH_CHEWY_PRICE_TOOLTIP')}
              >
                <InfoIcon className={classes.syncWithChewyPriceInfoIcon} />
              </PuiTooltip>
            </Grid>
          )}
        </Grid>
        <Grid container item className={classes.priceTopLeftItem} wrap={wrap}>
          <Grid container item md={6}>
            <PuiTextField
              field={name}
              inputProps={{ maxLength: 100 }}
              label={name.label}
            />
          </Grid>
          {!isPriceMatchCapable && (
            <Grid
              container
              item
              md={3}
              pl={isMobile ? 0 : 2}
              pr={1}
              wrap="nowrap"
            >
              <PuiTextField
                field={originalCode}
                inputProps={{ maxLength: 15 }}
                label={originalCode.label}
              />
              <PuiTooltip
                tooltipText={t(
                  'Tooltips:ALPHANUMERIC_IDENTIFIER_TO_SEARCH_FOR_ITEMS',
                )}
              >
                <InfoIcon className={classes.codeInfoIcon} />
              </PuiTooltip>
            </Grid>
          )}
          {isPriceMatchCapable && (
            <Grid
              container
              item
              md={6}
              pl={isMobile ? 0 : 2}
              pr={1}
              py={1}
              wrap="nowrap"
            >
              <PuiTooltip
                disabled={isUserPracticeAdmin || !syncWithExternalPrice.value}
                tooltipText={t(
                  'Tooltips:PRICE_CODE_CANNOT_BE_CHANGED_BY_NON_ADMIN_WHILE_PRICE_MATCH_ENABLED',
                )}
              >
                <FormControl fullWidth>
                  <Grid container>
                    <Grid item xs={9}>
                      <InputLabel htmlFor="code-type-select">
                        {t('Common:CODE')}
                      </InputLabel>
                      <PuiSelect
                        fullWidth
                        disabled={
                          (!isUserPracticeAdmin &&
                            syncWithExternalPrice.value) ||
                          isLoadingVariationPartNumberDetails
                        }
                        field={originalCode}
                        input={<Input id="code-type-select" />}
                        isLoading={isLoadingVariationPartNumberDetails}
                        items={codeSelectOptions}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <PuiTooltip
                        tooltipText={t(
                          'Tooltips:VARIATION_PRICE_CODE_DROPDOWN',
                        )}
                      >
                        <InfoIcon className={classes.codeInfoIcon} />
                      </PuiTooltip>
                    </Grid>
                  </Grid>
                </FormControl>
              </PuiTooltip>
            </Grid>
          )}
        </Grid>
        <VariationCost
          count={count}
          fields={{
            cost,
            costTypeId,
            priceUnitsSize,
            unitId,
          }}
          packageTypeId={packageTypeId}
          perPackageUnitsId={perPackageUnitsId}
          saleUnitOfMeasure={saleUnitOfMeasure}
          wrap={wrap}
        />
        <PuiTooltip
          disabled={!syncWithExternalPrice.value}
          tooltipText={
            isPriceMatchCapable
              ? t('Tooltips:PRICE_MATCH_MARKUP_PRICING_ONLY_ONE_CAN_BE_ENABLED')
              : ''
          }
        >
          <Grid container item>
            <Grid item className={classes.priceTopLeftItem}>
              <PuiCheckbox
                disabled={
                  isPriceMatchCapable &&
                  !isUserPracticeAdmin &&
                  syncWithExternalPrice.value
                }
                field={{
                  ...useMarkupPricing,
                  set: () => {
                    if (!useMarkupPricing.value) {
                      syncWithExternalPrice.setValue(false)
                    }
                    useMarkupPricing.setValue(!useMarkupPricing.value)
                  },
                }}
                label={useMarkupPricing.label}
              />
            </Grid>
            <Grid item>
              <PercentTextField
                disabled={isMarkupPriceDisabled}
                field={markupPercent}
                fullWidth={false}
                highLimit={9999}
              />
            </Grid>
          </Grid>
        </PuiTooltip>
        <Grid
          container
          item
          alignItems="flex-end"
          direction="column"
          px={1}
          wrap={wrap}
        >
          <Grid container item columnSpacing={1}>
            <Grid item xs={6}>
              <CurrencyTextField
                field={priceMinCharge}
                label={priceMinCharge.label}
              />
            </Grid>
            <Grid item xs={6}>
              <CurrencyTextField
                field={dispensingFee}
                label={dispensingFee.label}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" mt={1}>
            <Grid item>
              <PuiSwitch
                field={discountAllowed}
                label={discountAllowed.label}
              />
            </Grid>
            <Grid
              container
              item
              alignItems="flex-end"
              columnSpacing={1}
              minHeight={50}
              wrap="nowrap"
            >
              <Grid item>
                <PuiSwitch field={taxable} label={taxable.label} />
              </Grid>
              {taxable.value && (
                <Grid item width={300}>
                  <TaxRateSelect field={taxes} taxableField={taxable} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {priceControlsArea}
    </Grid>
  )
})

export default VariationPriceBlock
