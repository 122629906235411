import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, TableFilter } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { BaseTableFilters } from '~/types'

import {
  FETCH_INVENTORY_ORDER,
  FETCH_INVENTORY_ORDERS,
  FETCH_MORE_INVENTORY_ORDERS,
  fetchInventoryOrder,
  fetchInventoryOrderFailure,
  fetchInventoryOrders,
  fetchInventoryOrdersFailure,
  fetchInventoryOrdersSuccess,
  fetchInventoryOrderSuccess,
  fetchMoreInventoryOrders,
  fetchMoreInventoryOrdersFailure,
  fetchMoreInventoryOrdersSuccess,
  getInventoryOrdersFilters,
} from '../duck/inventoryOrders'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const serializeFilters = (filters: Record<string, TableFilter>) =>
  Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]: filters[key]?.value,
    }),
    {} as Record<string, TableFilter['value']>,
  )

function* fetchInventoryOrdersSaga({
  from,
  to,
}: ReturnType<typeof fetchInventoryOrders>) {
  try {
    const filters: BaseTableFilters = yield select(getInventoryOrdersFilters) ||
      {}

    const {
      result: { data, totalCount },
      entities: { inventoryOrders },
    } = yield requestAPI(
      API.fetchInventoryOrders,
      from,
      to,
      serializeFilters(filters),
    )

    yield call(updateEntities, {
      inventoryOrders,
    })
    yield put(fetchInventoryOrdersSuccess(data, totalCount))
  } catch (error) {
    yield put(fetchInventoryOrdersFailure(error as ApiError))
  }
}

function* fetchMoreInventoryOrdersSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreInventoryOrders>) {
  try {
    const filters: BaseTableFilters = yield select(getInventoryOrdersFilters) ||
      {}

    const {
      result: { data, totalCount },
      entities: { inventoryOrders },
    } = yield requestAPI(
      API.fetchInventoryOrders,
      from,
      to,
      serializeFilters(filters),
    )

    yield call(updateEntities, {
      inventoryOrders,
    })
    yield put(fetchMoreInventoryOrdersSuccess(data, totalCount, from))
  } catch (error) {
    yield put(fetchMoreInventoryOrdersFailure(error as ApiError))
  }
}

export function* fetchInventoryOrderSaga({
  orderId,
}: ReturnType<typeof fetchInventoryOrder>) {
  try {
    const { entities } = yield requestAPI(API.fetchInventoryOrder, orderId)

    yield call(updateEntities, entities)
    yield put(fetchInventoryOrderSuccess(orderId))
  } catch (error) {
    yield put(fetchInventoryOrderFailure(error as ApiError))
  }
}

function* watchFetchInventoryOrders() {
  yield takeLatest(FETCH_INVENTORY_ORDERS, fetchInventoryOrdersSaga)
}

function* watchFetchMoreInventoryOrders() {
  yield takeLatest(FETCH_MORE_INVENTORY_ORDERS, fetchMoreInventoryOrdersSaga)
}

function* watchFetchInventoryOrder() {
  yield takeLatest(FETCH_INVENTORY_ORDER, fetchInventoryOrderSaga)
}

export default function* InventoryOrdersSaga() {
  yield all([
    watchFetchInventoryOrders(),
    watchFetchMoreInventoryOrders(),
    watchFetchInventoryOrder(),
  ])
}
