import { AnyAction } from 'redux'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  ReminderCommunicationTemplatePreviewInput,
  ReminderProtocolSettings,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import { updateBusinesses } from '../actions/businesses'
import type { RootState } from '../index'
import { getCurrentBusinessId } from '../reducers/auth'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_REMINDERS_SETTINGS =
  'reminderProtocols/FETCH_REMINDERS_SETTINGS'
export const FETCH_REMINDERS_SETTINGS_SUCCESS =
  'reminderProtocols/FETCH_REMINDERS_SETTINGS_SUCCESS'
export const FETCH_REMINDERS_SETTINGS_FAILURE =
  'reminderProtocols/FETCH_REMINDERS_SETTINGS_FAILURE'

export const UPDATE_REMINDERS_SETTINGS =
  'reminderProtocols/UPDATE_REMINDERS_SETTINGS'
export const UPDATE_REMINDERS_SETTINGS_SUCCESS =
  'reminderProtocols/UPDATE_REMINDERS_SETTINGS_SUCCESS'
export const UPDATE_REMINDERS_SETTINGS_FAILURE =
  'reminderProtocols/UPDATE_REMINDERS_SETTINGS_FAILURE'

export const FETCH_REMINDER_SETTINGS_TEMPLATE =
  'reminderProtocols/FETCH_REMINDER_SETTINGS_TEMPLATE'
export const FETCH_REMINDER_SETTINGS_TEMPLATE_SUCCESS =
  'reminderProtocols/FETCH_REMINDER_SETTINGS_TEMPLATE_SUCCESS'
export const FETCH_REMINDER_SETTINGS_TEMPLATE_FAILURE =
  'reminderProtocols/FETCH_REMINDER_SETTINGS_TEMPLATE_FAILURE'

export const FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE =
  'reminderProtocols/FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE'
export const FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_SUCCESS =
  'reminderProtocols/FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_SUCCESS'
export const FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_FAILURE =
  'reminderProtocols/FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_FAILURE'

export const FETCH_REMINDER_TEMPLATE_PREVIEW =
  'reminderProtocols/FETCH_REMINDER_TEMPLATE_PREVIEW'
export const FETCH_REMINDER_TEMPLATE_PREVIEW_SUCCESS =
  'reminderProtocols/FETCH_REMINDER_TEMPLATE_PREVIEW_SUCCESS'
export const FETCH_REMINDER_TEMPLATE_PREVIEW_FAILURE =
  'reminderProtocols/FETCH_REMINDER_TEMPLATE_PREVIEW_FAILURE'

export const fetchRemindersSettings = (businessId: string) => ({
  type: FETCH_REMINDERS_SETTINGS,
  businessId,
})
export const fetchRemindersSettingsSuccess = (
  businessId: string,
  settings: ReminderProtocolSettings,
) => ({
  type: FETCH_REMINDERS_SETTINGS_SUCCESS,
  businessId,
  settings,
})
export const fetchRemindersSettingsFailure = (error: ApiError) => ({
  type: FETCH_REMINDERS_SETTINGS_FAILURE,
  error,
})

export const updateRemindersSettings = (
  businessId: string,
  settings: ReminderProtocolSettings,
) => ({
  type: UPDATE_REMINDERS_SETTINGS,
  businessId,
  settings,
})
export const updateRemindersSettingsSuccess = (
  businessId: string,
  settings: ReminderProtocolSettings,
) => ({
  type: UPDATE_REMINDERS_SETTINGS_SUCCESS,
  businessId,
  settings,
})
export const updateRemindersSettingsFailure = (error: ApiError) => ({
  type: UPDATE_REMINDERS_SETTINGS_FAILURE,
  error,
})

export const fetchReminderSettingsTemplate = (
  businessId: string,
  templateId: string,
) => ({
  type: FETCH_REMINDER_SETTINGS_TEMPLATE,
  businessId,
  templateId,
})
export const fetchReminderSettingsTemplateSuccess = (template: string) => ({
  type: FETCH_REMINDER_SETTINGS_TEMPLATE_SUCCESS,
  template,
})
export const fetchReminderSettingsTemplateFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_SETTINGS_TEMPLATE_FAILURE,
  error,
})

export const fetchCurrentReminderSettingsTemplate = (
  businessId: string,
  text: string,
) => ({
  type: FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE,
  businessId,
  text,
})
export const fetchCurrentReminderSettingsTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_SUCCESS,
  template,
})
export const fetchCurrentReminderSettingsTemplateFailure = (
  error: ApiError,
) => ({
  type: FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_FAILURE,
  error,
})

export const fetchReminderTemplatePreview = (
  businessId: string,
  template: ReminderCommunicationTemplatePreviewInput,
) => ({
  type: FETCH_REMINDER_TEMPLATE_PREVIEW,
  businessId,
  template,
})
export const fetchReminderTemplatePreviewSuccess = (
  email: string,
  textMessage: string,
) => ({
  type: FETCH_REMINDER_TEMPLATE_PREVIEW_SUCCESS,
  email,
  textMessage,
})
export const fetchReminderTemplatePreviewFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_TEMPLATE_PREVIEW_FAILURE,
  error,
})

export type ReminderProtocolSettingsState = {
  emailMessagePreview: string | null
  error: string | null
  isFetchingPreview: boolean
  isSettingsLoading: boolean
  isTemplateLoading: boolean
  settingsMap: Record<string, ReminderProtocolSettings>
  template: string | undefined
  textMessagePreview: string | null
}

const INITIAL_STATE: ReminderProtocolSettingsState = {
  template: undefined,
  settingsMap: {},
  isTemplateLoading: false,
  isSettingsLoading: false,
  error: null,
  isFetchingPreview: false,
  emailMessagePreview: null,
  textMessagePreview: null,
}

export const reminderProtocolSettingsReducer = (
  state: ReminderProtocolSettingsState = INITIAL_STATE,
  action: AnyAction,
): ReminderProtocolSettingsState => {
  switch (action.type) {
    case FETCH_REMINDER_TEMPLATE_PREVIEW:
      return {
        ...state,
        isFetchingPreview: true,
        error: null,
      }
    case FETCH_REMINDER_TEMPLATE_PREVIEW_SUCCESS:
      return {
        ...state,
        isFetchingPreview: false,
        emailMessagePreview: action.email,
        textMessagePreview: action.textMessage,
      }
    case FETCH_REMINDER_TEMPLATE_PREVIEW_FAILURE:
      return {
        ...state,
        isFetchingPreview: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REMINDERS_SETTINGS:
      return {
        ...state,
        isSettingsLoading: true,
        error: null,
      }
    case FETCH_REMINDERS_SETTINGS_SUCCESS:
      return {
        ...state,
        isSettingsLoading: false,
        settingsMap: {
          ...state.settingsMap,
          [action.businessId]: action.settings,
        },
      }
    case FETCH_REMINDERS_SETTINGS_FAILURE:
      return {
        ...state,
        isSettingsLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_REMINDERS_SETTINGS:
      return {
        ...state,
        isSettingsLoading: true,
        error: null,
      }
    case UPDATE_REMINDERS_SETTINGS_SUCCESS:
      return {
        ...state,
        isSettingsLoading: false,
        settingsMap: {
          ...state.settingsMap,
          [action.businessId]: action.settings,
        },
      }
    case UPDATE_REMINDERS_SETTINGS_FAILURE:
      return {
        ...state,
        isSettingsLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REMINDER_SETTINGS_TEMPLATE:
      return {
        ...state,
        isTemplateLoading: true,
        error: null,
      }
    case FETCH_REMINDER_SETTINGS_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateLoading: false,
        template: action.template,
      }
    case FETCH_REMINDER_SETTINGS_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE:
      return {
        ...state,
        isTemplateLoading: true,
        error: null,
      }
    case FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateLoading: false,
        template: action.template,
      }
    case FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

const getReminderProtocolSettings = (
  state: RootState,
): ReminderProtocolSettingsState => state.reminderProtocolSettings
export const getIsPreviewFetching = (state: RootState) =>
  getReminderProtocolSettings(state).isFetchingPreview
export const getReminderPreviewEmail = (state: RootState) =>
  getReminderProtocolSettings(state).emailMessagePreview
export const getReminderPreviewTextMessage = (state: RootState) =>
  getReminderProtocolSettings(state).textMessagePreview
export const getIsSettingsLoading = (state: RootState) =>
  getReminderProtocolSettings(state).isSettingsLoading
export const getRemindersSettings = (id: string) =>
  createSelector(
    getReminderProtocolSettings,
    (settings) => settings.settingsMap[id],
  )
export const getIsTemplateLoading = (state: RootState) =>
  getReminderProtocolSettings(state).isTemplateLoading
export const getSettingsTemplate = (state: RootState) =>
  getReminderProtocolSettings(state).template

export function* fetchReminderTemplatePreviewSaga({
  businessId,
  template,
}: ReturnType<typeof fetchReminderTemplatePreview>) {
  try {
    const { email, textMessage } = yield* requestAPI(
      API.fetchReminderTemplatePreviewV2,
      businessId,
      template,
    )
    yield put(fetchReminderTemplatePreviewSuccess(email, textMessage))
  } catch (error) {
    yield put(fetchReminderTemplatePreviewFailure(error as ApiError))
  }
}

export function* fetchRemindersSettingsSaga({
  businessId,
}: ReturnType<typeof fetchRemindersSettings>) {
  try {
    const settings: ReminderProtocolSettings = yield* requestAPI(
      API.fetchRemindersSettingsV2,
      businessId,
    )
    yield put(fetchRemindersSettingsSuccess(businessId, settings))
  } catch (error) {
    yield put(fetchRemindersSettingsFailure(error as ApiError))
  }
}

export function* updateRemindersSettingsSaga({
  businessId,
  settings,
}: ReturnType<typeof updateRemindersSettings>) {
  try {
    const updatedSettings: ReminderProtocolSettings = yield* requestAPI(
      API.updateRemindersSettingsV2,
      businessId,
      settings,
    )
    const currentBusinessId: string = yield select(getCurrentBusinessId)
    // update sendReminders and defaultReminderExpirationOffset in current business to have it in sync
    if (currentBusinessId === businessId) {
      yield put(
        updateBusinesses({
          [businessId]: {
            sendReminders: updatedSettings.active,
            defaultReminderExpirationOffset:
              updatedSettings.defaultReminderExpirationOffset,
          },
        }),
      )
    }
    yield put(updateRemindersSettingsSuccess(businessId, updatedSettings))
  } catch (error) {
    yield put(updateRemindersSettingsFailure(error as ApiError))
  }
}

export function* fetchReminderSettingsTemplateSaga({
  businessId,
  templateId,
}: ReturnType<typeof fetchReminderSettingsTemplate>) {
  try {
    const template: string = yield requestAPI(
      API.fetchReminderSettingsTemplate,
      businessId,
      templateId,
    )
    yield put(fetchReminderSettingsTemplateSuccess(template))
  } catch (error) {
    yield put(fetchReminderSettingsTemplateFailure(error as ApiError))
  }
}

export function* fetchCurrentReminderSettingsTemplateSaga({
  businessId,
  text,
}: ReturnType<typeof fetchCurrentReminderSettingsTemplate>) {
  try {
    const template: string = yield requestAPI(
      API.fetchCurrentReminderSettingsTemplate,
      businessId,
      text,
    )
    yield put(fetchCurrentReminderSettingsTemplateSuccess(template))
  } catch (error) {
    yield put(fetchCurrentReminderSettingsTemplateFailure(error as ApiError))
  }
}

function* watchFetchReminderTemplatePreview() {
  yield takeLatest(
    FETCH_REMINDER_TEMPLATE_PREVIEW,
    fetchReminderTemplatePreviewSaga,
  )
}

function* watchFetchReminderSettings() {
  yield takeLatest(FETCH_REMINDERS_SETTINGS, fetchRemindersSettingsSaga)
}

function* watchUpdateReminderSettings() {
  yield takeLatest(UPDATE_REMINDERS_SETTINGS, updateRemindersSettingsSaga)
}

function* watchFetchReminderSettingsTemplate() {
  yield takeLatest(
    FETCH_REMINDER_SETTINGS_TEMPLATE,
    fetchReminderSettingsTemplateSaga,
  )
}

function* watchFetchCurrentReminderSettingsTemplate() {
  yield takeLatest(
    FETCH_CURRENT_REMINDER_SETTINGS_TEMPLATE,
    fetchCurrentReminderSettingsTemplateSaga,
  )
}

export function* reminderProtocolSettingsSaga() {
  yield all([
    watchFetchReminderSettings(),
    watchUpdateReminderSettings(),
    watchFetchReminderSettingsTemplate(),
    watchFetchCurrentReminderSettingsTemplate(),
    watchFetchReminderTemplatePreview(),
  ])
}
