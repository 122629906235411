import { DateUtils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export interface DymoPracticeAddress {
  address?: string
  city?: string
  name?: string
  phone?: string
  state?: string
  suite?: string
  zipcode?: string
}

export interface DymoPatient {
  patientAge?: string
  patientBreed?: string
  patientGender?: string
  patientName?: string
  patientSpecies?: string
}

export interface DymoClient {
  clientAddress?: string
  clientName?: string
  clientPhone?: string
}

export interface DymoFolder extends DymoPatient, DymoClient {}

export interface DymoPrescription {
  bottomWarning?: string
  clientName?: string
  doctorDeaNumber?: string
  doctorName?: string
  drugExpiresDate?: string
  drugForm?: string
  drugName?: string
  drugQuantity?: string
  drugStrength?: string
  filledDate?: string
  instructions?: string
  patientName?: string
  patientSpecies?: string
  practice?: DymoPracticeAddress
  prescriptionNumber?: string
  refillDate?: string
  refillsNumber?: string
}

export interface DymoCageCard extends DymoPatient, DymoClient {
  patientAlert?: string
}

export interface DymoLabOrder extends DymoPatient {
  clientLastName?: string
  date?: string
  orderNumber?: string
  vendorName?: string
}

export enum DymoLabelType {
  PRESCRIPTION = 'prescription',
  CAGE_CARD = 'cageCard',
  CAGE_CARD_ALERT = 'cageCardAlert',
  LAB_ORDER = 'labOrder',
  FOLDER = 'folder',
  PRACTICE_ADDRESS = 'practiceAddress',
}

export enum DymoLabelHtmlTypes {
  PRESCRIPTION = 'prescription',
  CAGE_CARD = 'cageCard',
  LAB_ORDER = 'labOrder',
}

const PracticePlaceholder: DymoPracticeAddress = {
  name: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PRACTICE_NAME'),
  address: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PRACTICE_ADDRESS'),
  city: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PRACTICE_CITY'),
  state: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PRACTICE_STATE'),
  zipcode: '98119',
  phone: '(425) 218-0927',
}

const ClientPlaceholder: DymoClient = {
  clientName: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.CLIENT_NAME'),
  clientAddress: i18n.t(
    'Constants:LABEL_CONFIGURATION.PLACEHOLDER.CLIENT_ADDRESS',
  ),
  clientPhone: '(239) 928-1982',
}

const PatientPlaceholder: DymoPatient = {
  patientName: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_NAME'),
  patientGender: i18n.t(
    'Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_GENDER',
  ),
  patientAge: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_AGE'),
  patientSpecies: i18n.t(
    'Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_SPECIES',
  ),
  patientBreed: i18n.t(
    'Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_BREED',
  ),
}

export const DymoLabelPreviews = {
  [DymoLabelType.FOLDER]: {
    ...PatientPlaceholder,
    ...ClientPlaceholder,
  },
  [DymoLabelType.PRESCRIPTION]: {
    practice: PracticePlaceholder,
    doctorName: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.DOCTOR_NAME'),
    doctorDeaNumber: '09238763-27',
    prescriptionNumber: '0192837-102938',
    filledDate: DateUtils.formatDate('2019-06-18'),
    clientName: ClientPlaceholder.clientName,
    patientName: PatientPlaceholder.patientName,
    patientSpecies: PatientPlaceholder.patientSpecies,
    drugName: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.DRUG_NAME'),
    drugStrength: '100mg',
    drugForm: i18n.t('Constants:LABEL_CONFIGURATION.PLACEHOLDER.DRUG_FORM'),
    drugQuantity: '60',
    drugExpiresDate: DateUtils.formatDate('2022-06-18'),
    instructions: i18n.t(
      'Constants:LABEL_CONFIGURATION.PLACEHOLDER.DRUG_INSTRUCTIONS',
    ),
    refillsNumber: '2',
    refillDate: DateUtils.formatDate('2020-08-12'),
    bottomWarning: i18n.t(
      'Constants:LABEL_CONFIGURATION.PLACEHOLDER.BOTTOM_WARNING',
    ),
  },
  [DymoLabelType.PRACTICE_ADDRESS]: {
    ...PracticePlaceholder,
  },
  [DymoLabelType.CAGE_CARD]: {
    patientAlert: i18n.t(
      'Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_ALERT',
    ),
    ...PatientPlaceholder,
    ...ClientPlaceholder,
  },
  [DymoLabelType.CAGE_CARD_ALERT]: {
    patientAlert: i18n.t(
      'Constants:LABEL_CONFIGURATION.PLACEHOLDER.PATIENT_ALERT',
    ),
    ...PatientPlaceholder,
    ...ClientPlaceholder,
  },
  [DymoLabelType.LAB_ORDER]: {
    vendorName: 'IDEXX',
    orderNumber: '0192837-102938',
    date: DateUtils.formatDate('2020-08-12'),
    clientLastName: i18n.t(
      'Constants:LABEL_CONFIGURATION.PLACEHOLDER.CLIENT_LAST_NAME',
    ),
    ...PatientPlaceholder,
  },
}

export const LABEL_WARNING = i18n.t('Utils:WARNING_LABEL')
