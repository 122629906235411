import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiAlertProps, Text, User } from '@pbt/pbt-ui-components'

import { NoShowAlertText } from '~/components/dashboard/timetable/scheduler/appointment/NoShowAlertText'
import { ConfirmAlertType } from '~/constants/DialogNames'

import useConfirmAlert from './useConfirmAlert'

interface OpenConfirmAppointmentNoShowAlertProps
  extends Omit<PuiAlertProps, 'open'> {
  client?: Partial<User>
  isHighValueAppointment?: boolean
  onConfirm: (proceed: boolean, key?: React.Key | null) => void
}

type UseConfirmAppointmentNoShowAlertReturnType = [
  (props: OpenConfirmAppointmentNoShowAlertProps) => void,
  () => void,
  boolean,
]

const useStyles = makeStyles(
  (theme) => ({
    noShowAlertContainer: {
      minWidth: 434,
      textAlign: 'center',
      fontSize: '1.4rem',
      padding: theme.spacing(3, 2),
    },
    noShowAlertButton: {
      fontSize: '1.4rem',
    },
    noShowAlertMessage: {
      fontWeight: 500,
      fontSize: '1.4rem',
    },
  }),
  { name: 'AppointmentNoShowAlertDialog' },
)

const useConfirmAppointmentNoShowAlert =
  (): UseConfirmAppointmentNoShowAlertReturnType => {
    const { t } = useTranslation(['Common', 'Dialogs'])
    const classes = useStyles()
    const [openConfirmAlert, closeAlert, isOpen] = useConfirmAlert({
      preventShowAgainCheckBox: false,
      type: ConfirmAlertType.HIGH_VALUE_APPOINTMENT_NO_SHOW,
    })

    const openConfirmAppointmentNoShowAlert = ({
      onConfirm,
      isHighValueAppointment,
      client,
      ...rest
    }: OpenConfirmAppointmentNoShowAlertProps) => {
      const NoShowContent = (
        <Trans
          components={{
            linkComponent: (
              <Link
                href={`/communications/client/${client?.id}`}
                rel="help noopener"
                target="_blank"
                underline="always"
              />
            ),
          }}
          i18nKey="Dialogs:CONFIRM_ALERT_DIALOG.APPOINTMENT_NO_SHOW.CONTENT"
          values={{ client: client?.firstName }}
        />
      )

      openConfirmAlert({
        applyCustomMessage: true,
        message: isHighValueAppointment ? (
          t(
            'Dialogs:CONFIRM_ALERT_DIALOG.HIGH_VALUE_APPOINTMENT_NO_SHOW.MESSAGE',
          )
        ) : (
          <NoShowAlertText plainText />
        ),
        content: (
          <Text mb={2} variant="body2">
            {isHighValueAppointment
              ? t(
                  'Dialogs:CONFIRM_ALERT_DIALOG.HIGH_VALUE_APPOINTMENT_NO_SHOW.CONTENT',
                )
              : NoShowContent}
          </Text>
        ),
        okButtonText: isHighValueAppointment
          ? t(
              'Dialogs:CONFIRM_ALERT_DIALOG.HIGH_VALUE_APPOINTMENT_NO_SHOW.CONFIRM_ACTION',
            )
          : t(
              'Dialogs:CONFIRM_ALERT_DIALOG.APPOINTMENT_NO_SHOW.CONFIRM_ACTION',
            ),
        cancelButtonText: t('Common:GO_BACK'),
        onConfirm,
        classes: {
          container: classes.noShowAlertContainer,
          message: classes.noShowAlertMessage,
          button: classes.noShowAlertButton,
        },
        ...rest,
      })
    }

    return [openConfirmAppointmentNoShowAlert, closeAlert, isOpen]
  }

export default useConfirmAppointmentNoShowAlert
