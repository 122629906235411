import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, CircularProgress, Divider, Fab, Grid } from '@mui/material'
import { PuiTextArea, Text } from '@pbt/pbt-ui-components'

import {
  fetchInventoryOrder,
  getInventoryOrder,
  getInventoryOrdersIsFetching,
} from '~/store/duck/inventoryOrders'

import ShipmentStatusLabel from '../../shipments/ShipmentStatusLabel'
import { OrderItemsPageBreadcrumbs } from './OrderItemsPageBreadcrumbs'
import { OrderItemsPageHeaderLabels } from './OrderItemsPageHeaderLabels'
import { OrderItemsTableComponent } from './OrderItemsTableComponent'

export const OrderItemsPage = () => {
  const { orderId } = useParams()
  const { t } = useTranslation(['Admin', 'Common'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const inventoryOrder = useSelector(getInventoryOrder(orderId))
  const isFetching = useSelector(getInventoryOrdersIsFetching)

  useEffect(() => {
    if (orderId && !inventoryOrder?.items && !isFetching) {
      dispatch(fetchInventoryOrder(orderId))
    }
  }, [orderId, inventoryOrder, isFetching, dispatch])

  const navigateToOrdersPage = () => {
    navigate('/admin/catalog/inventories/orders')
  }

  if (isFetching || !inventoryOrder?.items) {
    return <CircularProgress sx={{ m: 'auto' }} />
  }

  return (
    <>
      <OrderItemsPageBreadcrumbs inventoryOrder={inventoryOrder} />
      <Grid
        container
        direction="column"
        height="100%"
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <Grid item xs={2}>
          <Box alignItems="center" display="flex" gap={1}>
            <Text marginY={2} variant="h1">
              {t('Admin:CATALOG.ORDERS.RECEIVE_ORDER_TITLE', {
                orderNumber: inventoryOrder.orderNumber,
              })}
            </Text>
            <ShipmentStatusLabel statusId={inventoryOrder.statusId} />
          </Box>
          <OrderItemsPageHeaderLabels inventoryOrder={inventoryOrder} />
        </Grid>
        <Grid item xs display="flex" flexDirection="column">
          <Text
            sx={(theme) => ({
              color: theme.colors.brandSecondary,
              fontWeight: theme.typography.fontWeightBold,
            })}
          >
            {t('Admin:CATALOG.ORDERS.ITEMS')}
          </Text>
          <OrderItemsTableComponent items={inventoryOrder.items || []} />
        </Grid>
        <Divider orientation="horizontal" sx={{ my: 6 }} />
        <Grid item xs={2}>
          <Text
            sx={(theme) => ({
              color: theme.colors.brandSecondary,
              fontWeight: theme.typography.fontWeightBold,
            })}
          >
            {t('Common:NOTES')}
          </Text>
          {/* TODO: Implement notes saving functionality */}
          <PuiTextArea
            sx={{ width: '500px', mb: 4, mt: 0 }}
            value={inventoryOrder.notes ?? ''}
          />
          <Box alignItems="center" display="flex" gap={2} m={1}>
            <Fab
              color="inherit"
              size="small"
              sx={{ px: 4 }}
              variant="extended"
              onClick={navigateToOrdersPage}
            >
              {t('Common:SAVE_ACTION')}
            </Fab>
            <Text link variant="body2" onClick={navigateToOrdersPage}>
              {t('Common:CANCEL_ACTION')}
            </Text>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
