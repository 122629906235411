import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, Grid, IconButton, InputBase } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from 'use-debounce'
import {
  ControlButtonGroupItem,
  ControlButtonGroupName,
  Defaults,
  InfiniteLoaderList,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import { InventoryCategoryName } from '~/constants/inventoryCategory'
import {
  fetchGlobalList,
  fetchMoreGlobalList,
} from '~/store/actions/globalInventoryCatalog'
import { getInventoryCategory } from '~/store/reducers/constants'
import {
  getGlobalInventoryList,
  getGlobalInventoryListTotalCount,
  getIsLoading,
} from '~/store/reducers/globalInventoryCatalog'
import { GlobalInventoryCatalogItem, Variation } from '~/types'

import GlobalItemSelectorItem from './GlobalItemSelectorItem'

const useStyles = makeStyles(
  (theme) => ({
    progress: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
    },
    listContainer: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tabBorder,
      overflowY: 'auto',
      position: 'relative',
      height: 332,
    },
    input: {
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      borderRadius: 20,
      height: 40,
      padding: theme.spacing(1, 0, 1, 2),
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    searchIcon: {
      color: '#FFFFFF',
      width: 22,
      height: 22,
    },
  }),
  { name: 'GlobalItemSelector' },
)

interface GlobalItemSelectorProps {
  itemCategoryId: string
  onEdit: (inventoryId: string | Nil) => void
  selectedItem: GlobalInventoryCatalogItem | Nil
  selectedVariationsMap: Record<string, Variation[]>
  setSelectedItem: (item: GlobalInventoryCatalogItem | Nil) => void
  setSelectedVariationsMap: (variationsMap: Record<string, Variation[]>) => void
}

const GlobalItemSelector = ({
  onEdit,
  selectedItem,
  setSelectedItem,
  selectedVariationsMap,
  setSelectedVariationsMap,
  itemCategoryId,
}: GlobalItemSelectorProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Search')

  const isLoading = useSelector(getIsLoading)
  const items = useSelector(getGlobalInventoryList)
  const totalCount = useSelector(getGlobalInventoryListTotalCount)
  const InventoryCategory = useSelector(getInventoryCategory)

  const itemCategoryName = Utils.getConstantName(
    itemCategoryId,
    InventoryCategory,
  )
  const [rawSearchTerm, setRawSearchTerm] = useState('')

  const [searchTerm] = useDebounce(rawSearchTerm, Defaults.DEBOUNCE_ACTION_TIME)

  useEffect(() => {
    if (
      searchTerm.length >= Defaults.MIN_SEARCH_LENGTH ||
      searchTerm.length === 0
    ) {
      dispatch(
        fetchGlobalList(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          searchTerm,
          [itemCategoryId],
        ),
      )
    }
  }, [dispatch, searchTerm, itemCategoryId])

  const tooltipText = t('Tooltips:ITEM_ALREADY_HAVE_VARIATION_WITH_EDIT', {
    itemName: selectedItem?.name,
  })

  const itemButtons: ControlButtonGroupItem[] = [
    {
      name: ControlButtonGroupName.EDIT,
      onClick: (event) => {
        event.stopPropagation()
        onEdit(selectedItem?.businessInventoryItemId)
      },
    },
    {
      name: ControlButtonGroupName.INFO,
      tooltip: tooltipText,
    },
  ]

  const ItemSearchNameMap: Record<string, string> = {
    [InventoryCategoryName.DRUG]: t('Search:BY_DRUG_NAME'),
    [InventoryCategoryName.FOOD]: t('Search:BY_FOOD_AND_SUPPLEMENTS_NAME'),
    [InventoryCategoryName.SUPPLIES]: t('Search:BY_SUPPLY_NAME'),
    [InventoryCategoryName.VACCINES]: t('Search:BY_VACCINE_NAME'),
    [InventoryCategoryName.VET_DIET]: t('Search:BY_VET_DIET_NAME'),
  }

  return (
    <Grid container item direction="column">
      <Grid container item>
        <Grid item xs>
          <InputBase
            fullWidth
            aria-label={ItemSearchNameMap[itemCategoryName]}
            classes={{
              root: classes.input,
            }}
            endAdornment={
              <IconButton
                className={classes.iconButton}
                color="inherit"
                size="large"
                onClick={() =>
                  dispatch(
                    fetchGlobalList(0, 15, rawSearchTerm, [itemCategoryId]),
                  )
                }
              >
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            }
            placeholder={ItemSearchNameMap[itemCategoryName]}
            value={rawSearchTerm}
            onChange={Utils.handleFormTextInput(setRawSearchTerm)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.listContainer}
        direction="column"
        mt={1}
        wrap="nowrap"
      >
        {isLoading && items.length === 0 && (
          <CircularProgress className={classes.progress} color="secondary" />
        )}
        <InfiniteLoaderList
          isItemLoaded={(index) => Boolean(items[index])}
          itemCount={totalCount}
          itemData={items}
          itemSpacing={0}
          loadMoreItems={(startIndex, endIndex) => {
            dispatch(
              fetchMoreGlobalList(startIndex, endIndex, searchTerm, [
                itemCategoryId,
              ]),
            )
          }}
        >
          {(itemId) => (
            <GlobalItemSelectorItem
              buttons={itemButtons}
              isSelected={Boolean(selectedItem) && selectedItem?.id === itemId}
              itemId={itemId}
              rawSearchTerm={rawSearchTerm}
              selectedVariationsMap={selectedVariationsMap}
              setSelectedVariationsMap={setSelectedVariationsMap}
              onItemChange={setSelectedItem}
            />
          )}
        </InfiniteLoaderList>
      </Grid>
    </Grid>
  )
}

export default GlobalItemSelector
