import { EventTypeName } from '@pbt/pbt-ui-components'

import {
  APPOINTMENT_TYPES_WITH_NO_SHOW_CANCELLATION_POLICY,
  AppointmentTypeName,
} from '~/constants/appointmentTypes'
import { useEventType } from '~/utils/useEventType'

export const useGetIsNoShowConsentAppointmentType = () => {
  const AppointmentEventSubTypes = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  ).filter((type: { name: AppointmentTypeName }) =>
    APPOINTMENT_TYPES_WITH_NO_SHOW_CANCELLATION_POLICY.includes(type.name),
  )

  return (typeId: string | undefined) =>
    Boolean(typeId) &&
    AppointmentEventSubTypes.some((type: { id: string }) => type.id === typeId)
}
