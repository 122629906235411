import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Constant, LanguageUtils, Utils } from '@pbt/pbt-ui-components'
import {
  Decline,
  Delete,
  Edit,
  Email,
  Eye,
  Print,
} from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import {
  DeletableLabOrderStates,
  LabOrdersStatus,
  orderHasResults,
} from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { useLabTestItemDecline } from '~/store/hooks/orders'
import { getLabOrderStatuses } from '~/store/reducers/constants'
import {
  getClientId,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { LabOrder, Order } from '~/types'
import useDialog from '~/utils/useDialog'

import { useDeclineOrderLabTestItem } from '../../charges/utils/useDeclineOrderItem'
import { FinalizeOrdersActionButton } from '../actions/FinalizeOrdersActionButton'
import { FinalizeOrdersActionDivider } from '../actions/FinalizeOrdersActionDivider'
import { FinalizeOrdersActionHeader } from '../actions/FinalizeOrdersActionHeader'

interface FinalizeLabOrdersActionsProps {
  handleCancel: () => void
  handleEdit: () => void
  handlePrintLabel: () => void
  order: LabOrder
  orderLabTests: Order[]
  vendorId: string
}

export const FinalizeLabOrdersActions = ({
  order,
  orderLabTests,
  vendorId,
  handleEdit,
  handlePrintLabel,
  handleCancel,
}: FinalizeLabOrdersActionsProps) => {
  const { t } = useTranslation(['Common'])

  const [openOrderResultsDialog] = useDialog(DialogNames.ORDER_RESULTS)
  const [openFinalizeLabOrderDialog] = useDialog(DialogNames.FINALIZE_LAB_ORDER)
  const [openOrderResultsEmailDialog] = useDialog(
    DialogNames.EMAIL_LAB_ORDER_RESULT,
  )
  const [openOrderResultsPreviewDialog] = useDialog(
    DialogNames.VIEW_ORDER_RESULTS_FRAME,
  )
  const LabOrderStatuses: Constant[] = useSelector(getLabOrderStatuses)
  const soapId = useSelector(getSoapId)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const {
    iframeUrl,
    pdfUrl,
    id: orderId,
    statusId,
    cancellable = true,
    resultPdfUrl,
    resultIdentifier,
    resultIframeUrl,
  } = order

  const orderStatus = Utils.getConstantName(statusId, LabOrderStatuses)
  const orderStatusTranslated = LanguageUtils.getConstantTranslatedName(
    statusId,
    LabOrderStatuses,
  )

  const { declineDisabled, declinedLabTestStateId } = useLabTestItemDecline(
    orderLabTests[0],
    orderLabTests.length > 1,
  )

  const declineLabTest = () =>
    partialEditOrder({
      id: orderLabTests[0].id,
      type: orderLabTests[0].type,
      stateId: declinedLabTestStateId,
    })

  const handleDecline = useDeclineOrderLabTestItem(
    orderLabTests[0],
    declineLabTest,
  )

  const canEditOrder = Boolean(iframeUrl)
  const canPreviewOrder = Boolean(iframeUrl)
  const canPrintOrder = Boolean(pdfUrl)
  const canCompleteOrder = orderStatus === LabOrdersStatus.RESULTS_RECEIVED
  const canDeleteOrder =
    cancellable && DeletableLabOrderStates.includes(orderStatusTranslated)
  const canDeclineOrder = !declineDisabled

  const hasOrderActions =
    canEditOrder ||
    canPreviewOrder ||
    canPrintOrder ||
    canDeleteOrder ||
    canDeclineOrder

  const hasResults = orderHasResults(orderStatus)
  const showViewResults =
    hasResults && Boolean(resultIdentifier || resultIframeUrl)
  const showPrintResults = Boolean(resultPdfUrl)
  const showEmailResults = Boolean(resultPdfUrl)

  const hasResultsActions =
    showViewResults || showPrintResults || showEmailResults

  const handlePrintOrder = () => {
    openOrderResultsDialog({
      pdfUrl,
      vendorId,
      orderId,
      canComplete: canCompleteOrder,
      hasResults: false,
    })
  }
  const handlePreviewOrder = () => {
    openFinalizeLabOrderDialog({
      orderId,
      soapId,
      soapBusinessId,
      url: iframeUrl,
      vendorId,
    })
  }

  const handlePrintResults = () => {
    openOrderResultsDialog({
      pdfUrl: resultPdfUrl,
      vendorId,
      orderId,
      canComplete: canCompleteOrder,
      hasResults: true,
    })
  }

  const handlePreviewResults = () => {
    openOrderResultsPreviewDialog({
      pdfUrl: resultPdfUrl,
      vendorId,
      orderId,
      canComplete: canCompleteOrder,
      resultIdentifier,
      resultFrameUrl: resultIframeUrl,
    })
  }

  const handleEmailResults = () => {
    openOrderResultsEmailDialog({
      clientId,
      patientId,
      orderId,
      vendorId,
      soapId,
    })
  }

  return (
    <>
      <FinalizeOrdersActionButton
        handler={handlePrintLabel}
        icon={<Print />}
        text={t('Common:PRINT_LABEL')}
      />
      {hasResultsActions && (
        <FinalizeOrdersActionHeader text={t('Common:RESULTS')} />
      )}
      {showEmailResults && (
        <FinalizeOrdersActionButton
          handler={handleEmailResults}
          icon={<Email />}
          text={t('Common:EMAIL_RESULTS_ACTION')}
        />
      )}
      {showPrintResults && (
        <FinalizeOrdersActionButton
          handler={handlePrintResults}
          icon={<Print />}
          text={t('Common:PRINT_RESULTS')}
        />
      )}
      {showViewResults && (
        <FinalizeOrdersActionButton
          handler={handlePreviewResults}
          icon={<Eye />}
          text={t('Common:VIEW_RESULTS')}
        />
      )}
      {hasResultsActions && hasOrderActions && <FinalizeOrdersActionDivider />}
      {hasOrderActions && (
        <FinalizeOrdersActionHeader text={t('Common:ORDER')} />
      )}
      {canDeclineOrder && (
        <FinalizeOrdersActionButton
          handler={handleDecline}
          icon={<Decline />}
          text={t('Common:DECLINE_ORDER')}
        />
      )}
      {canDeleteOrder && (
        <FinalizeOrdersActionButton
          handler={handleCancel}
          icon={<Delete />}
          text={t('Common:DELETE_ORDER')}
        />
      )}
      {canEditOrder && (
        <FinalizeOrdersActionButton
          handler={handleEdit}
          icon={<Edit />}
          text={t('Common:EDIT_ORDER')}
        />
      )}
      {canPrintOrder && (
        <FinalizeOrdersActionButton
          handler={handlePrintOrder}
          icon={<Print />}
          text={t('Common:PRINT_ORDER')}
        />
      )}
      {canPreviewOrder && (
        <FinalizeOrdersActionButton
          handler={handlePreviewOrder}
          icon={<Eye />}
          text={t('Common:PREVIEW_ORDER')}
        />
      )}
    </>
  )
}
