import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { AppointmentState } from '~/constants/appointmentStates'
import { isHighValueAppointment } from '~/constants/appointmentTypes'
import FeatureToggle from '~/constants/featureToggle'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { getPatchedAppointmentData } from '~/store/reducers/timetable'
import { getIsOutsideCancellationWindow } from '~/utils/appointmentCancellationUtils'
import { useAppointmentStateId } from '~/utils/useEventType'

export const useAppointmentCancellationNotification = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)
  const noShowStateId = useAppointmentStateId(AppointmentState.NO_SHOW)
  const patchedAppointmentData = useSelector(getPatchedAppointmentData)
  const patientName = useSelector(
    getPatientName(patchedAppointmentData?.patientId),
  )
  const isDepositPayByLinkEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DEPOSIT_PAY_BY_LINK),
  )
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  useEffect(() => {
    const uiNotificationData = {
      id: uuid(),
      message: '',
      type: SnackNotificationType.APPOINTMENT_CANCELLATION,
      params: {},
    }
    const isHighValue = isHighValueAppointment(
      patchedAppointmentData?.businessAppointmentType?.name ?? '',
    )
    const isCancelled = patchedAppointmentData?.stateId === cancelledStateId
    const isNoShow = patchedAppointmentData?.stateId === noShowStateId

    if (isCancelled && patientName) {
      // If high value appointment, we show a message about the deposit instead
      if (isDepositPayByLinkEnabled && isOmnichannel && isHighValue) {
        const isOutsideCancellationWindow = getIsOutsideCancellationWindow(
          patchedAppointmentData?.scheduledStartDatetime,
          patchedAppointmentData?.scheduledEndDatetime,
        )
        const forfeitMessage = t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.HIGH_VALUE_CANCELLED_FORFEIT_MESSAGE',
        )
        const refundMessage = t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.HIGH_VALUE_CANCELLED_REFUND_MESSAGE',
        )
        uiNotificationData.type =
          SnackNotificationType.HIGH_VALUE_APPOINTMENT_CANCELLATION
        uiNotificationData.message = isOutsideCancellationWindow
          ? forfeitMessage
          : refundMessage
        uiNotificationData.params = {
          clientId:
            patchedAppointmentData?.clientId ?? patchedAppointmentData?.client,
        }
      } else {
        uiNotificationData.message = t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.MESSAGE',
          {
            patientName,
            appointmentTypeName: patchedAppointmentData?.type?.name,
          },
        )
      }
    } else if (isNoShow) {
      if (isDepositPayByLinkEnabled && isOmnichannel && isHighValue) {
        uiNotificationData.type =
          SnackNotificationType.HIGH_VALUE_APPOINTMENT_NO_SHOW
        uiNotificationData.message = t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.HIGH_VALUE_NO_SHOW_MESSAGE',
        )
        uiNotificationData.params = {
          clientId: patchedAppointmentData?.clientId,
        }
      } else if (patientName) {
        uiNotificationData.type = SnackNotificationType.NO_SHOW
        uiNotificationData.message = t(
          'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.DEFAULT_NO_SHOW_MESSAGE',
          {
            patientName,
            appointmentTypeName: patchedAppointmentData?.type?.name,
          },
        )
      }
    }

    if (isCancelled || isNoShow) {
      dispatch(addUiNotification(uiNotificationData))
    }
  }, [
    patchedAppointmentData,
    patientName,
    cancelledStateId,
    noShowStateId,
    isDepositPayByLinkEnabled,
    isOmnichannel,
  ])
}
