import * as R from 'ramda'
import {
  AppointmentEventType,
  moment,
  NamedEntity,
  Nil,
} from '@pbt/pbt-ui-components'

import { AppointmentStateColorMap } from '~/constants/event'
import { SlotType } from '~/constants/schedulerConstants'
import i18n from '~/locales/i18n'
import {
  BoardingSchedule,
  TimetableEvent,
  TimetableFilter,
  TimetableSlot,
} from '~/types'
import { getUrlSearchParam } from '~/utils'
import { formatHourRange } from '~/utils/time'

export const getSlotId = (slot: TimetableSlot) =>
  slot.appointment || slot.busyTime

export const dragDropIdToReal = (dragDropId: string) => dragDropId.split('-')[0]
export const realIdToDragDrop = (id: string, type: string) =>
  type ? `${id}-${type}` : id

export const getFilterGroupsBySelectedIds = (
  defaultFilters: TimetableFilter<NamedEntity>[],
  selectedLabels: string[],
) =>
  selectedLabels?.length
    ? defaultFilters.map((item) => ({
        ...item,
        selected: selectedLabels.includes(item.id),
      }))
    : defaultFilters

export const getSlotIdForPlaceholder = (
  slots: TimetableSlot[],
  appointment: TimetableEvent | undefined,
  appointmentsMap: Record<string, TimetableEvent>,
) => {
  if (!appointment) {
    return undefined
  }

  const slot = slots.find((s) => {
    const id = getSlotId(s)
    const slotAppointment = id ? appointmentsMap[id] : undefined
    return moment(slotAppointment?.scheduledStartDatetime).isAfter(
      moment(appointment.scheduledStartDatetime),
    )
  })

  return slot && getSlotId(slot)
}

export const getSlotsAreOverlapping = (
  slotOne: TimetableSlot,
  slotTwo: TimetableSlot,
) => {
  const notOverlapping =
    moment(slotOne.interval.to).isSameOrBefore(slotTwo.interval.from) ||
    moment(slotTwo.interval.to).isSameOrBefore(slotOne.interval.from)

  return !notOverlapping
}

export const getSlotsAreOverlappingAndSameType = (
  slotOne: TimetableSlot,
  slotTwo: TimetableSlot,
) => {
  const notOverlapping =
    moment(slotOne.interval.to).isSameOrBefore(slotTwo.interval.from) ||
    moment(slotTwo.interval.to).isSameOrBefore(slotOne.interval.from) ||
    // if one slot is Busy type, then it should not count as overlapping with a non-Busy slot
    (slotOne.type === SlotType.BUSY) !== (slotTwo.type === SlotType.BUSY)

  return !notOverlapping
}

const canFitSlotIntoColumn = (
  column: TimetableSlot[],
  newSlot: TimetableSlot,
) => column.every((slot) => !getSlotsAreOverlapping(slot, newSlot))

const canFitSlotIntoColumnByType = (
  column: TimetableSlot[],
  newSlot: TimetableSlot,
) => column.every((slot) => !getSlotsAreOverlappingAndSameType(slot, newSlot))

export const getSlotsPositions = (
  slots: TimetableSlot[] | [],
  columns = [] as any,
): any => {
  if (!slots.length) {
    return columns
  }

  const [currentSlot, ...otherSlots] = slots

  const matchingColumn = columns.find((column: any) =>
    canFitSlotIntoColumn(column, currentSlot),
  )

  if (matchingColumn) {
    matchingColumn.push(currentSlot)
  } else {
    columns.push([currentSlot])
  }
  return getSlotsPositions(otherSlots, columns)
}

export const getSlotsPositionsByType = (
  slots: TimetableSlot[] | [],
  columns = [] as any,
): any => {
  if (!slots.length) {
    return columns
  }

  const [currentSlot, ...otherSlots] = slots

  const matchingColumn = columns.find((column: any) =>
    canFitSlotIntoColumnByType(column, currentSlot),
  )

  if (matchingColumn) {
    matchingColumn.push(currentSlot)
  } else {
    columns.push([currentSlot])
  }
  return getSlotsPositionsByType(otherSlots, columns)
}

const isBusySlot = (slot: TimetableSlot) => slot.type === SlotType.BUSY

export const getSlotOffsetPosition = (
  slot: TimetableSlot,
  positions: any[][],
  isAppointmentReservedEnabled = false,
) => {
  const positionIndex = positions.findIndex((position) =>
    position.includes(slot),
  )
  const otherPositions = R.remove(positionIndex, 1, positions)

  const hasConflictingSlotsInOtherPositions = otherPositions.some((position) =>
    position.some(
      (otherSlot) =>
        getSlotsAreOverlapping(slot, otherSlot) &&
        (!isAppointmentReservedEnabled ||
          isBusySlot(otherSlot) === isBusySlot(slot)),
    ),
  )

  return hasConflictingSlotsInOtherPositions ? positionIndex : -1
}

export const getTodayAppointmentsByType = (
  schedules: BoardingSchedule[],
  appointmentsMap: Record<string, TimetableEvent>,
  types: string[],
  getDate: (slot: TimetableSlot) => string,
) => {
  const items = R.pluck('items', schedules)
  const slots = R.flatten(R.pluck('slots', R.flatten(items)))
  const checkOutTodaySlots = slots.filter((slot) =>
    moment(getDate(slot)).isSame(new Date(), 'day'),
  )

  return checkOutTodaySlots
    .map((slot) => appointmentsMap[getSlotId(slot)!])
    .filter(
      (appointment) =>
        appointment.state && types.includes(appointment.state.id),
    )
}

export const getAppointmentColorState = (state: string | undefined) =>
  AppointmentStateColorMap[state ?? 'DEFAULT'] ||
  AppointmentStateColorMap.DEFAULT

type GetAppointmentWarningLabel = {
  from?: string | Nil
  stateName?: string
  to?: string | Nil
}

const WARNING_THRESHOLD = 10 // minutes
const WAITING_WARNING_THRESHOLD = 15 // minutes

/*
  Status in (Scheduled, Contacted, Confirmed, Rescheduled)
  > 10 minutes after appointment start datetime
*/
const isAppointmentLate = ({ from, stateName }: GetAppointmentWarningLabel) => {
  // TODO: leave only Scheduled and Rescheduled status for now
  const lateCandidates = ['Scheduled', 'Rescheduled'] // ['Scheduled', 'Contacted', 'Confirmed', 'Rescheduled']
  const diff = moment().diff(moment(from), 'minute')
  const isLate =
    stateName && lateCandidates.includes(stateName) && diff > WARNING_THRESHOLD
  return { isLate, diff }
}

/*
  Status in (Arrived)
  > 15 minutes after appointment start datetime
*/
const isAppointmentWaiting = ({
  from,
  stateName,
}: GetAppointmentWarningLabel) => {
  const lateCandidates = ['Arrived']
  const diff = moment().diff(moment(from), 'minute')
  const isWaiting =
    stateName &&
    lateCandidates.includes(stateName) &&
    diff > WAITING_WARNING_THRESHOLD
  return { isWaiting, diff }
}

/*
  Status in (Admitted, Hospitalized, In progress)
  > 10 minutes after appointment end datetime
*/
const isAppointmentBehind = ({ to, stateName }: GetAppointmentWarningLabel) => {
  // TODO: remove behind options for now
  const behindCandidates = [] as any[] // ['Admitted', 'Hospitalized', 'In progress']
  const diff = moment().diff(moment(to), 'minute')
  const isBehind =
    stateName &&
    behindCandidates.includes(stateName) &&
    diff > WARNING_THRESHOLD
  return { isBehind, diff }
}

const humanizeDiff = (diff: number) => {
  const m = diff % 60
  const h = Math.floor((diff - m) / 60)
  const d = Math.floor(h / 24)

  if (d >= 1) {
    return i18n.t('Time:FORMAT.DAYS', { days: d })
  }

  return h > 0
    ? i18n.t('Time:FORMAT.HOURS', { hours: h, minutes: m })
    : i18n.t('Time:FORMAT.MINUTES', { minutes: m })
}

export const getAppointmentWarningLabel = ({
  from,
  to,
  stateName,
}: GetAppointmentWarningLabel) => {
  const { isLate, diff: lateDiff } = isAppointmentLate({ from, to, stateName })
  const { isBehind, diff: behindDiff } = isAppointmentBehind({
    from,
    to,
    stateName,
  })
  const { isWaiting, diff: waitingDiff } = isAppointmentWaiting({
    from,
    to,
    stateName,
  })

  return isWaiting
    ? i18n.t('Time:LABEL.WAITING', { time: humanizeDiff(waitingDiff) })
    : isLate
      ? i18n.t('Time:LABEL.LATE', { time: humanizeDiff(lateDiff) })
      : isBehind
        ? i18n.t('Time:LABEL.BEHIND', { time: humanizeDiff(behindDiff) })
        : ''
}

export const getAppointmentTimeRange = (appointment: TimetableEvent) => ({
  startTime: moment(appointment?.scheduledStartDatetime),
  endTime: moment(appointment?.scheduledEndDatetime),
})

export const getHourAppointmentRange = (appointment: TimetableEvent) => {
  const [dateOne, dateTwo] = R.values(getAppointmentTimeRange(appointment))
  return formatHourRange(dateOne, dateTwo)
}

export const getUrlTimetableDate = () =>
  getUrlSearchParam('date', window.location.search) || undefined

export const checkEventAppointmentType = (
  appointment: TimetableEvent | Nil,
  AppointmentType: AppointmentEventType,
) => {
  const typeId = appointment?.typeId || appointment?.type?.id
  return typeId && R.includes(typeId, R.pluck('id', AppointmentType.subTypes))
}
