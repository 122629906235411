import * as API from '~/api'

// @ts-ignore
import catalogDuck from './duck-generators/catalog'
// @ts-ignore
import createDuck from './duck-generators/createDuck'

const remindersCatalogDuck = createDuck({
  name: 'remindersCatalog',
  duck: catalogDuck,
  apiEndpoints: (types: Record<string, string>) => ({
    [types.FETCH_CATALOG_FILTERS]: API.fetchRemindersCatalogFilters,
    [types.FETCH_CATALOG_ITEMS]: API.fetchRemindersCatalogItems,
    [types.FETCH_MORE_CATALOG_ITEMS]: API.fetchRemindersCatalogItems,
  }),
})

export const remindersCatalogReducer = remindersCatalogDuck.reducer
export const remindersCatalogSaga = remindersCatalogDuck.saga
export const remindersCatalogSelectors = remindersCatalogDuck.selectors
export const remindersCatalogActions = remindersCatalogDuck.actions
