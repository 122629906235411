/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useSelector } from 'react-redux'
import {
  LanguageUtils,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { Invoice as GraphqlInvoice } from '~/api/graphql/generated/types'
import { getInvoiceStates } from '~/store/reducers/constants'
import { BatchInvoice, InvoiceOrEstimate } from '~/types'

import { INVOICE_CHARGE_SHEET_TYPE } from '../charge-sheet/constants'

interface SimpleInvoiceStatusLabelProps extends StateLabelProps {
  state: string | undefined
  stateId: string
}

export const SimpleInvoiceStatusLabel = ({
  stateId,
  state,
  ...rest
}: SimpleInvoiceStatusLabelProps) => {
  const InvoiceStates = useSelector(getInvoiceStates)

  const State = Utils.findById(stateId, InvoiceStates)
  const displayStatusName = LanguageUtils.getConstantTranslatedName(
    stateId,
    InvoiceStates,
    state,
  )

  const props = State ? { [State.color || State.colorVariant]: true } : {}

  return (
    <StateLabel variant="big" {...props} {...rest}>
      {displayStatusName}
    </StateLabel>
  )
}

export interface InvoiceStatusLabelProps extends StateLabelProps {
  invoice: BatchInvoice | InvoiceOrEstimate | GraphqlInvoice
}

const InvoiceStatusLabel = ({ invoice, ...rest }: InvoiceStatusLabelProps) => {
  if (!invoice?.stateId && invoice.type !== INVOICE_CHARGE_SHEET_TYPE) {
    return null
  }

  return (
    <SimpleInvoiceStatusLabel
      state={invoice.state}
      stateId={invoice.stateId}
      {...rest}
    />
  )
}

export default InvoiceStatusLabel
