import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  AlertIconType,
  Nil,
  UnsavedWellnessPlanVersion,
  Utils,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  getBenefitCoversAppointmentTypes,
  getBenefitCoversCatalog,
  getBenefitHasCoveredItems,
} from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import DialogNames from '~/constants/DialogNames'
import {
  REQUIRED_BASE_TIER_BENEFIT_COUNT,
  REQUIRED_EXTRA_BENEFIT_COUNT,
  REQUIRED_PACKAGE_BENEFIT_COUNT,
  REQUIRED_TIER_BENEFIT_COUNT,
  WellnessPlanLevels,
} from '~/constants/wellnessPlansConstants'
import { isEditItem } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import {
  clearWellnessPlanVersionValidationError,
  createWellnessPlanVersion,
  editWellnessPlanVersion,
} from '../actions/wellnessPlans'
import { getWellnessPlanType } from '../reducers/constants'
import {
  getLastCreatedWellnessPlanVersionId,
  getWellnessPlanGlobalBenefitGroups,
  getWellnessPlansIsLoading,
  getWellnessPlansIsSaving,
  getWellnessPlanValidationError,
} from '../reducers/wellnessPlans'

export function useGetBenefitShouldShowCoverColumn() {
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)

  return (benefit: WellnessPlanBenefit) => {
    const group = Utils.findById(benefit.groupId, globalBenefitGroups)
    const GlobalBenefit = Utils.findById(
      benefit.globalBenefitId,
      group?.benefits || [],
    )
    const globalBenefitHasAppointmentCover =
      getBenefitCoversAppointmentTypes(GlobalBenefit)
    const globalBenefitHasCatalogCover = getBenefitCoversCatalog(GlobalBenefit)
    const hasItems = getBenefitHasCoveredItems(benefit)

    return (
      hasItems ||
      globalBenefitHasAppointmentCover ||
      globalBenefitHasCatalogCover
    )
  }
}

export function useValidatePlanBenefits() {
  const getBenefitShouldShowCoverColumn = useGetBenefitShouldShowCoverColumn()

  return (plan: WellnessPlan) =>
    plan?.benefits?.every((benefit) => {
      const showCoverColumn = getBenefitShouldShowCoverColumn(benefit)
      const hasItems = getBenefitHasCoveredItems(benefit)

      return !showCoverColumn || hasItems
    })
}

export function useSaveWellnessPlanVersion(onSave?: () => void) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const lastCreatedWellnessPlanVersionId = useSelector(
    getLastCreatedWellnessPlanVersionId,
  )
  const validationError = useSelector(getWellnessPlanValidationError)

  const goToListPage = ({ id: idProp }: { id: string | null }) => {
    if (validationError) {
      return
    }
    const id = lastCreatedWellnessPlanVersionId || idProp
    if (id) {
      navigate(`/admin/catalog/wellness-plans/${id}`)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onSave || goToListPage,
    getWellnessPlansIsSaving,
  )

  const [openActivePatientsAlert, closeActivePatientsAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const doSave = (
    wellnessPlanVersion: WellnessPlanVersion | UnsavedWellnessPlanVersion,
  ) => {
    setCloseAfterCreationOn({
      id: wellnessPlanVersion.id,
    })

    if (isEditItem(wellnessPlanVersion)) {
      dispatch(editWellnessPlanVersion(wellnessPlanVersion))
    } else {
      dispatch(createWellnessPlanVersion(wellnessPlanVersion))
    }
  }

  return (
    wellnessPlanVersion: WellnessPlanVersion | UnsavedWellnessPlanVersion,
  ) => {
    const hasActivePatients =
      wellnessPlanVersion.activePatients &&
      wellnessPlanVersion.activePatients > 0
    const hasUnsavedCoverOptions = wellnessPlanVersion.plans.some((plan) =>
      plan.benefits.some((benefit) =>
        benefit.cover.some((option) => !option.id),
      ),
    )
    if (hasActivePatients && hasUnsavedCoverOptions) {
      openActivePatientsAlert({
        iconType: AlertIconType.WARN,
        message: t('WellnessPlans:VERSION_NUMBER_CURRENTLY_HAS_ACTIVE_USERS', {
          versionNumber: wellnessPlanVersion.versionNumber,
        }),
        cancelButtonText: t('Common:NO_KEEP_EDITING'),
        okButtonText: t('Common:SAVE_ACTION'),
        onCancel: closeActivePatientsAlert,
        onClose: closeActivePatientsAlert,
        onOk: () => {
          closeActivePatientsAlert()
          doSave(wellnessPlanVersion)
        },
      })
    } else {
      doSave(wellnessPlanVersion)
    }
  }
}

export const useGetPlanRequiredBenefitsCount = (plan: WellnessPlan) => {
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const isBaseLevel = plan.level === WellnessPlanLevels.BASE

  if (isBaseLevel) {
    return REQUIRED_BASE_TIER_BENEFIT_COUNT
  }

  const counts: Record<string, number> = {
    [PackageTypeId]: REQUIRED_PACKAGE_BENEFIT_COUNT,
    [ExtraTypeId]: REQUIRED_EXTRA_BENEFIT_COUNT,
  }

  return counts[plan.planTypeId] || REQUIRED_TIER_BENEFIT_COUNT
}

export const useGetPlanDefaultName = (plan: WellnessPlan) => {
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const names: Record<string, string> = {
    [PackageTypeId]: 'Subscribe-and-save',
    [ExtraTypeId]: 'Extra',
  }

  return names[plan.planTypeId] || 'Plan'
}

export const useWellnessPlanVersionActivationAlert = (
  wellnessPlanVersion: WellnessPlanVersion | Nil,
  onOk: (id: string) => void,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const validationError = useSelector(getWellnessPlanValidationError)
  const isLoading = useSelector(getWellnessPlansIsLoading)

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const isActivatingExistingVersion = Boolean(
    wellnessPlanVersion?.versionNumber,
  )

  const versionMessage = isActivatingExistingVersion
    ? `${t('Common:VERSION').toLowerCase()} ${
        wellnessPlanVersion?.versionNumber
      }`
    : t('Common:NEW_VERSION').toLowerCase()

  const onCancel = () => {
    closeAlert()
    dispatch(clearWellnessPlanVersionValidationError())
  }

  useEffect(() => {
    if (validationError && !isLoading) {
      openAlert({
        CancelButtonProps: {
          loading: isLoading,
          disabled: isLoading,
        },
        cancelButtonText: t('WellnessPlans:KEEP_VERSION_NUMBER_ACTIVE', {
          versionNumber: validationError?.currentActive?.versionNumber || '',
        }),
        iconType: AlertIconType.WARN,
        message: t(
          'WellnessPlans:VERSION_NUMBER_IS_CURRENTLY_ACTIVE_AND_WILL_BECOME_INACTIVE_ONCE_YOU_ACTIVE_MESSAGE',
          {
            versionNumber: validationError?.currentActive?.versionNumber || '',
            versionMessage,
          },
        ),
        OkButtonProps: {
          loading: isLoading,
          disabled: isLoading,
        },
        okButtonText: `${t('Common:ACTIVATE_ACTION')} ${versionMessage}`,
        onCancel,
        onOk: () => {
          dispatch(clearWellnessPlanVersionValidationError())
          onOk(validationError?.currentActive?.id)
        },
      })
    }
  }, [validationError, isLoading])
}
