/* eslint-disable react/no-multi-comp */
import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  PersonOutlined as PersonOutlinedIcon,
  Tune as TuneIcon,
} from '@mui/icons-material'
import { Grid, InputLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import * as R from 'ramda'
import {
  DateFormat,
  Nil,
  PermissionArea,
  PuiCheckbox,
  PuiTextArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { WeekDays } from '@pbt/pbt-ui-components/src/localization'

import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import UserSelect from '~/components/common/inputs/UserSelect'
import FeatureToggle from '~/constants/featureToggle'
import { DEFAULT_APPOINTMENT_NAME } from '~/constants/schedulerConstants'
import { getAppointmentTypesMap } from '~/store/reducers/appointmentTypes'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { DataHandle, TeamFilter, TimetableEvent } from '~/types'
import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'
import {
  getAppointmentRecurrenceTimeUnitConstant,
  TimeUnitsAdjectives,
} from '~/utils/time'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

import AppointmentDateSection, {
  AppointmentDateSectionHandle,
  getReleaseTimeConstant,
  getReleaseTimeValue,
} from './AppointmentDateSection'
import { useGetIsReservedType } from './appointmentUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
    },
    icon: {
      color: theme.colors.selectedOption,
    },
    checkboxRoot: {
      paddingRight: theme.spacing(0.5),
    },
    checkbox: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'Busy' },
)

const ReadOnlyItemContainer = styled(Grid)`
  max-width: 800px;
`

const ReadOnlyItemRow = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayGray3};
  color: ${({ theme }) => theme.colors.grayGray1};
`
const ReadOnlyItemValue = styled(Grid)`
  color: ${({ theme }) => theme.colors.grayGray1};
`

const ReadOnlyBusyLine = ({
  fieldName,
  fieldValue,
}: {
  fieldName?: string
  fieldValue?: string
}) => (
  <ReadOnlyItemRow container item lineHeight={1.4} py={1} xs={12}>
    <Grid item marginBottom="auto" pl={1} xs={3}>
      <Text strong color="grayGray1" fontSize="1.4rem">
        {fieldName}
      </Text>
    </Grid>
    <ReadOnlyItemValue item xs={9}>
      {fieldValue}
    </ReadOnlyItemValue>
  </ReadOnlyItemRow>
)

const getTimeRangeString = (
  startDatetimeString: string | Nil,
  endDateTimeString: string | Nil,
) => {
  if (!startDatetimeString || !endDateTimeString) {
    return ''
  }
  const startDateString = moment(startDatetimeString).format('MM/DD/YYYY')
  const endDateString = moment(endDateTimeString).format('MM/DD/YYYY')

  const startTimeString = moment(startDatetimeString).format(
    DateFormat.TIME_WITH_MERIDIAN,
  )
  const endTimeString = moment(endDateTimeString).format(
    DateFormat.TIME_WITH_MERIDIAN,
  )

  return `${startDateString} ${startTimeString} - ${endDateString === startDateString ? '' : endDateString} ${endTimeString}`
}

export interface BusyProps {
  appointment: TimetableEvent | Nil
  appointmentTypeId: string
  onFieldsChange?: (changedFields: FieldCache) => void
  personId?: string
}

export interface BusyHandle extends DataHandle {}

const Busy = forwardRef(function Busy(
  { appointment, appointmentTypeId, personId, onFieldsChange = R.F }: BusyProps,
  ref: ForwardedRef<BusyHandle>,
) {
  const classes = useStyles()
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const { t } = useTranslation(['TimeTable', 'Tooltips', 'Plurals'])

  const isAppointmentReservedEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_RESERVED),
  )
  const initialPerson = useSelector(getUser(appointment?.personId || personId))
  const reservedBlockPermissions = useSelector(
    getCRUDByArea(PermissionArea.RESERVED_BLOCK),
  )
  const getIsReservedType = useGetIsReservedType()
  const isReservedAppointment = getIsReservedType(appointmentTypeId)
  const appointmentDateSectionRef = useRef<AppointmentDateSectionHandle>(null)

  const userHasPermissions =
    !isAppointmentReservedEnabled ||
    !isReservedAppointment ||
    reservedBlockPermissions.create

  const appointmentTypes = useSelector(getAppointmentTypesMap)
  const activeAppointmentTypes: Record<string, BusinessAppointmentType> =
    R.pickBy((item) => item.enabled, appointmentTypes)
  const simplifyBusinessAppointmentType = ({
    id,
    eventTypeId,
    color,
    name,
  }: BusinessAppointmentType) => ({ id, eventTypeId, color, name })
  const { fields, validate } = useFields(
    [
      {
        name: 'title',
        initialValue:
          !appointment?.name || appointment?.name === DEFAULT_APPOINTMENT_NAME
            ? ''
            : appointment?.name,
      },
      {
        name: 'person',
        label: t('Common:TEAM_MEMBER'),
        initialValue: appointment?.personId || personId || '',
      },
      { name: 'notes', initialValue: appointment?.notes || '' },
      {
        name: 'appointmentTypeIds',
        label: t('Common:APPOINTMENT_TYPES'),
        type: 'select',
        initialValue: appointment?.businessAppointmentTypeRestrictions
          ? appointment?.businessAppointmentTypeRestrictions?.map(
              ({ id }) => appointmentTypes[id],
            )
          : [],
      },
      {
        name: 'memberOnly',
        label: t('Common:MEMBER_ONLY'),
        type: 'toggle',
        initialValue: Boolean(appointment?.wplanMembersOnly),
      },
    ],
    false,
  )

  const { title, person, notes, appointmentTypeIds, memberOnly } = fields

  useFieldsChanged(onFieldsChange, fields)

  useImperativeHandle(ref, () => ({
    validate: () =>
      validate() && (appointmentDateSectionRef.current?.validate() ?? true),
    get: () => ({
      type: appointmentTypeId,
      name: title.value || DEFAULT_APPOINTMENT_NAME,
      person: person.value || null,
      notes: notes.value,
      wplanMembersOnly: memberOnly.value,
      businessAppointmentTypeRestrictions: appointmentTypeIds.value.map(
        simplifyBusinessAppointmentType,
      ),
      ...appointmentDateSectionRef.current?.get(),
    }),
  }))

  return (
    <Grid container item className={classes.root} pb={2} px={3} rowSpacing={1}>
      {userHasPermissions ? (
        <>
          <Grid item xs={12}>
            <Grid
              container
              item
              alignItems="center"
              columnSpacing={1}
              wrap="nowrap"
              xs={5}
            >
              <Grid item mt={3}>
                <TuneIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <PuiTextField
                  disabled={!appointmentPermissions.update}
                  field={title}
                  label={t('Common:TITLE')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              item
              alignItems="center"
              columnSpacing={1}
              wrap="nowrap"
              xs={3}
            >
              <Grid item mt={1}>
                <PersonOutlinedIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <UserSelect
                  disabled={!appointmentPermissions.update}
                  field={person}
                  label={person.label}
                  teamFilter={TeamFilter.ALL}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={8}>
              <AppointmentDateSection
                appointment={appointment}
                appointmentTypeId={appointmentTypeId}
                ref={appointmentDateSectionRef}
                onFieldsChange={onFieldsChange}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <ReadOnlyItemContainer container item pt={3}>
              <ReadOnlyBusyLine
                fieldName={t('Common:TITLE')}
                fieldValue={appointment?.name}
              />
              <ReadOnlyBusyLine
                fieldName={t('Common:ASSIGNED')}
                fieldValue={Utils.getPersonString(initialPerson)}
              />
              <ReadOnlyBusyLine
                fieldName={t('Common:WHEN')}
                fieldValue={getTimeRangeString(
                  appointment?.scheduledStartDatetime,
                  appointment?.scheduledEndDatetime,
                )}
              />
              <ReadOnlyBusyLine
                fieldName={t('Common:REPEAT_ACTION')}
                fieldValue={
                  appointment?.recurrenceParams
                    ? `${
                        TimeUnitsAdjectives[
                          getAppointmentRecurrenceTimeUnitConstant(appointment)
                        ]
                      }; ${appointment?.recurrenceParams?.days
                        .map((day) => WeekDays[day])
                        .join(', ')}`
                    : t('Common:NO_REPEAT')
                }
              />
              <ReadOnlyBusyLine
                fieldName={t('Common:RELEASE_AUTOMATICALLY')}
                fieldValue={
                  appointment?.releaseTimeOffset
                    ? t('Plurals:Z_ICU_WORKAROUND.RELEASE_TIME', {
                        numUnits: Number(
                          getReleaseTimeValue(appointment?.releaseTimeOffset),
                        ),
                        unit:
                          getReleaseTimeConstant(
                            appointment?.releaseTimeOffset,
                          ).toLocaleLowerCase() || '',
                      })
                    : t('Common:NO')
                }
              />
            </ReadOnlyItemContainer>
          </Grid>
        </>
      )}
      {isAppointmentReservedEnabled && isReservedAppointment && (
        <Grid item xs={12}>
          <Grid item xs={6}>
            <TextWithTooltip
              strong
              tooltipText={t('Tooltips:RESERVED_RESTRICTION')}
              variant="subheading3"
            >
              {t('Common:RESTRICTION_OTHER')}
            </TextWithTooltip>
          </Grid>
          <Grid item xs={12}>
            {userHasPermissions ? (
              <PuiSelectAll
                hideLabelWhenEmpty
                field={appointmentTypeIds}
                items={Object.values(activeAppointmentTypes)}
                label={appointmentTypeIds.label}
                noneSelectedText={t('Common:APPOINTMENT_TYPES')}
              />
            ) : (
              <ReadOnlyItemContainer container item pt={1}>
                <ReadOnlyBusyLine
                  fieldName={appointmentTypeIds.label}
                  fieldValue={
                    appointment?.businessAppointmentTypeRestrictions &&
                    appointment?.businessAppointmentTypeRestrictions.length > 0
                      ? appointment?.businessAppointmentTypeRestrictions
                          .map((bat) => bat.name)
                          .join(', ')
                      : t('TimeTable:ADD_APPOINTMENT_COMPONENT.NO_RESTRICTIONS')
                  }
                />
                <ReadOnlyBusyLine
                  fieldName={t('Common:MEMBER_ONLY')}
                  fieldValue={
                    appointment?.wplanMembersOnly
                      ? t('Common:TRUE')
                      : t('TimeTable:ADD_APPOINTMENT_COMPONENT.NO_RESTRICTIONS')
                  }
                />
              </ReadOnlyItemContainer>
            )}
          </Grid>
        </Grid>
      )}
      {isAppointmentReservedEnabled &&
        isReservedAppointment &&
        userHasPermissions && (
          <Grid item xs={12}>
            <Grid item xs={6}>
              <PuiCheckbox
                checkboxClasses={{
                  root: classes.checkboxRoot,
                }}
                className={classes.checkbox}
                field={memberOnly}
                label={t('Common:MEMBER_ONLY')}
                name="memberOnly"
              />
            </Grid>
          </Grid>
        )}
      {(userHasPermissions || appointment?.notes) && (
        <Grid item mt={1} xs={12}>
          <InputLabel htmlFor="notes-input">
            <Text strong variant="subheading3">
              {t('Common:NOTES')}
            </Text>
          </InputLabel>
          {userHasPermissions ? (
            <PuiTextArea
              multiline
              disabled={!appointmentPermissions.update}
              field={notes}
              id="notes-input"
              margin="none"
              maxRows={6}
              minRows={1}
            />
          ) : (
            appointment?.notes && (
              <Text pl={1} pt={1}>
                {appointment?.notes}
              </Text>
            )
          )}
        </Grid>
      )}
    </Grid>
  )
})

export default Busy
