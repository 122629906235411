import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  CircularProgressOverlay,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  SaveWellnessPlanRenewalMappingInput,
  useSaveWellnessPlanRenewalMappingsMutation,
  WellnessPlanVersionsDocument,
} from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import Stepper from '~/components/common/stepper/Stepper'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import { registerWarnAlert } from '~/store/duck/uiAlerts'
import {
  useSaveWellnessPlanVersion,
  useWellnessPlanVersionActivationAlert,
} from '~/store/hooks/wellnessPlans'
import {
  getLastCreatedWellnessPlanVersionId,
  getWellnessPlanGlobalBenefitGroups,
  getWellnessPlansIsSaving,
} from '~/store/reducers/wellnessPlans'
import { getApolloErrorMessage } from '~/utils/errors'

import {
  CreateWellnessPlanVersionPageHandle,
  PostActivateSteps,
  StepsList,
  usePlanStepManager,
} from './usePlanStepManager'
import { useWellnessPlanVersionData } from './useWellnessPlanVersionData'
import { getPlanByLevel } from './wellnessPlanUtils'
import { WellnessPlanVersionLayout } from './WellnessPlanVersionLayout'

const StyledCircularProgressOverlay = styled(CircularProgressOverlay)(
  ({ theme }) => ({
    '&.MuiGrid-root': {
      width: `calc(100% - ${theme.spacing(3)})`,
      height: `calc(100% - ${theme.spacing(2)})`,
    },
  }),
)

export const CreateWellnessPlanVersionPageNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { wellnessPlanVersionId } = useParams()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const isSaving = useSelector(getWellnessPlansIsSaving)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)
  const lastCreatedWellnessPlanVersionId = useSelector(
    getLastCreatedWellnessPlanVersionId,
  )

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.WP_ACTIVATING_WARNING,
  })

  const { isClone, isEdit, planToEdit, versionTemplate } =
    useWellnessPlanVersionData(wellnessPlanVersionId)

  const wellnessPlanVersionTemplate = isEdit ? planToEdit : versionTemplate

  const [wellnessPlanVersionCandidate, setWellnessPlanVersionCandidate] =
    useState(wellnessPlanVersionTemplate)

  const stepComponentRef = useRef<CreateWellnessPlanVersionPageHandle>(null)

  const tryUpdateVersion = () => {
    const updatedVersion =
      stepComponentRef.current?.get?.() as WellnessPlanVersion
    if (updatedVersion) {
      setWellnessPlanVersionCandidate(updatedVersion)
    }
  }

  const validate = () => stepComponentRef.current?.validate?.() ?? true

  const {
    currentStep,
    activePostActivateStep,
    isPostActivateStepValid,
    goToPostActivateStep,
    StepComponents,
    StepComponent,
    hasPrevStep,
    hasNextStep,
    onStepValidStateChange,
    goToPrevStep,
    goToNextStep,
    onStepChange,
    onStepperLeftArrowClick,
    onStepperRightArrowClick,
  } = usePlanStepManager(
    wellnessPlanVersionId,
    wellnessPlanVersionCandidate,
    stepComponentRef,
    tryUpdateVersion,
    validate,
  )

  const saveWellnessPlanVersion =
    useSaveWellnessPlanVersion(goToPostActivateStep)

  const saveAsDraftWellnessPlanVersion = useSaveWellnessPlanVersion()

  const { plans = [] } = wellnessPlanVersionCandidate || {}

  useEffect(() => {
    if (wellnessPlanVersionTemplate) {
      setWellnessPlanVersionCandidate(wellnessPlanVersionTemplate)
    }
  }, [wellnessPlanVersionTemplate])

  const togglePlan = (wellnessPlan: WellnessPlan) => {
    const isBaseLevel = wellnessPlan.level === WellnessPlanLevels.BASE
    const wellnessPlanIndex = plans.indexOf(wellnessPlan)
    const planTemplate = getPlanByLevel(
      wellnessPlan.level,
      versionTemplate?.plans,
    )
    const isEnabled = isBaseLevel
      ? !wellnessPlanVersionCandidate?.basePlanHidden
      : Boolean(wellnessPlan)

    const updatedCandidate = {
      ...wellnessPlanVersionCandidate,
    }

    if (isBaseLevel) {
      // for base plan just set basePlanHidden prop on version
      updatedCandidate.basePlanHidden = isEnabled
      if (isEnabled) {
        // for base plan also reset name to default
        const updatedPlan = {
          ...wellnessPlan,
          name: planTemplate?.name || '',
        }
        updatedCandidate.plans = R.update(
          wellnessPlanIndex,
          updatedPlan,
          updatedCandidate.plans || [],
        )
      }
    } else if (isEnabled) {
      updatedCandidate.plans = R.without(
        [wellnessPlan],
        updatedCandidate.plans || [],
      )
    } else if (planTemplate) {
      const index = R.findLastIndex(
        (plan) => plan.level < wellnessPlan.level,
        updatedCandidate.plans || [],
      )
      updatedCandidate.plans = R.insert(
        index + 1,
        planTemplate,
        updatedCandidate.plans || [],
      )
    }

    if (wellnessPlanVersionCandidate?.id) {
      if (isEnabled) {
        updatedCandidate.deletedPlanIds = R.uniq(
          (wellnessPlanVersionCandidate.deletedPlanIds || []).concat(
            wellnessPlan.id,
          ),
        )
      } else if (wellnessPlan) {
        updatedCandidate.deletedPlanIds = R.without(
          [wellnessPlan.id],
          updatedCandidate.deletedPlanIds || [],
        )
      }
    }

    setWellnessPlanVersionCandidate(updatedCandidate as WellnessPlanVersion)
  }

  const savePlanVersion = (wellnessPlanVersion: WellnessPlanVersion) => {
    if (validate()) {
      openConfirmAlert({
        applyCustomMessage: true,
        message: t('WellnessPlans:ACTIVATING.CONFIRMATION_WARNING_MESSAGE', {
          versionNumber: wellnessPlanVersion.versionNumber,
        }),
        okButtonText: t('WellnessPlans:ACTIVATING.YES_ACTIVATE'),
        cancelButtonText: t('Common:NO_GO_BACK'),
        preventShowAgainCheckBox: false,
        onConfirm: (proceed) => {
          if (proceed) {
            saveWellnessPlanVersion(wellnessPlanVersion)
          }
        },
      })
    }
  }

  const activatePlan = () => {
    savePlanVersion({
      ...wellnessPlanVersionCandidate,
      active: true,
    } as WellnessPlanVersion)
  }

  const savePlanAsDraft = () => {
    if (validate()) {
      saveAsDraftWellnessPlanVersion({
        ...wellnessPlanVersionCandidate,
        active: false,
      } as WellnessPlanVersion)
    }
  }

  const onForceSaveRequested = (currentActiveId: string) => {
    savePlanVersion({
      ...wellnessPlanVersionCandidate,
      active: true,
      currentActiveId,
    } as WellnessPlanVersion)
  }

  useWellnessPlanVersionActivationAlert(
    wellnessPlanVersionCandidate,
    onForceSaveRequested,
  )

  const [saveWellnessPlanRenewalMappings, { loading }] =
    useSaveWellnessPlanRenewalMappingsMutation({
      refetchQueries: [
        {
          query: WellnessPlanVersionsDocument,
          variables: { limit: 100, offset: 0 },
        },
      ],
    })

  const handleFinish = () => {
    const input =
      stepComponentRef.current?.get() as SaveWellnessPlanRenewalMappingInput[]

    if (!input?.length) return
    saveWellnessPlanRenewalMappings({
      variables: {
        input,
      },
      onCompleted: () => {
        navigate(
          `/admin/catalog/wellness-plans/${lastCreatedWellnessPlanVersionId || wellnessPlanVersionCandidate?.id}`,
        )
      },
      onError: (error) => {
        dispatch(registerWarnAlert(getApolloErrorMessage(error)))
      },
    })
  }

  return (
    <WellnessPlanVersionLayout>
      <WellnessPlanVersionLayout.Header>
        <Stepper
          activeStep={currentStep}
          components={StepComponents}
          disableNavigationButtons={Boolean(activePostActivateStep)}
          id="wellness-plan-wizard-stepper"
          paddingMultiplier={0}
          steps={[...StepsList, PostActivateSteps.PLAN_LINKING]}
          onLeftArrowClick={onStepperLeftArrowClick}
          onRightArrowClick={onStepperRightArrowClick}
          onStepChange={onStepChange}
        />
      </WellnessPlanVersionLayout.Header>
      <WellnessPlanVersionLayout.Body>
        {wellnessPlanVersionCandidate && globalBenefitGroups.length > 0 ? (
          <StepComponent
            isClone={isClone}
            isEdit={isEdit}
            ref={stepComponentRef}
            setWellnessPlanVersion={setWellnessPlanVersionCandidate}
            togglePlan={togglePlan}
            wellnessPlanVersion={wellnessPlanVersionCandidate}
            wellnessPlanVersionTemplate={wellnessPlanVersionTemplate}
            onValidStateChange={onStepValidStateChange}
          />
        ) : (
          <StyledCircularProgressOverlay
            open
            transparent
            preloaderText={t('Common:LOADING')}
          />
        )}
      </WellnessPlanVersionLayout.Body>
      <WellnessPlanVersionLayout.Footer>
        {hasPrevStep && (
          <Grid item>
            <BackButton
              label={t('Common:BACK_ACTION')}
              onClick={goToPrevStep}
            />
          </Grid>
        )}

        {activePostActivateStep ? (
          <Tooltip
            title={
              isPostActivateStepValid ? undefined : (
                <Box sx={{ maxWidth: 230 }}>
                  {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.FINISH_TOOLTIP')}
                </Box>
              )
            }
          >
            <Box>
              <ButtonWithLoader
                disabled={!isPostActivateStepValid}
                loading={loading}
                onClick={handleFinish}
              >
                {t('Common:FINISH_ACTION')}
              </ButtonWithLoader>
            </Box>
          </Tooltip>
        ) : hasNextStep ? (
          <ButtonWithLoader onClick={goToNextStep}>
            {t('Common:NEXT_ACTION')}
          </ButtonWithLoader>
        ) : (
          <>
            <ButtonWithLoader loading={isSaving} onClick={activatePlan}>
              {t('Common:ACTIVATE_ACTION')}
            </ButtonWithLoader>
            <ButtonWithLoader
              color="secondary"
              loading={isSaving}
              onClick={savePlanAsDraft}
            >
              {t('Common:SAVE_AS_A_DRAFT')}
            </ButtonWithLoader>
          </>
        )}
      </WellnessPlanVersionLayout.Footer>
    </WellnessPlanVersionLayout>
  )
}
