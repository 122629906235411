export const ShareMenuItemType = {
  MEDICAL_HISTORY: 'MEDICAL_HISTORY',
  PROOF_OF_VACCINATION: 'PROOF_OF_VACCINATION',
  RABIES_CERTIFICATE: 'RABIES_CERTIFICATE',
}

export const NewMenuItemType = {
  ADDENDUM: 'ADDENDUM',
  APPOINTMENT_SOAP: 'APPOINTMENT_SOAP',
  ATTACHMENT: 'ATTACHMENT',
  CONVERSATION: 'CONVERSATION',
  DIAGNOSIS_HISTORY: 'DIAGNOSIS_HISTORY',
  PAST_MEDICAL_HISTORY: 'PAST_MEDICAL_HISTORY',
  ESTIMATE: 'ESTIMATE',
  NOTE: 'NOTE',
  OTC_REFILL: 'OTC_REFILL',
  PATIENT: 'PATIENT',
  PET_FRIEND: 'PET_FRIEND',
  PREVIOUS_MEDICAL_RECORD: 'PREVIOUS_MEDICAL_RECORD',
  REMINDER: 'REMINDER',
  SOAP: 'SOAP',
  TASK: 'TASK',
  VACCINE_HISTORY: 'VACCINE_HISTORY',
  VITALS: 'VITALS',
}

export const createMenuItem = (
  name: string,
  value: string,
  enabled: boolean = true,
) => ({
  name,
  value,
  disabled: !enabled,
})

export const onSelectMenuItem =
  (actionsMap: Record<string, () => void>) => (value: string) => {
    const action = actionsMap[value]
    if (action) {
      action()
    } else {
      // eslint-disable-next-line no-console
      console.error(`There is no action handler for menu item: ${value}`)
    }
  }
