import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import { ClassesType, Defaults, Nil, Utils } from '@pbt/pbt-ui-components'
import { Info } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'
import { ColorVariant } from '~/constants/colors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { SlotType } from '~/constants/schedulerConstants'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import { useAlertType } from '~/store/hooks/patient'
import { getEventType, getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { ScheduleSlot } from '~/types'
import useDialog from '~/utils/useDialog'

import BusyCard from './cards/BusyCard'
import ColumnCard from './cards/ColumnCard'
import CondensedCard from './cards/CondensedCard'
import MediumCard from './cards/MediumCard'
import ShortCard from './cards/ShortCard'
import AppointmentPopper from './scheduler/appointment/AppointmentPopper'
import {
  getAppointmentColorState,
  getAppointmentWarningLabel,
} from './timetableUtils'

const useStyles = makeStyles(
  (theme) => ({
    rootOverflowHidden: {
      overflow: 'hidden',
    },
    root: {
      userSelect: 'none',
      position: 'relative',
      cursor: 'pointer',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
      borderRadius: 2,
      border: '1px solid',
    },
    appointmentColor: {
      borderLeft: (props: UseStylesProps) =>
        props.appointmentColor ? 'none' : undefined,
      paddingLeft: (props: UseStylesProps) =>
        props.appointmentColor ? theme.spacing(0.5) : undefined,
      '&:before': {
        top: '-2px',
        left: 0,
        position: 'absolute',
        pointerEvents: 'none',
        content: (props: UseStylesProps) =>
          props.appointmentColor ? '""' : undefined,
        width: theme.spacing(0.75),
        height: 'calc(100% + 4px)',
        backgroundColor: (props: UseStylesProps) =>
          props.appointmentColor || undefined,
      },
    },
    fullWidthRoot: {
      width: '100%',
    },
    halfWidthRoot: {
      width:
        theme.constants.schedulerColumnWidth / 2 -
        theme.constants.schedulerCardBorderWidth,
    },
    warningRoot: {
      borderWidth: 2,
      backgroundColor: '#FEFAF7',
      borderColor: theme.colors.important,
    },
    disabledRoot: {
      backgroundColor: '#FAFAFA',
      borderColor: theme.colors.filterBorderColor,
      borderWidth: 2,
    },
    normalRoot: {
      borderWidth: 2,
      backgroundColor: '#FCFCFC',
      borderColor: theme.colors.markerHighlighted,
    },
    successRoot: {
      borderWidth: 2,
      backgroundColor: theme.colors.successBackground,
      borderColor: theme.colors.success,
    },
    alertedRoot: {
      borderColor: (props: UseStylesProps) =>
        props.alertColor || theme.colors.alertLabelError,
    },
    notAvailableRoot: {
      border: 'none',
    },
    busyRoot: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below', 2),
      backgroundColor: theme.colors.listItemHover,
    },
    markedBusyRoot: {
      borderColor: theme.colors.sideText,
      '&:hover': {
        boxShadow: theme.constants.blockShadow,
      },
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
    },
    reservedRoot: {
      border: theme.constants.tableBorderSelected,
      borderStyle: 'dashed',
      borderWidth: 2,
      backgroundColor: theme.colors.contentBackground,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
    },
    reservedClickThrough: {
      zIndex: 3,
    },
    warningLabel: {
      backgroundColor: theme.colors.actionNeededBackground,
      color: theme.colors.important,
    },
    disabledLabel: {
      backgroundColor: theme.colors.disabledLabel,
      color: theme.colors.disabledLabelText,
    },
    normalLabel: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
      color: theme.colors.title,
    },
    successLabel: {
      backgroundColor: theme.colors.successBackground,
      color: theme.colors.success,
    },
    overShadow: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above'),
    },
    infoButton: {
      padding: 0,
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    infoButtonShort: {
      top: 6,
      right: 0,
    },
  }),
  { name: 'TimetableEventCard' },
)

export interface TimetableEventCardProps {
  appointmentId: string | Nil
  canOpenPopup?: boolean
  classes?: ClassesType<typeof useStyles>
  contentStyle?: React.CSSProperties
  draggedItemId?: string
  isCondensed?: boolean
  isHalfWidth?: boolean
  isMedium?: boolean
  isShort?: boolean
  onClick: (appointmentId: string | Nil) => void
  onClose: () => void
  rootStyle?: React.CSSProperties
  showAppointmentType?: boolean
  showHourRange?: boolean
  showSpace?: boolean
  slot: ScheduleSlot
}

interface UseStylesProps {
  alertColor: string
  appointmentColor: string | Nil
  classes?: ClassesType<typeof useStyles>
}

const TimetableEventCard = ({
  classes: classesProp,
  canOpenPopup,
  showHourRange,
  showAppointmentType = true,
  showSpace = true,
  onClick,
  onClose,
  appointmentId,
  slot: { interval, type },
  isHalfWidth,
  draggedItemId,
  isCondensed = false,
  isMedium = false,
  isShort = false,
  rootStyle,
  contentStyle,
}: TimetableEventCardProps) => {
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const patient = useSelector(getPatient(appointment?.patient))
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const EventType = useSelector(getEventType)
  const isAppointmentReservedEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_RESERVED),
  )

  const BusyEventType = Utils.findConstantByName('Busy', EventType)
  const BusySubtypes = BusyEventType && BusyEventType.subTypes
  const ReservedEventId = Utils.findConstantIdByName('Reserved', BusySubtypes)

  const rootRef = useRef<HTMLDivElement>(null)

  const isNotAvailable = type === SlotType.NOT_AVAILABLE
  const isReserved = appointment?.type?.id === ReservedEventId
  const isBusy = isNotAvailable || type === SlotType.BUSY
  const showInfoButton =
    !isBusy || (Boolean(appointment?.notes) && !isAppointmentReservedEnabled)

  const appointmentColor =
    isAppointmentReservedEnabled && isBusy
      ? undefined
      : appointment?.businessAppointmentType?.color

  const [popperOpen, setPopperOpen] = useState(false)

  const closeAppointmentPopper = () => {
    setPopperOpen(false)
    onClose()
  }

  useEffect(() => {
    if (!canOpenPopup) {
      setPopperOpen(false)
    }
  }, [canOpenPopup])

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const showAppointmentPopper = () => {
    if (!draggedItemId && !popperOpen) {
      setPopperOpen(true)
      onClick(appointmentId)
    }
  }

  const scheduleShowAppointment = useDebouncedCallback(
    showAppointmentPopper,
    Defaults.SCHEDULE_TOOLTIP_DELAY,
  )
  const scheduleHideAppointment = useDebouncedCallback(
    closeAppointmentPopper,
    Defaults.SCHEDULE_TOOLTIP_DELAY,
  )

  const onMouseEnter = () => {
    if (popperOpen) {
      scheduleHideAppointment.cancel()
    } else {
      scheduleShowAppointment()
    }
  }

  const onMouseLeave = () => {
    if (popperOpen) {
      scheduleHideAppointment()
    } else {
      scheduleShowAppointment.cancel()
    }
  }

  /**
   * Cancel delayed event handlers
   */
  const cleanUp = () => {
    scheduleShowAppointment.cancel()
    scheduleHideAppointment.cancel()
  }

  useEffect(() => cleanUp, [])

  const warningMessage = getAppointmentWarningLabel({
    from: interval?.from,
    to: interval?.to,
    stateName: appointment?.state?.name,
  })
  const showWarning = Boolean(warningMessage)

  const colorState = getAppointmentColorState(appointment?.state?.name)
  const isWarningState = colorState === ColorVariant.IMPORTANT || showWarning
  const isDisabledState = colorState === ColorVariant.DISABLED || isBusy
  const isSuccessState = colorState === ColorVariant.SUCCESS
  const isNormalState =
    colorState === ColorVariant.NORMAL && !isBusy && !isWarningState

  const isSmallInfoButton = isShort || isMedium

  const onCardClick = () => {
    openAppointmentDialog({
      appointmentId,
    })
  }

  const onInfoButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (popperOpen) {
      scheduleHideAppointment.cancel()
      closeAppointmentPopper()
    } else {
      scheduleShowAppointment.cancel()
      showAppointmentPopper()
    }
  }

  const onInfoButtonMouseLeave = () => {
    scheduleShowAppointment.cancel()
  }

  const { alertsToRender } = useAlertType(appointment?.patient)

  const showAlertLabel = alertsToRender.length > 0
  const shouldShowPopper = !isNotAvailable

  const alertColor = useGetAlertColor(
    patient?.alertColorId,
    AlertColorLevel.PATIENT,
  )
  // TODO: Migrate this component to `styled`
  // useStyles is buggy with psuedoelements
  const classes = useStyles({
    classes: classesProp,
    alertColor,
    appointmentColor,
  })

  const AppointmentCardComponent = R.cond([
    [R.always(isShort), R.always(ShortCard)],
    [R.always(isMedium), R.always(MediumCard)],
    [R.always(isCondensed), R.always(CondensedCard)],
    [R.T, R.always(ColumnCard)],
  ])()

  return (
    <>
      <Grid
        container
        className={classNames(classes.root, {
          [classes.appointmentColor]: Boolean(appointmentColor),
          [classes.fullWidthRoot]: !isHalfWidth,
          [classes.halfWidthRoot]: isHalfWidth,
          [classes.warningRoot]: isWarningState,
          [classes.disabledRoot]: isDisabledState,
          [classes.normalRoot]: isNormalState,
          [classes.successRoot]: isSuccessState,
          [classes.busyRoot]: isBusy,
          [classes.reservedRoot]: isReserved,
          [classes.reservedClickThrough]:
            isReserved && isAppointmentReservedEnabled,
          [classes.notAvailableRoot]: isNotAvailable,
          [classes.markedBusyRoot]: isBusy && !isNotAvailable,
          [classes.alertedRoot]: showAlertLabel && !isDisabledState,
          [classes.overShadow]: popperOpen,
        })}
        {...(appointmentId ? { id: appointmentId } : {})}
        ref={rootRef}
        style={rootStyle}
        wrap="nowrap"
        onClick={shouldShowPopper ? onCardClick : undefined}
        onMouseLeave={shouldShowPopper ? onMouseLeave : undefined}
      >
        <Grid container className={classes.rootOverflowHidden}>
          {isBusy ? (
            <BusyCard
              appointmentId={appointmentId}
              isNotAvailable={isNotAvailable}
              isReserved={isReserved}
              showHourRange={showHourRange}
            />
          ) : (
            <AppointmentCardComponent
              appointmentId={appointmentId!}
              contentStyle={contentStyle}
              isDisabledState={isDisabledState}
              isHalfWidth={isHalfWidth}
              isNormalState={isNormalState}
              isSuccessState={isSuccessState}
              isWarningState={isWarningState}
              showAppointmentType={showAppointmentType}
              showHourRange={showHourRange}
              showSpace={showSpace}
              warningMessage={warningMessage}
            />
          )}
          {showInfoButton && (
            <IconButton
              className={classNames(classes.infoButton, {
                [classes.warningLabel]: isWarningState,
                [classes.disabledLabel]: isDisabledState,
                [classes.normalLabel]: isNormalState,
                [classes.successLabel]: isSuccessState,
                [classes.infoButtonShort]: isShort,
              })}
              onClick={onInfoButtonClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onInfoButtonMouseLeave}
            >
              <Info fontSize={isSmallInfoButton ? 'small' : 'medium'} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {shouldShowPopper && (
        <AppointmentPopper
          anchorEl={rootRef.current}
          appointmentId={appointmentId!}
          open={popperOpen}
          onClose={closeAppointmentPopper}
          // do not close when has open dialogs
          onMouseEnter={onMouseEnter}
          onMouseLeave={hasOpenDialogs ? undefined : onMouseLeave}
        />
      )}
    </>
  )
}

export default TimetableEventCard
