import React, { ForwardedRef, forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import { SaveWellnessPlanRenewalMappingInput } from '~/api/graphql/generated/types'
import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'

import { useFetchAutoRenewingPlansInfo } from './useFetchAutoRenewingPlansInfo'
import { VerifyAutoRenewingPlansContainer } from './VerifyAutoRenewingPlansContainer'

interface AutoRenewPlanLinkingProps {
  onValidStateChange: (isValid: boolean) => void
}

export interface AutoRenewPlanLinkingHandle {
  get: () => SaveWellnessPlanRenewalMappingInput[]
}

export const AutoRenewPlanLinking = forwardRef(
  (
    { onValidStateChange }: AutoRenewPlanLinkingProps,
    ref: ForwardedRef<AutoRenewPlanLinkingHandle>,
  ) => {
    const [isValid, setIsValid] = useState(true)

    const { t } = useTranslation(['Dialogs', 'Common'])
    const { autoRenewingPlansInfo, isLoading } = useFetchAutoRenewingPlansInfo({
      filterInput: { autoRenewalOnly: true },
    })
    const { plansMap, versionsList, versionsMap, plansList } =
      autoRenewingPlansInfo

    const activeVersion = versionsList.find(
      (id) => versionsMap[id]?.active,
    ) as string

    const handleValidityChange = (isTableValid: boolean) => {
      setIsValid(isTableValid)
      onValidStateChange(isTableValid)
    }

    if (!isLoading && !versionsList.length && !plansList.length) {
      return null
    }

    return (
      <>
        <CircularProgressOverlay open={isLoading} />
        <Box minHeight={300} p={2}>
          {!isLoading && (
            <>
              {!isValid && (
                <Box pb={1}>
                  <AlertBanner
                    title={t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.ALERT')}
                    variant="error"
                  />
                </Box>
              )}
              <VerifyAutoRenewingPlansContainer
                activeVersion={activeVersion}
                handleValidityChange={handleValidityChange}
                key={activeVersion}
                plansList={plansList}
                plansMap={plansMap}
                ref={ref}
                versionsList={versionsList}
                versionsMap={versionsMap}
              />
            </>
          )}
        </Box>
      </>
    )
  },
)
