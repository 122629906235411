import * as R from 'ramda'
import {
  DateUtils,
  LanguageUtils,
  NamedEntity,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { Variation } from '~/types'
import { parse } from '~/utils'
import { toInteger } from '~/utils/number'

export const getPluralPackageLabel = (
  packageId: string,
  PackageType: NamedEntity[],
) => {
  const packageName = Utils.getConstantName(packageId, PackageType)
  const packageDisplayName = LanguageUtils.getConstantTranslatedName(
    packageId,
    PackageType,
  )
  return packageName !== 'Each'
    ? `${packageDisplayName}(s)`
    : packageDisplayName
}

// NOTE: in order to have unique id:
// singular -> id_1
// plural -> id_X
export const getPriceUnitsIdFromValue = (value: any) =>
  value && value.includes('_') ? value.split('_')[0] : value
export const getPriceUnitsValuedFromId = (id: string, isPlural: boolean) =>
  `${id}_${isPlural ? 'X' : '1'}`
export const isPluralUnit = (value: string) =>
  value && value.split('_')[1] === 'X'
export const isPackagePluralUnit = (
  InventoryPriceUnit: NamedEntity[],
  value: string,
) => {
  const id = Utils.findConstantIdByName('Per X packages', InventoryPriceUnit)
  return value === getPriceUnitsValuedFromId(id, true)
}

export const getVariationKey = (variationId: string = '', index: number = 0) =>
  JSON.stringify({ variationId, index })
export const parseVariationKey = (key: string) => parse(key)

export const getVariation = (
  variationKey: string | Nil,
  variationsMap: Record<string, Variation[]>,
): Variation | undefined => {
  const parsedKey = parseVariationKey(variationKey ?? '')
  if (!parsedKey) {
    return undefined
  }
  const { variationId, index } = parsedKey
  return R.path<Variation | undefined>([variationId, index], variationsMap)
}

export const toggleSelectedVariationsState = (
  variationsMap: Record<string, Variation[]>,
  variation: Variation,
  variationName: string,
  isFoodCatalogEnabled?: boolean,
) => {
  const alreadyExist = Boolean(variationsMap[variation.id])

  return alreadyExist
    ? R.omit([variation.id], variationsMap)
    : {
        ...variationsMap,
        [variation.id]: [
          {
            ...R.omit(['id'], variation),
            [isFoodCatalogEnabled
              ? 'globalInventoryVariationMappingId'
              : 'globalVariationId']: variation.id,
            name: variation.name || variationName,
          },
        ],
      }
}

export const toggleSelectedCustomCompoundState = (
  variationsMap: Record<string, Variation[]>,
  variation: Record<string, string>,
) => {
  const alreadyExist = Boolean(variationsMap[variation.id])
  return alreadyExist
    ? R.omit([variation.id], variationsMap)
    : {
        ...variationsMap,
        [variation.id]: [{ name: i18n.t('Common:CUSTOM_DRUG_COMPOUND') }],
      }
}

export const getVariationIndex = (
  variationKey: string,
  variationsMap: Record<string, Variation[]>,
) => {
  const parsedKey = parseVariationKey(variationKey)
  if (!parsedKey) {
    return undefined
  }
  const { variationId, index: variationIndex } = parsedKey
  const { index, found } = Object.keys(variationsMap).reduce(
    (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      { index, found },
      id,
    ) =>
      !found
        ? id === variationId
          ? { index: index + variationIndex, found: true }
          : { index: index + variationsMap[id].length, found: false }
        : { index, found },
    { index: 0, found: false },
  )
  return found ? index : undefined
}

export const getNextVariationKey = (
  variationKey: string | Nil,
  variationsMap: Record<string, Variation[]>,
) => {
  const parsedKey = parseVariationKey(variationKey ?? '')
  if (!parsedKey?.variationId) {
    return undefined
  }
  const { variationId, index } = parsedKey
  const selectedVariationIds = Object.keys(variationsMap)
  if (variationsMap[variationId]?.length > index + 1) {
    return getVariationKey(variationId, index + 1)
  }

  const currentIdIndex = selectedVariationIds.indexOf(variationId)

  return currentIdIndex < selectedVariationIds.length - 1
    ? getVariationKey(selectedVariationIds[currentIdIndex + 1], 0)
    : undefined
}

export const getPrevVariationKey = (
  variationKey: string | Nil,
  variationsMap: Record<string, Variation[]>,
) => {
  const parsedKey = parseVariationKey(variationKey ?? '')
  if (!parsedKey) {
    return undefined
  }
  const { variationId, index } = parsedKey
  const selectedVariationIds = Object.keys(variationsMap)

  if (index > 0) {
    return getVariationKey(variationId, index - 1)
  }

  const curentIdIndex = selectedVariationIds.indexOf(variationId)
  return curentIdIndex > 0
    ? getVariationKey(
        selectedVariationIds[curentIdIndex - 1],
        variationsMap[selectedVariationIds[curentIdIndex - 1]].length - 1,
      )
    : undefined
}

export const insertVariation = (
  variation: Variation | Nil,
  variationsMap: Record<string, Variation[]>,
  isFoodCatalogEnabled: boolean,
) => {
  if (!variation) {
    return variationsMap
  }
  const id: string =
    variation?.id ||
    variation[
      isFoodCatalogEnabled
        ? 'globalInventoryVariationMappingId'
        : 'globalVariationId'
    ] ||
    ''
  return {
    ...variationsMap,
    [id]: [...(variationsMap[id] || []), variation],
  }
}

export const getVariationsCount = (
  variationsMap: Record<string, Variation[]> = {},
) =>
  Object.values(variationsMap).reduce((acc, arr) => acc + (arr?.length || 0), 0)

export const addOrUpdateVariation = (
  variationKey: string | Nil,
  variation: Variation,
  variationsMap: Record<string, Variation[]>,
): Record<string, Variation[]> => {
  const parsedKey = parseVariationKey(variationKey || '')
  if (!parsedKey) {
    return variationsMap
  }
  const { variationId, index: variationIndex } = parsedKey
  const variationsWithSameId = variationsMap[variationId] || []
  return {
    ...variationsMap,
    [variationId]:
      variationIndex >= variationsWithSameId.length
        ? [...variationsWithSameId, variation]
        : variationsWithSameId.map((item, index) =>
            index === variationIndex ? variation : item,
          ),
  }
}

export const getCommonPackagingLabel =
  (InventoryProductSizeUnit: NamedEntity[], PackageType: NamedEntity[]) =>
  (item: any): string => {
    if (!item) {
      return ''
    }

    const {
      perPackageUnitsId,
      packageTypeId,
      perPackageAmount,
      count,
      saleUnitOfMeasure,
    } = item || {}

    const perPackageUnits = LanguageUtils.getConstantTranslatedName(
      perPackageUnitsId,
      InventoryProductSizeUnit,
    )
    const packageType = LanguageUtils.getConstantTranslatedName(
      packageTypeId,
      PackageType,
    )

    let initialLabel = `${perPackageAmount} ${perPackageUnits}`

    if (saleUnitOfMeasure) {
      initialLabel += ` ${saleUnitOfMeasure}`
    }

    if (count) {
      initialLabel += ` | ${count}`
    }

    if (packageType) {
      initialLabel += `/${packageType}`
    }

    return initialLabel
  }

export const getOnHandAmount =
  (InventoryProductSizeUnit: NamedEntity[], PackageType: NamedEntity[]) =>
  ({
    perPackageUnitsId,
    packageTypeId,
    perPackageAmount,
    onHandAmount,
  }: Partial<Variation> = {}) => {
    const perPackageUnits = LanguageUtils.getConstantTranslatedName(
      perPackageUnitsId,
      InventoryProductSizeUnit,
    )
    const packageType = LanguageUtils.getConstantTranslatedName(
      packageTypeId,
      PackageType,
    )
    const packageAmount =
      perPackageAmount && perPackageAmount !== 0
        ? toInteger(Number(((onHandAmount || 0) / perPackageAmount).toFixed(2)))
        : 0

    return `${packageAmount} ${packageType} (${
      onHandAmount || 0
    } ${perPackageUnits})`
  }

export const getUnitCoefficient = (
  InventoryInStockUnit: NamedEntity[],
  unitId: string,
  perPackageAmount: number,
): number => {
  const packagesUnitId = Utils.findConstantIdByName(
    'Package',
    InventoryInStockUnit,
  )

  return unitId === packagesUnitId ? perPackageAmount : 1
}

export const getMinimalAdjustmentAmountByOnHandAmount = (
  InventoryInStockUnit: NamedEntity[],
  variation: Variation | undefined,
  initialAmount: number,
  initialUnitId: string,
  directedUnitId: string,
): number => {
  const perPackageAmount = variation?.perPackageAmount || 0
  const onHandAmount = variation?.onHandAmount || 0

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const directedCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    directedUnitId,
    perPackageAmount,
  )

  const initialUnits = initialAmount * initialCoefficient

  const minimalUnits =
    onHandAmount < 0 ? initialUnits : initialUnits - onHandAmount

  const packagesUnitId = Utils.findConstantIdByName(
    'Package',
    InventoryInStockUnit,
  )
  const isPackageDirected = packagesUnitId === directedUnitId

  const preparedUnits = minimalUnits / directedCoefficient

  return isPackageDirected
    ? toInteger(preparedUnits)
    : Number(Utils.round(preparedUnits, 4))
}

export const getDirectAdjustmentDelta = (
  InventoryInStockUnit: NamedEntity[],
  variation: Variation | undefined,
  initialAmount: number,
  initialUnitId: string,
  directedAmount: number,
  directedUnitId: string,
  // eslint-disable-next-line max-params
): number => {
  const perPackageAmount = variation?.perPackageAmount || 0
  const onHandAmount = variation?.onHandAmount || 0

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const directedCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    directedUnitId,
    perPackageAmount,
  )

  const initialUnits = initialAmount * initialCoefficient
  const directedUnits = directedAmount * directedCoefficient

  return Number(
    Utils.round(
      (directedUnits - (onHandAmount - initialUnits)) / directedCoefficient,
      4,
    ),
  )
}

export const getVariationWithAddedOnHandAmount = (
  InventoryInStockUnit: NamedEntity[],
  variation: Variation | undefined,
  initialAmount: number,
  initialUnitId: string,
  addAmount: number,
  addUnitId: string,
  // eslint-disable-next-line max-params
): Variation => {
  const perPackageAmount = variation?.perPackageAmount || 0
  const onHandAmount = variation?.onHandAmount || 0

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const addCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    addUnitId,
    perPackageAmount,
  )

  const relativeAdjustment =
    addAmount * addCoefficient - initialAmount * initialCoefficient

  return {
    ...variation,
    onHandAmount: Utils.round(onHandAmount + relativeAdjustment, 4),
  } as Variation
}

export const getReceivedDate = R.pipe(
  R.prop('receivedDate'),
  DateUtils.formatDate,
)
export const getCreationDate = R.pipe(
  R.prop('creationDate'),
  DateUtils.formatDate,
)

export const pluckStorageLocation: (items: any[]) => string[] = R.pipe(
  R.pluck('storageLocationId') as (items: any[]) => string[],
  R.filter(Boolean),
  R.uniq,
)
