/* eslint-disable max-lines */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  BlobWithName,
  Calendar,
  CustomFieldValidatorState,
  ImageScalingUtils,
  LanguageUtils,
  NamedEntity,
  Nil,
  Patient as PatientType,
  PermissionArea,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import {
  isValidTimestamp,
  notEmptyFormField,
} from '@pbt/pbt-ui-components/src/utils/validation'

import Avatar from '~/components/common/Avatar'
import BreedAutocomplete from '~/components/common/inputs/BreedAutocomplete'
import FileInput, {
  FileInputHandle,
} from '~/components/common/inputs/file-input/FileInput'
import GenderField from '~/components/common/inputs/GenderField'
import PatientSizeSelect from '~/components/common/inputs/PatientSizeSelect'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import SpayedNeuteredField from '~/components/common/inputs/SpayedNeuteredField'
import SpeciesSelect from '~/components/common/inputs/SpeciesSelect'
import AlertLabel from '~/components/common/labels/AlertLabel'
import PuiSwitch from '~/components/common/PuiSwitch'
import DateOfBirthSelector from '~/components/common/selects/DateOfBirthSelector'
import { useGetAcquiredTypeList } from '~/constants/acquiredTypes'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { editPatient } from '~/store/actions/patients'
import { useAlertType } from '~/store/hooks/patient'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessSendRemindersEnabled,
} from '~/store/reducers/auth'
import {
  getActivityLevel,
  getAnimalEnvironment,
  getCountryInsuranceProviderByCode,
  getServiceAnimal,
  getSize,
} from '~/store/reducers/constants'
import { getPatientPreferencesIsLoading } from '~/store/reducers/patientPreferences'
import {
  getCurrentPatientId,
  getPatient,
  getPatientsIsLoading,
} from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { AlertsConfig, EasterEggEvents } from '~/types'
import { isBool, isFieldValuesChanged } from '~/utils'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '../../link-chewy-account/LinkedChewyAccountContainer'
import { PreferencesDisplayWidget } from '../../preferences/PreferencesDisplayWidget'
import ReferredBySelect, {
  joinReferredByIds,
  parseReferredByIds,
} from '../ReferredBySelect'
import { useGetPatientPreferencesViewData } from './useGetPatientPreferencesViewData'

const AVATAR_SIZE = 108

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 3, 3),
      },
    },
    button: {
      minWidth: 200,
      textTransform: 'none',
      boxShadow: 'none',
    },
    buttonInactive: {
      backgroundColor: '#FFFFFF',
      border: theme.constants.inactiveBorder,
      color: theme.colors.sideText,
    },
    buttonError: {
      border: `1px solid ${theme.colors.errorColor}`,
    },
    fabContainer: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    alignRight: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    alertPlusButton: {
      backgroundColor: theme.colors.errorColor,
      '&&&&:hover': {
        backgroundColor: theme.colors.errorColor,
      },
    },
    alertsRoot: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(7),
      justifyContent: 'flex-start',
    },
    sizeButton: {
      width: '24vw',
    },
    imageInputLogoArea: {
      height: 180,
    },
    imageInputButtonsContainer: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
    },
    imageInputButtons: {
      width: 200,
      maxWidth: '45%',
      [theme.breakpoints.down('md')]: {
        width: 300,
        maxWidth: '100%',
      },
    },
    profilePicContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    profilePic: {
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE,
      boxShadow:
        '0 14px 26px -12px rgba(0, 0, 0, 0.22), 0 4px 23px 0px rgba(0, 0, 0, 0.07), 0 8px 10px -5px rgba(0, 0, 0, 0.1)',
    },
    chewyIcon: {
      width: 24,
      height: 24,
    },
  }),
  { name: 'Patient' },
)

export interface PatientHandle {
  addPatient: (addAnother?: boolean) => void
  getHasUnsavedChanges: () => boolean
}

interface PatientBaseProps {
  clientId: string | Nil
  createPatient: (
    clientId: string,
    patient: Omit<PatientType, 'id'>,
    avatarBlob?: BlobWithName | Nil,
  ) => void
  onOk?: () => void
}

interface PatientViaId extends PatientBaseProps {
  patient?: never
  patientId: string | Nil
}

interface PatientViaProps extends PatientBaseProps {
  patient: Partial<PatientType>
  patientId?: never
}

type PatientProps = PatientViaId | PatientViaProps

const Patient = forwardRef<PatientHandle, PatientProps>(function Patient(
  { onOk, clientId, patientId, patient: inputPatient, createPatient },
  ref,
) {
  const navigate = useNavigate()
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const [openAddAlertsDialog] = useDialog(DialogNames.ADD_ALERTS_DIALOG)
  const [openPatientPreferencesDialog] = useDialog(
    DialogNames.PATIENT_PREFERENCES,
  )
  const { t } = useTranslation(['Common', 'Clients'])

  const dispatch = useDispatch()
  const client = useSelector(getUser(clientId))
  const storePatient = useSelector(getPatient(patientId))
  const patient = { ...inputPatient, ...storePatient }
  const createdPatientId = useSelector(getCurrentPatientId)
  const currentBusiness = useSelector(getCurrentBusiness)
  const patientIsLoading = useSelector(getPatientsIsLoading)
  const patientPreferencesIsLoading = useSelector(
    getPatientPreferencesIsLoading,
  )
  const Size = useSelector(getSize)
  const AnimalEnvironment = useSelector(getAnimalEnvironment)
  const ActivityLevel = useSelector(getActivityLevel)
  const InsuranceProvider = useSelector(
    getCountryInsuranceProviderByCode(currentBusiness?.countryCatalogCode),
  )
  const ServiceAnimal = useSelector(getServiceAnimal)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const currentBusinessSendRemindersEnabled = useSelector(
    getCurrentBusinessSendRemindersEnabled,
  )

  const showSendRemindersFlag =
    currentBusinessSendRemindersEnabled && client?.sendReminders
  const patientSendRemindersInitial =
    currentBusinessSendRemindersEnabled && client?.sendReminders

  const isEdit = patient?.id
  const { AcquiredTypeItemsList } = useGetAcquiredTypeList()

  const avatarScaledServingUrl = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const getActiveState = ({ active = true } = {}) => Boolean(active)
  const [isLogoEditing, setIsLogoEditing] = useState(!avatarScaledServingUrl)
  const [photo, setPhoto] = useState(isEdit ? avatarScaledServingUrl : null)
  const [closeAfterCreation, setCloseAfterCreation] = useState(false)
  const [avatarSelected, setAvatarSelected] = useState(false)
  const [savePatientOnAvatarReady, setSavePatientOnAvatarReady] =
    useState(false)
  const [addAnother, setAddAnother] = useState(false)
  const fileInputRef = useRef<FileInputHandle>(null)

  // gcp file upload flow
  const [avatarBlob, setAvatarBlob] = useState<BlobWithName | null>(null)

  const [alertColorId, setAlertColorId] = useState<string | Nil>(
    patient?.alertColorId,
  )

  const patientAlerts = useAlertType(patientId)
  const [alerts, setAlerts] = useState(patientAlerts.alertsEntities || [])
  const [alertTypeIds, setAlertTypeIds] = useState(
    patientAlerts.alertsIds || [],
  )
  const [customAlertType, setCustomAlertType] = useState(
    patientAlerts.customAlert || '',
  )

  const deceasedDateValidator = ({
    state: { deceased, deceasedDate },
  }: CustomFieldValidatorState) =>
    deceased
      ? notEmptyFormField(deceasedDate) && isValidTimestamp(deceasedDate)
      : true

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Common:NAME'),
        validators: ['required'],
        initialValue: patient?.name || '',
      },
      {
        name: 'dob',
        label: t('Common:DATE_OF_BIRTH'),
        validators: ['timestamp'],
        initialValue: patient?.dateOfBirth || '',
      },
      {
        name: 'approximateAge',
        label: t('Common:APPROXIMATE_AGE'),
        type: 'toggle',
        initialValue: patient?.approximateAge || '',
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        validators: ['required'],
        initialValue: patient?.species || '',
      },
      {
        name: 'color',
        label: t('Common:COLOR'),
        initialValue: patient?.color || '',
      },
      {
        name: 'size',
        label: t('Common:SIZE'),
        type: 'select',
        initialValue: patient?.size || '',
      },
      {
        name: 'breed',
        label: t('Common:BREED'),
        type: 'select',
        initialValue: patient?.breeds || [] || '',
      },
      {
        name: 'gender',
        label: t('Common:GENDER'),
        validators: ['required'],
        initialValue: patient?.gender || '',
      },
      {
        name: 'spayedNeutered',
        label: t('Common:SPAYED'),
        validators: ['required'],
        initialValue: isBool(patient?.spayedNeutered)
          ? patient?.spayedNeutered
          : null,
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: patient?.notes || '',
      },
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: getActiveState(patient),
      },
      {
        name: 'deceased',
        label: t('Common:DECEASED'),
        type: 'toggle',
        initialValue: Boolean(patient?.deceased),
      },
      {
        name: 'deceasedDate',
        label: t('Common:DECEASED_DATE'),
        validators: [
          { validator: deceasedDateValidator, validatorName: 'deceasedDate' },
        ],
        initialValue: patient?.deceasedDate || '',
      },
      {
        name: 'sendReminders',
        label: t('Common:SEND_REMINDERS'),
        type: 'toggle',
        initialValue: patient?.sendReminders ?? patientSendRemindersInitial,
      },
      {
        name: 'diet',
        label: t('Common:DIET_PATIENT'),
        initialValue: patient?.diet || '',
      },
      {
        name: 'knownAllergies',
        label: t('Common:KNOWN_ALLERGIES'),
        initialValue: patient?.allergies || '',
      },
      {
        name: 'acquiredType',
        label: t('Common:PET_ACQUIRED_TYPE'),
        type: 'select',
        initialValue: patient?.acquiredType || '',
      },
      {
        name: 'date',
        label: t('Common:DATE_TIME'),
        validators: ['timestamp'],
        initialValue: patient?.acquiredDate || '',
      },
      {
        name: 'environment',
        label: t('Common:PET_ENVIRONMENT'),
        type: 'select',
        initialValue: patient?.environment || '',
      },
      {
        name: 'activityLevel',
        label: t('Clients:PATIENT_SECTION.LABEL.ACTIVITY_LEVEL'),
        type: 'select',
        initialValue: patient?.activityLevel || '',
      },
      {
        name: 'insurance',
        label: t('Common:INSURANCE'),
        type: 'select',
        initialValue: patient?.insuranceProvider || '',
      },
      {
        name: 'policyNumber',
        label: t('Clients:PATIENT_SECTION.LABEL.POLICY_NUMBER_INSURANCE'),
        initialValue: patient?.insurancePolicyNumber || '',
      },
      {
        name: 'microchip',
        label: t('Common:MICROCHIP_NUMBER_SIGN'),
        initialValue: patient?.microchipNumber || '',
      },
      {
        name: 'serviceAnimalType',
        label: t('Common:SERVICE_DESIGNATION'),
        type: 'select',
        initialValue: patient?.serviceAnimalType || '',
      },
      {
        name: 'referredBy',
        label: t('Common:REFERRED_BY'),
        type: 'select',
        initialValue:
          joinReferredByIds(
            patient?.referralSourceId,
            patient?.referralSourceTypeId,
          ) || '',
      },
      {
        name: 'rabiesTag',
        label: t('Common:RABIES_TAG'),
        type: 'text',
        initialValue: patient?.rabiesTag || '',
      },
      {
        name: 'rabiesTagExpiration',
        label: t('Common:RABIES_TAG_EXPIRATION'),
        validators: ['timestamp'],
        initialValue: patient?.rabiesTagExpiration || '',
      },
      {
        name: 'markingsTattoo',
        label: t('Common:MARKINGS_OR_TATTOO'),
        type: 'text',
        initialValue: patient?.markingsTattoo || '',
      },
      {
        name: 'license',
        label: t('Common:LICENSE_NUMBER'),
        type: 'text',
        initialValue: patient?.license || '',
      },
      {
        name: 'schedule',
        label: t('Common:SCHEDULE_NOUN'),
        type: 'text',
        initialValue: patient?.schedule || '',
      },
    ],
    false,
  )

  const {
    name,
    dob,
    approximateAge,
    species,
    color,
    size,
    breed,
    gender,
    spayedNeutered,
    notes,
    active,
    deceased,
    deceasedDate,
    sendReminders,
    diet,
    knownAllergies,
    acquiredType,
    date,
    environment,
    activityLevel,
    insurance,
    policyNumber,
    microchip,
    serviceAnimalType,
    referredBy,
    rabiesTag,
    rabiesTagExpiration,
    markingsTattoo,
    license,
    schedule,
  } = fields

  useEffectExceptOnMount(() => {
    if (active.value) {
      deceased.setValue(false)
    } else {
      sendReminders.setValue(false)
    }
  }, [active.value])

  useEffectExceptOnMount(() => {
    if (deceased.value) {
      active.setValue(false)
      sendReminders.setValue(false)
    }
  }, [deceased.value])

  const resetForm = () => {
    setPhoto(null)
    setAvatarBlob(null)
    reset()
  }

  useEffect(() => {
    if (!patientIsLoading && closeAfterCreation) {
      if (!isEdit && createdPatientId) {
        navigate(`/client/${clientId}/patient/${createdPatientId}`)
      }
      if (onOk) {
        onOk()
      }
      setAvatarBlob(null)
    }
  }, [closeAfterCreation, onOk, patientIsLoading, createdPatientId])

  const getPatientFields = (): Omit<PatientType, 'id'> => {
    const [referredById, referredByType] =
      parseReferredByIds(referredBy.value) || []
    const preparedAlertTypes = [
      ...R.pluck('name', alerts),
      customAlertType,
    ].filter(Boolean)

    return {
      name: name.value,
      dateOfBirth: dob.value,
      approximateAge: approximateAge.value,
      species: species.value,
      color: color.value,
      size: size.value,
      breeds: breed.value,
      gender: gender.value,
      spayedNeutered: spayedNeutered.value,
      notes: notes.value,
      diet: diet.value,
      allergies: knownAllergies.value,
      acquiredType: acquiredType.value,
      acquiredDate: date.value,
      environment: environment.value,
      activityLevel: activityLevel.value,
      insuranceProvider: insurance.value,
      insurancePolicyNumber: policyNumber.value,
      microchipNumber: microchip.value,
      serviceAnimalType: serviceAnimalType.value,
      alertTypes: preparedAlertTypes,
      alertTypeIds,
      customAlertType,
      alertColorId,
      active: active.value,
      deceased: deceased.value,
      deceasedDate: deceasedDate.value,
      sendReminders: sendReminders.value,
      referralSourceId: referredById,
      referralSourceTypeId: referredByType,
      rabiesTag: rabiesTag.value,
      rabiesTagExpiration: rabiesTagExpiration.value,
      markingsTattoo: markingsTattoo.value,
      license: license.value,
      schedule: schedule.value,
    }
  }

  const addAvatarAndSave = (
    addAnotherAvatar: boolean,
    selected: boolean,
    newPhoto: PatientType['photo'],
  ) => {
    if (validate()) {
      const newPatient = getPatientFields()

      dispatch(
        queueEasterEggEvent({
          actionType: EasterEggEvents.UPDATE_PATIENT_DETAILS,
        }),
      )

      if (selected) {
        setSavePatientOnAvatarReady(true)
        setPhoto(null)
        setAddAnother(addAnotherAvatar)
        fileInputRef?.current?.crop()
        return
      }

      if (clientId) {
        if (isEdit) {
          dispatch(
            editPatient(
              clientId,
              { ...newPatient, id: patient.id! },
              avatarBlob,
            ),
          )
        } else {
          createPatient(
            clientId,
            { ...newPatient, photo: newPhoto },
            avatarBlob,
          )
        }
      }

      if (addAnotherAvatar) {
        resetForm()
      }

      if (!addAnotherAvatar) {
        setCloseAfterCreation(true)
      }

      if (isMobile) {
        if (isEdit) {
          navigate(`/client/${clientId}/patient/${patient.id}`)
        } else if (addAnotherAvatar) {
          resetForm()
        }
      }
    }
  }

  useEffect(() => {
    if (savePatientOnAvatarReady && photo) {
      addAvatarAndSave(addAnother, false, photo)
      setSavePatientOnAvatarReady(false)
    }
  }, [savePatientOnAvatarReady, photo])

  const addPatient = (addAnotherAvatar: boolean | undefined = false) => {
    addAvatarAndSave(addAnotherAvatar, avatarSelected, photo)
  }

  useImperativeHandle(ref, () => ({
    addPatient,
    getHasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  const onAvatarReadyV2 = ({ blob }: { blob: BlobWithName }) => {
    setAvatarSelected(false)
    setAvatarBlob(blob)
    setIsLogoEditing(false)
    setPhoto(URL.createObjectURL(blob))
  }

  const onAvatarSelected = () => {
    setAvatarSelected(true)
    setSavePatientOnAvatarReady(false)
  }

  const onAvatarCancel = () => {
    setIsLogoEditing(false)
    setAvatarBlob(null)
    setAvatarSelected(false)
  }

  const onAlertsEdited = (alertsConfig?: AlertsConfig) => {
    setAlerts(alertsConfig?.alerts || [])
    setAlertTypeIds(alertsConfig?.alertTypesIds || [])
    setCustomAlertType(alertsConfig?.customAlertType || '')
    setAlertColorId(alertsConfig?.alertColorId)
  }

  const preferences = useGetPatientPreferencesViewData(patientId)

  const onAddPreferences = () => {
    if (!patientPreferencesIsLoading) {
      openPatientPreferencesDialog({ patientId })
    }
  }

  const handleEditAlerts = () =>
    openAddAlertsDialog({
      alertColorId,
      alerts,
      customAlertType,
      patientName: patient?.name,
      onOk: onAlertsEdited,
    })

  const avatarSrc = avatarBlob
    ? URL.createObjectURL(avatarBlob)
    : avatarScaledServingUrl || photo

  return (
    <Grid container alignItems="center" className={classes.root} spacing={2}>
      {isEdit && (
        <Grid item xs={12}>
          <LinkedChewyAccountContainer
            accountType={KyriosAccountType.PATIENT}
            className={classes.chewyIcon}
            clientId={clientId}
            patientId={patientId}
          />
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={name}
          inputProps={{ maxLength: 100 }}
          label={`${name.label}*`}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DateOfBirthSelector
          approximateAge={approximateAge}
          dob={dob}
          label={dob.label}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <PuiTextField
          field={color}
          inputProps={{ maxLength: 100 }}
          label={color.label}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <PuiTextField
          field={markingsTattoo}
          inputProps={{ maxLength: 100 }}
          label={markingsTattoo.label}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        {isMobile ? (
          <Grid container justifyContent="space-between">
            {Size.map((sizeItem: NamedEntity) => (
              <Grid item key={sizeItem?.id}>
                <Fab
                  className={classNames(
                    classes.button,
                    classes.sizeButton,
                    size.value !== sizeItem?.id && classes.buttonInactive,
                    !size.valid && classes.buttonError,
                  )}
                  type="button"
                  variant="extended"
                  onClick={() => size.set({ target: { value: sizeItem?.id } })}
                >
                  {sizeItem?.nameTranslation || sizeItem?.name}
                </Fab>
              </Grid>
            ))}
          </Grid>
        ) : (
          <PatientSizeSelect field={size} />
        )}
      </Grid>
      <Grid item md={5} xs={12}>
        <SpeciesSelect required field={species} />
      </Grid>
      <Grid item md={7} xs={12}>
        <BreedAutocomplete field={breed} species={species.value} />
      </Grid>
      <Grid item md={6} xs={12}>
        <GenderField required field={gender} />
      </Grid>
      <Grid item md={6} xs={12}>
        <SpayedNeuteredField
          required
          field={spayedNeutered}
          gender={gender.value}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel>
          <Text inline strong>
            {t('Common:ADD_A_PICTURE')}:
          </Text>
        </InputLabel>
        <div className={classes.profilePicContainer}>
          {isLogoEditing || !(avatarScaledServingUrl || photo) ? (
            <FileInput
              clearOnCancel
              onlyImages
              aspectRatio={1 / 1}
              buttonsHidden={!avatarScaledServingUrl}
              classes={{
                logoAreaClassName: classes.imageInputLogoArea,
                buttonsContainerClassName: classes.imageInputButtonsContainer,
                buttonsClassName: classes.imageInputButtons,
              }}
              ref={fileInputRef}
              size="normal"
              uploadButtonColor="primary"
              onCancel={onAvatarCancel}
              onFileReady={onAvatarReadyV2}
              onFileSelected={onAvatarSelected}
            />
          ) : (
            <Avatar
              className={classes.profilePic}
              size="normal"
              src={avatarSrc}
              onEditClick={() => setIsLogoEditing(true)}
            />
          )}
        </div>
      </Grid>
      <Grid item md={12} xs={12}>
        {alerts.length > 0 || customAlertType ? (
          <AlertLabel
            showEdit
            alertColorId={alertColorId}
            alertColorLevel={AlertColorLevel.PATIENT}
            alertTypes={[
              ...alerts.map((alert) =>
                LanguageUtils.getConstantTranslatedName(alert.id, alerts),
              ),
              customAlertType,
            ]}
            classes={{ root: classes.alertsRoot }}
            handleEdit={handleEditAlerts}
          />
        ) : (
          <AddButton
            addText={t('Common:ADD_ALERT')}
            plusButtonClass={classes.alertPlusButton}
            variant="dashed"
            onAdd={handleEditAlerts}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <PreferencesDisplayWidget
          showInfoIcon
          headerText={t('Clients:PATIENT_SECTION.PATIENT_PREFERENCES')}
          preferences={preferences}
          onEditPreferences={onAddPreferences}
        />
      </Grid>
      <Grid item mb={4} md={12} xs={12}>
        <InputLabel htmlFor="notes-input">
          <Text inline strong>
            {notes.label}:
          </Text>
          &nbsp;{t('Common:NOTE_NOT_SHARED_WITH_CLIENT')}
        </InputLabel>
        <PuiTextField
          multiline
          field={notes}
          id="notes-input"
          inputProps={{ maxLength: 1000 }}
          minRows={3}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <PuiSwitch
          disabled={!permissions.update}
          field={active}
          label={
            active.value ? t('Common:ACTIVE_ONE') : t('Common:INACTIVE_ONE')
          }
        />
      </Grid>
      <Grid container item alignItems="center" md={8} xs={12}>
        <Grid item sm={6}>
          <PuiSwitch
            disabled={!permissions.update}
            field={deceased}
            label={deceased.label}
          />
        </Grid>
        {deceased.value && (
          <Grid item sm>
            <Calendar
              fullWidth
              utc
              disabled={!permissions.update}
              field={deceasedDate}
              label={deceasedDate.label}
            />
          </Grid>
        )}
      </Grid>
      {showSendRemindersFlag && (
        <Grid item md={12} xs={12}>
          <PuiSwitch
            disabled={!permissions.update}
            field={sendReminders}
            label={sendReminders.label}
          />
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={diet}
          inputProps={{ maxLength: 100 }}
          label={diet.label}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={knownAllergies}
          inputProps={{ maxLength: 100 }}
          label={knownAllergies.label}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="acquired-type-select">
            {t('Common:WHERE_CLIENT_GET_PATIENT', {
              clientFirstName: client?.firstName,
              patientName: name.value || t('Common:NEW_PATIENT'),
            })}
          </InputLabel>
          <PuiSelect
            field={acquiredType}
            input={<Input id="acquired-type-select" />}
            items={AcquiredTypeItemsList}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <Calendar
          fullWidth
          utc
          field={date}
          label={t('Common:WHEN_CLIENT_GET_PATIENT', {
            clientFirstName: client?.firstName,
            patientName: name.value || t('Common:NEW_PATIENT'),
          })}
          onChange={date.setValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="environment-select">
            {environment.label}
          </InputLabel>
          <PuiSelect
            field={environment}
            input={<Input id="environment-select" />}
            items={AnimalEnvironment}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="activity-level-select">
            {activityLevel.label}
          </InputLabel>
          <PuiSelect
            field={activityLevel}
            input={<Input id="activity-level-select" />}
            items={ActivityLevel}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="insurance-select">{insurance.label}</InputLabel>
          <PuiSelect
            field={insurance}
            input={
              <Input
                data-testid="Patient-insuranceSelect"
                id="insurance-select"
              />
            }
            items={InsuranceProvider}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={policyNumber}
          inputProps={{ maxLength: 100 }}
          label={policyNumber.label}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={microchip}
          inputProps={{ maxLength: 100 }}
          label={microchip.label}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="service-animal-select">
            {serviceAnimalType.label}
          </InputLabel>
          <PuiSelect
            field={serviceAnimalType}
            input={<Input id="service-animal-select" />}
            items={ServiceAnimal}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField field={rabiesTag} label={rabiesTag.label} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Calendar
          fullWidth
          utc
          field={rabiesTagExpiration}
          label={rabiesTagExpiration.label}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="referred-by-select">
            {referredBy.label}
          </InputLabel>
          <ReferredBySelect
            field={referredBy}
            input={<Input id="referred-by-select" />}
          />
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <PuiTextField
          field={license}
          inputProps={{ maxLength: 100 }}
          label={license.label}
        />
      </Grid>
      <Grid container item md={6} xs={12}>
        <PuiTextField
          multiline
          field={schedule}
          inputProps={{ maxLength: 100 }}
          label={schedule.label}
        />
      </Grid>
      <Grid item xs={12}>
        <RequiredFieldsNotice />
      </Grid>
    </Grid>
  )
})

export default Patient
