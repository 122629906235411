import React, { JSXElementConstructor, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import PuiInformationPanel from '~/components/common/PuiInformationPanel'
import { getIsRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import { ChewyOrderDetails } from '~/components/dashboard/prescription/elements/ChewyOrderDetails/ChewyOrderDetails'
import { AutoshipFrequencyOption } from '~/constants/autoship'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getItemErrors } from '~/store/duck/clientFinanceData'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice, InvoiceLineItem, Order } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { isAutoshipDataValid } from '~/utils/autoship'
import useDialog from '~/utils/useDialog'

import ChargeDetailsLineItem from './ChargeDetailsLineItem'
import ChargeItemButtonActions from './ChargeItemButtonActions'
import ChargeItemNote from './ChargeItemNote'
import {
  ChargeItemRefundCandidate,
  ChargeItemRefundCandidateProps,
} from './ChargeItemRefundCandidate'
import { ChargeItemRefundDetails } from './ChargeItemRefundDetails'
import ChargeItemTitle from './ChargeItemTitle'

export interface ChargeInformationPanelProps {
  ComponentsActions?: Partial<Record<OrderType, JSXElementConstructor<any>>>
  classes?: ClassesType<any>
  editDisabled: boolean
  invoice: Invoice | InvoiceV3 | Nil
  isInvoice?: boolean
  isLoading: boolean
  isOpened: boolean
  isSoap?: boolean
  item: InvoiceLineItem | RetailOrderLineItem | Nil
  onClose: () => void
  onSelectItem: (item: InvoiceLineItem | RetailOrderLineItem | Order) => void
  refundCandidate?: ChargeItemRefundCandidateProps
}

const ChargeInformationPanel = ({
  classes,
  editDisabled,
  isOpened,
  invoice,
  isLoading,
  isSoap,
  item,
  isInvoice,
  refundCandidate,
  onClose,
  onSelectItem,
  ComponentsActions,
}: ChargeInformationPanelProps) => {
  const isRetailOrderLineItem = getIsRetailOrderLineItem(item)
  const noteEditDisabled =
    !R.isNil(item) && (R.isNil(item.logId) || R.isEmpty(item.logId))

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const areChargesPostedAndEditable = isInvoice && isEditPostedChargesEnabled

  const [openDeleteLineItemDialog] = useDialog(DialogNames.DELETE_LINE_ITEMS)

  const hasRefunds = isRetailOrderLineItem
    ? false // RetailOrderLineItem has no concept of refunds
    : !R.isEmpty(item?.refundLineItems ?? [])

  const showRefundSection =
    isIpoM0InvoiceRefundsEnabled && hasRefunds && !isRetailOrderLineItem

  const itemErrors = useSelector(getItemErrors)

  useEffect(() => {
    if (
      itemErrors &&
      itemErrors.length > 0 &&
      !R.isNil(item) &&
      itemErrors.filter((itemError) => itemError.lineItemId === item.id)
        .length > 0
    ) {
      openDeleteLineItemDialog({
        lineItems: [item],
        clientId: isRetailOrderLineItem ? item.client.id : item.clientId,
      })
    }
  }, [item, itemErrors])

  return (
    <PuiInformationPanel
      classes={{ informationPanel: classes?.chargeInformationPanel }}
      isLoading={isLoading}
      isOpened={isOpened}
      onClose={onClose}
    >
      {item ? (
        <>
          <ChargeItemTitle item={item} />

          <ChargeItemButtonActions
            ComponentsActions={ComponentsActions}
            disabled={editDisabled}
            isInvoice={isInvoice}
            isLoading={isLoading}
            item={item}
            onDelete={onClose}
            onUpdateItem={onSelectItem}
          />

          {isRetailOrderLineItem && isChewyCheckoutEnabled && (
            <ChewyOrderDetails
              frequency={
                item?.autoshipUnit?.name &&
                item?.autoshipFrequency &&
                isAutoshipDataValid(
                  item.autoshipUnit.name,
                  item.autoshipFrequency,
                )
                  ? ({
                      uom: item.autoshipUnit.name as any,
                      value: item.autoshipFrequency,
                    } as AutoshipFrequencyOption)
                  : undefined
              }
              orderNumber={item?.viewOrderNumber}
            />
          )}

          <ChargeDetailsLineItem
            areChargesPostedAndEditable={Boolean(areChargesPostedAndEditable)}
            editDisabled={editDisabled}
            invoice={invoice}
            item={item}
          />

          {!refundCandidate && (
            <ChargeItemNote
              disabled={editDisabled || noteEditDisabled}
              isSoap={isSoap}
              item={item}
            />
          )}
          {showRefundSection && <ChargeItemRefundDetails item={item} />}
          {refundCandidate && (
            <ChargeItemRefundCandidate {...refundCandidate} />
          )}
        </>
      ) : null}
    </PuiInformationPanel>
  )
}

export default ChargeInformationPanel
