import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFields } from '@pbt/pbt-ui-components'

import { useGetIsNoShowConsentAppointmentType } from '~/components/common/appointments/useGetIsNoShowConsentAppointmentType'
import useConfirmAppointmentNoShowAlert from '~/components/common/dialog/useConfirmAppointmentNoShowAlert'
import { AppointmentState } from '~/constants/appointmentStates'
import { isHighValueAppointment } from '~/constants/appointmentTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { patchAppointment } from '~/store/actions/timetable'
import {
  getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { TimetableEvent } from '~/types'
import { getIsOutsideCancellationWindow } from '~/utils/appointmentCancellationUtils'
import useDialog from '~/utils/useDialog'
import { useAppointmentStateId } from '~/utils/useEventType'

export const useAppointmentStatusChange = (
  appointment: TimetableEvent | undefined,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )
  const isNoShowCancellationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY),
  )
  const isNoShowCancellationPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )
  const isNoShowPenaltyRefundEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_REFUND),
  )
  const isDepositPayByLinkEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DEPOSIT_PAY_BY_LINK),
  )
  const isCurrentBusinessIsAppointmentCancellationReasonEnabled = useSelector(
    getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  )
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const client = useSelector(getUser(appointment?.clientId))

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)
  const noShowStateId = useAppointmentStateId(AppointmentState.NO_SHOW)

  const [
    openAppointmentCancellationReasonDialog,
    closeAppointmentCancellationReasonDialog,
  ] = useDialog(DialogNames.APPOINTMENT_CANCELLATION_REASON)
  const [openConfirmAppointmentNoShowAlert] = useConfirmAppointmentNoShowAlert()
  const isNoShowConsentAppointmentType = useGetIsNoShowConsentAppointmentType()
  const showNoShowAlert =
    isNoShowPenaltyRefundEnabled &&
    isOmnichannel &&
    isNoShowConsentAppointmentType(appointment?.type?.id)

  const showNoShowCancellationPenaltyAlert = (item: TimetableEvent) =>
    isNoShowCancellationEnabled &&
    isNoShowCancellationPenaltyCardOnFileAlertEnabled &&
    isOmnichannel &&
    isNoShowConsentAppointmentType(item?.type?.id || '') &&
    getIsOutsideCancellationWindow(
      item?.scheduledStartDatetime,
      item?.scheduledEndDatetime,
    )

  const showHighValueDepositAlerts = (item: TimetableEvent) =>
    isDepositPayByLinkEnabled &&
    isOmnichannel &&
    isHighValueAppointment(item?.type?.name || '')

  const {
    fields: { appointmentStatus },
    reset,
  } = useFields(
    [
      {
        name: 'appointmentStatus',
        label: t('Common:APPOINTMENT_STATUS'),
        initialValue: appointment?.state?.id || '',
      },
    ],
    false,
  )

  const onHandleStatusChange = (state: string) => {
    if (appointment?.id) {
      if (
        isAppointmentCancellationReasonEnabled &&
        isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
        state === cancelledStateId
      ) {
        openAppointmentCancellationReasonDialog({
          onProceed: (
            cancellationReasonId: string,
            cancellationReason: string,
            internalNotes: string,
          ) => {
            dispatch(
              patchAppointment({
                id: appointment?.id,
                state,
                cancellationReasonId,
                cancellationReason,
                internalNotes,
              }),
            )
          },
          showNoShowCancellationPenaltyAlert:
            showNoShowCancellationPenaltyAlert(appointment),
          onClose: () => {
            reset()
            closeAppointmentCancellationReasonDialog()
          },
          showDepositAlert: showHighValueDepositAlerts(appointment),
          isAppointmentWithin24Hours: getIsOutsideCancellationWindow(
            appointment?.scheduledStartDatetime,
            appointment?.scheduledEndDatetime,
          ),
          isNoShowConsentAppointmentType: isNoShowConsentAppointmentType(
            appointment?.type?.id,
          ),
        })
      } else if (
        showHighValueDepositAlerts(appointment) &&
        state === noShowStateId
      ) {
        openConfirmAppointmentNoShowAlert({
          onConfirm: (proceed: boolean) => {
            if (proceed) {
              dispatch(
                patchAppointment({
                  id: appointment?.id,
                  state,
                }),
              )
            } else {
              reset()
            }
          },
          isHighValueAppointment: true,
        })
      } else if (showNoShowAlert && state === noShowStateId) {
        openConfirmAppointmentNoShowAlert({
          onConfirm: (proceed: boolean) => {
            if (proceed) {
              dispatch(
                patchAppointment({
                  id: appointment?.id,
                  state,
                }),
              )
            } else {
              reset()
            }
          },
          client,
          isHighValueAppointment: false,
        })
      } else {
        dispatch(
          patchAppointment({
            id: appointment?.id,
            state,
          }),
        )
      }
    }
  }

  useEffect(() => {
    reset()
  }, [appointment])

  useEffect(() => {
    if (appointmentStatus.value !== appointmentStatus.initialValue) {
      onHandleStatusChange(appointmentStatus.value)
    }
  }, [appointmentStatus.value])

  return { onHandleStatusChange, appointmentStatus }
}
