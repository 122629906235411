import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import { fetchSpacesListWithType } from '~/store/actions/spaces'
import {
  getInventoryAdjustmentReasons,
  getSpaceType,
} from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpaceCurrentTypeId,
  getSpacesIsFetching,
} from '~/store/reducers/spaces'
import { Adjustment } from '~/types'

import { getCreationDate, pluckStorageLocation } from '../inventoryUtils'
import AdjustmentAmountCell from './AdjustmentAmountCell'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      height: 50,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    leftColumn: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    tableTitle: {
      height: 38,
    },
  }),
  { name: 'AdjustmentsTable' },
)

export interface AdjustmentsTableProps
  extends ScrollableDataTableProps<Adjustment, false> {
  adjustments: Adjustment[]
  isLoading: boolean
}

const AdjustmentsTable = ({
  adjustments,
  isLoading,
  ...rest
}: AdjustmentsTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const headerRow = [
    { label: t('Common:ADJUSTMENT') },
    { label: t('Common:REASON') },
    { label: t('Common:USER') },
    { label: t('Common:STORAGE_LOCATION') },
    { label: t('Common:NOTES') },
  ]

  const mainColumns = [
    {
      label: t('Common:DATE_TIME'),
      prop: getCreationDate,
    },
  ]

  const InventoryAdjustmentReasons = useSelector(getInventoryAdjustmentReasons)
  const SpaceType = useSelector(getSpaceType)

  const spaceIds = pluckStorageLocation(adjustments)
  const spaces = useSelector(getMultipleSpaces(spaceIds)).filter(Boolean)
  const currentSpaceTypeId = useSelector(getSpaceCurrentTypeId)
  const spaceIsFetching = useSelector(getSpacesIsFetching)

  useEffect(() => {
    const locationTypeSpaceId = Utils.findConstantIdByName(
      'Storage location',
      SpaceType,
    )
    if (!spaces.length || currentSpaceTypeId !== locationTypeSpaceId) {
      dispatch(fetchSpacesListWithType(locationTypeSpaceId))
    }
  }, [spaces.length, currentSpaceTypeId])

  return isLoading || spaceIsFetching ? (
    <Grid container justifyContent="center">
      <CircularProgress size={32} />
    </Grid>
  ) : (
    <ScrollableDataTable
      duplicateDisabled
      classes={classes}
      data={adjustments}
      headerRow={headerRow}
      mainColumns={mainColumns}
      {...rest}
    >
      {({ item }) => (
        <>
          <TableCell className={classes.tableCell}>
            <AdjustmentAmountCell {...item} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            {LanguageUtils.getConstantTranslatedName(
              item.reasonId,
              InventoryAdjustmentReasons,
            )}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {Utils.getPersonString(item.person)}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {Utils.findById(item.storageLocationId, spaces)?.name}
          </TableCell>
          <TableCell className={classes.tableCell}>{item.notes}</TableCell>
        </>
      )}
    </ScrollableDataTable>
  )
}

export default AdjustmentsTable
