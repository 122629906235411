import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { PrimitiveTable, PrimitiveTableColumn } from '@pbt/pbt-ui-components'

import { InventoryOrderItem } from '~/types'

import ShipmentItemStatusLabel from '../../shipments/ShipmentItemStatusLabel'
import { OrderItemTableRow } from './OrderItemTableRow'

const useStyles = makeStyles(
  (theme) => ({
    tableHeader: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      minHeight: '30px',
    },
  }),
  { name: 'OrderItemsTableComponent' },
)

interface OrderItemsTableComponentProps {
  items: InventoryOrderItem[]
}

export const OrderItemsTableComponent = ({
  items,
}: OrderItemsTableComponentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')

  const columns: PrimitiveTableColumn[] = [
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_NAME'),
      prop: 'itemVariationName',
      width: 4,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_STATUS'),
      prop: ShipmentItemStatusLabel,
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_ORDERED_QUANTITY'),
      prop: 'orderedQuantity',
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_RECEIVED_QUANTITY'),
      prop: 'receivedQuantity',
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_DAMAGED_QUANTITY'),
      prop: 'damagedQuantity',
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_SUPPLIER_UOM'),
      prop: 'packageTypeId',
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDER_ITEM_SUPPLIER_SKU'),
      prop: 'sku',
      width: 2,
    },
  ]

  return (
    <PrimitiveTable
      HeaderProps={{
        classes: {
          tableHeader: classes.tableHeader,
        },
      }}
      RowComponent={OrderItemTableRow}
      columns={columns}
      isItemLoaded={(index) => Boolean(items[index])}
      itemSpacing={0}
      list={items}
      loadMoreItems={() => {}}
      totalCount={items.length}
    />
  )
}
