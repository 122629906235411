import React, { ComponentProps } from 'react'
import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import {
  SupportedAnalyticsDashboard,
  supportedAnalyticsDashboards,
} from '~/constants/analytics'
import FeatureToggle from '~/constants/featureToggle'
import { useGetAnalyticsFilterParams } from '~/store/hooks/business'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import AnalyticsPage from '../analytics/AnalyticsPage'
import { generalAnalyticsDashboardMapping } from '../menu/configs/useGeneralAnalyticsMenu'
import { omnichannelAnalyticsDashboardMapping } from '../menu/configs/useOmnichannelAnalyticsMenu'
import { MenuItem } from '../menu/menuItem'

type AnalyticsRoute = {
  id: SupportedAnalyticsDashboard
  signedParams?: ComponentProps<typeof AnalyticsPage>['signedParams']
}

type AnalyticsComponentRoute = {
  component: () => React.JSX.Element
  path: string
  permissions: PermissionArea
}

export const useGetAnalyticsRoutes = (): AnalyticsComponentRoute[] => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const isOmnichannelAnalyticsMenuEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OMNICHANNEL_ANALYTICS_MENU),
  )
  const {
    date: { todayDate, last30DaysDate },
    range: { todayRange, last30DaysRange },
    rhapsodyDomain,
  } = useGetAnalyticsFilterParams()

  const analyticsDashboardMapping: Record<
    string,
    Record<string, MenuItem> | MenuItem
  > =
    isOmnichannelAnalyticsMenuEnabled && currentBusiness?.omniChannel
      ? omnichannelAnalyticsDashboardMapping
      : generalAnalyticsDashboardMapping

  const analyticsRoutes: AnalyticsRoute[] = [
    {
      id: SupportedAnalyticsDashboard.PRACTICE_PERFORMANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.PRODUCTION,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.GROUP_PERFORMANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.REMINDERS_COMPLIANCE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.CLIENT_LIST,
      signedParams: {
        newPatientsFilter: last30DaysRange,
        newClientsFilter: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.INVENTORY_USAGE,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.MAGIC_QUADRANT,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.TIME_TRACKING,
      signedParams: {
        dateFrom: last30DaysDate,
        dateTo: todayDate,
      },
    },
    {
      id: SupportedAnalyticsDashboard.GROUP_PRICE_LISTS,
    },
    {
      id: SupportedAnalyticsDashboard.RABIES_VACCINE_HISTORY,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.MONTHLY_SALES_SUMMARY,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.CONTROLLED_SUBSTANCE_LOG,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.MEDICAL_INSIGHTS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.CLIENT_AND_PATIENT_STATUS,
    },
    {
      id: SupportedAnalyticsDashboard.MARKETING_DATA_QUALITY,
    },
    {
      id: SupportedAnalyticsDashboard.FORWARD_BOOKINGS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.CANCELLATIONS_AND_NO_SHOWS,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.ACQUISITION_AND_LIFETIME_VALUE,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.WELLNESS_PLANS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.REFERRAL_BUSINESS,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.OPEN_SOAPS_AND_INVOICES,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.DAILY_RECONCILIATION,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.BANK_RECONCILIATION,
      signedParams: { dateRange: last30DaysRange },
    },
    {
      id: SupportedAnalyticsDashboard.INVOICES_AND_PAYMENTS,
      signedParams: { dateRange: todayRange },
    },
    {
      id: SupportedAnalyticsDashboard.ACCOUNTING,
      signedParams: {
        creationDate: todayDate,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.SALES_SUMMARY_EXCLUDING_UNPAID_OTC,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.PRACTICE_SALES,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.NEW_CLIENT_AND_PATIENT_ACTIVITY,
      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.BUNDLES_AND_WELLNESS_PLANS,
      signedParams: {
        dateRange: last30DaysRange,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.CLIENT_INITIATED_REQUESTS,

      signedParams: {
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.DENTAL_AND_HEARTWORM_OVERVIEW,
    },
    {
      id: SupportedAnalyticsDashboard.APPOINTMENT_MIX_BENCHMARK,
    },
    {
      id: SupportedAnalyticsDashboard.INSURED_PATIENTS_VISITS,
    },
    {
      id: SupportedAnalyticsDashboard.INVENTORY_USAGE_AND_AVERAGE_INVOICE,
    },
    {
      id: SupportedAnalyticsDashboard.REPEAT_AND_RECHECK_PATIENTS,
      signedParams: {
        creationDate: todayDate,
      },
    },
    {
      id: SupportedAnalyticsDashboard.ONLINE_SICK_APPOINTMENTS,
    },
    {
      id: SupportedAnalyticsDashboard.RESOLUTION_RATE,
    },
    {
      id: SupportedAnalyticsDashboard.DISCOUNT_USAGE,
      signedParams: {
        dateFrom: last30DaysDate,
        dateTo: todayDate,
      },
    },
    {
      id: SupportedAnalyticsDashboard.INVENTORY_AND_CATALOG_AUDIT,
    },
    {
      id: SupportedAnalyticsDashboard.OVERDUE_TASKS,
    },
    {
      id: SupportedAnalyticsDashboard.SMS_ACTIVITY,
    },
    {
      id: SupportedAnalyticsDashboard.DAILY_BILLING_ACTIVITY,
    },
    {
      id: SupportedAnalyticsDashboard.BILLING_AGING,
      signedParams: {
        creationDate: todayDate,
        rhapsodyDomain,
      },
    },
    {
      id: SupportedAnalyticsDashboard.INVOICE_ACTIVITY,
      signedParams: {
        rhapsodyDomain,
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.PRODUCER_SALES,
      signedParams: {
        creationDate: last30DaysRange,
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.OPEN_AND_UNAPPLIED_BILLING,
      signedParams: {
        rhapsodyDomain,
        dateRange: last30DaysRange,
      },
    },
    {
      id: SupportedAnalyticsDashboard.END_OF_DAY_AUDIT,
    },
  ]

  /**
   * Prevent all analytics dashboard routes being available if the user switches accounts on a report
   * to one that should not have access to it
   */
  const analyticsDashboardFilter = (route: AnalyticsRoute) =>
    analyticsDashboardMapping[route.id] &&
    !(
      supportedAnalyticsDashboards[route.id].cvcOnly &&
      !currentBusiness?.omniChannel
    )

  return analyticsRoutes.filter(analyticsDashboardFilter).flatMap((route) => {
    const dashboardMapping = analyticsDashboardMapping[route.id]

    // Some mappings contain multiple menu item configs
    const dashboardItemConfigs: MenuItem[] =
      'id' in dashboardMapping
        ? ([dashboardMapping] as MenuItem[])
        : Object.values(dashboardMapping)

    return dashboardItemConfigs.map(({ id, url }) => ({
      path: url as string,
      component: () => (
        <AnalyticsPage
          dashboard={Number(id)}
          signedParams={{ ...(route.signedParams || {}) }}
        />
      ),
      permissions: PermissionArea.REPORTING,
    }))
  })
}
