import React from 'react'
import { includes } from 'ramda'
import { StateLabel } from '@pbt/pbt-ui-components'

import {
  MembershipPaymentStatus,
  MembershipPaymentStatusLabel,
} from '~/constants/paymentTypes'

const SuccessStatuses = [
  MembershipPaymentStatus.SUCCEEDED,
  MembershipPaymentStatus.COMPLETED,
]

const DisabledStatuses = [
  MembershipPaymentStatus.REFUNDED,
  MembershipPaymentStatus.CANCELED,
  MembershipPaymentStatus.REFUND_FAILED,
  MembershipPaymentStatus.VOIDED,
]

const WarningStatuses = [
  MembershipPaymentStatus.REFUND_PENDING,
  MembershipPaymentStatus.NEXT,
  MembershipPaymentStatus.REQUIRES_PAYMENT_METHOD,
  MembershipPaymentStatus.REQUIRES_CONFIRMATION,
  MembershipPaymentStatus.REQUIRES_ACTION,
  MembershipPaymentStatus.REQUIRES_CAPTURE,
  MembershipPaymentStatus.PAYMENT_DECLINE,
]

const NormalStatuses = [
  MembershipPaymentStatus.WRITE_OFF,
  MembershipPaymentStatus.TOTAL_REFUND,
  MembershipPaymentStatus.PARTIAL_REFUND,
]

interface StatusCellProps {
  status: MembershipPaymentStatus
}

const StatusCell = ({ status }: StatusCellProps) => {
  const isSuccess = includes(status, SuccessStatuses)
  const isDisabled = includes(status, DisabledStatuses)
  const isWarning = includes(status, WarningStatuses)
  const isNormal = includes(status, NormalStatuses)

  return (
    <StateLabel
      disabled={isDisabled}
      normal={isNormal}
      success={isSuccess}
      variant="small"
      warning={isWarning}
    >
      {MembershipPaymentStatusLabel[status] || ''}
    </StateLabel>
  )
}

export default StatusCell
