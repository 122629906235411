import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  DateUtils,
  LanguageUtils,
  moment,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import { useTaskTypes } from '~/store/hooks/tasks'
import {
  getTaskTemplateOptions,
  getTaskTimeUnits,
} from '~/store/reducers/constants'
import { getTeamsList } from '~/store/reducers/members'
import { getMultipleUsers } from '~/store/reducers/users'
import { Bundle, Task } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    tableTitle: {
      height: 40,
      fontSize: '1.4rem',
    },
    tableCell: {
      height: 48,
      fontSize: '1.4rem',
    },
    leftColumn: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    addItem: {
      marginTop: theme.spacing(1),
    },
    boopIcon: {
      width: '0.75em',
      height: '0.75em',
      margin: theme.spacing(0, 0.5),
    },
  }),
  { name: 'BundleTasksTable' },
)

export interface BundleTasksTableProps
  extends ScrollableDataTableProps<Task, true> {
  bundle: Bundle
  onAdd: () => void
}

const BundleTasksTable = ({
  onAdd,
  bundle,
  ...rest
}: BundleTasksTableProps) => {
  const classes = useStyles()
  const TaskTemplateOptions = useSelector(getTaskTemplateOptions)
  const TaskTimeUnits = useSelector(getTaskTimeUnits)
  const teamsList = useSelector(getTeamsList)
  const teams = useSelector(getMultipleUsers(teamsList))
  const { t } = useTranslation(['Admin', 'Common', 'Tasks'])

  const headerRow = [
    { label: t('Common:ASSIGNED') },
    { label: t('Common:WORKFLOW_ONE') },
    { label: t('Common:TYPE_ONE') },
    { label: t('Tasks:LABEL.DUE_DATE') },
    { label: t('Tasks:LABEL.DUE_TIME') },
    { label: t('Common:REPEAT_ACTION') },
  ]

  const TaskTypes = useTaskTypes()
  const SpecificDueTime = Utils.findConstantIdByName(
    'Set a specific time',
    TaskTemplateOptions.dueTime || [],
  )

  const data = bundle.tasks || []

  return (
    <>
      <Text strong mt={3} variant="subheading3">
        {t('Admin:CATALOG.BUNDLE_TASKS_TABLE.BUNDLE_TASKS')}
      </Text>
      {data.length > 0 && (
        <ScrollableDataTable
          duplicateDisabled
          useIndexAsId
          classes={{
            leftColumn: classes.leftColumn,
            tableTitle: classes.tableTitle,
            tableCell: classes.tableCell,
          }}
          data={data}
          headerRow={headerRow}
          {...rest}
        >
          {({ item: task, tableCellClassName }) => (
            <>
              <TableCell className={tableCellClassName}>
                {task.forClient ? (
                  <Grid container alignItems="center" wrap="nowrap">
                    <Text variant="body2">
                      {t(
                        'Admin:CATALOG.BUNDLE_TASKS_TABLE.PET_PARENT_OF_PATIENT',
                      )}
                    </Text>
                  </Grid>
                ) : task.teamMemberOptionId ? (
                  LanguageUtils.getConstantTranslatedName(
                    task.teamMemberOptionId,
                    TaskTemplateOptions.teamMember || [],
                  )
                ) : (
                  R.find(R.propEq('id', task.teamId), teams)?.firstName
                )}
              </TableCell>
              <TableCell className={tableCellClassName}>
                {task.forClient
                  ? t('Common:BOOP_SYSTEM_NAME')
                  : t('Common:INTERNAL')}
              </TableCell>
              <TableCell className={tableCellClassName}>
                {LanguageUtils.getConstantTranslatedName(
                  task.typeId,
                  TaskTypes,
                )}
              </TableCell>
              <TableCell className={tableCellClassName}>
                {LanguageUtils.getConstantTranslatedName(
                  task.dueDateOptionId,
                  TaskTemplateOptions.dueDate || [],
                )}
              </TableCell>
              <TableCell className={tableCellClassName}>
                {task.dueTimeOptionId === SpecificDueTime
                  ? DateUtils.formatTime(
                      moment(task.specificDueTime, 'HH:mm').toISOString(),
                    )
                  : LanguageUtils.getConstantTranslatedName(
                      task.dueTimeOptionId,
                      TaskTemplateOptions.dueTime || [],
                    )}
              </TableCell>
              <TableCell className={tableCellClassName}>
                {task.recurrencePeriod
                  ? `
                    ${t('Common:EVERY')}
                      ${task.recurrencePeriod.amount}
                      ${
                        LanguageUtils.getConstantTranslatedName(
                          task.recurrencePeriod.unit,
                          TaskTimeUnits,
                        )?.toLowerCase() || ''
                      }
                    `
                  : t('Common:NO_REPEAT')}
              </TableCell>
            </>
          )}
        </ScrollableDataTable>
      )}
      <AddButton
        addText={t('Common:ADD_TASK')}
        classes={{ addItem: classes.addItem }}
        onAdd={onAdd}
      />
    </>
  )
}

export default BundleTasksTable
