import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  useWellnessPlanVersionsQuery,
  WellnessPlanVersionFilterInput,
  WellnessPlanVersionsQuery,
} from '~/api/graphql/generated/types'
import {
  WellnessPlanAutoRenew,
  WellnessPlanAutoRenewInfo,
  WellnessPlanVersionAutoRenew,
} from '~/types'
import { getUrlSearchParam } from '~/utils'

type FetchedPlan = {
  id: string
  name: string
  renewalTarget?: {
    id: string
    versionId: string
  } | null
  versionId: string
}

type FetchedVersion = {
  active?: boolean | null
  activePatients?: number | null
  id: string
  plans?: (FetchedPlan | null)[] | null
  versionNumber: number
}

const getPlanKey = (versionId: string, planId: string) =>
  `${versionId}-${planId}`

function mapVersions(versions: FetchedVersion[]): WellnessPlanAutoRenewInfo {
  const versionsMap: Record<string, WellnessPlanVersionAutoRenew> = {}
  const versionsList: string[] = []
  const plansMap: Record<string, WellnessPlanAutoRenew> = {}
  const plansList: string[] = []

  versions.forEach((version) => {
    versionsMap[version.id] = {
      id: version.id,
      versionNumber: version.versionNumber,
      active: version.active,
      activePatients: version.activePatients,
      plans: (version.plans || []).map((plan) => `${version.id}-${plan?.id}`),
    }

    versionsList.push(version.id)
    const versionPlans = version.plans || []

    versionPlans.forEach((plan: FetchedPlan | null) => {
      if (!plan) return
      const mapKey = getPlanKey(version.id, plan.id)
      const autoRenewVersionId = plan.renewalTarget?.versionId
      const autoRenewPlanId = plan.renewalTarget?.id
      plansMap[mapKey] = {
        id: plan.id,
        key: mapKey,
        versionId: plan.versionId,
        name: plan.name,
        active: version.active,
        autoRenewInto:
          autoRenewVersionId && autoRenewPlanId
            ? getPlanKey(autoRenewVersionId, autoRenewPlanId)
            : null,
      }
      plansList.push(mapKey)
    })
  })

  return { versionsMap, versionsList, plansMap, plansList }
}

const LIMIT = 100

export const useFetchAutoRenewingPlansInfo = ({
  planKey,
  filterInput,
}: { filterInput?: WellnessPlanVersionFilterInput; planKey?: string } = {}) => {
  const location = useLocation()
  const [allVersions, setAllVersions] = useState<FetchedVersion[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [fetchError, setFetchError] = useState(false)

  const { data, loading, fetchMore, refetch } = useWellnessPlanVersionsQuery({
    variables: { filterInput, limit: LIMIT, offset: 0 },
  })

  const search = getUrlSearchParam('query', location.search)
  const isAllDataLoaded =
    totalCount !== null && allVersions.length >= totalCount
  const isLoading = (loading || isFetching) && !isAllDataLoaded && !fetchError

  useEffect(() => {
    refetch()
  }, [search])

  useEffect(() => {
    if (data?.wellnessPlanVersions.data) {
      setAllVersions((prev) => {
        const versionMap = new Map(prev.map((version) => [version.id, version]))
        data.wellnessPlanVersions.data.forEach((version) => {
          versionMap.set(version.id, version)
        })
        return Array.from(versionMap.values())
      })
      setTotalCount(data.wellnessPlanVersions.totalCount)
    }
  }, [data])

  // Auto-fetch next batch
  useEffect(() => {
    const fetchMoreData = async () => {
      if (
        isFetching ||
        fetchError ||
        (totalCount !== null && allVersions.length >= totalCount)
      )
        return

      setIsFetching(true)

      await fetchMore({
        variables: {
          filterInput,
          limit: LIMIT,
          offset: allVersions.length,
        },
        updateQuery: (
          prev: WellnessPlanVersionsQuery,
          { fetchMoreResult }: { fetchMoreResult?: WellnessPlanVersionsQuery },
        ) => {
          if (!fetchMoreResult && allVersions.length < totalCount) {
            setFetchError(true)
            return prev
          }

          if (
            !fetchMoreResult?.wellnessPlanVersions ||
            !fetchMoreResult.wellnessPlanVersions.data.length
          ) {
            return prev
          }

          return {
            wellnessPlanVersions: {
              ...fetchMoreResult.wellnessPlanVersions,
              data: [
                ...prev.wellnessPlanVersions.data,
                ...fetchMoreResult.wellnessPlanVersions.data,
              ],
            },
          }
        },
      })
      setIsFetching(false)
    }

    if (
      !loading &&
      !fetchError &&
      totalCount !== null &&
      allVersions.length < totalCount
    ) {
      fetchMoreData()
    }
  }, [allVersions.length, totalCount, isFetching, loading])

  const autoRenewingPlansInfo = mapVersions(allVersions)

  const shouldShowPlanRenewalWarning = (): boolean => {
    const { plansList, plansMap } = autoRenewingPlansInfo

    if (!plansList || plansList.length === 0) {
      return false // No plans to check
    }

    return plansList.some((key) => {
      if (!plansMap[key]) {
        return false
      }

      const plan = plansMap[key]

      // Self-linked plans don't need renewal warnings
      const isSelfLinked = key === plan.autoRenewInto
      if (isSelfLinked) {
        return false
      }

      // Plans without renewal targets need warnings
      if (!plan.autoRenewInto) {
        return true
      }
      const targetPlan = plansMap[plan.autoRenewInto]

      // Plans linked to inactive targets need warnings
      return !targetPlan.active
    })
  }

  const getRenewInfoByCurrentPlan = () => {
    if (!planKey) {
      return {}
    }

    const { plansMap, versionsMap } = autoRenewingPlansInfo || {}

    const currentPlan = plansMap?.[planKey]
    if (!currentPlan) {
      return {}
    }

    const renewalPlanKey = currentPlan.autoRenewInto
    if (!renewalPlanKey) {
      return {}
    }

    const renewalPlan = plansMap?.[renewalPlanKey]
    if (!renewalPlan) {
      return {}
    }

    const renewalVersion = versionsMap?.[renewalPlan.versionId]

    return {
      showTargetPlanInfo: Boolean(
        renewalVersion?.versionNumber && renewalPlan?.name,
      ),
      renewalVersion,
      renewalPlan,
    }
  }

  return {
    showTargetPlanInfo: getRenewInfoByCurrentPlan().showTargetPlanInfo,
    renewVersion: getRenewInfoByCurrentPlan().renewalVersion,
    renewPlan: getRenewInfoByCurrentPlan().renewalPlan,
    autoRenewingPlansInfo,
    isLoading,
    shouldShowPlanRenewalWarning: shouldShowPlanRenewalWarning(),
    refetchAutoRenewingPlansInfo: refetch,
  }
}
