import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { InventoryCategoryName } from '~/constants/inventoryCategory'
import { ProcedureState } from '~/constants/SOAPStates'
import { useIsInventoryCategory } from '~/store/hooks/orders'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getProcedureStatus } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import { Order } from '~/types'
import { getIsRabiesVaccine } from '~/utils/orderUtils'

const requiredRabiesFields = [
  'dueDate',
  'typeId',
  'manufacturedBy',
  'serialNumber',
  'lotNumber',
  'lotExpiration',
  'signature',
]

const requiredVaccineFields = ['dueDate']

export const useMissingVaccineInfo = (vaccines?: Order[]) => {
  const ProcedureStatus = useSelector(getProcedureStatus)
  const selectedOrders = useSelector(getSelectedOrders)
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isVaccine = useIsInventoryCategory(InventoryCategoryName.VACCINES)

  const ProcedureDeclinedState = Utils.findConstantIdByName(
    ProcedureState.DECLINED,
    ProcedureStatus,
  )

  const relevantOrders =
    vaccines && vaccines.length > 0 ? vaccines : selectedOrders

  const requiredRabies = isCurrentBusinessOmniChannel
    ? requiredRabiesFields
    : R.without(['signature'], requiredRabiesFields)

  return R.pipe(
    R.filter(
      (order: Order) =>
        (isVaccine(order) || getIsRabiesVaccine(order)) &&
        order?.stateId !== ProcedureDeclinedState,
    ),
    R.map((vaccine: Order) =>
      R.filter(
        R.isNil,
        R.pick(
          getIsRabiesVaccine(vaccine) ? requiredRabies : requiredVaccineFields,
          vaccine?.vaccinationDetails || {},
        ),
      ),
    ),
    R.reject(R.isEmpty),
  )(relevantOrders)
}
