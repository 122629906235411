import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  Constant,
  CopyToClipboard,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Chain as ChainIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Eye as ViewIcon,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsPopper from '~/components/common/ActionsPopper'
import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { addForm, deleteForm } from '~/store/actions/timetable'
import { useIsSoapLocked } from '~/store/hooks/soap'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getDocumentTypes, getFeatureToggle } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getClientId,
  getIsCurrentContextSoap,
  getIsFinalized,
  getSOAPisFetching,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getTimetableEventForms } from '~/store/reducers/timetable'
import { Document, FormStatus } from '~/types'
import { getDeleteConfirmMessage, getKioskUrl } from '~/utils'
import useDialog from '~/utils/useDialog'

import {
  normalizeDocumentForSave,
  usePdfActions,
} from '../../admin/catalog/documents/utils'
import { getFormSelectedOptions } from '../../getFormSelectedOptions'
import SoapWidget from '../SoapWidget'
import useFormsWidgetColumns from './useFormsWidgetColumns'

const ROW_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    addSection: {
      borderTop: theme.constants.tableBorder,
    },
    addItem: {
      width: 'auto',
      padding: theme.spacing(1, 1, 1, 2),
    },
    nameCell: {
      paddingLeft: theme.spacing(2),
    },
    cell: {
      padding: theme.spacing(0, 2),
      minHeight: ROW_HEIGHT,
      overflow: 'hidden',
      fontSize: '1.4rem',
    },
    cellBordered: {
      borderRight: theme.constants.tableBorder,
    },
    spacingCell: {
      padding: theme.spacing(0, 2),
      fontSize: '1.4rem',
    },
    nameHeaderCell: {
      paddingLeft: theme.spacing(2),
    },
    copyToClipboardLink: {
      height: 'auto',
      padding: 0,
      textDecoration: 'none',
      '&&&&:hover': {
        backgroundColor: 'inherit',
        textDecoration: 'none',
      },
    },
  }),
  { name: 'FormsWidget' },
)

const FormsWidget = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])
  const dispatch = useDispatch()

  const isLocked = useIsSoapLocked(false)
  const allForms = useSelector(getTimetableEventForms) || []
  const isLoading = useSelector(getSOAPisLoading)
  const isFetching = useSelector(getSOAPisFetching)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const appointmentId = useSelector(getAppointmentId)
  const clientId = useSelector(getClientId)
  const business = useSelector(getCurrentBusiness)
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const isSOAPConsentFormsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CONSENT_FORMS),
  )
  const forms = isSOAPConsentFormsEnabled
    ? allForms
    : allForms.filter((form) => form.status === FormStatus.COMPLETE)
  const DocumentTypes: Constant[] = useSelector(getDocumentTypes)
  const formDocumentType = Utils.findConstantByName('Form', DocumentTypes)

  const editDisabled = !appointmentPermissions.update
  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const [openAddFormDialog] = useDialog(DialogNames.SELECT_ATTACHMENT)

  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [actionsAnchorElement, setActionsAnchorElement] =
    useState<HTMLElement | null>(null)
  const [activeActionItemId, setActiveActionItemId] = useState<string | null>(
    null,
  )
  const activeActionItem = forms.find((form) => form.id === activeActionItemId)

  const closeActionsPopper = () => {
    setActionsAnchorElement(null)
    setActiveActionItemId(null)
  }

  const onDocumentsFetched = (documents: Document[]) => {
    dispatch(addForm(documents, appointmentId || ''))
  }

  const onAddRequested = () => {
    openAddFormDialog({
      documentTypes: [formDocumentType],
      label: t('Common:FORM_NOUN').toLowerCase(),
      clientId,
      enrich: false,
      convertToTextDocument: false,
      skipFileTemplates: false,
      onDocumentsFetched,
    })
  }

  const columns = useFormsWidgetColumns({
    actionsAnchorElement,
    setActionsAnchorElement,
    setActiveActionItem: setActiveActionItemId,
    classes,
    activeActionItem: activeActionItemId,
  })

  const {
    isGeneratingForPreview,
    onViewRequested,
    isGeneratingForDownload,
    onDownloadRequested,
    closePopperIfPdfGenerated,
  } = usePdfActions(activeActionItem, closeActionsPopper)

  const onDeleteRequested = () => {
    openDeleteAttachmentAlert({
      message: getDeleteConfirmMessage(t('Common:FORM_NOUN').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteAttachmentAlert(),
      onOk: () => {
        if (activeActionItem && appointmentId) {
          dispatch(
            deleteForm({
              formInstance: normalizeDocumentForSave(activeActionItem),
              documentId: activeActionItem.id,
              appointmentId,
            }),
          )
        }
        closeDeleteAttachmentAlert()
      },
    })
    closeActionsPopper()
  }

  const formActions = [
    {
      id: 'forms_view_action',
      label: t('Common:VIEW_ACTION'),
      Icon: isGeneratingForPreview
        ? () => (
            <CircularProgress
              color="secondary"
              size={16}
              sx={{ marginLeft: 0.5, marginRight: 1.5 }}
            />
          )
        : ViewIcon,
      onClick: onViewRequested,
    },
    {
      id: 'forms_download_action',
      label: t('Common:DOWNLOAD_ACTION'),
      Icon: isGeneratingForDownload
        ? () => (
            <CircularProgress
              color="secondary"
              size={16}
              sx={{ marginLeft: 0.5, marginRight: 1.5 }}
            />
          )
        : DownloadIcon,
      onClick: onDownloadRequested,
    },

    ...(isLocked || activeActionItem?.status === FormStatus.COMPLETE
      ? []
      : [
          ...(isSOAPConsentFormsEnabled
            ? [
                {
                  id: 'forms_kiosk_link_action',
                  Icon: ChainIcon,
                  content: (
                    <CopyToClipboard
                      classes={{
                        link: classes.copyToClipboardLink,
                      }}
                      label={t('Common:COPY_KIOSK_SYSTEM_LINK')}
                      text={getKioskUrl('consent-forms', {
                        businessId: business?.id,
                        eventId: appointmentId,
                        clientId,
                      })}
                    />
                  ),
                },
              ]
            : []),
          {
            id: 'forms_delete_action',
            label: t('Common:DELETE_ACTION'),
            Icon: DeleteIcon,
            onClick: onDeleteRequested,
          },
        ]),
  ]

  const numOptions = forms
    .map((form) => getFormSelectedOptions(form))
    .flat().length
  const numViewportItems = forms ? forms.length + numOptions : 0

  // Remove once FeatureToggle.SOAP_CONSENT_FORMS is removed, as we currently can’t add new forms when this feature toggle is off
  if (!isSOAPConsentFormsEnabled && !forms.length) {
    return null
  }

  return (
    <SoapWidget id="forms-widget" title={t('Common:FORMS')}>
      <Grid container item direction="column">
        {(forms && forms.length > 0) || !isReadOnly ? (
          <PuiDataTable
            borderless
            densed
            dynamicSize
            columns={columns}
            emptyPlaceholder={
              <Text align="center" m={2} variant="body2">
                {t('Soap:APPOINTMENT_FORMS.NO_FORMS')}
              </Text>
            }
            isLoading={isLoading || isFetching}
            items={forms}
            loadMoreItems={() => {}}
            minRowHeight={ROW_HEIGHT}
            minViewPortHeight={numViewportItems * ROW_HEIGHT}
            rowHeight={ROW_HEIGHT}
            totalCount={forms ? forms.length : 0}
          />
        ) : (
          <Text p={2}>{t('Soap:APPOINTMENT_FORMS.NO_FORMS')}</Text>
        )}
        {!isReadOnly && isSOAPConsentFormsEnabled && (
          <Grid container item className={classes.addSection} mt="auto">
            <AddButton
              addText={t('Common:ADD_FORM')}
              classes={{ addItem: classes.addItem }}
              disabled={editDisabled}
              onAdd={onAddRequested}
            />
          </Grid>
        )}
      </Grid>
      <ActionsPopper
        actions={formActions}
        anchorEl={actionsAnchorElement}
        disablePortal={false}
        id="estm_actions"
        onClose={closePopperIfPdfGenerated}
      />
    </SoapWidget>
  )
}

export default FormsWidget
