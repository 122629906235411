import axios from 'axios'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'

import { getEnvironment } from '.'

const API = Symbol('API')
const searchEngineSymbol = Symbol('searchEngine')

const getPlaceTimezone = ({ lat, lng }: google.maps.LatLngLiteral) =>
  axios
    .get('https://maps.googleapis.com/maps/api/timezone/json', {
      params: {
        location: `${lat},${lng}`,
        timestamp: new Date().getTime() / 1000,
        key: ENVIRONMENT_VARIABLES[getEnvironment()].GOOGLE_API_KEY,
      },
    })
    .then(({ data }) => data)

const getPlaceAPIDetails = (
  searchEngine: any,
  { placeId = '', type = '' }: { placeId?: string; type?: string },
) =>
  new Promise((resolve, reject) => {
    searchEngine.getDetails(
      {
        placeId,
        fields: [
          'name',
          'geometry',
          'formatted_address',
          'website',
          'address_component',
          'international_phone_number',
          'icon',
          'place_id',
        ],
        type,
      },
      (place: any, status: string) => {
        if (status === 'OK') {
          resolve(place)
        } else {
          reject()
        }
      },
    )
  })

class GoogleMapHelper {
  private [API]: any

  private [searchEngineSymbol]: any

  constructor(api: any, map: any) {
    this[API] = api
    this[searchEngineSymbol] = new this[API].places.PlacesService(map)
  }

  destroy() {
    this[API] = undefined
    this[searchEngineSymbol] = undefined
  }

  searchPlaces({
    query,
    area,
    type = '',
    fallback = false,
  }: {
    area?: any
    fallback?: boolean
    query: string
    type?: string
  }) {
    if (!this[searchEngineSymbol]) {
      return Promise.reject()
    }
    const request = {
      [area ? 'keyword' : 'query']: query,
      fields: ['name', 'geometry', 'formatted_address'],
      type,
      language: 'en',
    }
    if (area) {
      request.bounds = new this[API].LatLngBounds(area.sw, area.ne)
    }
    return new Promise((resolve, reject) => {
      this[searchEngineSymbol][area ? 'nearbySearch' : 'textSearch'](
        request,
        (points: any) => {
          if (!this[searchEngineSymbol]) {
            reject()
          }
          if (fallback && area && (!points || !points.length)) {
            this.searchPlaces({ query, type }).then(resolve, reject)
          } else {
            resolve(points || [])
          }
        },
      )
    })
  }

  getPlaceDetails(params: {
    lat?: any
    lng?: any
    placeId?: string
    type: string
  }) {
    if (!this[searchEngineSymbol]) {
      return Promise.reject()
    }
    return Promise.all([
      getPlaceAPIDetails(this[searchEngineSymbol], params),
      getPlaceTimezone({ lat: params.lat, lng: params.lng }),
    ])
  }
}

export default GoogleMapHelper
export const SearchTypes = {
  VETERINARY_CARE: 'veterinary_care',
}
