/* eslint-disable max-lines */
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  ControlButtonGroup,
  ControlButtonGroupName,
  ErrorTooltip,
  LanguageUtils,
  Nil,
  PlusButton,
  PuiCheckbox,
  Text,
  TextWithTooltip,
  UnsavedWellnessPlan,
  Utils,
  ValidateHandle,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Wellness as WellnessIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import {
  REQUIRED_EXTRA_BENEFIT_COUNT,
  REQUIRED_PACKAGE_BENEFIT_COUNT,
  REQUIRED_TIER_BENEFIT_COUNT,
  WellnessPlanLevels,
} from '~/constants/wellnessPlansConstants'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'
import { getWellnessPlanType } from '~/store/reducers/constants'
import { getWellnessPlanGlobalBenefitGroups } from '~/store/reducers/wellnessPlans'
import useDialog from '~/utils/useDialog'

import {
  dropIdsFromPlan,
  getBenefitIsAccessToBoop,
  getBenefitUniqueId,
  getEnabledPlans,
  getPlanHasBenefit,
  globalBenefitToSelected,
  isLevelEnabled,
  removeBenefitFromPlan,
  useGroupedWellnessPlans,
} from '../../wellnessPlanUtils'

const FIRST_ROW_HEIGHT = 108

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '100%',
      width: 'auto',
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    headerRow: {
      flexShrink: 0,
    },
    headerText: {
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
    },
    nameColumn: {
      width: 300,
      minWidth: 300,
      padding: theme.spacing(1, 2, 1, 4),
    },
    nameColumnHeader: {
      height: FIRST_ROW_HEIGHT,
      borderBottom: theme.constants.tabBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    nameColumnHeaderText: {
      color: theme.colors.planBranding,
    },
    boopLogo: {
      marginRight: theme.spacing(1),
      height: '2.4rem',
      width: '6.2rem',
    },
    headerTitleRow: {
      padding: theme.spacing(1),
      borderBottom: theme.constants.tabBorder,
      height: (2 / 5) * FIRST_ROW_HEIGHT,
    },
    borderColumn: {
      borderRight: theme.constants.tabBorder,
    },
    headerPlanNamesRow: {
      height: (3 / 5) * FIRST_ROW_HEIGHT,
      borderBottom: theme.constants.tabBorder,
    },
    planNameText: {
      fontSize: '1.6rem',
    },
    planColumn: {
      minWidth: 135,
      width: 135,
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tabBorder,
      },
      padding: theme.spacing(0.5, 1),
    },
    addTierColumn: {
      width: 135,
    },
    addPackageColumn: {
      width: 215,
    },
    planColumnError: {
      borderBottom: `2px solid ${theme.colors.alertLabelError}`,
    },
    subHeaderColumn: {
      borderBottom: theme.constants.tabBorder,
      height: 40,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    subHeaderRow: {
      height: 40,
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderBottom: theme.constants.tabBorder,
    },
    planActionsColumn: {
      height: 40,
      padding: theme.spacing(0),
    },
    benefitRow: {
      height: 40,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    benefitGroupRow: {
      height: 40,
      borderBottom: theme.constants.tabBorder,
      '&:not(:first-of-type)': {
        borderTop: theme.constants.tabBorder,
      },
    },
    checkboxColumn: {
      height: 40,
      padding: theme.spacing(0.5, 1.5),
    },
    leftColumn: {
      width: 'auto',
    },
    rightColumn: {
      overflowX: 'auto',
    },
    checkbox: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    plusButton: {
      margin: theme.spacing(1),
    },
    errorText: {
      color: theme.colors.alertLabelError,
    },
    errorPopper: {
      top: '-10px !important',
    },
    plansColumn: {
      minWidth: 'fit-content',
    },
    wellnessIcon: {
      color: theme.colors.success,
    },
    wellnessBenefitsText: {
      marginLeft: theme.spacing(1),
      color: theme.colors.success,
    },
    addButton: {
      margin: `auto ${theme.spacing(1)} auto 0`,
      minWidth: 20,
    },
  }),
  { name: 'WellnessPlanBasics' },
)

export interface WellnessPlanBasicsHandle extends ValidateHandle {}

interface WellnessPlanBasicsProps {
  setWellnessPlanVersion: (version: WellnessPlanVersion | Nil) => void
  togglePlan: (plan: WellnessPlan) => void
  wellnessPlanVersion: WellnessPlanVersion
  wellnessPlanVersionTemplate: WellnessPlanVersion | Nil
}

const WellnessPlanBasics = forwardRef<
  WellnessPlanBasicsHandle,
  WellnessPlanBasicsProps
>(function WellnessPlanBasics(
  {
    wellnessPlanVersion,
    wellnessPlanVersionTemplate,
    setWellnessPlanVersion,
    togglePlan,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Plurals', 'WellnessPlans'])

  const WellnessPlanType = useSelector(getWellnessPlanType)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)

  const [openWellnessPlanTierDialog] = useDialog(DialogNames.WELLNESS_PLAN_TIER)
  const [openWellnessPlanPackageDialog] = useDialog(
    DialogNames.WELLNESS_PLAN_PACKAGE,
  )
  const [openWellnessPlanExtraDialog] = useDialog(
    DialogNames.WELLNESS_PLAN_EXTRA,
  )

  const { plans = [], activePatients = 0 } = wellnessPlanVersion

  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const hasActivePatients = activePatients > 0
  const {
    packages = [],
    extras = [],
    tiers = [],
  } = useGroupedWellnessPlans(plans)
  const hasExtras = extras.length > 0
  const levels = R.pluck('level', plans)
  const maxLevel = R.reduce(R.max, -Infinity, levels) as number
  const maxLevelPlan = plans.find((plan) => plan.level === maxLevel)
  const templateBenefits = R.flatten(
    R.pluck('benefits', wellnessPlanVersionTemplate?.plans || []),
  )
  const templateBenefitsByGroup = R.groupBy(R.prop('groupId'), templateBenefits)
  const benefitGroups = globalBenefitGroups
    .map((group) => ({
      ...group,
      benefits: R.pipe(
        R.concat(group.benefits || []),
        R.uniqBy(getBenefitUniqueId),
        R.filter(
          (benefit: WellnessPlanBenefit) => !getBenefitIsAccessToBoop(benefit),
        ),
      )(templateBenefitsByGroup[group.id] || []),
    }))
    .filter((group) => group.benefits.length > 0)

  const enabledPlans = getEnabledPlans(wellnessPlanVersion)
  const { tiers: enabledTiers = [] } = useGroupedWellnessPlans(enabledPlans)

  const hasEnabledPlans = enabledPlans.length > 0
  const hasSingleEnabledTier = enabledTiers.length === 1

  const [invalidTier, setInvalidTier] = useState<WellnessPlan>()
  const [invalidPackage, setInvalidPackage] = useState<WellnessPlan>()
  const [invalidExtra, setInvalidExtra] = useState<WellnessPlan>()

  const findInvalidPlan = (
    plansToFind: WellnessPlan[],
    requiredBenefitCount: number,
  ) =>
    plansToFind.find((plan) => {
      const planBenefits = plan.benefits.filter(
        (benefit) => !getBenefitIsAccessToBoop(benefit),
      )
      return planBenefits.length < requiredBenefitCount
    })

  const validate = () => {
    // validate tiers
    // do not validate base plan
    const tiersToValidate = tiers.filter(
      (tier) => tier.level !== WellnessPlanLevels.BASE,
    )
    const foundInvalidTier = findInvalidPlan(
      tiersToValidate,
      REQUIRED_TIER_BENEFIT_COUNT,
    )

    setInvalidTier(foundInvalidTier)

    if (foundInvalidTier) {
      return false
    }

    // validate packages
    const foundInvalidPackage = findInvalidPlan(
      packages,
      REQUIRED_PACKAGE_BENEFIT_COUNT,
    )

    setInvalidPackage(foundInvalidPackage)

    if (foundInvalidPackage) {
      return false
    }

    // validate extras
    const foundInvalidExtra = findInvalidPlan(
      extras,
      REQUIRED_EXTRA_BENEFIT_COUNT,
    )

    setInvalidExtra(foundInvalidExtra)

    return !foundInvalidExtra
  }

  const onAddTier = () => {
    openWellnessPlanTierDialog({
      onProceed: (tier: UnsavedWellnessPlan) => {
        const tierLeveled = {
          ...maxLevelPlan,
          ...(tier as WellnessPlan),
          level: maxLevel + 1,
          benefits: [], // do not copy any benefits to new tiers
          id: null!, // remove id from existing plan
        }
        setWellnessPlanVersion({
          ...wellnessPlanVersion,
          plans: wellnessPlanVersion.plans.concat(tierLeveled),
        })
      },
    })
  }

  const onAddPackage = () => {
    openWellnessPlanPackageDialog({
      onProceed: (packageItem: UnsavedWellnessPlan) => {
        const packageLeveled = {
          ...(packageItem as WellnessPlan),
          level: null!, // packages do not have a level, set it explicitly
          benefits: [], // do not copy any benefits to new packages
          id: null!, // remove id from existing package
        }
        setWellnessPlanVersion({
          ...wellnessPlanVersion,
          plans: wellnessPlanVersion.plans.concat(packageLeveled),
        })
      },
    })
  }

  const onAddExtra = () => {
    openWellnessPlanExtraDialog({
      onProceed: (extra: UnsavedWellnessPlan) => {
        const extraLeveled = {
          ...(extra as WellnessPlan),
          level: null!, // extras do not have a level, set it explicitly
          benefits: [], // do not copy any benefits to new extras
          id: null!, // remove id from existing extra
        }
        setWellnessPlanVersion({
          ...wellnessPlanVersion,
          plans: wellnessPlanVersion.plans.concat(extraLeveled),
        })
      },
    })
  }

  const onDelete = (plan: WellnessPlan) => {
    togglePlan(plan)
  }

  const onEdit = (plan: WellnessPlan) => {
    const isPackage = plan.planTypeId === PackageTypeId
    const isExtra = plan.planTypeId === ExtraTypeId
    const planIndex = plans.indexOf(plan)

    const onProceed = (updatedPlan: WellnessPlan | UnsavedWellnessPlan) => {
      setWellnessPlanVersion({
        ...wellnessPlanVersion,
        plans: R.update(
          planIndex,
          updatedPlan as WellnessPlan,
          wellnessPlanVersion.plans,
        ),
      })
    }

    if (isPackage) {
      openWellnessPlanPackageDialog({
        package: plan,
        onProceed,
      })
    } else if (isExtra) {
      openWellnessPlanExtraDialog({
        extra: plan,
        onProceed,
      })
    } else {
      openWellnessPlanTierDialog({
        tier: plan,
        onProceed,
      })
    }
  }

  const onDuplicate = (plan: WellnessPlan) => {
    const newPlan = {
      ...dropIdsFromPlan(plan),
      level: R.isNil(plan.level) ? null : maxLevel + 1,
      name: `${plan.name}_copy`,
    }
    setWellnessPlanVersion({
      ...wellnessPlanVersion,
      plans: wellnessPlanVersion.plans.concat(newPlan as WellnessPlan),
    })
  }

  const onCheckboxToggle = (
    globalBenefit: WellnessPlanBenefit,
    plan: WellnessPlan,
    checked: boolean,
  ) => {
    const benefit = globalBenefitToSelected(globalBenefit)
    const wellnessPlanIndex = plans.indexOf(plan)

    const benefitInThisPlan = plan.benefits.find(
      ({ globalBenefitId }) => globalBenefitId === benefit.globalBenefitId,
    )

    const updatedBenefits = checked
      ? plan.benefits.concat(benefit)
      : removeBenefitFromPlan(plan, benefit)

    const updatedWellnessPlan = {
      ...plan,
      benefits: updatedBenefits,
      deletedBenefitIds:
        checked || !benefitInThisPlan?.id
          ? plan.deletedBenefitIds
          : R.uniq((plan.deletedBenefitIds || []).concat(benefitInThisPlan.id)),
    }

    const updatedWellnessPlans = R.update(
      wellnessPlanIndex,
      updatedWellnessPlan,
      plans,
    )

    setWellnessPlanVersion({
      ...wellnessPlanVersion,
      plans: updatedWellnessPlans,
    })
    setInvalidTier(undefined)
    setInvalidPackage(undefined)
    setInvalidExtra(undefined)
  }

  useImperativeHandle(ref, () => ({
    validate,
  }))

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid
        container
        item
        className={classNames(classes.leftColumn, classes.borderColumn)}
        direction="column"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classNames(classes.nameColumn, classes.nameColumnHeader)}
          pl={2}
          wrap="nowrap"
        >
          {openBoop ? (
            <>
              <WellnessIcon className={classes.wellnessIcon} />
              <Text
                strong
                className={classes.wellnessBenefitsText}
                variant="body"
              >
                {t('Common:WELLNESS_BENEFITS')}
              </Text>
            </>
          ) : (
            <>
              <Text
                strong
                className={classes.nameColumnHeaderText}
                variant="body2"
              >
                {t('Common:INCLUDED_IN_ALL_PLANS')}
              </Text>
            </>
          )}
        </Grid>
        <Grid
          item
          className={classNames(classes.nameColumn, classes.subHeaderColumn)}
        />
        <Grid container item direction="column" wrap="nowrap">
          {benefitGroups.map((group) => {
            const benefits = group.benefits.map((benefit) => (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(classes.nameColumn, classes.benefitRow)}
                key={getBenefitUniqueId(benefit)}
              >
                <Text variant="body">
                  {LanguageUtils.getTranslatedFieldName(benefit)}
                </Text>
              </Grid>
            ))

            return (
              <React.Fragment key={group.id}>
                <Grid
                  item
                  className={classNames(
                    classes.nameColumn,
                    classes.benefitGroupRow,
                  )}
                  pl={2}
                >
                  <Text strong variant="subheading3">
                    {LanguageUtils.getTranslatedFieldName(group)}
                  </Text>
                </Grid>
                <Grid container item direction="column">
                  {benefits}
                </Grid>
              </React.Fragment>
            )
          })}
        </Grid>
      </Grid>
      <Grid container item xs className={classes.rightColumn} wrap="nowrap">
        <Grid
          container
          item
          xs
          className={classNames(classes.plansColumn, classes.borderColumn)}
          direction="column"
          wrap="nowrap"
        >
          <Grid container item className={classes.headerTitleRow} wrap="nowrap">
            <TextWithTooltip
              tooltipText={t(
                'WellnessPlans:PATIENTS_CAN_BE_ENROLLED_IN_ONLY_ONE_PLAN',
              )}
              variant="h4"
            >
              {t('Common:WELLNESS_PLANS')}
            </TextWithTooltip>
          </Grid>
          <Grid
            container
            item
            className={classes.headerPlanNamesRow}
            wrap="nowrap"
          >
            {tiers.map((tier, index) => {
              const isTooltipOpen = invalidTier === tier

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classNames(classes.planColumn, {
                    [classes.planColumnError]: isTooltipOpen,
                  })}
                  key={tier.id || index}
                >
                  <ErrorTooltip
                    classes={{
                      errorPopper: classes.errorPopper,
                    }}
                    message={t(
                      'Plurals:Z_ICU_WORKAROUND.PLEASE_SELECT_AT_LEAST_NUMBER_BENEFITS',
                      {
                        number: REQUIRED_TIER_BENEFIT_COUNT,
                      },
                    )}
                    open={isTooltipOpen}
                    placement="top"
                    validateTag={Math.random()}
                  >
                    <Text
                      className={classNames(classes.planNameText, {
                        [classes.errorText]: isTooltipOpen,
                      })}
                      variant="h2"
                    >
                      {tier.name}
                    </Text>
                  </ErrorTooltip>
                </Grid>
              )
            })}
            {!hasActivePatients && (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(
                  classes.planColumn,
                  classes.addTierColumn,
                )}
              >
                <AddButton
                  addText={t('Common:ADD_PLAN')}
                  classes={{ plusButton: classes.addButton }}
                  onAdd={onAddTier}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item className={classes.subHeaderRow} wrap="nowrap">
            {tiers.map((tier, index) => {
              const enabled = isLevelEnabled(tier.level, wellnessPlanVersion)

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classNames(
                    classes.planColumn,
                    classes.planActionsColumn,
                  )}
                  key={tier.id || index}
                >
                  {enabled ? (
                    <ControlButtonGroup
                      buttons={[
                        {
                          name: ControlButtonGroupName.DELETE,
                          disabled:
                            hasActivePatients ||
                            (hasSingleEnabledTier && hasExtras),
                          onClick: () => onDelete(tier),
                        },
                        {
                          name: ControlButtonGroupName.EDIT,
                          disabled: hasActivePatients,
                          onClick: () => onEdit(tier),
                        },
                        {
                          name: ControlButtonGroupName.DUPLICATE,
                          disabled: hasActivePatients,
                          onClick: () => onDuplicate(tier),
                        },
                      ]}
                    />
                  ) : (
                    <PlusButton
                      classes={{
                        iconContainer: classes.plusButton,
                      }}
                      onClick={() => togglePlan(tier)}
                    />
                  )}
                </Grid>
              )
            })}
            {!hasActivePatients && (
              <Grid
                item
                className={classNames(
                  classes.planColumn,
                  classes.addTierColumn,
                )}
              />
            )}
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            {benefitGroups.map((group) => {
              const benefits = group.benefits.map((benefit) => (
                <Grid
                  container
                  item
                  className={classes.benefitRow}
                  key={getBenefitUniqueId(benefit)}
                  wrap="nowrap"
                >
                  {tiers.map((tier, index) => {
                    const isGlobalBenefit = !benefit.globalBenefitId
                    const hasBenefit = getPlanHasBenefit(tier, benefit)
                    const isAccessToBoop = getBenefitIsAccessToBoop(benefit)
                    const isMatchingLevel = tier.level >= benefit.level
                    const enabled = isLevelEnabled(
                      tier.level,
                      wellnessPlanVersion,
                    )
                    const shouldShowCheckbox =
                      (isMatchingLevel || !isGlobalBenefit) &&
                      !isAccessToBoop &&
                      enabled

                    return (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={tier.id || index}
                      >
                        {shouldShowCheckbox && (
                          <PuiCheckbox
                            checkboxClasses={{
                              root: classes.checkbox,
                            }}
                            checked={hasBenefit}
                            disabled={
                              hasActivePatients ||
                              (!hasBenefit && !isGlobalBenefit)
                            }
                            onChange={(event, checked) =>
                              onCheckboxToggle(benefit, tier, checked)
                            }
                          />
                        )}
                      </Grid>
                    )
                  })}
                  {!hasActivePatients && (
                    <Grid
                      item
                      className={classNames(
                        classes.planColumn,
                        classes.addTierColumn,
                      )}
                    />
                  )}
                </Grid>
              ))

              return (
                <React.Fragment key={group.id}>
                  <Grid container item className={classes.benefitGroupRow}>
                    {tiers.map((tier, index) => (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={tier.id || index}
                      />
                    ))}
                    {!hasActivePatients && (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.addTierColumn,
                        )}
                      />
                    )}
                  </Grid>
                  <Grid container item direction="column">
                    {benefits}
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          className={classNames(classes.plansColumn, classes.borderColumn)}
          direction="column"
          wrap="nowrap"
        >
          <Grid container item className={classes.headerTitleRow} wrap="nowrap">
            <TextWithTooltip
              tooltipText={t(
                'WellnessPlans:PATIENT_MUST_BE_ENROLLED_IN_PLAN_TO_INCLUDE_ANY_EXTRA',
              )}
              variant="h4"
            >
              {t('Common:WELLNESS_EXTRAS')}
            </TextWithTooltip>
          </Grid>
          <Grid
            container
            item
            className={classes.headerPlanNamesRow}
            wrap="nowrap"
          >
            {extras.map((extra, index) => {
              const isTooltipOpen = invalidExtra === extra

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classNames(classes.planColumn, {
                    [classes.planColumnError]: isTooltipOpen,
                  })}
                  key={extra.id || index}
                >
                  <ErrorTooltip
                    classes={{
                      errorPopper: classes.errorPopper,
                    }}
                    message={t(
                      'Plurals:Z_ICU_WORKAROUND.PLEASE_SELECT_AT_LEAST_NUMBER_BENEFITS',
                      {
                        number: REQUIRED_EXTRA_BENEFIT_COUNT,
                      },
                    )}
                    open={isTooltipOpen}
                    placement="top"
                    validateTag={Math.random()}
                  >
                    <Text
                      className={classNames(classes.planNameText, {
                        [classes.errorText]: isTooltipOpen,
                      })}
                      variant="h2"
                    >
                      {extra.name}
                    </Text>
                  </ErrorTooltip>
                </Grid>
              )
            })}
            {!hasActivePatients && hasEnabledPlans && (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(
                  classes.planColumn,
                  classes.addTierColumn,
                )}
              >
                <AddButton
                  addText={t('Common:ADD_EXTRA')}
                  classes={{ plusButton: classes.addButton }}
                  onAdd={onAddExtra}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item className={classes.subHeaderRow} wrap="nowrap">
            {extras.map((extra, index) => (
              <Grid
                item
                className={classNames(
                  classes.planColumn,
                  classes.planActionsColumn,
                )}
                key={extra.id || index}
              >
                <ControlButtonGroup
                  buttons={[
                    {
                      name: ControlButtonGroupName.DELETE,
                      disabled: hasActivePatients,
                      onClick: () => onDelete(extra),
                    },
                    {
                      name: ControlButtonGroupName.EDIT,
                      disabled: hasActivePatients,
                      onClick: () => onEdit(extra),
                    },
                    {
                      name: ControlButtonGroupName.DUPLICATE,
                      disabled: hasActivePatients,
                      onClick: () => onDuplicate(extra),
                    },
                  ]}
                />
              </Grid>
            ))}
            {!hasActivePatients && (
              <Grid
                item
                className={classNames(
                  classes.planColumn,
                  classes.addTierColumn,
                )}
              />
            )}
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            {benefitGroups.map((group) => {
              const benefits = group.benefits.map((benefit) => (
                <Grid
                  container
                  item
                  className={classes.benefitRow}
                  key={getBenefitUniqueId(benefit)}
                  wrap="nowrap"
                >
                  {extras.map((extra, index) => {
                    const isGlobalBenefit = !benefit.globalBenefitId
                    const hasBenefit = getPlanHasBenefit(extra, benefit)
                    const isAccessToBoop = getBenefitIsAccessToBoop(benefit)
                    const shouldShowCheckbox = !isAccessToBoop

                    return (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={extra.id || index}
                      >
                        {shouldShowCheckbox && (
                          <PuiCheckbox
                            checkboxClasses={{
                              root: classes.checkbox,
                            }}
                            checked={hasBenefit}
                            disabled={
                              hasActivePatients ||
                              (!hasBenefit && !isGlobalBenefit)
                            }
                            onChange={(event, checked) =>
                              onCheckboxToggle(benefit, extra, checked)
                            }
                          />
                        )}
                      </Grid>
                    )
                  })}
                  {!hasActivePatients && (
                    <Grid
                      item
                      className={classNames(
                        classes.planColumn,
                        classes.addTierColumn,
                      )}
                    />
                  )}
                </Grid>
              ))

              return (
                <React.Fragment key={group.id}>
                  <Grid container item className={classes.benefitGroupRow}>
                    {extras.map((extra, index) => (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={extra.id || index}
                      />
                    ))}
                    {!hasActivePatients && (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.addTierColumn,
                        )}
                      />
                    )}
                  </Grid>
                  <Grid container item direction="column">
                    {benefits}
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          className={classes.plansColumn}
          direction="column"
          wrap="nowrap"
        >
          <Grid container item className={classes.headerTitleRow} wrap="nowrap">
            <TextWithTooltip
              tooltipText={t(
                'WellnessPlans:SUBSCRIPTIONS_ARE_AVAILABLE_INDEPENDENTLY_OF_WELLNESS_PLANS',
              )}
              variant="h4"
            >
              {t('Common:SUBSCRIBE_AND_SAVE')}
            </TextWithTooltip>
          </Grid>
          <Grid
            container
            item
            className={classes.headerPlanNamesRow}
            wrap="nowrap"
          >
            {packages.map((packageItem, index) => {
              const isTooltipOpen = invalidPackage === packageItem

              return (
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classNames(classes.planColumn, {
                    [classes.planColumnError]: isTooltipOpen,
                  })}
                  key={packageItem.id || index}
                >
                  <ErrorTooltip
                    classes={{
                      errorPopper: classes.errorPopper,
                    }}
                    message={t(
                      'Plurals:Z_ICU_WORKAROUND.PLEASE_SELECT_AT_LEAST_NUMBER_BENEFITS',
                      {
                        number: REQUIRED_PACKAGE_BENEFIT_COUNT,
                      },
                    )}
                    open={isTooltipOpen}
                    placement="top"
                    validateTag={Math.random()}
                  >
                    <Text
                      className={classNames(classes.planNameText, {
                        [classes.errorText]: isTooltipOpen,
                      })}
                      variant="h2"
                    >
                      {packageItem.name}
                    </Text>
                  </ErrorTooltip>
                </Grid>
              )
            })}
            {!hasActivePatients && (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(
                  classes.planColumn,
                  classes.addPackageColumn,
                )}
              >
                <AddButton
                  addText={t('Common:ADD_SUBSCRIBE_AND_SAVE')}
                  classes={{ plusButton: classes.addButton }}
                  onAdd={onAddPackage}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item className={classes.subHeaderRow} wrap="nowrap">
            {packages.map((packageItem, index) => (
              <Grid
                item
                className={classNames(
                  classes.planColumn,
                  classes.planActionsColumn,
                )}
                key={packageItem.id || index}
              >
                <ControlButtonGroup
                  buttons={[
                    {
                      name: ControlButtonGroupName.DELETE,
                      disabled: hasActivePatients,
                      onClick: () => onDelete(packageItem),
                    },
                    {
                      name: ControlButtonGroupName.EDIT,
                      disabled: hasActivePatients,
                      onClick: () => onEdit(packageItem),
                    },
                    {
                      name: ControlButtonGroupName.DUPLICATE,
                      disabled: hasActivePatients,
                      onClick: () => onDuplicate(packageItem),
                    },
                  ]}
                />
              </Grid>
            ))}
            {!hasActivePatients && (
              <Grid
                item
                className={classNames(
                  classes.planColumn,
                  classes.addPackageColumn,
                )}
              />
            )}
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            {benefitGroups.map((group) => {
              const benefits = group.benefits.map((benefit) => (
                <Grid
                  container
                  item
                  className={classes.benefitRow}
                  key={getBenefitUniqueId(benefit)}
                  wrap="nowrap"
                >
                  {packages.map((packageItem, index) => {
                    const isGlobalBenefit = !benefit.globalBenefitId
                    const hasBenefit = getPlanHasBenefit(packageItem, benefit)
                    const isAccessToBoop = getBenefitIsAccessToBoop(benefit)
                    const shouldShowCheckbox = !isAccessToBoop

                    return (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={packageItem.id || index}
                      >
                        {shouldShowCheckbox && (
                          <PuiCheckbox
                            checkboxClasses={{
                              root: classes.checkbox,
                            }}
                            checked={hasBenefit}
                            disabled={
                              hasActivePatients ||
                              (!hasBenefit && !isGlobalBenefit)
                            }
                            onChange={(event, checked) =>
                              onCheckboxToggle(benefit, packageItem, checked)
                            }
                          />
                        )}
                      </Grid>
                    )
                  })}
                  {!hasActivePatients && (
                    <Grid
                      item
                      className={classNames(
                        classes.planColumn,
                        classes.addPackageColumn,
                      )}
                    />
                  )}
                </Grid>
              ))

              return (
                <React.Fragment key={group.id}>
                  <Grid container item className={classes.benefitGroupRow}>
                    {packages.map((packageItem, index) => (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.checkboxColumn,
                        )}
                        key={packageItem.id || index}
                      />
                    ))}
                    {!hasActivePatients && (
                      <Grid
                        item
                        className={classNames(
                          classes.planColumn,
                          classes.addPackageColumn,
                        )}
                      />
                    )}
                  </Grid>
                  <Grid container item direction="column">
                    {benefits}
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default WellnessPlanBasics
