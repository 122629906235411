import { RoleName, User } from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import { TimetableEvent } from '~/types'

export const getDoctor = (
  appointment: TimetableEvent | undefined,
): User | undefined =>
  appointment?.personResponsibilities?.filter(
    (pr) => pr.responsibility?.name === ResponsibilityName.Veterinarian,
  )?.[0]?.person ||
  appointment?.personRoles?.filter(
    (pr) => pr.role?.name === RoleName.Veterinarian,
  )?.[0]?.person
