import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { sendDefaultWelcomeEmail } from '~/store/actions/wellnessPlans'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { composePatientNames } from '~/utils/paymentUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    success: {
      maxWidth: 450,
    },
    successWithNames: {
      maxWidth: 450,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    successIcon: {
      width: 38,
      height: 38,
      color: theme.colors.alertSuccess,
    },
    paragraphWithNames: {
      fontSize: 20,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'MembershipSignUpSuccessSection' },
)

interface MembershipSignUpSuccessSectionProps {
  clientId: string | Nil
  onClose?: () => void
  patientNames: string[]
  selection: { patientId: string; planId: string }[]
}

const MembershipSignUpSuccessSection = ({
  clientId,
  onClose,
  patientNames,
  selection,
}: MembershipSignUpSuccessSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Memberships'])

  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))

  const isIpoM2BBrainTreeSignupEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M2B_BRAINTREE_SIGNUP),
  )

  const classes = useStyles({
    buttonsColor: business?.buttonsColor,
    backgroundColor: business?.backgroundColor,
  })

  useEffect(() => {
    if (client?.id && client?.email) {
      dispatch(sendDefaultWelcomeEmail(client.id, client.email, selection))
    }
  }, [client, selection])

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      direction="column"
    >
      <Grid
        container
        alignItems="center"
        className={
          isIpoM2BBrainTreeSignupEnabled
            ? classes.successWithNames
            : classes.success
        }
        direction="column"
        px={8}
        py={4}
      >
        <SuccessAlert className={classes.successIcon} />
        {isIpoM2BBrainTreeSignupEnabled ? (
          <Grid>
            <Text align="center" className={classes.paragraphWithNames} my={3}>
              {t(
                'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.CONGRATS_ON_ENROLLING_WITH_NAME',
                {
                  petNames: composePatientNames(patientNames),
                },
              )}
            </Text>
            <Text align="center">
              {t(
                'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.ENROLLING_REMINDER',
              )}
            </Text>
          </Grid>
        ) : (
          <Grid>
            <Text align="center" my={3}>
              {t(
                'Memberships:MEMBERSHIP_SIGN_UP_SUCCESS_SECTION.CONGRATS_ON_ENROLLING',
              )}
            </Text>
          </Grid>
        )}
        {!isIpoM2BBrainTreeSignupEnabled && (
          <ButtonWithLoader onClick={onClose}>
            {t('Common:CLOSE_ACTION')}
          </ButtonWithLoader>
        )}
      </Grid>
    </Grid>
  )
}

export default MembershipSignUpSuccessSection
