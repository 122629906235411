import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Breakpoint } from '@mui/material'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  RoleName,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { ReminderProtocol } from '~/types'
import useHasRole from '~/utils/useHasRole'

import StepAddReminderFromProtocol, {
  StepAddReminderFromProtocolProps,
} from './StepAddReminderFromProtocol'
import StepAddReminderFromProtocolDetails, {
  StepAddReminderFromProtocolDetailsProps,
} from './StepAddReminderFromProtocolDetails'
import StepAddReminderInit, {
  StepAddReminderInitProps,
} from './StepAddReminderInit'
import StepAddReminderManually, {
  StepAddReminderManuallyProps,
} from './StepAddReminderManually'

enum AddReminderStep {
  ADD_FROM_PROTOCOL = 'ADD_FROM_PROTOCOL',
  ADD_FROM_PROTOCOL_DETAILS = 'ADD_FROM_PROTOCOL_DETAILS',
  ADD_MANUALLY = 'ADD_MANUALLY',
  INITIAL = 'INITIAL',
}

const AddReminderStepToComponent = {
  [AddReminderStep.ADD_FROM_PROTOCOL_DETAILS]:
    StepAddReminderFromProtocolDetails,
  [AddReminderStep.ADD_FROM_PROTOCOL]: StepAddReminderFromProtocol,
  [AddReminderStep.ADD_MANUALLY]: StepAddReminderManually,
  [AddReminderStep.INITIAL]: StepAddReminderInit,
}

const AddReminderStepToSize: Record<AddReminderStep, Breakpoint> = {
  [AddReminderStep.ADD_FROM_PROTOCOL_DETAILS]: 'sm',
  [AddReminderStep.ADD_FROM_PROTOCOL]: 'lg',
  [AddReminderStep.ADD_MANUALLY]: 'sm',
  [AddReminderStep.INITIAL]: 'sm',
}

interface AddReminderDialogProps extends BasePuiDialogProps {
  patientId: string | Nil
}

const AddReminderDialog = ({
  patientId,
  onClose,
  open,
}: AddReminderDialogProps) => {
  const { t } = useTranslation('Dialogs')
  const isCurrentUserVCR = useHasRole(RoleName.VCR)
  const isSimplifyReminderFlowForVCREnabled = useSelector(
    getFeatureToggle(FeatureToggle.SIMPLIFY_REMINDER_FLOW_FOR_VCR),
  )
  const shouldUseVCRBehavior =
    isCurrentUserVCR && isSimplifyReminderFlowForVCREnabled
  const [currentStep, setCurrentStep] = useState(
    shouldUseVCRBehavior
      ? AddReminderStep.ADD_FROM_PROTOCOL
      : AddReminderStep.INITIAL,
  )
  const [protocol, setProtocol] = useState<ReminderProtocol>()

  const AddReminderStepToTitle = {
    [AddReminderStep.ADD_FROM_PROTOCOL_DETAILS]: t(
      'Dialogs:ADD_REMINDER_DIALOG.TITLE_DEFAULT',
    ),
    [AddReminderStep.ADD_FROM_PROTOCOL]: t(
      'Dialogs:ADD_REMINDER_DIALOG.TITLE_ADD_FROM_PROTOCOL',
    ),
    [AddReminderStep.ADD_MANUALLY]: t(
      'Dialogs:ADD_REMINDER_DIALOG.TITLE_ADD_MANUALLY',
    ),
    [AddReminderStep.INITIAL]: t('Dialogs:ADD_REMINDER_DIALOG.TITLE_DEFAULT'),
  }

  const dialogSize = AddReminderStepToSize[currentStep]
  const dialogTitle = AddReminderStepToTitle[currentStep]
  const StepComponent = AddReminderStepToComponent[currentStep]

  const onCreateManually = () => {
    setCurrentStep(AddReminderStep.ADD_MANUALLY)
  }

  const onCreateFromProtocol = () => {
    setCurrentStep(AddReminderStep.ADD_FROM_PROTOCOL)
  }

  const onBack = (step?: AddReminderStep) => () => {
    setProtocol(undefined)
    setCurrentStep(step || AddReminderStep.INITIAL)
  }

  const onProtocolSelected = (selectedProtocol: ReminderProtocol) => {
    setProtocol(selectedProtocol)
    setCurrentStep(AddReminderStep.ADD_FROM_PROTOCOL_DETAILS)
  }

  const stepComponentProps = {
    ...(currentStep === AddReminderStep.INITIAL
      ? { onCreateManually, onCreateFromProtocol }
      : ({} as StepAddReminderInitProps)),
    ...(currentStep === AddReminderStep.ADD_MANUALLY
      ? { protocol, patientId, onBack: onBack(), onClose }
      : ({} as StepAddReminderManuallyProps)),
    ...(currentStep === AddReminderStep.ADD_FROM_PROTOCOL
      ? { onProtocolSelected, patientId, onBack: onBack() }
      : ({} as StepAddReminderFromProtocolProps)),
    ...(currentStep === AddReminderStep.ADD_FROM_PROTOCOL_DETAILS
      ? {
          protocol,
          patientId,
          onBack: onBack(AddReminderStep.ADD_FROM_PROTOCOL),
          onClose,
        }
      : ({} as StepAddReminderFromProtocolDetailsProps)),
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="add-reminder-dialog"
      maxWidth={dialogSize}
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <StepComponent {...stepComponentProps} />
    </PuiDialog>
  )
}

export default AddReminderDialog
