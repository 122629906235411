import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LanguageUtils, NumberUtils, UnitUtils } from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import { getNdc11DigitsFormat } from '~/components/common/form-inputs/ndc/ndcUtils'
import { getUnitsState } from '~/store/duck/settings'
import {
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getInventoryProductSizeUnit,
  getPackageType,
  getProductForm,
  getProductSizeUnit,
} from '~/store/reducers/constants'
import { Price, Variation } from '~/types'
import { boolToYesNoString, getManualInputSelectValue } from '~/utils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import { getOnHandAmount } from '../inventoryUtils'

const useStyles = makeStyles(
  () => ({
    noPadding: {
      padding: 0,
    },
    innerCell: {
      paddingBottom: 0,
      height: 36,
    },
    priceName: {
      width: 70,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
  { name: 'VariationsTableRow' },
)

const getRenderMultipleValues =
  (classes: { innerCell?: string; tableCell?: string }) =>
  (
    items: Price[],
    propName: keyof Price,
    renderFunc: (prop: any, index: number) => React.ReactNode,
  ) =>
    (items || []).map(({ id, [propName]: prop }, index) => {
      const isLast = items.length === index + 1
      const key = `${id}${index}`
      return (
        <TableRow key={key}>
          <TableCell
            className={classNames(
              classes.tableCell,
              !isLast && classes.innerCell,
            )}
          >
            {renderFunc ? renderFunc(prop, index) : prop}
          </TableCell>
        </TableRow>
      )
    })

interface VariationsTableRowProps {
  isDrug: boolean
  tableCellClassName?: string
  variation: Variation
}

const VariationsTableRow = ({
  isDrug,
  variation,
  tableCellClassName,
}: VariationsTableRowProps) => {
  const classes = useStyles()
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const unitsState = useSelector(getUnitsState)
  const { t } = useTranslation('Common')

  const getOnHandAmountLabel = getOnHandAmount(
    InventoryProductSizeUnit,
    PackageType,
  )

  const renderMultipleValues = getRenderMultipleValues({
    tableCell: tableCellClassName,
    innerCell: classes.innerCell,
  })

  const priceUnits = useGetFormattedPriceUnit(variation.prices, variation)

  const productSizeUnitDisplayName = LanguageUtils.getConstantTranslatedName(
    variation.perPackageUnitsId,
    ProductSizeUnit,
  )
  const packageTypeDisplayName = LanguageUtils.getConstantTranslatedName(
    variation.packageTypeId,
    PackageType,
  )

  return (
    <>
      <TableCell className={tableCellClassName}>
        {variation.perPackageAmount}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {`${productSizeUnitDisplayName} ${variation?.saleUnitOfMeasure || ''}`}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {packageTypeDisplayName}
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(variation.prices, 'name', (name: string) => (
              <div className={classes.priceName}>{name}</div>
            ))}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(variation.prices, 'price', (price: number) =>
              NumberUtils.formatMoney(price),
            )}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(
              variation.prices,
              'unitId',
              (unitId: string, index: number) => priceUnits[index],
            )}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(
              variation.prices,
              'minCharge',
              (minCharge: number) => NumberUtils.formatMoney(minCharge),
            )}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(
              variation.prices,
              'dispensingFee',
              (dispensingFee: number) => NumberUtils.formatMoney(dispensingFee),
            )}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(
              variation.prices,
              'discountAllowed',
              boolToYesNoString,
            )}
          </TableBody>
        </Table>
      </TableCell>
      <TableCell className={classNames(tableCellClassName, classes.noPadding)}>
        <Table>
          <TableBody>
            {renderMultipleValues(
              variation.prices,
              'taxable',
              boolToYesNoString,
            )}
          </TableBody>
        </Table>
      </TableCell>
      {isDrug && (
        <TableCell className={tableCellClassName}>
          {variation.nationalDrugCode &&
            getNdc11DigitsFormat(variation.nationalDrugCode)}
        </TableCell>
      )}
      <TableCell className={tableCellClassName}>
        {boolToYesNoString(variation.active)}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {getOnHandAmountLabel(variation)}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {getManualInputSelectValue(
          ProductForm,
          variation.formId,
          variation.customForm,
        )}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {LanguageUtils.getConstantTranslatedName(
          variation.deliveryMethodId,
          DrugDeliveryMethod,
        )}
      </TableCell>
      <TableCell className={tableCellClassName}>{variation.strength}</TableCell>
      <TableCell className={tableCellClassName}>
        {LanguageUtils.getConstantTranslatedName(
          variation.strengthUnitsId,
          DrugStrengthUnit,
        )}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {LanguageUtils.getConstantTranslatedName(
          variation.strengthColorId,
          DrugStrengthColor,
        )}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {R.isNil(variation.weightMinimum) && R.isNil(variation.weightMaximum)
          ? ''
          : `
          ${
            R.isNil(variation.weightMinimum)
              ? t('Common:ALL').toLowerCase()
              : UnitUtils.convertUnits(
                  UnitTypes.WEIGHT,
                  variation.weightMinimum,
                  unitsState,
                )
          }
            -
            ${
              R.isNil(variation.weightMaximum)
                ? t('Common:ALL').toLowerCase()
                : UnitUtils.convertUnits(
                    UnitTypes.WEIGHT,
                    variation.weightMaximum,
                    unitsState,
                  )
            }
            ${unitsState[UnitTypes.WEIGHT]}
            `}
      </TableCell>
      <TableCell className={tableCellClassName}>
        {variation.flavor ?? ''}
      </TableCell>
    </>
  )
}

export default VariationsTableRow
