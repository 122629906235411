import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import moment from 'moment'
import { DateFormat, HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'
import { getPersonString } from '@pbt/pbt-ui-components/src/utils'

import { getInfoAboutEnums } from '~/store/reducers/problems'
import { Problem, ProblemLogEnumReference, ProblemLogHistory } from '~/types'

import IdentifiedProblemDetailsHistoryEnum from './IdentifiedProblemDetailsHistoryEnum'
import IdentifiedProblemListItemStatus from './IdentifiedProblemListItemStatus'

interface IdentifiedProblemDetailsHistoryItemProps {
  historyItem: ProblemLogHistory
  problem: Problem
}

const IdentifiedProblemDetailsHistoryItem = ({
  historyItem,
  problem,
}: IdentifiedProblemDetailsHistoryItemProps) => {
  const { t } = useTranslation('Common')
  const parentHistoryEnums = historyItem.enumLogs.filter(
    (enumLog) => !enumLog.parentEnumValueId,
  )
  const childrenHistoryEnums = historyItem.enumLogs.filter(
    (enumLog) => enumLog.parentEnumValueId,
  )
  const parentHistoryEnumsIds = parentHistoryEnums.map((item) => item.enumId)
  const childrenHistoryEnumsIds = childrenHistoryEnums.map(
    (item) => item.enumId,
  )
  const parentHistoryEnumsInfo = useSelector(
    getInfoAboutEnums(parentHistoryEnumsIds),
  )
  const childrenHistoryEnumsInfo = useSelector(
    getInfoAboutEnums(childrenHistoryEnumsIds),
  )
  const createdAt = moment(historyItem.date).format(
    DateFormat.SHORT_DATE_YEAR_ABBREV,
  )

  return (
    <Box mb={2}>
      <Grid container alignItems="self-end" display="flex" mb={0.5} spacing={1}>
        <Grid item>
          <Text variant="lowAccent2">{createdAt}</Text>
        </Grid>
        <Grid item>
          <IdentifiedProblemListItemStatus
            problemType={problem.type}
            stateId={historyItem.stateId}
            variant="small"
          />
        </Grid>
      </Grid>
      {historyItem.doctor && (
        <Text variant="body2">
          <Text strong component="span" variant="body2">
            {t('Common:DOCTOR')}:
          </Text>
          &nbsp;
          {getPersonString(historyItem.doctor)}
        </Text>
      )}
      {historyItem.notes && (
        <HtmlNotesPreview
          includeLabel
          strong
          notes={historyItem.notes}
          variant="body2"
        />
      )}
      {Boolean(parentHistoryEnums?.length) && (
        <Text variant="body2">
          <Text strong component="span" variant="body2">
            {t('Soap:PROBLEMS.DESCRIPTION')}:
          </Text>
          {parentHistoryEnums.map((enumRef: ProblemLogEnumReference) => (
            <IdentifiedProblemDetailsHistoryEnum
              childrenHistoryEnums={childrenHistoryEnums}
              childrenHistoryEnumsInfo={childrenHistoryEnumsInfo}
              key={`history-enum-${enumRef.id}-${enumRef.enumId}`}
              parentHistoryEnum={enumRef}
              parentHistoryEnumsInfo={parentHistoryEnumsInfo}
            />
          ))}
        </Text>
      )}
    </Box>
  )
}

export default IdentifiedProblemDetailsHistoryItem
