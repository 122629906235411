import { useSelector } from 'react-redux'
import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import * as R from 'ramda'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  ChargesSection,
  GroupedItem,
  GroupedItems,
  GroupedLineItems,
  GroupIdentifier,
  InvoiceLineItem as InvoiceLineItemGenerated,
  MutationUpdateChargeSheetItemBatchArgs,
  MutationUpdateSectionsAdditionalDiscountArgs,
  QueryPendingActiveRxForOutstandingFinalizationArgs,
  QueryPendingIdexxImagingForOutstandingFinalizationArgs,
  QueryPendingLabTestsForOutstandingFinalizationArgs,
  QueryPendingsForOutstandingFinalizationWhenPostingInvoiceArgs,
  RetailOrderLineItem,
  RetailOrderLineItemProducerInput,
} from '~/api/graphql/generated/types'
import {
  getIsRetailOrderLineItem,
  isAnyItemPrepaid,
} from '~/components/dashboard/invoices/invoiceUtils'
import {
  BalanceChargeSheet,
  EditChargeSheetItemProducerBatchPayload,
  InvoiceLineItem,
  OpenChargeSheet,
  Order,
  OrderFilter,
} from '~/types'
import {
  AddChargeSheetItemsPayload,
  ChargeSheet,
  ChargeSheetItem,
  ChargeSheetItemSection,
  DeleteChargeSheetItemPayload,
} from '~/types/entities/chargesSheet'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { getErrorMessage } from '~/utils/errors'
import {
  convertChargeSheetGroupedLineItemToInvoiceLineItem,
  convertChargeSheetLineItemToInvoiceLineItem,
} from '~/utils/finance'

import {
  EMAIL_CHARGE_SHEET,
  EMAIL_CHARGE_SHEET_FAILURE,
  EMAIL_CHARGE_SHEET_SUCCESS,
  GENERATE_PDF_FOR_CHARGE_SHEET,
  GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE,
  GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS,
} from '../actions/types/communications'
import type { RootState } from '../index'

export const UPDATE_CLIENT_FINANCE_CHARGES =
  'clientFinanceData/UPDATE_CLIENT_FINANCE_CHARGES'
export const RESET_CLIENT_FINANCE_SUBITEMS =
  'clientFinanceData/RESET_CLIENT_FINANCE_SUBITEMS'

type PendingOutstandingOrders = {
  activeRx: boolean
  imaging: boolean
  labTests: boolean
}
export type ModifiedLineItem = {
  id: string
  logId?: string | Nil
  modificationDate?: string | Nil
  order?: { stateId?: string } | Nil
  soapLogModificationDate?: string | Nil
}

export type ClientFinanceDataState = {
  balanceChargeSheet: BalanceChargeSheet | Nil
  chargeSheet: ChargeSheet | Nil
  chargeSheetClientId: string | Nil
  chargeSheetItemMap: Record<string, ChargeSheetItem>
  chargeSheetItemsCount: number | Nil
  chargeSheetLineItemLoading: boolean
  chargeSheetPrintHtml: string | Nil
  chargeSheetSubItemsMap: Record<string, ChargeSheetItemSection>
  charges: ChargeSheet | InvoiceV3 | Nil
  chargesList: (InvoiceLineItem | RetailOrderLineItem)[]
  chargesListAmount: number
  clientBalance: number
  currentChargeSheetLineItem: InvoiceLineItem | RetailOrderLineItem | Nil
  editingLineItemState: string | Nil
  error: string | Nil
  expandedGroups: GroupIdentifier[] | Nil
  hasPendingOutstandingOrdersForChargeSheet: PendingOutstandingOrders
  hasPendingOutstandingOrdersWhilePostingInvoices: PendingOutstandingOrders
  isCheckingPendingOutstandingOrdersForChargeSheet: boolean
  isCheckingPendingOutstandingOrdersWhilePostingInvoices: boolean
  isGeneratingChargeSheetPdf: boolean
  itemErrors: any[]
  loading: boolean
  openChargeSheet: OpenChargeSheet | Nil
  orderFilters: OrderFilter[]
  orderFiltersError: string | Nil
  orderFiltersLoading: boolean
  orderStatusOrNotesLoading: boolean
}

const hasPendingOutstandingOrdersInitialState = {
  activeRx: false,
  imaging: false,
  labTests: false,
}

export const CLIENT_FINANCE_DATA_INITIAL_STATE: ClientFinanceDataState = {
  balanceChargeSheet: null,
  charges: null,
  chargeSheet: null,
  chargeSheetItemsCount: null,
  chargeSheetClientId: null,
  chargeSheetSubItemsMap: {},
  chargeSheetItemMap: {},
  error: null,
  itemErrors: [],
  loading: false,
  isGeneratingChargeSheetPdf: false,
  chargeSheetPrintHtml: null,
  expandedGroups: [],
  openChargeSheet: null,
  orderFilters: [],
  orderFiltersLoading: false,
  orderFiltersError: null,
  chargeSheetLineItemLoading: false,
  currentChargeSheetLineItem: null,
  clientBalance: 0,
  chargesList: [],
  chargesListAmount: 0,
  isCheckingPendingOutstandingOrdersForChargeSheet: false,
  isCheckingPendingOutstandingOrdersWhilePostingInvoices: false,
  hasPendingOutstandingOrdersForChargeSheet:
    hasPendingOutstandingOrdersInitialState,
  hasPendingOutstandingOrdersWhilePostingInvoices:
    hasPendingOutstandingOrdersInitialState,
  orderStatusOrNotesLoading: false,
  editingLineItemState: undefined,
}

const clientFinanceDataSlice = createSlice({
  name: 'clientFinanceData',
  initialState: CLIENT_FINANCE_DATA_INITIAL_STATE,
  reducers: {
    checkPendingOutstandingOrdersForInvoices: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryPendingsForOutstandingFinalizationWhenPostingInvoiceArgs>,
    ) => {
      state.isCheckingPendingOutstandingOrdersWhilePostingInvoices = true
      state.error = null
      state.hasPendingOutstandingOrdersWhilePostingInvoices =
        hasPendingOutstandingOrdersInitialState
    },
    checkPendingOutstandingOrdersForInvoicesSuccess: (
      state,
      action: PayloadAction<{
        pending: PendingOutstandingOrders
      }>,
    ) => {
      state.isCheckingPendingOutstandingOrdersWhilePostingInvoices = false
      state.hasPendingOutstandingOrdersWhilePostingInvoices =
        action.payload.pending
    },
    checkPendingOutstandingOrdersForInvoicesFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isCheckingPendingOutstandingOrdersWhilePostingInvoices = false
      state.error = getErrorMessage(action.payload.error)
    },
    checkPendingActiveRx: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryPendingActiveRxForOutstandingFinalizationArgs>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = true
      state.error = null
      state.hasPendingOutstandingOrdersForChargeSheet.activeRx = false
    },
    checkPendingActiveRxSuccess: (state, action: PayloadAction<boolean>) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.hasPendingOutstandingOrdersForChargeSheet.activeRx = action.payload
    },
    checkPendingActiveRxFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.error = getErrorMessage(action.payload.error)
    },
    checkPendingImaging: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryPendingIdexxImagingForOutstandingFinalizationArgs>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = true
      state.error = null
      state.hasPendingOutstandingOrdersForChargeSheet.imaging = false
    },
    checkPendingImagingSuccess: (state, action: PayloadAction<boolean>) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.hasPendingOutstandingOrdersForChargeSheet.imaging = action.payload
    },
    checkPendingImagingFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.error = getErrorMessage(action.payload.error)
    },
    checkPendingLabTests: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryPendingLabTestsForOutstandingFinalizationArgs>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = true
      state.error = null
      state.hasPendingOutstandingOrdersForChargeSheet.labTests = false
    },
    checkPendingLabTestsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.hasPendingOutstandingOrdersForChargeSheet.labTests = action.payload
    },
    checkPendingLabTestsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isCheckingPendingOutstandingOrdersForChargeSheet = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchClientFinanceCharges: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string
        includeDeleted?: boolean
        soapId?: string | Nil
      }>,
    ) => {
      state.loading = true
      state.error = null
      state.chargesList = []
      state.chargesListAmount = 0
    },
    fetchClientFinanceDataSuccess: (
      state,
      action: PayloadAction<{
        chargeSheet: ChargeSheet
        charges?: ChargeSheet | InvoiceV3
        chargesList?: GroupedLineItems
        clientId: string
      }>,
    ) => {
      state.loading = false
      state.chargeSheetClientId = action.payload.clientId
      state.chargeSheet = action.payload.chargeSheet
      state.charges = action.payload.charges
      state.clientBalance = action.payload.chargeSheet.prevBalance || 0

      state.chargesList = action.payload.chargesList
        ? action.payload.chargesList?.items.map((item) =>
            R.has('groupName', item)
              ? convertChargeSheetGroupedLineItemToInvoiceLineItem(
                  item as GroupedItem,
                )
              : R.has('autoshipFrequency', item)
                ? (item as RetailOrderLineItem)
                : convertChargeSheetLineItemToInvoiceLineItem(
                    item as InvoiceLineItemGenerated,
                  ),
          )
        : []
      state.chargesListAmount = action.payload.chargesList?.totalAmount || 0
    },
    fetchClientFinanceDataFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    fetchBalanceChargeSheet: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string
        includeDeleted?: boolean
      }>,
    ) => {
      state.loading = true
    },
    fetchBalanceChargeSheetSuccess: (
      state,
      action: PayloadAction<BalanceChargeSheet>,
    ) => {
      state.loading = false
      state.balanceChargeSheet = action.payload
    },
    fetchBalanceChargeSheetFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    fetchOpenChargeSheet: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
      }>,
    ) => {
      state.loading = true
      state.error = null
    },
    fetchOpenChargeSheetSuccess: (
      state,
      action: PayloadAction<OpenChargeSheet>,
    ) => {
      state.loading = false
      state.openChargeSheet = action.payload
    },
    fetchOpenChargeSheetFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    updateChargeSheetItem: (
      state,
      action: PayloadAction<Record<string, ChargeSheetItem>>,
    ) => {
      state.chargeSheetItemMap = action.payload
    },
    updateChargeSheetSubItems: (
      state,
      action: PayloadAction<Record<string, ChargesSection>>,
    ) => {
      state.chargeSheetSubItemsMap = action.payload as unknown as Record<
        string,
        ChargeSheetItemSection
      >
      if (state.currentChargeSheetLineItem) {
        const flattenChargeSheetSubItems = R.pipe<
          (typeof action.payload)[],
          ChargesSection[],
          GroupedItems[],
          (InvoiceLineItemGenerated | RetailOrderLineItem)[]
        >(
          R.values,
          R.chain(({ groupedItems }) => groupedItems),
          R.chain(
            (groupedItem) =>
              R.prop('items', groupedItem) || [
                groupedItem as InvoiceLineItemGenerated | RetailOrderLineItem,
              ],
          ),
        )(action.payload)

        const currentChargeSheetLineItem = R.find(
          R.propEq('id', state.currentChargeSheetLineItem.id as string),
          flattenChargeSheetSubItems,
        )
        if (currentChargeSheetLineItem) {
          if (getIsRetailOrderLineItem(currentChargeSheetLineItem)) {
            state.currentChargeSheetLineItem = currentChargeSheetLineItem
          } else {
            state.currentChargeSheetLineItem =
              convertChargeSheetLineItemToInvoiceLineItem(
                currentChargeSheetLineItem,
              )
          }
        }
      }
    },
    updateBalanceChargeSheet: (
      state,
      action: PayloadAction<BalanceChargeSheet>,
    ) => {
      state.balanceChargeSheet = action.payload
    },
    fetchClientChargeSheetItemsCount: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: PayloadAction<{
        clientId: string
        includeDeleted?: boolean
      }>,
    ) => {
      state.error = null
      state.loading = true
    },
    fetchClientChargeSheetItemsCountSuccess: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.loading = false
      state.chargeSheetItemsCount = action.payload
    },
    fetchClientChargeSheetItemsCountFailure: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    printChargeSheet: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
        expandedGroups: GroupIdentifier[] | Nil
      }>,
    ) => {
      state.error = null
      state.loading = true
      state.chargeSheetPrintHtml = null
    },
    expandGroups: (
      state,
      action: PayloadAction<{
        groupId: GroupIdentifier
      }>,
    ) => {
      const expandedSet = state.expandedGroups
        ? state.expandedGroups.concat([])
        : []

      state.expandedGroups = R.find(
        R.equals(action.payload.groupId),
        expandedSet,
      )
        ? R.without([action.payload.groupId], expandedSet)
        : R.append(action.payload.groupId, expandedSet)
    },
    printChargeSheetSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.chargeSheetPrintHtml = action.payload
    },
    printChargeSheetFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    clearChargeSheetPrintHtml: (state) => {
      state.chargeSheetPrintHtml = null
    },
    addChargeSheetItems: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<AddChargeSheetItemsPayload>,
    ) => {
      state.error = null
      state.loading = true
    },
    addChargeSheetItemsSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
      }>,
    ) => {
      state.error = null
      state.loading = false
    },
    addChargeSheetItemsFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    editChargeSheetItem: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateChargeSheetItemBatchArgs>,
    ) => {
      state.loading = true
      state.error = null
    },
    editChargeSheetSectionPercentageDiscount: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateChargeSheetItemBatchArgs>,
    ) => {
      state.loading = true
      state.error = null
    },
    editChargeSheetItemSuccess: (
      state,
      action: PayloadAction<InvoiceLineItemGenerated[]>,
    ) => {
      const currentChargeSheetLineItem = R.find(
        ({ id }) =>
          R.equals(id, R.path(['currentChargeSheetLineItem', 'id'], state)),
        action.payload,
      )
      state.loading = false
      state.error = null
      state.currentChargeSheetLineItem = currentChargeSheetLineItem
        ? convertChargeSheetLineItemToInvoiceLineItem(
            currentChargeSheetLineItem,
          )
        : state.currentChargeSheetLineItem
    },
    editChargeSheetItemFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    editChargeSheetRetailOrderLineItemProducerBatch: (
      state,
      action: PayloadAction<RetailOrderLineItemProducerInput[]>,
    ) => {
      state.chargeSheetLineItemLoading = true
      state.loading = true
      state.currentChargeSheetLineItem = null
      state.editingLineItemState = R.path(
        ['payload', 'stateEntity', 'id'],
        action,
      )
      state.error = null
    },
    editChargeSheetRetailOrderLineItemProducerBatchSuccess: (
      state,
      action: PayloadAction<RetailOrderLineItem[]>,
    ) => {
      const currentChargeSheetLineItem = R.find(
        ({ id }) =>
          R.equals(id, R.path(['currentChargeSheetLineItem', 'id'], state)),
        action.payload,
      )
      state.chargeSheetLineItemLoading = false
      state.loading = false
      state.currentChargeSheetLineItem =
        currentChargeSheetLineItem || state.currentChargeSheetLineItem
    },
    editChargeSheetRetailOrderLineItemProducerBatchFailure: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.error = getErrorMessage(action.payload)
      state.chargeSheetLineItemLoading = false
      state.loading = false
    },
    editChargeSheetProducerItemBatch: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<EditChargeSheetItemProducerBatchPayload>,
    ) => {
      state.loading = true
      state.error = null
    },
    editChargeSheetProducerItemBatchSuccess: (state) => {
      state.loading = false
    },
    deleteChargeSheetItemsSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
      }>,
    ) => {
      state.loading = false
      state.error = null
      state.itemErrors = []
    },
    deleteChargeSheetItemsFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    deleteChargeSheetItems: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeleteChargeSheetItemPayload>,
    ) => {
      state.loading = true
      state.error = null
    },
    fetchChargeSheetOrderFilters: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
        patientId: string
      }>,
    ) => {
      state.orderFiltersLoading = true
      state.orderFiltersError = null
    },
    fetchChargeSheetOrderFiltersSuccess: (
      state,
      action: PayloadAction<OrderFilter[]>,
    ) => {
      state.orderFilters = action.payload
      state.orderFiltersLoading = false
    },
    fetchChargeSheetOrderFiltersFailure: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.error = getErrorMessage(action.payload)
      state.orderFiltersLoading = false
    },
    fetchChargeSheetLineItem: (
      state,
      action: PayloadAction<{
        id: string
        includeDeleted?: boolean
      }>,
    ) => {
      state.chargeSheetLineItemLoading = true
      state.currentChargeSheetLineItem = null
      state.editingLineItemState = R.path(
        ['payload', 'stateEntity', 'id'],
        action,
      )
      state.error = null
    },
    fetchChargeSheetLineItemByLogId: (
      state,
      action: PayloadAction<{
        includeDeleted?: boolean
        logId: string
        logType: string
      }>,
    ) => {
      state.chargeSheetLineItemLoading = true
      state.currentChargeSheetLineItem = null
      state.editingLineItemState = R.path(
        ['payload', 'stateEntity', 'id'],
        action,
      )
      state.error = null
    },
    fetchChargeSheetLineItemSuccess: (
      state,
      action: PayloadAction<InvoiceLineItemGenerated>,
    ) => {
      state.chargeSheetLineItemLoading = false
      state.currentChargeSheetLineItem =
        convertChargeSheetLineItemToInvoiceLineItem(action.payload)
    },
    fetchChargeSheetLineItemFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload)
      state.chargeSheetLineItemLoading = false
    },
    fetchChargeSheetRetailOrderLineItem: (
      state,
      action: PayloadAction<{
        id: string
      }>,
    ) => {
      state.chargeSheetLineItemLoading = true
      state.currentChargeSheetLineItem = null
      state.editingLineItemState = R.path(
        ['payload', 'stateEntity', 'id'],
        action,
      )
      state.error = null
    },
    fetchChargeSheetRetailOrderLineItemSuccess: (
      state,
      action: PayloadAction<RetailOrderLineItem>,
    ) => {
      state.chargeSheetLineItemLoading = false
      state.currentChargeSheetLineItem = action.payload
    },
    fetchChargeSheetRetailOrderLineItemFailure: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.error = getErrorMessage(action.payload)
      state.chargeSheetLineItemLoading = false
    },
    editChargeSheetOrder: (
      state,
      action: PayloadAction<{
        id: string
        options?: {
          declined?: boolean
          isChewyActiveRx?: boolean
          logModificationDate: string
        }
        order: any
        soapLogModificationDate: string
        type: string
      }>,
    ) => {
      state.orderStatusOrNotesLoading =
        action.payload.order.stateId !==
        (state.currentChargeSheetLineItem as InvoiceLineItem)?.stateEntity?.id
      state.error = null
      const orderStateId = R.path(['payload', 'order', 'stateId'], action)
      if (orderStateId) {
        state.editingLineItemState = orderStateId
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editChargeSheetOrderSuccess: (state, action: PayloadAction<Order[]>) => {
      state.orderStatusOrNotesLoading = false
    },
    editChargeSheetOrderFailure: (state, action: PayloadAction<any>) => {
      state.error = getErrorMessage(action.payload)
      state.orderStatusOrNotesLoading = false
    },
    updateSectionAdditionalDiscount: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateSectionsAdditionalDiscountArgs>,
    ) => {
      state.loading = true
      state.error = null
    },
    updateSectionAdditionalDiscountSuccess: (state) => {
      state.loading = false
    },
    updateSectionAdditionalDiscountFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.error = getErrorMessage(action.payload.error)
      state.loading = false
    },
    resetChargeSheet: () => CLIENT_FINANCE_DATA_INITIAL_STATE,
    updateClientFinanceCharges: (state) => {
      state.loading = true
    },
    refetchChargeSheet: (state) => {
      state.loading = true
    },
    resetEditingLineItemState: (state) => {
      state.editingLineItemState = undefined
    },
    updateErrors: (state, action: PayloadAction<any>) => {
      state.itemErrors = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action: AnyAction) => action.type === GENERATE_PDF_FOR_CHARGE_SHEET,
        (state) => {
          state.isGeneratingChargeSheetPdf = true
        },
      )
      .addMatcher(
        (action: AnyAction) =>
          action.type === GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS,
        (state) => {
          state.isGeneratingChargeSheetPdf = false
        },
      )
      .addMatcher(
        (action: AnyAction) => action.type === EMAIL_CHARGE_SHEET,
        (state) => {
          state.loading = true
        },
      )
      .addMatcher(
        (action: AnyAction) => action.type === EMAIL_CHARGE_SHEET_SUCCESS,
        (state) => {
          state.loading = false
        },
      )
      .addMatcher(
        (action: AnyAction) =>
          action.type === GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE,
        (state, action) => {
          state.isGeneratingChargeSheetPdf = false
          state.error = action.error
        },
      )
      .addMatcher(
        (action: AnyAction) => action.type === EMAIL_CHARGE_SHEET_FAILURE,
        (state, action) => {
          state.loading = false
          state.error = action.error
        },
      )
  },
})

const { actions, reducer } = clientFinanceDataSlice

export const {
  checkPendingActiveRx,
  checkPendingActiveRxFailure,
  checkPendingActiveRxSuccess,
  checkPendingImaging,
  checkPendingImagingFailure,
  checkPendingImagingSuccess,
  checkPendingLabTests,
  checkPendingLabTestsFailure,
  checkPendingLabTestsSuccess,
  checkPendingOutstandingOrdersForInvoices,
  checkPendingOutstandingOrdersForInvoicesFailure,
  checkPendingOutstandingOrdersForInvoicesSuccess,
  fetchClientFinanceCharges,
  fetchClientFinanceDataSuccess,
  fetchClientFinanceDataFailure,
  updateChargeSheetSubItems,
  updateBalanceChargeSheet,
  fetchClientChargeSheetItemsCount,
  fetchClientChargeSheetItemsCountSuccess,
  fetchClientChargeSheetItemsCountFailure,
  printChargeSheet,
  printChargeSheetSuccess,
  printChargeSheetFailure,
  clearChargeSheetPrintHtml,
  addChargeSheetItems,
  addChargeSheetItemsFailure,
  addChargeSheetItemsSuccess,
  fetchBalanceChargeSheet,
  fetchBalanceChargeSheetSuccess,
  fetchBalanceChargeSheetFailure,
  fetchOpenChargeSheet,
  fetchOpenChargeSheetSuccess,
  fetchOpenChargeSheetFailure,
  editChargeSheetItem,
  editChargeSheetSectionPercentageDiscount,
  editChargeSheetRetailOrderLineItemProducerBatch,
  editChargeSheetRetailOrderLineItemProducerBatchSuccess,
  editChargeSheetRetailOrderLineItemProducerBatchFailure,
  editChargeSheetProducerItemBatch,
  editChargeSheetItemSuccess,
  editChargeSheetItemFailure,
  deleteChargeSheetItems,
  deleteChargeSheetItemsSuccess,
  deleteChargeSheetItemsFailure,
  expandGroups,
  fetchChargeSheetOrderFilters,
  fetchChargeSheetOrderFiltersSuccess,
  fetchChargeSheetOrderFiltersFailure,
  fetchChargeSheetLineItem,
  fetchChargeSheetLineItemByLogId,
  fetchChargeSheetLineItemSuccess,
  fetchChargeSheetLineItemFailure,
  fetchChargeSheetRetailOrderLineItem,
  fetchChargeSheetRetailOrderLineItemSuccess,
  fetchChargeSheetRetailOrderLineItemFailure,
  editChargeSheetOrder,
  editChargeSheetOrderSuccess,
  editChargeSheetOrderFailure,
  updateSectionAdditionalDiscount,
  updateSectionAdditionalDiscountSuccess,
  updateSectionAdditionalDiscountFailure,
  updateErrors,
  resetChargeSheet,
  updateClientFinanceCharges,
  editChargeSheetProducerItemBatchSuccess,
  refetchChargeSheet,
  resetEditingLineItemState,
} = actions

export default reducer

export const getClientFinanceData = (
  state: RootState,
): ClientFinanceDataState => state.clientFinanceData
export const getChargeSheetSubItemsMap = (state: RootState) =>
  getClientFinanceData(state).chargeSheetSubItemsMap
export const getMultipleChargeSheetSubItems = (ids: string[]) =>
  createSelector(getChargeSheetSubItemsMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )

export const getChargeSheetSubItemById = (id: string | Nil) =>
  createSelector(getChargeSheetSubItemsMap, (map) => (id ? map[id] : null))
export const getChargeSheetSubItemsBySoapId = (soapId: string | Nil) =>
  createSelector(getChargeSheetSubItemsMap, (chargeSheetSubItemsMap) =>
    chargeSheetSubItemsMap
      ? R.values(chargeSheetSubItemsMap).filter(
          (section) => section.soapId === soapId,
        )
      : [],
  )

export const useGetChargeSheetItems = (): Record<string, InvoiceLineItem[]> => {
  const subItemsMap = useSelector(getChargeSheetSubItemsMap)
  const subItemsIds: string[] = Object.keys(subItemsMap) || []

  return R.pipe<
    (typeof subItemsIds)[],
    [string, InvoiceLineItem[]][],
    Record<string, InvoiceLineItem[]>,
    Record<string, InvoiceLineItem[]>
  >(
    R.map((id: string) => [
      id,
      R.prop('groupedItems', subItemsMap[id])! as InvoiceLineItem[],
    ]),
    R.fromPairs,
    R.filter(Boolean),
  )(subItemsIds)
}

export const getChargeSheetSubItemBySoapPatient = (
  patientId?: string,
  soapId?: string,
  chargeSheetSubItemId?: string,
) =>
  createSelector(getChargeSheetSubItemsMap, (chargeSheetItemMaps) =>
    chargeSheetSubItemId
      ? chargeSheetItemMaps[chargeSheetSubItemId]
      : (soapId || patientId) && chargeSheetItemMaps
        ? Object.values(chargeSheetItemMaps).find(
            (chargeSheetItemSection: ChargeSheetItemSection) =>
              (R.isNil(soapId) || chargeSheetItemSection.soapId === soapId) &&
              chargeSheetItemSection.patientId === patientId,
          )
        : null,
  )

export const getClientFinanceClientId = (state: RootState) =>
  getClientFinanceData(state).chargeSheetClientId

export const getChargeSheet = (state: RootState) =>
  getClientFinanceData(state).chargeSheet
export const getClientFinanceLoading = (state: RootState) =>
  getClientFinanceData(state).loading
export const getClientFinanceError = (state: RootState) =>
  getClientFinanceData(state).error
export const getBalanceChargeSheet = (state: RootState) =>
  getClientFinanceData(state).balanceChargeSheet
export const getChargeSheetItemsCount = (state: RootState) =>
  getClientFinanceData(state).chargeSheetItemsCount
export const getIsGeneratingChargeSheetPdf = (state: RootState) =>
  getClientFinanceData(state).isGeneratingChargeSheetPdf
export const getChargeSheetPrintHtml = (state: RootState) =>
  getClientFinanceData(state).chargeSheetPrintHtml
export const getExpandedGroups = (state: RootState) =>
  getClientFinanceData(state).expandedGroups
export const getGroupIdIsExpanded = (id: GroupIdentifier | Nil) =>
  createSelector(
    getExpandedGroups,
    (ids: ReturnType<typeof getExpandedGroups>) =>
      id ? (ids ? R.includes(id, ids) : false) : false,
  )
export const getChargeSheetOrderFilters = (state: RootState) =>
  getClientFinanceData(state).orderFilters
export const getChargeSheetOrderFiltersLoading = (state: RootState) =>
  getClientFinanceData(state).orderFiltersLoading
export const getChargeSheetOrderFiltersError = (state: RootState) =>
  getClientFinanceData(state).error
export const getCurrentChargeSheetLineItem = (state: RootState) =>
  getClientFinanceData(state).currentChargeSheetLineItem
export const getChargeSheetLineItemLoading = (state: RootState) =>
  getClientFinanceData(state).chargeSheetLineItemLoading
export const getChargeSheetClientBalance = (state: RootState) =>
  getClientFinanceData(state).clientBalance
export const getOpenChargeSheet = (state: RootState) =>
  getClientFinanceData(state).openChargeSheet
export const getOrderStatusOrNotesLoading = (state: RootState) =>
  getClientFinanceData(state).orderStatusOrNotesLoading

export const getChargesList = (state: RootState) =>
  getClientFinanceData(state).chargesList
export const getChargesListTotalAmount = (state: RootState) =>
  getClientFinanceData(state).chargesListAmount

export const useIsAnyPrepaid = (): boolean => {
  const chargeSheetItems = useGetChargeSheetItems()
  const invoiceLineItems = Object.values(chargeSheetItems).flatMap(
    (ilis) => ilis,
  )
  return isAnyItemPrepaid(invoiceLineItems)
}

export const getHasPendingOutstandingOrdersWhilePostingInvoices = (
  state: RootState,
) => getClientFinanceData(state).hasPendingOutstandingOrdersWhilePostingInvoices
export const getIsCheckingPendingOutstandingOrdersWhilePostingInvoices = (
  state: RootState,
) =>
  getClientFinanceData(state)
    .isCheckingPendingOutstandingOrdersWhilePostingInvoices

export const getIsCheckingPendingOutstandingOrdersForChargeSheet = (
  state: RootState,
) =>
  getClientFinanceData(state).isCheckingPendingOutstandingOrdersForChargeSheet
export const getHasPendingOutstandingOrdersForChargeSheet = (
  state: RootState,
) => getClientFinanceData(state).hasPendingOutstandingOrdersForChargeSheet
export const getHasPendingActiveRxForChargeSheet = createSelector(
  getHasPendingOutstandingOrdersForChargeSheet,
  (pending) => pending.activeRx,
)
export const getHasPendingImagingForChargeSheet = createSelector(
  getHasPendingOutstandingOrdersForChargeSheet,
  (pending) => pending.imaging,
)
export const getHasPendingLabTestsForChargeSheet = createSelector(
  getHasPendingOutstandingOrdersForChargeSheet,
  (pending) => pending.labTests,
)
export const getEditingLineItemState = (state: RootState) =>
  getClientFinanceData(state).editingLineItemState

export const getItemErrors = (state: RootState) =>
  getClientFinanceData(state).itemErrors
