import { Task } from '@redux-saga/types'
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  TimelineEntryType,
  TimelineEntryTypesList,
} from '~/constants/timelineConstants'

import {
  deleteLabTestAttachmentFile,
  deleteLabTestAttachmentFileFailure,
  deleteLabTestAttachmentFileSuccess,
  fetchMoreItemsForTimelineFailure,
  fetchMoreItemsForTimelineSuccess,
  fetchTimeline,
  fetchTimelineFailure,
  fetchTimelineSuccess,
  optimisticAttachmentDeleteFromTimeline,
  optimisticLabTestFileDeleteFromLabTestCard,
} from '../actions/timeline'
import {
  DELETE_LAB_TEST_ATTACHMENT_FILE,
  FETCH_MORE_ITEMS_FOR_TIMELINE,
  FETCH_TIMELINE,
  RESET_TIMELINE,
  UPDATE_TIMELINE_CLIENT_DATA,
  UPDATE_TIMELINE_DATE_RANGE,
  UPDATE_TIMELINE_FILTERS,
} from '../actions/types/timeline'
import {
  getTimelineClientId,
  getTimelineDateRange,
  getTimelineFilters,
  getTimelinePatientId,
} from '../reducers/timeline'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const getFiltersString = (
  filters: string[],
  typesList: TimelineEntryType[],
) => {
  const replacedFilters = filters.length > 0 ? filters : typesList
  return replacedFilters.join(',')
}

export function* fetchTimelineCancellable({
  from,
  to,
  type,
}: ReturnType<typeof fetchTimeline>) {
  try {
    const timelineClientId: string = yield select(getTimelineClientId)
    const timelinePatientId: string = yield select(getTimelinePatientId)

    const missingTimelineFlow = !timelineClientId || !timelinePatientId
    if (missingTimelineFlow) {
      return
    }

    const timelineFilters: TimelineEntryType[] =
      yield select(getTimelineFilters)
    const [startDate, endDate] = yield select(getTimelineDateRange)

    const cardTypes = getFiltersString(
      timelineFilters,
      TimelineEntryTypesList,
    ).replace('REMINDER', 'REMINDER_V2')

    const {
      entities,
      result: { data: list, totalCount },
    } = yield* requestAPI(
      API.fetchTimeline,
      timelineClientId,
      timelinePatientId,
      cardTypes,
      startDate,
      endDate,
      from,
      to,
    )

    yield call(updateEntities, entities)

    if (type === FETCH_MORE_ITEMS_FOR_TIMELINE) {
      yield put(
        fetchMoreItemsForTimelineSuccess(list, totalCount, from as number),
      )
    } else {
      yield put(fetchTimelineSuccess(list, totalCount))
    }
  } catch (e) {
    const error = e as ApiError
    if (type === FETCH_MORE_ITEMS_FOR_TIMELINE) {
      yield put(fetchMoreItemsForTimelineFailure(error))
    } else {
      yield put(fetchTimelineFailure(error))
    }
  }
}

export function* fetchTimelineSaga(params: ReturnType<typeof fetchTimeline>) {
  const task: Task = yield fork(fetchTimelineCancellable, params)
  yield take(RESET_TIMELINE)
  yield cancel(task)
}

export function* deleteLabTestAttachmentFileSaga({
  labTestLogId,
  groupId,
  fileId,
}: ReturnType<typeof deleteLabTestAttachmentFile>) {
  try {
    yield put(optimisticAttachmentDeleteFromTimeline(fileId))
    yield put(optimisticLabTestFileDeleteFromLabTestCard(fileId))
    yield* requestAPI(API.deleteLabTestFile, labTestLogId, groupId, fileId)
    yield put(fetchTimeline())
    yield put(deleteLabTestAttachmentFileSuccess())
  } catch (error) {
    yield put(deleteLabTestAttachmentFileFailure(error as ApiError))
  }
}

function* watchFetchTimeline() {
  yield takeLatest(
    [
      FETCH_TIMELINE,
      UPDATE_TIMELINE_CLIENT_DATA,
      UPDATE_TIMELINE_FILTERS,
      UPDATE_TIMELINE_DATE_RANGE,
      FETCH_MORE_ITEMS_FOR_TIMELINE,
    ],
    fetchTimelineSaga,
  )
}

function* watchDeleteLabTestAttachmentFile() {
  yield takeLeading(
    DELETE_LAB_TEST_ATTACHMENT_FILE,
    deleteLabTestAttachmentFileSaga,
  )
}

export default function* timelineSaga() {
  yield all([watchFetchTimeline(), watchDeleteLabTestAttachmentFile()])
}
