import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { fetchWellnessPlanSavings } from '~/store/actions/wellnessPlans'
import { getUser } from '~/store/reducers/users'
import { getWellnessPlanSavingsForClient } from '~/store/reducers/wellnessPlans'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.planBranding,
      borderRadius: 2,
      boxShadow: '3px 3px 20px 0 rgba(168,163,163,0.5)',
    },
    text: {
      color: theme.colors.profileText,
    },
  }),
  { name: 'ClientMembershipSavingsBanner' },
)

export interface ClientMembershipSavingsBannerProps extends GridProps {
  clientId: string
}

const ClientMembershipSavingsBanner = ({
  className,
  clientId,
  ...rest
}: ClientMembershipSavingsBannerProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const client = useSelector(getUser(clientId))
  const savings = useSelector(getWellnessPlanSavingsForClient(clientId))
  const { t } = useTranslation('Memberships')

  const { earnedPoints, savedAmount } = savings || {}

  const shouldRender = client && (savedAmount || earnedPoints)

  useEffect(() => {
    if (clientId && !savings) {
      dispatch(fetchWellnessPlanSavings(clientId))
    }
  }, [clientId])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      px={1.5}
      py={1}
      {...rest}
    >
      <Text strong className={classes.text} ml={1} variant="body">
        {t(
          'Memberships:CLIENT_MEMBERSHIP_SAVINGS_BANNER.CLIENT_YEAR_TO_DATE_SAVINGS',
          {
            clientFirstName: client.firstName,
          },
        )}
        {savedAmount &&
          ` ${t('Memberships:CLIENT_MEMBERSHIP_SAVINGS_BANNER.SAVED_AMOUNT', {
            savedAmount: NumberUtils.formatMoney(savedAmount),
          })}`}
        {savedAmount && earnedPoints && ` ${t('Common:AND').toLowerCase()} `}
        {earnedPoints &&
          t('Memberships:EARNED_LOYALTY_POINTS', {
            earnedPoints,
          })}
      </Text>
    </Grid>
  )
}

export default ClientMembershipSavingsBanner
