import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { ThermometerIcon } from '~/components/elements/Icons/generated'
import { InventoryItem } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    flagIcon: {
      color: theme.colors.important,
      width: 32,
      height: 32,
    },
  }),
  { name: 'InventoryTableStoreColdIcon' },
)

const InventoryTableStoreColdIcon = (inventory: InventoryItem) => {
  const classes = useStyles()

  if (!inventory.storeCold) {
    return null
  }

  return <ThermometerIcon className={classes.flagIcon} />
}

export default InventoryTableStoreColdIcon
