import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { LanguageUtils, NamedEntity, Text } from '@pbt/pbt-ui-components'

import { getUserName } from '~/store/reducers/users'
import { Order, Task } from '~/types'
import { getUniqueOrderId, isOrder } from '~/utils/orderUtils'

import TaskMesh from '../../tasks-dashboard/mesh/TaskMesh'
import ToDoTasksListItemRow from './ToDoTasksListItemRow'

const useStyles = makeStyles(
  (theme) => ({
    groupNameContainer: {
      borderRadius: '1px 1px 0 0',
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    boopIcon: {
      width: '0.75em',
      height: '0.75em',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ToDoTasksListItem' },
)

const isPetParentTasksGroup = (group: ToDoTaskGroup) =>
  R.any<Task>(({ forClient }) => Boolean(forClient), group.items as Task[])

export type ToDoTaskGroup = NamedEntity & {
  items: (Task | Order)[]
}

interface ToDoTasksListItemProps {
  checkedOrderIds: string[]
  checkedTaskIds: string[]
  disabled?: boolean
  group: ToDoTaskGroup
  hasRecurringTasks?: boolean
  headerRef: React.RefObject<HTMLDivElement>
  onCheckOrder: (order: Order) => void
  onCheckTask: (task: Task) => void
  rowRef: React.RefObject<HTMLDivElement>[]
  scrollOffset: number
  showHeader?: boolean
  timeColumns: string[]
}

const ToDoTasksListItem = ({
  checkedOrderIds,
  checkedTaskIds,
  scrollOffset,
  showHeader,
  disabled,
  group,
  hasRecurringTasks,
  timeColumns,
  headerRef,
  rowRef,
  onCheckOrder,
  onCheckTask,
}: ToDoTasksListItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const userName = useSelector(getUserName(group.id))

  const displayName = LanguageUtils.getTranslatedFieldName(group) || userName
  const isPetParent = isPetParentTasksGroup(group)

  return (
    <Grid container item direction="column">
      {showHeader && (
        <Grid container item className={classes.groupNameContainer}>
          <Grid container item xs alignItems="center" px={1.5} py={0.5}>
            <Text strong variant="subheading3">
              {`${displayName}${
                isPetParent ? ` (${t('Common:PET_PARENT').toLowerCase()})` : ''
              }`}
            </Text>
          </Grid>
          {hasRecurringTasks && (
            <TaskMesh
              columns={timeColumns}
              ref={headerRef}
              scrollOffset={scrollOffset}
            />
          )}
        </Grid>
      )}
      {group.items.map((item, itemIndex) => {
        const isOrderItem = isOrder(item)
        const itemId = isOrderItem ? getUniqueOrderId(item as Order) : item.id
        return (
          <ToDoTasksListItemRow
            checkedOrderIds={checkedOrderIds}
            checkedTaskIds={checkedTaskIds}
            disabled={disabled}
            hasRecurringTasks={hasRecurringTasks}
            isOrderItem={isOrderItem}
            item={item}
            itemId={itemId}
            itemIndex={itemIndex}
            key={itemId}
            rowRef={rowRef}
            scrollOffset={scrollOffset}
            timeColumns={timeColumns}
            onCheckOrder={onCheckOrder}
            onCheckTask={onCheckTask}
          />
        )
      })}
    </Grid>
  )
}

export default ToDoTasksListItem
