import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiTooltip } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'

const alertColorGetter = (props: UseStylesProps) => props.alertColor

const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      color: alertColorGetter,
      borderColor: alertColorGetter,
      '&::before': {
        borderTopColor: alertColorGetter,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: theme.spacing(1),
      },
    },
    alertIconContainer: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      backgroundColor: alertColorGetter,
    },
    alertIconContainerSmall: {
      width: 16,
      height: 16,
    },
    alertIconContainerPlain: {
      width: 20,
      height: 20,
    },
    alertIcon: {
      fontSize: '1.2rem',
      color: theme.colors.tableBackground,
    },
    alertIconPlain: {
      fontSize: '2.0rem',
      color: alertColorGetter,
    },
    alertIconSmall: {
      fontSize: '1rem',
    },
  }),
  { name: 'AlertIcon' },
)

export interface AlertIconProps {
  alertColorId?: string | Nil
  alertColorLevel?: AlertColorLevel
  alertPlacement?: 'top' | 'top-end'
  alertText?: string
  plain?: boolean
  size?: 'small' | 'medium'
}

interface UseStylesProps {
  alertColor?: string
}

const AlertIcon = ({
  size = 'medium',
  alertText,
  alertPlacement,
  alertColorId,
  alertColorLevel,
  plain = false,
}: AlertIconProps) => {
  const alertColor = useGetAlertColor(alertColorId, alertColorLevel)
  const useStylesProps: UseStylesProps = {
    alertColor,
  }
  const classes = useStyles(useStylesProps)

  if (!alertText) {
    return null
  }

  const isSmall = size === 'small'
  const containerClass = plain
    ? classes.alertIconContainerPlain
    : classes.alertIconContainer

  const iconClass = plain ? classes.alertIconPlain : classes.alertIcon

  return (
    <PuiTooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      tooltipPlacement={alertPlacement}
      tooltipText={alertText}
    >
      <Grid
        container
        alignItems="center"
        className={classNames(containerClass, {
          [classes.alertIconContainerSmall]: isSmall,
        })}
        justifyContent="center"
      >
        <WarnAlert
          className={classNames(iconClass, {
            [classes.alertIconSmall]: isSmall,
          })}
        />
      </Grid>
    </PuiTooltip>
  )
}

export default AlertIcon
