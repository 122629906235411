import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  NumberUtils,
  PermissionArea,
  Text,
  UserPermissions,
  Utils,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { EstimatesTakeDepositsButtonMenu } from '~/components/dashboard/invoices/EstimatesTakeDepositsButtonMenu'
import EstimateStateLabel from '~/components/dashboard/invoices/EstimateStateLabel'
import useEstimateAlertDialog from '~/components/dashboard/soapV2/estimates/utils/useEstimateAlertDialog'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  useDisplayRecordDeposit,
  useGetEstimateState,
} from '~/store/hooks/estimate'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getCRUDByArea,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getFinanceInvoice,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { EstimateTimelineItem } from '~/types'
import useDialog from '~/utils/useDialog'

import TimelineCard from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme) => ({
    warningIcon: {
      color: theme.colors.important,
      fontSize: '1.6rem',
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'EstimateCard' },
)

export interface EstimateCardProps {
  clientId: string
  item: EstimateTimelineItem
  patientId: string
}

const EstimateCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: EstimateCardProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const addDepositRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation([
    'Common',
    'Clients',
    'Soap',
    'Invoices',
    'Tooltips',
  ])
  const isConsolidateRecordDeposit = useSelector(
    getFeatureToggle(FeatureToggle.CONSOLIDATE_RECORD_DEPOSIT),
  )
  const isPreventEmptyDepositEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_PREVENT_EMPTY_DEPOSIT),
  )

  const estimate = useSelector(getFinanceInvoice(item?.id))
  const isBusinessOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)
  const estimateLoading = useSelector(getFinanceIsLoading)
  const invoicePermissions: UserPermissions = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const appointmentPermission: UserPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const { isDraft: isStatusDraft } = useGetEstimateState()(item?.stateId)

  const { setAttachingToSoapEstimateId, setSoapToAttachEstimateId } =
    useEstimateAlertDialog({
      fromSoap: false,
      fromTimeline: true,
      timelineEstimateId: item.id,
    })

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const [openAppointmentListDialog] = useDialog(
    DialogNames.APPOINTMENTS_LIST_FOR_ESTIMATE,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openDepositDialog] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openPaymentRequest] = useDialog(DialogNames.PAYMENT_REQUEST)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const {
    amountNoFee,
    deposits = [],
    invoiceNo,
    dueToPay,
    dueToPayNoFee,
    paidDeposit,
    paidDepositAmount,
    paidDepositAmountNoFee,
    id,
    requiredDeposit,
    state,
  } = item || {}

  const disableRecordForEmptyDeposit =
    isPreventEmptyDepositEnabled &&
    isBusinessOmniChannel &&
    requiredDeposit === 0

  const { displayRecordDeposit, hasPermission } = useDisplayRecordDeposit(item)
  const hasPayments = deposits && deposits.length > 0
  const hasDue = dueToPay > 0 || dueToPayNoFee > 0
  const feeAmount = paidDepositAmount - paidDepositAmountNoFee

  const hasPaidFee = feeAmount > 0
  const serviceFeeDescription = ` (${t('Invoices:INCLUDING_SERVICE_FEE', {
    amount: NumberUtils.formatMoney(feeAmount),
  })})`

  const onChangeAttachingToSoapEstimateId = useCallback(
    setAttachingToSoapEstimateId,
    [],
  )
  const onChangeSoapToAttachEstimateId = useCallback(
    setSoapToAttachEstimateId,
    [],
  )
  const onAddAppointmentClick = useCallback(() => {
    openAppointmentListDialog({
      clientId,
      estimateId: id,
      patientId,
      autoCopyItems: true,
      onChangeAttachingToSoapEstimateId,
      onChangeSoapToAttachEstimateId,
    })
  }, [
    clientId,
    id,
    patientId,
    onChangeSoapToAttachEstimateId,
    onChangeAttachingToSoapEstimateId,
  ])

  const onViewEstimateClick = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId: id,
      isEstimate: true,
      fromTimeline: true,
      newEstimateFlow: true,
      setAttachingToSoapEstimateId: onChangeAttachingToSoapEstimateId,
      setSoapToAttachEstimateId: onChangeSoapToAttachEstimateId,
    })
  }

  const onViewDepositClick = useCallback(() => {
    if (deposits.length === 1) {
      openDepositDialog({
        clientId,
        payment: deposits[0],
      })
    } else {
      navigate(`/balance/${clientId}`, { replace: true })
    }
  }, [clientId, deposits])

  const onAddPaymentClick = useCallback(() => {
    openAddPaymentDialog({
      ComponentProps: {
        assignedInvoiceId: id,
        paymentTypeName: 'Deposit',
        invoiceAmount: dueToPayNoFee,
      },
      clientId,
    })
  }, [clientId, dueToPayNoFee])

  return (
    <TimelineCard
      title={LanguageUtils.getTranslatedFieldName(item)}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        <Text strong variant="body">
          {`${invoiceNo}: ${Utils.getPersonString(client)} | ${patient?.name}`}
        </Text>
        <Text strong variant="body">
          {`${t('Common:TOTAL')}: ${NumberUtils.formatMoney(amountNoFee)}`}
        </Text>
        <Grid container item alignItems="center">
          {hasDue && <WarningIcon className={classes.warningIcon} />}
          <Text variant="body">
            {hasDue
              ? t('Clients:TIMELINE.ESTIMATE_CARD.DEPOSIT_DUE')
              : t('Clients:TIMELINE.ESTIMATE_CARD.DEPOSIT_PAID')}
            {`(${NumberUtils.toPercentFormat(
              hasDue ? requiredDeposit - paidDeposit : paidDeposit,
            )}):
            ${NumberUtils.formatMoney(
              hasDue ? dueToPayNoFee : paidDepositAmount,
            )} `}
            {`${hasPaidFee ? serviceFeeDescription : ''}`}
          </Text>
        </Grid>
      </TimelineCardContent>
      <TimelineCardActions
        customStateControl={<EstimateStateLabel estimate={item} />}
        state={state}
      >
        {appointmentPermission.read && invoicePermissions.update && (
          <Button onClick={onAddAppointmentClick}>
            {t('Common:ADD_TO_APPOINTMENT')}
          </Button>
        )}
        {hasPayments && !isStatusDraft && (
          <Button onClick={onViewDepositClick}>
            {t('Common:VIEW_DEPOSIT')}
          </Button>
        )}
        {displayRecordDeposit &&
          (isConsolidateRecordDeposit && isBusinessOmniChannel ? (
            <EstimatesTakeDepositsButtonMenu
              disableTooltipHover={Boolean(id) && !disableRecordForEmptyDeposit}
              disabled={
                !hasPermission ||
                !id ||
                estimateLoading ||
                disableRecordForEmptyDeposit
              }
              tooltipText={
                disableRecordForEmptyDeposit
                  ? t('Tooltips:NO_DEPOSIT_REQUIRED')
                  : t('Tooltips:SAVE_ESTIMATE_FIRST')
              }
              withLoader={false}
              onRecordDeposit={onAddPaymentClick}
              onSendDepositLink={() =>
                openPaymentRequest({
                  depositPayment: true,
                  invoice: estimate,
                })
              }
            />
          ) : (
            <Button
              disabled={!hasPermission}
              ref={addDepositRef}
              onClick={onAddPaymentClick}
            >
              {t('Common:RECORD_DEPOSIT_ACTION')}
            </Button>
          ))}
        {invoicePermissions.read && (
          <Button onClick={onViewEstimateClick}>
            {t('Common:VIEW_ESTIMATE_BUDGET')}
          </Button>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default EstimateCard
