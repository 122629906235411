import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Switch,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  Field,
  LanguageUtils,
  NamedEntity,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

const useMenuHeadingStyles = makeStyles(
  (theme) => ({
    selectorContainer: {
      borderBottom: theme.constants.tabBorder,
    },
    switchLabel: {
      fontWeight: 500,
    },
  }),
  { name: 'MenuHeading' },
)

const useStyles = makeStyles(
  (theme) => ({
    selectRoot: {
      '& .MuiInputBase-input': {
        whiteSpace: 'pre-wrap',
      },
    },
    toggleIcon: {
      color: theme.colors.toggleIcon,
      pointerEvents: 'none',
    },
    dropdownMenu: {
      maxHeight: 360,
      minWidth: '0 !important',
    },
    listItem: {
      height: 32,
      paddingLeft: 0,
    },
  }),
  { name: 'PuiSelectAll' },
)

export interface MenuHeadingProps {
  isSelected: boolean
  onChange: () => void
}

export const MenuHeading = forwardRef<HTMLDivElement, MenuHeadingProps>(
  function MenuHeading({ isSelected, onChange }, ref) {
    const classes = useMenuHeadingStyles()
    const { t } = useTranslation('Common')

    return (
      <Grid item className={classes.selectorContainer} px={1.5} ref={ref}>
        <FormControlLabel
          classes={{
            label: classes.switchLabel,
          }}
          control={
            <Switch checked={isSelected} color="primary" onChange={onChange} />
          }
          label={t<string>('Common:SELECT_ALL')}
        />
      </Grid>
    )
  },
)

export interface PuiSelectAllProps<T = NamedEntity>
  extends Omit<SelectProps<T[]>, 'classes' | 'onChange'> {
  ItemComponent?: React.JSXElementConstructor<any>
  allSelectedText?: string
  classes?: ClassesType<typeof useStyles>
  field: Field
  hideLabelWhenEmpty?: boolean
  items?: T[]
  noneSelectedText?: string
}

// eslint-disable-next-line react/no-multi-comp
const PuiSelectAll = forwardRef(function PuiSelectAll(
  {
    items = [],
    ItemComponent,
    label,
    noneSelectedText,
    allSelectedText,
    disabled,
    field,
    classes: classesProp,
    hideLabelWhenEmpty = false,
    ...props
  }: PuiSelectAllProps,
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const puiSelectAllNoneSelectedText = noneSelectedText || t('Common:ALL')
  const puiSelectAllSelectedText = allSelectedText || t('Common:ALL')

  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState<NamedEntity[]>([])

  useEffect(() => {
    setSelectedItems(field.value)
  }, [field.value])

  useEffect(() => {
    if (field.value) {
      const selectAllState =
        items.length > 0 && field.value.length === items.length
      setSelectAll(selectAllState)
    }
  }, [field.value, items])

  const handleChange = (event: SelectChangeEvent<NamedEntity[]>) => {
    const newValue = event.target.value as NamedEntity[]
    field.set(newValue)
    setSelectedItems(newValue)
  }

  const handleSelectAll = () => {
    const newSelectedItems = selectAll ? [] : items

    field.set(newSelectedItems)
    setSelectAll(!selectAll)
    setSelectedItems(newSelectedItems)
  }

  const formatRenderValue = (selected: NamedEntity[]) => {
    if (!selected?.length) {
      return puiSelectAllNoneSelectedText
    }

    if (selected.length === items.length) {
      return puiSelectAllSelectedText
    }

    return selected
      .map((item) => LanguageUtils.getConstantTranslatedName(item.id, selected))
      .filter(Boolean)
      .join(', ')
  }

  const IconComponent = useCallback(
    () => <Toggle className={classes.toggleIcon} />,
    [],
  )

  return (
    <FormControl fullWidth>
      <InputLabel shrink>
        {hideLabelWhenEmpty && selectedItems?.length === 0 ? null : label}
      </InputLabel>
      <Select
        displayEmpty
        multiple
        IconComponent={IconComponent}
        MenuProps={{
          PaperProps: { className: classes.dropdownMenu },
          variant: 'menu',
        }}
        className={classes.selectRoot}
        disabled={disabled}
        input={<Input fullWidth />}
        ref={ref}
        renderValue={formatRenderValue}
        value={selectedItems}
        onChange={handleChange}
        {...props}
      >
        <MenuHeading isSelected={selectAll} onChange={handleSelectAll} />
        {items.map((item) => (
          // @ts-ignore
          <MenuItem className={classes.listItem} key={item.id} value={item}>
            <Checkbox checked={selectedItems.includes(item)} />
            {ItemComponent ? (
              <ItemComponent item={item} />
            ) : (
              <ListItemText
                primary={LanguageUtils.getTranslatedFieldName(item)}
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export default PuiSelectAll
