/* eslint-disable react/no-multi-comp */
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from '@pbt/pbt-ui-components'

const StyledHeader = styled(Grid)`
  & .MuiStepper-root {
    padding: 0;
    background-color: transparent;
  }
`

const StyledFooter = styled(Grid)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    marginRight: theme.spacing(2),
    minWidth: 150,
  },
}))

interface WellnessPlanVersionLayoutProps {
  children: ReactNode
}

export const WellnessPlanVersionLayout = ({
  children,
}: WellnessPlanVersionLayoutProps) => (
  <Grid
    container
    item
    direction="column"
    sx={(theme) => ({
      maxWidth: '100vw',
      flex: 1,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.constants.progressBarHeight}px)`,
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
    })}
    wrap="nowrap"
  >
    {children}
  </Grid>
)

const Header = ({ children }: WellnessPlanVersionLayoutProps) => {
  const { t } = useTranslation(['Common', 'WellnessPlans'])
  return (
    <StyledHeader
      container
      item
      direction="column"
      sx={(theme) => ({
        padding: theme.spacing(2, 3, 1),
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
        zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      })}
    >
      <Text
        sx={{
          marginBottom: (theme) => theme.spacing(2),
          lineHeight: '2.4rem',
        }}
        variant="h1"
      >
        {t('Common:WELLNESS_PLANS')}
      </Text>
      {children}
    </StyledHeader>
  )
}

const Body = ({ children }: WellnessPlanVersionLayoutProps) => (
  <Grid
    container
    item
    alignContent="flex-start"
    alignItems="flex-start"
    sx={{
      position: 'relative',
      overflowY: 'auto',
      flex: 1,
      padding: (theme) => theme.spacing(2, 3),
    }}
  >
    {children}
  </Grid>
)

const Footer = ({ children }: WellnessPlanVersionLayoutProps) => (
  <StyledFooter
    container
    item
    alignItems="center"
    sx={(theme) => ({
      padding: theme.spacing(2, 3),
      backgroundColor: theme.colors.grayWhite,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    })}
  >
    {children}
  </StyledFooter>
)

WellnessPlanVersionLayout.Header = Header
WellnessPlanVersionLayout.Body = Body
WellnessPlanVersionLayout.Footer = Footer
