import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CustomFieldValidatorState,
  InternalDisplayPreference,
  Nil,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { PDF } from '~/constants/extensions'
import {
  generatePdfDownload,
  generatePdfPreview,
} from '~/store/actions/documents'
import { getDocumentTypes } from '~/store/reducers/constants'
import {
  getDocument,
  getIsGeneratingForDownload,
  getIsGeneratingForPreview,
} from '~/store/reducers/documents'
import { Document, SoapForm, SoapFormToSave } from '~/types'
import { downloadFile } from '~/utils/file'
import useDialog from '~/utils/useDialog'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

export const normalizeDocumentForSave = (
  document: SoapForm,
): SoapFormToSave => ({
  ...document,
  id: document.generatedId ? undefined : document.id,
})

export const validateSummarizeInOneLineField = ({
  state: { howToDisplay, summarizeInOneLine },
}: CustomFieldValidatorState) => {
  if (howToDisplay === InternalDisplayPreference.SUMMARIZED) {
    return Validators.notEmptyFormField(summarizeInOneLine)
  }
  return true
}

export const useIsFormTypeDocument = (documentId: string | undefined) => {
  const document = useSelector(getDocument(documentId))
  const DocumentTypes = useSelector(getDocumentTypes)
  const FormType = Utils.findConstantIdByName('Form', DocumentTypes) || {}
  return document?.type === FormType
}

export const usePdfActions = (
  activeActionItem: SoapForm | Nil,
  closeActionsPopper: () => void,
) => {
  const dispatch = useDispatch()

  const isGeneratingForPreview = useSelector(getIsGeneratingForPreview)
  const isGeneratingForDownload = useSelector(getIsGeneratingForDownload)

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const isGenerating = isGeneratingForPreview || isGeneratingForDownload

  const isPdfGeneratedForActiveItem = Boolean(activeActionItem?.pdfUrl)

  useWSTopic({
    wsTopic: WSTopics.DOCUMENT_INSTANCE,
  })

  useEffect(() => {
    if (!isGenerating) {
      closeActionsPopper()
    }
  }, [isGenerating])

  const onViewRequested = () => {
    if (isPdfGeneratedForActiveItem) {
      openDocumentDialog({
        previewOnly: true,
        document: {
          extension: PDF,
          name: activeActionItem?.name,
          fileUrl: activeActionItem?.pdfUrl,
        } as Document,
        step: DocumentDialogStates.PREVIEW,
        disableCloseAfterUpdate: true,
        PreviewProps: {
          hideTopButtons: true,
          view: true,
          showChangeFile: false,
          hideEmail: true,
          hidePrint: true,
        },
      })
    } else if (activeActionItem?.documentId) {
      dispatch(generatePdfPreview(normalizeDocumentForSave(activeActionItem)))
    }
  }

  const onDownloadRequested = () => {
    const url = activeActionItem?.pdfUrl
    if (url) {
      downloadFile({
        url,
        name: activeActionItem?.name,
        extension: PDF,
      })
    } else if (activeActionItem?.documentId) {
      dispatch(generatePdfDownload(normalizeDocumentForSave(activeActionItem)))
    }
  }

  const closePopperIfPdfGenerated = () => {
    if (isPdfGeneratedForActiveItem) {
      closeActionsPopper()
    }
  }

  return {
    isGenerating,
    isGeneratingForPreview,
    onViewRequested,
    isGeneratingForDownload,
    onDownloadRequested,
    closePopperIfPdfGenerated,
  }
}
