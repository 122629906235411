import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  AlertIconType,
  LanguageUtils,
  PuiAlert,
  PuiAlertProps,
} from '@pbt/pbt-ui-components'

import { cancelLabOrder } from '~/store/duck/labOrders'
import { getLabVendors } from '~/store/reducers/constants'

export interface RemoveLabOrderDialogProps extends PuiAlertProps {
  labOrderId: string
  onClose: () => void
  vendorId: string
}

const RemoveLabOrderDialog = ({
  vendorId,
  labOrderId,
  onClose,
  ...rest
}: RemoveLabOrderDialogProps) => {
  const dispatch = useDispatch()
  const LabVendors = useSelector(getLabVendors)
  const { t } = useTranslation(['Soap', 'Common'])

  const handleRemove = () => {
    dispatch(cancelLabOrder(vendorId, labOrderId))
    onClose()
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:NO_KEEP_ORDER')}
      iconType={AlertIconType.WARN}
      message={t('Soap:ARE_YOU_SURE_YOU_WANT_TO_DELETE_LAB_ORDER', {
        orderName: LanguageUtils.getConstantTranslatedName(
          vendorId,
          LabVendors,
        ),
      })}
      okButtonText={t('Common:YES_DELETE_ORDER')}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleRemove}
      {...rest}
    />
  )
}

export default RemoveLabOrderDialog
