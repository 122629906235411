import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Theme } from '@mui/material'
import { useFields } from '@pbt/pbt-ui-components'

import { DiagnosisHistoryItem } from '~/components/elements/PreviousMedicalHistoryItem/DiagnosisHistoryItem'

export interface PreviousMedicalRecordsEntryProps {
  backgroundColor?: keyof Theme['colors']
  item: any
  onDelete: () => void
}

export interface PreviousMedicalRecordsEntryHandle {
  get: () => any
}

export const PreviousMedicalRecordsEntry = forwardRef<
  PreviousMedicalRecordsEntryHandle,
  any
>(
  (
    { item, backgroundColor, onDelete }: PreviousMedicalRecordsEntryProps,
    ref,
  ) => {
    const { t } = useTranslation('Common')

    const { fields } = useFields([
      { name: 'notes', initialValue: item.notes, label: t('Common:NOTES') },
      { name: 'date', initialValue: item.date, label: t('Common:DATE_TIME') },
      {
        name: 'practice',
        initialValue: item.diagnosedIn,
        label: t('Common:PRACTICE'),
      },
      {
        name: 'doctor',
        initialValue: item.diagnosedBy,
        label: t('Common:DOCTOR'),
      },
    ])

    const { notes, date, practice, doctor } = fields

    useImperativeHandle(ref, () => ({
      get: () => ({
        ...item,
        notes: notes.value,
        date: date.value,
        diagnosedIn: practice.value,
        diagnosedBy: doctor.value,
        id: item.id,
      }),
    }))

    return (
      <DiagnosisHistoryItem
        backgroundColor={backgroundColor}
        date={date}
        doctor={doctor}
        name={item.descriptionTranslation}
        notes={notes}
        practice={practice}
        onDelete={onDelete}
      />
    )
  },
)
