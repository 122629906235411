import * as React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const SvgShipmentIcon = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      clipRule="evenodd"
      d="m4.591 7.138 7.095 4.139 7.212-4.139L11.686 3zM11.094 20.5v-8.277L4 8.085v8.277zm1.183 0v-8.277l7.094-4.138v8.277z"
    />,
  )
export default SvgShipmentIcon
