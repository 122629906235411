import { normalize } from 'normalizr'

import * as Schema from './schemas'

const mockInventoryOrderStatus = [
  '7NOogO4G', // Ordered
  'l2ObGV4Z', // Partially Received
  'nm1Kg1ZN', // Received
]
const mockDistributors = ['eLVAQVd5', 'Z0OZX1KJ', '6YORQE7n']
const mockInventoryCategories = ['PL1J8Oa3', 'l2ObGV4Z', 'nm1Kg1ZN', '7NOogO4G']
const mockTotalLines = 10

// Helper function to return fake data until the API is ready
const getMockOrderData = (from: number, to: number) =>
  new Array(to - from).fill(null).map((_, index) => ({
    id: (index + from).toString(),
    orderedDate: '2023-05-21T04:00:00Z',
    orderNumber: 'AS-100',
    distributorId: mockDistributors[index % mockDistributors.length],
    orderedById: 'PL1J8Oa3',
    orderedByName: 'Freddie Mercury',
    categories: mockInventoryCategories.slice(
      index % mockInventoryCategories.length,
      mockInventoryCategories.length,
    ), // Drugs, Supplies
    linesReceived: mockTotalLines % (index + 1),
    totalLines: mockTotalLines,
    statusId: mockInventoryOrderStatus[index % mockInventoryOrderStatus.length],
    inventoryItemFlags: { controlled: true, storeCold: true },
    lastReceivedDate:
      index % mockInventoryOrderStatus.length === 0
        ? ''
        : '2023-06-16T13:00:00Z',
  }))

export const fetchInventoryOrders = async (
  businessId: string,
  from: number,
  to: number,
  // We will use these when actually calling the API
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _filters: any,
) =>
  new Promise((res) => {
    setTimeout(
      () =>
        res(
          normalize(
            {
              data: getMockOrderData(from, to),
              totalCount: 1000,
            },
            Schema.inventoryOrdersList,
          ),
        ),
      Math.random() * 2000,
    )
  })

const mockOrderData = (orderId: string) => ({
  id: orderId,
  orderNumber: '123456',
  orderedDate: '2023-05-21T04:00:00Z',
  distributorId: 'PL1J8Oa3',
  distributorName: 'MWI', // Only need this if supplier is Other
  orderedById: 'PL1J8Oa3',
  orderedByName: 'Dr. Vet Care Person',
  statusId: '7NOogO4G',
  notes: 'This is a note about the entire order',
  items: new Array(20).fill(null).map((_, index) => ({
    damagedQuantity: 2,
    id: index.toString(),
    itemVariationId: '1234',
    name: 'Vangaurd Rabies 3 year | 1 Vial',
    orderedQuantity: 10,
    receivedQuantity: 1,
    sku: '1234',
    statusId: 'PL1J8Oa3',
    packageTypeId: '7NOogO4G',
    packageUnitsId: '7NOogO4G',
    perPackageAmount: 1,
    inventoryItemFlags: { controlled: true, storeCold: true },
  })),
})

export const fetchInventoryOrder = async (
  businessId: string,
  orderId: string,
) =>
  new Promise((res) => {
    setTimeout(
      () => res(normalize(mockOrderData(orderId), Schema.inventoryOrder)),
      Math.random() * 2000,
    )
  })
