import React from 'react'
import { Box } from '@mui/material'

import {
  ChargesSection,
  RefundChargesSection,
} from '~/api/graphql/generated/types'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { UIRoliSection } from '~/utils/finance'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

interface MultipleLinesCellProps {
  InvoiceLineItem: React.ComponentType<ChargesSection & { index: number }>
  RetailOrderLineItem: React.ComponentType<UIRoliSection>
  hideRetailOrder?: boolean
  sections: RefundChargesSection[] | (ChargesSection | UIRoliSection)[]
}

const cellProps = {
  minHeight: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}

export const MultipleLinesCell = ({
  InvoiceLineItem,
  RetailOrderLineItem,
  sections,
  hideRetailOrder = false,
}: MultipleLinesCellProps) => {
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  return (
    <>
      {sections.map((section, index) => {
        const isRetailOrderSection =
          isChewyCheckoutEnabled && section.id === 'retailOrder'
        return (
          <Box
            {...cellProps}
            // eslint-disable-next-line react/no-array-index-key
            key={`${section.id}_${index}`}
          >
            {!hideRetailOrder && isRetailOrderSection ? (
              <RetailOrderLineItem {...(section as UIRoliSection)} />
            ) : (
              <InvoiceLineItem {...(section as ChargesSection)} index={index} />
            )}
          </Box>
        )
      })}
    </>
  )
}
