import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Table, TableCell, TableHead } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as R from 'ramda'
import {
  AddButton,
  AlertIconType,
  ButtonWithLoader,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { DiagnosisHistoryItemColumnWidths } from '~/components/elements/PreviousMedicalHistoryItem/DiagnosisHistoryItem'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import { DraftDiagnosisStatusId } from '~/constants/medicalHistory'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { clearClientsError, fetchClient } from '~/store/actions/clients'
import {
  batchDeleteDiagnosisHistory,
  clearDraftDiagnoses,
  deleteDiagnosisHistory,
  deleteDraftDiagnosis,
  fetchAttachmentHistory,
  saveDiagnosisHistory,
} from '~/store/actions/medicalHistory'
import { fetchProblemCatalog } from '~/store/actions/problems'
import { fetchWidgetsData, getWidgetData } from '~/store/duck/landing'
import { getClientsError } from '~/store/reducers/clients'
import {
  getDiagnosisStatus,
  getFeatureToggle,
} from '~/store/reducers/constants'
import {
  getAttachmentHistory,
  getDraftDiagnoses,
  getMedicalHistoryIsFetching,
  getMedicalHistoryIsSending,
} from '~/store/reducers/medicalHistory'
import { getUserByPatient } from '~/store/reducers/users'
import { DiagnosisSnapshotItem, Document, ProblemStates } from '~/types'
import { isNilOrEmpty } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import DocumentPreview from '../../admin/catalog/documents/DocumentPreview'
import {
  PreviousMedicalRecordsEntry,
  PreviousMedicalRecordsEntryHandle,
} from './PreviousMedicalRecordsEntry'

const StyledContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  margin-bottom: 0;
  height: ${({ theme }) => `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`};
`

const Footer = styled(Box)`
  margin-top: 40px;
  display: flex;
  gap: 16px;
`

const StyledRecordContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${({ theme }) =>
    `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(2)}`};
`

const StyledDocumentContainer = styled(Box)`
  width: 635px;
  overflow-y: scroll;
`

const StyledPMRTitle = styled(Text)`
  padding-left: ${({ theme }) => theme.spacing(2.5)};
`

const StyledDocumentPreview = styled(DocumentPreview)`
  height: 100%;
  overflow-y: scroll;
`

const StyledTableCell = styled(TableCell)`
  padding: 4px;
  color: ${({ theme }) => theme.colors.grayGray2};
`

const StyledAddButtonContainer = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const StyledTable = styled(Table)`
  position: sticky;
  top: ${({ theme }) => theme.spacing(-2)};
  background: ${({ theme }) => theme.colors.brandPageBg};
  z-index: 3;
`

export default function PreviousMedicalRecordsPage() {
  const { patientId = '', clientId = '', pmrId = '' } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const error = useSelector(getClientsError)
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const { t } = useTranslation(['Common', 'MedicalHistory'])
  const attachments = useSelector(getAttachmentHistory)
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)

  const client = useSelector(getUserByPatient(patientId, undefined))
  const [diagnosesToDelete, setDiagnosesToDelete] = useState<string[]>([])
  const isMasterProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MASTER_PROBLEMS_WIDGET),
  )
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const [openConfirmDeclineAlert] = useConfirmAlert({
    type: ConfirmAlertType.DECLINE_PMR_DIAGNOSES,
    preventShowAgainCheckBox: false,
  })

  const AddedByAiDiagnosisStatusId = Utils.findConstantIdByName(
    ProblemStates.ADDED_BY_AI,
    DiagnosisStatus,
  )

  const loadWidgetsData = (quiet: boolean | undefined = false) => {
    const widgetType = isMasterProblemsWidgetEnabled
      ? SnapshotsAliasTypes.Problems
      : SnapshotsAliasTypes.Diagnoses

    dispatch(
      fetchWidgetsData([widgetType], {
        quiet,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId,
        pageSize: 150,
      }),
    )

    if (isMasterProblemsWidgetEnabled) {
      dispatch(fetchProblemCatalog(patientId))
    }
  }

  const diagnoses = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      isMasterProblemsWidgetEnabled
        ? SnapshotsAliasTypes.Problems
        : SnapshotsAliasTypes.Diagnoses,
    ),
  )

  const draftDiagnoses = useSelector(getDraftDiagnoses)

  const diagnosesForPMR = diagnoses
    .filter(
      (diagnosis) =>
        diagnosis.statusId === AddedByAiDiagnosisStatusId &&
        diagnosis.previousMedicalRecordId === pmrId,
    )
    .concat(draftDiagnoses)

  useEffect(() => {
    loadWidgetsData(true)
    // TODO: This fetches all history, not just PMR. Will need new endpoint for release to live clinics
    dispatch(fetchAttachmentHistory(patientId))

    if (isNilOrEmpty(client) && patientId) {
      dispatch(fetchClient({ clientId: undefined, patientId }))
    }
  }, [patientId])

  useEffect(() => {
    if (error) {
      openAlert({
        hideCloseButton: true,
        iconType: AlertIconType.WARN,
        message: error,
        okButtonText: t('Common:GO_BACK'),
        onOk: () => {
          dispatch(clearClientsError())
          navigate('/clients')
          closeAlert()
        },
      })
    }
  }, [error])

  // Clear draft diagnoses when the page is unmounted
  useEffect(
    () => () => {
      dispatch(clearDraftDiagnoses())
    },
    [],
  )

  const diagnosesRefs = Array.from({ length: diagnosesForPMR.length }, () =>
    React.createRef<PreviousMedicalRecordsEntryHandle>(),
  )

  const isFetching = useSelector(getMedicalHistoryIsFetching)
  const isSending = useSelector(getMedicalHistoryIsSending)

  const navigateToSnapshotsAfterImportOrDecline = useCloseAfterCreation(
    () => navigate(`/client/${clientId}/patient/${patientId}/snapshots`),
    getMedicalHistoryIsSending,
    true,
  )

  const importDiagnoses = () => {
    const items = diagnosesRefs.map((ref) => ref.current?.get())

    const itemsToSave = items
      .filter(Boolean)
      .map((item) =>
        item.statusId === DraftDiagnosisStatusId
          ? R.omit(['id', 'statusId'], item)
          : item,
      )

    dispatch(saveDiagnosisHistory(itemsToSave, patientId, clientId))
    navigateToSnapshotsAfterImportOrDecline()
  }

  const declineAll = () => {
    openConfirmDeclineAlert({
      okButtonText: t('Common:DECLINE_ALL'),
      onConfirm: (proceed) => {
        if (proceed) {
          dispatch(
            batchDeleteDiagnosisHistory(
              diagnosesForPMR.map((diagnosis) => diagnosis.id),
              patientId,
            ),
          )
          navigateToSnapshotsAfterImportOrDecline()
        }
      },
    })
  }

  const PMRAttachment = attachments.find(
    (attachment) => attachment.id === pmrId,
  )

  const handleDelete = (item: DiagnosisSnapshotItem) => {
    if (item.statusId === DraftDiagnosisStatusId) {
      dispatch(deleteDraftDiagnosis(item.id))
    } else {
      setDiagnosesToDelete((prev) => [...prev, item.id])
      dispatch(deleteDiagnosisHistory(item.id, patientId))
    }
  }

  const onAddRequested = () => {
    openDiagnosisHistoryDialog({
      patientId,
      clientId,
      isForPmr: true,
    })
  }

  return (
    <StyledContainer>
      <StyledRecordContainer>
        <Text variant="h1">{t('Common:ADD_TO_MEDICAL_RECORD')}</Text>
        <Text fontSize={14} my={1} variant="h3">
          {t('Common:DIAGNOSIS_HISTORY')}
        </Text>
        <StyledTable>
          <TableHead>
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[0]}>
              {t('Common:ITEM')}
            </StyledTableCell>
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[1]}>
              {t('Common:WHEN')}
            </StyledTableCell>
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[2]} />
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[3]}>
              {t('Common:PRACTICE')}
            </StyledTableCell>
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[4]} />
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[5]}>
              {t(
                'MedicalHistory:DIAGNOSIS_HISTORY_EXPANDED_DETAILS.DIAGNOSED_BY',
              )}
            </StyledTableCell>
            <StyledTableCell width={DiagnosisHistoryItemColumnWidths[6]} />
          </TableHead>
        </StyledTable>
        {!isFetching &&
          diagnosesForPMR
            .filter((diagnosis) => !diagnosesToDelete.includes(diagnosis.id))
            .map((diagnosis, i) => (
              <PreviousMedicalRecordsEntry
                backgroundColor={i % 2 === 0 ? 'grayGray4' : 'inherit'}
                item={diagnosis}
                key={diagnosis.id}
                ref={diagnosesRefs[i]}
                onDelete={() => handleDelete(diagnosis)}
              />
            ))}
        {!isFetching && (
          <StyledAddButtonContainer>
            <AddButton
              addText={t('Common:ADD_DIAGNOSIS_HISTORY')}
              onAdd={onAddRequested}
            />
          </StyledAddButtonContainer>
        )}
        <Footer>
          <ButtonWithLoader
            loading={isSending}
            onClick={() => importDiagnoses()}
          >
            {t('Common:IMPORT_ALL')}
          </ButtonWithLoader>
          <ButtonWithLoader
            color="secondary"
            loading={isSending}
            onClick={() => declineAll()}
          >
            {t('Common:DECLINE_ALL')}
          </ButtonWithLoader>
        </Footer>
      </StyledRecordContainer>
      <StyledDocumentContainer>
        <StyledPMRTitle variant="h3">PMR</StyledPMRTitle>
        {PMRAttachment && (
          <StyledDocumentPreview document={PMRAttachment as Document} />
        )}
      </StyledDocumentContainer>
    </StyledContainer>
  )
}
