import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import {
  Defaults,
  PrimitiveTable,
  PrimitiveTableColumn,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import AlertIcon from '~/components/common/labels/AlertIcon'
import ExpandableTable from '~/components/common/lists/ExpandableTable'
import CreationDateCell from '~/components/common/lists/primitive-table/cells/CreationDateCell'
import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import Typography from '~/components/elements/Typography/Typography'
import i18n from '~/locales/i18n'
import {
  fetchMoreItemsForWellnessPlansList,
  fetchWellnessPlansList,
} from '~/store/actions/wellnessPlans'
import {
  getMultipleWellnessPlansVersions,
  getWellnessPlansIsFetchingList,
  getWellnessPlansVersionsList,
  getWellnessPlansVersionsTotalCount,
} from '~/store/reducers/wellnessPlans'
import { WellnessPlanAutoRenew, WellnessPlanAutoRenewInfo } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

import WellnessPlanDetails from './details/WellnessPlanDetails'
import NoWellnessPlansScreen from './NoWellnessPlansScreen'
import { useFetchAutoRenewingPlansInfo } from './steps/plan-linking/useFetchAutoRenewingPlansInfo'

const requiresPlanRenewalChange = (
  version: WellnessPlanVersion,
  autoRenewalPlansMap: Record<string, WellnessPlanAutoRenew>,
) => {
  if (!version.plans || version.plans.length === 0) {
    return false
  }
  return version.plans?.some((plan: WellnessPlan) => {
    const planVersionKey = `${version.id}-${plan.id}`

    const autoRenewalInfo = autoRenewalPlansMap[planVersionKey]
    // If plan not in autoRenewalPlansMap, no renewal change needed
    if (!autoRenewalInfo) {
      return false
    }

    const targetAutoRenewKey = autoRenewalInfo.autoRenewInto

    // If plan doesn't have autoRenewInto info, renewal change required
    if (!targetAutoRenewKey) {
      return true
    }

    const targetPlan = autoRenewalPlansMap[targetAutoRenewKey]

    const isLinkedToInactivePlan = !targetPlan.active
    const isSelfLinked = plan.id === targetPlan.id

    return isLinkedToInactivePlan && !isSelfLinked
  })
}

const getColumns = (autoRenewingPlansInfo: WellnessPlanAutoRenewInfo) =>
  [
    {
      label: i18n.t('Common:VERSION'),
      prop: 'versionNumber',
      width: 5,
    },
    {
      label: i18n.t('Common:CREATED'),
      prop: CreationDateCell,
      width: 4,
    },
    {
      label: i18n.t('Common:ENROLLED_PATIENTS'),
      prop: (item: WellnessPlanVersion) => item.activePatients?.toString(),
      width: 4,
    },
    {
      label: i18n.t('Common:ACTIVE_ONE'),
      prop: (item: WellnessPlanVersion) => boolToYesNoString(item.active),
      width: 3,
    },
    autoRenewingPlansInfo.plansList.length
      ? {
          prop: (item: WellnessPlanVersion) =>
            requiresPlanRenewalChange(item, autoRenewingPlansInfo.plansMap) ? (
              <AlertIcon
                plain
                alertPlacement="top-end"
                alertText={i18n.t(
                  'WellnessPlans:SOME_PLANS_AUTO_RENEW_INTO_INACTIVE_PLANS',
                )}
              />
            ) : undefined,
          width: 1,
        }
      : undefined,
  ].filter(Boolean)

export interface WellnessPlansTableComponentProps {
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
  wellnessPlanVersionId?: string
}

const WellnessPlansTableComponent = ({
  headerButtons,
  wellnessPlanVersionId,
  onDetailsClose,
}: WellnessPlansTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const list = useSelector(getWellnessPlansVersionsList)
  const wellnessPlansVersions = useSelector(
    getMultipleWellnessPlansVersions(list),
  )
  const totalCount = useSelector(getWellnessPlansVersionsTotalCount)
  const isFetchingList = useSelector(getWellnessPlansIsFetchingList)
  const {
    isLoading: isLoadingAutoRenewingPlansInfo,
    shouldShowPlanRenewalWarning,
    autoRenewingPlansInfo,
    refetchAutoRenewingPlansInfo,
  } = useFetchAutoRenewingPlansInfo()

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchWellnessPlansList({
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        query: search,
      }),
    )
  }, [search])

  const navigateToWellnessPlan = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/wellness-plans/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(wellnessPlansVersions[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(
      fetchMoreItemsForWellnessPlansList({
        from: startIndex,
        to: endIndex,
        query: search,
      }),
    )
  }

  const getDisabled = (item: WellnessPlanVersion) => !item.active

  return (
    <ExpandableTable
      Expander={WellnessPlanDetails}
      NoItemsScreen={NoWellnessPlansScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList || isLoadingAutoRenewingPlansInfo}
      itemId={wellnessPlanVersionId}
      list={wellnessPlansVersions}
      searchTerm={search}
      title={
        shouldShowPlanRenewalWarning ? (
          <>
            <Box pb={1} pt={3}>
              <Typography.H1>{t('Common:WELLNESS_PLANS')}</Typography.H1>
            </Box>
            <AlertBanner
              actions={{
                linkText: t('WellnessPlans:VERIFY_PLAN_LINKING'),
                onClick: () => {
                  navigate(`/admin/catalog/wellness-plans/linking`)
                },
              }}
              title={
                <Box pr={4}>
                  <Typography.H3 color="uiError">
                    {t('WellnessPlans:MAKE_SURE_ALL_PLANS_AUTO_RENEW')}
                  </Typography.H3>
                </Box>
              }
              variant="error"
            />
          </>
        ) : (
          t('Common:WELLNESS_PLANS')
        )
      }
      onClose={onDetailsClose}
      onSave={refetchAutoRenewingPlansInfo}
      onSelected={navigateToWellnessPlan}
    >
      <PrimitiveTable
        columns={getColumns(autoRenewingPlansInfo) as PrimitiveTableColumn[]}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default WellnessPlansTableComponent
