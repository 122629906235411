import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AddButton, PuiTooltip, Utils } from '@pbt/pbt-ui-components'

import ListActions from '~/components/common/lists/ListActions'
import DialogNames from '~/constants/DialogNames'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { editOrdersStatuses, removeOrders } from '~/store/actions/orders'
import { deleteTasks, editTasks } from '~/store/actions/tasks'
import { useSetFinalOrderState } from '~/store/hooks/orders'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import { useTaskEvent } from '~/store/hooks/tasks'
import {
  getAppointmentId,
  getClientId,
  getIsCurrentContextSoap,
  getIsFinalized,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { EasterEggEvents, Order, Task } from '~/types'
import { getOrderByUniqueId, isLabTestUnderOrder } from '~/utils/orderUtils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    addText: {
      fontWeight: 400,
    },
    buttonContainer: {
      marginLeft: theme.spacing(1),
      '& button': {
        textTransform: 'none',
      },
    },
  }),
  { name: 'ToDoTaskListActions' },
)

const isDeletableOrder = R.compose(R.not, isLabTestUnderOrder)

export interface ToDoTaskListActionsProps {
  checkedOrderIds: string[]
  checkedTaskIds: string[]
  clearCheckedItems: () => void
  editDisabled?: boolean
  orders: Order[]
  tasks: Task[]
}

const ToDoTaskListActions = ({
  checkedOrderIds,
  checkedTaskIds,
  editDisabled,
  orders,
  tasks,
  clearCheckedItems,
}: ToDoTaskListActionsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Task'])

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const soapId = useSelector(getSoapId)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const isInvoicePosted = useGetInvoicePosted()

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const setFinalOrderState = useSetFinalOrderState(['id', 'type'])

  const [pendingCreateAndAssignOrders, setPendingCreateAndAssignOrders] =
    useState<Order[]>([])
  const [pendingCreateAndAddRepeatOrders, setPendingCreateAndAddRepeatOrders] =
    useState<Order[]>([])

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)

  const multipleTaskDialogProps = {
    clientId,
    patientId,
    appointmentId,
    dueDate: appointment?.scheduledEndDatetime,
    soapId,
  }

  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openMultipleTasksDialog] = useDialog(DialogNames.MULTIPLE_TASKS)
  const [openAssignTasksDialog] = useDialog(DialogNames.ASSIGN_TASKS, () => {
    if (pendingCreateAndAssignOrders.length > 0) {
      openMultipleTasksDialog({
        ...multipleTaskDialogProps,
        items: pendingCreateAndAssignOrders,
      })
    }
    setPendingCreateAndAssignOrders([])
  })
  const [openRepeatTasksDialog] = useDialog(DialogNames.REPEAT_TASKS, () => {
    if (pendingCreateAndAddRepeatOrders.length > 0) {
      openMultipleTasksDialog({
        ...multipleTaskDialogProps,
        items: pendingCreateAndAddRepeatOrders,
      })
    }
    setPendingCreateAndAddRepeatOrders([])
  })
  const [openRemoveOrderFromPostedInvoiceDialog] = useDialog(
    DialogNames.REMOVE_ORDERS_FROM_POSTED_INVOICE,
  )

  const getTasksList = (taskIds: string[]) =>
    taskIds.map((taskId) => Utils.findById(taskId, tasks))
  const getOrdersList = (orderIds: string[]) =>
    orderIds
      .map((orderId) => getOrderByUniqueId(orderId, orders))
      .filter(R.identity) as Order[]

  const ordersList = getOrdersList(checkedOrderIds)
  const deletableOrdersList = ordersList.filter(isDeletableOrder)
  const deleteDisabled = ordersList.length > deletableOrdersList.length

  const createTasks = () => {
    openMultipleTasksDialog({
      ...multipleTaskDialogProps,
      items: ordersList,
    })
  }

  const addRepeatToTasks = () => {
    const tasksList = getTasksList(checkedTaskIds)

    if (ordersList.length > 0) {
      setPendingCreateAndAddRepeatOrders(ordersList)
    }

    openRepeatTasksDialog({ tasks: tasksList })
    clearCheckedItems()
  }

  const assignTasks = () => {
    const tasksList = getTasksList(checkedTaskIds)

    if (ordersList.length > 0) {
      setPendingCreateAndAssignOrders(ordersList)
    }

    openAssignTasksDialog({ tasks: tasksList })
    clearCheckedItems()
  }

  const doRemove = (
    tasksToRemove: Task[] = [],
    ordersToRemove: Order[] = [],
  ) => {
    if (tasksToRemove.length > 0) {
      dispatch(deleteTasks(R.pluck('id', tasksToRemove)))
    }

    if (ordersToRemove.length > 0) {
      dispatch(removeOrders(ordersToRemove))
    }
  }

  const removeTasks = () => {
    const tasksList = getTasksList(checkedTaskIds)

    if (isInvoicePosted && deletableOrdersList.length > 0) {
      openRemoveOrderFromPostedInvoiceDialog({
        orders: deletableOrdersList,
        onOk: () => doRemove(tasksList),
      })
    } else {
      doRemove(tasksList, deletableOrdersList)
    }

    clearCheckedItems()
  }

  const markAsCompleted = () => {
    const tasksList = getTasksList(checkedTaskIds)

    if (tasksList.length > 0) {
      const newTasks = tasksList.map((task) => ({
        ...task,
        stateId: DoneState,
      }))
      dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
      dispatch(editTasks(newTasks))
    }

    if (ordersList.length > 0) {
      const newOrders = ordersList.map(setFinalOrderState)
      dispatch(editOrdersStatuses(newOrders))
    }

    clearCheckedItems()
  }

  const onAdd = () => {
    openTaskDialog({
      clientId,
      patientId,
      soapId,
      appointmentId,
      dueDate: appointment?.scheduledEndDatetime,
    })
  }

  const checkedItems = checkedTaskIds.concat(checkedOrderIds)
  const hasCheckedItems = checkedItems.length > 0
  const hasOnlyAssignedCheckedItems = checkedTaskIds.every(
    (id) => Utils.findById(id, tasks)?.assigned,
  )
  const hasTasksCheckedItems = checkedTaskIds.length > 0

  return hasCheckedItems ? (
    <ListActions onClear={clearCheckedItems}>
      <Button disabled={editDisabled} onClick={markAsCompleted}>
        {t('Common:MARK_AS_COMPLETED')}
      </Button>
      {!isReadOnly && hasTasksCheckedItems && (
        <Button disabled={editDisabled} onClick={assignTasks}>
          {hasOnlyAssignedCheckedItems
            ? t('Common:RE-ASSIGN_ACTION')
            : t('Common:ASSIGN_ACTION')}
        </Button>
      )}
      {!isReadOnly && hasTasksCheckedItems && (
        <Button disabled={editDisabled} onClick={addRepeatToTasks}>
          {t('Common:ADD_REPEAT')}
        </Button>
      )}
      {!isReadOnly && !hasTasksCheckedItems && (
        <Button disabled={editDisabled} onClick={createTasks}>
          {t('Common:CREATE_TASKS')}
        </Button>
      )}
      {!isReadOnly &&
        (deleteDisabled ? (
          <PuiTooltip
            tooltipText={t(
              'Tasks:FINALIZED_ORDERS_CANT_BE_REMOVED_FROM_TASK_LIST',
            )}
          >
            <span className={classes.buttonContainer}>
              <Button disabled>{t('Common:DELETE_ACTION')}</Button>
            </span>
          </PuiTooltip>
        ) : (
          <Button disabled={editDisabled} onClick={removeTasks}>
            {t('Common:DELETE_ACTION')}
          </Button>
        ))}
    </ListActions>
  ) : isReadOnly ? null : (
    <Grid container item p={1}>
      <AddButton
        addText={t('Common:ADD_TASK')}
        classes={{ addText: classes.addText }}
        disabled={editDisabled}
        onAdd={onAdd}
      />
    </Grid>
  )
}

export default ToDoTaskListActions
