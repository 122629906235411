import { Moment, MomentInput, unitOfTime } from 'moment'
import * as R from 'ramda'
import {
  Business,
  Constant,
  DateFormat,
  Defaults,
  moment,
  Nil,
  NumberUtils,
  PluralForms,
  PluralUtils,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { TimetableEvent } from '~/types'

export const DEFAULT_APPOINTMENT_DURATION = 30
const DEFAULT_STEP = 30
const ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]'

export enum TimeUnits {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  NIGHT = 'NIGHT',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export const TimeUnitsAdjectives = {
  [TimeUnits.DAY]: i18n.t('Constants:TIME_UNITS.DAY_ADJECTIVE'),
  [TimeUnits.HOUR]: i18n.t('Constants:TIME_UNITS.HOUR_ADJECTIVE'),
  [TimeUnits.MINUTE]: i18n.t('Constants:TIME_UNITS.MINUTE_ADJECTIVE'),
  [TimeUnits.MONTH]: i18n.t('Constants:TIME_UNITS.MONTH_ADJECTIVE'),
  [TimeUnits.NIGHT]: i18n.t('Constants:TIME_UNITS.NIGHT_ADJECTIVE'),
  [TimeUnits.WEEK]: i18n.t('Constants:TIME_UNITS.WEEK_ADJECTIVE'),
  [TimeUnits.YEAR]: i18n.t('Constants:TIME_UNITS.YEAR_ADJECTIVE'),
}

export const TimeUnitDiffMap: Record<TimeUnits, unitOfTime.Diff | undefined> = {
  [TimeUnits.DAY]: 'd',
  [TimeUnits.HOUR]: 'h',
  [TimeUnits.MINUTE]: 'm',
  [TimeUnits.MONTH]: 'M',
  [TimeUnits.WEEK]: 'w',
  [TimeUnits.YEAR]: 'y',
  [TimeUnits.NIGHT]: undefined,
}

export type TimeSingularFormKey = `${TimeUnits}_${PluralForms.ONE}`
export type TimePluralFormKey = `${TimeUnits}_${PluralForms.OTHER}`
export type TimeSingularPluralFormKey =
  `${TimeUnits}_${PluralForms.ONE_OR_OTHER}`
export type AggregateDate = {
  date: Moment
  ignoreSeconds?: boolean
  time: Moment
}

export const getTimeTrackerEnabled = (user: User, businessId: string) =>
  user?.timeTrackingSettings?.find(R.propEq('business', businessId))
    ?.trackingEnabled || false

export const getLockOutsideWorkingHoursEnabled = (
  user: User,
  businessId: string,
) =>
  user?.lockWorkingHoursSettings?.find(R.propEq('businessId', businessId))
    ?.lockOutsideWorkingHours || false

export const getHoursBetween = (start: string, end: string) => {
  const startMoment = moment(start).utc()
  const endMoment = moment(end).utc()
  const duration = moment.duration(endMoment.diff(startMoment))
  const hours = duration.asHours()
  return NumberUtils.formatNumberWithDecimal(Utils.round(hours, 2) as number)
}

export const getDateString = (startDate: Moment, endDate?: Moment) => {
  if (!endDate || startDate.isSame(endDate, 'day')) {
    return startDate.format(Defaults.DATE_FORMAT)
  }
  if (startDate.isSame(endDate, 'year')) {
    return `${startDate.format(DateFormat.DATE_WITHOUT_YEAR)}-${endDate.format(
      Defaults.DATE_FORMAT,
    )}`
  }
  return `${startDate.format(Defaults.DATE_FORMAT)}-${endDate.format(
    Defaults.DATE_FORMAT,
  )}`
}

export const aggregateDate = ({ date, time, ignoreSeconds }: AggregateDate) =>
  moment([
    date.get('year'),
    date.get('month'),
    date.get('date'),
    time.get('hour'),
    time.get('minute'),
    ignoreSeconds ? 0 : time.get('second'),
  ])

export const aggregateDateToUtc = ({
  date,
  time,
  ignoreSeconds,
}: AggregateDate) =>
  aggregateDate({ date, time, ignoreSeconds }).utc().format(ISO_FORMAT)

export const aggregateStartAndEndDates = ({
  startDate,
  endDate,
  startTime,
  endTime,
  ignoreSeconds,
  isMultiday,
}: {
  endDate: Moment
  endTime: Moment
  ignoreSeconds?: boolean
  isMultiday: boolean
  startDate: Moment
  startTime: Moment
}) => {
  if (!isMultiday && !startDate.isSame(endDate, 'days')) {
    endDate = startDate.clone()
  }

  const start = aggregateDate({
    date: startDate,
    time: startTime,
    ignoreSeconds,
  })
  const end = aggregateDate({ date: endDate, time: endTime, ignoreSeconds })

  // Add day to endTime if after midnight
  if (start.isAfter(end)) {
    end.add(1, 'day')
  }

  return [start.utc().format(ISO_FORMAT), end.utc().format(ISO_FORMAT)]
}

export const roundMinutes = (minutes: number) => Math.ceil(minutes / 5) * 5

export const roundTime = (datetime: string) => {
  const momentTime = moment(datetime)
  const minutes = roundMinutes(momentTime.minutes())
  return momentTime.minutes(minutes).toISOString()
}

export const getAppointmentEndTime = (
  businessTzStartTime: Moment,
  durationMinutes: number,
) => {
  const duration = durationMinutes || DEFAULT_APPOINTMENT_DURATION
  const midnight = businessTzStartTime.clone()
  midnight
    .set({
      hour: 0,
      minutes: 0,
      seconds: 0,
      ms: 0,
    })
    .add(1, 'day')
    .subtract(1, 'second')

  const endTime = businessTzStartTime.clone()
  endTime.add(duration, 'minutes')
  endTime.set({
    minutes: roundMinutes(endTime.minutes()),
    seconds: 0,
    ms: 0,
  })

  return endTime.isAfter(midnight)
    ? midnight.toISOString()
    : endTime.toISOString()
}

// This is used to send API request and as a helper to validate dates
// As it's not used to display dates, we must not localize this
export const serializeTimeTo24hStr = (date: string) =>
  date ? moment(date).format('HH:mm') : undefined

export const getNewTimeAfterDayChange = (
  newDate: string | null,
  oldTime: string,
) => {
  if (!newDate) {
    return oldTime
  }

  return aggregateDate({
    date: moment(newDate),
    time: moment(oldTime),
    ignoreSeconds: true,
  }).toISOString()
}

export const dateToIntervals = (
  startDate: Moment,
  endDate: Moment,
  step = DEFAULT_STEP,
) => {
  const dates = []
  const d = startDate.clone()

  while (d.isBefore(endDate, 'minutes')) {
    dates.push(d.toISOString())
    d.add(step, 'minutes')
  }

  return dates
}

export const isWithinTimeInterval = (
  time: MomentInput | Nil,
  start: MomentInput,
  step = DEFAULT_STEP,
) =>
  Boolean(time) &&
  moment(time).isBetween(start, moment(start).add(step, 'm'), null, '[)')

export const getIsMultidayEvent = (event: TimetableEvent | Nil) => {
  if (!event) {
    return false
  }

  const startDate = moment(event.scheduledStartDatetime)
  const endDate = moment(event.scheduledEndDatetime)

  return endDate.isAfter(startDate, 'date')
}

export const LocalizedSingularTimeUnits: Record<TimeSingularFormKey, string> = {
  DAY_ONE: i18n.t('Constants:TIME_UNITS.DAY_ONE'),
  HOUR_ONE: i18n.t('Constants:TIME_UNITS.HOUR_ONE'),
  MINUTE_ONE: i18n.t('Constants:TIME_UNITS.MINUTE_ONE'),
  MONTH_ONE: i18n.t('Constants:TIME_UNITS.MONTH_ONE'),
  NIGHT_ONE: i18n.t('Constants:TIME_UNITS.NIGHT_ONE'),
  WEEK_ONE: i18n.t('Constants:TIME_UNITS.WEEK_ONE'),
  YEAR_ONE: i18n.t('Constants:TIME_UNITS.YEAR_ONE'),
}

export const LocalizedPluralTimeUnits: Record<TimePluralFormKey, string> = {
  DAY_OTHER: i18n.t('Constants:TIME_UNITS.DAY_OTHER'),
  HOUR_OTHER: i18n.t('Constants:TIME_UNITS.HOUR_OTHER'),
  MINUTE_OTHER: i18n.t('Constants:TIME_UNITS.MINUTE_OTHER'),
  MONTH_OTHER: i18n.t('Constants:TIME_UNITS.MONTH_OTHER'),
  NIGHT_OTHER: i18n.t('Constants:TIME_UNITS.NIGHT_OTHER'),
  WEEK_OTHER: i18n.t('Constants:TIME_UNITS.WEEK_OTHER'),
  YEAR_OTHER: i18n.t('Constants:TIME_UNITS.YEAR_OTHER'),
}

export const LocalizedSingularOrPluralTimeUnits: Record<
  TimeSingularPluralFormKey,
  string
> = {
  DAY_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.DAY_OR_DAYS'),
  HOUR_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.HOUR_OR_HOURS'),
  MINUTE_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.MINUTE_OR_MINUTES'),
  MONTH_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.MONTH_OR_MONTHS'),
  NIGHT_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.NIGHT_OR_NIGHTS'),
  WEEK_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.WEEK_OR_WEEKS'),
  YEAR_ONE_OR_OTHER: i18n.t('Constants:TIME_UNITS.YEAR_OR_YEARS'),
}

export const getSingularTimeUnits = (units: Constant[]) =>
  R.map(
    (unit) => ({
      ...unit,
      name: unit.name,
      nameTranslation:
        LocalizedSingularTimeUnits[
          PluralUtils.getPluralUnitKey<TimeSingularFormKey, TimeUnits>(
            unit.name as TimeUnits,
            PluralForms.ONE,
          )
        ] || unit.name,
    }),
    units,
  )

export const getPluralTimeUnits = (units: Constant[]) =>
  R.map(
    (unit) => ({
      ...unit,
      name: unit.name,
      nameTranslation:
        LocalizedPluralTimeUnits[
          PluralUtils.getPluralUnitKey<TimePluralFormKey, TimeUnits>(
            unit.name as TimeUnits,
            PluralForms.OTHER,
          )
        ] || i18n.t('Common:SOMETHING_PLURALIZED', { something: unit.name }),
    }),
    units,
  )

export const getSingularOrPluralTimeUnits = (units: Constant[]) =>
  R.map(
    (unit) => ({
      ...unit,
      name: unit.name,
      nameTranslation:
        LocalizedSingularOrPluralTimeUnits[
          PluralUtils.getPluralUnitKey<TimeSingularPluralFormKey, TimeUnits>(
            unit.name as TimeUnits,
            PluralForms.ONE_OR_OTHER,
          )
        ] || i18n.t('Common:SOMETHING_ONE_OR_OTHER', { something: unit.name }),
    }),
    units,
  )

export const getTranslatedTimeUnits = (units: Constant[]) => ({
  singularTimeUnits: getSingularTimeUnits(units),
  pluralTimeUnits: getPluralTimeUnits(units),
  singularOrPluralTimeUnits: getSingularOrPluralTimeUnits(units),
})

export const getTimeUnitListPluralized = (
  units: Constant[],
  amount: number,
) => {
  const { singularTimeUnits, pluralTimeUnits, singularOrPluralTimeUnits } =
    getTranslatedTimeUnits(units)

  return PluralUtils.getPluralForm(amount, {
    fallback: singularOrPluralTimeUnits,
    singularOrPlural: singularOrPluralTimeUnits,
    plural: pluralTimeUnits,
    singular: singularTimeUnits,
  })
}

export const getOneTimeUnitPluralized = (
  unitName: TimeUnits,
  amount: number,
) => {
  const singularUnitName = PluralUtils.getPluralUnitKey<
    TimeSingularFormKey,
    TimeUnits
  >(unitName, PluralForms.ONE)
  const pluralUnitName = PluralUtils.getPluralUnitKey<
    TimePluralFormKey,
    TimeUnits
  >(unitName, PluralForms.OTHER)
  const singularOrPluralUnitName = PluralUtils.getPluralUnitKey<
    TimeSingularPluralFormKey,
    TimeUnits
  >(unitName, PluralForms.ONE_OR_OTHER)

  return PluralUtils.getPluralForm(amount, {
    fallback: unitName,
    singularOrPlural:
      LocalizedSingularOrPluralTimeUnits[singularOrPluralUnitName] ||
      (PluralUtils.pluralizeOneOrOther(unitName) as string),
    plural:
      LocalizedPluralTimeUnits[pluralUnitName] ||
      (PluralUtils.pluralizeOneOrOther(unitName) as string),
    singular: LocalizedSingularTimeUnits[singularUnitName],
  })
}

export const hasDiffWithoutRemains = (
  endDate: string,
  startDate: string | Nil,
  type: unitOfTime.Diff | undefined,
) => {
  const diff = moment(endDate).diff(startDate, type, true)
  return diff > 0 && Number.isInteger(diff)
}

export const getAppointmentRecurrenceTimeUnitConstant = (
  appointment: TimetableEvent | Nil,
) => {
  const { recurrenceStartDate: startDate, recurrenceEndDate: endDate } =
    appointment || {}

  if (!endDate) {
    return TimeUnits.DAY
  }

  return R.cond([
    [
      R.always(
        hasDiffWithoutRemains(
          endDate,
          startDate,
          TimeUnitDiffMap[TimeUnits.YEAR],
        ),
      ),
      R.always(TimeUnits.YEAR),
    ],
    [
      R.always(
        hasDiffWithoutRemains(
          endDate,
          startDate,
          TimeUnitDiffMap[TimeUnits.MONTH],
        ),
      ),
      R.always(TimeUnits.MONTH),
    ],
    [
      R.always(
        hasDiffWithoutRemains(
          endDate,
          startDate,
          TimeUnitDiffMap[TimeUnits.WEEK],
        ),
      ),
      R.always(TimeUnits.WEEK),
    ],
    [R.T, R.always(TimeUnits.DAY)],
  ])()
}

export const getAppointmentTypeColor = (
  business: Business | undefined,
  typeId: string,
) => business?.appointmentConfigurationByTypeId?.[typeId].color || undefined

export const formatCalendarDate = (dateString: string) =>
  moment(dateString).calendar()

export const formatHourRange = (
  dateOne: string | Moment,
  dateTwo: string | Moment,
) => {
  const firstRange = moment(dateOne).format(DateFormat.FULL_TIME_WITH_MERIDIAN)
  const secondRange = moment(dateTwo).format(DateFormat.FULL_TIME_WITH_MERIDIAN)

  return `${firstRange} - ${secondRange}`
}

const getMomentDate = (date?: Moment | string) => (date ? moment(date) : null)

export const isToday = (date?: Moment | string, timezone?: string) =>
  timezone
    ? Boolean(getMomentDate(date)?.isSame(moment().tz(timezone), 'd'))
    : Boolean(getMomentDate(date)?.isSame(moment(), 'd'))

export const isYesterday = (date: Moment | string) =>
  Boolean(getMomentDate(date)?.isSame(moment().add(-1, 'days'), 'd'))

export const isTomorrow = (date: Moment | string) =>
  Boolean(getMomentDate(date)?.isSame(moment().add(1, 'days'), 'd'))

export const isNow = ({
  date,
  timezone,
  step,
}: {
  date: Moment | string
  step?: number
  timezone?: string
}) =>
  isToday(date, timezone) &&
  isWithinTimeInterval(moment(), getMomentDate(date), step)

export const splitDateAndTime = (dateString: string, timezone: string) => {
  const curDate = moment.tz(dateString, timezone)
  const isTodayDate = isToday(curDate)
  const date = isTodayDate
    ? i18n.t('Time:LABEL.TODAY')
    : curDate.format(DateFormat.FULL_DATE_YEAR_ABBREV)
  const time = curDate.format(DateFormat.FULL_TIME_WITH_MERIDIAN)
  return { date, time }
}

export const minutesToHHMM = (minutes: number) =>
  moment().startOf('day').add(minutes, 'minutes').format('HH:mm')
