import { Schema, schema } from 'normalizr'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { getReminderGroupName } from '~/components/dashboard/reminders/reminderUtils'
import {
  getLogKeyFromCatalog,
  getLogKeyFromLogs,
  getProblemKey,
} from '~/components/dashboard/soapV2/problems/utils'
import { LandingWidgetName } from '~/constants/landingConstants'
import PaymentType from '~/constants/paymentTypes'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { TimelineEntryType } from '~/constants/timelineConstants'
import { getMembershipPaymentId } from '~/utils/membershipPaymentUtils'

import {
  getBusinessProcessStrategy,
  getPatientProcessStrategy,
  getUserProcessStrategy,
} from './utils'

export const appointmentType = new schema.Entity('appointmentTypes')
const appointmentTypeArray = new schema.Array(appointmentType)
export const appointmentTypeList = {
  data: appointmentTypeArray,
}

const businessItem = new schema.Entity('businesses')
const businessList = new schema.Array(businessItem)
export const business = new schema.Entity(
  'businesses',
  {
    children: businessList,
  },
  {
    processStrategy: getBusinessProcessStrategy,
  },
)

export const businessOnboardInfo = new schema.Entity(
  'businesses',
  {},
  { idAttribute: 'businessId' },
)

export const patient = new schema.Entity(
  'patients',
  {},
  {
    processStrategy: getPatientProcessStrategy,
  },
)

const userItem = new schema.Entity(
  'users',
  {},
  {
    processStrategy: getUserProcessStrategy,
  },
)
export const user = new schema.Entity(
  'users',
  {
    businessToRoleList: [{ business }],
    patients: [patient],
    coparents: [userItem],
  },
  {
    processStrategy: getUserProcessStrategy,
  },
)

export const userArray = new schema.Array(user)

export const clientsList = {
  data: userArray,
}

export const membersList = {
  data: userArray,
}

export const businessesArray = new schema.Array(business)
export const businessesList = {
  data: businessesArray,
}

export const roles = new schema.Entity('roles')
export const constants = {
  Role: new schema.Array(roles),
}

export const availabilityRule = new schema.Entity('availabilityRules')
export const groupedAvailabilityRule = {
  rules: new schema.Array(availabilityRule),
}
export const availabilityRulesList = {
  data: new schema.Array(groupedAvailabilityRule),
}

export const location = new schema.Entity('locations')

export const inventory = new schema.Entity('inventories')
export const inventoriesList = {
  data: new schema.Array(inventory),
}

export const variation = new schema.Entity('variations')
export const variationsList = new schema.Array(variation)

export const procedure = new schema.Entity('procedures')
export const proceduresList = {
  data: new schema.Array(procedure),
}

export const notificationMenuTotalCountItem = new schema.Entity(
  'notificationMenuTotalCount',
  {},
  { idAttribute: 'notificationAreaId' },
)
export const notificationMenuTotalCountItemsArray = new schema.Array(
  notificationMenuTotalCountItem,
)
export const notificationListTotalCountItem = new schema.Entity(
  'notificationListTotalCount',
  {},
  { idAttribute: 'notificationAreaId' },
)
export const notificationListTotalCountItemsArray = new schema.Array(
  notificationListTotalCountItem,
)
export const notificationTotalCounts = {
  menuTotalCountsForAreas: notificationMenuTotalCountItemsArray,
  listTotalCountsForAreas: notificationListTotalCountItemsArray,
}

export const notificationItem = new schema.Entity('notificationItem')
export const notificationItemsArray = new schema.Array(notificationItem)
export const notificationItemsList = {
  data: notificationItemsArray,
  notificationTotalCounts,
}

export const wsNotificationData = {
  notificationTotalCounts,
  notification: notificationItem,
}

// Following map is needed because of excess payment type enums
// used on backend. Details can be enriched from Oleg Gavavka
const paymentTypesMap: Record<string, string> = {
  'Go Stripe': PaymentType.GO_STRIPE_PAYMENT,
  'Go Stripe PaymentLink': PaymentType.GO_STRIPE_PAYMENT,
  Go: PaymentType.GO_PAYMENT,
  'Go PaymentLink': PaymentType.GO_PAYMENT,
}

export const payment = new schema.Entity(
  'payments',
  {
    client: user,
  },
  {
    processStrategy: (value) => ({
      ...value,
      [paymentTypesMap[value.sourceType] || 'initialPaymentSource']:
        value.initialPaymentSource,
    }),
  },
)
export const goPayment = new schema.Entity('goPayments', {
  payment,
  client: user,
})

export const goStripePayment = new schema.Entity('goStripePayments', {
  payment,
  client: user,
})

payment.define({
  [PaymentType.GO_STRIPE_PAYMENT]: goStripePayment,
  [PaymentType.GO_PAYMENT]: goPayment,
})

export const posTransaction = new schema.Entity('posTransactions', {
  payment: {
    client: user,
  },
})

export const invoice = new schema.Entity('invoices', {
  client: user,
  patient,
  groups: [
    {
      groupedItems: [
        {
          producer: user,
        },
      ],
    },
  ],
})
export const invoicesArray = new schema.Array(invoice)
invoice.define({ invoices: invoicesArray })
export const invoicesList = {
  data: invoicesArray,
}

export const batchInvoice = {
  invoices: invoicesArray,
}

export const checkoutInvoiceData = {
  forDate: invoicesArray,
  others: invoicesArray,
}

export const space = new schema.Entity('spaces')
export const spacesArray = new schema.Array(space)
export const spacesList = {
  data: spacesArray,
}

export const eventItem = new schema.Entity('events')
export const event = new schema.Entity(
  'events',
  {
    estimates: invoicesArray,
    patient,
    client: user,
    assignedSpace: space,
    createdBy: user,
    modifiedBy: user,
  },
  {
    processStrategy: (value) => {
      let { documentInstances } = value

      if (Array.isArray(documentInstances)) {
        documentInstances = documentInstances.map((doc) => {
          const originalId = doc.id
          const id = originalId || uuid()
          const generatedId = !originalId
          return { ...doc, id, generatedId }
        })
      }

      return { ...value, documentInstances }
    },
  },
)

export const eventsArray = new schema.Array(event)
export const eventsList = { data: eventsArray }
export const events = {
  appointments: eventsArray,
  reminders: eventsArray,
}

export const schedules = {
  appointments: eventsArray,
  busyTimes: eventsArray,
  persons: userArray,
}

export const vital = new schema.Entity(
  'vitals',
  {},
  {
    // old format vitalRecord: {vitalObj: {id}}
    // new format vitalRecord: {...vitalObj, id}
    // TO DO: delete value.vital?.id after PR-529 will be done
    idAttribute: (value) => value.vital?.id || value.id,
    processStrategy: ({ vital: vitalObj, ...rest }) => ({
      ...rest,
      ...vitalObj,
    }),
  },
)
export const vitalsArray = new schema.Array(vital)
export const vitalsList = new schema.Object({
  data: vitalsArray,
})

export const addendum = new schema.Entity('addendums', {
  person: user,
})
export const addendumsArray = new schema.Array(addendum)

export const SOAP = new schema.Object({
  vetTechs: [user],
  veterinarians: [user],
  petParents: [user],
  client: user,
  patient,
  fullAppointment: event,
  vitals: vitalsArray,
  invoice,
  soapData: {
    soap: {
      addendums: addendumsArray,
    },
  },
})

export const paymentsList = {
  data: new schema.Array(
    {
      Payment: payment,
      Invoice: invoice,
      GoPayment: goPayment,
      GoStripePayment: goStripePayment,
    },
    'type',
  ),
}

export const labTest = new schema.Entity('labTests')
export const labTestsList = {
  data: new schema.Array(labTest),
}

export const bundle = new schema.Entity(
  'bundles',
  {},
  {
    processStrategy: (entity) => ({
      minAge: null,
      maxAge: null,
      minWeight: null,
      maxWeight: null,
      ...entity,
    }),
  },
)
export const bundlesList = {
  data: new schema.Array(bundle),
}

export const question = new schema.Entity('questions')
export const questionsArray = new schema.Array(question)
export const questionsList = {
  data: questionsArray,
}

export const wellnessPlanVersion = new schema.Entity('wellnessPlansVersions')
export const wellnessPlansVersionsList = {
  data: new schema.Array(wellnessPlanVersion),
}

export const wellnessPlanBusinessSettings = {
  mappedVersion: wellnessPlanVersion,
}

export const patientMembership = new schema.Entity(
  'patientMemberships',
  {
    patient,
  },
  {
    idAttribute: (value) => value.patient.id,
  },
)
export const patientMembershipsList = {
  data: new schema.Array(patientMembership),
}

export const membershipPayment = new schema.Entity(
  'membershipPayments',
  {
    patient,
  },
  {
    idAttribute: (value) => getMembershipPaymentId(value),
  },
)
export const membershipPaymentsList = {
  data: new schema.Array(membershipPayment),
}

export const globalInventoryItem = new schema.Entity('globalInventoryItems')
export const globalInventoryItemsList = {
  data: new schema.Array(globalInventoryItem),
}

export const reminderProtocolGroup = new schema.Entity(
  'reminderProtocolGroups',
  {
    createdBy: user,
    modifiedBy: user,
  },
)

export const reminderProtocolGroupsList = {
  data: new schema.Array(reminderProtocolGroup),
}

export const reminder = new schema.Entity('reminders', {
  patient,
})
export const remindersArray = new schema.Array(reminder)
export const remindersGroup = new schema.Entity(
  'remindersGroups',
  { reminders: remindersArray },
  { idAttribute: getReminderGroupName },
)
export const remindersGroupsList = {
  data: new schema.Array(remindersGroup),
}
export const remindersList = {
  data: new schema.Array(reminder),
}

export const document = new schema.Entity('documents')
export const documentsArray = new schema.Array(document)
export const documentsList = {
  data: documentsArray,
}

export const timeEntity = new schema.Entity('timeEntities')
export const timeEntities = new schema.Array(timeEntity)
export const timeEntitiesList = {
  data: timeEntities,
}

export const contact = new schema.Entity('contacts')
export const contactsArray = new schema.Array(contact)
export const contactsList = {
  data: contactsArray,
}

export const labVendorConfig = new schema.Entity(
  'labVendorConfigs',
  {},
  { idAttribute: 'vendorId' },
)
export const labVendorConfigList = new schema.Array(labVendorConfig)

export const imagingVendorConfig = new schema.Entity(
  'imagingVendorConfigs',
  {},
  { idAttribute: 'vendorId' },
)
export const imagingVendorConfigList = new schema.Array(imagingVendorConfig)

export const imagingOrder = new schema.Entity('imagingOrder')
export const imagingOrdersArray = new schema.Array(imagingOrder)
export const imagingOrdersList = { data: imagingOrdersArray }

export const imagingVendorOrders = new schema.Entity(
  'imagingVendorOrders',
  {
    orders: imagingOrdersArray,
  },
  { idAttribute: 'vendorId' },
)
export const imagingVendorOrdersList = new schema.Array(imagingVendorOrders)

export const order = new schema.Entity('orders')

export const task = new schema.Entity('tasks', {
  order,
  assigned: user,
  client: user,
  appointment: event,
  createdBy: user,
  modifiedBy: user,
  patient,
  contact,
  notifications: notificationItemsArray,
})
export const taskArray = new schema.Array(task)
export const tasksList = {
  data: taskArray,
}
task.define({ children: taskArray })

const soapTask = new schema.Entity('tasks', {
  assigned: user,
})

export const wsSoapTaskData = {
  tasks: new schema.Array(soapTask),
}

export const conversationFile = new schema.Entity('files')
export const conversationFileArray = new schema.Array(conversationFile)

export const message = new schema.Entity('messages', {
  files: conversationFileArray,
})
export const messageArray = new schema.Array(message)
export const messagesList = {
  data: messageArray,
}

export const conversation = new schema.Entity('conversations', {
  messages: messageArray,
  assignee: user,
  client: user,
  contact,
  patient,
  notifications: notificationItemsArray,
})
export const conversationsArray = new schema.Array(conversation)
export const conversationsList = {
  data: conversationsArray,
}

const problemLog = new schema.Entity(
  'problemLogsMap',
  {},
  {
    idAttribute: (value, parent) =>
      getLogKeyFromLogs(value.entity?.problemId, parent.bodySystemId),
    processStrategy: (value, parent) => ({
      ...value,
      key: getLogKeyFromLogs(value.entity?.problemId, parent.bodySystemId),
    }),
  },
)

const problemLogsList = new schema.Array(problemLog)

const bodySystemLog = new schema.Entity(
  'bodySystemLogsMap',
  {},
  {
    idAttribute: (value) => value.entity.bodySystemId,
    processStrategy: (value) => ({
      ...value,
      key: value.entity.bodySystemId,
    }),
  },
)

const bodySystemLogsList = new schema.Array(bodySystemLog)

bodySystemLog.define({ entity: { problemLogs: problemLogsList } })

export const problemsLog = {
  bodySystemLogs: bodySystemLogsList,
}

const problem = new schema.Entity(
  'problems',
  {},
  {
    idAttribute: (value, parent) =>
      getProblemKey({
        ...value,
        level: !value.primaryId ? 1 : parent.level + 1,
      }),
    processStrategy: (value, parent) => ({
      ...value,
      key: getProblemKey({
        ...value,
        level: !value.primaryId ? 1 : parent.level + 1,
      }),
      level: !value.primaryId ? 1 : parent.level + 1,
      logKey: value.type ? getLogKeyFromCatalog(value) : null,
    }),
  },
)
const problemList = new schema.Array(problem)

problem.define({ children: problemList })

const problemEnum = new schema.Entity('enums')
const problemEnumList = new schema.Array(problemEnum)

export const problemsData = {
  problems: problemList,
  enums: problemEnumList,
}

const problemSearchItem = new schema.Entity(
  'foundedProblems',
  {},
  {
    idAttribute: (value) => getLogKeyFromLogs(value.id, value.bodySystemId),
    processStrategy: (value) => ({
      ...value,
      key: getLogKeyFromLogs(value.id, value.bodySystemId),
      logKey: getLogKeyFromCatalog(value),
    }),
  },
)
const problemSearchItemsArray = new schema.Array(problemSearchItem)

export const searchProblemsList = {
  data: problemSearchItemsArray,
}

const problemLogHistory = new schema.Entity(
  'problemLogHistory',
  {},
  {
    idAttribute: (value) => value.date,
  },
)
const problemLogHistoryArray = new schema.Array(problemLogHistory)

export const problemLogHistoryList = {
  data: problemLogHistoryArray,
}

export const timelineVital = new schema.Entity('vitals')

const timelineEntryTypeMap: Partial<Record<TimelineEntryType, Schema>> = {
  [TimelineEntryType.IMAGING]: {
    imagingOrder,
    assignedVet: user,
  },
  [TimelineEntryType.TASK]: task,
  [TimelineEntryType.REMINDER]: reminder,
  [TimelineEntryType.VITAL]: timelineVital,
  [TimelineEntryType.APPOINTMENT]: {
    addendums: addendumsArray,
  },
}

export const timelineEntry = {
  entries: (entity: { type: TimelineEntryType }) =>
    new schema.Array(timelineEntryTypeMap[entity.type] as Schema),
  administeredBy: user,
  createdBy: user,
  modifiedBy: user,
  person: user,
}
export const timeline = {
  data: [timelineEntry],
}

export const treatmentsSchedule = {
  appointment: event,
  tasks: taskArray,
}

export const treatmentsScheduleArray = new schema.Array(treatmentsSchedule)

export const shipmentItem = new schema.Entity('shipmentItems')
export const shipmentItemArray = new schema.Array(shipmentItem)
export const shipmentItemList = { data: shipmentItemArray }

export const adjustment = new schema.Entity('adjustments')
export const adjustmentArray = new schema.Array(adjustment)
export const adjustmentList = { data: adjustmentArray }

export const shipment = new schema.Entity('shipments')
export const shipmentsArray = new schema.Array(shipment)
export const shipmentsList = { data: shipmentsArray }

export const inventoryOrder = new schema.Entity('inventoryOrders')
export const inventoryOrdersArray = new schema.Array(inventoryOrder)
export const inventoryOrdersList = { data: inventoryOrdersArray }

export const benchmarkingKey = new schema.Entity(
  'benchmarkingKeys',
  {},
  {
    idAttribute: 'key',
    processStrategy: ({ key, ...rest }) => ({
      ...rest,
      id: key,
    }),
  },
)
export const benchmarkingKeyArray = new schema.Array(benchmarkingKey)
export const benchmarkingKeyList = { data: benchmarkingKeyArray }

export const shipmentImportSession = new schema.Entity('shipmentImportSession')
export const shipmentImportItem = new schema.Entity('shipmentImportItem')
export const shipmentImportItemArray = new schema.Array(shipmentImportItem)
export const shipmentImportItemList = { data: shipmentImportItemArray }

export const importSession = new schema.Entity('importSession')
export const importSessionArray = new schema.Array(importSession)

export const importRequest = {
  data: [new schema.Entity('importRequest')],
}

export const importTask = {
  data: [new schema.Entity('importTask')],
}

export const importMapping = new schema.Entity('importMapping')
export const importMappingArray = new schema.Array(importMapping)
export const importMappingList = { data: importMappingArray }

export const practiceExportSession = new schema.Entity(
  'practiceCloningSession',
  {},
  { idAttribute: 'exportId' },
)
export const practiceExportSessionArray = new schema.Array(
  practiceExportSession,
)
export const practiceExportSessionList = { data: practiceExportSessionArray }

export const practiceImportSession = new schema.Entity(
  'practiceCloningSession',
  {},
  { idAttribute: 'importId' },
)

export const labTestsDashboardRecord = new schema.Entity(
  'labTestsDashboardRecords',
  {
    client: user,
    patient,
  },
  {
    idAttribute: 'identifier',
    processStrategy: (value) => ({
      ...value,
      rawClient: R.isNil(value.client?.id) ? value.client : {},
      rawPatient: R.isNil(value.patient?.id) ? value.patient : {},
    }),
  },
)
export const labTestsDashboardRecordsArray = new schema.Array(
  labTestsDashboardRecord,
)
export const labTestsDashboardRecordsList = {
  data: labTestsDashboardRecordsArray,
}

export const imagingDashboardRecord = new schema.Entity(
  'imagingDashboardRecord',
  {
    client: user,
    patient,
    assignedVet: user,
  },
)
export const imagingDashboardRecordArray = new schema.Array(
  imagingDashboardRecord,
)
export const imagingDashboardRecordList = { data: imagingDashboardRecordArray }

export const onHandVariation = new schema.Entity('onHandVariations')
export const onHandVariationsArray = new schema.Array(onHandVariation)
export const onHandVariationsList = { data: onHandVariationsArray }

export const onHandHistory = new schema.Entity('onHandHistories')
export const onHandHistoryArray = new schema.Array(onHandHistory)
export const onHandHistoryList = { data: onHandHistoryArray }

export const marketplaceItem = new schema.Entity('marketplaceItems')
export const marketplaceItemsArray = new schema.Array(marketplaceItem)
export const marketplaceItemsList = { data: marketplaceItemsArray }

export const marketplaceIFrame = new schema.Entity('marketplaceIFrames')
export const marketplaceIFrameItemsList = new schema.Array(marketplaceIFrame)

export const widgetData = new schema.Entity('widgetData')
export const widgetDataItemsArray = new schema.Array(widgetData)
export const widgetDataItemsList = { data: widgetDataItemsArray }

export const widgetsData = {
  clients: userArray,
  widgetData: {
    [LandingWidgetName.OPEN_SOAPS]: {
      page: eventsList,
    },
    [LandingWidgetName.UPCOMING]: {
      page: eventsList,
    },
    [LandingWidgetName.ADMITTED]: {
      page: eventsList,
    },
    [LandingWidgetName.MY_TASKS]: {
      page: tasksList,
    },
    [LandingWidgetName.COMMUNICATIONS]: {
      page: conversationsList,
    },
    [LandingWidgetName.LABS]: {
      page: labTestsDashboardRecordsList,
    },
    [LandingWidgetName.TIME_TRACKING]: {
      page: timeEntitiesList,
    },
    [SnapshotsAliasTypes.Reminders]: {
      page: remindersList,
    },
    [SnapshotsAliasTypes.Appointments]: {
      page: eventsList,
    },
  },
}

export const bulkPrice = new schema.Entity('bulkPrices')
export const bulkVariation = new schema.Entity('bulkVariations', {
  prices: [bulkPrice],
})
const bulkVariationArray = new schema.Array(bulkVariation)
export const bulkPriceVariationsList = {
  data: bulkVariationArray,
}

export const bulkPricePreview = new schema.Entity('bulkPricesPreview')
export const bulkVariationPreview = new schema.Entity('bulkVariationsPreview', {
  prices: [bulkPricePreview],
})
const bulkVariationPreviewArray = new schema.Array(bulkVariationPreview)
export const bulkPriceVariationsPreviewList = {
  data: bulkVariationPreviewArray,
}

export const bulkPriceSession = {
  creator: user,
}

export const discountGroup = new schema.Entity('discountGroups')
export const discountGroupsArray = new schema.Array(discountGroup)

export const department = new schema.Entity('departments')
export const departmentItem = {
  data: department,
}
export const departmentsArray = new schema.Array(department)
export const departmentsList = {
  data: departmentsArray,
}

export const connectedDevice = new schema.Entity('connectedDevices')
export const connectedDevicesArray = new schema.Array(connectedDevice)

export const rhapsodyPayConfig = new schema.Entity(
  'rhapsodyPayConfigs',
  {},
  { idAttribute: 'businessId' },
)

export const chargeSheetSubItem = new schema.Entity(
  'chargeSheetSubItems',
  {},
  {
    idAttribute: ({ eventId, patientId, soapId, id }) =>
      id || `${patientId}_${eventId}_${soapId}`,
  },
)

export const invoiceSubItem = new schema.Entity(
  'invoiceV3SubItems',
  {},
  {
    idAttribute: (value) =>
      value.id ? value.id : `${value.soapId}_${value.patientId}`,
  },
)

export const invoiceV3Event = new schema.Entity('invoiceV3Events', {})

export const invoicesV3 = new schema.Entity('invoicesV3', {
  client: user,
  patients: [patient],
  persons: [userItem],
  events: [eventItem],
  groups: [invoiceSubItem],
})

export const clientWithChargeSheet = {
  chargeSheet: {
    events: [eventItem],
    persons: [userItem],
    sections: [chargeSheetSubItem],
  },
  patients: [patient],
}

export const prescriptionV2 = new schema.Entity('prescriptionsV2')

const unmatchedPetSchema = new schema.Entity(
  'unmatchedPets',
  {},
  { idAttribute: 'name' },
)
const unmatchedPatientsSchema = new schema.Entity(
  'unmatchedPatients',
  {},
  { idAttribute: 'rhapsodyId' },
)
const matchedPetsSchema = new schema.Entity(
  'matchedPets',
  {},
  {
    idAttribute: (value) =>
      `${value.rhapsodyPatient.rhapsodyId}-${value.chewyPet.name}`,
  },
)

export const matchChewyPetsSchema = new schema.Object({
  unmatchedPets: new schema.Array(unmatchedPetSchema),
  unmatchedPatients: new schema.Array(unmatchedPatientsSchema),
  matchedPets: new schema.Array(matchedPetsSchema),
})
