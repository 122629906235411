import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { Button, Fab, Grid, Hidden, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import * as R from 'ramda'
import {
  Defaults,
  NumberUtils,
  PermissionArea,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { ContextualBackButton } from '~/components/common/buttons/ContextualBackButton'
import { InvoicesInfiniteLoaderList } from '~/components/dashboard/clients/balance/infiniteLoaderList/InvoicesInfiniteLoaderList'
import { PaymentsInfiniteLoaderList } from '~/components/dashboard/clients/balance/infiniteLoaderList/PaymentsInfiniteLoaderList'
import ExpandedWidget from '~/components/dashboard/landing/widgets/ExpandedWidget'
import useEstimateAlertDialog from '~/components/dashboard/soapV2/estimates/utils/useEstimateAlertDialog'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18nPortal from '~/locales/i18n'
import { fetchClient } from '~/store/actions/clients'
import { fetchBalance } from '~/store/actions/users'
import {
  getBillingActivitySummary,
  getDefaultInvoiceStatusesIds,
  getDefaultRefundInvoiceStatusesIds,
  getEstimateLoading,
  getEstimateLoadingMore,
  getInvoiceLoading,
  getInvoiceLoadingMore,
  getPaymentLoading,
  getPaymentLoadingMore,
  getUnappliedPaymentLoading,
  getUnappliedPaymentLoadingMore,
  getUnpaidInvoiceLoading,
  getUnpaidInvoiceLoadingMore,
  refreshClientBillingData,
} from '~/store/duck/clientBillingActivityData'
import {
  fetchOpenChargeSheet,
  getOpenChargeSheet,
} from '~/store/duck/clientFinanceData'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { BillingActivitySectionName } from '~/types/entities/clients'
import { isRhapsodyGoAvailableForPractice } from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'

import ClientAndPatientSection from '../details/new-client-and-patient/ClientAndPatientSection'
import ClientInfoLinkType from '../details/new-client-and-patient/ClientInfoLinkType'
import DetailsBackButton from '../DetailsBackButton'
import { BillingActivity } from './BillingActivity'
import { EstimatesInfiniteLoaderList } from './infiniteLoaderList/EstimatesInfiniteLoaderList'

const useStyles = makeStyles(
  (theme) => {
    const alignCell = {
      maxWidth: '100%',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    }

    return {
      root: {},
      button: {
        minWidth: 160,
        height: 40,
      },
      inheritButton: {
        boxShadow:
          '0 2px 2px 0 rgba(46,153,168,0.14), 0 1px 5px 0 rgba(53,177,194,0.12)',
      },
      invoiceButton: {
        minWidth: 165,
        whiteSpace: 'nowrap',
      },
      roundedButton: {
        borderRadius: 24,
        textTransform: 'none',
        fontWeight: 500,
      },
      header: {
        boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.10)',
      },
      backButton: {
        paddingLeft: theme.spacing(2),
      },
      expandedWidget: {
        border: theme.constants.tabBorder,
        minHeight: 'auto',
      },
      expandedWidgetHeader: {
        borderBottom: theme.constants.tabBorder,
        padding: theme.spacing(1),
        height: 'auto',
      },
      table: {
        border: '0 !important',
      },
      estimateCell: {
        '&:nth-child(4)': alignCell,
        '&:nth-child(5)': alignCell,
        '&:nth-child(6)': alignCell,
      },
      invoiceCell: {
        '&:nth-child(6)': alignCell,
      },
      paymentCell: {
        '&:nth-child(7)': alignCell,
      },
    }
  },
  { name: 'BalancePage' },
)

type BalancePageParams = {
  clientId: string
  eventId?: string
  invoiceId?: string
  patientId: string
  paymentId: string
}

type BalancePageLocationState = {
  patientId?: string
}

export type ClientBillingActivityWidgetFilters = {
  invoiceStateIds?: string[]
  paymentLabelAndAmount?: boolean
  refundStateIds?: string[]
  unapplied?: boolean
  unpaid?: boolean
}

interface Section {
  Component: React.JSXElementConstructor<any>
  ComponentProps: object
  isLoading: boolean
  name: string
}

export const BalancePageV2 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { clientId, patientId } = useParams() as BalancePageParams
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Tooltips'])
  const [searchParams, setSearchParams] = useSearchParams()

  const client = useSelector(getUser(clientId))
  const business = useSelector(getCurrentBusiness)
  const rhapsodyPayConfig =
    useSelector(getRhapsodyPayConfig(business?.id)) || {}
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const paymentPermissions = useSelector(getCRUDByArea(PermissionArea.PAYMENT))
  const creditAdjustmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.CREDIT_ADJUSTMENT),
  )
  const isIpoM0CreditAdjustmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_CREDIT_ADJUSTMENT),
  )
  const isContextualBackButtonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CONTEXTUAL_BACK_BUTTON),
  )
  const isFetchingInvoicesData = useSelector(getInvoiceLoading)
  const isFetchingMoreInvoicesData = useSelector(getInvoiceLoadingMore)
  const isFetchingUnpaidInvoicesData = useSelector(getUnpaidInvoiceLoading)
  const isFetchingMoreUnpaidInvoicesData = useSelector(
    getUnpaidInvoiceLoadingMore,
  )
  const isFetchingEstimatesData = useSelector(getEstimateLoading)
  const isFetchingMoreEstimatesData = useSelector(getEstimateLoadingMore)
  const isFetchingPaymentsData = useSelector(getPaymentLoading)
  const isFetchingMorePaymentsData = useSelector(getPaymentLoadingMore)
  const isFetchingUnappliedPaymentsData = useSelector(
    getUnappliedPaymentLoading,
  )
  const isFetchingMoreUnappliedPaymentsData = useSelector(
    getUnappliedPaymentLoadingMore,
  )
  const invoiceStateFilters = useSelector(getDefaultInvoiceStatusesIds())
  const refundedInvoiceStateFilters = useSelector(
    getDefaultRefundInvoiceStatusesIds(),
  )
  const { balance } = useSelector(getBillingActivitySummary)

  const balanceNegative = balance < 0
  const expandedQuery = searchParams.get(
    'expanded',
  ) as BillingActivitySectionName

  const getFiltersFromSearchParams = () => {
    const {
      invoiceStateIds,
      refundStateIds,
      unpaid,
      paymentLabelAndAmount,
      unapplied,
    } = parse(location.search, {
      parseBooleans: true,
      arrayFormat: 'comma',
    }) as ClientBillingActivityWidgetFilters & {
      businessId?: string
      expanded?: boolean
    }

    const pageFilters: ClientBillingActivityWidgetFilters = {
      invoiceStateIds: invoiceStateIds || [],
      refundStateIds: refundStateIds || [],
      unpaid,
      paymentLabelAndAmount,
      unapplied,
    }

    return R.reject(R.isNil, pageFilters)
  }
  const filtersQuery = useMemo(
    () =>
      isContextualBackButtonEnabled && expandedQuery
        ? {
            [BillingActivitySectionName.ESTIMATES]:
              expandedQuery === BillingActivitySectionName.ESTIMATES
                ? getFiltersFromSearchParams()
                : {},
            [BillingActivitySectionName.INVOICES]:
              expandedQuery === BillingActivitySectionName.INVOICES
                ? getFiltersFromSearchParams()
                : {},
            [BillingActivitySectionName.PAYMENTS]:
              expandedQuery === BillingActivitySectionName.PAYMENTS
                ? getFiltersFromSearchParams()
                : {},
          }
        : {
            [BillingActivitySectionName.ESTIMATES]: {},
            [BillingActivitySectionName.INVOICES]: {},
            [BillingActivitySectionName.PAYMENTS]: {},
          },
    [isContextualBackButtonEnabled, expandedQuery],
  )
  const [expandedWidgetKey, setExpandedWidgetKey] = useState<
    BillingActivitySectionName | undefined
  >()
  const [filters, setFilters] = useState<
    Record<BillingActivitySectionName, ClientBillingActivityWidgetFilters>
  >({
    [BillingActivitySectionName.ESTIMATES]: {},
    [BillingActivitySectionName.INVOICES]: {},
    [BillingActivitySectionName.PAYMENTS]: {},
  })

  const clearFilters = () => {
    searchParams.delete('expanded')

    const currentFilters = getFiltersFromSearchParams()
    const WIDGET_FILTERS: (keyof ClientBillingActivityWidgetFilters)[] = [
      'invoiceStateIds',
      'refundStateIds',
      'unpaid',
      'paymentLabelAndAmount',
      'unapplied',
    ]

    Object.keys(currentFilters)
      .filter((f) =>
        WIDGET_FILTERS.includes(f as keyof ClientBillingActivityWidgetFilters),
      )
      .forEach((filterKey) => {
        searchParams.delete(filterKey)
      })

    setSearchParams(searchParams, { replace: true })
  }

  const toggleExpand = (
    sectionKey: BillingActivitySectionName,
    newFilters?: ClientBillingActivityWidgetFilters,
  ) => {
    if (isContextualBackButtonEnabled) {
      searchParams.set('expanded', sectionKey)

      if (newFilters && !R.isEmpty(newFilters)) {
        Object.entries(newFilters).forEach(([key, value]) =>
          searchParams.set(key, value.toString() as string),
        )
      }

      setSearchParams(searchParams, { replace: true })
    } else {
      setExpandedWidgetKey(sectionKey)
      window.history.pushState({}, '', window.location.href)
      window.scrollTo({ top: 0, behavior: 'smooth' })

      if (newFilters) {
        setFilters({
          ...filters,
          [sectionKey]: newFilters,
        })
      }
    }
  }

  useEffect(() => {
    const handlePopState = () => {
      if (isContextualBackButtonEnabled) {
        clearFilters()
      } else {
        setExpandedWidgetKey(undefined)
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (!isContextualBackButtonEnabled) {
      return
    }

    if (expandedQuery == null) {
      clearFilters()
      return
    }

    if (expandedQuery) {
      toggleExpand(expandedQuery, getFiltersFromSearchParams())
    }
  }, [expandedQuery])

  const { setAttachingToSoapEstimateId, setSoapToAttachEstimateId } =
    useEstimateAlertDialog({
      fromSoap: false,
      fromTimeline: false,
      fromOther: true,
    })

  const isLoadingInvoices =
    isFetchingUnpaidInvoicesData ||
    isFetchingMoreUnpaidInvoicesData ||
    isFetchingInvoicesData ||
    isFetchingMoreInvoicesData
  const isLoadingEstimates =
    isFetchingEstimatesData || isFetchingMoreEstimatesData
  const isLoadingPayments =
    isFetchingUnappliedPaymentsData ||
    isFetchingMoreUnappliedPaymentsData ||
    isFetchingPaymentsData ||
    isFetchingMorePaymentsData

  const locationState = location.state as BalancePageLocationState

  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    business,
    rhapsodyPayConfig,
  )

  useEffect(() => {
    if (!client && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  // Ensure RhapsodyPayConfig is fetched
  useEffect(() => {
    dispatch(fetchRhapsodyPayConfig(business?.id || '', true))
  }, [])

  // TODO: Add new polling action when ready
  useInterval(() => {
    if (
      !hasOpenDialogs &&
      clientId &&
      business?.id &&
      paymentPermissions.read
    ) {
      dispatch(fetchBalance(clientId))
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const [openPaymentRequest] = useDialog(DialogNames.PAYMENT_REQUEST)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openAddClientPaymentDialog, , isAddClientPaymentDialogOpened] =
    useDialog(DialogNames.ADD_CLIENT_PAYMENT)
  const [openCreditAdjustmentDialog] = useDialog(
    DialogNames.CREDIT_ADJUSTMENT_DIALOG,
  )

  const onBackButtonClick = () => {
    const url = locationState?.patientId
      ? `/client/${clientId}/patient/${locationState?.patientId}`
      : `/client/${clientId}`

    navigate(url)
  }
  const refreshData = () => dispatch(refreshClientBillingData())
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE, refreshData)
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)
  const onClickEstimate = (estimate: GraphqlEstimate) => {
    openInvoice({
      clientId,
      patientId: estimate.patientId,
      invoiceId: estimate.id,
      isEstimate: true,
      soapBusinessId: estimate.businessId,
      newEstimateFlow: true,
      setAttachingToSoapEstimateId,
      setSoapToAttachEstimateId,
    })
  }

  const expandedWidgetSectionMap: Record<BillingActivitySectionName, Section> =
    {
      [BillingActivitySectionName.INVOICES]: {
        Component: InvoicesInfiniteLoaderList,
        ComponentProps: {
          classes: {
            table: classes.table,
            tableCell: classes.invoiceCell,
          },
          invoiceStateIds: invoiceStateFilters,
          refundStateIds: refundedInvoiceStateFilters,
          clientId,
          ...(isContextualBackButtonEnabled
            ? filtersQuery[BillingActivitySectionName.INVOICES]
            : filters[BillingActivitySectionName.INVOICES]),
        },
        name: i18nPortal.t('Common:INVOICES'),
        isLoading: isLoadingInvoices,
      },
      [BillingActivitySectionName.ESTIMATES]: {
        Component: EstimatesInfiniteLoaderList,
        ComponentProps: {
          classes: {
            table: classes.table,
            tableCell: classes.estimateCell,
          },
          clientId,
          onClickEstimate,
          ...(isContextualBackButtonEnabled
            ? filtersQuery[BillingActivitySectionName.ESTIMATES]
            : filters[BillingActivitySectionName.ESTIMATES]),
        },
        name: i18nPortal.t('Common:ESTIMATES_BUDGET'),
        isLoading: isLoadingEstimates,
      },
      [BillingActivitySectionName.PAYMENTS]: {
        Component: PaymentsInfiniteLoaderList,
        ComponentProps: {
          classes: {
            table: classes.table,
            tableCell: classes.paymentCell,
          },
          clientId,
          onClickEstimate,
          ...(isContextualBackButtonEnabled
            ? filtersQuery[BillingActivitySectionName.PAYMENTS]
            : filters[BillingActivitySectionName.PAYMENTS]),
        },
        name: i18nPortal.t('Common:PAYMENTS.PAYMENTS'),
        isLoading: isLoadingPayments,
      },
    }
  const currentExpandedWidget =
    isContextualBackButtonEnabled && expandedQuery
      ? expandedWidgetSectionMap[expandedQuery]
      : expandedWidgetKey
        ? expandedWidgetSectionMap[expandedWidgetKey]
        : undefined

  useInterval(() => {
    if (clientId) {
      dispatch(fetchOpenChargeSheet({ clientId }))
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  useEffect(() => {
    if (clientId) {
      dispatch(fetchOpenChargeSheet({ clientId }))
    }
  }, [clientId])

  const openChargeSheet = useSelector(getOpenChargeSheet)
  const openChargeSheetBalance = openChargeSheet
    ? openChargeSheet.subTotal -
      openChargeSheet.totalDiscount +
      openChargeSheet.totalTax
    : 0

  const onOpenChargeButtonClick = () => {
    const url = `/chargesheet/${clientId}`

    navigate(url)
  }

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <Hidden mdDown>
        <Grid item>
          <ClientAndPatientSection
            clientId={clientId}
            highlight={ClientInfoLinkType.BALANCE}
            patientId={patientId}
          />
        </Grid>
      </Hidden>
      <Grid container item xs direction="column" wrap="nowrap">
        {!currentExpandedWidget && (
          <>
            {isContextualBackButtonEnabled ? (
              <ContextualBackButton
                buttonText={t('Common:CLIENT_AND_PATIENT_DETAIL')}
                navigateBackToContext={onBackButtonClick}
              />
            ) : (
              <DetailsBackButton
                fullWidth
                thin
                className={classes.backButton}
                onClick={onBackButtonClick}
              >
                {t('Common:CLIENT_AND_PATIENT_DETAIL')}
              </DetailsBackButton>
            )}
          </>
        )}
        <Grid
          container
          item
          xs
          direction="column"
          position="relative"
          wrap="nowrap"
        >
          {currentExpandedWidget ? (
            <ExpandedWidget
              classes={{
                root: classes.expandedWidget,
                header: classes.expandedWidgetHeader,
              }}
              disabled={currentExpandedWidget.isLoading}
              name={currentExpandedWidget.name}
              widgetComponent={currentExpandedWidget.Component}
              widgetComponentProps={currentExpandedWidget.ComponentProps}
              onClose={() => {
                if (isContextualBackButtonEnabled) {
                  clearFilters()
                } else {
                  setExpandedWidgetKey(undefined)
                }
              }}
            />
          ) : (
            <>
              <Grid
                container
                alignItems="flex-start"
                className={classes.header}
                direction={{ sm: 'column', md: 'row' }}
                px={3}
                py={2}
                wrap="nowrap"
              >
                <Text variant="h1">{t('Common:CLIENT_BALANCE_ACTIVITY')}</Text>
                <Grid
                  container
                  item
                  xs
                  columnSpacing={2}
                  justifyContent="flex-end"
                  mt={{ xs: 1, md: 0 }}
                  rowSpacing={{ xs: 2, md: 0.01 }}
                >
                  {isIpoM0CreditAdjustmentEnabled
                    ? creditAdjustmentPermissions.create && (
                        <Grid item>
                          <Fab
                            className={classNames(
                              classes.inheritButton,
                              classes.button,
                            )}
                            color="inherit"
                            type="submit"
                            variant="extended"
                            onClick={() =>
                              openCreditAdjustmentDialog({ clientId })
                            }
                          >
                            {t('Common:CREDIT_ADJUSTMENT')}
                          </Fab>
                        </Grid>
                      )
                    : paymentPermissions.create && (
                        <Grid item>
                          <Button
                            className={classNames(
                              classes.button,
                              classes.roundedButton,
                            )}
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              openAddPaymentDialog({
                                clientId,
                                ComponentProps: { label: 'Adjustment' },
                                isAdjustment: true,
                              })
                            }
                          >
                            {t('Common:ADJUSTMENT')}
                          </Button>
                        </Grid>
                      )}
                  {paymentPermissions.create &&
                    isGoAvailableForPractice &&
                    (balanceNegative ? (
                      <Grid item>
                        <Tooltip
                          aria-label="add"
                          placement="top"
                          title={
                            t('Tooltips:REQUEST_PAYMENT_NEGATIVE_BALANCE') || ''
                          }
                        >
                          <span>
                            <Fab
                              className={classNames(
                                classes.inheritButton,
                                classes.button,
                              )}
                              color="inherit"
                              disabled={balance < 0}
                              type="submit"
                              variant="extended"
                              onClick={() =>
                                openPaymentRequest({
                                  balancePayment: true,
                                  clientId,
                                })
                              }
                            >
                              {t('Common:REQUEST_PAYMENT')}
                            </Fab>
                          </span>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Fab
                          className={classNames(
                            classes.inheritButton,
                            classes.button,
                          )}
                          color="inherit"
                          disabled={balance < 0}
                          type="submit"
                          variant="extended"
                          onClick={() =>
                            openPaymentRequest({
                              balancePayment: true,
                              clientId,
                            })
                          }
                        >
                          {t('Common:REQUEST_PAYMENT')}
                        </Fab>
                      </Grid>
                    ))}
                  {paymentPermissions.create && (
                    <Grid item>
                      <Fab
                        className={classNames(
                          classes.inheritButton,
                          classes.button,
                        )}
                        color="inherit"
                        type="submit"
                        variant="extended"
                        onClick={() =>
                          openAddClientPaymentDialog({
                            clientId,
                            onClickEstimate,
                          })
                        }
                      >
                        {t('Common:ADD_PAYMENT')}
                      </Fab>
                    </Grid>
                  )}
                  <Grid item>
                    <Fab
                      className={classNames(
                        classes.inheritButton,
                        classes.button,
                      )}
                      color={
                        openChargeSheetBalance === 0 ? 'inherit' : 'warning'
                      }
                      type="submit"
                      variant="extended"
                      onClick={onOpenChargeButtonClick}
                    >
                      {`${t(
                        'Common:OPEN_CHARGE_SHEET',
                      )}: ${NumberUtils.formatMoney(openChargeSheetBalance)}`}
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <BillingActivity
                  clientId={clientId}
                  expandedWidget={
                    isContextualBackButtonEnabled
                      ? expandedQuery
                      : expandedWidgetKey
                  }
                  isAddClientPaymentDialogOpened={
                    isAddClientPaymentDialogOpened
                  }
                  onClickEstimate={onClickEstimate}
                  onExpand={toggleExpand}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
