/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { Nil, PuiTooltip, Text } from '@pbt/pbt-ui-components'
import { ChewySubmark, Info } from '@pbt/pbt-ui-components/src/icons'

import {
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundChargesSection,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import Typography from '~/components/elements/Typography/Typography'
import { AppointmentState } from '~/constants/appointmentStates'
import DialogNames from '~/constants/DialogNames'
import i18nPortal from '~/locales/i18n'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'
import { getNoShowCancellationPenaltyRefundEnabled } from '~/store/reducers/constants'
import { Invoice } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'
import { isRefundInvoice } from '~/utils/refundUtils'
import useDialog from '~/utils/useDialog'
import { useAppointmentStateId } from '~/utils/useEventType'

import { MultipleLinesCell } from './MultipleLinesCell'

const StyledInfoIcon = styled(Info)(({ theme }) => ({
  color: theme.colors.link,
  cursor: 'pointer',
  margin: theme.spacing(1),
}))

const StyledAppointmentStateLabel = styled(AppointmentStateLabel)(
  ({ theme }) => ({
    marginLeft: 'auto',
    marginRight: theme.spacing(1.5),
    fontSize: '1.2rem',
  }),
)

const useStyles = makeStyles(
  () => ({
    appointmentStatusBox: {
      alignItems: 'center',
      display: 'flex',
      gap: 0.5,
      justifyContent: 'space-around',
      width: '100%',
    },
  }),
  { name: 'AppointmentPenaltyEventCell' },
)

interface InvoiceTableEventTypeCellProps {
  hideRetailOrder?: boolean
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

interface AppointmentPenaltyEventCellProps {
  sections: RefundChargesSection[] | (ChargesSection | UIRoliSection)[] | null
}

const otcLabel = i18nPortal.t<string>(
  'Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION',
)

const useOpenAppointment = () => {
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const openAppointment = (eventId: string | Nil) => {
    openAppointmentDialog({
      appointmentId: eventId,
      isAppointmentType: true,
    })
  }

  return openAppointment
}

const useShowNoShowAppointmentState = (
  sections: RefundChargesSection[] | (ChargesSection | UIRoliSection)[] | null,
  invoice: Invoice | GraphqlInvoice | RefundInvoice,
) => {
  const isNoShowPenaltyRefundEnabled = useSelector(
    getNoShowCancellationPenaltyRefundEnabled,
  )
  const noShowStateId = useAppointmentStateId(AppointmentState.NO_SHOW)

  if (!isNoShowPenaltyRefundEnabled || !sections || R.isEmpty(sections)) {
    return false
  }

  const { event } = sections?.[0] as ChargesSection

  if (!event) {
    return false
  }

  const isAppointmentPenaltyInvoice =
    Boolean((invoice as Invoice)?.appointmentPenalty) ||
    Boolean((invoice as GraphqlInvoice)?.appointmentPenalty)
  const isNoShowEvent = event && event?.state?.id === noShowStateId

  return (
    isAppointmentPenaltyInvoice &&
    !isRefundInvoice(invoice?.invoiceNo) &&
    isNoShowEvent
  )
}

const InvoiceLineItem = (section: ChargesSection) => {
  const { event } = section
  const openAppointment = useOpenAppointment()
  return event ? (
    <Text link variant="body2" onClick={() => openAppointment(event.id)}>
      {event.type?.name || ''}
    </Text>
  ) : (
    <Text variant="body2">{otcLabel}</Text>
  )
}

const RetailOrderLineItem = (section: UIRoliSection) => {
  const { t } = useTranslation(['Invoices'])
  const getChewyOrderState = useGetChewyOrderState()
  const hasState = 'state' in section && section.state

  if (!hasState) {
    return <></>
  }

  const { isMovedToChewyCart, isOrderPlaced } = getChewyOrderState(
    section.state?.id,
  )
  const chewyTooltip = isOrderPlaced
    ? t('Invoices:CHEWY_ITEMS.ORDER_PLACED_INFORMATION')
    : isMovedToChewyCart
      ? t('Invoices:CHEWY_ITEMS.CHEWY_CART_INFORMATION')
      : t('Invoices:CHEWY_ITEMS.DRAFT_INFORMATION_ALTERNATIVE')

  return (
    <Box
      alignItems="center"
      display="flex"
      gap={0.5}
      justifyContent="space-around"
      width="100%"
    >
      <ChewySubmark />
      <Typography.Paragraph>
        {t('Common:CHEWY_COM_ORDER')}
        {isOrderPlaced ? `: ${section.orderId}` : ''}
      </Typography.Paragraph>
      <PuiTooltip sx={{ ml: 'auto' }} tooltipText={chewyTooltip}>
        <StyledInfoIcon />
      </PuiTooltip>
    </Box>
  )
}

const AppointmentPenaltyEventCell = ({
  sections,
}: AppointmentPenaltyEventCellProps) => {
  const classes = useStyles()
  const openAppointment = useOpenAppointment()

  if (!sections || R.isEmpty(sections)) {
    return null
  }

  const { event } = sections?.[0] as ChargesSection

  if (!event) {
    return null
  }

  return (
    <Box className={classes.appointmentStatusBox}>
      <Text link variant="body2" onClick={() => openAppointment(event?.id)}>
        {event?.type?.name}
      </Text>
      <StyledAppointmentStateLabel item={event} />
    </Box>
  )
}

export const InvoiceTableEventTypeCell = ({
  hideRetailOrder = false,
  invoice,
  multipleRowsLimit,
}: InvoiceTableEventTypeCellProps) => {
  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const openAppointment = useOpenAppointment()

  const showNoShowAppointmentState = useShowNoShowAppointmentState(
    sections,
    invoice,
  )

  if (showNoShowAppointmentState) {
    return <AppointmentPenaltyEventCell sections={sections} />
  }

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItem}
        RetailOrderLineItem={RetailOrderLineItem}
        hideRetailOrder={hideRetailOrder}
        sections={sections}
      />
    )
  }

  if ('event' in invoice && invoice.event) {
    const invoiceEvent = invoice.event

    return (
      <Text
        link
        variant="body2"
        onClick={() => openAppointment(invoiceEvent?.id)}
      >
        {invoiceEvent?.type?.name || otcLabel}
      </Text>
    )
  }

  return null
}
