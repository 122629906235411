import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { findById } from '@pbt/pbt-ui-components/src/utils'

import { RefundInvoice } from '~/api/graphql/generated/types'
import AlertLabel from '~/components/common/labels/AlertLabel'
import { getRefundInvoice } from '~/store/duck/refunds'

import PaymentClientNotesSection from './PaymentClientNotesSection'

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

const useStyles = makeStyles(
  (theme) => ({
    alert: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
      backgroundColor: `${theme.colors.uiError} !important`,
      padding: theme.spacing(0.25, 1, 0.25),
      gap: theme.spacing(0.25),
    },
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      width: 155,
    },
  }),
  { name: 'RefundPaymentDialog' },
)

interface RefundCheckNumberDialogProps extends BasePuiDialogProps {
  assignedInvoiceId?: string
  clientId: string | Nil
  invoiceRefundAmount?: number
}

const RefundCheckNumberDialog = ({
  assignedInvoiceId,
  clientId,
  open,
  onClose,
  invoiceRefundAmount,
}: RefundCheckNumberDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const TitleWithAlert = (
    <Grid container direction="column" gap={1}>
      <Grid item>{t('Common:REFUND_NOUN')}</Grid>
      <Grid item>
        <AlertLabel
          showIcon
          clamp={2}
          className={classes.alert}
          message={
            <Trans
              components={{ br: <br /> }}
              i18nKey="Errors:NO_SHOW_PENALTY_FEE_REFUND_FAILED"
            />
          }
        />
      </Grid>
    </Grid>
  )

  const refundInvoice =
    useSelector(getRefundInvoice(assignedInvoiceId)) || ({} as RefundInvoice)

  const refundOriginalPayments = refundInvoice?.originalInvoice?.payments ?? []
  const eligibleRefundOriginalPayments = refundOriginalPayments.filter(
    (p) => p.refundableAmount > 0,
  )

  // TODO: uncomment when integrating with backend
  // const [refundOriginalPaymentId, setRefundOriginalPaymentId] = useState(
  //   eligibleRefundOriginalPayments.length === 1
  //     ? eligibleRefundOriginalPayments[0].id
  //     : '',
  // )

  const refundOriginalPaymentId = ''

  const refundOriginalPayment =
    findById(refundOriginalPaymentId, eligibleRefundOriginalPayments) ?? {}

  const handleClose = () => {
    onClose?.()
  }

  const { fields: refundCheckNumberFields } = useFields(
    [
      {
        name: 'refundCheckNumberField',
        initialValue: '',
        validators: ['required'],
      },
    ],
    false,
  )

  const { refundCheckNumberField } = refundCheckNumberFields

  return (
    <>
      <PuiDialog
        actions={
          <CustomWidthTooltip
            placement="top"
            title={
              refundCheckNumberField?.value === ''
                ? t('Tooltips:REFUND_CHECK_NUMBER_REQUIRED')
                : ''
            }
          >
            <span>
              <ButtonWithLoader
                className={classes.button}
                disabled={refundCheckNumberField?.value === ''}
              >
                {t('Common:MARK_AS_REFUNDED')}
              </ButtonWithLoader>
            </span>
          </CustomWidthTooltip>
        }
        aria-labelledby="refund-check-number-dialog"
        classes={{
          actions: classes.actions,
          paper: classes.paper,
        }}
        open={open}
        title={TitleWithAlert}
        onClose={handleClose}
      >
        <Grid container item>
          <Grid container item direction="column" p={3} xs={7}>
            <Grid item>
              <Text variant="body">{t('Common:PAYMENTS.REFUND_AMOUNT')}:</Text>
              {invoiceRefundAmount}
            </Grid>
            <Grid item>
              <PuiTextField
                required
                field={refundCheckNumberField}
                label={t('Common:REFUND_CHECK_NUMBER')}
              />
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <PaymentClientNotesSection
              isRefundFailed
              clientId={clientId}
              payment={refundOriginalPayment}
            />
          </Grid>
        </Grid>
      </PuiDialog>
    </>
  )
}

export default RefundCheckNumberDialog
