import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  PermissionArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { AutomaticCommunicationType } from '~/components/common/automaticCommunication/AutomaticCommunicationPreviewDialog'
import PuiSwitch from '~/components/common/PuiSwitch'
import TimeUnitsSelect from '~/components/common/TimeUnitsSelect'
import DialogNames from '~/constants/DialogNames'
import {
  fetchRemindersSettings,
  getRemindersSettings,
  updateRemindersSettings,
} from '~/store/duck/reminderProtocolSettings'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getReminderProtocolTimeUnits } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps, ReminderTemplate } from '~/types'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import ExtendedReminderTemplatesTable from './ExtendedRemindersSetUpTable'

const useStyles = makeStyles(
  () => ({
    expiresInput: {
      width: 48,
    },
    ageUnitsSelect: {
      width: 80,
    },
  }),
  { name: 'ReminderSetUpSection' },
)

const ReminderSetUpSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const businessId = business.id

  const ReminderProtocolTimeUnits = useSelector(getReminderProtocolTimeUnits)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )
  const reminderSettings = useSelector(getRemindersSettings(businessId))

  const [openReminderCommunicationDialog] = useDialog(
    DialogNames.REMINDER_COMMUNICATION,
  )
  const [openPreviewDialog] = useDialog(
    DialogNames.AUTOMATIC_COMMUNICATION_PREVIEW,
  )

  const yearId = Utils.findConstantIdByName('Year', ReminderProtocolTimeUnits)

  const { validate, fields, reset } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        initialValue: reminderSettings?.active,
      },
      {
        name: 'defaultExpirationAmount',
        initialValue: reminderSettings?.defaultReminderExpirationOffset?.amount,
        type: 'number',
      },
      {
        name: 'defaultExpirationUnit',
        initialValue:
          reminderSettings?.defaultReminderExpirationOffset?.unit || yearId,
      },
      {
        name: 'templates',
        initialValue: reminderSettings?.templates || [],
      },
    ],
    false,
  )

  const { active, defaultExpirationAmount, defaultExpirationUnit, templates } =
    fields

  useEffect(() => {
    if (reminderSettings) {
      templates.setValue(reminderSettings.templates)
      reset()
    }
  }, [reminderSettings])

  const handleAddReminderCommunication = () => {
    openReminderCommunicationDialog({
      businessId,
      onSave: (template: ReminderTemplate) =>
        templates.setValue([...templates.value, template]),
    })
  }

  usePracticeFieldsSection<any>({
    fields,
    business,
    parsedFields: {
      sendReminders: active.value,
      defaultReminderExpirationOffset: {
        amount: Number(defaultExpirationAmount.value),
        unit: defaultExpirationUnit.value,
      },
    },
    sectionName: PracticeDetailsPanels.REMINDER_SET_UP,
    validate,
    reset,
    onSave: () => {
      dispatch(
        updateRemindersSettings(business.id, {
          active: active.value,
          defaultReminderExpirationOffset: {
            amount: Number(defaultExpirationAmount.value),
            unit: defaultExpirationUnit.value,
          },
          defaultCommunicationMessage:
            reminderSettings.defaultCommunicationMessage,
          templates: templates.value,
        }),
      )
    },
  })

  const handleChangeActiveTemplate = (index: number, isActive: boolean) => {
    templates.setValue(
      R.update(
        index,
        { ...templates.value[index], active: isActive },
        templates.value,
      ),
    )
  }

  const handleEditTemplate = (template: ReminderTemplate, index: number) => {
    openReminderCommunicationDialog({
      businessId,
      template,
      onSave: (newTemplate: ReminderTemplate) =>
        templates.setValue(R.update(index, newTemplate, templates.value)),
    })
  }

  const handlePreviewTemplate = (template: ReminderTemplate) => {
    openPreviewDialog({
      business,
      automaticCommunication: {
        subject: template.subject as string,
        message: template.message as string,
        textMessage: template.textMessage as string,
      },
      type: AutomaticCommunicationType.REMINDER,
    })
  }

  const handleDeleteTemplate = (index: number) => {
    templates.setValue(R.remove(index, 1, templates.value))
  }

  useEffect(() => {
    if (businessId) {
      dispatch(fetchRemindersSettings(businessId))
    }
  }, [businessId])

  return (
    <Grid container direction="column">
      <PuiSwitch
        disabled={!permissions.update}
        field={active}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={t(
              'Businesses:REMINDER_SET_UP.IF_TURNED_ON_ANY_UPCOMING_REMINDERS_WILL_BE_SENT',
            )}
            variant="body"
          >
            {t('Businesses:REMINDER_SET_UP.AUTOMATICALLY_SEND_REMINDERS')}
          </TextWithTooltip>
        }
      />
      <Text strong mb={1} mt={2} variant="body2">
        {t('Businesses:REMINDER_SET_UP.COMMUNICATION_CADENCE')}
      </Text>
      <Text variant="body">
        {t('Businesses:REMINDER_SET_UP.ALL_REMINDERS_WILL_BE_COALESCED')}
      </Text>
      <ExtendedReminderTemplatesTable
        templates={templates.value}
        onChangeActive={handleChangeActiveTemplate}
        onDelete={handleDeleteTemplate}
        onEdit={handleEditTemplate}
        onPreview={handlePreviewTemplate}
      />
      <Grid item my={2}>
        <AddButton
          addText={t('Businesses:REMINDER_SET_UP.ADD_REMINDER_COMMUNICATION')}
          disabled={!permissions.create}
          onAdd={handleAddReminderCommunication}
        />
      </Grid>
      <Text strong mb={1} mt={2} variant="body2">
        {t('Businesses:REMINDER_SET_UP.DEFAULT_REMINDER_EXPIRATION')}
      </Text>

      <Grid container alignItems="center" columnSpacing={2}>
        <Grid item>
          <PuiTextField
            className={classes.expiresInput}
            disabled={!permissions.update}
            field={defaultExpirationAmount}
            margin="none"
          />
        </Grid>
        <Grid item>
          <TimeUnitsSelect
            amount={Number(defaultExpirationAmount.value)}
            className={classes.ageUnitsSelect}
            disabled={!permissions.update}
            field={defaultExpirationUnit}
            items={ReminderProtocolTimeUnits}
            renderEmpty={false}
          />
        </Grid>
        <Grid item>
          <TextWithTooltip
            allowWrap
            tooltipText={t(
              'Businesses:REMINDER_SET_UP.BASED_ON_THIS_DATE_REMINDER_STATUS_WILL_BE_CHANGED',
            )}
            variant="body"
          >
            {t('Businesses:REMINDER_SET_UP.AFTER_DUE_DATE').toLowerCase()}
          </TextWithTooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(ReminderSetUpSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
