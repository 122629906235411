import * as React from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { Button, Tooltip } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

interface EstimatesTakeDepositsButtonMenuProps {
  className?: string
  disableTooltipHover?: boolean
  disabled?: boolean
  isLoading?: boolean
  onRecordDeposit: () => void
  onSendDepositLink: () => void
  tooltipText?: string
  withLoader?: boolean
}

export const EstimatesTakeDepositsButtonMenu = ({
  className,
  onRecordDeposit,
  onSendDepositLink,
  disabled,
  disableTooltipHover,
  isLoading,
  withLoader = true,
  tooltipText,
}: EstimatesTakeDepositsButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { t } = useTranslation(['Common', 'Payments', 'Tooltips'])

  const commonButtonProps = {
    className,
    disabled,
    endIcon: <KeyboardArrowDown />,
    onClick: handleClick,
  }

  return (
    <>
      <Tooltip
        disableFocusListener
        disableHoverListener={disableTooltipHover}
        placement="top"
        title={tooltipText ?? t('Tooltips:SAVE_ESTIMATE_FIRST')}
      >
        <span>
          {withLoader ? (
            <ButtonWithLoader
              {...commonButtonProps}
              color="primary"
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              {t('Common:TAKE_DEPOSIT_ACTION')}
            </ButtonWithLoader>
          ) : (
            <Button {...commonButtonProps}>
              {t('Common:TAKE_DEPOSIT_ACTION')}
            </Button>
          )}
        </span>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          key="record-deposit"
          onClick={() => {
            handleClose()
            onRecordDeposit()
          }}
        >
          {t('Common:RECORD_DEPOSIT_ACTION')}
        </MenuItem>
        <MenuItem
          key="send-deposit-link"
          onClick={() => {
            handleClose()
            onSendDepositLink()
          }}
        >
          {t('Payments:SEND_PAYMENT_LINK')}
        </MenuItem>
      </Menu>
    </>
  )
}
