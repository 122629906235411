import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'
import { Check, Exclamation } from '@pbt/pbt-ui-components/src/icons'

import { FindingState } from '~/types'

import FindingsNode from '../rail/summary/FindingsNode'
// @ts-ignore
import { groupFindings, prepareFindings } from './findingsUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    itemName: {
      textDecoration: 'none',
      paddingTop: 0,
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    nodeRoot: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.colors.success,
      color: theme.colors.tableBackground,
      marginRight: theme.spacing(1),
      verticalAlign: 'text-top',
      padding: 1,
    },
    checkIcon: {
      backgroundColor: theme.colors.success,
    },
    infoIcon: {
      backgroundColor: theme.colors.alertError,
    },
    cell: {
      padding: theme.spacing(1),
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    row: {
      border: theme.constants.tableBorder,
    },
    nameCell: {
      verticalAlign: 'top',
      borderRight: theme.constants.tableBorder,
    },
    borderBottomCell: {
      borderBottom: theme.constants.tableBorder,
    },
    closeIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.5em',
      width: '1em',
      height: '1em',
      marginRight: theme.spacing(1),
      backgroundColor: theme.colors.filterBorderColor,
    },
    closeIcon: {
      color: theme.colors.tableBackground,
      width: '0.5em',
      height: '0.5em',
    },
    divider: {
      height: theme.spacing(2),
    },
  }),
  { name: 'FindingsTable' },
)

interface FindingsTableProps {
  findingsState: Record<string, FindingState> | FindingState[]
}

const FindingsTable = ({ findingsState }: FindingsTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  const {
    hasWnlFindings,
    wnlFindingsWithNotes,
    wnlFindingsWithoutNotesName,
    onlFindingsSorted,
    hasOnlFindings,
    hasNcFindings,
    ncFindingsSorted,
    hasFindings,
  } = prepareFindings(groupFindings(findingsState))

  const tableDivider = (
    <tr>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td>
        <div className={classes.divider} />
      </td>
    </tr>
  )

  if (!hasFindings) {
    return null
  }

  return (
    <table className={classNames(classes.table, classes.root)}>
      <tbody>
        {hasWnlFindings && (
          <>
            <tr className={classes.row}>
              <td className={classes.cell} colSpan={2}>
                <Text strong variant="body">
                  <Check
                    className={classNames(classes.icon, classes.checkIcon)}
                  />
                  {t('Soap:FINDINGS_TABLE.WITHIN_NORMAL_LIMITS_FINDING_TITLE')}
                </Text>
              </td>
            </tr>

            {Boolean(wnlFindingsWithoutNotesName) && (
              <tr className={classes.row}>
                <td className={classes.cell} colSpan={2}>
                  <Text variant="body">{wnlFindingsWithoutNotesName}</Text>
                </td>
              </tr>
            )}

            {wnlFindingsWithNotes.map(({ id, name, notes }: FindingState) => (
              <tr className={classes.row} key={`${id}_${name}`}>
                <td className={classNames(classes.nameCell, classes.cell)}>
                  <Text variant="body">{name}</Text>
                </td>
                <td className={classes.cell}>
                  <HtmlNotesPreview includeLabel notes={notes} />
                </td>
              </tr>
            ))}
          </>
        )}

        {(hasOnlFindings || hasNcFindings) && tableDivider}

        {hasOnlFindings && (
          <>
            <tr className={classes.row}>
              <td className={classes.cell} colSpan={2}>
                <Text strong variant="body">
                  <Exclamation
                    className={classNames(classes.icon, classes.infoIcon)}
                  />
                  {t('Soap:FINDINGS_TABLE.OUTSIDE_NORMAL_LIMITS_TITLE')}
                </Text>
              </td>
            </tr>

            {onlFindingsSorted.map(
              ({ id, name, notes, findings }: FindingState) => {
                const findingsList = findings ? Object.values(findings) : []

                return (
                  <tr className={classes.row} key={`${id}_${name}`}>
                    <td className={classNames(classes.nameCell, classes.cell)}>
                      <Text variant="body">{name}</Text>
                    </td>
                    <td>
                      {(notes || findingsList.length > 0) && (
                        <table className={classes.table}>
                          <tbody>
                            {notes && (
                              <tr>
                                <td
                                  className={classNames(
                                    findingsList.length &&
                                      classes.borderBottomCell,
                                    classes.cell,
                                  )}
                                >
                                  <HtmlNotesPreview
                                    includeLabel
                                    notes={notes}
                                  />
                                </td>
                              </tr>
                            )}
                            {findingsList.length > 0 && (
                              <tr>
                                <td className={classes.cell}>
                                  {findingsList.map((finding) => (
                                    <FindingsNode
                                      editDisabled
                                      simpleStyles
                                      classes={{
                                        root: classes.nodeRoot,
                                        itemName: classes.itemName,
                                      }}
                                      finding={finding}
                                      key={finding.id}
                                    />
                                  ))}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </td>
                  </tr>
                )
              },
            )}
          </>
        )}

        {hasNcFindings && tableDivider}

        {hasNcFindings && (
          <>
            <tr className={classes.row}>
              <td className={classes.cell} colSpan={2}>
                <Grid container alignItems="center">
                  <span className={classes.closeIconContainer}>
                    <CloseIcon className={classes.closeIcon} />
                  </span>
                  <Text strong variant="body">
                    {t('Soap:FINDINGS_TABLE.NOT_CHECKED_TITLE')}
                  </Text>
                </Grid>
              </td>
            </tr>

            {ncFindingsSorted.map(({ id, name, notes }: FindingState) => (
              <tr className={classes.row} key={`${id}_${name}`}>
                <td className={classNames(classes.nameCell, classes.cell)}>
                  <Text variant="body">{name}</Text>
                </td>
                <td className={classes.cell}>
                  <HtmlNotesPreview includeLabel notes={notes} />
                </td>
              </tr>
            ))}
          </>
        )}
      </tbody>
    </table>
  )
}

export default FindingsTable
