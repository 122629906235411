import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Defaults,
  PrimitiveTable,
  PrimitiveTableColumn,
  Question,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import AppointmentTypesCell from '~/components/common/lists/primitive-table/cells/AppointmentTypesCell'
import BusinessNameCell from '~/components/common/lists/primitive-table/cells/BusinessNameCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import WorkflowCell from '~/components/common/lists/primitive-table/cells/WorkflowCell'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  fetchMoreItemsForQuestionsList,
  fetchQuestionsList,
} from '~/store/actions/questions'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getMultipleQuestions,
  getQuestionsIsFetchingList,
  getQuestionsList,
  getQuestionsTotalCount,
} from '~/store/reducers/questions'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

import NoQuestionsScreen from './NoQuestionsScreen'
import QuestionDetails from './QuestionDetails'

const getColumns = (isEnabled: boolean) =>
  [
    {
      label: i18n.t('Common:QUESTION_ONE'),
      prop: 'questionText',
      width: 5,
    },
    {
      label: i18n.t('Common:WORKFLOW_ONE'),
      prop: WorkflowCell,
      width: 4,
    },
    {
      label: i18n.t('Common:APPOINTMENT_TYPE'),
      prop: AppointmentTypesCell,
      width: 4,
    },
    {
      label: i18n.t('Common:SPECIES'),
      prop: SpeciesCell,
      width: 2,
    },
    isEnabled && {
      label: i18n.t('Common:SOURCE'),
      prop: BusinessNameCell,
      width: 2,
    },
    {
      label: i18n.t('Common:ACTIVE_ONE'),
      prop: (item: Question) => boolToYesNoString(item.active),
      width: 1,
    },
    {
      label: i18n.t('Common:RANK'),
      prop: 'rank',
      width: 1,
    },
  ].filter(Boolean) as PrimitiveTableColumn[]

export interface QuestionsTableComponentProps {
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
  questionId?: string
}

const QuestionsTableComponent = ({
  headerButtons,
  questionId,
  onDetailsClose,
}: QuestionsTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getQuestionsList)
  const questions = useSelector(getMultipleQuestions(list))
  const totalCount = useSelector(getQuestionsTotalCount)
  const isFetchingList = useSelector(getQuestionsIsFetchingList)
  const { t } = useTranslation('Common')

  const isGroupQuestionsFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_QUESTIONS_SHARING),
  )

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchQuestionsList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search])

  const navigateToQuestion = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/questions/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(questions[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForQuestionsList(startIndex, endIndex, search))
  }

  const getDisabled = (item: Question) => !item.active

  return (
    <ExpandableTable
      Expander={QuestionDetails}
      NoItemsScreen={NoQuestionsScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={questionId}
      list={questions}
      searchTerm={search}
      title={t('Common:PATIENT_QUESTIONS_CATALOG')}
      onClose={onDetailsClose}
      onSelected={navigateToQuestion}
    >
      <PrimitiveTable
        columns={getColumns(isGroupQuestionsFeatureEnabled)}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default QuestionsTableComponent
