import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  LanguageUtils,
  NumberUtils,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import SearchPath from '~/components/common/buttons/SearchPath'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Order } from '~/types'
import {
  hasPriceOrAggregatedPrice,
  isObsoleteGlobalItem,
} from '~/utils/orderUtils'

import BenefitListItem, { BenefitListItemProps } from './BenefitListItem'
import BundleListItem, { BundleListItemProps } from './BundleListItem'
import OrderListItem from './OrderListItem'
import ReadonlyOrderTooltip from './ReadonlyOrderTooltip'
import ReminderListItem, { ReminderListItemProps } from './ReminderListItem'

const useStyles = makeStyles(
  (theme) => ({
    listItemContainerDisabled: {
      opacity: 0.5,
    },
    infoIcon: {
      marginLeft: theme.spacing(0.75),
    },
    divider: {
      width: '100%',
      margin: `${theme.spacing(0.5)} 0`,
      color: theme.constants.tabBorder,
    },
  }),
  { name: 'ListItemWrapper' },
)

const ItemsMap: Record<string, React.JSXElementConstructor<any>> = {
  [OrderType.BUNDLE]: BundleListItem,
  [OrderType.REMINDER]: ReminderListItem,
  [OrderType.BENEFIT]: BenefitListItem,
  [OrderType.WPLANS]: BenefitListItem,
  DEFAULT: OrderListItem,
}

interface ListItemWrapperProps
  extends Partial<BundleListItemProps>,
    Partial<ReminderListItemProps>,
    Partial<BenefitListItemProps> {
  ItemComponent?: React.JSXElementConstructor<any>
  includeOnHandQuantity?: boolean
  includePrice?: boolean
  includeSubItemsPrices?: boolean
  isLoading?: boolean
  isSelectableItem?: boolean
  onSearchResultFilterClick?: (affectedFilters: any[]) => void
  order: Order
  searchResultFilter?: any[]
  searchTerm?: string
  showLabelState?: boolean
  toolTipText?: React.ReactNode
}

const ListItemWrapper = forwardRef<HTMLDivElement, ListItemWrapperProps>(
  function ListItemWrapper(
    {
      ItemComponent,
      includeOnHandQuantity,
      includePrice,
      includeSubItemsPrices,
      isLoading,
      isSelectableItem,
      onSearchResultFilterClick,
      order,
      searchResultFilter,
      searchTerm,
      showLabelState = false,
      isSelected,
      toolTipText = <ReadonlyOrderTooltip order={order} />,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common'])
    const isFoodCatalogEnabled = useSelector(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const ItemSpecial = order?.type ? ItemsMap[order.type] : null

    const Item = ItemComponent || ItemSpecial || ItemsMap.DEFAULT

    const itemPrice =
      order.price?.price ||
      order.items?.reduce((total, i) => {
        total += i.price?.price || 0
        return total
      }, 0)

    const hasSearchPath = searchResultFilter && !R.isEmpty(searchResultFilter)
    const hasPrice = includePrice && hasPriceOrAggregatedPrice(order)

    const onClick =
      onSearchResultFilterClick && searchResultFilter
        ? (index: number) => {
            const affectedFilters = searchResultFilter.slice(0, index + 1)
            onSearchResultFilterClick(affectedFilters)
          }
        : undefined

    const tooltipTextProp = isObsoleteGlobalItem(order, isFoodCatalogEnabled)
      ? t('Common:OBSOLETE')
      : toolTipText

    return isSelectableItem ? (
      <>
        <Grid container item alignItems="center" wrap="nowrap">
          <Grid
            container
            item
            xs={7 + (hasSearchPath ? 0 : 3.5) + (hasPrice ? 0 : 1.5)}
          >
            <Item
              includeOnHandQuantity={includeOnHandQuantity}
              includePrice={includeSubItemsPrices}
              isLoading={isLoading}
              isSelected={isSelected}
              order={order}
              ref={ref}
              searchTerm={searchTerm}
              showLabelState={showLabelState}
              {...rest}
            />
          </Grid>
          {hasSearchPath && (
            <Grid container item alignItems="center" mb="auto" xs={3.5}>
              <SearchPath path={searchResultFilter} onClick={onClick} />
            </Grid>
          )}
          {hasPrice && (
            <Grid item mb="auto" xs={1.5}>
              <Text align="right" px={1} py={0.75} variant="lowAccent2">
                {NumberUtils.formatMoney(itemPrice)}
              </Text>
            </Grid>
          )}
        </Grid>
        {isSelected && Item === BenefitListItem && (
          <Divider className={classes.divider} />
        )}
      </>
    ) : (
      <Grid
        container
        item
        alignItems="center"
        className={classes.listItemContainerDisabled}
        justifyContent="space-between"
        px={1}
        py={0.5}
        ref={ref}
        wrap="nowrap"
      >
        <TextWithTooltip
          noWrap
          classes={{
            infoIcon: classes.infoIcon,
          }}
          highlight={searchTerm}
          iconPlacement="left"
          tooltipText={tooltipTextProp}
          variant="body2"
        >
          {LanguageUtils.getTranslatedFieldName(order)}
        </TextWithTooltip>
        {includePrice && <Text variant="lowAccent2">-</Text>}
      </Grid>
    )
  },
)

export default ListItemWrapper
