import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import {
  Calendar,
  CountryCode,
  Nil,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  StateSelect,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { LicenseTypes } from '~/constants/LicenseTypes'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getLicenseTypes } from '~/store/reducers/constants'
import { DataHandleWithUnsavedChanges, TeamMemberLicense } from '~/types'
import { isFieldValuesChanged } from '~/utils'

export interface LicenseProps {
  license: TeamMemberLicense | Nil
}

export type LicenseHandle = DataHandleWithUnsavedChanges<TeamMemberLicense>

const License = forwardRef<LicenseHandle, LicenseProps>(function License(
  { license },
  ref,
) {
  const { t } = useTranslation(['Admin', 'Common'])

  const licenseTypes = useSelector(getLicenseTypes)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isTelemed = currentBusiness?.tags?.length
    ? currentBusiness?.tags?.filter((tag) => tag.name === 'Telemed').length > 0
    : false
  const otherLicenceLengh = isTelemed ? 4 : 6
  const isCreate = !license

  const { fields, validate } = useFields(
    [
      {
        name: 'typeId',
        label: t('Common:LICENSE_TYPE'),
        validators: ['required'],
        initialValue: isCreate ? '' : license.typeId,
      },
      {
        name: 'type',
        label: t('Admin:MEMBER.LICENSE.ENTER_LICENSE_TYPE'),
        initialValue: isCreate ? '' : license.type,
      },
      {
        name: 'number',
        label: t('Common:LICENSE_NUMBER'),
        validators: ['required'],
        initialValue: isCreate ? '' : license.number,
      },
      {
        name: 'issueDate',
        label: t('Common:ISSUE_DATE'),
        validators: ['timestamp', 'required'],
        initialValue: isCreate ? '' : license.issueDate,
      },
      {
        name: 'expirationDate',
        label: t('Common:EXPIRATION_DATE'),
        validators: ['timestamp', 'required'],
        initialValue: isCreate ? '' : license.expirationDate,
      },
      {
        name: 'issuingAuthority',
        label: t('Common:ISSUING_AUTHORITY'),
        validators: ['required'],
        initialValue: isCreate ? '' : license.issuingAuthority,
      },
      {
        name: 'rank',
        label: t('Common:RANK'),
        initialValue: isCreate ? '' : license.rank,
      },
      {
        name: 'issuingState',
        label: t('Common:STATE'),
        initialValue: license?.issuingState || '',
      },
      {
        name: 'additionalInfo',
        label: t('Common:ADDITIONAL_INFORMATION'),
        initialValue: isCreate ? '' : license.additionalInfo,
      },
    ],
    false,
  )

  const {
    typeId,
    type,
    number,
    issueDate,
    expirationDate,
    issuingAuthority,
    rank,
    additionalInfo,
    issuingState,
  } = fields

  const selectedLicenseType = Utils.findById(typeId.value, licenseTypes) || {}

  useImperativeHandle(ref, () => ({
    get: () => ({
      ...license,
      typeId: typeId.value,
      type: type.value,
      number: number.value,
      issueDate: issueDate.value,
      expirationDate: expirationDate.value,
      issuingAuthority: issuingAuthority.value,
      issuingState: issuingState.value,
      rank: rank.value,
      additionalInfo: additionalInfo.value,
    }),
    validate,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  return (
    <Grid container item alignItems="center" columnSpacing={2}>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="license-type-select">{`${typeId.label}*`}</InputLabel>
          <PuiSelect
            fullWidth
            field={typeId}
            input={<Input id="license-type-select" />}
            items={licenseTypes}
            name={typeId.name}
          />
        </FormControl>
      </Grid>
      {isTelemed && (
        <Grid item md={2} xs={12}>
          <StateSelect country={CountryCode.US} field={issuingState} />
        </Grid>
      )}
      <Grid item md={otherLicenceLengh} xs={12}>
        {selectedLicenseType.name === LicenseTypes.OTHER && (
          <PuiTextField
            field={type}
            inputProps={{ maxLength: 100 }}
            label={type.label}
            name={type.name}
          />
        )}
      </Grid>
      <Grid item md={4} xs={12}>
        <PuiTextField
          field={number}
          inputProps={{ maxLength: 100 }}
          label={`${number.label}*`}
          name={number.name}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Calendar field={issueDate} label={`${issueDate.label}*`} />
      </Grid>
      <Grid item md={4} xs={12}>
        <Calendar field={expirationDate} label={`${expirationDate.label}*`} />
      </Grid>
      <Grid item md={9}>
        <PuiTextField
          field={issuingAuthority}
          inputProps={{ maxLength: 100 }}
          label={`${issuingAuthority.label}*`}
          name={issuingAuthority.name}
        />
      </Grid>
      <Grid item md={3}>
        <PuiTextField
          field={rank}
          inputProps={{ maxLength: 100 }}
          label={rank.label}
          name={rank.name}
        />
      </Grid>
      <Grid item mt={2} xs={12}>
        <PuiTextArea
          label={additionalInfo.label}
          name={additionalInfo.name}
          value={additionalInfo.value}
          onChange={additionalInfo.set}
        />
      </Grid>
      <Grid item mt={1} xs={12}>
        <RequiredFieldsNotice />
      </Grid>
    </Grid>
  )
})

export default License
