import { Task } from '@redux-saga/types'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Defaults, Role, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getTeamMembersFilters } from '~/components/dashboard/timetable/rail/TimetableFilters'
import apiErrorTypes from '~/constants/apiErrorTypes'
import DialogNames from '~/constants/DialogNames'
import { LandingType } from '~/constants/landingConstants'
import SnackNotificationType from '~/constants/SnackNotificationType'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import i18n from '~/locales/i18n'
import {
  Document,
  DocumentSource,
  Entities,
  Schedule,
  TimetableEvent,
  WritableTimetableEvent,
} from '~/types'

import { fetchInvoiceByEventId } from '../actions/finance'
import {
  fetchSOAPOrders,
  selectDoctorLocally,
  selectTechLocally,
} from '../actions/soap'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import {
  addForm,
  addFormFailure,
  addFormSuccess,
  cancelFutureAppointments,
  cancelFutureAppointmentsFailure,
  cancelFutureAppointmentsSuccess,
  createAppointment,
  createAppointmentFailure,
  createAppointmentSuccess,
  createBusyTime,
  createBusyTimeFailure,
  createBusyTimeSuccess,
  deleteAppointment,
  deleteAppointmentFailure,
  deleteAppointmentSuccess,
  deleteBusyTime,
  deleteBusyTimeFailure,
  deleteBusyTimeSuccess,
  deleteForm,
  deleteFormFailure,
  deleteFormSuccess,
  editAppointment,
  editAppointmentFailure,
  editAppointmentSuccess,
  editBusyTime,
  editBusyTimeFailure,
  editBusyTimeSuccess,
  fetchAppointment,
  fetchAppointmentFailure,
  fetchAppointmentSuccess,
  fetchBusyTime,
  fetchBusyTimeFailure,
  fetchBusyTimeSuccess,
  patchAppointment,
  patchAppointmentAbolish,
  patchAppointmentFailure,
  patchAppointmentSuccess,
  setTimetableFilters,
  updateAppointmentNotes,
  updateAppointmentNotesFailure,
  updateAppointmentNotesSuccess,
  updateAppointmentStatus,
  updateAppointmentStatusFailure,
  updateAppointmentStatusSuccess,
  updateTimetableFilteredPersons,
} from '../actions/timetable'
import {
  ADD_FORM,
  CANCEL_FUTURE_APPOINTMENTS,
  CREATE_APPOINTMENT,
  CREATE_BUSY_TIME,
  DELETE_APPOINTMENT,
  DELETE_BUSY_TIME,
  DELETE_FORM,
  DISABLE_TIMETABLE_AUTO_REFRESH,
  EDIT_APPOINTMENT,
  EDIT_BUSY_TIME,
  FETCH_APPOINTMENT,
  FETCH_BUSY_TIME,
  PATCH_APPOINTMENT,
  SET_TIMETABLE_FILTERS,
  UPDATE_APPOINTMENT_NOTES,
  UPDATE_APPOINTMENT_STATUS,
} from '../actions/types/timetable'
import { OPEN_DIALOG, openDialog } from '../duck/dialogs'
import { fetchWidgetsData } from '../duck/landing'
import { registerWarnAlert } from '../duck/uiAlerts'
import { addUiNotification } from '../duck/uiNotifications'
import { getCurrentBusinessId } from '../reducers/auth'
import { getRolesMap } from '../reducers/roles'
import { getSchedules } from '../reducers/scheduler'
import { getClientId, getSoapBusinessId, getSoapId } from '../reducers/soap'
import {
  getTimetableEventFieldsToUpdate,
  getTimetableFilteredPersonGroups,
  getTimetableFilteredPersons,
  getTimetablePersonsList,
} from '../reducers/timetable'
import { getWhiteboardTeamSchedules } from '../reducers/whiteboard'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* createAppointmentSaga({
  appointment,
}: ReturnType<typeof createAppointment>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createAppointment,
      appointment,
    )
    yield call(updateEntities, entities)
    yield put(fetchTimelineAction())
    yield put(createAppointmentSuccess(result))
  } catch (error) {
    yield put(createAppointmentFailure(error as ApiError))
  }
}

export function* fetchAppointmentCancelableSaga({
  appointmentId,
}: ReturnType<typeof fetchAppointment>) {
  try {
    const { entities } = yield* requestAPI(API.fetchAppointment, appointmentId)
    yield call(updateEntities, entities, { spaces: [true] })
    yield put(fetchAppointmentSuccess())
  } catch (error) {
    yield put(fetchAppointmentFailure(error as ApiError))
  }
}
export function* fetchAppointmentSaga(
  params: ReturnType<typeof fetchAppointment>,
) {
  const task: Task = yield fork(fetchAppointmentCancelableSaga, params)
  yield take([UPDATE_APPOINTMENT_STATUS, EDIT_APPOINTMENT, PATCH_APPOINTMENT])
  yield cancel(task)
}

function* notifyAutochargedSoap(event: TimetableEvent) {
  const autocharged = event?.autocharged
  if (!autocharged) {
    return
  }
  yield put(
    openDialog({
      name: DialogNames.SOAP_AUTOCHARGE_ALERT,
      id: uuid(),
      unique: true,
    }),
  )

  const soapId: string = yield select(getSoapId)
  const soapBusinessId: string = yield select(getSoapBusinessId)
  const clientId: string | null = yield select(getClientId)

  if (soapId) {
    yield put(fetchSOAPOrders(soapId, soapBusinessId))
  }

  if (clientId && event.id) {
    yield put(fetchInvoiceByEventId(clientId, event.id))
  }
}

function* processEventUpdateSuccess(entities: Entities, appointmentId: string) {
  yield call(updateEntities, entities, { spaces: [true] })

  const soapId: string = yield select(getSoapId)
  const currentEvent =
    soapId &&
    Object.values(entities.events || {}).find((event) =>
      R.find(R.propEq('id', soapId), event.soaps || []),
    )
  const updatedCurrentSoap =
    currentEvent && R.find(R.propEq('id', soapId), currentEvent.soaps || [])
  if (updatedCurrentSoap) {
    yield put(selectDoctorLocally(updatedCurrentSoap.assignedVet?.id))
    yield put(selectTechLocally(updatedCurrentSoap.assignedVetTech?.id))
  }

  if (entities.events && appointmentId) {
    yield notifyAutochargedSoap(entities.events[appointmentId])
  }
}

export function* editAppointmentSaga({
  appointment,
  param,
}: ReturnType<typeof editAppointment>) {
  try {
    const { result: appointmentId, entities } = param
      ? yield* requestAPI(API.editRecurringAppointment, appointment, param)
      : yield* requestAPI(API.editAppointment, appointment)
    yield processEventUpdateSuccess(entities, appointmentId)
    yield put(editAppointmentSuccess(appointmentId))
  } catch (error) {
    yield put(editAppointmentFailure(error as ApiError))
  }
}

export function* patchAppointmentSaga({
  appointment,
  param,
  debounced,
}: ReturnType<typeof patchAppointment>) {
  try {
    const { id } = appointment
    if (debounced) {
      yield delay(Defaults.DEBOUNCE_ACTION_TIME)
    }
    const fieldsToUpdate: WritableTimetableEvent = yield select(
      getTimetableEventFieldsToUpdate,
    )
    const { result, entities } = param
      ? yield* requestAPI(
          API.patchRecurringAppointment,
          id,
          fieldsToUpdate,
          param,
        )
      : yield* requestAPI(API.patchAppointment, id, fieldsToUpdate)
    yield processEventUpdateSuccess(entities, result)
    yield put(patchAppointmentSuccess(result))
  } catch (e) {
    const error = e as ApiError
    if (error.responseBody.type === apiErrorTypes.CANNOT_CHANGE_PATIENT) {
      yield put(patchAppointmentAbolish())
      yield put(
        registerWarnAlert(i18n.t('Errors:API_ERROR.CANNOT_CHANGE_PATIENT')),
      )
    } else {
      yield put(patchAppointmentFailure(error))
    }
  }
}

export function* deleteAppointmentSaga({
  appointmentId,
  param,
}: ReturnType<typeof deleteAppointment>) {
  try {
    if (param) {
      yield* requestAPI(API.deleteRecurringAppointment, appointmentId, param)
    } else {
      yield* requestAPI(API.deleteAppointment, appointmentId)
    }
    yield put(deleteAppointmentSuccess(appointmentId))
  } catch (error) {
    yield put(deleteAppointmentFailure(error as ApiError))
  }
}

export function* cancelFutureAppointmentsSaga({
  patientId,
}: ReturnType<typeof cancelFutureAppointments>) {
  try {
    yield* requestAPI(API.cancelFutureAppointments, patientId)
    yield put(cancelFutureAppointmentsSuccess())
    yield put(
      fetchWidgetsData([SnapshotsAliasTypes.Appointments], {
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        quiet: true,
        patientId,
      }),
    )
    yield put(
      addUiNotification({
        id: uuid(),
        message: i18n.t(
          'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.ALL_UPCOMING_APPOINTMENTS_WERE_CANCELED',
        ),
        type: SnackNotificationType.INFO,
      }),
    )
  } catch (error) {
    yield put(cancelFutureAppointmentsFailure(error as ApiError))
  }
}

export function* createBusyTimeSaga({
  busyTime,
}: ReturnType<typeof createBusyTime>) {
  try {
    const { result, entities } = yield* requestAPI(API.createBusyTime, busyTime)
    yield call(updateEntities, entities)
    yield put(createBusyTimeSuccess(result))
  } catch (error) {
    yield put(createBusyTimeFailure(error as ApiError))
  }
}

export function* fetchBusyTimeSaga({
  busyTimeId,
}: ReturnType<typeof fetchBusyTime>) {
  try {
    const { entities } = yield* requestAPI(API.fetchBusyTime, busyTimeId)
    yield call(updateEntities, entities, { spaces: [true] })
    yield put(fetchBusyTimeSuccess())
  } catch (error) {
    yield put(fetchBusyTimeFailure(error as ApiError))
  }
}

export function* editBusyTimeSaga({
  busyTime,
  param,
}: ReturnType<typeof editBusyTime>) {
  try {
    const { result, entities } = param
      ? yield* requestAPI(API.editRecurringBusyTime, busyTime, param)
      : yield* requestAPI(API.editBusyTime, busyTime)
    yield call(updateEntities, entities)
    yield put(editBusyTimeSuccess(result))
  } catch (error) {
    yield put(editBusyTimeFailure(error as ApiError))
  }
}

export function* deleteBusyTimeSaga({
  busyTimeId,
  param,
}: ReturnType<typeof deleteBusyTime>) {
  try {
    if (param) {
      yield* requestAPI(API.deleteRecurringBusyTime, busyTimeId, param)
    } else {
      yield* requestAPI(API.deleteBusyTime, busyTimeId)
    }
    yield put(deleteBusyTimeSuccess(busyTimeId))
  } catch (error) {
    yield put(deleteBusyTimeFailure(error as ApiError))
  }
}

export function* updateAppointmentNotesSaga({
  id,
  notes,
  debounced,
}: ReturnType<typeof updateAppointmentNotes>) {
  try {
    if (debounced) {
      yield delay(Defaults.DEBOUNCE_ACTION_TIME)
    }
    const response = yield* requestAPI(API.updateAppointmentNotes, id, notes)
    yield put(
      updateAppointmentNotesSuccess({ id: response.id, notes: response.notes }),
    )
  } catch (error) {
    yield put(updateAppointmentNotesFailure(error as ApiError))
  }
}

export function* updateAppointmentStatusSaga({
  appointment,
  oldStatus,
  newStatus,
}: ReturnType<typeof updateAppointmentStatus>) {
  try {
    const businessId: string = yield select(getCurrentBusinessId)

    const newAppointment = {
      ...appointment,
      bypassValidation: true,
      state: appointment.state?.id,
      type: appointment.type?.id,
      business: businessId,
      spaceId: appointment.assignedSpace,
      personRoles: appointment.personRoles
        ? appointment.personRoles.map((role) => ({
            ...role,
            person: role.personId,
            role: role.roleId,
          }))
        : null,
      personResponsibilities: appointment.personResponsibilities
        ? appointment.personResponsibilities.map((responsibility) => ({
            ...responsibility,
            person: responsibility.personId,
            responsibility: responsibility.responsibilityId,
          }))
        : null,
    }
    const { result: appointmentId, entities } = yield* requestAPI(
      API.editAppointment,
      newAppointment as TimetableEvent,
    )
    yield call(updateEntities, entities, { spaces: [true] })
    yield put(updateAppointmentStatusSuccess())
    yield notifyAutochargedSoap(entities.events[appointmentId])
  } catch (error) {
    yield put(
      updateAppointmentStatusFailure(
        error as ApiError,
        appointment,
        oldStatus,
        newStatus,
      ),
    )
  }
}

export function* cancelableByAppointment<Fn extends (...args: any[]) => any>(
  handler: Fn,
  ...params: Parameters<Fn>
) {
  const task: Task = yield fork(handler, ...params)
  // when user moves appointment between columns or when we create/edit/delete appointment,
  // we need to cancel all background sync activities so that when sync completes it won't rewind all users changes
  yield take([
    OPEN_DIALOG,
    UPDATE_APPOINTMENT_STATUS,
    DISABLE_TIMETABLE_AUTO_REFRESH,
    CREATE_APPOINTMENT,
    EDIT_APPOINTMENT,
    DELETE_APPOINTMENT,
    CREATE_BUSY_TIME,
    EDIT_BUSY_TIME,
    DELETE_BUSY_TIME,
  ])
  yield cancel(task)
}

function* setTimetableFiltersSaga({
  isWhiteboard,
}: ReturnType<typeof setTimetableFilters>) {
  const persons: User[] = yield select(getTimetablePersonsList)
  const schedules: Schedule[] = isWhiteboard
    ? yield select(getWhiteboardTeamSchedules)
    : yield select(getSchedules)

  const previousFilteredPersons: string[] = yield select(
    getTimetableFilteredPersons,
  )
  const roles: Record<string, Role> = yield select(getRolesMap)
  const businessId: string = yield select(getCurrentBusinessId)
  const timetableFilteredPersonGroups: string[] = yield select(
    getTimetableFilteredPersonGroups,
  )

  const teamMembersFilters = getTeamMembersFilters(schedules, roles, businessId)
  const selectedTeamMembersFilters = teamMembersFilters.filter(({ id }) =>
    timetableFilteredPersonGroups.includes(id),
  )

  const filteredPersons = persons.filter(
    (person) =>
      !selectedTeamMembersFilters.every((filter) => filter.matcher(person)) ||
      previousFilteredPersons.includes(person.id),
  )

  yield put(updateTimetableFilteredPersons(R.pluck('id', filteredPersons)))
}

function* addFormSaga({
  documents,
  appointmentId,
}: ReturnType<typeof addForm>) {
  try {
    const addedForms = yield* requestAPI(
      API.addForm,
      DocumentSource.APPOINTMENT,
      appointmentId,
      documents.map((doc: Document) => doc.id),
    )
    yield put(addFormSuccess(addedForms, appointmentId))
  } catch (error) {
    yield put(addFormFailure(error as ApiError))
  }
}

function* deleteFormSaga({
  formInstance,
  documentId,
  appointmentId,
}: ReturnType<typeof deleteForm>) {
  try {
    yield* requestAPI(API.deleteForm, formInstance)
    yield put(deleteFormSuccess(documentId, appointmentId))
  } catch (error) {
    yield put(deleteFormFailure(error as ApiError))
  }
}

function* watchCreateAppointment() {
  yield takeEvery(CREATE_APPOINTMENT, createAppointmentSaga)
}

function* watchFetchAppointment() {
  yield takeLatest(FETCH_APPOINTMENT, fetchAppointmentSaga)
}

function* watchEditAppointment() {
  yield takeEvery(EDIT_APPOINTMENT, editAppointmentSaga)
}

function* watchPatchAppointment() {
  yield takeLatest(PATCH_APPOINTMENT, patchAppointmentSaga)
}

function* watchDeleteAppointment() {
  yield takeLatest(DELETE_APPOINTMENT, deleteAppointmentSaga)
}

function* watchCancelFutureAppointments() {
  yield takeLatest(CANCEL_FUTURE_APPOINTMENTS, cancelFutureAppointmentsSaga)
}

function* watchCreateBusyTime() {
  yield takeEvery(CREATE_BUSY_TIME, createBusyTimeSaga)
}

function* watchFetchBusyTime() {
  yield takeLatest(FETCH_BUSY_TIME, fetchBusyTimeSaga)
}

function* watchEditBusyTime() {
  yield takeEvery(EDIT_BUSY_TIME, editBusyTimeSaga)
}

function* watchDeleteBusyTime() {
  yield takeLatest(DELETE_BUSY_TIME, deleteBusyTimeSaga)
}

function* watchUpdateAppointmentStatus() {
  yield takeLatest(UPDATE_APPOINTMENT_STATUS, updateAppointmentStatusSaga)
}

function* watchUpdateAppointmentNotes() {
  yield takeLatest(UPDATE_APPOINTMENT_NOTES, updateAppointmentNotesSaga)
}

function* watchSetTimetableFilters() {
  yield takeLatest(SET_TIMETABLE_FILTERS, setTimetableFiltersSaga)
}

function* watchAddForm() {
  yield takeLatest(ADD_FORM, addFormSaga)
}

function* watchDeleteForm() {
  yield takeLatest(DELETE_FORM, deleteFormSaga)
}

export default function* timetableSaga() {
  yield all([
    watchCreateAppointment(),
    watchFetchAppointment(),
    watchEditAppointment(),
    watchPatchAppointment(),
    watchDeleteAppointment(),
    watchCancelFutureAppointments(),
    watchCreateBusyTime(),
    watchFetchBusyTime(),
    watchEditBusyTime(),
    watchDeleteBusyTime(),
    watchUpdateAppointmentStatus(),
    watchUpdateAppointmentNotes(),
    watchSetTimetableFilters(),
    watchAddForm(),
    watchDeleteForm(),
  ])
}
