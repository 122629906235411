import * as R from 'ramda'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, DateUtils, moment } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getUrlTimetableDate } from '~/components/dashboard/timetable/timetableUtils'
import { SchedulerColumnType } from '~/constants/schedulerConstants'
import { Entities, Schedule } from '~/types'

import {
  fetchAlternateDateSchedulesSuccess,
  fetchBoardingSchedulesFailure,
  fetchBoardingSchedulesSuccess,
  fetchSchedules,
  fetchSchedulesFailure,
  fetchSchedulesSuccess,
  fetchUpcomingEvents,
  fetchUpcomingEventsFailure,
  fetchUpcomingEventsSuccess,
} from '../actions/scheduler'
import { setTimetableFilters } from '../actions/timetable'
import {
  FETCH_ALTERNATE_DATE_SCHEDULES,
  FETCH_BOARDING_SCHEDULES,
  FETCH_SCHEDULES,
  FETCH_UPCOMING_EVENTS,
} from '../actions/types/scheduler'
import { finishLoading, startLoading } from '../duck/progress'
import {
  getShowOtcSaleEvents,
  UPDATE_SHOW_OTC_EVENTS,
} from '../duck/userSettings'
import { cancelableByAppointment } from './timetable'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const isDeletedPerson = (schedule: Schedule, entities: Entities) =>
  schedule.columnType === SchedulerColumnType.PERSON &&
  R.pathEq(['users', schedule.personId, 'deleted'], true)(entities)

const isInactivePerson = (schedule: Schedule, entities: Entities) =>
  schedule.columnType === SchedulerColumnType.PERSON &&
  R.pathEq(['users', schedule.personId, 'active'], false)(entities)

export function* fetchSchedulesCancelable({
  silent,
  date,
}: {
  date?: string
  silent?: boolean
}) {
  try {
    const selectedDate = date || getUrlTimetableDate()

    if (!selectedDate) {
      return
    }

    yield put(startLoading('schedule'))
    const showOtcSaleEvents: boolean = yield select(getShowOtcSaleEvents)

    const { result, entities } = yield* requestAPI(
      API.fetchSchedulesV2,
      DateUtils.serializeDate(selectedDate),
      showOtcSaleEvents,
    )

    yield call(updateEntities, entities)
    yield put(
      fetchSchedulesSuccess({
        ...result,
        schedules: result.schedules?.map((schedule: Schedule) => ({
          ...schedule,
          deleted: isDeletedPerson(schedule, entities),
          active: !isInactivePerson(schedule, entities),
        })),
      }),
    )

    if (!silent) {
      yield put(setTimetableFilters())
    }
  } catch (error) {
    yield put(fetchSchedulesFailure(error as ApiError))
  } finally {
    yield put(finishLoading('schedule'))
  }
}

export function* fetchAlternateDateSchedulesCancelable({
  silent,
  date,
}: {
  date?: string
  silent?: boolean
}) {
  try {
    const selectedDate = date || getUrlTimetableDate()

    if (!selectedDate) {
      return
    }

    yield put(startLoading('schedule'))
    const showOtcSaleEvents: boolean = yield select(getShowOtcSaleEvents)

    const { result, entities } = yield* requestAPI(
      API.fetchSchedulesV2,
      DateUtils.serializeDate(selectedDate),
      showOtcSaleEvents,
    )

    yield call(updateEntities, entities)
    yield put(
      fetchAlternateDateSchedulesSuccess({
        ...result,
        date: selectedDate,
        schedules: result.schedules?.map((schedule: Schedule) => ({
          ...schedule,
          deleted: isDeletedPerson(schedule, entities),
          active: !isInactivePerson(schedule, entities),
        })),
      }),
    )

    if (!silent) {
      yield put(setTimetableFilters())
    }
  } catch (error) {
    yield put(fetchSchedulesFailure(error as ApiError))
  } finally {
    yield put(finishLoading('schedule'))
  }
}

export function* fetchSchedulesSaga(params: ReturnType<typeof fetchSchedules>) {
  yield call(cancelableByAppointment, fetchSchedulesCancelable, params)
}

export function* fetchAlternateDateSchedulesSaga(
  params: ReturnType<typeof fetchSchedules>,
) {
  yield call(
    cancelableByAppointment,
    fetchAlternateDateSchedulesCancelable,
    params,
  )
}

export function* fetchBoardingSchedulesSaga() {
  try {
    const selectedDate = getUrlTimetableDate()

    if (!selectedDate) {
      return
    }

    yield put(startLoading('schedule'))
    const { result, entities } = yield* requestAPI(
      API.fetchBoardingSchedules,
      DateUtils.serializeDate(selectedDate),
    )
    yield call(updateEntities, entities)
    yield put(fetchBoardingSchedulesSuccess(result))
  } catch (error) {
    yield put(fetchBoardingSchedulesFailure(error as ApiError))
  } finally {
    yield put(finishLoading('schedule'))
  }
}

export function* fetchUpcomingEventsSaga({
  clientId,
  patientId,
  includePast,
  filterByClient,
}: ReturnType<typeof fetchUpcomingEvents>) {
  try {
    const startOfTheDay = includePast ? null : moment().toISOString()
    const {
      result: {
        appointments: appointmentsList = [],
        reminders: remindersList = [],
      },
      entities,
    } = yield* requestAPI(
      API.fetchUpcomingEvents,
      clientId,
      patientId,
      startOfTheDay,
      filterByClient,
    )
    yield call(updateEntities, entities)
    yield put(fetchUpcomingEventsSuccess(appointmentsList, remindersList))
  } catch (error) {
    yield put(fetchUpcomingEventsFailure(error as ApiError))
  }
}

function* watchFetchSchedules() {
  yield takeLatest(
    [FETCH_SCHEDULES, UPDATE_SHOW_OTC_EVENTS],
    fetchSchedulesSaga,
  )
}

function* watchFetchAlternateDateSchedules() {
  yield takeLatest(
    FETCH_ALTERNATE_DATE_SCHEDULES,
    fetchAlternateDateSchedulesSaga,
  )
}

function* watchFetchBoardingSchedules() {
  yield takeLatest(FETCH_BOARDING_SCHEDULES, fetchBoardingSchedulesSaga)
}

function* watchFetchUpcomingEvents() {
  yield takeLatest(FETCH_UPCOMING_EVENTS, fetchUpcomingEventsSaga)
}

export default function* schedulerSaga() {
  yield all([
    watchFetchSchedules(),
    watchFetchBoardingSchedules(),
    watchFetchUpcomingEvents(),
    watchFetchAlternateDateSchedules(),
  ])
}
