import React from 'react'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { Status, StatusProps } from '~/components/elements/Status/Status'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'

interface ChewyRetailOrderStatusLabelProps extends StatusProps {
  statusId: string | undefined
}

export const ChewyRetailOrderStatusLabel = ({
  statusId,
  ...rest
}: ChewyRetailOrderStatusLabelProps) => {
  const getChewyOrderState = useGetChewyOrderState()
  const { currentState } = getChewyOrderState(statusId)

  if (!statusId || !currentState) {
    return null
  }

  return (
    <Status flexShrink={0} variant={currentState.variant} {...rest}>
      {LanguageUtils.getTranslatedFieldName(currentState)}
    </Status>
  )
}
