import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import { renderMultiPathRoute } from '../routes/utils'
import CreateWellnessPlanVersionPage from './CreateWellnessPlanVersionPage'
import { CreateWellnessPlanVersionPageNew } from './CreateWellnessPlanVersionPageNew'
import { LinkingWellnessPlansPage } from './LinkingWellnessPlansPage'
import WellnessPlansListPage from './WellnessPlansListPage'

const WellnessPlansPage = () => {
  const isWpAutoRenewalLinkingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_LINKING_AUTO_RENEWAL),
  )
  return (
    <Routes>
      {/* root path: /admin/catalog/wellness-plans */}
      {renderMultiPathRoute({
        path: [
          'clone/:wellnessPlanVersionId',
          'edit/:wellnessPlanVersionId',
          'create',
        ],
        element: isWpAutoRenewalLinkingEnabled ? (
          <CreateWellnessPlanVersionPageNew />
        ) : (
          <CreateWellnessPlanVersionPage />
        ),
      })}
      {renderMultiPathRoute({
        path: [':wellnessPlanVersionId', '/'],
        element: <WellnessPlansListPage />,
      })}
      {renderMultiPathRoute({
        path: ['linking'],
        element: <LinkingWellnessPlansPage />,
      })}
      <Route element={<Navigate replace to="/" />} path="*" />
    </Routes>
  )
}

export default WellnessPlansPage
