import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import { InventoryOrder } from '~/types'

interface OrderItemsPageBreadcrumbsProps {
  inventoryOrder: InventoryOrder
}

export const OrderItemsPageBreadcrumbs = ({
  inventoryOrder: { id, orderNumber },
}: OrderItemsPageBreadcrumbsProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation('Admin')

  const breadcrumbs = [
    {
      label: t('Admin:CATALOG.ORDERS.ORDERS_LABEL'),
      onClick: () => navigate('/admin/catalog/inventories/orders'),
    },
    {
      label: t('Admin:CATALOG.ORDERS.RECEIVE_ORDER_TITLE', { orderNumber }),
      onClick: () => navigate(`/admin/catalog/inventories/orders/${id}`),
    },
    // Will be adding one more breadcrumb for the receiving page
  ]

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        backgroundColor: (theme) => theme.colors.grayGray4,
        height: (theme) => theme.spacing(5),
        py: 1,
        px: 2,
      }}
    >
      {breadcrumbs.map(({ label, onClick }, index) => {
        const isLast = index === breadcrumbs.length - 1
        return (
          <React.Fragment key={label}>
            <Text
              link={!isLast}
              variant="body2"
              onClick={!isLast ? onClick : undefined}
            >
              {label}
            </Text>
            {!isLast && (
              <Text aria-hidden="true" variant="body2">
                {'>'}
              </Text>
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}
