import { Defaults, Nil, User } from '@pbt/pbt-ui-components'

import { requestQuery } from '~/api/graphql/graphqlCore'
import { FETCH_CLIENT_BILLING_ACTIVITY } from '~/api/graphql/queries/client'
import i18n from '~/locales/i18n'
import { ClientData } from '~/types/entities/clients'

import { getAPIPath, getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const createClient = (
  businessId: string,
  client: ClientData,
  verify = false,
) =>
  request(
    getAPIPath('clients'),
    {
      method: 'POST',
      data: client,
      params: { businessId, verify, originalBusinessId: businessId },
    },
    true,
    Schema.user,
  )

export const findClients = (
  businessId: string,
  data: Partial<Pick<User, 'email' | 'mobilePhone'>>,
) =>
  request(
    getAPIPath('clients/find'),
    {
      method: 'POST',
      data,
      params: { businessId, originalBusinessId: businessId },
    },
    true,
    Schema.userArray,
  )

export const updateClientActiveState = (
  businessId: string,
  clientId: string,
  clientActivityState: boolean,
) =>
  request(
    getAPIPath(`clients/${clientId}/businessScope`),
    {
      method: 'PUT',
      data: { active: clientActivityState },
      params: { businessId, originalBusinessId: businessId },
    },
    true,
    Schema.user,
  )

export const updateClient = (businessId: string, client: User) =>
  request(
    getAPIPath(`clients/${client.id}`),
    {
      method: 'PUT',
      data: client,
      params: { businessId, originalBusinessId: businessId },
    },
    true,
    Schema.user,
  )

export const partialUpdateClient = (businessId: string, client: User) =>
  request(
    getAPIPath(`clients/${client.id}`),
    {
      method: 'PATCH',
      data: client,
      params: { businessId, originalBusinessId: businessId },
    },
    true,
    Schema.user,
  )

export const fetchClient = (
  businessId: string,
  clientId: string,
  withGlobalClientPreferences?: boolean,
) =>
  request(
    getAPIPath(`clients/${clientId}`),
    {
      method: 'GET',
      params: {
        businessId,
        originalBusinessId: businessId,
        language: i18n.language,
        withGlobalClientPreferences,
      },
    },
    true,
    Schema.user,
  )

export const fetchClientByPatientId = (businessId: string, patientId: string) =>
  request(
    getAPIPath('clients'),
    {
      method: 'GET',
      params: {
        businessId,
        originalBusinessId: businessId,
        patientId,
        language: i18n.language,
      },
    },
    true,
    Schema.user,
  )

export const fetchClients = (
  businessId: string,
  from = 0,
  to = Defaults.CLIENTS_BATCH_LOAD_COUNT,
  query: string | Nil,
  fieldsQuery: string,
  showLastAppointment: boolean | Nil,
  includeInactive: boolean | Nil,
  sharedOnly: boolean | Nil,
  groupSearchRequire: boolean | Nil,
) =>
  request(
    getAPIPath('clients'),
    {
      method: 'GET',
      params: {
        businessId,
        from,
        to,
        query: fieldsQuery ? query || '' : query,
        fieldsQuery,
        lastAppointment: showLastAppointment,
        includeInactive,
        sharedOnly,
        groupSearchRequire,
        language: i18n.language,
        originalBusinessId: businessId,
      },
    },
    true,
    Schema.clientsList,
  )

export const fetchClientsMembershipFilters = (businessId: string) =>
  request(
    getPreludeAPIPath('wplans/membership/filters'),
    {
      method: 'GET',
      params: {
        businessId,
        language: i18n.language,
        originalBusinessId: businessId,
      },
    },
    true,
  )

export const validateClientEmail = (businessId: string, clientId: string) =>
  request(
    getAPIPath(`clients/validate-email/${clientId}`),
    { method: 'POST', params: { businessId, originalBusinessId: businessId } },
    true,
  )

export const sendClientEmailVerification = (
  businessId: string,
  clientId: string,
) =>
  request(
    getAPIPath(`auth/verification-email/send/${clientId}`),
    { method: 'POST', params: { businessId, originalBusinessId: businessId } },
    true,
  )

export const fetchClientBillingActivity = (includeProcessingRefunds: boolean) =>
  requestQuery({
    query: FETCH_CLIENT_BILLING_ACTIVITY(includeProcessingRefunds),
    variablesHandler: (currentBusinessId, clientId) => ({
      businessId: currentBusinessId,
      clientId,
    }),
  })
