import { includes, toLower } from 'ramda'

export const InventoryItemState = {
  DISPENSED: 'Dispensed',
  DECLINED: 'Declined',
  ORDERED: 'Ordered',
}

export const ProcedureState = {
  DECLINED: 'Declined',
  COMPLETED: 'Completed',
  ORDERED: 'Ordered',
}

export const LabTestState = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  DECLINED: 'Declined',
  DELETED: 'Deleted',
  IN_PROGRESS: 'In-progress',
  ORDERED: 'Ordered',
  PARTIAL_RESULTS: 'Partial results',
  PENDING: 'Pending',
  RESULTS_RECEIVED: 'Results Received',
  SAMPLE_COLLECTED: 'Sample Collected',
  SAMPLE_RECEIVED: 'Sample Received',
  SAMPLES_NOT_RECEIVED: 'Samples not received',
  SELECTED: 'Selected',
  SHIPPED: 'Shipped',
  UNSUCCESSFUL: 'Unsuccessful',
  WAITING_ON_SAMPLE: 'Waiting on sample',
  UPDATED_RESULTS: 'Updated Results',
}

export const LabOrdersStatus = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  CREATED: 'Created',
  PARTIAL_RESULTS: 'Partial results',
  PENDING: 'Pending',
  RESULTS_RECEIVED: 'Results received',
  SUBMITTED: 'Submitted',
  UPDATED_RESULTS: 'Updated results',
}

export const LabTestTypes = {
  IN_HOUSE: 'In-house',
  REFERENCE: 'Reference',
}

export const ReadyToOrderLabStatuses = [
  LabTestState.SELECTED,
  LabTestState.WAITING_ON_SAMPLE,
  LabTestState.SAMPLE_COLLECTED,
]

export const NotSelectableLabTestStates = [LabTestState.DELETED]

export const CancellableLabOrderStates = [
  LabOrdersStatus.CREATED,
  LabOrdersStatus.SUBMITTED,
]

export const DeletableLabOrderStates = [
  LabOrdersStatus.CREATED,
  LabOrdersStatus.SUBMITTED,
  LabOrdersStatus.PENDING,
]

export const DeclinableLabTestsStates = [
  LabTestState.ORDERED,
  LabTestState.SAMPLE_COLLECTED,
  LabTestState.SAMPLE_RECEIVED,
  LabTestState.UNSUCCESSFUL,
  LabTestState.SELECTED,
  LabTestState.WAITING_ON_SAMPLE,
  LabTestState.SAMPLES_NOT_RECEIVED,
  LabTestState.PENDING,
]

const SubmittedOrCompletedStatuses: string[] = [
  LabOrdersStatus.SUBMITTED,
  LabOrdersStatus.RESULTS_RECEIVED,
  LabOrdersStatus.PARTIAL_RESULTS,
  LabOrdersStatus.COMPLETED,
  LabOrdersStatus.UPDATED_RESULTS,
]

export const includesState = (status = '', array: string[] = []) =>
  includes(status.toLowerCase(), array.map(toLower))

export const isSubmittedOrCompletedOrder = (status: string) =>
  includesState(status, SubmittedOrCompletedStatuses)

const ResultsReceivedOrCompletedStatuses = [
  LabOrdersStatus.RESULTS_RECEIVED,
  LabOrdersStatus.PARTIAL_RESULTS,
  LabOrdersStatus.COMPLETED,
  LabOrdersStatus.UPDATED_RESULTS,
]

export const orderHasResults = (status: string) =>
  includesState(status, ResultsReceivedOrCompletedStatuses)

export const LabTestStateWithIssue = [
  LabTestState.WAITING_ON_SAMPLE,
  LabTestState.UNSUCCESSFUL,
]

export const LabTestStateIsDone = [
  LabTestState.COMPLETED,
  LabTestState.RESULTS_RECEIVED,
  LabTestState.UPDATED_RESULTS,
]

export const LabTestStateEasterEgg = [
  LabTestState.COMPLETED,
  LabTestState.ORDERED,
  LabTestState.SAMPLE_COLLECTED,
  LabTestState.SAMPLE_RECEIVED,
]

export const PrescriptionItemState = {
  APPROVED: 'Approved',
  CANCELED: 'Canceled',
  DECLINED: 'Declined',
  DRAFT: 'Draft',
  ORDERED: 'Ordered',
  PRESCRIBED: 'Prescribed',
  SUBMITTED: 'Submitted',
}

export enum BodySystemState {
  NC = 'NC',
  WNL = 'WNL',
  ONL = 'ONL',
}

export enum OrderType {
  PRICE = 'PRICE',
  PROCEDURE = 'PROCEDURE',
  LAB_TEST = 'LAB_TEST',
  INVENTORY = 'INVENTORY',
  BUNDLE = 'BUNDLE',
  PREPAID = 'PREPAID',
  PRESCRIPTION = 'PRESCRIPTION',
  // TODO: find out whether it could be removed
  TASK = 'TASK',
  REFILL = 'REFILL',
  REMINDER = 'REMINDER',
  BENEFIT = 'BENEFIT',
  WPLANS = 'WPLANS',
}

export enum SOAPStep {
  APPOINTMENT = 'APPOINTMENT',
  QUESTIONS = 'QUESTIONS',
  VITALS = 'VITALS',
  ORDER = 'ORDER',
  TO_DO = 'TO_DO',
  WRAP_UP = 'WRAP_UP',
  CHECK_OUT = 'CHECK_OUT',
  MARKETPLACE = 'MARKETPLACE',
  PROBLEMS = 'PROBLEMS',
}

export const SOAPStepsList = [
  SOAPStep.APPOINTMENT,
  SOAPStep.QUESTIONS,
  SOAPStep.VITALS,
  SOAPStep.PROBLEMS,
  SOAPStep.ORDER,
  SOAPStep.TO_DO,
  SOAPStep.WRAP_UP,
  SOAPStep.CHECK_OUT,
  SOAPStep.MARKETPLACE,
]
