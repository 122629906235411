import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FabProps, FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Business as BusinessType,
  CityTextField,
  ClassesType,
  CountrySelect,
  PhoneUtils,
  PuiTextField,
  RegionUtils,
  StateSelect,
  TimezoneSelect,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import Business, { BusinessDto } from '~/store/dto/Business'
import { getTimezoneGroups, getTimezones } from '~/store/reducers/constants'

import BusinessLogoPaper from './BusinessLogoPaper'
import RegistrationPage from './RegistrationPage'

const useStyles = makeStyles(
  (theme) => ({
    input: {
      marginTop: 0,
    },
    inputContainer: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    form: {
      paddingBottom: 70,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 12,
      },
    },
    logoPaperImage: {
      width: 216,
      height: 121,
      marginTop: 5,
      marginBottom: 5,
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        height: 114,
      },
    },
    header: {},
    imageRoot: {},
    registrationRoot: {},
    registrationBody: {},
  }),
  { name: 'ClinicEdit' },
)

interface ClinicEditProps {
  backLabel?: string
  buttonColor?: FabProps['color']
  classes?: ClassesType<typeof useStyles>
  clinic: BusinessType | BusinessDto
  headerText: string
  onBackToMapClick: () => void
  onSubmit: (business: BusinessType | BusinessDto) => void
  onUploadTrigger: (business: BusinessType | BusinessDto) => void
  subheaderText?: string
  submitLabel?: string
}

const ClinicEdit = ({
  clinic,
  backLabel,
  onBackToMapClick,
  headerText,
  subheaderText,
  submitLabel,
  buttonColor,
  onSubmit,
  onUploadTrigger,
  classes: classesProp,
}: ClinicEditProps) => {
  const classes = useStyles({ classes: classesProp })
  const isEditPage = Boolean(clinic)
  const { t } = useTranslation(['Common', 'Registration'])

  const Timezones = useSelector(getTimezones)
  const TimezoneGroups = useSelector(getTimezoneGroups)

  const [requiredFieldsEmptyFlagShow, setRequiredFieldsEmptyFlagShow] =
    useState(false)

  const { fields, validate, reset } = useFields([
    {
      name: 'address',
      label: t('Common:PRACTICE_ADDRESS'),
      validators: ['required'],
      initialValue: clinic.address,
    },
    {
      name: 'city',
      label: t('Common:CITY'),
      validators: ['required'],
      initialValue: clinic.city,
    },
    {
      name: 'name',
      label: t('Registration:CLINIC_EDIT.ENTER_PRACTICE_NAME'),
      validators: ['required'],
      initialValue: clinic.name,
    },
    {
      name: 'phone',
      label: `${t('Common:PRACTICE_PHONE_NUMBER')}*`,
      validators: ['phoneRequired', 'phone'],
      initialValue: PhoneUtils.getPhoneNumber(clinic.phone),
    },
    {
      name: 'state',
      label: t('Common:STATE'),
      validators: ['required'],
      initialValue: clinic.state,
    },
    {
      name: 'country',
      label: t('Common:COUNTRY'),
      validators: ['required'],
      initialValue: RegionUtils.getAvailableCountry(clinic.country),
    },
    {
      name: 'timeZone',
      label: t('Common:TIMEZONE'),
      validators: ['required'],
      initialValue: clinic.timeZone,
    },
    {
      name: 'suite',
      label: t('Common:SUITE_NUMBER'),
      initialValue: clinic.suite,
    },
    {
      name: 'website',
      label: t('Common:WEBSITE'),
      validators: ['website'],
      initialValue: clinic.website,
    },
    {
      name: 'zipcode',
      label: t('Common:ZIP_CODE'),
      validators: ['required', 'zip'],
      initialValue: clinic.zipcode,
    },
  ])

  const {
    address,
    city,
    name,
    phone,
    state,
    country,
    timeZone,
    suite,
    website,
    zipcode,
  } = fields

  const fieldValues = Object.values(fields).map((field) => field.value)

  useEffect(() => {
    reset()
  }, [clinic])

  useEffect(() => {
    setRequiredFieldsEmptyFlagShow(false)
  }, fieldValues)

  const createBusiness = (): BusinessDto =>
    new Business({
      id: clinic && clinic.id,
      address: address.value.trim(),
      city: city.value.trim(),
      logo: clinic.logo,
      name: name.value.trim(),
      phone: PhoneUtils.parsePhoneNumber(phone.value),
      state: state.value,
      country: country.value,
      suite: suite.value.trim(),
      website: website.value.trim(),
      zipcode: zipcode.value,
      timeZone: timeZone.value,
    })

  const saveClinic = () => {
    if (validate()) {
      onSubmit(createBusiness())
    } else {
      setRequiredFieldsEmptyFlagShow(true)
    }
  }

  return (
    <RegistrationPage
      additionalInfo={
        <div className={classes.logoPaperImage} key={clinic.id}>
          <BusinessLogoPaper
            allowUpload
            business={clinic}
            classes={{
              imageRoot: classes.imageRoot,
            }}
            triggerUpload={() => onUploadTrigger(createBusiness())}
          />
        </div>
      }
      backLabel={backLabel || t('Common:BACK_TO_MAP')}
      buttonColor={buttonColor}
      classes={{
        body: classes.registrationBody,
        root: classes.registrationRoot,
        header: classes.header,
      }}
      header={headerText}
      subheader={subheaderText}
      submitLabel={
        submitLabel ||
        (isEditPage ? t('Common:SAVE_ACTION') : t('Common:ADD_YOUR_PRACTICE'))
      }
      onBack={onBackToMapClick}
      onSubmit={saveClinic}
    >
      <Grid
        container
        alignItems="flex-end"
        className={classes.form}
        spacing={1}
      >
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              autoFocus
              InputProps={{
                inputProps: {
                  maxLength: 100,
                },
              }}
              className={classes.input}
              field={name}
              label={`${name.label}*`}
            />
          </div>
        </Grid>
        <Grid item md={9} xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              className={classes.input}
              field={address}
              inputProps={{ maxLength: 100 }}
              label={`${address.label}*`}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              className={classes.input}
              field={suite}
              inputProps={{ maxLength: 10 }}
              label={suite.label}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={8}>
          <div className={classes.inputContainer}>
            <CityTextField
              required
              className={classes.input}
              country={country.value}
              field={city}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={4}>
          <div className={classes.inputContainer}>
            <CountrySelect
              required
              field={{
                ...country,
                set: (event) => {
                  country.set(event)
                  zipcode.setValue('')
                  state.setValue('')
                },
              }}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={4}>
          <div className={classes.inputContainer}>
            <StateSelect
              required
              className={classes.input}
              country={country.value}
              field={state}
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              InputProps={{
                inputComponent: ZipInput,
                inputProps: { country: country.value },
              }}
              className={classes.input}
              field={zipcode}
              label={`${zipcode.label}*`}
            />
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <FormControl fullWidth className={classes.input} margin="normal">
              <InputLabel htmlFor="timezone-select">
                {timeZone.label}*
              </InputLabel>
              <TimezoneSelect
                useTimezoneGroupVariant
                timezoneGroups={TimezoneGroups}
                timezones={Timezones}
                value={timeZone.value}
                onChange={(event) => {
                  timeZone.setValue(event.target.value)
                }}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.inputContainer}>
            <PhoneInput className={classes.input} field={phone} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.inputContainer}>
            <PuiTextField
              className={classes.input}
              field={website}
              inputProps={{ maxLength: 100 }}
              label={website.label}
            />
          </div>
        </Grid>
        <Grid item pl={1} xs={12}>
          <RequiredFieldsNotice
            hasRequiredErrors={requiredFieldsEmptyFlagShow}
          />
        </Grid>
      </Grid>
    </RegistrationPage>
  )
}

export default ClinicEdit
