import React from 'react'
import { IconButton, styled, Theme } from '@mui/material'
import { Calendar, Field, PuiTextField } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import Typography from '../Typography/Typography'
import { PreviousMedicalHistoryItem } from './PreviousMedicalHistoryItem'

export const DiagnosisHistoryItemColumnWidths = [
  '20%',
  '15%',
  '5%',
  '30%',
  '5%',
  '20%',
  '5%',
]

export interface DiagnosisHistoryItemProps {
  backgroundColor?: keyof Theme['colors']
  date: Field
  doctor: Field
  name: string
  notes: Field
  onDelete: () => void
  practice: Field
}

const StyledH3 = styled(Typography.H3)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`

export const DiagnosisHistoryItem = ({
  name,
  date,
  practice,
  doctor,
  notes,
  backgroundColor,
  onDelete,
}: DiagnosisHistoryItemProps) => (
  <PreviousMedicalHistoryItem
    backgroundColor={backgroundColor}
    fields={[
      {
        name: 'Diagnosis',
        field: <StyledH3>{name}</StyledH3>,
        width: DiagnosisHistoryItemColumnWidths[0],
      },
      {
        name: 'Date',
        field: <Calendar fullWidth field={date} margin="none" />,
        width: DiagnosisHistoryItemColumnWidths[1],
      },
      {
        name: 'Spacer',
        field: '',
        width: DiagnosisHistoryItemColumnWidths[2],
      },
      {
        name: 'Practice',
        field: <PuiTextField field={practice} margin="none" />,
        width: DiagnosisHistoryItemColumnWidths[3],
      },
      {
        name: 'Spacer2',
        field: '',
        width: DiagnosisHistoryItemColumnWidths[4],
      },
      {
        name: 'Doctor',
        field: <PuiTextField field={doctor} margin="none" />,
        width: DiagnosisHistoryItemColumnWidths[5],
      },
      {
        name: 'Actions',
        field: (
          <IconButton
            sx={{ minHeight: 0, minWidth: 0, padding: 0, marginTop: '0.5rem' }}
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        ),
        width: DiagnosisHistoryItemColumnWidths[6],
      },
    ]}
    notes={notes}
  />
)
