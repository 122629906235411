import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CustomFieldValidatorState,
  Field,
  Nil,
  PuiCheckbox,
  PuiSelect,
  PuiTextField,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { CancellationInfo } from '~/constants/cancellationReasonType'
import { getInternalCancellationReasons } from '~/store/reducers/constants'
import { isNilOrEmpty } from '~/utils'
import { useIsWaivableCancellationReason } from '~/utils/appointmentCancellationUtils'

import { useUpdateInternalNotesForAppointmentCancellation } from './useUpdateInternalNotesForAppointmentCancellation'

const useStyles = makeStyles(
  () => ({
    checkbox: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    otherReasonWarningToolTip: {
      maxWidth: 300,
      width: 300,
    },
  }),
  { name: 'Appointment' },
)
export interface AppointmentCancellationDetailsProps {
  cancellationInfo: CancellationInfo
  internalNotes: Field
  isAppointmentWithin24Hours?: boolean
  isNoShowConsentAppointmentType: boolean
  setCancellationInfo: Dispatch<SetStateAction<CancellationInfo | {}>>
  setInternalNote: (value: string) => void
  setWaiveLateCancellationFeeChecked: Dispatch<SetStateAction<boolean | Nil>>
  waiveLateCancellationFeeChecked: boolean | Nil
}
export const AppointmentCancellationDetails = forwardRef(
  function AppointmentCancellationDetails(
    {
      isAppointmentWithin24Hours,
      isNoShowConsentAppointmentType,
      cancellationInfo,
      setCancellationInfo,
      waiveLateCancellationFeeChecked,
      internalNotes,
      setInternalNote,
      setWaiveLateCancellationFeeChecked,
    }: AppointmentCancellationDetailsProps,
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common'])
    const appointmentCancellationReasons = useSelector(
      getInternalCancellationReasons,
    )

    const { fields, validate } = useFields(
      [
        {
          name: 'cancellationReason',
          label: t('Common:CANCELLATION_REASON'),
          type: 'select',
          initialValue: cancellationInfo.cancellationReasonId || '',
        },
        {
          name: 'waiveLateCancellationFee',
          label: t('Common:WAIVE_LATE_CANCELLATION_FEE'),
        },
        {
          name: 'otherReasonField',
          initialValue: cancellationInfo.cancellationReason || '',
          messages: {
            otherReasonForCancellation: t(
              'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_VALIDATION_ERROR',
            ),
          },
          validators: [
            {
              validator: ({ value }: CustomFieldValidatorState) => value,
              validatorName: 'otherReasonForCancellation',
            },
          ],
        },
      ],
      false,
    )

    const { cancellationReason, waiveLateCancellationFee, otherReasonField } =
      fields

    const { isWaivableCancellationReason } = useIsWaivableCancellationReason()

    const getShowWaiveFeeCheckboxValue = (cancellationReasonId: string) =>
      !isNilOrEmpty(cancellationReasonId) &&
      isWaivableCancellationReason(cancellationReasonId) &&
      Boolean(isAppointmentWithin24Hours) &&
      isNoShowConsentAppointmentType

    const [showWaiveFeeCheckbox, setShowWaiveFeeCheckbox] = useState(
      getShowWaiveFeeCheckboxValue(cancellationInfo.cancellationReasonId),
    )

    const otherReasonId = Utils.findConstantIdByName(
      'Other',
      appointmentCancellationReasons,
    )

    const showOtherReasonTextField = cancellationReason.value === otherReasonId

    const [otherReasonFieldValue, setOtherReasonFieldValue] = useState(
      otherReasonField?.initialValue || '',
    )

    const {
      onCancellationReasonChange,
      onWaiveLateCancellationFeeChange,
      onOtherReasonTextChange,
    } = useUpdateInternalNotesForAppointmentCancellation({
      internalNotes,
      setInternalNote,
      waiveLateCancellationFee,
      showWaiveFeeCheckbox,
      otherReasonField,
      isAppointmentWithin24Hours,
      isNoShowConsentAppointmentType,
    })

    useEffect(() => {
      const showWaiveFeeCheckboxValue = getShowWaiveFeeCheckboxValue(
        cancellationReason.value,
      )
      setShowWaiveFeeCheckbox(showWaiveFeeCheckboxValue)

      // Whenever we switch to a cancellation reason where the waive-fee
      // checkbox should be shown, we default the checkbox to checked
      if (showWaiveFeeCheckboxValue) {
        waiveLateCancellationFee.setValue(true)
        setWaiveLateCancellationFeeChecked(true)
      }
    }, [cancellationReason.value])

    useEffect(() => {
      if (showWaiveFeeCheckbox) {
        // When the checkbox changed to visible state, we take the existing value
        // from the cancellation dialog
        waiveLateCancellationFee.setValue(waiveLateCancellationFeeChecked)
        setWaiveLateCancellationFeeChecked(waiveLateCancellationFeeChecked)
      } else {
        // When the checkbox is hidden, it doesn't exist hence set it to null
        waiveLateCancellationFee.setValue(null)
        setWaiveLateCancellationFeeChecked(null)
      }
    }, [showWaiveFeeCheckbox])

    const onSelectReason = (value: string) => {
      const reason = appointmentCancellationReasons.filter(
        (reason) => reason.id === value,
      )
      if (reason.length > 0 && reason[0].name) {
        onCancellationReasonChange(reason[0])
        cancellationReason.setValue(reason[0].id)

        otherReasonField?.setValue('')
        setOtherReasonFieldValue('')

        if (reason[0].id !== otherReasonId) {
          setCancellationInfo({
            cancellationReasonId: reason[0].id,
            cancellationReason: reason[0].name,
          })
        } else {
          setCancellationInfo({
            cancellationReasonId: reason[0].id,
            cancellationReason: otherReasonField?.value,
          })
        }
      }
    }

    const onCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
      onWaiveLateCancellationFeeChange(event.target.checked)
      waiveLateCancellationFee.setValue(event.target.checked)
      setWaiveLateCancellationFeeChecked(event.target.checked)
    }

    const handleOtherReasonTextFieldChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      otherReasonField?.setValue(event.target.value)
      setOtherReasonFieldValue(event.target.value)
      setCancellationInfo({
        ...cancellationInfo,
        cancellationReason: event.target.value,
      })
    }

    useEffect(() => {
      if (!R.isNil(otherReasonField?.value)) {
        onOtherReasonTextChange()
      }
    }, [otherReasonField?.value])

    useImperativeHandle(ref, () => ({
      validate,
    }))

    return (
      <Grid container>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="cancellation-reason-select">
              {t('Common:CANCELLATION_REASON')}
            </InputLabel>
            <PuiSelect
              field={cancellationReason}
              items={appointmentCancellationReasons}
              onChange={Utils.handleFormSelectInput(onSelectReason)}
            />
          </FormControl>
        </Grid>
        {showWaiveFeeCheckbox && (
          <Grid
            item
            className={classes.checkbox}
            sx={{ paddingLeft: 2 }}
            xs={6}
          >
            <PuiCheckbox
              checked={waiveLateCancellationFee?.value}
              label={t('Common:WAIVE_LATE_CANCELLATION_FEE')}
              onChange={onCheckBox}
            />
          </Grid>
        )}
        {showOtherReasonTextField && (
          <Grid item xs={12}>
            <PuiTextField
              TooltipProps={{
                classes: { errorTooltip: classes.otherReasonWarningToolTip },
              }}
              field={otherReasonField}
              inputProps={{ maxLength: 1500 }}
              label={t(
                'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
              )}
              value={otherReasonFieldValue}
              onChange={handleOtherReasonTextFieldChange}
            />
          </Grid>
        )}
      </Grid>
    )
  },
)
