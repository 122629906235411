import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import * as R from 'ramda'
import { AnyAction } from 'redux'

import type { RootState } from '~/store'
import { AUTH_SENSITIVE_ACTIONS } from '~/store/actions/auth'
import { MIGRATION_SENSITIVE_ACTIONS } from '~/store/actions/migration'
import { REGISTRATION_SENSITIVE_ACTIONS } from '~/store/actions/registration'
import { WELLNESS_PLANS_SENSITIVE_ACTIONS } from '~/store/actions/wellnessPlans'
import {
  CONFERENCING_SENSITIVE_ACTIONS,
  getConferencingSanitized,
} from '~/store/duck/conferencing'
import {
  getImagingVendorSanitized,
  IMAGING_VENDOR_CONFIG_SENSITIVE_ACTIONS,
} from '~/store/duck/imagingVendorConfig'
import {
  getLabVendorConfigSanitized,
  LAB_VENDOR_CONFIG_SENSITIVE_ACTIONS,
} from '~/store/duck/labVendorConfig'
import { getAuthSanitized } from '~/store/reducers/auth'
import { getConstantsSanitized } from '~/store/reducers/constants'
import { getMigrationSanitized } from '~/store/reducers/migration'
import { getRegistrationSanitized } from '~/store/reducers/registration'
import { getWellnessPlansSanitized } from '~/store/reducers/wellnessPlans'

import { arrayToMap } from '.'

const privateNetworkFieldNames = [
  'password',
  'token',
  'accessToken',
  'refreshToken',
  'locationToken',
  'zoomAccountId',
  'zoomClientId',
  'zoomClientSecret',
]

const SENSITIVE_ACTIONS = [
  ...AUTH_SENSITIVE_ACTIONS,
  ...LAB_VENDOR_CONFIG_SENSITIVE_ACTIONS,
  ...CONFERENCING_SENSITIVE_ACTIONS,
  ...IMAGING_VENDOR_CONFIG_SENSITIVE_ACTIONS,
  ...WELLNESS_PLANS_SENSITIVE_ACTIONS,
  ...MIGRATION_SENSITIVE_ACTIONS,
  ...REGISTRATION_SENSITIVE_ACTIONS,
]

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(
  privateNetworkFieldNames,
)

const cacheSensitiveActionsMap = arrayToMap(SENSITIVE_ACTIONS, R.prop('type'))

const LogRocketSanitizer: any = {
  requestSanitizer: (req: any) => {
    req.headers['x-auth-token'] = null
    req.headers.Authorization = null

    // avoid sending GET params with sensitive data
    const hasSensitiveParamsOnUrl = privateNetworkFieldNames.some((data) =>
      req.url.includes(data),
    )
    if (hasSensitiveParamsOnUrl) {
      ;[req.url] = req.url.split('?')
    }

    requestSanitizer(req)

    return req
  },
  responseSanitizer,
  stateSanitizer: (state: RootState) => ({
    ...state,
    auth: getAuthSanitized(state),
    conferencing: getConferencingSanitized(state),
    imagingVendorConfig: getImagingVendorSanitized(state),
    migration: getMigrationSanitized(state),
    labVendorConfig: getLabVendorConfigSanitized(state),
    registration: getRegistrationSanitized(state),
    wellnessPlans: getWellnessPlansSanitized(state),
    constants: getConstantsSanitized(),
  }),
  actionSanitizer: (actionProp: AnyAction) => {
    let action = { ...actionProp }

    // LogRocket has a 4MB limit on the size of actions
    const actionSize = new Blob([JSON.stringify(action)]).size
    if (actionSize >= 3000000) {
      action = {
        type: action.type,
        payload: 'Action too large to log',
      }
      LogRocket.log(
        'Redux action too large to log: ',
        action,
        ', size: ',
        actionSize,
        ' bytes',
      )
    }

    const currentRawAction = R.prop(action.type, cacheSensitiveActionsMap)

    if (currentRawAction) {
      const sanitizedAction = { ...action }
      const overrideAsSensitive = (sensitiveKey: string) => {
        if (sanitizedAction[sensitiveKey]) {
          sanitizedAction[sensitiveKey] = '*'
        }
      }
      currentRawAction.sensitiveData?.forEach(overrideAsSensitive)

      return sanitizedAction
    }

    return action
  },
}

export default LogRocketSanitizer
