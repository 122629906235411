import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getCvcCumulusRhapsodyIntegrationEnabled } from '~/store/reducers/constants'

import ResponsiveRoute from '../../../routes/ResponsiveRoute'
import { renderMultiPathRoute } from '../../../routes/utils'
import PricePage from './PricePage'
import { ProcedurePriceCreateRedirect } from './ProcedurePriceCreateRedirect'
import ProceduresBulkPricesPage from './ProceduresBulkPricesPage'
import ProceduresList from './ProceduresList'

const ProceduresPage = () => {
  const isCvcCumulusRhapsodyIntegrationEnabled = useSelector(
    getCvcCumulusRhapsodyIntegrationEnabled,
  )

  return (
    <Routes>
      {/* root path: /admin/catalog/procedures/ */}
      <Route
        element={
          isCvcCumulusRhapsodyIntegrationEnabled ? (
            <ProcedurePriceCreateRedirect />
          ) : (
            <ResponsiveRoute
              redirectWithReplace
              xs
              redirectTo="/admin/catalog/procedures/:procedureId"
            >
              <PricePage />
            </ResponsiveRoute>
          )
        }
        path=":procedureId/prices/create"
      />
      <Route
        element={
          <ResponsiveRoute
            redirectWithReplace
            xs
            redirectTo="/admin/catalog/procedures/:procedureId"
          >
            <PricePage />
          </ResponsiveRoute>
        }
        path=":procedureId/prices/:priceId"
      />
      <Route
        element={
          isCvcCumulusRhapsodyIntegrationEnabled ? (
            <ProcedurePriceCreateRedirect />
          ) : (
            <ProceduresBulkPricesPage />
          )
        }
        path="prices"
      />
      {renderMultiPathRoute({
        element: <ProceduresList />,
        path: [':procedureId', '/'],
      })}
      <Route element={<Navigate replace to="/" />} path="*" />
    </Routes>
  )
}

export default ProceduresPage
