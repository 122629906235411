/* eslint-disable react/no-multi-comp */
import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import {
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { ChewyRetailOrderStatusLabel } from '~/components/dashboard/invoices/elements/ChewyRetailOrderStatusLabel/ChewyRetailOrderStatusLabel'
import RefundInvoiceStatusLabel from '~/components/dashboard/invoices/RefundInvoiceStatusLabel'
import { Invoice } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'
import { isRefundInvoice } from '~/utils/refundUtils'

import InvoiceStatusLabel, {
  SimpleInvoiceStatusLabel,
} from '../../../../invoices/InvoiceStatusLabel'
import { MultipleLinesCell } from './MultipleLinesCell'

const useStyles = makeStyles(
  () => ({
    container: {},
    status: {
      width: 'fit-content',
      fontSize: '1.2rem',
    },
  }),
  { name: 'InvoiceTableStatusCell' },
)

interface InvoiceTableStatusCellProps {
  classes?: ClassesType<typeof useStyles>
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const InvoiceLineItem = (
  props: {
    index: number
    state: string | undefined
    stateId: string
  } & ChargesSection,
) => {
  const classes = useStyles()

  if (props.index === 0) {
    return (
      <SimpleInvoiceStatusLabel
        className={classes.status}
        state={props.state}
        stateId={props.stateId}
      />
    )
  }

  return <></>
}
const RetailOrderLineItem = (props: UIRoliSection) => (
  <ChewyRetailOrderStatusLabel
    labelSx={{ px: 0.5 }}
    statusId={props.state!.id}
    sx={{ p: 0 }}
  />
)

export const InvoiceTableStatusCell = ({
  classes: classesProp,
  invoice,
  multipleRowsLimit,
}: InvoiceTableStatusCellProps) => {
  const classes = useStyles({ classes: classesProp })
  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const stateId = 'stateId' in invoice ? invoice.stateId : ''
  const InvoiceLineItemWrapper = useCallback(
    (props: ChargesSection & { index: number }) => (
      <InvoiceLineItem
        {...props}
        state={invoice.state as string | undefined}
        stateId={stateId}
      />
    ),
    [invoice.state, stateId],
  )

  if (isRefundInvoice(invoice.invoiceNo)) {
    return (
      <Box className={classes.container}>
        <RefundInvoiceStatusLabel
          stateId={(invoice as RefundInvoice).state.id}
        />
      </Box>
    )
  }

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItemWrapper}
        RetailOrderLineItem={RetailOrderLineItem}
        sections={sections}
      />
    )
  }

  return (
    <Box className={classes.container}>
      <Box>
        <InvoiceStatusLabel
          className={classes.status}
          invoice={invoice as Invoice | GraphqlInvoice}
        />
      </Box>
    </Box>
  )
}
