import React from 'react'

import {
  remindersCatalogActions,
  remindersCatalogSelectors,
  // @ts-ignore
} from '~/store/duck/remindersCatalog'

import AddCatalogItemsDialog, {
  AddCatalogItemsDialogProps,
} from './AddCatalogItemsDialog'

interface AddReminderProtocolItemsDialogProps
  extends Omit<AddCatalogItemsDialogProps, 'actions' | 'selectors'> {}

const AddReminderProtocolItemsDialog = (
  props: AddReminderProtocolItemsDialogProps,
) => (
  <AddCatalogItemsDialog
    actions={remindersCatalogActions as AddCatalogItemsDialogProps['actions']}
    selectors={
      remindersCatalogSelectors as AddCatalogItemsDialogProps['selectors']
    }
    {...props}
  />
)

export default AddReminderProtocolItemsDialog
