import { useSelector } from 'react-redux'

import {
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { Variation } from '~/types'

import { getOnHandAmount } from '../inventoryUtils'

const OnHandAmountCell = (item: Variation) => {
  const PackageType = useSelector(getPackageType)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

  return getOnHandAmount(InventoryProductSizeUnit, PackageType)(item)
}

export default OnHandAmountCell
