import { includes, prop } from 'ramda'
import { all } from 'redux-saga/effects'

import { Duck } from '../interfaces/Duck'

const addNameToTypes = (name: string, types: Record<string, string>) =>
  Object.keys(types).reduce(
    (acc, key) => ({
      ...acc,
      [key]: `${name}/${types[key]}`,
    }),
    {},
  )

export default (config: Record<string, any>): Duck => {
  const {
    duck: configDuck,
    name,
    types: configTypes,
    actions,
    reducer,
    saga,
    selectors,
    apiEndpoints,
  } = config

  const types = addNameToTypes(name, {
    ...configDuck().types,
    ...(configTypes || {}),
  })
  const extendedConfig = {
    ...config,
    getReducer: prop(name),
    apiEndpoints: apiEndpoints?.(types),
  }
  const duck = {
    ...configDuck(extendedConfig),
    types,
  }

  duck.actions = {
    ...duck.actions(duck),
    ...(actions?.(duck) || {}),
  }

  duck.selectors = {
    ...duck.selectors,
    ...(selectors || {}),
  }

  const hasConfigTypes = Boolean(configTypes)
  const configTypesValues = Object.values(configTypes || {})

  return {
    ...duck,
    reducer: (state: any, action: any, ...rest: any) => {
      const rawTypeName = action.type.split('/')[1]
      const isAdditionalAction =
        hasConfigTypes && Boolean(includes(rawTypeName, configTypesValues))

      return isAdditionalAction
        ? reducer?.(state, action, duck, rest) || {}
        : duck.reducer(state, action, ...rest, duck)
    },
    *saga() {
      yield all([...duck.saga(duck)(), ...(saga?.(duck)() || [])])
    },
  }
}
