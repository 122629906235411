import { AtLeast, Nil } from '@pbt/pbt-ui-components'

import {
  DocumentSource,
  SoapFormToSave,
  TimetableEvent,
  WritableTimetableEvent,
} from '~/types'

import { getAPIPath, request } from './core'
import { requestMutation } from './graphql/graphqlCore'
import { UPDATE_APPOINTMENT_NOTES } from './graphql/mutations/appointment'
import * as Schema from './schemas'

export const createAppointment = (
  businessId: string,
  appointment: TimetableEvent,
) =>
  request(
    getAPIPath('appointments'),
    { method: 'POST', data: appointment, params: { businessId } },
    true,
    Schema.event,
  )

export const fetchAppointment = (
  businessId: string,
  appointmentId: string | Nil,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.event,
  )

export const editAppointment = (
  businessId: string,
  appointment: TimetableEvent,
) =>
  request(
    getAPIPath(`appointments/${appointment.id}`),
    { method: 'PUT', data: appointment, params: { businessId } },
    true,
    Schema.event,
  )

export const patchAppointment = (
  businessId: string,
  appointmentId: string | Nil,
  data: AtLeast<WritableTimetableEvent, 'id'>,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}`),
    { method: 'PATCH', data, params: { businessId } },
    true,
    Schema.event,
  )

export const editRecurringAppointment = (
  businessId: string,
  appointment: TimetableEvent,
  param: string,
) =>
  request(
    getAPIPath(`appointments/${appointment.id}`),
    {
      method: 'PUT',
      data: appointment,
      params: { businessId, [param]: param ? true : undefined },
    },
    true,
    Schema.event,
  )

export const patchRecurringAppointment = (
  businessId: string,
  appointmentId: string | Nil,
  data: AtLeast<WritableTimetableEvent, 'id'>,
  param: string,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}`),
    {
      method: 'PATCH',
      data,
      params: { businessId, [param]: param ? true : undefined },
    },
    true,
    Schema.event,
  )

export const deleteAppointment = (businessId: string, appointmentId: string) =>
  request(
    getAPIPath(`appointments/${appointmentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const deleteRecurringAppointment = (
  businessId: string,
  appointmentId: string,
  param: string,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}`),
    {
      method: 'DELETE',
      params: { businessId, [param]: param ? true : undefined },
    },
    true,
  )

export const cancelFutureAppointments = (
  businessId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`appointments/cancel`),
    {
      method: 'POST',
      params: { businessId, patientId },
    },
    true,
  )

export const createBusyTime = (businessId: string, busyTime: TimetableEvent) =>
  request(
    getAPIPath('busytime'),
    { method: 'POST', data: busyTime, params: { businessId } },
    true,
    Schema.event,
  )

export const fetchBusyTime = (businessId: string, busyTimeId: string) =>
  request(
    getAPIPath(`busytime/${busyTimeId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.event,
  )

export const editBusyTime = (businessId: string, busyTime: TimetableEvent) =>
  request(
    getAPIPath(`busytime/${busyTime.id}`),
    { method: 'PUT', data: busyTime, params: { businessId } },
    true,
    Schema.event,
  )

export const editRecurringBusyTime = (
  businessId: string,
  busyTime: TimetableEvent,
  param: string,
) =>
  request(
    getAPIPath(`busytime/${busyTime.id}`),
    {
      method: 'PUT',
      data: busyTime,
      params: { businessId, [param]: param ? true : undefined },
    },
    true,
    Schema.eventsList,
  )

export const deleteBusyTime = (businessId: string, busyTimeId: string) =>
  request(
    getAPIPath(`busytime/${busyTimeId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const deleteRecurringBusyTime = (
  businessId: string,
  busyTimeId: string,
  param: string,
) =>
  request(
    getAPIPath(`busytime/${busyTimeId}`),
    {
      method: 'DELETE',
      params: { businessId, [param]: param ? true : undefined },
    },
    true,
  )

export const updateAppointmentNotes = requestMutation({
  mutation: UPDATE_APPOINTMENT_NOTES,
  variablesHandler: (_, id, notes) => ({
    id,
    input: { notes },
  }),
})

export const addForm = (
  businessId: string,
  documentSource: DocumentSource,
  documentSourceId: string | undefined,
  documentIds: string[],
) =>
  request(
    getAPIPath(`admin/services/documents/v2/instances`),
    {
      method: 'POST',
      data: {
        documentSourceType: documentSource,
        documentIds,
        documentSourceId,
      },
      params: { businessId },
    },
    true,
  )

export const deleteForm = (businessId: string, formInstance: SoapFormToSave) =>
  request(
    getAPIPath(`admin/services/documents/v2/instances`),
    {
      method: 'DELETE',
      params: { businessId },
      data: formInstance,
    },
    true,
  )
