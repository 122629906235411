import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'
import Typography from '~/components/elements/Typography/Typography'

const useStyles = makeStyles(
  (theme) => ({
    warningText: {
      color: theme.colors.important,
    },
  }),
  { name: 'AppointmentDepositAlert' },
)

interface AppointmentDepositAlertProps {
  appointmentWithin24Hours?: boolean
}

export const AppointmentDepositAlert = ({
  appointmentWithin24Hours = false,
}: AppointmentDepositAlertProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  return (
    <AlertBanner
      fullWidth
      ContainerProps={{ mt: 1, p: 0.5 }}
      content={
        <>
          <Typography.H2 className={classes.warningText}>
            {t('Common:APPOINTMENT_MAY_HAVE_DEPOSIT')}
          </Typography.H2>
          <Typography.ParagraphSm className={classes.warningText}>
            {appointmentWithin24Hours
              ? t('Common:APPOINTMENT_MAY_HAVE_DEPOSIT_CHECK_FORFEIT')
              : t('Common:APPOINTMENT_MAY_HAVE_DEPOSIT_CHECK_REFUND')}
          </Typography.ParagraphSm>
        </>
      }
      variant="warning"
    />
  )
}
