import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import * as R from 'ramda'
import { Field, moment, NamedEntity, Utils } from '@pbt/pbt-ui-components'

import EnumSelect, {
  EnumSelectProps,
} from '~/components/common/inputs/EnumSelect'
import FeatureToggle from '~/constants/featureToggle'
import { TaskState, UncreatableTasks } from '~/constants/taskConstants'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { editTaskState } from '~/store/actions/tasks'
import { useTaskEvent } from '~/store/hooks/tasks'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { EasterEggEvents, Task } from '~/types'

export interface TaskStatusSelectProps extends Partial<EnumSelectProps> {
  disabled?: boolean
  fullWidth?: boolean
  task?: Task
}

const TaskStatusSelect = ({
  task,
  disabled: disabledProp,
  fullWidth,
  ...rest
}: TaskStatusSelectProps) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getTasksIsLoading)
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )
  const { states: TaskStates, subTypes: TaskSubTypes } = useTaskEvent()

  if (!task || R.isEmpty(task)) {
    return <Skeleton height={20} variant="text" width={70} />
  }

  const isForVCR = task?.forChewyVcr

  const shouldUseVCRBehavior = isTaskListOracleIntegrationEnabled && isForVCR

  const OpenStateId = Utils.findConstantIdByName(TaskState.OPEN, TaskStates)
  const OverdueStateId = Utils.findConstantIdByName(
    TaskState.OVERDUE,
    TaskStates,
  )
  const DoneStateId = Utils.findConstantIdByName(TaskState.DONE, TaskStates)

  const expired =
    task.stateId === OverdueStateId && moment().isAfter(task.dueDate)

  const filterFunc = (state: NamedEntity) =>
    !expired || state.id !== OpenStateId

  const FilteredTaskStates = TaskStates.filter(filterFunc)

  const setState = (stateId: string) => {
    if (stateId === DoneStateId) {
      dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
    }
    dispatch(editTaskState(task.id, stateId))
  }

  const disabled =
    disabledProp ||
    UncreatableTasks.some(
      (type) =>
        task.typeId === Utils.findConstantByName(type, TaskSubTypes)?.id,
    ) ||
    shouldUseVCRBehavior

  return (
    <EnumSelect
      accent
      Constant={FilteredTaskStates}
      disabled={disabled || isLoading}
      field={
        {
          value: task.stateId,
          set: Utils.handleFormSelectInput(setState),
        } as Field
      }
      fullWidth={fullWidth}
      {...rest}
    />
  )
}

export default TaskStatusSelect
