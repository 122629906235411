import { MenuItem } from '~/components/dashboard/menu/menuItem'

interface DashboardParams {
  id: number
  urlSuffix?: string
}

interface DashboardVersion extends DashboardParams {
  url: string
}

/**
 * The SupportedDashboardConfig class is used to manage and simplify the configuration for supported analytics
 * dashboards and the data used to render them which can become confusing especially when we have multiple version
 * of the same dashboard.
 *
 * For example, rather than representing multiple versions of a dashboard through different enums, the same can be
 * achieved by instantiating an instance of this class with the different available versions and then accessing them
 * via the `V{VERSION_NUMBER}` property:
 *   - ANALYTICS_DASHBOARD.V1
 *   - ANALYTICS_DASHBOARD.V2
 *
 * Assuming the class has two versions, it will return the respective DashboardVersion object for each expression.
 * Otherwise, trying to access a version that doesn't exist will throw an error.
 */
export class SupportedDashboardConfig {
  #versions: Array<DashboardParams>

  #url: string

  #cvcOnly: boolean;

  [key: `V${number}`]: DashboardVersion

  constructor(
    versions: Array<DashboardParams>,
    url: string,
    cvcOnly?: boolean,
  ) {
    this.#versions = versions
    this.#url = url
    this.#cvcOnly = Boolean(cvcOnly)

    /**
     * Creates a new Proxy for the current object (`this`) to intercept property access via the `get` handler.
     *
     * - If the requested property exists in the target object, return the original value.
     * - If the property name matches the pattern "V" followed by digits (e.g., "V1", "V2"),
     *   invoke a private method `#getVersionFromProperty` to handle the versioned property and return its value.
     * - If the property doesn't exist and doesn't match the version pattern, return `undefined` as would normally
     *   be done when accessing a property that doesn't exist.
     */
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, {
      get(target, prop) {
        if (prop in target) {
          return target[prop as keyof typeof target]
        }

        if (typeof prop === 'string' && /V\d+/.test(prop)) {
          return target.#getVersionFromProperty(prop)
        }

        return undefined
      },
    })
  }

  #getVersionIndex(version: number) {
    if (!this.#versions[version - 1]) {
      throw new Error(`No such report version, ${version}, exists`)
    }
    return version - 1
  }

  #getVersionUrl(version: number) {
    const index = this.#getVersionIndex(version)

    return (
      this.#url +
      (this.#versions[index]?.urlSuffix
        ? `-${this.#versions[index]?.urlSuffix}`
        : '')
    )
  }

  #getVersionFromProperty(versionString: string): DashboardVersion {
    const version = Number(versionString.split('V')[1])
    return {
      ...this.#versions[this.#getVersionIndex(version)],
      url: this.#getVersionUrl(version),
    }
  }

  get cvcOnly() {
    return this.#cvcOnly
  }
}

export const getAnalyticsMenuItem = (
  dashboardVersion: DashboardVersion,
  text: string,
): MenuItem => ({
  id: dashboardVersion.id.toString(),
  text,
  url: dashboardVersion.url,
})
