import { FindingLocation, FindingState } from '~/types'

export const serializeLocations = (
  locationsObject: Record<string, FindingLocation> | string[] | null,
) => {
  const locations: FindingLocation[] = locationsObject
    ? Object.values(locationsObject)
    : []
  if (!locations.length) {
    return [{ id: null, dimensions: [] }]
  }
  return locations.map(({ id, dimensions = {}, notes }) => ({
    id: id || null,
    dimensions: Object.values(dimensions).map((dimension) => ({
      id: dimension.dimension,
      value: dimension.value,
    })),
    notes,
  }))
}

export const serializeFindingsState = (
  findings: Record<string, FindingState>,
) =>
  Object.values(findings).map((bodySystem) => ({
    id: bodySystem.id,
    state: bodySystem.state,
    notes: bodySystem.notes || null,
    findings:
      bodySystem.findings &&
      Object.values(bodySystem.findings).map(({ id, locations }) => ({
        id,
        locations: serializeLocations(locations),
      })),
  }))

export const serializeFindingsStateWithConcurrency = (
  findings: Record<string, FindingState>,
  crc: number,
) => ({
  crc,
  findingLogs: serializeFindingsState(findings),
})

const convertToObject = <T = any>(list: T[], modifier: any) =>
  list.reduce((acc: any, value: any) => {
    acc[value.id === null ? '' : value.id] = modifier(value)
    return acc
  }, {})

const deserializeDimensions = <T = any>(dimensions: T[]) =>
  convertToObject(dimensions, ({ id, value }: { id: string; value: any }) => ({
    value,
    dimension: id,
  }))

const deserializeLocations = <T = any>(locations: T[]) =>
  convertToObject(
    locations,
    ({
      id,
      name,
      notes = '',
      dimensions,
    }: {
      dimensions: any
      id: string
      name: string
      notes: string
    }) => ({
      id: id || '',
      name,
      notes,
      dimensions: deserializeDimensions(dimensions),
    }),
  )

const deserializeFindings = <T = any>(findings: T[]) =>
  convertToObject(
    findings,
    ({ locations, ...rest }: { [key: string]: any; locations: any }) => ({
      locations: deserializeLocations(locations),
      ...rest,
    }),
  )

export const deserializeFindingsState = <T = any>(bodySystems: T[]) =>
  convertToObject(bodySystems, (bodySystem: any) => ({
    ...bodySystem,
    findings: deserializeFindings(bodySystem?.findings),
  }))
