import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, Nil, NumberUtils, Text } from '@pbt/pbt-ui-components'
import { SuccessAlert, WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import Arrow from '~/components/common/images/Arrow.svg'
import CatWithLapTop from '~/components/common/images/CatWithLapTop.svg'
import Terminal from '~/components/common/images/TerminalSideView.svg'
import DialogNames from '~/constants/DialogNames'
import {
  CANCELED,
  CANCELLED,
  COMPLETED,
  PENDING,
  TAKEN,
} from '~/constants/transactionStatus'
import { getTransactionStatus } from '~/store/actions/payments'
import {
  getPendingPayment,
  getPosPayError,
  getTransactionStatusChecks,
} from '~/store/reducers/payments'
import { ExtendPayment, Payment } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(8, 11, 3),
    },
    catImage: {
      width: 110,
      height: 45,
    },
    terminalImage: {
      width: 110,
      height: 45,
    },
    lineImage: {
      width: 150,
      height: 30,
    },
    imageWrapper: {
      height: 130,
      width: 130,
      borderRadius: 65,
      boxShadow: '0 0 10px #bdbdbd',
    },
    successIcon: {
      width: 64,
      height: 64,
      marginBottom: theme.spacing(2),
      color: theme.colors.alertSuccess,
    },
    warnIcon: {
      width: 40,
      height: 40,
      marginBottom: theme.spacing(2),
      color: theme.colors.alertWarning,
    },
  }),
  { name: 'RhapsodyPayment' },
)

const RhapsodyPayment = ({
  transaction,
  onOk,
  clientId,
}: {
  clientId: string | Nil
  onOk: () => void
  transaction: Partial<Payment>
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'PageTitles'])

  const pendingPayment = useSelector(getPendingPayment)
  const transactionStatusChecks = useSelector(getTransactionStatusChecks)
  const posPayError = useSelector(getPosPayError)

  const [openPaymentDetails] = useDialog(DialogNames.PAYMENT_DETAILS)

  const moveToCurrentTransaction = (currentTransaction: Partial<Payment>) => {
    openPaymentDetails({
      clientId,
      payment: (currentTransaction as any).payment,
    })
    onOk()
  }

  useEffect(() => {
    if (transaction.state === COMPLETED) {
      moveToCurrentTransaction(transaction)
    }
  }, [transaction])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (transactionStatusChecks) {
      timeout = setTimeout(
        () => dispatch(getTransactionStatus(pendingPayment as ExtendPayment)),
        Defaults.DEBOUNCE_TRANSACTION_CHECK_TIME,
      )
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [transactionStatusChecks])

  return (
    <Grid container item className={classes.root}>
      {transaction.state === PENDING && !transaction.lastPOSAccess && (
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <Text align="center" variant="body">
            {t('Invoices:PAYMENTS.RHAPSODY_PAYMENT.SENDING_TRANSACTION', {
              amount: NumberUtils.formatMoney(transaction.amount),
            })}
          </Text>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="space-evenly"
            mt={3}
          >
            <Grid
              container
              item
              alignItems="center"
              className={classes.imageWrapper}
              justifyContent="center"
            >
              <img
                alt={t('PageTitles:CAT')}
                className={classes.catImage}
                src={CatWithLapTop}
              />
            </Grid>
            <img
              alt={t('Common:LINE')}
              className={classes.lineImage}
              src={Arrow}
            />
            <Grid
              container
              item
              alignItems="center"
              className={classes.imageWrapper}
              justifyContent="center"
            >
              <img
                alt={t('Common:TERMINAL_PAYMENT')}
                className={classes.terminalImage}
                src={Terminal}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(transaction.state === PENDING || transaction.state === TAKEN) &&
        transaction.lastPOSAccess && (
          <Grid container item alignItems="center" justifyContent="center">
            <Grid container item alignItems="center" justifyContent="center">
              <SuccessAlert className={classes.successIcon} />
            </Grid>
            <Text align="center" variant="body">
              {t('Common:SENT')}!
            </Text>
            <Text align="center" variant="body">
              {t('Invoices:PAYMENTS.RHAPSODY_PAYMENT.LOOK_FOR_TRANSACTION')}
            </Text>
          </Grid>
        )}
      {(transaction.state === CANCELLED || transaction.state === CANCELED) && (
        <Grid container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <WarnAlert className={classes.warnIcon} />
          </Grid>
          <Text align="center" variant="body">
            {t('Invoices:PAYMENTS.RHAPSODY_PAYMENT.CANCELLED_TRANSACTION')}
          </Text>
        </Grid>
      )}
      {posPayError && (
        <Grid container item alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            <WarnAlert className={classes.warnIcon} />
          </Grid>
          <Text align="center" variant="body">
            {t('Invoices:PAYMENTS.RHAPSODY_PAYMENT.ERROR_POS_PAY')}
          </Text>
        </Grid>
      )}
    </Grid>
  )
}

export default RhapsodyPayment
