import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  ButtonWithLoader,
  Defaults,
  PermissionArea,
  PuiTextArea,
  PuiTooltip,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { AddNote as AddNoteIcon } from '@pbt/pbt-ui-components/src/icons'

import InvoiceStatusLabel from '~/components/dashboard/invoices/InvoiceStatusLabel'
import { usePaymentController } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/usePaymentController'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { PaymentTypes } from '~/constants/financeConstants'
import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'
import { InvoiceViewStates } from '~/constants/refund'
import { OrderType } from '~/constants/SOAPStates'
import { fetchModality } from '~/store/duck/imagingOrders'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import {
  getChargesLogsByLogType,
  useGetHasOutstandingImagingOrders,
  useGetHasOutstandingLabOrders,
  useHasUnfinishedLabs,
  useOutstandingImagingOrders,
} from '~/store/hooks/finance'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessId,
  getCurrentUserId,
  getIsPracticeAdministrator,
} from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getInvoiceStates,
  getRefundDisabledReason,
} from '~/store/reducers/constants'
import {
  getInvoiceV3,
  getInvoiceV3Id,
  getInvoiceV3Loading,
  getInvoiceV3SubItemsMap,
  updateInvoiceNotesOrStatus,
  voidInvoice,
} from '~/store/reducers/invoiceV3'
import { getUsersMap } from '~/store/reducers/users'
import { ChargeSheetItemSection, InvoiceLineItem } from '~/types/entities'
import { convertV3InvoiceToInvoiceOmitEventsAndPatients } from '~/utils/finance'
import { isRhapsodyGoAvailableForPractice } from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useFieldsChanged from '~/utils/useFieldsChanged'

import InvoiceTotalsSection from '../../invoices/InvoiceTotalsSection'
import { getInvoiceTotals, isUnpostDisabled } from '../../invoices/invoiceUtils'
import MissingFinalizationDialog from '../components/MissingFinalizationDialog'
import InvoiceFinanceDropdownActions from './InvoiceFinanceDropdownActions'

const hasValue = R.allPass([R.complement(R.isNil), R.complement(R.isEmpty)])

const useInvoiceFooterStyles = makeStyles(
  (theme) => ({
    bottom: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
    },
    button: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0.5, 0),
    },
    buttonContainer: {
      '& button': {
        textTransform: 'none',
      },
    },
    total: {
      borderLeft: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    footNote: {
      backgroundColor: theme.colors.tableBackground,
    },
    invoiceTotal: {
      borderTop: theme.constants.tableBorder,
      borderTopWidth: 1,
    },
    scrollInput: {
      overflowY: 'scroll',
    },
    tooltipLink: {
      display: 'contents',
    },
  }),
  { name: 'InvoiceFooter' },
)

interface InvoiceFooterProps {
  onSwitchInvoiceViewState: (viewState: InvoiceViewStates) => void
  useInvoiceCVCLayout?: boolean
}

const InvoiceFooter = ({
  onSwitchInvoiceViewState,
  useInvoiceCVCLayout = false,
}: InvoiceFooterProps) => {
  const classes = useInvoiceFooterStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation(['Common', 'Invoices'])

  const { update: invoicePermissionsUpdate } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )
  const refundDisabledReasons = useSelector(getRefundDisabledReason)
  const InvoiceStates = useSelector(getInvoiceStates)
  const invoiceId = useSelector(getInvoiceV3Id)
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const isLoading = useSelector(getInvoiceV3Loading)
  const business = useSelector(getCurrentBusiness)
  const rhapsodyPayConfig = useSelector(getRhapsodyPayConfig(business?.id))
  const subItemsMap = useSelector(getInvoiceV3SubItemsMap)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const currentUserId = useSelector(getCurrentUserId)
  const usersMap = useSelector(getUsersMap)
  const paymentCreatePermissions = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  ).create

  const [internalNoteVisible, setInternalNoteVisible] = useState(
    hasValue(invoice?.internalNote),
  )
  const [includeServiceFee, setIncludeServiceFee] = useState(true)

  const [openChargeSheetLabOrderManagementDialog] = useDialog(
    DialogNames.CHARGE_SHEET_LAB_ORDER_MANAGEMENT_DIALOG,
  )
  const [openImagingOrdersManagementDialogV2] = useDialog(
    DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT_V2,
  )

  const isPracticeAdmin = useSelector(getIsPracticeAdministrator(business))

  const isChewyRxFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )

  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const paymentController = usePaymentController({
    invoice,
  })

  useEffect(() => {
    if (business?.id) {
      dispatch(fetchRhapsodyPayConfig(business.id))
    }
  }, [business?.id])

  useEffect(() => {
    setInternalNoteVisible(hasValue(invoice?.internalNote))
  }, [invoice?.internalNote])

  const groupedItems = R.pipe<
    (typeof subItemsMap)[],
    ChargeSheetItemSection[],
    InvoiceLineItem[]
  >(
    R.values,
    R.chain(R.prop('groupedItems')),
  )(subItemsMap)

  const openPaymentDialogAfterSaving = () => {
    if (invoice) {
      const { id, dueToPayNoFee, clientId } = invoice
      paymentController.addPayment({
        addPaymentDialogProps: {
          clientId,
          ComponentProps: {
            invoiceAmount: dueToPayNoFee,
            assignedInvoiceId: id,
            invoiceIds: [id],
            paymentType: includeServiceFee
              ? PaymentTypes.CREDIT_CARD
              : PaymentTypes.OTHER,
          },
        },
      })
    }
  }

  const voidedStateId = Utils.findConstantIdByName('Voided', InvoiceStates)

  const { stateId, dueToPayNoFee } = invoice || {}

  // accroding to RHAP-5603 now we need to synchronize state with backend invoice state
  const isVoidedState = stateId === voidedStateId
  const hasDueToPayNoFee = Number(dueToPayNoFee) > 0

  const retailOrder = invoice?.retailOrder
  const getHasRetailOrderInDraftState = useGetChewyOrderState()

  const PreparedInvoiceStates = isVoidedState
    ? InvoiceStates
    : InvoiceStates.filter((item) => item.id !== voidedStateId)

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchModality(currentBusinessId))
    }
  }, [currentBusinessId])
  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    business,
    rhapsodyPayConfig,
  )

  const toggleInternalNote = () => setInternalNoteVisible((prev) => !prev)

  const { fields, reset } = useFields(
    [
      {
        name: 'notes',
        initialValue: invoice?.internalNote || '',
      },
    ],
    false,
  )

  const { notes } = fields

  useEffect(() => {
    reset()
  }, [invoice])

  const actions = [
    {
      id: 'invoice-group',
      isGroup: true,
      title: t('Invoices:ADD_TO_INVOICE'),
      items: [
        {
          checkbox: true,
          id: 'internal-notes',
          checked: internalNoteVisible,
          Icon: AddNoteIcon,
          label: t('Common:INTERNAL_NOTE'),
          onClick: toggleInternalNote,
        },
      ],
    },
  ]

  const openChargeSheetAfterUnPost = useCloseAfterCreation(() => {
    if (invoice?.clientId) {
      navigate(`/chargesheet/${invoice.clientId}`)
    }
  }, getInvoiceV3Loading)

  const onVoidInvoiceClick = () => {
    openChargeSheetAfterUnPost()

    if (currentUserId) {
      dispatch(
        voidInvoice({
          invoiceId,
          voidedById: currentUserId,
        }),
      )
    }
  }

  const getTextStatus = (id: string) => {
    const stateConstant = PreparedInvoiceStates.find((st) => st.id === id)
    return !R.isNil(stateConstant)
      ? stateConstant.name.toUpperCase()
      : undefined
  }

  const onEditNotes = useDebouncedCallback((invoiceFields) => {
    const newStateId = R.pipe(
      R.find(R.propEq('name', 'state')),
      R.prop<string>('value'),
    )(invoiceFields)
    const stateName = getTextStatus(newStateId)
    if (invoice) {
      dispatch(
        updateInvoiceNotesOrStatus({
          invoiceId,
          input: {
            internalNote: R.pipe(
              R.find(R.propEq('name', 'notes')),
              R.prop('value'),
            )(invoiceFields),
            state: stateName,
          },
          expectedModification: invoice.innerModificationDate,
        }),
      )
    }
  }, Defaults.DEBOUNCE_ACTION_TIME)

  useFieldsChanged(onEditNotes, fields, true)

  // when user goes directly to another page, flush changes immediately, otherwise debounce will not fire
  useEffect(
    () => () => {
      onEditNotes.flush()
    },
    [onEditNotes],
  )

  const sections = Object.values(subItemsMap)
  const labLogs = getChargesLogsByLogType(OrderType.LAB_TEST, sections)
  const hasUnfinishedLabs = useHasUnfinishedLabs(labLogs)
  const {
    hasLabs,
    hasPendingLabOrders,
    isLoading: isLoadingLabTests,
  } = useGetHasOutstandingLabOrders({
    logs: labLogs,
    clientId: R.isNil(invoice) ? '' : invoice.clientId,
    invoiceId,
  })

  const onLabOrderManagementRequested = () => {
    if (invoice && invoice.clientId) {
      openChargeSheetLabOrderManagementDialog({
        invoiceId,
        clientId: invoice.clientId,
      })
    }
  }

  const procedureLogs = getChargesLogsByLogType(OrderType.PROCEDURE, sections)

  const { hasPendingImagingOrders, isLoading: isLoadingImagingOrders } =
    useGetHasOutstandingImagingOrders({
      logs: procedureLogs,
      clientId: R.isNil(invoice) ? '' : invoice.clientId,
      invoiceId,
    })

  const hasOutstandingImagingOrders =
    useOutstandingImagingOrders(procedureLogs)?.length > 0

  const onImagingManagementRequested = () => {
    if (invoice && invoice.clientId) {
      openImagingOrdersManagementDialogV2({
        hideOrderManagement: true,
        clientId: invoice.clientId,
        invoiceId,
      })
    }
  }

  if (!invoice) {
    return null
  }

  const convertedInvoice = convertV3InvoiceToInvoiceOmitEventsAndPatients(
    invoice,
    groupedItems,
    usersMap,
  )

  const totals = getInvoiceTotals(convertedInvoice)

  const requestPayment = () =>
    paymentController.paymentRequest({
      paymentRequestDialogProps: {
        invoice: convertedInvoice,
      },
    })

  const refundInvoiceUrl = `/refund/${invoice.pendingRefundInvoice?.id}`
  const refundDisabledReasonId = invoice.refundDisabledReason?.id
  const openInvoiceReasonId = Utils.findConstantIdByName(
    'Open Invoice',
    refundDisabledReasons,
  )
  const pendingRefundReasonId = Utils.findConstantIdByName(
    'Pending Refunded Invoice',
    refundDisabledReasons,
  )
  const fullyRefundedReasonId = Utils.findConstantIdByName(
    'Fully Refunded',
    refundDisabledReasons,
  )
  let refundDisabledTooltipText
  if (refundDisabledReasonId === openInvoiceReasonId) {
    refundDisabledTooltipText = t(
      'Invoices:REFUND_DISABLED_TOOLTIP.OPEN_INVOICE',
    )
  } else if (refundDisabledReasonId === fullyRefundedReasonId) {
    refundDisabledTooltipText = t(
      'Invoices:PAYMENTS.PAYMENT_DETAILS.REFUND_BUTTON.REVERSAL_OR_REFUND_COMPLETED',
    )
  } else if (refundDisabledReasonId === pendingRefundReasonId) {
    const linkToRefund = invoice.pendingRefundInvoice ? (
      <Link className={classes.tooltipLink} to={refundInvoiceUrl} />
    ) : (
      <span className={classes.tooltipLink} />
    )

    refundDisabledTooltipText = (
      <Trans
        components={{ linkToRefund }}
        i18nKey="Invoices:REFUND_DISABLED_TOOLTIP.PENDING_REFUND"
      />
    )
  }

  const showRefundButton =
    invoice.type === 'Invoice' && isIpoM0InvoiceRefundsEnabled

  const editDisabled = !invoicePermissionsUpdate || isLoading || isVoidedState

  const retailOrderHasPayment = (retailOrder?.paymentDetails.length ?? 0) > 0
  const canRecordPayment = hasDueToPayNoFee && paymentCreatePermissions
  const canAddChewyPayment =
    retailOrder?.totalPrice > 0 && !retailOrder?.orderConfirmationId

  const onlyChewyItems = groupedItems.length === 0 && isChewyCheckoutEnabled

  const showUnpostButton =
    isPracticeAdmin &&
    (!onlyChewyItems ||
      (onlyChewyItems &&
        !retailOrderHasPayment &&
        getHasRetailOrderInDraftState(retailOrder?.state.id)?.isDraft))

  return (
    <Grid container mt="auto">
      <Grid container item component={Collapse} in={internalNoteVisible}>
        <Grid px={FINANCE_TABLE_PADDING_X_SPACING_VALUE} py={2}>
          <PuiTextArea
            InputProps={{
              inputProps: {
                className: classes.scrollInput,
              },
            }}
            field={notes}
            label={t('Common:INTERNAL_NOTE')}
            margin="none"
            maxRows={6}
            minRows={2}
            showColon={false}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.bottom}>
        <Grid
          container
          item
          flexDirection="column"
          gap={1}
          justifyContent="flex-end"
          lg={9.15}
          md={6.5}
          px={FINANCE_TABLE_PADDING_X_SPACING_VALUE}
          py={2}
        >
          {!isChewyCheckoutEnabled && (
            <Grid container gap={1}>
              <Text strong variant="body2">
                {t('Common:STATUS')}:
              </Text>
              <InvoiceStatusLabel invoice={invoice} />
            </Grid>
          )}
          <Grid
            container
            item
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid container item gap={2} width="auto">
              {showRefundButton &&
                (invoice.refundDisabledReason ? (
                  <PuiTooltip tooltipText={refundDisabledTooltipText}>
                    <span className={classes.buttonContainer}>
                      <ButtonWithLoader disabled className={classes.button}>
                        {t('Common:REFUND')}
                      </ButtonWithLoader>
                    </span>
                  </PuiTooltip>
                ) : (
                  <ButtonWithLoader
                    className={classes.button}
                    onClick={() =>
                      onSwitchInvoiceViewState(InvoiceViewStates.REFUND_LANDING)
                    }
                  >
                    {t('Common:REFUND')}
                  </ButtonWithLoader>
                ))}
              {(canRecordPayment || canAddChewyPayment) && (
                <ButtonWithLoader
                  className={classes.button}
                  disabled={editDisabled}
                  loading={isLoading}
                  type="submit"
                  onClick={
                    canRecordPayment
                      ? openPaymentDialogAfterSaving
                      : paymentController.chewyPaymentDialogOnly
                  }
                >
                  {canRecordPayment
                    ? t('Common:RECORD_PAYMENT')
                    : t('Common:PLACE_CHEWY_ORDER')}
                </ButtonWithLoader>
              )}
              {showUnpostButton && (
                <ButtonWithLoader
                  className={classes.button}
                  disabled={isUnpostDisabled(invoice) || isVoidedState}
                  onClick={onVoidInvoiceClick}
                >
                  {t('Common:UNPOST_INVOICE')}
                </ButtonWithLoader>
              )}
              {hasDueToPayNoFee && isGoAvailableForPractice && (
                <ButtonWithLoader
                  className={classes.button}
                  color="secondary"
                  disabled={invoice.dueToPay === 0 || isVoidedState}
                  onClick={requestPayment}
                >
                  {t('Common:REQUEST_PAYMENT')}
                </ButtonWithLoader>
              )}
              {hasLabs && (
                <>
                  {isChewyRxFinalizationEnabled ? (
                    <ButtonWithLoader
                      className={classes.button}
                      color={hasPendingLabOrders ? 'important' : 'secondary'}
                      disabled={isLoadingLabTests}
                      loading={isLoadingLabTests}
                      type="submit"
                      onClick={onLabOrderManagementRequested}
                    >
                      {hasPendingLabOrders || isLoadingLabTests
                        ? t('Common:FINALIZE_LABS')
                        : t('Common:VIEW_LAB_DETAILS')}
                    </ButtonWithLoader>
                  ) : (
                    <ButtonWithLoader
                      className={classes.button}
                      color={hasUnfinishedLabs ? 'important' : 'secondary'}
                      disabled={isLoading}
                      loading={isLoading}
                      type="submit"
                      onClick={onLabOrderManagementRequested}
                    >
                      {hasUnfinishedLabs
                        ? t('Common:FINALIZE_LABS')
                        : t('Common:VIEW_LAB_DETAILS')}
                    </ButtonWithLoader>
                  )}
                </>
              )}
              {isChewyRxFinalizationEnabled ? (
                <>
                  {hasPendingImagingOrders && (
                    <ButtonWithLoader
                      className={classes.button}
                      color="important"
                      disabled={isLoadingImagingOrders}
                      loading={isLoadingImagingOrders}
                      type="submit"
                      onClick={onImagingManagementRequested}
                    >
                      {t('Common:FINALIZE_IMAGING')}
                    </ButtonWithLoader>
                  )}
                </>
              ) : (
                <>
                  {hasOutstandingImagingOrders && (
                    <ButtonWithLoader
                      className={classes.button}
                      color="important"
                      disabled={isLoading}
                      loading={isLoading}
                      type="submit"
                      onClick={onImagingManagementRequested}
                    >
                      {t('Common:FINALIZE_IMAGING')}
                    </ButtonWithLoader>
                  )}
                </>
              )}
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              className={classes.footNote}
              gap={1.5}
              width="auto"
            >
              {!useInvoiceCVCLayout && business?.invoiceNotes && (
                <Text variant="body3">{business.invoiceNotes}</Text>
              )}
              <InvoiceFinanceDropdownActions
                actions={actions}
                includeServiceFee={includeServiceFee}
                invoice={invoice}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className={classes.total} lg={2.85} md={5.5}>
          <InvoiceTotalsSection
            clientId={invoice?.clientId}
            includeServiceFee={includeServiceFee}
            invoice={convertedInvoice}
            isEstimate={false}
            navigateToRefundLanding={() =>
              onSwitchInvoiceViewState(InvoiceViewStates.REFUND_LANDING)
            }
            setIncludeServiceFee={setIncludeServiceFee}
            setInvoice={() => {}}
            totals={totals}
          />
        </Grid>
      </Grid>
      <MissingFinalizationDialog
        hasPendingChewyOrders={false}
        hasPendingImagingOrders={
          isChewyRxFinalizationEnabled
            ? hasPendingImagingOrders
            : hasOutstandingImagingOrders
        }
        hasPendingLabOrders={
          isChewyRxFinalizationEnabled ? hasPendingLabOrders : hasUnfinishedLabs
        }
        isAllowedRedirect={false}
      />
    </Grid>
  )
}

export default InvoiceFooter
