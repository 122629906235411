import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CustomFieldValidatorState,
  LanguageUtils,
  PuiAlert,
  PuiTextField,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import { StatusError } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'
import FeatureToggle from '~/constants/featureToggle'
import {
  getFeatureToggle,
  getInternalCancellationReasons,
} from '~/store/reducers/constants'
import { isNilOrEmpty } from '~/utils'
import { useIsWaivableCancellationReason } from '~/utils/appointmentCancellationUtils'

import { AppointmentDepositAlert } from './AppointmentDepositAlert'
import AppointmentCancellationWaiveFeeAlert from './reminders/AppointmentCancellationWaiveFeeAlert'
import { useUpdateInternalNotesForAppointmentCancellation } from './useUpdateInternalNotesForAppointmentCancellation'

const useStyles = makeStyles(
  (theme) => ({
    containerTextOnly: {
      padding: theme.spacing(2),
    },
    notesTemplateInput: {
      maxHeight: 170,
      overflowY: 'auto',
    },
    boxContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    button: {
      width: '200px',
    },
    otherReasonWarningToolTip: {
      maxWidth: 300,
      width: 300,
    },
    statusError: {
      margin: theme.spacing(1, 0),
      alignSelf: 'flex-start',
    },
    radioGroup: {
      marginTop: `${theme.spacing(2)} !important`,
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'AppointmentCancellationReasonDialog' },
)

const StyledAlert = styled(PuiAlert)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 637,
    maxWidth: 637,
    padding: theme.spacing(1),
  },
}))

export interface AppointmentCancellationReasonDialogProps
  extends BasePuiDialogProps {
  clientId: string
  isAppointmentWithin24Hours?: boolean
  isNoShowConsentAppointmentType: boolean
  onCancel?: () => void
  onProceed: (
    appointmentCancellationReasonId: string,
    appointmentCancellationReasonName: string,
    internalNotes: string,
    waiveLateCancellationFee?: boolean,
  ) => void
  patientId: string
  showDepositAlert?: boolean
  showNoShowCancellationPenaltyAlert?: boolean
}

export const AppointmentCancellationReasonDialog = ({
  open,
  onProceed,
  onClose,
  clientId,
  patientId,
  showDepositAlert = false,
  showNoShowCancellationPenaltyAlert = false,
  isAppointmentWithin24Hours,
  isNoShowConsentAppointmentType,
  ...rest
}: AppointmentCancellationReasonDialogProps) => {
  const { t } = useTranslation(['Dialogs', 'Common'])
  const classes = useStyles()

  // this logic sorts the reasons alphabetically and places Other reason at bottom of list
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  ).sort((a, b) =>
    a.name === 'Other'
      ? 1
      : b.name === 'Other'
        ? -1
        : a.name.localeCompare(b.name),
  )

  const noShowPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )

  const [internalNoteKey, setInternalNoteKey] = useState(uuid())
  const [showChooseReasonAlert, setShowChooseReasonAlert] = useState(false)
  const [showWaiveFeeAlert, setShowWaiveFeeAlert] = useState(false)

  const otherReasonId = Utils.findConstantIdByName(
    'Other',
    internalCancellationReasons,
  )

  const { fields, validate } = useFields(
    [
      {
        name: 'appointmentCancellationReason',
        validators: ['required'],
        type: 'select',
      },
      { name: 'waiveLateCancellationFee' },
      { name: 'internalNotes', initialValue: '' },
    ],
    false,
  )

  const {
    appointmentCancellationReason,
    waiveLateCancellationFee,
    internalNotes,
  } = fields

  const { fields: otherReasonFields, validate: otherReasonValidate } =
    useFields(
      [
        {
          name: 'otherReasonField',
          initialValue: '',
          messages: {
            otherReasonForCancellation: t(
              'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_VALIDATION_ERROR',
            ),
          },
          validators: [
            {
              validator: ({ value }: CustomFieldValidatorState) => value,
              validatorName: 'otherReasonForCancellation',
            },
          ],
        },
      ],
      false,
    )

  const { otherReasonField } = otherReasonFields
  const setInternalNote = (value: string) => {
    setInternalNoteKey(uuid())
    internalNotes.setValue(value)
  }

  const {
    onCancellationReasonRadioSelectEvent,
    onWaiveLateCancellationFeeChange,
    onOtherReasonTextChange,
  } = useUpdateInternalNotesForAppointmentCancellation({
    internalNotes,
    otherReasonField,
    setInternalNote,
    showWaiveFeeCheckbox: showWaiveFeeAlert,
    waiveLateCancellationFee,
    isAppointmentWithin24Hours,
    isNoShowConsentAppointmentType,
  })

  const { isWaivableCancellationReason } = useIsWaivableCancellationReason()

  useEffect(() => {
    if (
      noShowPenaltyCardOnFileAlertEnabled &&
      !appointmentCancellationReason.value
    ) {
      onWaiveLateCancellationFeeChange(showWaiveFeeAlert)
    }
  }, [
    noShowPenaltyCardOnFileAlertEnabled,
    showWaiveFeeAlert,
    appointmentCancellationReason.value,
  ])

  useEffect(() => {
    if (!isNilOrEmpty(appointmentCancellationReason.value))
      setShowChooseReasonAlert(!validate())
    setShowWaiveFeeAlert(
      showNoShowCancellationPenaltyAlert &&
        !isNilOrEmpty(appointmentCancellationReason.value) &&
        isWaivableCancellationReason(appointmentCancellationReason.value),
    )

    if (
      appointmentCancellationReason.value === otherReasonId &&
      !R.isNil(otherReasonField.value)
    ) {
      onOtherReasonTextChange()
    }
  }, [
    appointmentCancellationReason.value,
    otherReasonField.value,
    showNoShowCancellationPenaltyAlert,
  ])

  const handleProceed = () => {
    const isValid = validate()
    if (!isValid) {
      setShowChooseReasonAlert(!isValid)
      return
    }

    if (
      appointmentCancellationReason.value === otherReasonId &&
      !otherReasonValidate()
    )
      return

    const reasonName =
      otherReasonField.value ||
      LanguageUtils.getConstantTranslatedName(
        appointmentCancellationReason.value,
        internalCancellationReasons,
      )

    onProceed(
      appointmentCancellationReason.value,
      reasonName,
      internalNotes.value,
      waiveLateCancellationFee.value,
    )
    if (!onClose) return
    onClose()
  }

  const handleClose = () => {
    onClose?.()
  }

  return (
    <StyledAlert
      actions={
        <div>
          {showChooseReasonAlert && (
            <StatusError className={classes.statusError}>
              {t(
                'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.CHOOSE_REASON_APPOINTMENT_CANCELLED',
              )}
            </StatusError>
          )}

          <ButtonWithLoader
            className={classes.button}
            disabled={showChooseReasonAlert}
            onClick={handleProceed}
          >
            {t('Dialogs:APPOINTMENT_CANCELLATION_DIALOG.MARK_AS_CANCELED')}
          </ButtonWithLoader>
        </div>
      }
      aria-labelledby="appointment-cancellation-reason-dialog"
      classes={{ containerTextOnly: classes.containerTextOnly }}
      content={
        <Box className={classes.boxContainer}>
          <Typography.H2>
            {t(
              'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.WHY_IS_THE_APPOINTMENT_BEING_CANCELED',
            )}
            *
          </Typography.H2>
          <Typography.Paragraph>
            {t(
              'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.INTERNAL_NOTE_DISCLAIMER',
            )}
          </Typography.Paragraph>
          <EnumRadioGroup
            Constant={internalCancellationReasons}
            className={classes.radioGroup}
            field={appointmentCancellationReason}
            onChange={
              noShowPenaltyCardOnFileAlertEnabled
                ? onCancellationReasonRadioSelectEvent
                : undefined
            }
          />
          {appointmentCancellationReason.value === otherReasonId && (
            <Box mb={1} pl={4} width="100%">
              <PuiTextField
                TooltipProps={{
                  classes: { errorTooltip: classes.otherReasonWarningToolTip },
                }}
                field={otherReasonField}
                inputProps={{ maxLength: 1500 }}
                label={t(
                  'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
                )}
              />
            </Box>
          )}
          {showDepositAlert && (
            <AppointmentDepositAlert
              appointmentWithin24Hours={isAppointmentWithin24Hours}
            />
          )}
          {noShowPenaltyCardOnFileAlertEnabled && (
            <Grid mb={1} mt={1} width="100%">
              <NotesTemplateInput
                resetStateOnValueChange
                singleLine
                classes={{ richEditRoot: classes.notesTemplateInput }}
                clientId={clientId}
                field={internalNotes}
                key={internalNoteKey}
                minHeight={30}
                patientId={patientId}
                title={t('Common:INTERNAL_NOTES')}
              />
            </Grid>
          )}
          {showWaiveFeeAlert && (
            <AppointmentCancellationWaiveFeeAlert
              field={waiveLateCancellationFee}
              onChange={onWaiveLateCancellationFeeChange}
            />
          )}
        </Box>
      }
      open={open}
      title={t('Dialogs:APPOINTMENT_CANCELLATION_DIALOG.CANCEL_APPOINTMENT')}
      onClose={handleClose}
      {...rest}
    />
  )
}
