import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { AnyAction } from 'redux'
import { Nil, useInterval } from '@pbt/pbt-ui-components'

import { useAppointmentTypes } from '~/components/common/appointments/useAppointmentTypes'
import MarketplaceItemIframe from '~/components/common/marketplaceIframes/MarkeplaceItemIframe'
import DialogNames from '~/constants/DialogNames'
import { RootState } from '~/store'
import { setSchedulingAssignment } from '~/store/actions/timetable'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import { getExpandedIFrame } from '~/store/duck/marketplace'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getSchedulerLastFetchTimestamp } from '~/store/reducers/scheduler'
import {
  getTimetableAutoRefreshEnabled,
  getTimetableIsSaving,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import { Schedule, WhiteboardSchedule } from '~/types'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useTimetableDate from '~/utils/useTimetableDate'

import TimezoneAlert from '../alerts/TimezoneAlert'
import TimetableRail from './rail/TimetableRail'
import { useAppointmentCancellationNotification } from './useAppointmentCancellationNotification'

interface TimetablePageProps {
  children: React.ReactNode
  fetchTimetablesAction: (silent?: boolean, date?: string) => AnyAction
  railOnTop?: boolean
  schedulesSelector: (state: RootState) => Schedule[] | WhiteboardSchedule[]
  tabs?: {
    component: () => JSX.Element
    label: string
  }[]
}

interface TimetablePageLocationState {
  clientId?: string | null
  openDialog?: string
  patientId?: string | null
  redirectUrl?: string | Nil
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
    },
    rootWithRightRail: {
      flex: 1,
      width: `calc(100% - ${theme.constants.rightRailCollapsedWidth}px)`,
    },
    contentRoot: {
      width: '100%',
      maxWidth: '100%',
      paddingBottom: theme.spacing(1),
      position: 'relative',
    },
  }),
  { name: 'TimetablePage' },
)

const REFRESH_TIMEOUT = 60000

const TimetablePage = ({
  railOnTop,
  schedulesSelector,
  fetchTimetablesAction,
  children,
  tabs,
}: TimetablePageProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const mounted = useRef(false)
  const { appointmentId } = useParams()
  const isSaving = useSelector(getTimetableIsSaving)
  const timetableAutoRefreshEnabled = useSelector(
    getTimetableAutoRefreshEnabled,
  )
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const businessId = useSelector(getCurrentBusinessId)
  const iFrame = useSelector(getExpandedIFrame)
  const validationError = useSelector(getTimetableValidationError)
  const fetchTimestamp = useSelector(getSchedulerLastFetchTimestamp)
  useAppointmentTypes()
  const { selectedDate } = useTimetableDate()

  const {
    openDialog,
    clientId: clientIdProp,
    patientId: patientIdProp,
    redirectUrl,
  } = (location.state || {}) as TimetablePageLocationState

  const [clientId, setClientId] = useState(clientIdProp)
  const [patientId, setPatientId] = useState(patientIdProp)
  const [wasSaving, setWasSaving] = useState(false)
  const [rightRailVisible, setRightRailVisible] = useState(!railOnTop)

  const goToRoot = () => {
    const match = location.pathname.match(/(\/\w+)\/\w+/)
    if (match) {
      navigate(match[1], { replace: true })
    }
  }

  const onDialogClose = () => {
    goToRoot()
    setClientId(null)
    setPatientId(null)
  }

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT, onDialogClose)

  useEffect(() => {
    if (clientIdProp && patientIdProp) {
      dispatch(
        setSchedulingAssignment(clientIdProp, patientIdProp, redirectUrl),
      )
    }

    if (appointmentId || openDialog) {
      openAppointmentDialog({
        appointmentId,
        clientId: clientIdProp,
        patientId: patientIdProp,
        isAppointmentType: true,
      })
    }
  }, [])

  useEffect(() => {
    setRightRailVisible(!railOnTop)
  }, [railOnTop])

  const resetInterval = useInterval(() => {
    if (timetableAutoRefreshEnabled && !hasOpenDialogs) {
      dispatch(fetchTimetablesAction(true))
    }
  }, REFRESH_TIMEOUT)

  useEffectExceptOnMount(() => {
    if (timetableAutoRefreshEnabled && !hasOpenDialogs) {
      resetInterval()
    }
  }, [timetableAutoRefreshEnabled, hasOpenDialogs, fetchTimestamp])

  useEffect(() => {
    if (selectedDate) {
      mounted.current = true
      dispatch(fetchTimetablesAction(mounted.current))
    }
  }, [fetchTimetablesAction, selectedDate])

  useEffect(() => {
    if (isSaving) {
      setWasSaving(true)
    } else if (wasSaving) {
      setWasSaving(false)
      if (!validationError) {
        dispatch(fetchTimetablesAction(true))
      }
    }
  }, [isSaving])

  useAppointmentCancellationNotification()

  const onAddAppointmentRequested = () => {
    openAppointmentDialog({
      appointmentId,
      clientId,
      patientId,
    })
  }

  return (
    <Grid
      container
      className={classNames({
        [classes.root]: !rightRailVisible || railOnTop,
        [classes.rootWithRightRail]: !railOnTop && rightRailVisible,
      })}
    >
      <TimetableRail
        schedulesSelector={schedulesSelector}
        tabs={tabs}
        visible={rightRailVisible}
        onAddAppointmentRequested={onAddAppointmentRequested}
        onVisibilityChange={setRightRailVisible}
      />
      {iFrame ? (
        <MarketplaceItemIframe
          id={iFrame.id}
          params={{
            businessId,
          }}
          title={iFrame.name}
          url={iFrame.expandedUrl}
        />
      ) : (
        <Grid item className={classes.contentRoot}>
          {children}
        </Grid>
      )}
      <TimezoneAlert />
    </Grid>
  )
}

export default TimetablePage
